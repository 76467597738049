import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'
import { useSiteConfig } from '../../../SiteConfig'
import { useLayout } from '../../core'

const SidebarLogo = () => {
  const { sidebarMini, setSidebarMini } = useSiteConfig()
  const { config } = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled = config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled = config?.app?.sidebar?.default?.collapse?.desktop?.enabled

  const toggleType = appSidebarDefaultCollapseDesktopEnabled ? 'collapse' : 'minimize'
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = sidebarMini // config.app?.sidebar?.default?.minimize?.desktop?.default

  const toggleClassMenu = (id: any) => {
    setSidebarMini(!sidebarMini)
    let ele = document.getElementById(id) as any
    ele.classList.add("animating")
    setTimeout(() => {
      ele.classList.remove("animating")
    }, 100)
  }

  return (
    <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
      <Link to='/dashboard' className='d-flex align-items-center'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/images/logos/default-dark.png')}
          className='h-50px app-sidebar-logo-default'
        />
        <span className='app-sidebar-logo-default ms-2 fw-bold text-white fs-1' style={{ letterSpacing: '1px' }}>iRMS</span>
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-sm h-30px w-30px rotate bg-rm',
            { active: appSidebarDefaultMinimizeDefault }
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
          onClick={e => toggleClassMenu('kt_app_sidebar')}
        >
          <i className='fas fa-angle-double-left fs-3 rotate-180' />
        </div>
      )}
    </div>
  )
}

export { SidebarLogo }

