import axios from 'axios'
import { getAuth } from '../../app/modules/auth';
export const ApiService = axios.create()

ApiService.defaults.baseURL = process.env.REACT_APP_API_URL;
ApiService.defaults.timeout = 120 * 1000

ApiService.interceptors.request.use(async (config: any) => {
  const auth = getAuth() as any
  if (auth && auth.token) {
    config.headers.Authorization = `bearer ${auth.token}`
    config.headers['Access-Control-Max-Age'] = 86400
  }
  return config;
}, (error) => Promise.reject(error));

ApiService.interceptors.response.use(
  response => response,
  async (error) => {
    let status = error.request.status
    let message = JSON.parse(error.request.response).message
    if (status == 403) {
      // window.location.href = `/error/403?message=${message}`
    }
    return Promise.reject(error);
  }
);

export const getCookie = (name: string) => {
  const nameLenPlus = (name.length + 1);
  return document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
    .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null;
}

export const setCookie = (name: string, value: string, days: number) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
  return 0
}

export const removeCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  return 0
}