import { useQuery } from "react-query";
import { isUUID } from "../../../theme/helpers";
import { ApiService } from "../../../theme/helpers/ApiService";

type IApiType = 'users'
    | 'objects'
    | 'companyinformations'
    | 'permissions'
    | 'permissionsets'
    | 'emailtemplates'
    | 'emailsetups'
    | 'approvalusersetups'
    | 'accesscontrols'
    | 'noseries'
    | 'noserilines'
    | 'requireddocuments'
    | 'objectcustomfields'
    | 'approvalpolicies'
    | 'approvals'
    | 'listurlsforemailtemplate'
    | 'merchants'
    | 'selfservicesetups'
    | 'printerlists'
    | 'storetiers'
    | 'menustructuretiers'
    | 'timebasedmenutiers'
    | 'dimensions'
    | 'dimensionvalues'
    | 'usergroups'
    | 'usergroupmembers'

const fetchQuery = async (name: IApiType, query?: string) => {
    const isuuid = query && isUUID(query)
    const res = await ApiService.get(`/settings/${name}${isuuid ? `/${query}` : (query || '')}`);
    return res.data;
}

export const apiAd = {
    useCompanyInformations() {
        return useQuery(['ad-companyinformations'], () => fetchQuery('companyinformations'))
    },
    useCompanyInformationsId<I>(id?: string) {
        return useQuery<I>(['ad-companyinformations', id], () => fetchQuery('companyinformations', id), { enabled: !!id })
    },
    useObjects() {
        return useQuery(['ad-objects'], () => fetchQuery('objects'))
    },
    useObjectsByQuery(query?: string) {
        return useQuery(['ad-objects', query], () => fetchQuery('objects', query), { enabled: !!query })
    },
    useObjectsByNumber(number?: string) {
        return useQuery(['ad-objects', number], () => fetchQuery('objects', `?objectid=${number}`), { enabled: !!number })
    },
    useObjectsId<I>(id?: string) {
        return useQuery<I>(['ad-objects', id], () => fetchQuery('objects', id), { enabled: !!id })
    },
    useUsers() {
        return useQuery(['ad-users'], () => fetchQuery('users'))
    },
    useUsersByQuery(query?: string) {
        return useQuery(['ad-users', query], () => fetchQuery('users', query), { enabled: !!query })
    },
    useUsersId<I>(id?: string) {
        return useQuery<I>(['ad-users', id], () => fetchQuery('users', id), { enabled: !!id })
    },
    usePermissions() {
        return useQuery(['ad-permissions'], () => fetchQuery('permissions'))
    },
    usePermissionsByQuery(query?: string) {
        return useQuery(['ad-permissions', query], () => fetchQuery('permissions', query), { enabled: !!query })
    },
    usePermissionsId<I>(id?: string) {
        return useQuery<I>(['ad-permissions', id], () => fetchQuery('permissions', id), { enabled: !!id })
    },
    useEmailSetups() {
        return useQuery(['ad-emailsetups'], () => fetchQuery('emailsetups'))
    },
    useEmailSetupsId<I>(id?: string) {
        return useQuery<I>(['ad-emailsetups', id], () => fetchQuery('emailsetups', id), { enabled: !!id })
    },
    usePermissionSets() {
        return useQuery(['ad-permissionsets'], () => fetchQuery('permissionsets'))
    },
    usePermissionSetsId<I>(id?: string) {
        return useQuery<I>(['ad-permissionsets', id], () => fetchQuery('permissionsets', id), { enabled: !!id })
    },
    useApprovalusersetups() {
        return useQuery(['ad-approvalusersetups'], () => fetchQuery('approvalusersetups'))
    },
    useApprovalusersetupsByQuery(query?: string) {
        return useQuery(['ad-approvalusersetups', query], () => fetchQuery('approvalusersetups', query), { enabled: !!query })
    },
    useApprovalusersetupsId<I>(id?: string) {
        return useQuery<I>(['ad-approvalusersetups', id], () => fetchQuery('approvalusersetups', id), { enabled: !!id })
    },
    useAccessControls() {
        return useQuery(['ad-accesscontrols'], () => fetchQuery('accesscontrols'))
    },
    useAccessControlsByQuery(query?: string) {
        return useQuery(['ad-accesscontrols', query], () => fetchQuery('accesscontrols', query), { enabled: !!query })
    },
    useAccessControlsId<I>(id?: string) {
        return useQuery<I>(['ad-accesscontrols', id], () => fetchQuery('accesscontrols', id), { enabled: !!id })
    },
    useNoSeries() {
        return useQuery(['ad-noseries'], () => fetchQuery('noseries'))
    },
    useNoSeriesId<I>(id?: string) {
        return useQuery<I>(['ad-noseries', id], () => fetchQuery('noseries', id), { enabled: !!id })
    },
    useNoSeriLines() {
        return useQuery(['ad-noserilines'], () => fetchQuery('noserilines'))
    },
    useNoSeriLinesByQuery(query?: string) {
        return useQuery(['ad-noserilines', query], () => fetchQuery('noserilines', query), { enabled: !!query })
    },
    useNoSeriLinesId<I>(id?: string) {
        return useQuery<I>(['ad-noserilines', id], () => fetchQuery('noserilines', id), { enabled: !!id })
    },
    useEmailTemplates() {
        return useQuery(['ad-emailtemplates'], () => fetchQuery('emailtemplates'))
    },
    useEmailTemplatesId<I>(id?: string) {
        return useQuery<I>(['ad-emailtemplates', id], () => fetchQuery('emailtemplates', id), { enabled: !!id })
    },
    useRequiredDocuments() {
        return useQuery(['ad-requireddocuments'], () => fetchQuery('requireddocuments'))
    },
    useRequiredDocumentsId<I>(id?: string) {
        return useQuery<I>(['ad-requireddocuments', id], () => fetchQuery('requireddocuments', id), { enabled: !!id })
    },
    useRequiredDocumentsByQuery(query?: string) {
        return useQuery(['ad-requireddocuments', query], () => fetchQuery('requireddocuments', query), { enabled: !!query })
    },
    useObjectCustomFieldsByObjectId(objectid?: string) {
        return useQuery(['ad-objectcustomfields', objectid], () => fetchQuery('objectcustomfields', `?objectid=${objectid}`), { enabled: !!objectid })
    },
    useObjectCustomFields() {
        return useQuery(['ad-objectcustomfields'], () => fetchQuery('objectcustomfields'))
    },
    useApprovalPolicies() {
        return useQuery(['ad-approvalpolicies'], () => fetchQuery('approvalpolicies'))
    },
    useApprovals() {
        return useQuery(['ad-approvals'], () => fetchQuery('approvals'))
    },
    useApprovalsId<I>(id?: string) {
        return useQuery<I>(['ad-approvals', id], () => fetchQuery('approvals', `/${id}?iseditedcheck=true`), { enabled: !!id })
    },
    useApprovalsByQuery(query?: string) {
        return useQuery(['ad-approvals'+query, query], () => fetchQuery('approvals', query), { enabled: !!query })
    },
    useListFieldsOfObject(objectname?: string) {
        return useQuery(['ad-listfieldsofobject', objectname], () => ApiService.get(`utilities/listfieldsofobject?objectname=${objectname}`).then(res => res.data), { enabled: !!objectname })
    },
    useListUrlsForEmailTemplate() {
        return useQuery(['ad-listemailsystemfields'], () => ApiService.get(`utilities/listemailsystemfields`).then(res => res.data))
    },
    useSelfServiceSetups() {
        return useQuery(['ad-selfservicesetups'], () => fetchQuery('selfservicesetups'))
    },
    usePrinterListsByQuery(query?: string) {
        return useQuery(['ad-printerlists', query], () => fetchQuery('printerlists', query), { enabled: !!query })
    },
    useStoreTiers() {
        return useQuery(['ad-storetiers'], () => fetchQuery('storetiers'))
    },
    useStoreTiersByID<I>(id?: string) {
        return useQuery<I>(['ad-storetiers', id], () => fetchQuery('storetiers', id), { enabled: !!id })
    },
    useMenuStructureTiers() {
        return useQuery(['ad-menustructuretiers'], () => fetchQuery('menustructuretiers'))
    },
    useMenuStructureTiersByID<I>(id?: string) {
        return useQuery<I>(['ad-menustructuretiers', id], () => fetchQuery('menustructuretiers', id), { enabled: !!id })
    },
    useTimeBasedMenuTiers() {
        return useQuery(['ad-timebasedmenutiers'], () => fetchQuery('timebasedmenutiers'))
    },
    useTimeBasedMenuTiersByID<I>(id?: string) {
        return useQuery<I>(['ad-timebasedmenutiers', id], () => fetchQuery('timebasedmenutiers', id), { enabled: !!id })
    },
    useBackups(){
        return useQuery(['ad-backups'], async () => await (await ApiService.get(`backup/db`)).data)
    },
    useDimensions(){
        return useQuery(['ad-dimensions'], () => fetchQuery('dimensions'))
    },
    useDimensionValues(query?: any){
        return useQuery(['ad-dimensionvalues'], () => fetchQuery('dimensionvalues',query ))
    },
    useUserGroups(){
        return useQuery(['ad-usergroups'], () => fetchQuery('usergroups', '?isincludeusers=true'))
    },
    useUserGroupsByID(id?: string){
        return useQuery(['ad-usergroups', id], () => fetchQuery('usergroups', id), { enabled: !!id })
    },
    useUserGroupMembers(){
        return useQuery(['ad-usergroupmembers'], () => fetchQuery('usergroupmembers'))
    },
    useUserGroupMembersByID(id?: string){
        return useQuery(['ad-usergroupmembers', id], () => fetchQuery('usergroupmembers', id), { enabled: !!id })
    },
    useUserGroupMembersByQuery(query?: string){
        return useQuery(['ad-usergroupmembers', query], () => fetchQuery('usergroupmembers', query), { enabled: !!query })
    },
}