import { yupResolver } from "@hookform/resolvers/yup"
import { FC, useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { Column } from "react-table"
import { ApiService, ApiShowError, changeDataModify, getValueId, isInt, sortByUpdateAt } from "../../../theme/helpers"
import { ContentHeader } from "../../../theme/layout/components/content"
import { BasicCell, CustomHeader, DivHr, FilterDropdown, FormSubmit, InputDate, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../theme/partials"
import { IInitField } from "../../modules/api"
import { apiAd } from "../../modules/api/admin"
import { popupMessage } from "../../modules/messages"
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap"
import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl";
import { MergedProps } from "../../router/AppRoutes"

interface IAdNoSeri extends IInitField {
    code: string | null
    noseriname: string | null
}

const apiurl = 'settings/noseries'
export default function AdNoSeriesPage({ keyElement, permission }: MergedProps) {
    const intl = useIntl()
    const { data, isSuccess, isFetching } = apiAd.useNoSeries()
    const [tableData, setTableData] = useState<IAdNoSeri[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell to={props.data[props.row.index].id} data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'noseriname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].noseriname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" permission={permission} to={props.data[props.row.index].id} />
            <RowIcon action="delete" permission={permission} onClick={async () => {
                const id = props.data[props.row.index].id
                const items = await ApiService.get(`settings/noserilines?noseriid=${id}`)
                const objects = await ApiService.get(`settings/objects?noseriid=${id}`)
                if (items.data.length || objects.data.length) return popupMessage({ icon: "error", description: intl.formatMessage({ id: `message.delete.dependencies-exist` }) })
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <ContentHeader title={keyElement} elements={<>
            <FilterDropdown> </FilterDropdown>
            <KTButton action='new' />
        </>} />
        <KTTable
            isLoading={isFetching}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search />
    </>
}

const defaultValues: IAdNoSeri = {
    code: null,
    noseriname: null
}

export const AdNoSeriesDetailPage = ({ keyElement, pathElement, permission }: MergedProps) => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching } = apiAd.useNoSeriesId<IAdNoSeri>(valueid)
    const { register, reset, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdNoSeri>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            noseriname: yup.string().required()
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching) reset(data)
    }, [isFetching])

    const refSubmit = useRef<HTMLButtonElement | null>(null)
    return <>
        <ContentHeader title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <div className="card">
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        permission={permission}
                        isSubmitting={isSubmitting}
                        handleSubmit={() => refSubmit.current?.click()}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <form className="col-12"
                        onSubmit={handleSubmit(async (data: IAdNoSeri) => {
                            try {
                                const method = isEdit ? 'put' : 'post'
                                const result = await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                                if (id == 'new' && result?.data?.id) navigate(`${pathElement}/${result.data.id}`, { replace: true })
                                popupMessage({ icon: 'success', autoClose: true })
                                navigate(pathElement)
                            } catch (error) {
                                ApiShowError(error)
                            }
                        })}>
                        <div className="row justify-content-between">
                            <div className="col-12 col-lg-6">
                                <KTFormItem title='text.id'>
                                    <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                                </KTFormItem>
                            </div>
                            <div className="col-12 col-lg-6">
                                <KTFormItem title='text.name'>
                                    <input className={`form-control form-control-sm ${errors.noseriname && 'form-error'}`} {...register('noseriname')} />
                                </KTFormItem>
                            </div>
                            <button ref={refSubmit} type="submit" className="d-none" />
                        </div>
                    </form>
                    {isEdit && <div className="col-12">
                        <DivHr />
                        <AdNoSeriesLineCard noseries={data} permission={permission} />
                    </div>}
                </div>
            </div>
        </div>
    </>
}

interface IAdNoSeriLine extends IInitField {
    noseriid: string | null
    code: string | null
    startdate: string | null
    startnumber: string | null
    endnumber: string | null
    lastdateused: string | null
    lastnumberused: string | null
    warningnumber: string | null
    allowgap: boolean
    allowmanual: boolean
    gap: number | null
    prefix: string | null
}

interface IAdNoSeriesLineCard {
    noseries: IAdNoSeri | undefined
    permission?: string
}

const AdNoSeriesLineCard: FC<IAdNoSeriesLineCard> = ({ noseries, permission }) => {
    const { data, isSuccess, isFetching } = apiAd.useNoSeriLinesByQuery(noseries && noseries.id ? `?noseriid=${noseries.id}` : undefined)
    const [tableData, setTableData] = useState<IAdNoSeriLine[]>([])
    const [valueData, setValueData] = useState<IAdNoSeriLine | undefined>()

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.started-date' />,
        accessor: 'startdate',
        Cell: ({ ...props }) => <BasicCell type="date" data={props.data[props.row.index].startdate} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.no-seri.start-no' />,
        accessor: 'startnumber',
        Cell: ({ ...props }) => <BasicCell data={
            props.data[props.row.index].prefix && props.data[props.row.index].startnumber
            ? props.data[props.row.index].prefix + props.data[props.row.index].startnumber : ''
        } />,
    }, /* {
        Header: (props) => <CustomHeader tableProps={props} title='text.no-seri.end-no' />,
        accessor: 'endnumber',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].endnumber} />,
    },  */{
        Header: (props) => <CustomHeader tableProps={props} title='text.no-seri.last-number-used' />,
        accessor: 'lastnumberused',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].lastnumberused} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.last-date-used' />,
        accessor: 'lastdateused',
        Cell: ({ ...props }) => <BasicCell type="date" data={props.data[props.row.index].lastdateused} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" permission={permission} onClick={() => setValueData(props.data[props.row.index])} />
            <RowIcon action="delete" permission={permission} onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`settings/noserilines/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <div className="d-flex flex-stack align-items-center">
            <div className="fs-4"><FormattedMessage id="text.no-seri.lines" /></div>
            <KTButton action='new' permission={permission} disabled={!noseries || !noseries.id} onClick={() => {
                if (noseries && noseries.id)
                    setValueData({
                        noseriid: noseries.id,
                        code: noseries.code,
                        startdate: null,
                        startnumber: null,
                        endnumber: null,
                        lastdateused: null,
                        lastnumberused: null,
                        warningnumber: null,
                        allowgap: true,
                        allowmanual: false,
                        gap: 1,
                        prefix: null
                    })
            }} />
        </div>
        <KTTable
            data={tableData}
            columns={columns}
        />
        {valueData && <AdNoSeriesLineModal {...{
            permission,
            valueData,
            handleClose() { setValueData(undefined) },
            handleSave(type, value) {
                if (type) return setTableData(pre => pre.map(item => item.id == value.id ? value : item))
                setTableData([value, ...tableData])
            },
        }} />}
    </>
}

interface IAdNoSeriesLineModal {
    permission?: string
    valueData: IAdNoSeriLine | undefined
    handleClose: () => void
    handleSave: (type: boolean, value: IAdNoSeriLine) => void
}

const AdNoSeriesLineModal: FC<IAdNoSeriesLineModal> = ({ permission, valueData, handleClose, handleSave }) => {
    const { isEdit, valueid } = getValueId(valueData?.id)
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdNoSeriLine>({
        resolver: yupResolver(yup.object().shape({
            noseriid: yup.string().required(),
            startdate: yup.string().required(),
            prefix: yup.string().required(),
            startnumber: yup.string().test((val, ctx) => {
                if (!val || (val && !isInt(Number(val)))) return ctx.createError()
                return true
            }).required(),
        }))
    })

    useEffect(() => {
        if (valueData)
            reset(valueData)
    }, [valueData])

    return <Modal show={valueData != undefined} onHide={handleClose} centered size="lg">
        <ModalHeader>
            <ModalTitle><FormattedMessage id="text.no-seri.lines" /></ModalTitle>
        </ModalHeader>
        <ModalBody>
            <form onSubmit={handleSubmit(async (data: IAdNoSeriLine) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    const result = await ApiService[method](`settings/noserilines${isEdit ? `/${valueid}` : ''}`, data)
                    handleSave(isEdit, result.data)
                    popupMessage({ icon: 'success', autoClose: true })
                } catch (error) { ApiShowError(error) }
                handleClose()
            })}>
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.started-date'>
                            <Controller
                                name="startdate"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputDate
                                    options={{ static: true }}
                                    value={value}
                                    onChange={(e) => {
                                        if (!e.length) return onChange(null)
                                        onChange(e[0].toISOString())
                                    }}
                                    className={`${errors.startdate && 'form-error'}`}
                                />}
                            />
                        </KTFormItem >
                        <KTFormItem title="text.no-seri.start-no">
                            <div className="d-flex gap-2 w-100">
                                <div>
                                    <label className="text-gray-600 fs-7">Prefix</label>
                                    <input
                                        className={`form-control form-control-sm min-w-100px mw-100px ${errors.prefix && 'form-error'}`}
                                        maxLength={10}
                                        value={watch('prefix') || ''}
                                        onChange={(evt) => {
                                            setValue('prefix', evt.target.value)
                                        }}
                                    />
                                </div>
                                <div>
                                    <label className="text-gray-600 fs-7">Start with</label>
                                    <input
                                        className={`form-control form-control-sm ${errors.startnumber && 'form-error'}`}
                                        maxLength={10}
                                        value={watch('startnumber') || ''}
                                        onChange={(evt) => {
                                            setValue('startnumber', `${evt.target.value}`)
                                        }}
                                    />
                                </div>
                            </div>
                        </KTFormItem>
                        {/* <KTFormItem title="text.no-seri.end-no">
                            <input className={`form-control form-control-sm ${errors.endnumber && 'form-error'}`} {...register('endnumber')} />
                        </KTFormItem> */}
                        <KTFormItem title='text.last-date-used'>
                            <Controller
                                name="lastdateused"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputDate
                                    value={value}
                                    onChange={(e) => {
                                        if (!e.length) return onChange(null)
                                        onChange(e[0].toISOString())
                                    }}
                                    className={`${errors.lastdateused && 'form-error'}`}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title="text.no-seri.last-number-used">
                            <input className={`form-control form-control-sm ${errors.lastnumberused && 'form-error'}`} {...register('lastnumberused')} />
                        </KTFormItem>
                        {/* <KTFormItem title="text.no-seri.warning-number">
                            <input className={`form-control form-control-sm ${errors.warningnumber && 'form-error'}`} {...register('warningnumber')} />
                        </KTFormItem> */}
                    </div>
                    <div className="col-12 col-lg-6">
                        {/* <KTFormItem title='text.allow-manual'>
                            <label className="form-check form-switch form-check-custom form-check-solid gap-2">
                                <input className="form-check-input" type="checkbox" {...register("allowmanual")} />
                            </label>
                        </KTFormItem> */}
                        <KTFormItem title='text.allow-gap'>
                            <label className="form-check form-switch form-check-custom form-check-solid gap-2">
                                <input className="form-check-input" type="checkbox" {...register("allowgap", {
                                    onChange(event) {
                                        if (!event.target.target) setValue("gap", null)
                                        return event;
                                    },
                                })} />
                            </label>
                        </KTFormItem>
                        <KTFormItem title='text.gap'>
                            <input className={clsx(`form-control form-control-sm`, { 'form-error': errors.gap })}
                                disabled={!watch("allowgap")}
                                {...register('gap')}
                                type="number" />
                        </KTFormItem>
                    </div>
                    <div className="col-12 align-items-end d-flex flex-stack">
                        <TextFormByUser data={watch()} />
                        <FormSubmit
                            type={isEdit}
                            permission={permission}
                            isSubmitting={isSubmitting}
                            handleClose={handleClose} />
                    </div>
                </div>
            </form>
        </ModalBody>
    </Modal>
}