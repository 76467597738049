/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const AuthLayout = () => {

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) root.style.height = '100%'
    return () => {
      if (root) root.style.height = 'auto'
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row h-100 flex-center bg-auth'>
      {/* begin::Body */}
      <div className='d-flex flex-column w-lg-50 p-5 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px bg-body rounded mx-auto'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { AuthLayout }

