import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Column } from "react-table";
import { sortByUpdateAt, syncObjects } from "../../../../theme/helpers";
import { ApiService } from "../../../../theme/helpers/ApiService";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { BasicCell, CustomHeader, InputSelect, KTButton, KTFormItem, RowIcon } from "../../../../theme/partials";
import { FilterDropdown } from "../../../../theme/partials/layout/filtter-dropdown";
import { KTTable } from "../../../../theme/partials/widgets/table";
import { apiPrivate } from "../../../modules/api";
import { optionAddress } from "../../../modules/api/address";
import { IInitFieldAddress } from "../../../modules/api/type";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";
import { optionStoreType } from "./detail";

export interface IStore extends IInitFieldAddress {
    storetype: string | null
    code: string | null
    storename: string | null
    locationcode: string | null
    phoneno: string | null
    email: string | null
    managerid: string | null
    managername: string | null
    openhours: string | null
    closedhours: string | null
    latitude: string | null
    longitude: string | null
    status: boolean
    vatbuspostinggroup?: any
}

const urlApi = 'settings/stores'
export function SettingStores ({ keyElement, permission }: MergedProps) {
    const { data, isSuccess, isFetching, refetch } = apiPrivate.useStores()
    const [tableData, setTableData] = useState<IStore[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'storename',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].storename} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.location.id' />,
        accessor: 'locationcode',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].locationcode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.address' />,
        accessor: 'address',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].address} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" to={props.data[props.row.index].id} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [filter, setFilter] = useState<IStore | undefined>()
    const { register, watch, setValue, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: IStore[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                !!filter.storetype ? f.storetype == filter.storetype : true,
                !f.status == !filter.status,
                !!filter.country ? f.country == filter.country : true,
                !!filter.province ? f.province == filter.province : true
            ]
            return checks.every(val => val == true)
        })
    }

    const { data: countriesData, isSuccess: isCountries } = optionAddress.useCountries()
    const optionCountries = isCountries ? optionAddress.countries(countriesData) : []

    const { data: provincesData, isSuccess: isProvinces } = optionAddress.useProvincesByQuery(watch('country'))
    const optionProvinces = isProvinces ? optionAddress.provinces(provincesData) : []

    const toolbar = <>
        <FilterDropdown
            handleSubmit={handleSubmit}
            onClose={() => {
                setFilter(undefined)
                reset({})
            }}
            onSubmit={(data) => setFilter(data)}>
            <KTFormItem title='Loại' isBLockLabel>
                <Controller
                    name="storetype"
                    control={control}
                    render={({ field: { value, onChange } }) => <InputSelect
                        options={optionStoreType}
                        value={optionStoreType.find(f => f.value == value) || null}
                        onChange={(e) => {
                            if (!e) return onChange(null)
                            onChange(e.value)
                        }}
                    />}
                />
            </KTFormItem>
            <KTFormItem title='text.status' labelWidth="auto">
                <label className="form-check form-switch form-check-custom form-check-solid">
                    <input className="form-check-input" type="checkbox" {...register("status")} />
                </label>
            </KTFormItem>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <KTFormItem title='text.country' isBLockLabel>
                        <Controller
                            name="country"
                            control={control}
                            render={({ field: { value, onChange } }) => <InputSelect
                                isClearable
                                options={optionCountries}
                                value={optionCountries.find(f => String(f.value) == value) || null}
                                onChange={(e) => {
                                    setValue('province', null)
                                    if (!e) return onChange(null)
                                    onChange(e.value)
                                }}
                                className="w-100"
                            />}
                        />
                    </KTFormItem>
                </div>
                <div className="col-12 col-lg-6">
                    <KTFormItem title='text.province' isBLockLabel>
                        <Controller
                            name={'province'}
                            control={control}
                            render={({ field: { value, onChange } }) => <InputSelect
                                isClearable
                                options={optionProvinces}
                                value={optionProvinces.find(f => String(f.value) == value) || null}
                                onChange={(e) => {
                                    if (!e) return onChange(null)
                                    onChange(e.value)
                                }}
                                className="w-100"
                            />}
                        />
                    </KTFormItem>
                </div>
            </div>
        </FilterDropdown>
        <KTButton permission={permission} action="sync" onClick={() => syncObjects('/bcsync/stores', refetch)} />
    </>

    return <>
        <ContentHeader title={keyElement} elements={toolbar} />
        <KTTable
            isLoading={isFetching}
            data={onFilter(tableData)}
            columns={columns}
            search
            pagination
            cardInnner
        />
    </>
}