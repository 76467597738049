import React from "react";
import { DragLayerMonitorProps } from "@minoru/react-dnd-treeview";
// import { TypeIcon } from "./TypeIcon";
// import styles from "./CustomDragPreview.module.css";

type Props = {
  monitorProps: any;
};

export const CustomDragPreview: React.FC<Props> = (props) => {
  const item = props.monitorProps.item;

  return (
    <div className={''}>
      <div className={''}>
        {/* <TypeIcon droppable={item.droppable || false} /> */}
      </div>
      <div className={''}>{item.text}</div>
    </div>
  );
};
