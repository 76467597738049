import { FC } from 'react'
import { WithChildren } from '../../../theme/helpers'

const ErrorsLayout: FC<WithChildren> = ({ children }) => {

  return (
    <div className='d-flex flex-column flex-root bg-auth'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        {children}
      </div>
    </div>
  )
}

export { ErrorsLayout }

