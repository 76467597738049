import clsx from "clsx"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ListGroup, Modal } from "react-bootstrap"
import { UseFormSetValue } from "react-hook-form"
import InfiniteScroll from 'react-infinite-scroll-component'
import { FormattedMessage } from "react-intl"
import { useAsyncDebounce } from "react-table"
import { InputTag } from "../widgets/form/InputTag"
import { KTSVG } from "../widgets/utils/KTSVG"
const { Document } = require("flexsearch");

interface PropsSearchPopup {
    title?: string
    isLoading?: boolean
    setValue?: UseFormSetValue<any>
    searchData: any
    keys: Array<Array<any>>
    filters?: string[]
    className?: string
    handleClose: () => void
    selfSave?: (values: any) => void
    isMulti?: boolean
    values?: string[]
    fieldname?: string
}

export function buildIndex(data: string[][]) {
    const index = new Document({
        tokenize: "full",
        charset: "latin:simple",
        minlength: 2,
        cache: true,
        context: false,
        document: {
            id: "id",
            index: [{
                field: "title",
            }, {
                field: "description",
            }],
            store: ["description", "title", 'id'],
            worker: true,
        },
    });
    for (const question of data) {
        index.add(question);
    }

    return index;
}

const SearchPopup: FC<PropsSearchPopup> = ({ fieldname, values, title, selfSave, isLoading, setValue, searchData, keys, handleClose, className, isMulti }) => {
    const [word, setWord] = useState<string>('')
    const [dataSelect, setDataSelect] = useState<string[]>([])
    const [hasMore, setHasMore] = useState<any>(true)
    const scrollParentRef = useRef(null)
    const isModalMuti: boolean = searchData && searchData.length > 0 && typeof fieldname == 'string' && searchData[0][fieldname] == searchData[0].title && isMulti

    const result = useMemo(
        () => {
            let data = JSON.parse(JSON.stringify(searchData));
            if (word) {
                return buildIndex(data).search(word, { pluck: ["title", "description"], limit: 500, enrich: true })
            }
            return []
        },
        [word, document, 500]
    );

    const dataSource = result.map((item: any) => {
        return {
            key: item.doc.id,
            title: item.doc.title,
            description: item.doc.description,
            id: item.id,
        }
    })

    const [dataRecords, setDataRecords] = useState<any>([])
    const inputRef = useRef<HTMLInputElement | null>(null)

    useEffect(function () {
        function getData() {
            if (inputRef.current != document.activeElement) {
                inputRef.current?.focus()
            }
            setWord("")
            setDataSelect(values || [])
            let tempData = JSON.parse(JSON.stringify(searchData))
            if (isModalMuti && fieldname) {
                let temps: any[] = []
                tempData = tempData.filter((e: any) => {
                    if (temps.includes(e[fieldname]) || !e[fieldname]) {
                        return false
                    } else {
                        temps.push(e[fieldname])
                        return true
                    }
                });
            }
            setDataRecords(tempData.filter((item: any, index: number) => index < 80))
        }
        getData()
    }, [keys])

    const debounceDropDown = useCallback(useAsyncDebounce(value => setWord(value), 200), [])

    function handleInputOnchange(event: React.FormEvent<HTMLInputElement>) {
        debounceDropDown(event.currentTarget.value)
    }

    function handleSearchSelect(id: string) {
        if (isMulti) {
            if (!dataSelect.includes(id)) {
                return setDataSelect([id, ...dataSelect])
            }
            return setDataSelect(dataSelect.filter(f => f != id))
        }
        handleClose()
        const items: any = {}
        const value = searchData.find((e: any) => e.id == id)
        keys.forEach(key => {
            const fieldValue = (key.length == 1) ? key[0] : key[1]
            if (value && setValue) {
                !selfSave && setValue(key[0], value[fieldValue]);
                items[key[0]] = value[fieldValue]
            }
        })
        if (selfSave) selfSave(items)
    }

    function handleSaveSearchMulti() {
        if (selfSave && fieldname) {
            selfSave(searchData.filter((f: any) => dataSelect.includes(f[fieldname])))
        } else if (keys[0][0] && fieldname && setValue) {
            setValue(keys[0][0], dataSelect.join(','));
        }
        handleClose()
    }

    const fetchItems = () => {
        if (word) {
            return
        }
        let tempData = JSON.parse(JSON.stringify(searchData))
        let tempRecords = JSON.parse(JSON.stringify(dataRecords))
        const lengthLoad = document.querySelector('.infinite-scroll-component')?.children.length || tempRecords.length
        setTimeout(() => {
            let dataLoad = tempData.filter((item: any, index: number) => index <= lengthLoad + 1000)
            setDataRecords(dataLoad)
        }, 300);

    }

    const rendertemplateItem = () => {
        let dataRender = dataRecords
        if (word) {
            dataRender = dataSource
        }
        if (isModalMuti && fieldname) {
            dataRender = dataRender.filter((e: any) => e[fieldname] != undefined)
        }
        return dataRender?.map((item: any, index: number) => <ListGroup.Item
            as={'li'}
            key={index}
            className={clsx("d-flex justify-content-between align-items-start border shadow-sm rounded cursor-pointe ps-0 py-0", {
                'bg-hover-light': !dataSelect.includes(item.id),
            })}
            onClick={() => handleSearchSelect(fieldname ? item[fieldname] : item.id)}>
            <div className={clsx("me-auto min-h-35px d-flex gap-2 border-start border-3", {
                'border-primary': isModalMuti && fieldname && dataSelect.includes(item[fieldname])
            })}>
                <div className="py-3 ps-3">
                    <div className="fs-5 fw-bold">{item.title}</div>
                    <span>
                        {Array.isArray(item.description)
                            ? item.description.join('-')
                            : item.description}
                    </span>
                </div>
            </div>
        </ListGroup.Item>)
    }

    useEffect(() => {
        setWord('')
    }, [dataSelect])

    return <Modal centered show={keys.length != 0} onHide={handleClose} className={clsx(className)} >
        <Modal.Body className="rounded px-0 py-6">
            <div className='d-flex align-items-center position-relative px-6'>
                {isModalMuti && fieldname != undefined
                    ? <InputTag
                        showRemove={true}
                        className="border border-2 border-primary"
                        size="normal"
                        value={dataSelect}
                        onChange={(e) => {
                            setDataSelect(dataRecords
                                .filter((f: any) => e.includes(f[fieldname]))
                                .map((m: any) => m[fieldname]))
                        }}
                        inputComponent={<input
                            className="form-control form-control-sm border-0 p-0 d-inline-block"
                            ref={inputRef}
                            onChange={handleInputOnchange}
                            type='text'
                            placeholder='Search ...' />}
                    />
                    : <>
                        <KTSVG className='svg-icon-1 position-absolute ms-6 svg-icon-primary' path='/media/icons/duotune/general/gen021.svg' />
                        <input
                            ref={inputRef}
                            onChange={handleInputOnchange}
                            type='text'
                            className={clsx('form-control border border-2 border-primary ps-14')}
                            placeholder='Search ...'
                        />
                    </>}
            </div>
            <div className={clsx(isMulti ? 'my-2' : 'mb-6')}>
                {isModalMuti && fieldname != undefined && <div className="d-flex align-items-center h-100 flex-stack px-6 h-25px">
                    {title && <div className="text-primary fw-bolder"> {title} </div>}
                    <div className="form-check form-check-custom form-check-sm d-inline">
                        <input
                            type="checkbox"
                            className="form-check-input d-none"
                            value={'all'}
                            checked={dataSelect.length == dataRecords.length}
                            id="check_all"
                            onChange={e => {
                                if (dataSelect.length != dataRecords.length) {
                                    let list: string[] = []
                                    dataRecords.forEach((item: any) => {
                                        if (!list.includes(item[fieldname])) {
                                            list.push(item[fieldname])
                                        }
                                    })
                                    return setDataSelect(list)
                                }
                                setDataSelect([])
                            }} />
                        <label htmlFor="check_all" className={clsx("cursor-pointer bg-white py-1 px-2 rounded fw-bold", {
                            'text-primary': dataSelect.length == dataRecords.length && dataSelect.length != 0
                        })}>Check All ({dataSelect.length})</label>
                    </div>
                </div>}
            </div>
            <div id="scrollableDiv" className="hover-scroll h-400px my-4 border">
                <InfiniteScroll
                    dataLength={dataRecords.length}
                    next={fetchItems}
                    hasMore={hasMore}
                    loader={<div></div>}
                    scrollableTarget="scrollableDiv"
                >
                    {isLoading
                        ? <div className="">Loading...</div>
                        : dataRecords?.length != 0
                            ? <ListGroup as='ol' variant="flush" className="gap-3 py-3 mx-6">
                                {rendertemplateItem()}
                            </ListGroup>
                            : <div className="mx-6">Không có dữ liệu</div>}
                </InfiniteScroll>
            </div>
            <div className="d-flex align-items-center gap-3 px-6">
                <span className="me-auto">Total items: {searchData?.length}</span>
                {isMulti && <>
                    <button className="btn btn-sm btn-secondary" onClick={e => handleClose()}><FormattedMessage id="button.cancel"/></button>
                    <button className="btn btn-sm btn-primary" onClick={e => handleSaveSearchMulti()}><FormattedMessage id="button.save"/></button>
                </>}
            </div>
        </Modal.Body>
    </Modal>
}

export default SearchPopup;