import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { Column } from "react-table"
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId, sortByUpdateAt } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, FilterDropdown, FormSubmit, InputSelect, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../theme/partials"
import { IInitField } from "../../../modules/api"
import { apiAd } from "../../../modules/api/admin"
import { apiFnb } from "../../../modules/api/fnb"
import { popupMessage } from "../../../modules/messages"
import { MergedProps } from "../../../router/AppRoutes"

export interface IConfigTimeBasedMenuTier extends IInitField {
    code: string | null
    name: string | null
    description: string | null
    active: boolean
    timebasedmenuid: string | null
    timebasedmenucode: string | null
}

const defaultValues: IConfigTimeBasedMenuTier = {
    code: null,
    name: null,
    description: null,
    active: false,
    timebasedmenuid: null,
    timebasedmenucode: null
}

export function ConfigTimeBasedMenuTier({ keyElement, permission }: MergedProps) {
    const urlApi = 'settings/timebasedmenutiers'
    const [tableData, setTableData] = useState<IConfigTimeBasedMenuTier[]>([])
    const [valueData, setValueData] = useState<IConfigTimeBasedMenuTier | undefined>()
    const { data: dataQuery, isSuccess, isFetching, refetch } = apiAd.useTimeBasedMenuTiers()
    const { data: dataTimeBasedMenus, isSuccess: isTimeBasedMenus } = apiFnb.useTimeBasedMenus()
    const optionTimeBasedMenus = isTimeBasedMenus ? formatOptions(dataTimeBasedMenus, ['id', 'code', 'name']) : []

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(dataQuery))
    }, [isFetching])

    const columns: Array<Column<IConfigTimeBasedMenuTier>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => setValueData(props.data[props.row.index])}
            data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.time-based-menu' />,
        accessor: 'timebasedmenucode',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].timebasedmenucode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.active' />,
        accessor: 'active',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].active} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" onClick={() => setValueData(props.data[props.row.index])} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [filter, setFilter] = useState<IConfigTimeBasedMenuTier | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: IConfigTimeBasedMenuTier[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                f.active == filter.active,
            ]
            return checks.every(val => val == true)
        })
    }

    const toolbar = <>
        <FilterDropdown
            handleSubmit={handleSubmit}
            onClose={() => {
                setFilter(undefined)
                reset({})
            }}
            onSubmit={(data) => setFilter(data)}>
            <KTFormItem title='text.active' labelWidth="auto">
                <label className="form-check form-switch form-check-custom">
                    <input className="form-check-input" type="checkbox" {...register("active")} />
                </label>
            </KTFormItem>
        </FilterDropdown>
        <KTButton permission={permission} action="new" onClick={() => setValueData(defaultValues)} />
    </>

    return <>
        <ContentHeader title={keyElement} elements={toolbar} />
        <KTTable
            isLoading={isFetching}
            data={onFilter(tableData)}
            columns={columns}
            search
            pagination
            cardInnner
        />
        <ConfigTimeBasedMenuTierModal {...{
            optionTimeBasedMenus,
            valueData,
            tableData,
            handleSave: async function (values: any, isEdit: boolean) {
                try {
                    const method = isEdit ? 'put' : 'post'
                    const result = await ApiService[method](`${urlApi}${isEdit ? `/${values.id}` : ''}`, changeDataModify(values))
                    setTableData(isEdit ? tableData.map(m => m.id == values.id ? values : m) : [result, ...tableData])
                    popupMessage({ icon: 'success', autoClose: true })
                    setValueData(undefined)
                } catch (error) { ApiShowError(error) }
            },
            handleClose: function () {
                setValueData(undefined)
            }
        }} />
    </>
}

export function ConfigTimeBasedMenuTierModal(props: any) {
    const { isEdit } = getValueId(props.valueData?.id)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IConfigTimeBasedMenuTier>({
        resolver: yupResolver(yup.object().shape({
            code: yup.string().test('unique', (val, ctx) => {
                if (val && val != props.valueData?.code && props.tableData.map((m: any) => m.code).includes(val)) return ctx.createError()
                return true
            }).required(),
            name: yup.string().required(),
            timebasedmenuid: yup.string().required()
        })),
    })

    useEffect(() => {
        if (!!props.valueData) reset(props.valueData)
    }, [props.valueData])

    return <Modal show={props.valueData != undefined} onHide={props.handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{isEdit ? <FormattedMessage id="text.edit"/> : <FormattedMessage id="text.add"/>} <FormattedMessage id="text.time-based-menu-tier"/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit((data) => props.handleSave(data, isEdit))}>
                <KTFormItem title="text.id">
                    <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                </KTFormItem>
                <KTFormItem title='text.name'>
                    <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                </KTFormItem>
                <KTFormItem title='text.time-based-menu'>
                    <Controller
                        name="timebasedmenuid"
                        control={control}
                        render={({ field: { value, onChange } }) => <InputSelect
                            options={props.optionTimeBasedMenus}
                            value={props.optionTimeBasedMenus.find((f: any) => f.value == value) || null}
                            onChange={(e) => {
                                setValue('timebasedmenucode', null)
                                if (!e) return onChange(null)
                                onChange(e.value)
                                setValue('timebasedmenucode', e.code)
                            }}
                            className={clsx({ 'form-error': errors.timebasedmenuid })}
                        />}
                    />
                </KTFormItem>
                <KTFormItem title='text.description' isHeight>
                    <textarea className={`form-control form-control-sm ${errors.description && 'form-error'}`} {...register('description')} rows={3} />
                </KTFormItem>
                <KTFormItem title="text.active">
                    <label className="form-check form-check-sm form-check-custom">
                        <input className="form-check-input" type="checkbox" {...register('active')} />
                    </label>
                </KTFormItem>
                <div className="mt-6 d-flex flex-stack">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        hideCloseButton
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={props.permission}
                        handleClose={props.handleClose} />
                </div>
            </form>
        </Modal.Body>
    </Modal>
}