import { useQuery } from "react-query";
import { ApiService, sortByUpdateAt } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { BasicCell, CustomHeader, KTTable, RowIcon } from "../../../../theme/partials";
import { MergedProps } from "../../../router/AppRoutes";
import { Link } from "react-router-dom";

function StaffPermissionsGroup({ keyElement, permission }: MergedProps) {
    const { data: staffPermissionGroups } = useQuery([], () => ApiService.get(`settings/staffpermissiongroups`).then(res => res.data))
    const column = [{
        Header: (props: any) => <CustomHeader tableProps={props} title='text.code' />,
        accessor: 'code',
        Cell: ({ row: { original } }: any) => <BasicCell data={original.code} />
    }, {
        Header: (props: any) => <CustomHeader tableProps={props} title='text.description' />,
        accessor: 'description',
        Cell: ({ row: { original } }: any) => <BasicCell data={original.code} />
    }, {
        Header: (props: any) => <CustomHeader tableProps={props} title='text.actions' className="text-end" />,
        id: 'actions',
        Cell: ({ row: { original } }: any) => <div className="d-flex justify-content-end align-items-center flex-wrap gap-2">
            <RowIcon action="modify" to={original.id} />
            <RowIcon action="delete" onClick={() => deleteRow(original.id)} />
        </div>
    }]

    function deleteRow(id: string) {
        console.log(id)
    }

    return <>
        <ContentHeader title={keyElement} />
        <KTTable
            toolbar={<Link to={`new`} className="btn btn-sm btn-primary">+ Add Record</Link>}
            columns={column}
            data={sortByUpdateAt(staffPermissionGroups || [])}
            cardInnner
            pagination
            search
        />
    </>

}
export default StaffPermissionsGroup;