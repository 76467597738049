import clsx from 'clsx'
import { useRef } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { ShowForRole } from '../../../../app/modules/auth/Permission'

const itemClass = 'ms-1 ms-lg-3'
const btnClass = 'btn btn-sm btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary'
const userAvatarClass = 'symbol-30px symbol-md-35px'

const Navbar = () => {
  const { currentUser, logout } = useAuth()
  const textimage = currentUser?.fullname.split(" ").pop()?.charAt(0).toUpperCase()
  const ref = useRef<HTMLDivElement | null>(null);
  const buttonclass = 'btn btn-sm btn-link w-100 text-start'

  return (
    <div className='app-navbar flex-shrink-0'>

      <ShowForRole role={['SUPER', 'ADMIN']}>
        <div className={clsx('app-navbar-item', itemClass)}>
          <Link to={'/system-settings'} className={btnClass}>
            <i className="bi bi-gear fs-2 mt-1"></i>
          </Link>
        </div>
      </ShowForRole>

      <div className={clsx('app-navbar-item', itemClass)}>
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="bottom-end"
          overlay={<Popover className='fs-6 w-250px'>
            <Popover.Header className='border-bottom'>
              <div className='d-flex gap-4 align-items-center'>
                <div className='symbol symbol-40px'>
                  {currentUser?.avatar
                    ? <img alt='avatar' src={currentUser?.avatar} className="img-auto"/>
                    : <div className="symbol-label fs-2 text-success"> {textimage} </div>
                  }
                </div>
                <div className='d-flex flex-column one-row-ellipsis'>
                  <div className='fw-bolder d-flex align-items-center fs-5 one-row-ellipsis'>
                    {currentUser?.fullname}
                  </div>
                  <span className='text-muted fs-7 one-row-ellipsis'>
                    {currentUser?.authenticationemail}
                  </span>
                </div>
              </div>
            </Popover.Header>
            <Popover.Body className='fw-bold'>
              <div className='d-flex flex-column'>
                <Link className={buttonclass} to={'/account/profile'} onClick={(() => ref.current?.click())} >
                  <FormattedMessage id="header.avatar.my-profile" />
                </Link>
                <a
                  className={buttonclass}
                  onClick={() => {
                    ref.current?.click()
                    logout()
                  }}>
                  <FormattedMessage id="header.avatar.logout" />
                </a>
              </div>
            </Popover.Body>
          </Popover>}>
          <div ref={ref} className={clsx('cursor-pointer symbol', userAvatarClass)} >
            {currentUser?.avatar
              ? <img alt='avatar' src={currentUser?.avatar} className="img-auto" />
              : <div className="symbol-label fs-2 fw-bold text-success"> {textimage} </div>
            }
          </div>
        </OverlayTrigger>
      </div>
    </div>
  )
}

export { Navbar }

