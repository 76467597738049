import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Column } from "react-table";
import { IVendor } from ".";
import { ApiService, ApiShowError, changeDataModify, formatCurrent, formatOptions, getValueId, sortByUpdateAt, syncObjects } from "../../../../theme/helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { BasicCell, CustomHeader, ExtendAddressValue, FormSubmit, InputSelect, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../theme/partials";
import { apiPrivate, IInitFieldAddress } from "../../../modules/api";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";
import { apiAd } from "../../../modules/api/admin";

const defaultValues: IVendor = {
    number: null,
    vendorcode: null,
    vendorname: null,
    vendortype: null,
    email: null,
    phoneno: null,
    contact: null,
    blocked: null,
    paytovendorno: null,
    paymenttermscode: null,
    paymentmethodcode: null,
    vatregistrationno: null,
    balance: null,
    balanceascustomer: null,
    address: null,
    ward: null,
    district: null,
    province: null,
    country: null,
    zipcode: null,
    state: null
}

export const optionVendorsType = [
    { value: 'vendor', label: <FormattedMessage id="text.vendor" /> },
    { value: 'employee', label: <FormattedMessage id="text.vendor.employee" /> },
]

const urlApi = 'settings/vendors'
export const SettingVendorsForm = ({ keyElement, pathElement, permission }: MergedProps) => {
    const intl = useIntl()
    const navigate = useNavigate();
    const { id } = useParams()

    const { isEdit, valueid } = getValueId(id)
    const { data: dataQuery, isFetching, isSuccess, refetch } = apiPrivate.useVendorId<any>(valueid)
    const { data: dataUserSetup, isSuccess: isUserSetup, isFetching: loadUserSetup } = apiAd.useApprovalusersetupsByQuery(valueid ? `?vendorid=${valueid}` : undefined)
    const { data: dataUserSetups, isSuccess: isUserSetups } = apiAd.useApprovalusersetupsByQuery(`?nolinkedtovendor=true`)
    const optionUserSetups = isUserSetups && isUserSetup ? formatOptions([...dataUserSetup, ...dataUserSetups], ['id', 'username']) : []
    const { register, control, reset, watch, handleSubmit, setValue, formState: { errors, isSubmitting } } = useForm<IVendor>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            number: yup.string().required(),
            vendorname: yup.string().required(),
            vendortype: yup.string().required()
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching) {
            reset(value => ({ ...value, ...dataQuery }))
        }
    }, [isFetching])

    useEffect(() => {
        if (isUserSetup && !loadUserSetup) {
            const data = dataUserSetup[0]
            reset(value => ({ ...value, username: data?.username || null, usersetupid: data?.id }))
        }
    }, [loadUserSetup])

    return <>
        <ContentHeader
            isInfo
            title={keyElement}
            items={[{ title: keyElement, path: pathElement }]}
            elements={isEdit
                ? <KTButton
                    permission={permission}
                    action="sync"
                    onClick={() => syncObjects(`/bcsync/vendorbyvendorno?source=WEBSERVICE&vendorno=${dataQuery?.number}`, refetch)} />
                : <></>} />
        <form
            className="card mb-6"
            onSubmit={handleSubmit(async (data: IVendor) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    const result = await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify({ ...data, username: data.username || null }))
                    popupMessage({ icon: 'success', autoClose: true })
                    const val: IVendor = result.data
                    if (val) {
                        const body = { vendorid: val.id, vendorcode: val.number, vendorname: val.vendorname }
                        const bodynull = { vendorid: null, vendorcode: null, vendorname: null }
                        const { id, username } = dataUserSetup[0] || {}

                        if (data.username && data.username != username) {
                            id && await ApiService.put(`settings/approvalusersetups/${id}`, { ...bodynull })
                            data.usersetupid && await ApiService.put(`settings/approvalusersetups/${data.usersetupid}`, { ...body })
                        } else if (!data.username && dataUserSetup[0]?.username) {
                            await ApiService.put(`settings/approvalusersetups/${dataUserSetup[0].id}`, { ...bodynull })
                        }
                    }
                    navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.number && 'form-error'}`} {...register('number')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.vendorname && 'form-error'}`} {...register('vendorname')} />
                        </KTFormItem>
                        <ExtendAddressValue {...{
                            register,
                            control,
                            errors,
                            setValue,
                            watch
                        }} />
                        <KTFormItem title='text.phone'>
                            <input className={`form-control form-control-sm ${errors.phoneno && 'form-error'}`} {...register('phoneno')} />
                        </KTFormItem>
                        <KTFormItem title='text.email'>
                            <input className={`form-control form-control-sm ${errors.email && 'form-error'}`} {...register('email')} />
                        </KTFormItem>
                        <KTFormItem title='text.contact.name'>
                            <input className={`form-control form-control-sm ${errors.contact && 'form-error'}`} {...register('contact')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.type'>
                            <Controller
                                name="vendortype"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    options={optionVendorsType}
                                    value={optionVendorsType.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue('usersetupid', null)
                                        setValue('username', null)
                                        if (e.value != 'employee') {
                                            setValue("vendorcode", null)
                                        }
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                    className={clsx("w-100", { 'form-error': errors.vendortype })}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.vendor.user-setup'>
                            <Controller
                                name="usersetupid"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    disabled={watch("vendortype") == "vendor"}
                                    isClearable
                                    options={optionUserSetups}
                                    value={optionUserSetups.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue('username', undefined)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue('username', e.username)
                                    }}
                                    className={clsx("w-100", { 'form-error': errors.usersetupid })}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.pay-to-vendor'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.paytovendorno && 'form-error'}`} {...register('paytovendorno')} />
                        </KTFormItem>
                        <KTFormItem title='text.payment-term'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.paymenttermscode && 'form-error'}`} {...register('paymenttermscode')} />
                        </KTFormItem>
                        <KTFormItem title='text.payment-method'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.paymentmethodcode && 'form-error'}`} {...register('paymentmethodcode')} />
                        </KTFormItem>
                        <KTFormItem title='text.vat-registration-no'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.vatregistrationno && 'form-error'}`} {...register('vatregistrationno')} />
                        </KTFormItem>
                        <KTFormItem title='text.balance'>
                            {formatCurrent(watch('balance'))}
                        </KTFormItem>
                        <KTFormItem title='text.balance-as-customer'>
                            {formatCurrent(watch('balanceascustomer'))}
                        </KTFormItem>
                        <KTFormItem title='text.status'>
                            <span className={`badge badge-${watch('blocked') ? 'danger' : 'success'}`}>
                                {watch('blocked') ? intl.formatMessage({ id: "text.blocked" }) : intl.formatMessage({ id: "text.active" })}
                            </span>
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
        {isEdit && valueid && <div className="card card-body">
            <VendorBankAccountCard vendorData={dataQuery} />
        </div>}
    </>
}

interface IVendorBankAccount extends IInitFieldAddress {
    vendorid: string | null
    vendorno: string | null
    vendorname: string | null
    code: string | null
    bankname: string | null
    bankbranchno: string | null
    bankbranchname: string | null
    bankaccountno: string | null
    bankaccountname: string | null
    phoneno: string | null
    email: string | null
}

const VendorBankAccountCard: FC<{ vendorData: IVendor }> = ({ vendorData }) => {
    const urlApi = 'settings/vendorbankaccounts'
    const { data, isSuccess, isFetching, refetch } = apiPrivate.useVendorBankAccountsByQuery(vendorData?.id ? `?vendorno=${vendorData.number}` : undefined)
    const [tableData, setTableData] = useState<IVendorBankAccount[]>([])
    const [valueData, setValueData] = useState<IVendorBankAccount | undefined>()

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => setValueData(props.data[props.row.index])}
            data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'bankname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].bankname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.bank.account.no' />,
        accessor: 'bankaccountno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].bankaccountno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.bank.account.name' />,
        accessor: 'bankaccountname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].bankaccountname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" onClick={() => setValueData(props.data[props.row.index])} />
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <KTTable
            isLoading={isFetching}
            toolbar={<>
                <KTButton action="sync" onClick={() => syncObjects(`/bcsync/vendorbankaccountsbyvendorno?source=WEBSERVICE&vendorid=${vendorData.id}&vendorno=${vendorData.number}`, refetch)} />
                <KTButton action="new" onClick={() => {
                    vendorData?.id && setValueData({
                        vendorid: vendorData.id,
                        vendorno: vendorData.number,
                        vendorname: vendorData.vendorname,
                        code: null,
                        bankname: null,
                        bankbranchno: null,
                        bankbranchname: null,
                        bankaccountno: null,
                        bankaccountname: null,
                        phoneno: null,
                        email: null,
                        address: null,
                        ward: null,
                        district: null,
                        province: null,
                        country: null,
                        zipcode: null,
                        state: null
                    })
                }} />
            </>}
            title={<FormattedMessage id="text.banks" />}
            data={tableData}
            columns={columns} />
        {valueData && <VendorBankAccountModal {...{
            tableData,
            urlApi,
            valueData,
            handleClose() {
                setValueData(undefined)
            },
            handleSave(type, value) {
                setTableData(pre => type
                    ? sortByUpdateAt(pre.map(item => item.id == value.id ? value : item))
                    : [value, ...pre]
                )
            },
        }} />}
    </>
}

interface IVendorBankAccountModal {
    tableData: IVendorBankAccount[]
    urlApi: string
    valueData: IVendorBankAccount
    handleSave: (type: boolean, value: IVendorBankAccount) => void
    handleClose: () => void
}

const VendorBankAccountModal: FC<IVendorBankAccountModal> = ({ tableData, urlApi, valueData, handleSave, handleClose }) => {
    const { valueid, isEdit } = getValueId(valueData.id)
    const arrCode = tableData.filter(f => f.id != valueid).map(item => item.code)

    const { register, control, reset, watch, handleSubmit, setValue, formState: { errors, isSubmitting } } = useForm<IVendorBankAccount>({
        resolver: yupResolver(yup.object().shape({
            code: yup.string().test(val => val ? !arrCode.includes(val) : false).required(),
            bankname: yup.string().required(),
            bankaccountno: yup.string().required(),
            bankaccountname: yup.string().required(),
            // bankbranchno: yup.string().required(),
            // bankbranchname: yup.string().required(),
        })),
    })

    useEffect(() => {
        if (valueData)
            reset(valueData)
    }, [valueData])


    const onSubmit = async (data: any) => {
        try {
            const method = isEdit ? 'put' : 'post'
            const result = await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
            handleSave(isEdit, result.data)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
        handleClose()
    }

    return <Modal size="lg" show={valueData != undefined} onHide={handleClose} centered >
        <Modal.Header>
            <Modal.Title> <FormattedMessage id="text.bank.info" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name' >
                            <input className={`form-control form-control-sm ${errors.bankname && 'form-error'}`} {...register('bankname')} />
                        </KTFormItem>
                        <KTFormItem title='text.bank.account.no' >
                            <input className={`form-control form-control-sm ${errors.bankaccountno && 'form-error'}`} {...register('bankaccountno')} />
                        </KTFormItem>
                        <KTFormItem title='text.bank.account.name' >
                            <input className={`form-control form-control-sm ${errors.bankaccountname && 'form-error'}`} {...register('bankaccountname')} />
                        </KTFormItem>
                        <KTFormItem title='text.branch.id' >
                            <input className={`form-control form-control-sm ${errors.bankbranchno && 'form-error'}`} {...register('bankbranchno')} />
                        </KTFormItem>
                        <KTFormItem title='text.branch.name' >
                            <input className={`form-control form-control-sm ${errors.bankbranchname && 'form-error'}`} {...register('bankbranchname')} />
                        </KTFormItem>
                        <KTFormItem title='text.phone' >
                            <input className={`form-control form-control-sm ${errors.phoneno && 'form-error'}`} {...register('phoneno')} />
                        </KTFormItem>
                        <KTFormItem title='text.email' >
                            <input className={`form-control form-control-sm ${errors.email && 'form-error'}`} {...register('email')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <ExtendAddressValue {...{
                            // disabledAddress: true,
                            register,
                            control,
                            errors,
                            setValue,
                            watch
                        }} />
                    </div>
                </div>
                <div className="d-flex flex-stack">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        handleClose={handleClose} />
                </div>
            </form>
        </Modal.Body>
    </Modal>
}