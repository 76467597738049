import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { FormSubmit, InputSelect, KTFormItem, RadioBoolean, TextFormByUser, yup } from "../../../../theme/partials";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";
import { apiAd } from "../../../modules/api/admin";
import { useEffect, useState } from "react";

const defaultValues: any = {
    advancerequestforsomeone: false,
    paymentrequestforsomeone: false
}

export function SelfServiceSetupForm({ keyElement, pathElement, permission }: MergedProps) {
    const urlApi = 'settings/selfservicesetups/'
    const navigate = useNavigate();
    // const { id } = useParams()
    // const { isEdit, valueid } = getValueId(id)
    const [valueid,setValueid] = useState(null)
    const { data: dataSelf, isSuccess: isdataSelf } = apiAd.useSelfServiceSetups()

    const { data: noseriesData, isSuccess: isNoseries } = apiAd.useNoSeries()
    const { data: dataDimensions, isSuccess: isDimensions } = apiAd.useDimensions()
    const { data: objectOrder, isSuccess: isObjectOrder } = apiAd.useObjectsByNumber('500002')
    const { data: objectAdvance, isSuccess: isObjectAdvance } = apiAd.useObjectsByNumber('500003')
    const { data: objectPayment, isSuccess: isObjectPayment } = apiAd.useObjectsByNumber('500004')
    const optionNoseries = isNoseries ? formatOptions(noseriesData, ['id', 'code']) : []
    const optionDimensions = isDimensions ? formatOptions(dataDimensions, ['id', 'code']) : []
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        defaultValues,
        // resolver: yupResolver(yup.object().shape({
        //     code: yup.string().test('unique', (val, ctx) => {
        //         return true
        //     }).required(),
        //     name: yup.string().required(),
        // })),
    })
    useEffect(() => {
        if (dataSelf && dataSelf.length != 0) {
            let data = dataSelf.pop()
            setValue('advancerequestforsomeone', data?.advancerequestforsomeone)
            setValue('paymentrequestforsomeone', data?.paymentrequestforsomeone)
            setValue('globaldimension1code', data?.globaldimension1code)
            setValue('globaldimension1id', data?.globaldimension1id)
            setValue('globaldimension2code', data?.globaldimension2code)
            setValue('globaldimension2id', data?.globaldimension2id)
            setValueid(data?.id)
        }
    }, [dataSelf])
    useEffect(() => {
        if (objectOrder && objectOrder.length != 0) {
            setValue("objectOrderId",objectOrder[0]?.noseriid)
            setValue("nosericodeOrder",objectOrder[0]?.nosericode)
        }
    }, [objectOrder])
    useEffect(() => {
        if (objectAdvance && objectAdvance.length != 0) {
            setValue("objectAdvanceId",objectAdvance[0]?.noseriid)
            setValue("nosericodeAdvance",objectAdvance[0]?.nosericode)
            
        }
    }, [objectAdvance])
    useEffect(() => {
        if (objectPayment && objectPayment.length != 0) {
            setValue("objectPaymentId",objectPayment[0]?.noseriid)
            setValue("nosericodePayment",objectPayment[0]?.nosericode)
        }
    }, [objectPayment])
    const saveObject = (id: any, data: any) => {
        let urlApi = 'settings/objects/'+ id
        ApiService.put(`${urlApi}`, data)
    }
    return <>
        <ContentHeader title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: any) => {
                try {
                    // console.log('data',data)
                    saveObject(objectAdvance[0]?.id,{noseriid: data.objectAdvanceId, nosericode: data.nosericodeAdvance})
                    saveObject(objectPayment[0]?.id,{noseriid: data.objectPaymentId, nosericode: data.nosericodePayment})
                    saveObject(objectOrder[0]?.id,{noseriid: data.objectOrderId, nosericode: data.nosericodeOrder})
                    const method = valueid ? 'put' : 'post'
                    let tempData = {
                        advancerequestforsomeone: data.advancerequestforsomeone, 
                        paymentrequestforsomeone : data.paymentrequestforsomeone,
                        "globaldimension1id": data.globaldimension1id,
                        "globaldimension1code": data.globaldimension1code,
                        "globaldimension2id": data.globaldimension2id,
                        "globaldimension2code": data.globaldimension2code,
                    }
                    await ApiService[method](`${urlApi}${valueid?`${valueid}`:''}`, tempData)
                    popupMessage({ icon: 'success', autoClose: true })
                    // navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={true}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.advanceNo'>
                            <Controller
                                name="objectAdvanceId"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isClearable
                                    options={optionNoseries}
                                    value={optionNoseries.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue("nosericodeAdvance", null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue("nosericodeAdvance", e.code)
                                    }}
                                    className={`w-100 ${errors.noseriid && 'form-error'}`}
                                />}
                            />
                            {/* <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} /> */}
                        </KTFormItem>
                        <KTFormItem title='text.paymentNo'>
                            <Controller
                                name="objectPaymentId"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isClearable
                                    options={optionNoseries}
                                    value={optionNoseries.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue("nosericodePayment", null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue("nosericodePayment", e.code)
                                    }}
                                    className={`w-100 ${errors.noseriid && 'form-error'}`}
                                />}
                            />
                            {/* <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} /> */}
                        </KTFormItem>
                        <KTFormItem title='text.orderNo'>
                            {/* <input className={`form-control form-control-sm ${errors.orderNo && 'form-error'}`} {...register('name')} /> */}
                            <Controller
                                name="objectOrderId"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isClearable
                                    options={optionNoseries}
                                    value={optionNoseries.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue("nosericodeOrder", null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue("nosericodeOrder", e.code)
                                    }}
                                    className={`w-100 ${errors.noseriid && 'form-error'}`}
                                />}
                            />
                        </KTFormItem>
                        
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem labelWidth="200" title='text.advanceRequestFor'>
                            <RadioBoolean control={control} name="advancerequestforsomeone" className="form-check form-check-sm form-check-custom w-80px" />
                        </KTFormItem>
                        <KTFormItem labelWidth="200" title='text.paymentRequestFor'>
                            <RadioBoolean control={control} name="paymentrequestforsomeone" className="form-check form-check-sm form-check-custom w-80px" />
                        </KTFormItem>
                        <KTFormItem labelWidth="200" title='text.globaldimension1code'>
                            <Controller
                                name="globaldimension1id"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isClearable
                                    options={optionDimensions}
                                    value={optionDimensions.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue("globaldimension1code", null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue("globaldimension1code", e.code)
                                    }}
                                    className={`w-100 ${errors.globaldimension1code && 'form-error'}`}
                                />}
                            />
                            {/* <input className={`form-control form-control-sm ${errors.globaldimension1code && 'form-error'}`} {...register('globaldimension1code')} /> */}
                        </KTFormItem>
                        <KTFormItem labelWidth="200" title='text.globaldimension2code'>
                            <Controller
                                name="globaldimension2id"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isClearable
                                    options={optionDimensions}
                                    value={optionDimensions.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue("globaldimension2code", null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue("globaldimension2code", e.code)
                                    }}
                                    className={`w-100 ${errors.noseriid && 'form-error'}`}
                                />}
                            />
                            {/* <input className={`form-control form-control-sm ${errors.globaldimension2code && 'form-error'}`} {...register('globaldimension2code')} /> */}
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
    </> 
}