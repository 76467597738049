import clsx from "clsx"
import { FC, useEffect, useRef, useState } from "react"
import { popupMessage } from "../../../../app/modules/messages";

interface IInputMutiImage {
    isChecked?: boolean
    value: FileCustom[]
    onChange: (values: FileCustom[], fileId?: string | null) => void
    disabled?: boolean
}

interface FileCustom extends File {
    url?: string
    fileName: string
    fileSize?: string
    pathtofile?: string
    fileType?: string
    state: "upload" | "remove" | "load",
    isDefault?: boolean
}

const config = { MAXSIZE: 2, MAXFILES: 10, }

export const InputMutiImage: FC<IInputMutiImage> = ({ disabled, value, onChange, isChecked }) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [files, setFiles] = useState<FileCustom[]>([])

    useEffect(() => {
        if (value && value.length && files.length == 0) {
            setFiles(value.filter(f => f.hasOwnProperty('url')).map(e => ({ ...e, name: e.fileName, state: "load" })))
        }
    }, [value])

    function resetFiles(event: React.ChangeEvent<HTMLInputElement>) {
        // var fileBuffer = new DataTransfer();
        // fileBuffer.clearData()
        event.target.value = null as any;
    }

    const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const filesEvent = event.target.files
            ? Object.values(event.target.files)
            : []
        // console.log('filesEvent', filesEvent)
        const maxkb = (((config.MAXSIZE) * 1024) * 1024)
        const isErrSize = filesEvent.filter((f: any, index) => f.size > maxkb)
        const isErrFiles = filesEvent.filter((f: any, index) => index > config.MAXFILES)
        if (isErrSize.length) {
            popupMessage({ icon: "error", description: "This selected file is too large. Please select a file less than 1MB" })
            resetFiles(event)
            return null
        }
        if (isErrFiles.length) {
            popupMessage({ icon: "error", description: "The number of files to upload does not exceed 10" })
            resetFiles(event)
            return null
        }

        // const listname = files.filter(f => f.state != "remove").map(e => e.name)
        const fileResult: any[] = (filesEvent
            // .filter((f) => !listname.includes(f.name))
            .map((item, ind) => {
                const radom = Math.floor(Math.random() * 100000);
                const newName = `img_${radom}${new Date().getTime()}${ind}.png`
                const newFile = new File([item], newName)
                return Object.assign(newFile, { fileName: newName, state: "upload" })
            })
            .concat(files))

        // console.log('fileResult', fileResult)
        resetFiles(event)
        setFiles(fileResult)
        onChange(fileResult)
    }

    const removeFile = (ev: any, filename: string) => {
        ev.preventDefault();
        const fileResult: any[] = files
            // .filter((file, index) => !(index == i && file.state == 'upload'))
            .map((file, index) => {
                return file.fileName == filename ? ({ ...file, state: "remove" }) : file
            })
        setFiles(fileResult)
        onChange(fileResult)
    }

    function renderItem(file: any) {
        if (!file) return
        if (file?.url || file instanceof File) {
            const imgurl = file?.url ? file.url : file instanceof File ? window.URL.createObjectURL(file) : undefined
            return <div key={file.fileName}
                style={{ boxSizing: 'border-box' }}
                className={clsx("position-relative d-inline-flex border border-1 d-row-hover", {
                    "d-none": file.state == "remove",
                    "w-75px h-75px border-secondary": !file.isDefault,
                    "w-150px h-150px border-primary border-dashed p-1": file.isDefault,
                })}>
                <div
                    onClick={() => {
                        if (isChecked) {
                            const temps = files.map(v => {
                                if (v.fileName == file.fileName) {
                                    return Object.assign(v, { isDefault: true })
                                } else {
                                    delete v.isDefault
                                    return v
                                }
                            })
                            setFiles(temps)
                            onChange(temps)
                        }
                    }}
                    className={`cursor-pointer d-flex`}
                    style={{ minWidth: 75, overflow: 'hidden' }}>
                    {file.state != "remove" && <img src={imgurl} className='h-100' />}
                </div>
                {
                    !disabled && <div className="position-absolute top-0 end-0 mt-1 me-1 d-cell-hover">
                        <i className="bi bi-x-circle-fill text-danger cursor-pointer d-flex bg-white mh-15px rounded-circle" onClick={(e) => removeFile(e, file.fileName)} />
                    </div>
                }
            </div>
        }
        return
    }

    return <div className="">
        <div
            id="drop_zone"
            style={{ overflow: 'hidden' }}
            className="mb-2 position-relative border border-1 rounded border-secondary p-3 pe-1 min-h-125px">
            <input
                id='multifile'
                ref={inputRef}
                className="border-0 d-none"
                type='file'
                accept=".jpg,.png,.jpeg"
                multiple
                onChange={selectFile} />
            {files.length > 0
                ? <div className="d-flex gap-2" >
                    {files.find(f => f.isDefault)?.isDefault && renderItem(files.find(f => f.isDefault))}
                    <div className="d-flex flex-wrap gap-1" style={{ overflow: 'hidden', height: '150px' }} >
                        {files.filter(f => !f.isDefault).map((file, index) => renderItem(file))}
                    </div>
                </div>
                : <></>}
        </div>
        <button
            type="button"
            className="btn btn-sm btn-primary mb-2"
            disabled={disabled}
            onClick={() => inputRef.current?.click()}>
            Attach files
        </button>
        <span className="form-text text-muted ms-2">Max file size is {config.MAXSIZE}MB and max number of files is {config.MAXFILES}.</span>
    </div>
}