import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { IFnbCustomization, optionViewModals } from ".";
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId, sortWithAny } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { FormSubmit, InputPrice, InputSelect, KTFormItem, KTSVG, TextFormByUser, yup } from "../../../../theme/partials";
import { apiPrivate, IInitField } from "../../../modules/api";
import { apiFnb } from "../../../modules/api/fnb";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";

const defaultValues: IFnbCustomization = {
    code: null,
    name: null,
    headertext: null,
    active: false,
    minnoofselection: null,
    maxnoofselection: null,
    viewmodel: 'image',
    showasrecommendation: false,
    donotshowincombo: false
}

export interface IFnbCustomizationLine extends IInitField {
    customizationid: string | null
    customizationcode: string | null
    customizationname: string | null
    lineno: number | null
    hospitemid: string | null
    hospitemcode: string | null
    hospitemname: string | null
    hospitemvariantid: string | null
    hospitemvariantcode: string | null
    hospitemvariantname: string | null
    allowmultipleqty: boolean
    maxallowedqty: number | null
    showvariantimage: boolean
    sendasitem: boolean
    price: number | null
    unitofmeasure: string | null
}

export interface IFNBCustomizationsForm extends IFnbCustomization {
    CustomizationLines: IFnbCustomizationLine[]
}

export function FNBCustomizationsForm({ keyElement, pathElement, permission }: MergedProps) {
    const urlApi = 'fnb/customizations'
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, isEditWithPermisstion, valueid } = getValueId(id, permission)
    const { data, isSuccess, isFetching, refetch } = apiFnb.useCustomizationsId<IFNBCustomizationsForm>(valueid)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IFNBCustomizationsForm>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            code:  yup.string().required(),
            name: yup.string().required(),
            minnoofselection: yup.number().nullable(true).transform(val => val ? Number(val) : null),
            maxnoofselection: yup.number().nullable(true).transform(val => val ? Number(val) : null)
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset({ ...data, CustomizationLines: sortWithAny(data.CustomizationLines, { order: "asc", sort: "hospitemcode" }) })
    }, [isFetching])

    const { data: dataHospitalityItems, isSuccess: isHospitalityItems } = apiFnb.useHospitalityItems()
    const optionHospitalityItems = isHospitalityItems ? formatOptions(dataHospitalityItems, ['id', 'name']) : []

    const { data: dataHospItemVariants, isSuccess: isHospItemVariants } = apiFnb.useHospItemVariants()
    const optionHospItemVariants = isHospItemVariants ? formatOptions(dataHospItemVariants, ['id', 'code']) : []

    const { data: dataHospItemPrices, isSuccess: isHospItemPrices } = apiFnb.useHospItemPrices()
    const optionisHospItemPrices: any[] = isHospItemPrices ? dataHospItemPrices : []

    const { data: dataUoms, isSuccess: isUoms } = apiPrivate.useUoms()
    const optionUoms = isUoms ? formatOptions(dataUoms, ['code', 'displayname']) : []

    async function handleDeleteLine(index: number, key: any) {
        if (!watch(key)) return popupMessage({ icon: "error" })
        return setValue(key, watch(key).filter((f: any, i: number) => i != index) || [])
    }

    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IFNBCustomizationsForm) => {
                try {
                    // data.CustomizationLines = data.CustomizationLines.map((m, ind) => ({
                    //     ...m,
                    //     price: (optionisHospItemPrices.find(f =>
                    //         f.hospitemid == m.hospitemid &&
                    //         f.unitofmeasure == m.unitofmeasure &&
                    //         (m.hospitemvariantid
                    //             ? f.hospitemvariantid == m.hospitemvariantid
                    //             : true)
                    //     )?.unitprice) || 0
                    // }))
                    await ApiService.post(`${urlApi}/upsertwithnewdetails`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                        </KTFormItem>
                        <div className="row row-cols-lg-2 mb-2">
                            <KTFormItem title='text.min-no-of-selection' className="col">
                                <input className={`form-control form-control-sm ${errors.minnoofselection && 'form-error'}`} {...register('minnoofselection')} />
                            </KTFormItem>
                            <KTFormItem title='text.max-no-of-selection' className="col">
                                <input className={`form-control form-control-sm ${errors.maxnoofselection && 'form-error'}`} {...register('maxnoofselection')} />
                            </KTFormItem>
                        </div>
                        <KTFormItem title='text.view-model'>
                            {optionViewModals.map(item => <label key={item.value} className="form-check form-check-custom me-3">
                                <input className="form-check-input" type="radio" value={item.value} {...register("viewmodel")} />
                                {item.label}
                            </label>)}
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.header-text'>
                            <input className={`form-control form-control-sm ${errors.headertext && 'form-error'}`} {...register('headertext')} />
                        </KTFormItem>
                        <div>
                            <label className="form-check form-check-custom mb-6">
                                <input className="form-check-input" type="checkbox" {...register("showasrecommendation")} />
                                <FormattedMessage id="text.show-as-recommendation"/>
                            </label>
                            <label className="form-check form-check-custom">
                                <input className="form-check-input" type="checkbox" {...register("donotshowincombo")} />
                                <FormattedMessage id="text.do-not-show-in-combo"/>
                            </label>
                        </div>
                    </div>
                    <div className="col-12">
                        <hr />
                        <div className="card-header px-0 pt-0 border-bottom-0">
                            <div className="card-title"><FormattedMessage id="text.option-for-customization"/> </div>
                        </div>
                        {watch('CustomizationLines')?.map((line, index) => <div key={index} className="card border border-secondary mb-4 d-row-hover">
                            <div className="card-body pb-0">
                                <table className="table m-0 gx-4">
                                    <thead>
                                        <tr>
                                            <th className="py-0 min-w-200px"><FormattedMessage id="text.hospitality-item"/></th>
                                            <th className="py-0 w-150px"><FormattedMessage id="text.hospitality-item.variant.id"/></th>
                                            <th className="py-0"><FormattedMessage id="text.unit-of-measure"/></th>
                                            <th className="py-0 w-150px"><FormattedMessage id="text.price"/></th>
                                            <th className="py-0"><FormattedMessage id="text.allow-multiple-qty"/></th>
                                            <th className="py-0 w-125px"><FormattedMessage id="text.max-allowed-qty"/></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Controller
                                                    control={control}
                                                    name={`CustomizationLines.${index}.hospitemid`}
                                                    render={({ field: { value, onChange } }) => <InputSelect
                                                        isAsync
                                                        className="w-100 p-0"
                                                        options={optionHospitalityItems}
                                                        value={optionHospitalityItems.find(f => f.value == value) || null}
                                                        onChange={(e) => {
                                                            setValue(`CustomizationLines.${index}.hospitemvariantid`, null)
                                                            setValue(`CustomizationLines.${index}.hospitemvariantcode`, null)
                                                            setValue(`CustomizationLines.${index}.hospitemvariantname`, null)
                                                            if (!e) return onChange(null)
                                                            onChange(e.value)
                                                            setValue(`CustomizationLines.${index}.hospitemcode`, e.code)
                                                            setValue(`CustomizationLines.${index}.hospitemname`, e.name)
                                                            setValue(`CustomizationLines.${index}.unitofmeasure`, e.unitofmeasure)
                                                        }}
                                                    />}
                                                />
                                            </td>
                                            <td>
                                                <Controller
                                                    control={control}
                                                    name={`CustomizationLines.${index}.hospitemvariantid`}
                                                    render={({ field: { value, onChange } }) => <InputSelect
                                                        isClearable
                                                        className="w-100 p-0"
                                                        options={optionHospItemVariants.filter(f =>
                                                            (f.hospitemid == watch(`CustomizationLines.${index}.hospitemid`)) &&
                                                            !watch('CustomizationLines').map(i => i.hospitemvariantid).includes(f.id)
                                                        )}
                                                        value={optionHospItemVariants.find(f => f.value == value) || null}
                                                        onChange={(e) => {
                                                            if (!e) return onChange(null)
                                                            onChange(e.value)
                                                            setValue(`CustomizationLines.${index}.hospitemvariantcode`, e.code)
                                                            setValue(`CustomizationLines.${index}.hospitemvariantname`, e.name)
                                                        }}
                                                    />}
                                                />
                                            </td>
                                            <td>
                                                <Controller
                                                    name={`CustomizationLines.${index}.unitofmeasure`}
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => <InputSelect
                                                        isClearable
                                                        options={optionUoms}
                                                        value={optionUoms.find(f => f.value == value) || null}
                                                        onChange={(e) => {
                                                            if (!e) return onChange(null)
                                                            onChange(e.value)
                                                        }}
                                                    />}
                                                />
                                            </td>
                                            <td>
                                                <InputPrice
                                                    disabled
                                                    value={
                                                        (optionisHospItemPrices.find(f =>
                                                            f.hospitemid == watch(`CustomizationLines.${index}.hospitemid`) &&
                                                            f.unitofmeasure == watch(`CustomizationLines.${index}.unitofmeasure`) &&
                                                            (watch(`CustomizationLines.${index}.hospitemvariantid`)
                                                                ? f.hospitemvariantid == watch(`CustomizationLines.${index}.hospitemvariantid`)
                                                                : true)
                                                        )?.unitprice) || 0
                                                    }
                                                    onChange={(e) => { }} />
                                            </td>
                                            <td>
                                                <label className="form-check form-check-custom">
                                                    <input className="form-check-input" type="checkbox" {...register(`CustomizationLines.${index}.allowmultipleqty`)} />
                                                </label>
                                            </td>
                                            <td>
                                                <input
                                                    {...register(`CustomizationLines.${index}.maxallowedqty`)}
                                                    className={clsx('form-control form-control-sm', {
                                                        'form-error': errors.CustomizationLines?.length && errors.CustomizationLines[index]?.maxallowedqty
                                                    })}
                                                />
                                            </td>
                                            {/* <td>
                                                <label className="form-check form-check-custom">
                                                    <input className="form-check-input" type="checkbox" {...register(`CustomizationLines.${index}.showvariantimage`)} />
                                                </label>
                                            </td>
                                            <td>
                                                <label className="form-check form-check-custom">
                                                    <input className="form-check-input" type="checkbox" {...register(`CustomizationLines.${index}.sendasitem`)} />
                                                </label>
                                            </td> */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={clsx("d-flex w-100", { 'd-none': isEdit && !isEditWithPermisstion })}>
                                <span className="btn btn-icon btn-sm btn-light-danger ms-auto d-cell-hover" onClick={() => handleDeleteLine(index, 'CustomizationLines')}>
                                    <KTSVG path={'/media/icons/duotune/general/gen027.svg'} className={`svg-icon-4 svg-icon-danger`} />
                                </span>
                            </div>
                        </div>)}
                        <div className={clsx("card card-body border border-secondary", { 'd-none': isEdit && !isEditWithPermisstion })}>
                            <span
                                className={clsx("btn btn-icon btn-sm btn-primary rounded-circle mx-auto")}
                                onClick={() => setValue('CustomizationLines', [...(watch('CustomizationLines') || []), {
                                    customizationid: watch('id') as string,
                                    customizationcode: watch('code'),
                                    customizationname: watch('name'),
                                    lineno: null,
                                    hospitemid: null,
                                    hospitemcode: null,
                                    hospitemname: null,
                                    hospitemvariantid: null,
                                    hospitemvariantcode: null,
                                    hospitemvariantname: null,
                                    allowmultipleqty: false,
                                    maxallowedqty: null,
                                    showvariantimage: true,
                                    sendasitem: true,
                                    price: null,
                                    unitofmeasure: null
                                }])}>
                                <i className="bi bi-plus-lg fs-1"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}