import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService, ApiShowError, changeDataModify, getValueId, radomString } from "../../../theme/helpers";
import { ContentHeader } from "../../../theme/layout/components/content";
import { FormSubmit, InputDate, InputPrice, InputSelect, PaginationCustom, TextFormByUser, yup } from "../../../theme/partials";
import CustomToggle from "../../../theme/partials/CustomToggle";
import { apiFnb } from "../../modules/api/fnb";
import { popupMessage } from "../../modules/messages";
import { MergedProps } from "../../router/AppRoutes";
import { ModalListSelectedMutiProduct } from "./promotionsDetail";

export interface ICoupon {
    code: string
    startdatetime: string
    enddatetime: string
    discountvalue: string
    type: string
    description: string
    voucherspecific: any
    specificproducts: any[]
    minrequirement: any
    minrequirementvalue: any
    islimitnumbertimes: boolean
    islimitonepermember: boolean
    islimituseonce: boolean
    isonlytosinglecheapest: boolean
    expirationformula: any
    usepin: boolean
    pinlength: any
}

interface ICouponDataEntry {
    id?: string
    statuscode: number
    couponid?: string
    couponcode?: string
    limit: number
    pin: string
    amount: string
    remainingamount: string

    datecreated: null
    dateused: null
    expiringdate: null
    isused: false
    appliedbylineno: null
    appliedbytransaction: null
}

const optionDiscountType = [
    { label: 'Fixed Amount', value: 'fixed-amount' },
    { label: 'Percentage', value: 'percentage' },
    { label: 'Free Shipping', value: 'free-shipping' },
]

function isEndDateAfterStartDate(startDate: string, endDate: string) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    return end.isAfter(start);
}

export default function CouponsDetailPage({ keyElement, pathElement, permission }: MergedProps) {
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>();
    const { valueid, isEdit } = getValueId(id)
    const urlApi = 'discounts/coupons'
    const { data: dataQuery, isLoading } = useQuery([`${urlApi}/${valueid}`], () => ApiService.get(`${urlApi}/${valueid}`).then(res => res.data), { enabled: !!valueid })
    const { register, setValue, control, watch, reset, handleSubmit, formState: { errors, isSubmitting } } = useForm<ICoupon>({
        defaultValues: {
            type: 'fixed-amount',
            discountvalue: '0',
            specificproducts: [],
            minrequirement: 'none'
        },
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            description: yup.string().required(),
            startdatetime: yup.string().required(),
            enddatetime: yup.string()
                .test('check', (val, ctx) => {
                    if (val) {
                        if (!ctx.parent['startdatetime']) return ctx.createError()
                        return isEndDateAfterStartDate(ctx.parent['startdatetime'], val)
                    }
                    return true
                })
                .transform(val => val || null),
            minrequirementvalue: yup.number().nullable(true).transform(val => val || null),
        }))
    })
    const [valueSelected, setValueSelected] = useState<string | undefined>()
    const [isMultiple, setIsMultiple] = useState(false)
    const [typeData, setTypeData] = useState<'sku' | 'category' | 'subcategory' | undefined>()
    const { data: hospitemsData } = apiFnb.useHospitalityItems()
    const { data: categoriesData } = apiFnb.useCategories()
    const { data: subCategoriesData } = apiFnb.useSubCategories()

    useEffect(() => {
        if (dataQuery && !isLoading) {
            reset(dataQuery)
        }
    }, [dataQuery])

    function addItem(type: 'sku' | 'category' | 'subcategory') {
        const newLine = { id: `item_${new Date().getTime()}`, type }
        setValue('specificproducts', [...(watch('specificproducts') || []), newLine])
    }

    function removeItem(id: string) {
        setValue('specificproducts', watch('specificproducts').filter((item: any) => item.id !== id))
    }

    const { data: codesData, refetch } = useQuery([`discounts/coupondataentries?couponid=${valueid}`], () => ApiService.get(`discounts/coupondataentries?couponid=${valueid}`).then(res => res.data), { enabled: !!valueid })
    const [codeValue, setValueCode] = useState<any>()

    function generateUniqueCodes(quantity: number, existingCodes: string[], prefix: string) {
        let codes = new Set(existingCodes);

        while (codes.size < quantity + existingCodes.length) {
            let code = prefix + Math.floor(100000 + Math.random() * 900000).toString();
            codes.add(code);
        }

        // Trả về chỉ các mã mới được tạo ra
        let allCodes = Array.from(codes);
        return allCodes.slice(existingCodes.length);
    }

    async function onSaveCode(value: any) {
        if (value) {
            if (value.quantity) {
                const temps = generateUniqueCodes(value.quantity, (codesData || []).map((m: any) => m.pin), `${value.pin}-`)

                await Promise.all(temps.map(async (item: any) => {
                    await ApiService.post(`discounts/coupondataentries`, { ...value, pin: item })
                }))
            } else {
                await ApiService.post(`discounts/coupondataentries`, value)
            }
            refetch()
        }
        setValueCode(undefined)
    }

    async function removeCode(id: string) {
        await ApiService.delete(`discounts/coupondataentries/${id}`)
        refetch()
    }

    async function onSubmit(data: any) {
        try {
            const method = isEdit ? 'put' : 'post'
            await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify({ ...data, specificproducts: data.specificproducts.filter((f: any) => f.value) }))
            popupMessage({ icon: 'success', autoClose: true })
            navigate(pathElement)
        } catch (error) {
            ApiShowError(error)
        }
    }

    function onError(errors: any) {
        console.log(errors)
    }

    const [tabActive, setTabActive] = useState('product')

    const tcategory = watch('specificproducts')?.filter(f => f.type == 'category')
    const tsubcategry = watch('specificproducts')?.filter(f => f.type == 'subcategory')
    const tproduct = watch('specificproducts')?.filter(f => f.type == 'sku')

    const [page, setPage] = useState({ ind: 0, limit: 10 })

    function paginateArray(array: any[], pageIndex: number, pageLimit: number) {
        const startIndex = pageIndex * pageLimit;
        const endIndex = startIndex + pageLimit;
        return array.slice(startIndex, endIndex);
    }

    return (<>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                    <div className="card-toolbar">
                        <TextFormByUser data={watch()} />
                        <FormSubmit
                            type={isEdit}
                            isSubmitting={isSubmitting}
                            permission={permission}
                            handleClose={() => navigate(pathElement)} />
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="mb-6">
                                <label className="fs-5 mb-1">Code</label>
                                <input className={`form-control form-control-sm mb-4 ${errors.code && 'form-error'}`} {...register('code')} />
                            </div>
                            <div className="mb-6">
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <label>Start Date</label>
                                        <Controller
                                            name="startdatetime"
                                            control={control}
                                            render={({ field }) => <InputDate
                                                value={field.value}
                                                onChange={(e) => {
                                                    if (e[0]) field.onChange(e[0].toISOString())
                                                }}
                                                className={clsx({ 'form-error': errors.startdatetime })}
                                            />}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <label>Start Hour</label>
                                        <input
                                            type="time"
                                            className={clsx("form-control form-control-sm", { 'form-error': errors.startdatetime })}
                                            value={watch("startdatetime") ? dayjs(watch("startdatetime")).format('HH:mm') : undefined}
                                            onChange={(e) => {
                                                const hour = Number(e.target.value.split(':')[0])
                                                const minute = Number(e.target.value.split(':')[1])
                                                const date = new Date(watch('startdatetime') || Date.now())
                                                date.setHours(hour)
                                                date.setMinutes(minute)
                                                setValue('startdatetime', date.toISOString())
                                                return e
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-check form-check-custom my-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="isenddate"
                                        checked={!!watch("enddatetime")}
                                        onChange={(e) => setValue("enddatetime", e.target.checked ? new Date().toISOString() : "")}
                                    />
                                    <label htmlFor="isenddate"> Set end date </label>
                                </div>
                                <div className={clsx("row", { 'd-none': !watch("enddatetime") })}>
                                    <div className="col-12 col-lg-6">
                                        <label>End Date</label>
                                        <Controller
                                            name="enddatetime"
                                            control={control}
                                            render={({ field }) => <InputDate
                                                value={field.value}
                                                onChange={(e) => {
                                                    if (e[0]) field.onChange(e[0].toISOString())
                                                }}
                                                className={clsx({ 'form-error': errors.enddatetime })}
                                            />}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <label>End Hour</label>
                                        <input
                                            type="time"
                                            className={clsx("form-control form-control-sm", { 'form-error': errors.enddatetime })}
                                            value={watch("enddatetime") ? dayjs(watch("enddatetime")).format('HH:mm') : undefined}
                                            onChange={(e) => {
                                                const hour = Number(e.target.value.split(':')[0])
                                                const minute = Number(e.target.value.split(':')[1])
                                                const date = new Date(watch('enddatetime') || Date.now())
                                                date.setHours(hour)
                                                date.setMinutes(minute)
                                                setValue('enddatetime', date.toISOString())
                                                return e
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="mb-6">
                                <label className="fs-5 mb-1">Description</label>
                                <textarea
                                    rows={5}
                                    className={`form-control form-control-sm ${errors.description && 'form-error'}`}
                                    {...register('description')} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-stack mb-3">
                        <div className="fs-5">Voucher codes</div>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                + Add code
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1" onClick={() => setValueCode({
                                    couponid: valueid,
                                    couponcode: dataQuery.code,
                                    pin: '',
                                    limit: 1,
                                    statuscode: 1
                                })}>
                                    <div>
                                        <div className="fw-bold">Manual</div>
                                        <div>Manually enter the voucher code.</div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2" onClick={() => setValueCode({
                                    quantity: 1,
                                    couponid: valueid,
                                    couponcode: dataQuery.code,
                                    pin: '',
                                    limit: 1,
                                    statuscode: 1
                                })}>
                                    <div>
                                        <div className="fw-bold">Auto-generate codes</div>
                                        <div>Generate multiple codes at once</div>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <table className="table table-bordered border-secondary g-3">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th className="w-100px">Limit</th>
                                <th className="w-40px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginateArray(codesData || [], page.ind, page.limit)
                                .map((item: any, ind: number) => <tr key={ind}>
                                    <td> {item.pin} </td>
                                    <td> {item.limit} </td>
                                    <td className="p-0 text-center">
                                        <button
                                            type="button"
                                            className="btn btn-link py-0 text-danger fw-bold"
                                            onClick={() => removeCode(item.id)}>
                                            -
                                        </button>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                    <PaginationCustom
                        totalData={(codesData || []).length}
                        pageSize={page.limit}
                        page={page.ind}
                        gotoPage={(n) => setPage(prev => ({ ...prev, ind: n }))}
                        setPageSize={(n) => setPage(prev => ({ ...prev, limit: n }))}
                    />
                    <div className="my-4">
                        <div className="fs-5 mb-3">Discount Type</div>
                        {optionDiscountType.map((item, index) => <div key={index} className="d-inline-flex gap-3 mb-3 me-3">
                            <input className="form-check-input" type="radio" value={item.value} {...register(`type`)} />
                            <label className="form-label mb-0"> {item.label} </label>
                        </div>)}
                    </div>
                    {['fixed-amount', 'percentage'].includes(watch('type'))
                        && <div className="mb-6">
                            <div className="fs-5">Value</div>
                            <div className="d-flex align-items-center gap-3">
                                <div className="bg-secondary rounded h-35px d-flex gap-2 px-2 align-items-center">
                                    <span className={clsx(
                                        "px-2 py-1 d-flex align-items-center rounded w-40px justify-content-center",
                                        watch('type') == 'fixed-amount' ? 'bg-white' : 'bg-secondary'
                                    )}> VND </span>
                                    <span className={clsx(
                                        "px-2 py-1 d-flex align-items-center rounded w-40px justify-content-center",
                                        watch('type') == 'percentage' ? 'bg-white' : 'bg-secondary'
                                    )}> % </span>
                                </div>
                                {watch('type') == 'fixed-amount'
                                    ? <InputPrice
                                        placeholder="Discount Value"
                                        className="form-control form-control-sm w-200px"
                                        value={watch('discountvalue') ? Number(watch('discountvalue')) : 0}
                                        onChange={(e) => setValue('discountvalue', String(e))}
                                    />
                                    : <input
                                        type="number"
                                        min={0}
                                        max={100}
                                        placeholder="Discount Value"
                                        className="form-control form-control-sm w-200px"
                                        {...register('discountvalue')}
                                    />
                                }
                            </div>
                        </div>}
                    <div className="mb-6">
                        <div className="fs-5 mb-3">Voucher Specific Information</div>
                        <div className="d-flex gap-3 mb-4">
                            <input className="form-check-input" type="radio" value={'all-product'} id="all-product" {...register('voucherspecific')} />
                            <label className="form-label mb-0" htmlFor="all-product">
                                All products
                            </label>
                        </div>
                        <div className="d-flex gap-3 mb-4">
                            <input className="form-check-input" type="radio" value={'specific-products'} id="specific-products" {...register('voucherspecific')} />
                            <label className="form-label mb-0" htmlFor="specific-products">
                                Specific products
                            </label>
                        </div>
                        <div className="form-check form-check-custom align-items-center gap-2 mb-4">
                            <input className="form-check-input" type="checkbox" id="singlecheapest" {...register('isonlytosinglecheapest')} />
                            <label className="form-label mb-0" htmlFor="singlecheapest">
                                Apply only to a single cheapest eligible product<br />
                                If this option is disabled, discount will be counted for every eligible product
                            </label>
                        </div>
                    </div>
                    {watch('voucherspecific') == 'specific-products' && <>
                        <div className="d-flex gap-4 fs-5 mb-3">
                            <div
                                onClick={() => { setTabActive('category') }}
                                className={clsx('cursor-pointer', {
                                    'text-dark': tabActive != 'category',
                                    'fw-bold text-primary border-bottom border-2 border-primary': tabActive == 'category'
                                })}
                            >
                                Categories {tcategory.length > 0 ? `(${tcategory.length})` : null}
                            </div>
                            <div
                                onClick={() => { setTabActive('subcategory') }}
                                className={clsx('cursor-pointer', {
                                    'text-dark': tabActive != 'subcategory',
                                    'fw-bold text-primary border-bottom border-2 border-primary': tabActive == 'subcategory'
                                })}
                            >
                                Sub Categories {tsubcategry.length > 0 ? `(${tsubcategry.length})` : null}
                            </div>
                            <div
                                onClick={() => { setTabActive('product') }}
                                className={clsx('cursor-pointer', {
                                    'text-dark': tabActive != 'product',
                                    'fw-bold text-primary border-bottom border-2 border-primary': tabActive == 'product'
                                })}
                            >
                                Products {tproduct.length > 0 ? `(${tproduct.length})` : null}
                            </div>
                        </div>
                        {tabActive == 'product' && <div className="mb-6">
                            <div className="d-flex flex-stack align-items-center mb-2">
                                <div className="fs-5">Eligible Products</div>
                                <button type="button" onClick={() => addItem('sku')} className="btn btn-sm btn-link text-primary" >
                                    Assign Product
                                </button>
                            </div>
                            <table className="table table-bordered border-secondary g-2">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th style={{ width: '150px' }}>Unit of measure</th>
                                        <th style={{ width: '40px' }}></th>
                                    </tr>
                                </thead>
                                <tbody>{(watch(`specificproducts`) || [])
                                    .map((item: any, ind: number) => {
                                        if (item.type != 'sku') return null;
                                        let textValue = 'Choose product'
                                        if (watch(`specificproducts.${ind}.value`)) {
                                            const temp = hospitemsData?.find((f: any) => f.code == watch(`specificproducts.${ind}.value`))
                                            textValue = temp?.name || 'Choose product'
                                        }

                                        return <tr key={ind}>
                                            <td className="p-0">
                                                <div
                                                    className="text-primary ps-2 cursor-pointer fs-7"
                                                    onClick={() => {
                                                        setTypeData('sku')
                                                        setValueSelected(`specificproducts.${ind}.value`)
                                                    }}
                                                >
                                                    {textValue}
                                                </div>
                                            </td>
                                            <td className="p-0 ps-2">
                                                {watch(`specificproducts.${ind}.unitofmeasure`)}
                                            </td>
                                            <td className="p-0 text-center w-50px">
                                                <button
                                                    type="button"
                                                    className="btn btn-link text-danger fw-bold w-100 h-100"
                                                    onClick={() => removeItem(item.id)}>
                                                    -
                                                </button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>}
                        {tabActive == 'category' && <div className="mb-6">
                            <div className="d-flex flex-stack align-items-center mb-2">
                                <div className="fs-5">Eligible Categories</div>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-link text-primary"
                                    onClick={() => addItem('category')}>
                                    Assign Category
                                </button>
                            </div>
                            <table className="table table-bordered border-secondary g-3">
                                <thead>
                                    <tr>
                                        <th>Category Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{(watch(`specificproducts`) || [])
                                    .map((item: any, ind: number) => {
                                        if (item.type != 'category') return null;
                                        let textValue = 'Choose category'
                                        if (watch(`specificproducts.${ind}.value`)) {
                                            const temp = categoriesData?.find((f: any) => f.code == watch(`specificproducts.${ind}.value`))
                                            textValue = temp?.name || 'Choose category'
                                        }

                                        return <tr key={ind}>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn text-primary ps-2 py-0 fs-7"
                                                    onClick={() => {
                                                        setTypeData('category')
                                                        setValueSelected(`specificproducts.${ind}.value`)
                                                    }}
                                                >
                                                    {textValue}
                                                </button>
                                            </td>
                                            <td className="p-0 text-center w-50px">
                                                <button
                                                    type="button"
                                                    className="btn btn-link text-danger fw-bold w-100 h-100"
                                                    onClick={() => removeItem(item.id)}>
                                                    -
                                                </button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>}
                        {tabActive == 'subcategory' && <div className="mb-6">
                            <div className="d-flex flex-stack align-items-center mb-2">
                                <div className="fs-5">Eligible Sub Categories</div>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-link text-primary"
                                    onClick={() => addItem('subcategory')}>
                                    Assign Sub Category
                                </button>
                            </div>
                            <table className="table table-bordered border-secondary g-3">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{(watch(`specificproducts`) || [])
                                    .map((item: any, ind: number) => {
                                        if (item.type != 'subcategory') return null;
                                        let textValue = 'Choose sub category'
                                        if (watch(`specificproducts.${ind}.value`)) {
                                            const temp = subCategoriesData?.find((f: any) => f.code == watch(`specificproducts.${ind}.value`))
                                            textValue = temp?.name || 'Choose sub category'
                                        }

                                        return <tr key={ind}>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn text-primary ps-2 py-0 fs-7"
                                                    onClick={() => {
                                                        setTypeData('subcategory')
                                                        setValueSelected(`specificproducts.${ind}.value`)
                                                    }}
                                                >
                                                    {textValue}
                                                </button>
                                            </td>
                                            <td className="p-0 text-center w-50px">
                                                <button
                                                    type="button"
                                                    className="btn btn-link text-danger fw-bold w-100 h-100"
                                                    onClick={() => removeItem(item.id)}>
                                                    -
                                                </button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </>}
                    <div className="mb-6">
                        <div className="fs-5 mb-3">Minimum Requirements</div>
                        <div className="form-check form-check-custom align-items-center gap-2 mb-4">
                            <input className="form-check-input" type="radio" value={'none'} id="none" {...register('minrequirement')} />
                            <label className="form-label mb-0" htmlFor="none">
                                None
                            </label>
                        </div>
                        <div className="form-check form-check-custom align-items-center gap-2 mb-4">
                            <input className="form-check-input" type="radio" value={'minimum-order-value'} id="mini-2" {...register('minrequirement')} />
                            <label className="form-label mb-0" htmlFor="mini-2">
                                Minimum order value
                            </label>
                        </div>
                        <div className="form-check form-check-custom align-items-center gap-2 mb-4">
                            <input className="form-check-input" type="radio" value={'minimum-quantity-of-items'} id="mini-3" {...register('minrequirement')} />
                            <label className="form-label mb-0" htmlFor="mini-3">
                                Minimum quantity of items
                            </label>
                        </div>
                        {['minimum-order-value', 'minimum-quantity-of-items'].includes(watch("minrequirement"))
                            && <input
                                type="number"
                                placeholder="Minimum quantity of items"
                                className={`form-control form-control-sm mb-4 w-200px`} {...register('minrequirementvalue')}
                            />}
                    </div>
                    <div>
                        <div className="fs-5 mb-3">Usage Limits</div>
                        <div className="form-check form-check-custom align-items-center gap-2 mb-4">
                            <input className="form-check-input" type="checkbox" id="check-1" {...register('islimitnumbertimes')} />
                            <label className="form-label mb-0" htmlFor="check-1">
                                Limit number of times this discount can be used in total
                            </label>
                        </div>
                        <div className="form-check form-check-custom align-items-center gap-2 mb-4">
                            <input className="form-check-input" type="checkbox" id="check-2" {...register('islimitonepermember')} />
                            <label className="form-label mb-0" htmlFor="check-2">
                                Limit to one use per customer
                            </label>
                        </div>
                        <div className="form-check form-check-custom align-items-center gap-2 mb-4">
                            <input className="form-check-input" type="checkbox" id={`check-3`} {...register('islimituseonce')} />
                            <label className="form-label mb-0" htmlFor={`check-3`}>
                                Limit to voucher code use once
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        {codeValue && <ModalAddCode value={codeValue} onChange={onSaveCode} />}
        <ModalListSelectedMutiProduct
            hospitemsData={hospitemsData || []}
            categoriesData={categoriesData || []}
            subCategoriesData={subCategoriesData || []}
            isMultiple={isMultiple}
            typeData={typeData}
            watch={watch}
            valueSelected={valueSelected}
            onSave={(value) => {
                if (valueSelected) {
                    if (!isMultiple && value.at(0)?.type == 'hospitems') {
                        setValue([...valueSelected.split('.').slice(0, 2), 'unitofmeasure'].join('.') as any, value.at(0)?.unitofmeasure)
                    }
                    setValue(valueSelected as any, isMultiple ? value.map((val: any) => val?.value).join(',') : value.at(0)?.value)
                }
                setValueSelected(undefined)
                setIsMultiple(false)
            }}
            onClose={() => {
                setValueSelected(undefined)
                setIsMultiple(false)
            }}
        />
    </>);
}

export function ModalAddCode({ value, onChange }: any) {
    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm({
        defaultValues: value,
        resolver: yupResolver(yup.object().shape({
            pin: yup.string().required(),
            limit: yup.number().required(),
            quantity: yup.number().test('check', (val, ctx) => {
                if (!val && value?.quantity) {
                    return ctx.createError()
                }
                return true
            }).transform(val => val || null)
        }))
    });

    return <Modal show={!!value} onHide={() => onChange()} centered>
        <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title>Add Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="mb-3">
                <label className="form-label">Pin</label>
                <input className={clsx("form-control form-control-sm", { 'form-error': errors.pin })} {...register('pin')} />
            </div>
            <div className="mb-3">
                <label className="form-label">Limit</label>
                <input
                    type="number"
                    className={clsx("form-control form-control-sm", { 'form-error': errors.limit })}
                    value={watch("limit")}
                    onChange={(e) => {
                        const val = e.target.value
                        if (val && Number(val) < 0) return;
                        setValue('limit', val)
                    }}
                />
            </div>
            {value?.quantity > 0 && <div className="mb-3">
                <label className="form-label">Quantity (max 50)</label>
                <input
                    type="number"
                    className={clsx("form-control form-control-sm", { 'form-error': errors.quantity })}
                    value={watch("quantity")}
                    onChange={(e) => {
                        const val = e.target.value
                        if (val && Number(val) < 0 || Number(val) > 50) return;
                        setValue('quantity', val)
                    }}
                />
            </div>}
            <div className="d-flex justify-content-end gap-2">
                <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => onChange()}>
                    Cancel
                </button>
                <button type="button" className="btn btn-sm btn-primary" onClick={handleSubmit((data) => onChange(data))}>
                    Save
                </button>
            </div>
        </Modal.Body>
    </Modal>
}
