import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useParams } from "react-router-dom";
import { ApiService, formatCurrent, getTextCurrent, jsUcfirst } from "../../../theme/helpers";
import { DivRoot, KTCard, KTSVG } from "../../../theme/partials";
import { useAuth } from "../../modules/auth";
import { KTItem } from "./component";
import { LocalData } from "../../modules/api/localData";

const RequestPrint = () => {
    
    const { id } = useParams()
    const [dataApi, setDataApi] = useState<any>({})
   
    // console.log('id',id)
    
    async function getData(data:any) {
        let tempData = data as any
        setDataApi(tempData)
        setTimeout(function () { window.print(); }, 500);
        window.onafterprint = function(event) { window.close() };
    }
    
    useEffect(() => { 
        ApiService.get(`payments/paymentrequests/${id}/?expand=paymentrequestlines,users`)
        .then(({ data }) => {
            getData(data)
            
        })
        .catch(({ response }) => {
          console.log("err", response);
        });
        
            
    }, [])
    const {auth} = useAuth()
    const {user} = auth || {}
    const totalVatAmount = () => {
        const {PaymentRequestLines} = dataApi
        let roundNum = checkCurrencyVND()?0:2
        return Number(PaymentRequestLines?.reduce(function (acc:any, obj:any) { return acc + +(obj.vatamount) }, 0).toFixed(roundNum))
    }
    const totalAmountExclVat = () => {
        const {PaymentRequestLines} = dataApi
        let roundNum = checkCurrencyVND()?0:2
        return Number(PaymentRequestLines?.reduce(function (acc:any, obj:any) { return acc + +(obj.amountexclvat) }, 0).toFixed(roundNum)) 
    }
    const totalAmountinclVat = () => {
        const {PaymentRequestLines} = dataApi
        return PaymentRequestLines?.reduce(function (acc:any, obj:any) { return acc + +(obj.amountinclvat) }, 0)   
    }
    const totalAmountPayment = () => {
        const {totalamount,guaranteeamount,discountamount,advancedamount} = dataApi
        let roundNum = checkCurrencyVND()?0:2
        let a = Number((totalamount - (+Math.abs(advancedamount))).toFixed(roundNum))
        // console.log('totalAmountPayment',getTextCurrent(3456))
        return a
    }
    const getTextMoney = (money: any) => {
        let a = money.toString().split('.')
        // console.log('a',a)
        // console.log('money',money)
        let text = ''
        a.forEach((i: any,index: number) => {
            if(index > 0) {
                text = text+' chấm '+ getTextCurrent(i)
            }
            else {
                text = getTextCurrent(i)
            }
            
        })
        // console.log('text',text)
        return text
    }
    const checkCurrencyVND = () => {
       return dataApi?.currencytype == 284210000 || !dataApi.currencytype
    }
    return <>
        <div className={clsx('position-relative ',{'container-fluid': isTablet})}>
            <div className="position-relative print-pdf">
                <KTCard>
                    <div className="container-fluid">
                        <div className="row g-0">
                            <div className="col-7 text-end">
                                <img className="w-100px" src="/media/images/logo-tnf-black-1.png" alt="" />

                            </div>
                            <div className="col-2"></div>
                            <div className="col-3">
                                <div className="mb-3">
                                    <span>Số/Number: </span>
                                    <span>{dataApi.documentno}</span>
                                </div>
                                <div>
                                    <span>Ngày: </span>
                                    <span>{new Date().toLocaleDateString('en-Gb',{ day: '2-digit', month: '2-digit', year: 'numeric'})}</span>
                                </div>
                                {
                                        dataApi.status != '284210002' &&
                                        <div className="mt-2 btn btn-sm border-1 border-solid border border-black mb-3 fw-bold">
                                            {dataApi.status != '284210003' ?'Bản nháp':'Đã bị từ chối'}
                                        </div>
                                    }
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0 mt-2">
                        <div className="container-fluid">
                            {dataApi && 
                            <div className="row mb-2">
                                <div className="col-12 mb-2">
                                    <h2 className="text-center">PHIẾU ĐỀ NGHỊ THANH TOÁN</h2>
                                </div>

                            </div>
                            }
                            <div className="row">
                                <div className="col-12">
                                    <KTItem className="mb-0 fw-bold" labelWidth="200" title='Đối tượng nhận tiền/Beneficiary:'>
                                        <span className="fw-normal">{dataApi.sourcename}</span>
                                    </KTItem>
                                    <KTItem className="mb-0 fw-bold fs-6" labelWidth="350" title='Xin đề nghị thanh toán/Propose to claim the expense of:'>
                                        <span className="fw-normal">{dataApi.expensename}</span>
                                    </KTItem>
                                    <KTItem className="mb-0 fw-bold" labelWidth="250" title='Hình thức thanh toán/ Payment method:'>
                                        <div className="d-flex flex-stack">
                                            <span className="fw-normal me-10">
                                                <div className="form-check-sm form-check form-check-custom form-check-solid">
                                                    <span className="me-3">Tiền mặt/Cash</span>
                                                    <input className="form-check-input check_input cursor-pointer disabled" type="checkbox" onChange={e=>{}} checked={dataApi.paymentmethodcode == 284210000} value="" />
                                                </div>
                                            </span>
                                            <span className="fw-normal">
                                                <div className="form-check-sm form-check form-check-custom form-check-solid">
                                                    <span className="me-3">Chuyển khoản/Bank Transfer</span>
                                                    <input className="form-check-input check_input cursor-pointer disabled" type="checkbox" onChange={e=>{}} checked={dataApi.paymentmethodcode == 284210001} value="" />
                                                </div>
                                            </span>
                                        </div>
                                    </KTItem>
                                    <KTItem labelWidth="200" className="mb-0 fw-bold" title='Tên TK/Account name:'>
                                        <span className="fw-normal">{dataApi.bankaccountname}</span>
                                    </KTItem>
                                    <KTItem labelWidth="200" className="mb-0 fw-bold" title='Số TK/Account no:'>
                                        <span className="fw-normal">{dataApi.bankaccountno}</span>
                                    </KTItem>
                                    <KTItem labelWidth="200" className="mb-0 fw-bold" title='Ngân hàng/Bank:'>
                                        <span className="fw-normal">{dataApi.bankname}</span>
                                    </KTItem>
                                    <table className="table-print w-100">
                                        <thead className="fw-bold">
                                            <tr>
                                                <th>Stt</th>
                                                <th className="">Diễn giải</th>
                                                <th className="60px">Mã phòng ban</th>
                                                <th className="">Mã chi phí</th>
                                                <th className="">ĐVT</th>
                                                <th className="w-40px text-center">Số lượng</th>
                                                <th className="w-100px">Đơn giá ({checkCurrencyVND() ? 'chưa VAT': LocalData.currencyMethod[dataApi.currencytype]?.label})</th>
                                                {
                                                    checkCurrencyVND() &&
                                                    <th className="w-40px text-center">VAT</th>
                                                }
                                                <th className="w-100px">Thành tiền ({checkCurrencyVND() ? 'chưa VAT': LocalData.currencyMethod[dataApi.currencytype]?.label})</th>
                                                <th className="">Số {checkCurrencyVND() ? 'hóa đơn': 'hợp đồng'}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {
                                                dataApi?.PaymentRequestLines?.map((item:any,index: number)=>(
                                                <DivRoot key={index}>
                                                    <tr>
                                                        <td className="text-center">{index+1}</td>
                                                        <td className="">
                                                            {
                                                                item.remark ? 
                                                                <div>{item.remark}</div>
                                                                :
                                                                <div>{item.description}</div>
                                                            }
                                                        </td>
                                                        <td className="text-center">{item.globaldimension1value}</td>
                                                        <td className="text-center">{item.expensevaluecode}</td>
                                                        <td className="text-center">{item.uom}</td>
                                                        <td className="text-center">{item.quantity}</td>
                                                        <td className="text-center">{formatCurrent(item.unitprice)}</td>
                                                        {
                                                            checkCurrencyVND() &&
                                                            <td className="text-end">{item.vatperc}</td>
                                                        }
                                                        <td className="text-end">{formatCurrent(+((+item.amountexclvat).toFixed(checkCurrencyVND()?0:2)))}</td>
                                                        <td className="text-center">{item.invoiceno}</td>
                                                    </tr>
                                                    
                                                </DivRoot>
                                                ))
                                            }
                                            {/* <tr className="fw-bold">
                                                <td colSpan={8}>Ghi chú</td>
                                                <td className="text-end" >{item.remark}</td>
                                                <td className="text-end" ></td>
                                            </tr> */}
                                            <tr className="fw-bold">
                                                <td colSpan={checkCurrencyVND() ? 8: 7}>Tổng cộng (chưa VAT)/Total (excl. VAT)</td>
                                                <td className="text-end" >{formatCurrent(totalAmountExclVat())}</td>
                                                <td className="text-end" ></td>
                                            </tr>
                                            {
                                                checkCurrencyVND() &&
                                                <tr className="fw-bold">
                                                    <td colSpan={checkCurrencyVND() ? 8: 7}>Thuế VAT:</td>
                                                    <td className="text-end" >{formatCurrent(totalVatAmount())}</td>
                                                    <td className="text-end" ></td>
                                                </tr>
                                            }
                                            
                                            { dataApi.discountamount && 
                                             <tr className="fw-bold">
                                                <td colSpan={checkCurrencyVND() ? 8: 7}>Trừ {+dataApi.discountpct}% chiết khấu:</td>
                                                <td className="text-end" >{formatCurrent(+dataApi.discountamount)}</td>
                                                <td className="text-end" ></td>
                                            </tr>
                                            }
                                            
                                            { dataApi.guaranteeamount && 
                                                <tr className="fw-bold">
                                                    <td colSpan={checkCurrencyVND() ? 8: 7}>Trừ {+dataApi.guaranteepct}% bảo hành:</td>
                                                    <td className="text-end" >{formatCurrent(+dataApi.guaranteeamount)}</td>
                                                    <td className="text-end" ></td>
                                                </tr>
                                            }
                                            {
                                                dataApi.advancedamount &&
                                                <tr className="fw-bold">
                                                    <td colSpan={checkCurrencyVND() ? 8: 7}>Trừ tạm ứng:</td>
                                                    <td className="text-end" >{formatCurrent(Math.abs(dataApi.advancedamount))}</td>
                                                    <td className="text-end" ></td>
                                                </tr>
                                            }
                                            
                                            <tr className="fw-bold">
                                                <td colSpan={checkCurrencyVND() ? 8: 7}>Tổng số tiền thanh toán/ Total to be claimed</td>
                                                <td className="text-end" >
                                                    {totalAmountPayment() >= 0 ? formatCurrent(totalAmountPayment()):
                                                    `(${formatCurrent(Math.abs(totalAmountPayment()))})`
                                                    }
                                                </td>
                                                <td className="text-end" ></td>
                                            </tr>
                                            <tr className="fw-bold">
                                                <td colSpan={2}>Bằng chữ</td>
                                                <td colSpan={checkCurrencyVND() ? 8: 7} className="" >{getTextCurrent(Math.abs(totalAmountPayment())) && jsUcfirst(getTextMoney((Math.abs(totalAmountPayment())))) } {checkCurrencyVND() ? 'đồng': LocalData.currencyMethod[dataApi.currencytype]?.label}</td>
                                            </tr>
                                        </tbody>
                                        {/* <tfoot className="visibility-hidden">
                                            <tr className="">
                                                <td className="border-0" colSpan={10}>
                                                    <div className="page-footer-space h-20px">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot> */}
                                    </table>
                                </div>
                                
                               
                            </div>
                            <div className="row g-0 page-break-avoid ">
                                {
                                    dataApi.status == '284210002' && 
                                    <div className="col-12 mt-2">
                                        <div className="row g-0 fw-bold">
                                            <div className="col-3 text-center">
                                                <div className="border-bottom-0 p-2 border border-black min-h-60px d-flex flex-center">TRƯỞNG BỘ PHẬN</div>
                                                <div className="border-bottom-0 p-2 border border-black">1</div>
                                                <div className="h-100px p-2 border border-black">
                                                    {
                                                       dataApi.User?.signature && <img className="object-contant w-100 h-100" src={dataApi.User?.signature} alt="" />
                                                    }
                                                </div>
                                                <div className=" p-2 border-top-0 border border-black h-30px"></div>
                                            </div>
                                            <div className="col-3 text-center">
                                                <div className="border-bottom-0 border-left-0 p-2 border border-black min-h-60px d-flex flex-center">PHÒNG TÀI CHÍNH - KẾ TOÁN</div>
                                                <div className="border-bottom-0 border-left-0 p-2 border border-black">2</div>
                                                <div className="h-100px p-2 border border-left-0 border-black"></div>
                                                <div className=" p-2 border-top-0 border-left-0 border border-black h-30px"></div>

                                            </div>
                                            <div className="col-3 text-center">
                                                <div className="border-bottom-0 p-2 border-left-0 border border-black min-h-60px d-flex flex-center">GIÁM ĐỐC</div>
                                                <div className="border-bottom-0 p-2 border-left-0 border border-black">3</div>
                                                <div className="h-100px p-2 border border-left-0 border-black"></div>
                                                <div className=" p-2 border-top-0 border-left-0 border border-black h-30px"></div>
                                            </div>
                                            <div className="col-3 text-center">
                                                <div className="border-bottom-0 p-2 border-left-0 border border-black min-h-60px d-flex flex-center">CHỦ TỊCH/ TỔNG GIÁM ĐỐC</div>
                                                <div className="border-bottom-0 p-2 border-left-0 border border-black">4</div>
                                                <div className="h-100px p-2 border border-left-0 border-black"></div>
                                                <div className=" p-2 border-top-0 border-left-0 border border-black h-30px"></div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                }
                                <div className="col-12 mt-2">
                                    <div><strong>Ghi chú:</strong></div>
                                    <div><strong>Phạm vi áp dụng: Toàn hệ thông tập đoàn</strong></div>
                                    <div><strong>Nguyên tác kiểm soát tài chính tập đoàn: Độc lập và tài chính tập trung</strong></div>
                                    <div><strong>Kế toán kiểm tra việc nghiêm túc biểu mẫu này, áp dụng từ tháng 6 năm 2012</strong></div>
                                    <div><strong>Ký, ghi rõ họ tên đầy đủ các ô theo thứ tự quy định sau:</strong></div>
                                    <div>(1): Người đề nghị ký nháy, trưởng BP ký chính thức</div>
                                    <div>(2): Kế toán trưởng đơn vị hoặc tập đoàn</div>
                                    <div>(3): Giám đốc điều hành (công ty con/ CN trực thuộc tập đoàn hay Giám đốc nhà máy hoặc Giám đốc khối/ Bộ phận)</div>
                                    <div>(4): Phòng kiểm soát tài chinh của tập đoàn do CFO/ FM ký</div>
                                    <div>(4): Chủ tịch tập đoàn/ Tổng giám đốc tập đoàn ký</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </KTCard>
               
            </div>
            
            
        </div>
    </>
}

export default RequestPrint;