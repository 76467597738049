import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useParams } from "react-router-dom";
import { ApiService, formatCurrent, getTextCurrent } from "../../../theme/helpers";
import { DivRoot, KTCard, KTSVG } from "../../../theme/partials";
import { useAuth } from "../../modules/auth";
import { KTItem } from "./component";

const OrderPrint = () => {

    const { id } = useParams()
    const [dataApi, setDataApi] = useState<any>({})

    // console.log('id',id)

    async function getData(data:any) {
        let tempData = data as any
        setDataApi(tempData)
        setTimeout(function () { window.print(); }, 500);
        window.onafterprint = function(event) { window.close() };
    }

    useEffect(() => {
        ApiService.get(`orders/requestorders/${id}/?expand=requestorderlines,users`)
        .then(({ data }) => {
            getData(data)

        })
        .catch(({ response }) => {
          console.log("err", response);
        });


    }, [])
    const {auth} = useAuth()
    const {user} = auth || {} as any
    // console.log('user',user)
    return <>
        <div className={clsx('position-relative ',{'container-fluid': isTablet})}>
            <div className="position-relative print-pdf">
                <KTCard>
                    <div className="container-fluid">
                        <div className="row g-0">
                            <div className="col-12 text-center">
                                <img className="w-100px" src="/media/images/logo-tnf-black-1.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0 mt-2">
                        <div className="container-fluid">
                            {dataApi &&
                            <div className="row mb-2">
                                <div className="col-6">
                                    {
                                        dataApi.status != '284210002' &&
                                        <div className="btn btn-sm border-1 border-solid border border-black mb-3 fw-bold">
                                            {dataApi.status != '284210003' ?'Bản nháp':'Đã bị từ chối'}
                                        </div>
                                    }
                                    <div className="d-flex">
                                       <div className="me-10 fw-bold">
                                        <div className="me-1">Đơn vị: </div>
                                        <span className="me-1">(CTY/ CN)</span>
                                       </div>
                                        <span>Công ty cổ phần Trung Nguyên Franchising</span>
                                    </div>



                                </div>
                                <div className="col-6 text-end">
                                    <div>
                                        <strong>{dataApi.code}</strong>
                                    </div>
                                    {
                                        dataApi.documentdate &&
                                        <div>
                                            <span className="me-1">Ngày {new Date(dataApi.documentdate).getDate()} Tháng {new Date(dataApi.documentdate).getMonth()+1} Năm {new Date(dataApi.documentdate).getFullYear()}</span>
                                        </div>
                                    }

                                </div>
                                <div className="col-12 mb-2">
                                    <h2 className="text-center">PHIẾU ĐỀ XUẤT</h2>
                                    <div className="text-center">( Áp dụng cho tất cả nhu cầu sử dụng sản phẩm, POSM, ... từ các KHO HÀNG )</div>
                                    <div className="fw-bold text-center">Đề xuất từ: {dataApi.departmentname}</div>
                                </div>

                            </div>
                            }
                            <div className="row">
                                <div className="col-12">
                                    <KTItem className="mb-0 fw-bold" title='Kính gửi:'>
                                        <span className="fw-bold">{dataApi.requestto}</span>
                                    </KTItem>
                                    <KTItem className="mb-0 fw-bold" title='Về việc:'>
                                        <span className="fw-normal">{dataApi.title}</span>
                                    </KTItem>
                                    <KTItem className="mb-0 fw-bold" title='Mục đích:'>
                                        <span className="fw-normal">{dataApi.purpose}</span>
                                    </KTItem>
                                    <KTItem className="mb-0 fw-bold" title='Nội dung đề xuất:'>
                                        {/* <span className="fw-normal me-1">{dataApi.purpose} </span>  */}
                                        <i className="fw-normal"> (Có thể sử dụng khung mẫu theo chức năng, ví dụ: điều chuyển hàng,...)</i>
                                    </KTItem>

                                    <table className="table-print w-100">
                                        <thead className="fw-bold">
                                            <tr>
                                                <th>Stt</th>
                                                <th className="w-100px">Mã hàng</th>
                                                <th>Tên hàng</th>
                                                <th className="w-100px">ĐVT</th>
                                                <th className="w-80px">Số lượng</th>
                                                <th className="w-80px">Đơn giá hệ thống</th>
                                                <th className="w-80px">Đơn giá tham khảo</th>
                                                <th className="w-80px">Thành tiền</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {
                                                dataApi?.RequestOrderLines?.map((item:any,index: number)=>(
                                                <DivRoot key={index}>
                                                    <tr>
                                                        <td className="text-center">{index+1}</td>
                                                        <td className="text-center">{item.itemcode}</td>
                                                        <td>{item.description}</td>
                                                        <td className="text-center">{item.uom}</td>
                                                        <td className="text-center">{item.quantity}</td>
                                                        <td className="text-end">{formatCurrent(item.unitprice)}</td>
                                                        <td className="text-end">{formatCurrent(item.unitpriceref)}</td>
                                                        <td className="text-end">{formatCurrent(Number((+item.unitpriceref *+item.quantity).toFixed(0)))}</td>
                                                    </tr>
                                                    {
                                                        item.remark &&
                                                        <tr>
                                                            <td className="text-center">-</td>
                                                            <td className="" colSpan={7}>{item.remark}</td>
                                                        </tr>
                                                    }

                                                </DivRoot>
                                                ))

                                            }

                                            <tr className="fw-bold">
                                                <td colSpan={7}>Tổng cộng</td>
                                                <td className="text-end" >{formatCurrent(dataApi.totalamount)}</td>
                                            </tr>
                                        </tbody>
                                        {/* <tfoot className="visibility-hidden">
                                            <tr className="">
                                                <td className="border-0" colSpan={10}>
                                                    <div className="page-footer-space h-20px">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot> */}
                                    </table>
                                </div>
                                <div className="col-6">
                                    <KTItem className="mb-0 fw-bold" title='Mã kiểm soát:'>
                                        <span className="fw-normal">{}</span>
                                    </KTItem>
                                </div>
                                <div className="col-3">
                                    <KTItem className="mb-0 fw-bold" title='Mã chi phí:'>
                                        <span className="fw-normal">{}</span>
                                    </KTItem>
                                </div>
                                <div className="col-3">
                                    <KTItem className="mb-0 fw-bold" title='Mã chương trình:'>
                                        <span className="fw-normal">{}</span>
                                    </KTItem>
                                </div>
                                <div className="col-6">
                                    <KTItem className="mb-0 fw-bold" title='Mã khối/BP:'>
                                        <span className="fw-normal">{}</span>
                                    </KTItem>
                                </div>
                                <div className="col-6">
                                    <KTItem className="mb-0 fw-bold" title='Tên phòng ban:'>
                                        <span className="fw-normal">{dataApi.departmentname}</span>
                                    </KTItem>
                                </div>
                                <div className="col-6">
                                    <KTItem className="mb-0 fw-bold" title='Mã ngân sách:'>
                                        <span className="fw-normal">{}</span>
                                    </KTItem>
                                </div>
                                <div className="col-6">
                                    <KTItem className="mb-0 fw-bold" title='Tên ngân sách:'>
                                        <span className="fw-normal">{}</span>
                                    </KTItem>
                                </div>
                                <div className="col-6">
                                    <KTItem className="mb-0 fw-bold" title='Thời gian cần:'>
                                        <span className="fw-normal">{dataApi.expecteddate && new Date(dataApi.expecteddate).toLocaleDateString('en-Gb',{ day: '2-digit', month: '2-digit', year: 'numeric'})}</span>
                                    </KTItem>
                                </div>
                                <div className="col-12">
                                    <label className="text-black"><strong>Ngân sách quỹ công ty:</strong> (<i>Có thể sử dụng khung mẫu theo chức năng hoặc là điều chuyển hàng</i>)</label>
                                    <table className="table-print w-100">
                                        <thead className="fw-bold">
                                            <tr>
                                                <th>Nội dung</th>
                                                <th className="">NS duyệt đầu năm</th>
                                                <th>NS sử dụng đề xuất này</th>
                                                <th className="">Lũy kế đã sử dụng</th>
                                                <th className="">NS bổ sung/ BC</th>
                                                <th className="">NS còn lại</th>
                                                <th className="">Ghi chú</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            <tr>
                                                <td className="fw-bold">Trình ngân sách</td>
                                                <td></td>
                                                <td className="text-end">{formatCurrent(dataApi.totalamount)}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={7}>Hồ sơ, tài liệu, biên bản liên quan đính kèm</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row g-0 page-break-avoid ">
                                {
                                    dataApi.status == '284210002' &&
                                    <div className="col-12 mt-2">
                                        <div className="row g-0 fw-bold">
                                            <div className="col-4 text-center">
                                                <div className="border-bottom-0 p-2 border border-black">ĐỀ XUẤT</div>
                                                <div className="border-bottom-0 p-2 border border-black">Trưởng bộ phận</div>
                                                <div className="h-100px p-2 border border-black">
                                                    {
                                                       dataApi.User?.signature && <img className="object-contant w-100 h-100" src={dataApi.User?.signature} alt="" />
                                                    }
                                                </div>
                                                <div className=" p-2 border-top-0 border border-black h-30px"></div>
                                            </div>
                                            <div className="col-4 text-center">
                                                <div className="border-bottom-0 border-left-0 p-2 border border-black">SOÁT XÉT ĐỒNG THUẬN</div>
                                                <div className="border-bottom-0 border-left-0 p-2 border border-black">Phòng TC-KT</div>
                                                <div className="h-100px p-2 border border-left-0 border-black"></div>
                                                <div className=" p-2 border-top-0 border-left-0 border border-black h-30px"></div>

                                            </div>
                                            <div className="col-4 text-center">
                                                <div className="border-bottom-0 p-2 border-left-0 border border-black">PHÊ DUYỆT</div>
                                                <div className="border-bottom-0 p-2 border-left-0 border border-black">Giám đốc</div>
                                                <div className="h-100px p-2 border border-left-0 border-black"></div>
                                                <div className=" p-2 border-top-0 border-left-0 border border-black h-30px"></div>
                                            </div>

                                        </div>
                                    </div>
                                }
                                <div className="col-12 mt-2">
                                    <div><i>Ghi chú</i></div>
                                    <div><i>1. Form đề xuất này thay thế form đề xuất hiện tại và được áp dụng thống nhất cho toàn tập đoàn</i> </div>
                                    <div><i>2. Các chỉ tiêu: mục đích, nội dung đề xuất, mã đơn vị, mã chi phí, mã CT phải được ghi chú đầy đủ</i></div>
                                    <div><i>3. Mục ngân sách: nghiêm túc thực hiện; không kiểm soát được ngân sách, Ban TGD sẽ không ký duyệt</i></div>
                                    <div><i>4. Phiếu đề xuất có hiệu lực khi nội dung PHÊ DUYỆT được kí bởi Cấp có thẩm quyền</i></div>
                                    <div className="text-center mt-3"> <h4>TIẾT KIỆM - HIỆU QUẢ</h4></div>
                                    <div className="text-center"> <h4>LÀ TRÁCH NHIỆM CỦA MỌI THÀNH VIÊN TRUNG NGUYÊN</h4></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </KTCard>

            </div>


        </div>
    </>
}

export default OrderPrint;