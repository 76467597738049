import clsx from 'clsx'
import { FC } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { checkIsActive, WithChildren } from '../../../../helpers'
import { KTSVG } from '../../../../partials/widgets/utils/KTSVG'

interface Props extends WithChildren {
  to: string
  title: string
  icon?: string
  hasBullet?: boolean
  permission: boolean
}

const SidebarMenuItem: FC<Props> = ({ children, to, title, icon, hasBullet = false, permission }) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const isSvg = icon && (icon?.split('media/icons/').length > 1)

  return <div className={clsx('menu-item', { 'd-none': !permission })}>
    <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
      {hasBullet && (
        <span className='menu-bullet'>
          <span className='bullet bullet-dot'></span>
        </span>
      )}
      {icon && <span className='menu-icon'>
        {isSvg
          ? <KTSVG path={icon} className='svg-icon-2' />
          : <i className={clsx('fs-3', icon)}></i>
        }
      </span>}
      <span className='menu-title'>{title}</span>
    </Link>
    {children}
  </div>
}

export { SidebarMenuItem }

