import { yupResolver } from "@hookform/resolvers/yup"
import { FC, useEffect, useState } from "react"
import { Card, Modal, ModalBody } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { Column } from "react-table"
import { ApiService, ApiShowError, changeDataModify, getValueId, sortByUpdateAt, sortWithAny } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, FilterDropdown, FormSubmit, InputSelect, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../theme/partials"
import { IInitField } from "../../api"
import { apiAd } from "../../api/admin"
import { popupMessage } from "../../messages"
import { IAdObject, optionObjectType } from "./objects"
import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl";

export interface IAdPermission extends IInitField {
    rolecode: string | null
    name: string | null
}

const breadCrumbs = [{ title: 'system', path: '/system-settings', }]
const apiurl = 'settings/permissionsets'
export default function AdPermissionsPage() {
    const intl = useIntl()
    const { data, isSuccess, isFetching } = apiAd.usePermissionSets()
    const [tableData, setTableData] = useState<IAdPermission[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching) setTableData(sortByUpdateAt(data.filter((f: any) => f.rolecode != 'SUPER')))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'rolecode',
        Cell: ({ ...props }) => <BasicCell to={props.data[props.row.index].id} data={props.data[props.row.index].rolecode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" to={props.data[props.row.index].id} />
            <RowIcon action="delete" disabled={['ADMIN', 'BASIC'].includes(props.data[props.row.index].rolecode)} onClick={async () => {
                const id = props.data[props.row.index].id
                const items = await ApiService.get(`settings/permissions?permissionsetid=${id}`)
                if (items.data.length) {
                    popupMessage({ icon: 'error', description: intl.formatMessage({ id: 'text.permission-set.delete.error.permissions' }) })
                    return;
                }
                const accessControls = await ApiService.get(`settings/accesscontrols?permissionsetid=${id}`)
                if (accessControls.data.length) {
                    popupMessage({ icon: 'error', description: intl.formatMessage({ id: 'text.permission-set.delete.error.accesscontrols' }) })
                    return;
                }
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <ContentHeader title="system.permission-sets" items={breadCrumbs} />
        <KTTable
            isLoading={isFetching}
            toolbar={<>
                <FilterDropdown>
                </FilterDropdown>
                <KTButton action='new' />
            </>}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search />
    </>
}

const defaultValues: IAdPermission = {
    rolecode: null,
    name: null
}

interface IAdPermissionLine extends IInitField {
    permissionsetid: string | null
    rolecode: string | null
    objecttype: number | null
    objectid: string | null
    objectname: string | null
    readpermission: boolean
    insertpermission: boolean
    modifypermission: boolean
    deletepermission: boolean
    executepermission: boolean
}

interface IAdPermissionExpandItems extends IAdPermission {
    items: IAdPermissionLine[]
}

export const AdPermissionsDetailPage = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching, refetch } = apiAd.usePermissionSetsId<IAdPermissionExpandItems>(valueid)
    const { register, reset, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdPermission>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            rolecode: yup.string().required(),
            name: yup.string().required(),
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    const { data: permissionsData, isSuccess: isPermissions, isFetching: permissionFetch } = apiAd.usePermissionsByQuery(isEdit ? `?permissionsetid=${id}` : undefined)
    const [tableData, setTableData] = useState<IAdPermissionLine[]>([])

    useEffect(() => {
        if (isPermissions && !permissionFetch)
            setTableData(sortByUpdateAt(permissionsData))
    }, [permissionFetch])

    async function savePermissions() {
        try {
            return await ApiService.post('settings/permissions/upsertmulti', tableData)
        } catch (error) {
            ApiShowError(error)
        }
    }

    return <>
        <ContentHeader title="system.permission-sets" items={[...breadCrumbs, { title: 'system.permission-sets', path: '/system-settings/permission-sets' }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IAdPermission) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    const result = await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    if (!isEdit && result.data?.id) navigate(`/system-settings/permission-sets/${result.data?.id}`)
                    if (isEdit) await savePermissions()
                    popupMessage({ icon: 'success', autoClose: true })
                    if (isEdit) return navigate('/system-settings/permission-sets')
                } catch (error) {
                    ApiShowError(error)
                }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        handleClose={() => navigate('/system-settings/permission-sets')} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.rolecode && 'form-error'}`} {...register('rolecode')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                        </KTFormItem>
                    </div>
                </div>
                <hr />
                <PermissionItemsCard {...{
                    permissionData: watch(),
                    tableData,
                    setTableData
                }} />
            </div>
        </form>
    </>
}

interface IPermissionItemsCard {
    permissionData: IAdPermission
    tableData: IAdPermissionLine[]
    setTableData: React.Dispatch<React.SetStateAction<IAdPermissionLine[]>>
}

interface IFormSelectItem {
    objecttype: string | null
    objectid: string | null
}

const defaultSelectItem = {
    objecttype: null,
    objectid: null
}

const PermissionItemsCard: FC<IPermissionItemsCard> = ({ permissionData, tableData, setTableData }) => {
    const [valueData, setValueData] = useState<IFormSelectItem | undefined>()

    const CheckItem = ({ data, field }: {
        data: IAdPermissionLine,
        field: 'readpermission' | 'insertpermission' | 'modifypermission' | 'deletepermission' | 'executepermission'
    }) => {
        let isDisable = (data.objecttype == 20120002 && field != 'executepermission')
        let isHide = (data.objecttype != 20120002 && field == 'executepermission')
        return <div className="d-flex flex-center" >
            <div className={clsx("form-check form-check-custom form-check-solid form-check-sm", {
                // 'form-check-warning': isHide,
            })}>
                <input
                    disabled={isDisable || isHide}
                    className="form-check-input w-15px h-15px"
                    type="checkbox"
                    value=""
                    onChange={() => {
                        setTableData(pre => pre.map(item => item.objectid == data.objectid ? {
                            ...item,
                            [field]: !data[field]
                        } : item))
                    }}
                    checked={data[field]} />
            </div>
        </div>
    }

    const wItem = 'w-70px text-center'
    const columns: Array<Column<IAdPermissionLine>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.object.id' />,
        accessor: 'objectid',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].objectid} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.object.type' />,
        accessor: 'objecttype',
        Cell: ({ ...props }) => <BasicCell data={optionObjectType.find(f => f.value == props.data[props.row.index].objecttype)?.label} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.object.name' />,
        accessor: 'objectname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].objectname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.read' className={wItem} />,
        accessor: 'readpermission',
        Cell: ({ ...props }) => <CheckItem data={props.data[props.row.index]} field="readpermission" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.insert' className={wItem} />,
        accessor: 'insertpermission',
        Cell: ({ ...props }) => <CheckItem data={props.data[props.row.index]} field="insertpermission" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.modify' className={wItem} />,
        accessor: 'modifypermission',
        Cell: ({ ...props }) => <CheckItem data={props.data[props.row.index]} field="modifypermission" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.delete' className={wItem} />,
        accessor: 'deletepermission',
        Cell: ({ ...props }) => <CheckItem data={props.data[props.row.index]} field="deletepermission" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.execute' className={wItem} />,
        accessor: 'executepermission',
        Cell: ({ ...props }) => <CheckItem data={props.data[props.row.index]} field="executepermission" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`settings/permissions/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const { data: objectsData, isSuccess: isObjects } = apiAd.useObjects()
    const mapObjects = tableData.map(item => item.objectid)
    const optionObjects: any[] = isObjects ? sortWithAny(
        objectsData
            .filter((f: any) => !mapObjects.includes(f.objectid) && !f.issuperrole)
            .map((item: any) => ({ ...item, value: item.objectid, label: `${item.objectid} - ${item.objectname}` }))
        , { order: 'asc', sort: 'objectid' }) : []

    function handleClose() {
        setValueData(undefined)
    }

    return <>
        <div className="card border-0 rounded-0">
            <div className="card-header px-0">
                <div className="card-title">
                    <FormattedMessage id="text.objects" />
                </div>
                <div className="card-toolbar">
                    <KTButton disabled={!permissionData || !permissionData?.id} action="new" onClick={() => setValueData(defaultSelectItem)} />
                </div>
            </div>
            <KTTable
                data={tableData}
                columns={columns}
                pagination
            />
        </div>
        {valueData && <Modal show={valueData != undefined} onHide={handleClose} size="lg" centered>
            <Modal.Header>
                <Modal.Title><FormattedMessage id="text.objects.add" /></Modal.Title>
            </Modal.Header>
            <ModalBody>
                <div className="row">
                    <div className="col-12 col-lg-4 mb-6">
                        <KTFormItem title='text.object.type' isBLockLabel>
                            <InputSelect
                                options={optionObjectType}
                                value={optionObjectType.find(f => String(f.value) == valueData.objecttype) || null}
                                onChange={(e) => {
                                    if (!e) return setValueData({ ...valueData, objecttype: null })
                                    setValueData({ ...valueData, objecttype: e.value })
                                }}
                            />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-8 mb-6">
                        <KTFormItem title='text.objects' isBLockLabel>
                            <InputSelect
                                isMulti
                                options={optionObjects.filter(f => {
                                    return f.objecttype == valueData.objecttype
                                })}
                                value={valueData.objectid?.split(',').map(item => optionObjects.find(f => String(f.value) == item)) || null}
                                onChange={(e) => {
                                    if (!e) return setValueData({ ...valueData, objectid: null })
                                    setValueData({ ...valueData, objectid: e.map((item: any) => item.value).join(',') })
                                }}
                            />
                        </KTFormItem>
                    </div>
                </div>
                <div className="d-flex gap-2 flex-end">
                    <button type="button" className="btn btn-sm btn-secondary" onClick={handleClose}><FormattedMessage id='text.cancel' /></button>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                        const objects: IAdObject[] | undefined = valueData.objectid?.split(',').map(item => optionObjects.find(f => String(f.value) == item))
                        if (objects && objects.length) {
                            const temps: IAdPermissionLine[] = objects.map(item => {
                                const isExecute = item.objecttype == 20120002
                                return {
                                    permissionsetid: permissionData.id as any,
                                    rolecode: permissionData.rolecode,
                                    objecttype: item.objecttype,
                                    objectid: item.objectid,
                                    objectname: item.objectname,
                                    readpermission: !isExecute,
                                    insertpermission: !isExecute,
                                    modifypermission: !isExecute,
                                    deletepermission: !isExecute,
                                    executepermission: isExecute
                                }
                            })
                            setTableData([...temps, ...tableData])
                        }
                        handleClose()
                    }}><FormattedMessage id='text.save&close' /></button>
                </div>
            </ModalBody>
        </Modal>}
    </>
}
