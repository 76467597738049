import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ICustomer } from ".";
import { ApiService, ApiShowError, changeDataModify, getValueId, sortByUpdateAt, syncObjects } from "../../../../theme/helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { BasicCell, CustomHeader, ExtendAddressValue, FormSubmit, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../theme/partials";
import { apiPrivate, IInitFieldAddress } from "../../../modules/api";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";
import { Column } from "react-table";
import { Modal } from "react-bootstrap";

const defaultValues: ICustomer = {
    customerno: null,
    customername: null,
    phoneno: null,
    email: null,
    contactname: null,
    creditlimit: null,
    totalsalesexclvat: null,
    balance: null,
    balanceasvendor: null,
    payments: null,
    blocked: null,
    vatregistrationno: null,
    billtocustomerno: null,
    paymenttermscode: null,
    paymentmethodcode: null,
    address: null,
    ward: null,
    district: null,
    province: null,
    country: null,
    zipcode: null,
    state: null
}

const urlApi = 'settings/customers'
export const SettingCustomersForm = ({ keyElement, pathElement, permission }: MergedProps) => {
    const intl = useIntl()
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data: dataQuery, isFetching, isSuccess, refetch } = apiPrivate.useCustomerId<ICustomer>(valueid)
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<ICustomer>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            itemno: yup.string().required(),
            unitprice: yup.number().required(),
            unitcost: yup.number().required(),
            lastdirectcost: yup.number().required()
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching) {
            reset(dataQuery)
        }
    }, [isFetching])

    return <>
        <ContentHeader
            isInfo
            title={keyElement}
            items={[{ title: keyElement, path: pathElement }]}
            elements={<KTButton permission={permission} action="sync" onClick={() => syncObjects(`/bcsync/customerbycustomerno?source=WEBSERVICE&customerno=${dataQuery?.customerno}`, refetch)} />} />
        <form
            className="card mb-6"
            onSubmit={handleSubmit(async (data: ICustomer) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id' >
                            <input className={`form-control ${errors.customerno && 'form-error'}`} {...register('customerno')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input className={`form-control ${errors.customername && 'form-error'}`} {...register('customername')} />
                        </KTFormItem>
                        <ExtendAddressValue {...{
                            register,
                            control,
                            errors,
                            setValue,
                            watch
                        }} />
                        <KTFormItem title='text.phone'>
                            <input className={`form-control ${errors.phoneno && 'form-error'}`} {...register('phoneno')} />
                        </KTFormItem>
                        <KTFormItem title='text.email'>
                            <input className={`form-control ${errors.email && 'form-error'}`} {...register('email')} />
                        </KTFormItem>
                        <KTFormItem title='text.contact.name'>
                            <input className={`form-control ${errors.contactname && 'form-error'}`} {...register('contactname')} />
                        </KTFormItem>
                        <KTFormItem title='text.status'>
                            <span className={`badge badge-${watch('blocked') ? 'danger' : 'success'}`}>
                                {watch('blocked') ? intl.formatMessage({ id: "text.blocked" }) : intl.formatMessage({ id: "text.active" })}
                            </span>
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.credit-limit'>
                            <input className={`form-control ${errors.creditlimit && 'form-error'}`} {...register('creditlimit')} />
                        </KTFormItem>
                        <KTFormItem title='text.total-sales-excl-vat'>
                            <input className={`form-control ${errors.totalsalesexclvat && 'form-error'}`} {...register('totalsalesexclvat')} />
                        </KTFormItem>
                        <KTFormItem title='text.balance'>
                            <input className={`form-control ${errors.balance && 'form-error'}`} {...register('balance')} />
                        </KTFormItem>
                        <KTFormItem title='text.balance-as-vendor'>
                            <input className={`form-control ${errors.balanceasvendor && 'form-error'}`} {...register('balanceasvendor')} />
                        </KTFormItem>
                        <KTFormItem title='text.payments'>
                            <input className={`form-control ${errors.payments && 'form-error'}`} {...register('payments')} />
                        </KTFormItem>
                        <KTFormItem title='text.vat-registration-no'>
                            <input className={`form-control ${errors.vatregistrationno && 'form-error'}`} {...register('vatregistrationno')} />
                        </KTFormItem>
                        <KTFormItem title='text.bill-to-customer-no'>
                            <input className={`form-control ${errors.billtocustomerno && 'form-error'}`} {...register('billtocustomerno')} />
                        </KTFormItem>
                        <KTFormItem title='text.payment-term'>
                            <input className={`form-control ${errors.paymenttermscode && 'form-error'}`} {...register('paymenttermscode')} />
                        </KTFormItem>
                        <KTFormItem title='text.payment-method'>
                            <input className={`form-control ${errors.paymentmethodcode && 'form-error'}`} {...register('paymentmethodcode')} />
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
        {isEdit && valueid && dataQuery && <div className="card card-body">
            <CustomerBankAccountCard customerData={dataQuery} />
        </div>}
    </>
}

interface ICustomerBankAccount extends IInitFieldAddress {
    customerid: string | null
    customerno: string | null
    customername: string | null
    code: string | null
    bankname: string | null
    bankbranchno: string | null
    bankbranchname: string | null
    bankaccountno: string | null
    bankaccountname: string | null
    phoneno: string | null
    email: string | null
}

const CustomerBankAccountCard: FC<{ customerData: ICustomer }> = ({ customerData }) => {
    const urlApi = 'settings/customerbankaccounts'
    const { data, isSuccess, isFetching, refetch } = apiPrivate.useCustomerBankAccountsByQuery(customerData?.id ? `?customerno=${customerData.customerno}` : undefined)
    const [tableData, setTableData] = useState<ICustomerBankAccount[]>([])
    const [valueData, setValueData] = useState<ICustomerBankAccount | undefined>()

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => setValueData(props.data[props.row.index])}
            data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'bankname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].bankname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.bank.account.no' />,
        accessor: 'bankaccountno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].bankaccountno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.bank.account.name' />,
        accessor: 'bankaccountname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].bankaccountname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" onClick={() => setValueData(props.data[props.row.index])} />
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <KTTable
            toolbar={<>
                <KTButton action="sync" onClick={() => syncObjects(`/bcsync/customerbankaccountsbycustomerno?source=WEBSERVICE&customerno=${customerData.customerno}&customerid=${customerData.id}`, refetch)} />
                <KTButton action="new" onClick={() => {
                    customerData?.id && setValueData({
                        customerid: customerData.id,
                        customerno: customerData.customerno,
                        customername: customerData.customername,
                        code: null,
                        bankname: null,
                        bankbranchno: null,
                        bankbranchname: null,
                        bankaccountno: null,
                        bankaccountname: null,
                        phoneno: null,
                        email: null,
                        address: null,
                        ward: null,
                        district: null,
                        province: null,
                        country: null,
                        zipcode: null,
                        state: null
                    })
                }} />
            </>}
            title={<FormattedMessage id="text.banks" />}
            data={tableData}
            columns={columns} />
        {valueData && <CustomerBankAccountModal {...{
            tableData,
            urlApi,
            valueData,
            handleClose() {
                setValueData(undefined)
            },
            handleSave(type, value) {
                setTableData(pre => type
                    ? sortByUpdateAt(pre.map(item => item.id == value.id ? value : item))
                    : [value, ...pre]
                )
            },
        }} />}
    </>
}

interface ICustomerBankAccountModal {
    tableData: ICustomerBankAccount[]
    urlApi: string
    valueData: ICustomerBankAccount
    handleSave: (type: boolean, value: ICustomerBankAccount) => void
    handleClose: () => void
}

const CustomerBankAccountModal: FC<ICustomerBankAccountModal> = ({ tableData, urlApi, valueData, handleSave, handleClose }) => {
    const { valueid, isEdit } = getValueId(valueData.id)
    const arrCode = tableData.filter(f => f.id != valueid).map(item => item.code)

    const { register, control, reset, watch, handleSubmit, setValue, formState: { errors, isSubmitting } } = useForm<ICustomerBankAccount>({
        resolver: yupResolver(yup.object().shape({
            code: yup.string().test(val => val ? !arrCode.includes(val) : false).required(),
            bankname: yup.string().required(),
            bankaccountno: yup.string().required(),
            bankaccountname: yup.string().required(),
            // bankbranchno: yup.string().required(),
            // bankbranchname: yup.string().required(),
        })),
    })

    useEffect(() => {
        if (valueData)
            reset(valueData)
    }, [valueData])


    const onSubmit = async (data: any) => {
        try {
            const method = isEdit ? 'put' : 'post'
            const result = await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
            handleSave(isEdit, result.data)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
        handleClose()
    }

    return <Modal size="lg" show={valueData != undefined} onHide={handleClose} centered >
        <Modal.Header>
            <Modal.Title> <FormattedMessage id="text.bank.info" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name' >
                            <input className={`form-control form-control-sm ${errors.bankname && 'form-error'}`} {...register('bankname')} />
                        </KTFormItem>
                        <KTFormItem title='text.bank.account.no' >
                            <input className={`form-control form-control-sm ${errors.bankaccountno && 'form-error'}`} {...register('bankaccountno')} />
                        </KTFormItem>
                        <KTFormItem title='text.bank.account.name' >
                            <input className={`form-control form-control-sm ${errors.bankaccountname && 'form-error'}`} {...register('bankaccountname')} />
                        </KTFormItem>
                        <KTFormItem title='text.branch.id' >
                            <input className={`form-control form-control-sm ${errors.bankbranchno && 'form-error'}`} {...register('bankbranchno')} />
                        </KTFormItem>
                        <KTFormItem title='text.branch.name' >
                            <input className={`form-control form-control-sm ${errors.bankbranchname && 'form-error'}`} {...register('bankbranchname')} />
                        </KTFormItem>
                        <KTFormItem title='text.phone' >
                            <input className={`form-control form-control-sm ${errors.phoneno && 'form-error'}`} {...register('phoneno')} />
                        </KTFormItem>
                        <KTFormItem title='text.email' >
                            <input className={`form-control form-control-sm ${errors.email && 'form-error'}`} {...register('email')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <ExtendAddressValue {...{
                            // disabledAddress: true,
                            register,
                            control,
                            errors,
                            setValue,
                            watch
                        }} />
                    </div>
                </div>
                <div className="d-flex flex-stack">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        handleClose={handleClose} />
                </div>
            </form>
        </Modal.Body>
    </Modal>
}
