import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Column } from "react-table"
import { ApiService, ApiShowError, downloadFile, sortWithAny } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, KTTable, RowIcon } from "../../../../theme/partials"
import { apiAd } from "../../api/admin"
import { popupMessage, popupQuestion } from "../../messages"

const breadCrumbs = [{ title: 'system', path: '/system-settings' }]

interface IBackup {
    fileName: string
    fileSize: string
    createDate: string
    url: string
}

export const AdBackupsPage = () => {
    const { data: backupsData, isFetching, isSuccess, refetch } = apiAd.useBackups()
    const [tableData, setTableData] = useState<IBackup[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setTableData(sortWithAny(backupsData, { sort: "fileName", order: "desc" }))
        }
    }, [isFetching])

    const columns: Array<Column<any>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='#' />,
        id: 'index',
        Cell: ({ ...props }) => <BasicCell data={props.row.index + 1} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'fileName',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].fileName} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.created' />,
        accessor: 'createDate',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].createDate} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.size' />,
        accessor: 'fileSize',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].fileSize} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end min-w-100px' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="download" onClick={async () => {
                downloadFile(props.data[props.row.index].url, props.data[props.row.index].fileName)
            }} />
            <RowIcon action="delete" onClick={async () => {
                const record = props.data[props.row.index]
                await ApiService.delete(`backup/db/${record.fileName}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.fileName != record.fileName))
            }} />
        </div>
    }]

    async function handleCreate() {
        const question = await popupQuestion({ description: "Are you create backup file." })
        if (question) {
            try {
                await ApiService.post('backup/db')
                popupMessage({ icon: 'success', autoClose: true })
                refetch()
            } catch (error: any) {
                ApiShowError(error)
            }
        }
    }

    const toolbar = <button className="btn btn-sm btn-primary" onClick={handleCreate}><FormattedMessage id="text.generate" /></button>

    return <div className="position-relative">
        <ContentHeader title="system.auto-generate-database" items={breadCrumbs}/>
        <KTTable
            isLoading={isFetching}
            toolbar={toolbar}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search
        />
    </div>
}
