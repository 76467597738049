/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'
import { isRouteErrorResponse, useLocation, useRouteError } from 'react-router-dom'
import { useAuth } from '../auth'
import { Error400 } from './components/Error400'
import { ErrorsLayout } from './ErrorsLayout'

const ErrorsPage = () => {
  const intl = useIntl()
  const { auth } = useAuth()
  const location = useLocation()
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 401:
        return <ErrorsLayout>
          <Error400
            title={intl.formatMessage({ id: 'message.api.error.401' })}
            description={intl.formatMessage({ id: 'message.api.error.401.description' })}
            status={error.status}
            returnPath='login'
          />
        </ErrorsLayout>
      case 403:
        return <ErrorsLayout>
          <Error400
            title={intl.formatMessage({ id: 'message.api.error.403' })}
            description={intl.formatMessage({ id: 'message.api.error.403.description' })}
            status={error.status}
            returnPath={auth ? location.pathname != '/dashboard' ? 'dashboard' : 'login' : 'login'}
          />
        </ErrorsLayout>
      case 404:
        return <ErrorsLayout>
          <Error400
            title={intl.formatMessage({ id: 'message.api.error.404' })}
            description={intl.formatMessage({ id: 'message.api.error.404.description' })}
            status={error.status}
            returnPath='dashboard'
          />
        </ErrorsLayout>
      case 500:
        return <ErrorsLayout>
          <Error400
            title={intl.formatMessage({ id: 'message.api.error.500' })}
            description={intl.formatMessage({ id: 'message.api.error.500.description' })}
            status={error.status}
            returnPath='login'
          />
        </ErrorsLayout>
    }
  }

  return <ErrorsLayout>
    <Error400
      title={intl.formatMessage({ id: 'message.error.something-wrong' })}
      description={''}
      returnPath={location.pathname != '/dashboard' ? 'dashboard' : 'login'}
    />
  </ErrorsLayout>
}

export { ErrorsPage }

