import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/vi'
import '@formatjs/intl-relativetimeformat/polyfill'
import { enMessages } from './messages/en'
import { viMessages } from './messages/vi'

export type TypeLanguages = 'en' | 'vi'

export const locales = {
  en: "en",
  vi: "vi",
}

export const localMessages = {
  [locales.en]: enMessages,
  [locales.vi]: viMessages
}

const languages = [{
  value: locales.en,
  name: 'English',
  flag: '/media/svg/flags/united-states.svg',
}, {
  value: locales.vi,
  name: 'Vietnam',
  flag: '/media/svg/flags/vietnam.svg',
},]

export const intlConfig = { localMessages, languages }

declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: keyof typeof enMessages
    }
    interface IntlConfig {
      locale: TypeLanguages
    }
  }
}