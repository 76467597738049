import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

interface IError400 {
    title: string
    description: string
    status?: number
    returnPath: 'dashboard' | 'login'
}

const Error400: FC<IError400> = (props) => {
    return (
        <>
            <div className='mb-10 display-1'> {props.status} </div>
            <h1 className='fw-bolder fs-2qx text-gray-900 mb-4'>{props.title}</h1>
            <div className='fw-semibold fs-6 text-gray-500 mb-7'>{props.description}</div>
            <Link to={'/' + props.returnPath} className='btn btn-sm btn-primary'>
                {props.returnPath == 'dashboard'
                    ? <FormattedMessage id='text.return-home' />
                    : <FormattedMessage id='text.return-login' />}
            </Link>
        </>
    )
}

export { Error400 }
