import { FC } from 'react'
import { intlConfig } from '../../../i18n'
import { useSiteConfig } from '../../SiteConfig'
import { InputSelect } from '../widgets'

const Languages: FC = () => {
  const { currentLocale, setCurrentLocale } = useSiteConfig()

  const optionLang = intlConfig.languages.map(item => ({
    ...item, label: <div className='d-flex align-items-center'>
      <span className='me-2'>
        <img className='rounded w-15px h-15px' src={item.flag} alt='metronic' />
      </span>
      <span>{item.name.toUpperCase()}</span>
    </div>
  }))

  return <InputSelect
    className='w-125px'
    selectIndicator={false}
    options={optionLang}
    value={optionLang.find(f => f.value == currentLocale) || null}
    onChange={(e) => {
      setCurrentLocale(e.value)
    }}
  />
}

export { Languages }

