/* eslint-disable */
import { FC, useState, useEffect } from "react"
import { Card } from "react-bootstrap"
import { useParams } from "react-router-dom"

// import { ActionApprovals } from "./details/actions"
import axios from "axios"
const ApiCustom = axios.create()
ApiCustom.defaults.baseURL = process.env.REACT_APP_API_URL;
ApiCustom.defaults.timeout = 120 * 1000

const OrderActionFromMail: FC<{  }> = ({ }) => {
  const {id,token} = useParams()
  ApiCustom.interceptors.request.use(async (config: any) => {
        config.headers.Authorization = `bearer ${token}`
        config.headers['Access-Control-Max-Age'] = 86400
        return config;
      }, (error) => Promise.reject(error));
      
      ApiCustom.interceptors.response.use(
        response => response,
        async (error) => {
          return Promise.reject(error);
        }
    );
  const [tempRecord, setTempRecord] = useState<any>({})
  
  const getDataApi = async(data?: any) => {
    return await ApiCustom.post(`clients/approvals/processrequest`,data).then(res => res.data)
  }
  useEffect(() => {
    const getData = async() => {
        const urlParams = new URLSearchParams(window.location.search);
        const query = urlParams.get('action');
        const userid = urlParams.get('userid');
        const type = urlParams.get('requesttype');
        const tempData = {
          "requestid": id,
          "userid": userid,
          "action": query,
          "requesttype": type,
        }
        const dataQuery = await getDataApi(tempData)
        setTempRecord(dataQuery)
        return
        
    }
    getData()
  }, [])
  
    return <div className="position-relative">
        <Card className="h-100vh d-flex flex-center">
          <div className="fs-2tx">
            {tempRecord.action && 
            <div>
              <div>Đã {tempRecord.action  == 'approved'? 'phê duyệt' : 'từ chối'} {tempRecord.status == 'success'? 'thành công' : 'thất bại'}</div>
              <div><i className="fs-6">{tempRecord.message}</i></div>
            </div>
            }
            <br />
            <div className="text-center">
              <button onClick={e=>window.close()} className="btn btn-primary m-auto">Đóng trình duyệt</button>
            </div>
          </div>
        </Card>
    </div>
}

export default OrderActionFromMail;