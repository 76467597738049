import { useState } from "react"
import { ICoupon } from "./couponsDetail"
import { useForm } from "react-hook-form"
import { BasicCell, CustomHeader, FilterDropdown, KTButton, KTFormItem, KTTable, RowIcon } from "../../../theme/partials"
import { MergedProps } from "../../router/AppRoutes"
import { Column } from "react-table"
import { ContentHeader } from "../../../theme/layout/components/content"
import { useQuery } from "react-query"
import { ApiService } from "../../../theme/helpers"
import { popupMessage } from "../../modules/messages"

export default function CouponsPage({ keyElement, permission }: MergedProps) {
  const urlApi = 'discounts/coupons'
  const { data: dataQuery, isLoading, refetch } = useQuery(
    [urlApi], () => ApiService.get<ICoupon[]>(urlApi).then(res => res.data),
  )

  const columns: Array<Column> = [{
    Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
    accessor: 'code',
    Cell: ({ ...props }) => <BasicCell to={props.data[props.row.index].id} data={props.data[props.row.index].code} />,
  }, {
    Header: (props) => <CustomHeader tableProps={props} title='text.description' />,
    accessor: 'description',
    Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].description} />,
  }, {
    Header: (props) => <CustomHeader tableProps={props} title='text.start-time' />,
    accessor: 'startdatetime',
    Cell: ({ ...props }) => <BasicCell type="datetime" data={props.data[props.row.index].startdatetime} />,
  }, {
    Header: (props) => <CustomHeader tableProps={props} title='text.end-time' />,
    accessor: 'enddatetime',
    Cell: ({ ...props }) => <BasicCell type="datetime" data={props.data[props.row.index].enddatetime} />,
  }, {
    Header: (props) => <CustomHeader tableProps={props} title='text.active' />,
    accessor: 'statuscode',
    Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].statuscode == 1} />,
  }, {
    Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
    id: 'actions',
    Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
      <RowIcon permission={permission} action="modify" to={props.data[props.row.index].id} />
      <RowIcon permission={permission} action="delete" onClick={async () => {
        const id = props.data[props.row.index].id
        await ApiService.delete(`${urlApi}/${id}`)
        popupMessage({ icon: 'success', autoClose: true })
        refetch()
      }} />
    </div>
  }]

  const [filter, setFilter] = useState<ICoupon | undefined>()
  const { register, control, reset, handleSubmit } = useForm<any>()

  const onFilter = (data: ICoupon[]) => {
    if (!filter) return data
    return data.filter(f => {
      const checks: boolean[] = [
      ]
      return checks.every(val => val == true)
    })
  }

  const toolbar = <>
    <FilterDropdown
      handleSubmit={handleSubmit}
      onClose={() => {
        setFilter(undefined)
        reset({})
      }}
      onSubmit={(data) => setFilter(data)}>
      <KTFormItem title='text.active' labelWidth="auto">
        <label className="form-check form-switch form-check-custom">
          <input className="form-check-input" type="checkbox" {...register("active")} />
        </label>
      </KTFormItem>
    </FilterDropdown>
    <KTButton permission={permission} action="new" />
  </>

  return <>
    <ContentHeader title={keyElement} elements={toolbar} />
    <KTTable
      isLoading={isLoading}
      data={onFilter(dataQuery || [])}
      columns={columns}
      search
      pagination
      cardInnner
    />
  </>
}
