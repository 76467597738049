import clsx from "clsx";
import { FC, ReactNode } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { DivRoot } from "../../../partials";
import { useLayout } from "../../core";

interface IItem {
    title: string
    path: string
}

interface IContentHeader {
    isInfo?: boolean
    title?: string
    items?: IItem[]
    elements?: ReactNode
    disabledIntl?: boolean
}

export const ContentHeader: FC<IContentHeader> = ({ disabledIntl, isInfo, title, items, elements }) => {
    const { pathname } = useLocation()
    const intl = useIntl()
    const { config } = useLayout()
    const appPageTitleDirection = config.app?.pageTitle?.direction
    const textinfo = intl.formatMessage({ id: "text.information" }).toLowerCase()

    function getTitle(key: string) {
        if (disabledIntl) return key
        if (pathname.includes('request')) return key
        return intl.formatMessage({ id: key as any })
    }

    return <div className="d-flex flex-stack p-3 py-lg-3">
        <div>
            {config.app?.pageTitle?.display && title && (
                <h1
                    className={clsx('page-heading d-flex text-dark fw-bold fs-3 my-0', {
                        'flex-column justify-content-center': appPageTitleDirection,
                        'align-items-center': !appPageTitleDirection,
                    })}
                >
                    {getTitle(title)} {isInfo && textinfo}
                </h1>
            )}
            {items && items.length != 0
                ? <div className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 text-muted">
                    <li className="breadcrumb-item">
                        <Link to={'/'} className="text-muted">
                            {intl.formatMessage({ id: "header.title" })
                            }</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <span className='bullet bg-muted w-5px h-2px'></span>
                    </li>
                    {items.map((item, index) => <DivRoot key={index}>
                        <li className="breadcrumb-item">
                            <Link to={item.path || '#'} className="text-muted">
                                {item.title && getTitle(item.title)}
                            </Link>
                        </li>
                        {((index + 1) != items.length) && <li className="breadcrumb-item">
                            <span className='bullet bg-muted w-5px h-2px'></span>
                        </li>}
                    </DivRoot>)}
                </div>
                : <></>}
        </div>
        <div className="d-flex align-items-center gap-2">{elements}</div>
    </div>
}
