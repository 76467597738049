import { Controller, useForm } from "react-hook-form";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { IInitField } from "../../../modules/api";
import { MergedProps } from "../../../router/AppRoutes";
import { DivHr, FormSubmit, InputSelect, KTFormItem, TextFormByUser, yup } from "../../../../theme/partials";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService, ApiShowError, changeDataModify, getValueId } from "../../../../theme/helpers";
import { popupMessage } from "../../../modules/messages";
import { useQuery } from "react-query";
import { useEffect } from "react";

interface IStaffPermissionsGroup extends IInitField {
    code: string | null
    description: string | null
    percentofmaxdiscounttogive: string | null
    percentofmaxtotaldiscount: string | null

    ismanagerprivileges: boolean
    isaddpayment: boolean
    isopensalepos: boolean
    isopendintbllockedbystaff: boolean
    ischangedintblestatus: boolean
    isresetdiningtblstatus: boolean
    istransferorders: boolean
    issplitbills: boolean
    isopensalesposdirectly: boolean
    isvoidtransaction: boolean
    isvoidline: boolean
    isreturnintransaction: boolean
    issuspendtransaction: boolean
    iseditavailableqty: boolean
    ischangestaff: boolean

    isvoiditemsenttokitchen: boolean
    isvoidtransactionsenttokitchen: boolean
    isadditemsafterbillprint: boolean
}

function StaffPermissionsGroupDetail({ keyElement, permission, pathElement }: MergedProps) {
    const navigate = useNavigate()
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data } = useQuery([`settings/staffpermissiongroups/${valueid}`], () => ApiService.get(`settings/staffpermissiongroups/${valueid}`).then(res => res.data), { enabled: !!valueid })
    const { register, handleSubmit, watch, reset, formState: { isSubmitting, errors } } = useForm<IStaffPermissionsGroup>({
        defaultValues: {},
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            percentofmaxdiscounttogive: yup.number().nullable(true).transform((val) => val ? Number(val) : null),
            percentofmaxtotaldiscount: yup.number().nullable(true).transform((val) => val ? Number(val) : null),

            ismanagerprivileges: yup.boolean().transform((val) => val ? true : false),
            isaddpayment: yup.boolean().transform((val) => val ? true : false),
            isopensalepos: yup.boolean().transform((val) => val ? true : false),
            isopendintbllockedbystaff: yup.boolean().transform((val) => val ? true : false),
            ischangedintblestatus: yup.boolean().transform((val) => val ? true : false),
            isresetdiningtblstatus: yup.boolean().transform((val) => val ? true : false),
            istransferorders: yup.boolean().transform((val) => val ? true : false),
            issplitbills: yup.boolean().transform((val) => val ? true : false),
            isopensalesposdirectly: yup.boolean().transform((val) => val ? true : false),
            isvoidtransaction: yup.boolean().transform((val) => val ? true : false),
            isvoidline: yup.boolean().transform((val) => val ? true : false),
            isreturnintransaction: yup.boolean().transform((val) => val ? true : false),
            issuspendtransaction: yup.boolean().transform((val) => val ? true : false),
            iseditavailableqty: yup.boolean().transform((val) => val ? true : false),
            ischangestaff: yup.boolean().transform((val) => val ? true : false),
            isvoiditemsenttokitchen: yup.boolean().transform((val) => val ? true : false),
            isvoidtransactionsenttokitchen: yup.boolean().transform((val) => val ? true : false),
            isadditemsafterbillprint: yup.boolean().transform((val) => val ? true : false),
        }))
    })

    useEffect(() => {
        if (isEdit) {
            reset(data)
        }
    }, [data])

    async function onSubmit(data: any) {
        try {
            const method = isEdit ? 'put' : 'post'
            await ApiService[method](`settings/staffpermissiongroups` + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
            popupMessage({ icon: 'success', autoClose: true })
            handleClose()
        } catch (error) {
            ApiShowError(error)
        }
    }

    function handleClose() {
        navigate(`/configuration/staffs-permission-group`)
    }

    return <>
        <ContentHeader title={keyElement} isInfo items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(onSubmit, (error) => console.log(error))}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        permission={permission}
                        isSubmitting={isSubmitting}
                        handleClose={handleClose} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                    </div>
                    <div className="col-12">
                        <KTFormItem title='text.description' isHeight>
                            <textarea className={`form-control form-control-sm ${errors.description && 'form-error'}`} {...register('description')} />
                        </KTFormItem>
                    </div>
                    <DivHr />
                    <div className="col-12">
                        <KTFormItem title='Settings' labelClassName="fw-bold" isHeight>
                            <div className="row w-100">
                                <div className="col-12 col-lg-6">
                                    <KTFormItem title='Percent of max discount to give' isBLockLabel={true}>
                                        <input className={`form-control form-control-sm ${errors.percentofmaxdiscounttogive && 'form-error'}`} {...register('percentofmaxdiscounttogive')} />
                                    </KTFormItem>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <KTFormItem title='Percent of max total discount' isBLockLabel={true}>
                                        <input className={`form-control form-control-sm ${errors.percentofmaxtotaldiscount && 'form-error'}`} {...register('percentofmaxtotaldiscount')} />
                                    </KTFormItem>
                                </div>
                                <div className="col-12 col-lg-6 d-flex flex-column gap-4">
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('ismanagerprivileges')} />
                                        Manager Privileges
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isaddpayment')} />
                                        Add Payment
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isopensalepos')} />
                                        Open Sale POS
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isopendintbllockedbystaff')} />
                                        Open Dining Table Locked By Staff
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('ischangedintblestatus')} />
                                        Change Dining Table Status
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isresetdiningtblstatus')} />
                                        Reset Dining Table Status
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('istransferorders')} />
                                        Transfer Orders
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isvoiditemsenttokitchen')} />
                                        Voiding Items Sent To Kitchen
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isvoidtransactionsenttokitchen')} />
                                        Voiding Transaction Sent To Kitchen
                                    </label>

                                </div>
                                <div className="col-12 col-lg-6 d-flex flex-column gap-4">
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('issplitbills')} />
                                        Split Bill
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isopensalesposdirectly')} />
                                        Open Sales POS Directly
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isvoidtransaction')} />
                                        Void Transaction
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isvoidline')} />
                                        Void Line
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isreturnintransaction')} />
                                        Return In Transaction
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('issuspendtransaction')} />
                                        Suspend Transaction
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('iseditavailableqty')} />
                                        Edit Available Qty
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('ischangestaff')} />
                                        Change staff
                                    </label>
                                    <label className="form-check form-check-sm">
                                        <input className="form-check-input" type="checkbox" {...register('isadditemsafterbillprint')} />
                                        Adding Items After Bill Print
                                    </label>
                                </div>
                            </div>
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
    </>
}

export default StaffPermissionsGroupDetail;