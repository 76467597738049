export const sideBarMenuData = [
  // {
  //   key: 'dashboard',
  //   path: 'dashboard',
  //   icon: 'bi bi-house',
  //   permission: '500001'
  // },
  {
    key: 'dashboard',
    path: 'dashboard',
    icon: 'bi bi-house',
    permission: '500001'
  },
  {
    key: 'fnb',
    path: 'fnb',
    icon: '/media/icons/menus/fnb.svg',
    permission: '',
    children: [
      {
        key: 'categories',
        path: 'categories',
        icon: '/media/icons/menus/categories.svg',
        permission: '600001'
      },
      {
        key: 'sub-categories',
        path: 'sub-categories',
        icon: '/media/icons/menus/sub-categories.svg',
        permission: '600002'
      },
      {
        key: 'menu-structures',
        path: 'menu-structures',
        icon: '/media/icons/menus/menu-structure.svg',
        permission: '600003'
      },
      {
        key: 'hospitality-items',
        path: 'hospitality-items',
        icon: '/media/icons/menus/hosp-item.svg',
        permission: '600004'
      },
      {
        key: 'tags',
        path: 'tags',
        icon: '/media/icons/menus/tag.svg',
        permission: '600005'
      },
      {
        key: 'customizations',
        path: 'customizations',
        icon: '/media/icons/menus/customization.svg',
        permission: '600006'
      },
      {
        key: 'time-based-menus',
        path: 'time-based-menus',
        icon: '/media/icons/menus/time-based-menu.svg',
        permission: '600007'
      },
      {
        key: 'merchants',
        path: 'merchant-list',
        icon: '/media/icons/menus/marchant-list.svg',
        permission: '500012',
      },
    ]
  },
  {
    key: 'discounts',
    path: 'discounts',
    icon: 'bi bi-gift',
    permission: '',
    children: [
      {
        key: 'promotions',
        path: 'promotions',
        icon: '/media/icons/custom/product.svg',
        permission: '600009'
      },
      {
        key: 'coupons',
        path: 'coupons',
        icon: '/media/icons/custom/supplier.svg',
        permission: '600010'
      },
    ]
  },
  {
    key: 'catalogue',
    path: 'catalogue',
    icon: '/media/icons/menus/catalogue.svg',
    permission: '',
    children: [
      {
        key: 'items',
        path: 'item-list',
        icon: '/media/icons/custom/product.svg',
        permission: '500005',
      },
      {
        key: 'vendors',
        path: 'vendor-list',
        icon: '/media/icons/custom/supplier.svg',
        permission: '500006',
      },
      {
        key: 'customers',
        path: 'customer-list',
        icon: '/media/icons/custom/customer.svg',
        permission: '500007',
      },
      {
        key: 'expenses',
        path: 'expenses',
        icon: '/media/icons/custom/expense.svg',
        permission: '500008',
      },
      {
        key: 'stores',
        path: 'stores',
        icon: '/media/icons/custom/shop.svg',
        permission: '500009',
      },
      {
        key: 'departments',
        path: 'departments',
        icon: '/media/icons/custom/department.svg',
        permission: '500011',
      },
      {
        key: 'dimensions',
        path: 'dimensions',
        icon: '/media/icons/custom/Action_Dimensions_mono_16x16.svg',
        permission: '500015',
      },
    ]
  },
  {
    key: 'requests',
    path: 'requests',
    icon: '/media/icons/menus/self-service.svg',
    permission: '',
    children: [
      {
        key: 'order',
        path: 'order',
        icon: '/media/icons/custom/tnf_order_request.svg',
        permission: '500002'
      },
      {
        key: 'advance',
        path: 'advance',
        icon: '/media/icons/custom/tnf_advance.svg',
        permission: '500003'
      },
      {
        key: 'payment',
        path: 'payment',
        icon: '/media/icons/custom/tnf_payment.svg',
        permission: '500004'
      },
    ]
  }, {
    key: 'configuration',
    path: 'configuration',
    icon: '/media/icons/menus/configuration.svg',
    permission: '',
    children: [
      {
        key: 'email-templates',
        path: 'email-templates',
        icon: '/media/icons/custom/emailtemplate.svg',
        permission: '100007',
      },
      {
        key: 'attachments',
        path: 'attachments',
        icon: 'bi bi-paperclip',
        permission: '100011',
      },
      {
        key: 'no-series',
        path: 'no-series',
        icon: 'bi bi-upc',
        permission: '100008',
      },
      {
        key: 'store-tier',
        path: 'store-tier',
        icon: '/media/icons/custom/shop.svg',
        permission: '510001',
      },
      {
        key: 'menu-structure-tier',
        path: 'menu-structure-tier',
        icon: '/media/icons/menus/menu-structure.svg',
        permission: '510002',
      },
      // {
      //   key: 'time-based-menu-tier',
      //   path: 'time-based-menu-tier',
      //   icon: '/media/icons/menus/time-based-menu.svg',
      //   permission: '510004',
      // },
      // {
      //   key: 'payment-tier',
      //   path: 'payment-tier',
      //   icon: '/media/icons/custom/expense.svg',
      //   permission: '510004',
      // },
      {
        key: 'self-service-setup',
        path: 'self-service-setup',
        icon: '/media/icons/custom/shop.svg',
        permission: '500013',
      },
      {
        key: 'tax-posting-setup',
        path: 'tax-posting-setup',
        icon: 'bi bi-upc',
        permission: '600008',
      },
      {
        key: 'customer-price-groups',
        path: 'customer-price-groups',
        icon: 'bi bi-upc',
        permission: '510005',
      },
      {
        key: 'staffs-permission-group',
        path: 'staffs-permission-group',
        icon: '/media/icons/tabs/employees.svg',
        permission: '500014',
      },
      /* {
        key: 'staffs',
        path: 'staffs',
        icon: '/media/icons/tabs/employees.svg',
        permission: '500014',
      }, */
      /* {
        key: 'merchant-console-settings',
        path: 'merchant-console-settings',
        icon: '/media/icons/custom/shop.svg',
        permission: '500009',
      },
      {
        key: 'smart-web-settings',
        path: 'smart-web-settings',
        icon: '/media/icons/custom/department.svg',
        permission: '500011',
      },
      {
        key: 'other-settings',
        path: 'other-settings',
        icon: '/media/icons/menus/marchant-list.svg',
        permission: '500012',
      }, */
    ]
  },]

export const dataPages = sideBarMenuData.reduce((tol: any[], page: any) => [
  ...tol,
  ...(
    page.children
      ? page.children
        .filter((f: any) => f.permission)
        .map((i: any) => ({
          permission: i.permission,
          pathElement: `/${page.path}/${i.path}`,
          keyElement: ['sidebar', page.key, i.key].join('.'),
        }))
      : page.permission
        ? [{ ...page, pathElement: `/${page.path}`, keyElement: ['sidebar', page.key].join('.'), }]
        : []
  )
], [])