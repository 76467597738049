/* eslint-disable */
import { useEffect, useState } from 'react'
import { ContentHeader } from '../../../theme/layout/components/content'
import { useAuth } from '../../modules/auth'
import { LocalData } from '../../modules/api/localData'
import { useAdvanceForme, useOrderForme, usePaymentForme } from '../../modules/api/requests'
import clsx from 'clsx'
import { convertStatus, getEmailInfo } from '../orderRequest/details/function'
import { formatCurrent, getLocalStorage, sortWithDate } from '../../../theme/helpers'
import { BasicCell, CustomHeader, DivRoot, InputSelect, KTFormItem } from '../../../theme/partials'
import { Controller, useForm } from 'react-hook-form'
import { ActionApprovals } from '../orderRequest/details/actions'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { ChartAreaBasic, ChartColumn, OverAllSum, TopList, TopListDept, TopListReimbursements } from './template'
import { Column } from 'react-table'
import { getIsRole } from '../../modules/auth/Permission'



export function Dashboard() {
  const intl = useIntl()
  const [dataDefault, setDataDefault] = useState<any>(null)
  const [tableData, setTableData] = useState<Array<any>>([])  
  const [pageOption, setPageOption] = useState({ page: 1, limit: 50 })
  const [tempRecord, setTempRecord] = useState<any>({})
  const [filter, setFilter] = useState<any>(0)
  const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()

  const {auth} = useAuth()
  const {user} = auth || {} as any
  const isadminview = user.isadminview
  const isSuperAdmin = getIsRole(['SUPER'])
  const { data: dataQuery, isSuccess, isFetching } = useOrderForme('find/findapprovalforme','?isonlywaiting=true&isopen=true&status=284210001')
  // const { data: dataQuery1, isSuccess:isSuccess1, isFetching:isFetching1 } = useOrderForme('find/findrequestbyme','?status=284210001')
  const { data: dataAdvances, isSuccess: isSuccessAdvances } = useAdvanceForme('find/findapprovalforme','?isonlywaiting=true&isopen=true&status=284210001')
  // const { data: dataAdvances1, isSuccess: isSuccessAdvances1 } = useAdvanceForme('find/findrequestbyme','?status=284210001')
  const { data: dataPayments, isSuccess: isSuccessPayments } = usePaymentForme('find/findapprovalforme','?isonlywaiting=true&isopen=true&status=284210001')
  // const { data: dataPayments1, isSuccess: isSuccessPayments1 } = usePaymentForme('find/findrequestbyme','?status=284210001')
  
  let {selectedLang} = getLocalStorage('siteConfig') as any
  selectedLang = selectedLang||'vi'
  const optionStatusLocal = Object.values(LocalData.statusData)
  useEffect(() => {
    if(watch('status') && watch('status') != -1) {
      // console.log('tempRecord',tempRecord)
      // console.log('status()',watch('status'))
      // console.log('recordId()',watch('recordId'))
      // console.log('dataDefault()',dataDefault )
        const grid  = dataDefault.map((item: any)=> {
            if(item.id == tempRecord.id) {
                item.status = watch('status')
                item.statusLocal = convertStatus(watch('status'), item.approvalhistory,user?.id,1)
                item.isApproval = true
            }
            return item
        })
        setDataDefault(grid)
        setValue('status',-1)
    }
  }, [watch('status')])
  const optionsType = [
    {
      label: intl.formatMessage({ id: "text.all" as any }),
      value: 'all'
    },
    {
      label: intl.formatMessage({ id: "text.approval-request" as any }),
      value: 'request'
    },
    // {
    //   label: 'Phiếu tạm ứng',
    //   value: '500003'
    // },
    // {
    //   label: 'Phiếu thanh toán',
    //   value: '500004'
    // },
  ]
  const optionsTime = [
    {
      label: 'This month',
      value: '1'
    },
    {
      label: 'This Quarter',
      value: '6'
    },
    {
      label: 'This Year',
      value: '12'
    },
    {
      label: 'Previous Quarter',
      value: '-6'
    },
    {
      label: 'Previous Year',
      value: '-12'
    },
  ]
  
  useEffect(() => {
    if (isSuccessPayments  && isSuccessAdvances  && isSuccess )
        getData()
  }, [isSuccessPayments,isSuccessAdvances,isSuccess])
  function timeSince(date:any) {
    let today = new Date().getTime()
    // console.log('today - date',today - date)
    var seconds = Math.floor((today - date) / (1000));
    var interval = seconds / 31536000;
    let text = 'cách đây '
    if (interval > 1) {
      let content = text + Math.floor(interval) + " năm | Yêu cầu phê duyệt";
      if(selectedLang == 'en') {
        content = Math.floor(interval) + " years ago | Approval Request"
      }
      return content
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      let content = text + Math.floor(interval) + " tháng | Yêu cầu phê duyệt";
      if(selectedLang == 'en') {
        content = Math.floor(interval) + " mounths ago | Approval Request"
      }
      return content
    }
    interval = seconds / 86400;
    if (interval > 1) {
      let content = text + Math.floor(interval) + " ngày | Yêu cầu phê duyệt";
      if(selectedLang == 'en') {
        content = Math.floor(interval) + " days ago | Approval Request"
      }
      return content
    }
    interval = seconds / 3600;
    if (interval > 1) {
      let content = text + Math.floor(interval) + " giờ | Yêu cầu phê duyệt";
      if(selectedLang == 'en') {
        content = Math.floor(interval) + " hours ago | Approval Request"
      }
      return content
    }
    interval = seconds / 60;
    if (interval > 1) {
      let content = text + Math.floor(interval) + " phút | Yêu cầu phê duyệt";
      if(selectedLang == 'en') {
        content = Math.floor(interval) + " minutes ago | Approval Request"
      }
      return content
    }
    interval = interval < 0? 0 : interval
    let content = text + Math.floor(interval) + " giây | Yêu cầu phê duyệt";
      if(selectedLang == 'en') {
        content = Math.floor(interval) + " seconds ago | Approval Request"
      }
    return content;
  }
  const getData = () => {
    let data = dataQuery.items//?.concat(dataQuery1.items||[]) as any
    let data1 = dataAdvances.items//?.concat(dataAdvances1.items||[]) as any
    let data2 = dataPayments.items//?.concat(dataPayments1.items||[]) as any
    data?.forEach((item: any) => {
      let currentstage = item.approvalhistory?.currentstage
      item.typeCode = '500002'
      item.typeFilter = 'request'
      item.code = item.code
      item.typeName = intl.formatMessage({ id: "text.task.order" as any })
      item.userApproval = item.approvalhistory?.rule[currentstage]
      item.statusLocal = convertStatus(item.status, item.approvalhistory,user?.id)
      item.fullname = item.User?.fullname
      item.isSubmitto = item.approvalhistory?.Approval?.type != "20120002" && item.approvalhistory?.rule && item.approvalhistory?.rule[0]?.userid == user?.id
      item.urlApi = {
        settings: 'settings',
        typeRequest: 'orders/requestorders',
        sendMail: 'utilities/sendmail/withcustomtemplate',
        request: 'order',
      }
      item.link = `/requests/order/${item.id}`
    })
    data1?.forEach((item: any) => {
      let currentstage = item.approvalhistory?.currentstage
      item.typeCode = '500003'
      item.typeFilter = 'request'
      item.code = item.documentno
      item.typeName = intl.formatMessage({ id: "text.task.advance" as any })
      item.userApproval = item.approvalhistory?.rule[currentstage]
      item.statusLocal = convertStatus(item.status, item.approvalhistory,user?.id)
      item.fullname = item.User?.fullname
      item.isSubmitto = item.approvalhistory?.Approval?.type != "20120002" && item.approvalhistory?.rule && item.approvalhistory?.rule[0]?.userid == user?.id
      item.urlApi = {
        settings: 'settings',
        typeRequest: 'employees/employeeadvancerequests',
        sendMail: 'utilities/sendmail/withcustomtemplate',
        request: 'advance',
      }
      item.link = `/requests/advance/${item.id}`

    })
  data2?.forEach((item: any) => {
      let currentstage = item.approvalhistory?.currentstage
      item.typeCode = '500004'
      item.typeFilter = 'request'
      item.code = item.documentno
      item.typeName = intl.formatMessage({ id: "text.task.payment" as any })
      item.userApproval = item.approvalhistory?.rule[currentstage]
      // item.userApproval = item.approvalhistory?.rule[currentstage]?.username
      item.statusLocal = convertStatus(item.status, item.approvalhistory,user?.id)
      item.fullname = item.User?.fullname
      item.isSubmitto = item.approvalhistory?.Approval?.type != "20120002" && item.approvalhistory?.rule && item.approvalhistory?.rule[0]?.userid == user?.id
      item.urlApi = {
        settings: 'settings',
        typeRequest: 'payments/paymentrequests',
        sendMail: 'utilities/sendmail/withcustomtemplate',
        request: 'payment',
      }
      item.link = `/requests/payment/${item.id}`
    })
    let tempData = data.concat(data1,data2)
    let a = tempData.filter((i:any)=>i.statusLocal == '284210005')
    let b = tempData.filter((i:any)=>i.statusLocal != '284210005')
    a = sortWithDate(a,{sort:'createdat', order: 'desc'})
    b = sortWithDate(b,{sort:'createdat', order: 'desc'})
    tempData = a.concat(b)
    console.log('setDataDefault',tempData)
    setDataDefault(tempData)
  }
  useEffect(() => {
    if(dataDefault) {
        setTableData(dataDefault.map((item:any)=>item))
    }
  }, [dataDefault])
  useEffect(() => {
    setValue('type', 'all')
  }, [])
  const onSubmit = () => {

  }
  

  const onSubmitFilter:any = (data: any) => {
    // if(!filter) return data
    let tempData = data.filter((item: any) => !item.isApproval)//.map((item:any)=> item).filter((i:any)=>i.statusLocal == '284210005')
    if(filter == 1) {
      tempData = tempData.filter((i:any)=> isOverdue(i))
    }
    if(filter == 2) {
      tempData = tempData.filter((i:any)=> isOverdue(i,true))
    }
    if(watch('type') && watch('type') != 'all' ) {
        tempData = tempData.filter((i:any)=> i.typeFilter == watch('type'))
    }
    console.log('tempData',tempData)
    return tempData
  }
  function endOfWeek(){
    let date = new Date()
    var lastday = date.getDate() - (date.getDay() - 1) + 6;
    return new Date(date.setDate(lastday));
 
  }
  const isOverdue = (item: any,isWeekend?:any) => {
    const overdueTime = item.userApproval?.approvalreminderbefore*24*60*60*1000
    if(!overdueTime) {
      return false
    }
    // console.log('overdueTime',overdueTime)
    let dayRequest = new Date(item.approvalhistory?.modifiedat).getTime() + overdueTime
    let toDay = new Date().getTime() as any
    if(isWeekend) {
      toDay = endOfWeek().getTime()
    }
    
    return dayRequest < toDay && item.statusLocal == '284210005'
  }
  

  
const dataFilter = [
  {
      label: intl.formatMessage({ id: "text.current" }),
      class: "",
      action: false,
      value: null,
  },
  {
      label: intl.formatMessage({ id: "text.thismonth" }),
      class: "",
      action: true,
      value: 'thismonth',
  },
  {
      label: intl.formatMessage({ id: "text.thisquarter" }),
      class: "",
      action: true,
      value: 'thisquarter',
  },
  {
      label: intl.formatMessage({ id: "text.thisyear" }),
      class: "",
      action: true,
      value: 'thisyear',
  },
  {
      label: intl.formatMessage({ id: "text.previous" }),
      class: "border border-end-0 border-bottom-0 border-start-0 border-gray-400",
      action: false,
      value: null,
  },
  {
      label: intl.formatMessage({ id: "text.previous-month" }),
      class: "",
      action: true,
      value: 'previousmonth',
  },
  {
      label: intl.formatMessage({ id: "text.previous-quarter" }),
      class: "",
      action: true,
      value: 'previousquarter',
  },
  {
      label: intl.formatMessage({ id: "text.previous-year" }),
      class: "",
      action: true,
      value: 'previousyear',
  },
]
const ListActionApproval = () => {
    return <div className={`card`}>
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold text-dark'> {intl.formatMessage({ id: "text.task-is-open" })}</span>
        {/* <span className='text-muted mt-1 fw-semibold fs-7'>Tổng {tableData.length}</span> */}
      </h3>
      <div className='card-toolbar z-index-99'>
        <div className="d-flex align-items-center gap-6">
          <div className='d-flex gap-4'>
            <span className={clsx(`badge p-4 cursor-pointer ${filter == 0?'badge-warning':'text-black bg-gray-300 bg-opacity-50'}`,)} onClick={(e => setFilter(0))}><i className="text-white fs-6 bi bi-card-list me-2"></i>{intl.formatMessage({ id: "text.all" })} - {tableData.filter((i:any)=>i.statusLocal == '284210005').length}</span>
            <span className={clsx(`badge  p-4 cursor-pointer ${filter == 1?'badge-warning':'text-black bg-gray-300 bg-opacity-50'}`)} onClick={(e => setFilter(1))}>{intl.formatMessage({ id: "text.expired" as any })} - {tableData.filter((i:any)=>i.statusLocal == '284210005' && isOverdue(i)).length}</span>
            <span className={clsx(`badge p-4 cursor-pointer ${filter == 2?'badge-warning':'text-black bg-gray-300 bg-opacity-50'}`)} onClick={(e => setFilter(2))}>{intl.formatMessage({ id: "text.expired-week" as any})} - {tableData.filter((i:any)=>i.statusLocal == '284210005' && isOverdue(i,true)).length}</span>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className='w-200px'>
            <KTFormItem labelWidth="45" title='text.show' className='mb-0'>
                <Controller
                    name='type'
                    control={control}
                    render={({ field }) => <InputSelect
                        options={optionsType}
                        // isClearable
                        value={optionsType?.find((e: any) => e.value == field.value)|| ''}
                        onChange={e => {
                          // console.log('e',e.value)
                            if (!e) return field.onChange(null)
                            field.onChange(e.value)
                          }
                        }
                    />} />
            </KTFormItem>
          </form>
        </div>
      </div>
    </div>
    <div className='card-body pt-5'>
    <div className=" table-loading px-6">
            <table className='table table-row-bordered dataTable no-footer table-hover'>
                {/* <thead>
                    <tr className="fw-bolder">
                        <th></th>
                        <th></th>
                        
                    </tr>
                </thead> */}
                <tbody>
                {onSubmitFilter(tableData).map((item:any, index:number) => <DivRoot key={index}>
                  <tr className={clsx("align-top hover-approval", )}>
                    <td className=" px-0"> 
                        <div className="px-3">
                          {item.statusLocal != '284210006' &&
                          <div className='d-flex align-items-center gap-2'>
                            <span className={clsx(`bullet bullet-vertical bullet-custom bg-${LocalData.statusData[item.statusLocal]?.color}`)}></span>
                            <div className='text-transform-capitalize'>
                            {item.statusLocal == '284210000' &&
                            <><strong>{item.createdby == user?.id?intl.formatMessage({ id: "text.you" as any }): item.creater} </strong> {intl.formatMessage({ id: "text.created-a-new-ticket" as any })}{item.typeName} <Link to={item.link} className='fw-bold'>{item.code}</Link></>
                            }
                            {item.statusLocal == '284210001' &&
                            <>{item.typeName} <Link to={item.link} className='fw-bold'>{item.code}</Link> {intl.formatMessage({ id: "text.task.of" as any })} <strong>{item.createdby == user?.id?intl.formatMessage({ id: "text.you" as any }): item.creater}</strong> {intl.formatMessage({ id: "text.task.pending" as any })} <strong>{item.userApproval?.username}</strong> {intl.formatMessage({ id: "text.task.approval" as any })}</>
                            }
                            {item.statusLocal == '284210002' &&
                            <>{item.typeName} <Link to={item.link} className='fw-bold'>{item.code}</Link> {intl.formatMessage({ id: "text.task.of" as any })} <strong>{item.createdby == user?.id?intl.formatMessage({ id: "text.you" as any }): item.creater}</strong> {intl.formatMessage({ id: "text.task.approved" as any })}</>
                            }
                            {item.statusLocal == '284210003' &&
                            <><strong>{item.modifiedby == user?.id ? intl.formatMessage({ id: "text.you" as any}): item.modifier} </strong> {intl.formatMessage({ id: "text.task.denied" as any })} {item.typeName} <Link to={item.link} className='fw-bold'>{item.code}</Link> {intl.formatMessage({ id: "text.task.of" as any })} <strong>{item.createdby == user?.id?intl.formatMessage({ id: "text.you" as any }): item.creater}</strong></>
                            }
                            {item.statusLocal == '284210004' &&
                            <>
                              <strong>{item.createdby == user?.id?intl.formatMessage({ id: "text.you" as any}): item.creater}</strong> {intl.formatMessage({ id: "text.task.closed" as any })} {item.typeName} <Link to={item.link} className='fw-bold'>{item.code}</Link>
                              </>
                            }
                            
                            {item.statusLocal == '284210005' &&
                            <>
                            {
                              item.createdby != item.modifiedby ?
                            <>{intl.formatMessage({ id: "text.task.request" as any})} {item.typeName} <Link to={item.link} className='fw-bold'>{item.code}</Link> {intl.formatMessage({ id: "text.task.by" as any})} <strong>{item.creater} </strong> {intl.formatMessage({ id: "text.task.initialization-has" as any})} <strong>{item.modifier} </strong> {intl.formatMessage({ id: "text.task.approved-and-forwarded" as any})} <strong>{intl.formatMessage({ id: "text.you" as any})} </strong> {intl.formatMessage({ id: "text.task.next-request" as any})}.</>:
                            <><strong>{item.creater} </strong> {intl.formatMessage({ id: "text.task.request-for" as any})} <strong>{intl.formatMessage({ id: "text.you" as any})}</strong> {intl.formatMessage({ id: "text.task.approval" as any})} {item.typeName} <Link to={item.link} className='fw-bold'>{item.code}</Link></>
                            }
                              
                              
                            </>
                            }
                            <div> {timeSince(new Date(item.modifiedat).getTime())}</div>
                            </div>
                          </div>
                          }
                          {item.statusLocal == '284210006' &&
                          <div className='d-flex align-items-center gap-2'>
                            <span className={clsx(`bullet bullet-vertical bullet-custom bg-${LocalData.statusData[item.statusLocal]?.badge}`)}></span>
                            <div className='text-transform-capitalize'>
                              <strong>{intl.formatMessage({ id: "text.you" as any})} </strong> {intl.formatMessage({ id: "text.task.forwarded" as any})} {item.typeName} <Link to={item.link} className='fw-bold'>{item.code}</Link> {intl.formatMessage({ id: "text.task.for" as any})} <strong>{item.userApproval?.username}</strong> {intl.formatMessage({ id: "text.task.approval" as any})}
                              <div>{intl.formatMessage({ id: "text.task.date" as any})} {new Date(item.createdat).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</div>

                            </div>
                          </div>
                          }
                            
                            {/* <span className={clsx(`badge badge-${LocalData.statusData[item.statusLocal]?.badge}`)}>
                                {LocalData.statusData[item.statusLocal]?.label}
                            </span>     */}
                        </div>
                    </td>
                    <td className="text-end ps-0 min-w-250px">
                        <div className="d-flex flex-end gap-2 ps-3">
                            {
                                item.status == '284210001' && item.approvalhistory?.open && item.approvalhistory?.rule?.find((i:any)=>i.stage == item.approvalhistory?.currentstage && i.userid == user?.id)  && !item.isApproval &&
                                <div className="in-approval custom-dasboard">
                                  <ActionApprovals 
                                  approvalUser={item}
                                  approvalhistories={item.approvalhistory}
                                  approvalLevels={item.approvalhistory?.rule}
                                  totalamount={item?.totalamount}
                                  valueid={item?.id}
                                  setValue={setValue}
                                  includeFromlist={true}
                                  setTempRecord={setTempRecord}
                                  emailnotification={getEmailInfo(item.approvalhistory?.Approval?.emailnotification||{},item,item.typeCode)}
                                  urlApi={
                                      item.urlApi
                                  }
                                  employeeAdvanceData= {
                                    {
                                      employeeadvancerequestid: item.employeeadvancerequestid,
                                      remainingadvancedamount: item.remainingadvancedamount
                                    }
                                  }
                                  />
                                </div>
                            }
                            {item.statusLocal == '284210005' && isOverdue(item) &&
                              <span className='btn btn-sm btn-warning'>{intl.formatMessage({ id: "text.expired" as any})}</span>
                            }
                        </div>
                    </td>
                  </tr>
                </DivRoot>
                )}
                {onSubmitFilter(tableData).length == 0 && 
                <tr>
                  <td colSpan={20}>
                    <div className='d-flex w-100 justify-content-center py-2 text-gray-600'>
                      {intl.formatMessage({ id: "table.not-data" })}
                    </div>
                  </td>
                </tr>
                }
                </tbody>
                    
            </table>
        </div>
    </div>
  </div>
}
  return (
    <>
      {/* <ContentHeader title={''} /> */}
      <div className={` mb-6`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-dark'>{intl.formatMessage({ id: "text.hello" })}, {user?.fullname}</span>
          </h3>
          <div className='card-toolbar'></div>
        </div>
      </div>
      <div className="row gy-5 g-xl-8">
        <div className="col-12">
          <ListActionApproval />
        </div>
        {(isadminview || isSuperAdmin) &&
        <>
          <div className="col-md-8 col-12">
            <ChartAreaBasic intl={intl} dataFilter={dataFilter} title={intl.formatMessage({ id: "text.payment-summary" })}/>
          </div>
          <div className="col-md-4 col-12">
            <OverAllSum intl={intl} dataFilter={dataFilter} title={intl.formatMessage({ id: "text.overall-summary" })} chartData={[]}/>
          </div>
        
          <div className="col-12">
            <TopList dataFilter={dataFilter} intl={intl}/>
          </div>
          <div className="col-md-6 col-12">
            <TopListDept intl={intl} dataFilter={dataFilter} title={intl.formatMessage({ id: "text.top-dept" })} chartData={[]}/>
          </div>
          <div className="col-md-6 col-12">
            <TopListReimbursements intl={intl} dataFilter={dataFilter} title={intl.formatMessage({ id: "text.pending-reimbursements" })}/>
          </div>
          {/* <div className="col-md-5 col-12">
            <ChartColumn optionsTime={optionsTime} title={'Chart Pending Reimbursements'} chartData={stateColumns}/>
          </div> */}
        </>
        }
        
        
      </div>
      
    </>
  )
}
