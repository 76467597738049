import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { IFnbSubCategory } from ".";
import { optionOrderType } from "..";
import { ApiDeleteFile, ApiService, ApiShowError, ApiUploadFiles, base64ToFile, changeDataModify, formatOptions, getValueId, isBase64 } from "../../../../../theme/helpers";
import { ContentHeader } from "../../../../../theme/layout/components/content";
import { FormSubmit, InputSelect, InputSingleImage, KTFormItem, RadioBoolean, TextFormByUser, yup } from "../../../../../theme/partials";
import { apiFnb } from "../../../../modules/api/fnb";
import { popupMessage } from "../../../../modules/messages";
import { MergedProps } from "../../../../router/AppRoutes"
import { UploadImage } from "../../../../../theme/partials/UploadImage";

const defaultValues: IFnbSubCategory = {
    code: null,
    name: null,
    image: null,
    icon: null,
    active: false,
    categoryid: null,
    categorycode: null,
    categoryname: null,
    ordertype: null
}

export function FNBSubCategoriesForm({ keyElement, pathElement, permission }: MergedProps) {
    const urlApi = 'fnb/subcategories'
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching } = apiFnb.useSubCategoriesId<IFnbSubCategory>(valueid)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IFnbSubCategory>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            categoryid: yup.string().required(),
            code: yup.string().required(),
            name: yup.string().required(),
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    const { data: dataCategories, isSuccess: isCategories } = apiFnb.useCategories()
    const optionCategories = isCategories ? formatOptions(dataCategories, ['id', 'name']) : []

    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (value: IFnbSubCategory) => {
                const isDelete = data?.image && (!value.image || isBase64(value.image))
                const isUpload = isBase64(value.image)
                let bodyData = {
                    ...value,
                    image: !(isDelete || isUpload) ? value.image : null
                }
                let itemid;
                try {
                    const method = isEdit ? "put" : "post"
                    const datas = await ApiService[method](`${urlApi}${isEdit ? `/${id}` : ""}`, bodyData)
                    itemid = datas?.data?.id
                } catch (error) {
                    ApiShowError(error)
                }

                if (data?.image && (!value.image || isBase64(value.image))) {
                    try {
                        const path: string[] = String(data.image).split(`https://icmsapi.isoftvietnam.com`)
                        await ApiDeleteFile(path.length > 1 ? path.at(1) : data.image)
                    } catch (error) { }
                }
                try {
                    if (itemid && value.image && isBase64(value.image)) {
                        const file = base64ToFile(value.image)
                        const result = await ApiUploadFiles(itemid, "subcategory", [file], false)
                        if (!result.length) {
                            return popupMessage({ icon: "error", autoClose: true })
                        }
                        await ApiService.put(`${urlApi}/${itemid}`, { ...bodyData, image: result?.at(0)?.url })
                    }

                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.category'>
                            <Controller
                                name="categoryid"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    className={clsx({ 'form-error': errors.categoryid })}
                                    options={optionCategories}
                                    value={optionCategories.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue('categorycode', e.code)
                                        setValue('categoryname', e.name)
                                    }} />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                        </KTFormItem>
                        <KTFormItem title='text.order-type' isHeight>
                            <div>
                                {optionOrderType.map((item, index) => <label key={index} className="form-check form-check-sm form-check-custom mb-4">
                                    <input className="form-check-input" type="checkbox" value={item.value} {...register(`ordertype`)} />
                                    {item.label}
                                </label>)}
                            </div>
                        </KTFormItem>
                        <KTFormItem title='text.active'>
                            <RadioBoolean control={control} name="active" className="form-check form-check-sm form-check-custom w-80px" />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        {/* <KTFormItem title='text.icon' isHeight>
                            <Controller
                                name="icon"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSingleImage
                                    size="0.5:0.5"
                                    value={value}
                                    onChange={onChange} />}
                            />
                        </KTFormItem> */}
                        <KTFormItem title='text.image' isHeight>
                            <Controller
                                name="image"
                                control={control}
                                render={({ field: { value, onChange } }) => <UploadImage
                                    value={value}
                                    onChange={onChange}
                                    className="w-100px h-100px border"
                                />}
                            />
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
    </>
}