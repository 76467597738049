import { useEffect, useState } from "react"
import { Column } from "react-table"
import { ApiService, formatCurrent, sortByUpdateAt, syncObjects } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, FilterDropdown, KTButton, KTTable, RowIcon } from "../../../../theme/partials"
import { apiPrivate, IInitFieldAddress } from "../../../modules/api"
import { popupMessage } from "../../../modules/messages"
import { MergedProps } from "../../../router/AppRoutes"

export interface ICustomer extends IInitFieldAddress {
    customerno: string | null
    customername: string | null
    phoneno: string | null
    email: string | null
    contactname: string | null
    creditlimit: number | null
    totalsalesexclvat: number | null
    balance: number | null
    balanceasvendor: number | null
    payments: string | null
    blocked: string | null
    vatregistrationno: string | null
    billtocustomerno: string | null
    paymenttermscode: string | null
    paymentmethodcode: string | null
}

const urlApi = 'settings/customers'
export function SettingCustomers({ keyElement, permission }: MergedProps) {
    const [tableData, setTableData] = useState<ICustomer[]>([])
    const { data: dataQuery, isSuccess, isFetching, refetch } = apiPrivate.useCustomers()

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(dataQuery))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'customerno',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].customerno}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'customername',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].customername} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.phone' />,
        accessor: 'phoneno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].phoneno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.vat-registration-no' />,
        accessor: 'vatregistrationno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].vatregistrationno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.total-sales-excl-vat' />,
        accessor: 'totalsalesexclvat',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].totalsalesexclvat)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.balance' />,
        accessor: 'balance',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].balance)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" to={props.data[props.row.index].id} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const toolbar = <>
        <FilterDropdown>
        </FilterDropdown>
        <KTButton permission={permission} action="sync" onClick={() => syncObjects('/bcsync/customers?source=WEBSERVICE', refetch)} />
    </>

    return <>
        <ContentHeader title={keyElement} elements={toolbar} />
        <KTTable
            isLoading={isFetching}
            data={tableData}
            columns={columns}
            search
            pagination
            cardInnner
        />
    </>
}