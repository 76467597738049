import { useEffect, useState } from "react";
import { Column } from "react-table";
import { ApiService } from "../../../../theme/helpers/ApiService";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { BasicCell, CustomHeader, KTButton, RowIcon } from "../../../../theme/partials";
import { FilterDropdown } from "../../../../theme/partials/layout/filtter-dropdown";
import { KTTable } from "../../../../theme/partials/widgets/table";
import { IInitField } from "../../../modules/api/type";
import { popupMessage } from "../../../modules/messages";
import { apiPrivate } from "../../../modules/api";
import { sortByUpdateAt } from "../../../../theme/helpers";
import { MergedProps } from "../../../router/AppRoutes";

export interface IExpense extends IInitField {
    code: string | null
    expensename: string | null
    accountno: string | null
    costcenterno: string | null
    budgetcode: string | null
    cashflowcode: string | null
    noofmonthallocated: number | null
    blocked: boolean
}

const urlApi = 'settings/expenses'
export function SettingExpenses({ keyElement, permission }: MergedProps) {
    const [tableData, setTableData] = useState<IExpense[]>([])
    const { data: dataQuery, isSuccess, isFetching } = apiPrivate.useExpenses()
  
    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(dataQuery))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' className="min-w-60px"/>,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'expensename',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].expensename} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.account.no' />,
        accessor: 'accountno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].accountno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.cost-center-no' />,
        accessor: 'costcenterno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].costcenterno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.budget' />,
        accessor: 'budgetcode',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].budgetcode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.cash-flow' className="min-w-80px"/>,
        accessor: 'cashflowcode',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].cashflowcode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" to={props.data[props.row.index].id} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const toolbar = <>
        <FilterDropdown>
        </FilterDropdown>
        <KTButton permission={permission} action="new"/>
    </>

    return <>
        <ContentHeader title={keyElement} elements={toolbar}/>
        <KTTable
            isLoading={isFetching}
            data={tableData}
            columns={columns}
            search
            pagination
            cardInnner
        />
    </>
}
