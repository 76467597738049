import PropTypes from 'prop-types';
import React, { FC } from 'react';
import ReactSelect, { components, createFilter, FormatOptionLabelMeta, GetOptionLabel, GetOptionValue, GroupBase, OptionsOrGroups, StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import List from 'react-virtualized/dist/commonjs/List';
import { boolean } from 'yup';

export interface PropsIOption { value: any, label: string }

export interface PropsSelect {
  isLoading?: boolean
  isCreatable?: boolean
  isClearable?: boolean
  isAsync?: boolean
  formatOptionLabel?: ((data: unknown, formatOptionLabelMeta: FormatOptionLabelMeta<unknown>) => React.ReactNode) | undefined
  getOptionLabel?: GetOptionLabel<unknown> | undefined
  getOptionValue?: GetOptionValue<unknown> | undefined
  isSearchable?: boolean
  disabled?: boolean
  isMulti?: boolean
  className?: string
  options?: OptionsOrGroups<any, GroupBase<any>> | undefined
  value?: any
  defaultValue?: any
  onChange?: (e: any) => void
  placeholder?: string
  size?: 'sm' | 'default' | 'lg',
  width?: string
  indicatorSeparator?: boolean
  border?: boolean
  selectIndicator?: boolean
  components?: Partial<SelectComponents<unknown, false, GroupBase<unknown>>> | undefined
  isAbsolute?: boolean
  menuIsOpen?: boolean
  tabIndex?: any
  style?: any
  autoFocus?: boolean
  openMenuOnFocus?: boolean
  closeMenuOnSelect?: boolean
  onKeyDown?: (e: any) => void
}

export const InputSelect: FC<PropsSelect> = ({ options, ...rest }) => {
  const { size, className, isAbsolute, placeholder, disabled, border, indicatorSeparator, selectIndicator, width, isAsync, isCreatable, components, style, formatOptionLabel } = rest;
  const inputSize = size || 'sm'
  const isSolid = className?.length != 0 && className?.split(" ").includes("react-select-solid")
  const isError = className?.length != 0 && className?.split(" ").includes("form-error")

  let colourStyles: StylesConfig<any, false, GroupBase<any>> | undefined = {
    container: (base, props) => ({
      ...base,
      fontSize: inputSize == 'sm' ? '0.95rem' : '1.1rem',
    }),
    menu: (base: any) => ({
      ...base,
      // width: "max-content",
      // minWidth: "100%"
      zIndex: 100,
    }),
    placeholder: (base) => ({
      ...base,
      color: '#9da1b4',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }),
    indicatorSeparator: (style) => ({
      ...style,
      backgroundColor: indicatorSeparator ? style.backgroundColor : 'transparent',
      marginBottom: inputSize == 'sm' ? '8px' : style.marginBottom
    }),
    dropdownIndicator: (base: any) => {
      return {
        ...base,
        display: border == false || selectIndicator == false ? 'none' : 'flex',
        alignItems: 'center',
        padding: inputSize == 'sm' ? '.25rem' : base.padding,
        width: inputSize == 'sm' ? '25.5px' : base.width,
        backgroundColor: '#f9f9f9',
        margin: 0,
        height: '100%',
        borderRadius: '0px 5px 5px 0',
      }
    },
    clearIndicator: (base) => ({
      ...base,
      padding: inputSize == 'sm' ? '.25rem' : base.padding,
      width: inputSize == 'sm' ? '22.5px' : base.width
    }),
    menuList: (base: any) => ({
      ...base,
      padding: '3px 3px 3px 3px'
    }),
    option: (base: any, { isFocused, isSelected, isDisabled }: any) => ({
      ...base,
      lineHeight: 1.5,
      color: isSelected ? '#fff' : '#5e6278',
      zIndex: 1,
      backgroundColor: isSelected ? '#009ef7' : isFocused ? '#f6f6f6' : 'transparent',
      opacity: isDisabled ? 0.5 : 1,
      borderRadius: '5px',
      marginBottom: '3px',
      ':hover': {
        backgroundColor: isSelected ? '#009ef7' : '#f6f6f6'
      },

    }),
    control: (base: any, { menuIsOpen, isMulti, isDisabled }) => ({
      ...base,
      borderColor: border == false ? "transparent" : '',
      border: isDisabled ? '1px solid #e1e3ea' : (isSolid || border) == false ? 'none' : isError ? 'none' : `1px solid #b5b5c3`,
      // backgroundColor: isSolid ? '#f5f8fa' : isDisabled ? '#f9f9f9' : base.backgroundColor,
      minHeight: inputSize == 'sm' ? '2.69rem !important' : '38.2px',
      height: inputSize == 'sm' ? 'auto !important' : 'auto',
      minWidth: width || base.minWidth,
      boxShadow: '#b5b5c3',
      ':focus': {
        color: '#5e6278',
        backgroundColor: '#ffffff',
        borderColor: '#b5b5c3',
        outline: 0,
        boxShadow: 'inset 0 1px 2px rgb(0 0 0 / 8%), 0 0 0 0.25rem rgb(0 158 247 / 25%)',
      },
      ':hover': {
        borderColor: '#b5b5c3'
      },
    }),
    singleValue: (provided, state) => {
      const transition = 'opacity 300ms';
      const noBorder = border == false ? {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      } : {}

      return {
        ...provided,
        ...noBorder,
        color: '#181c32',
        transition,
        lineHeight: 1.5,
      };
    },
    multiValue: (provided, state) => {
      const color = state.data.color ? state.data.color : '#5e6278'
      return {
        ...provided,
        backgroundColor: state.data.color ? `${state.data.color}26` : provided.backgroundColor,
        color,
      }
    },
    multiValueLabel: (provided, state) => {
      const color = state.data.color ? state.data.color : '#5e6278'
      return {
        ...provided,
        color,
        fontWeight: 500,
      }
    },
    multiValueRemove: (provided, state) => {
      return {
        ...provided,
        ":hover": state.data.color && {
          color: '#fff',
          backgroundColor: state.data.color
        }
      }
    },
  }

  const customRest: any = {
    ...rest,
    options: isAsync ? [] : options,
    menuShouldScrollIntoView: true,
    closeMenuOnSelect: rest.isMulti ? false : true,
    menuPosition: isAbsolute ? 'absolute' : 'fixed',
    placeholder: placeholder || '',
    isDisabled: disabled,
    classNamePrefix: /* isAsync ? 'async-select' :  */'select',
    styles: colourStyles,
    className: [className, 'w-100'].join(" "),
    onMenuOpen: function () {
      setTimeout(() => {
        const selectedEl = document.getElementsByClassName("select__option--is-selected")[0];
        if (selectedEl) {
          selectedEl.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
        }
      }, 15);
    },
    components: options && options?.length > 1000 ? (components || { Option: CustomOption, MenuList: CustomMenuList }) : undefined
  }

  const filterColors = (inputValue: string) => {
    return (options || []).filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: any[]) => void
  ) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };

  if (isCreatable) return <CreatableSelect {...customRest} />
  if (isAsync) return <AsyncSelect
    {...customRest}
    cacheOptions
    captureMenuScroll={false}
    filterOption={createFilter({ ignoreAccents: false })}
    loadOptions={loadOptions}
    defaultOptions={options}
    // className='custom-select-cell'
    formatOptionLabel={formatOptionLabel}
  />

  return <ReactSelect {...customRest} />
}

export const CustomOption = ({ children, ...props }: any) => {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className="async-option">
      {children}
    </components.Option>
  );
};

const DefaultItemHeight = 31;

export class CustomMenuList extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
    maxHeight: PropTypes.number.isRequired,
    getValue: PropTypes.func.isRequired
  }

  renderItem = (props: any) => {
    const { children }: any = this.props;
    if (Array.isArray(children)) {
      return <div style={props.style} key={props.index}>
        {children[props.index]}
      </div>
    }
    return <div key={props.index} style={props.style} className='h-100 p-2 d-flex align-items-center'>
      {children.props.children}
    </div>
  }

  render() {
    const { options, children, maxHeight, getValue }: any = this.props;
    const [value] = getValue();
    const indexValue = options.indexOf(value)
    const childrenOptions = React.Children.toArray(children);
    const wrapperHeight = (maxHeight < (childrenOptions.length * DefaultItemHeight))
      ? maxHeight
      : childrenOptions.length * DefaultItemHeight;
    return <List
      className='p-1'
      style={{ width: '100%' }}
      width={1000}
      height={wrapperHeight + 6}
      scrollToIndex={indexValue}
      rowCount={childrenOptions.length}
      rowHeight={DefaultItemHeight}
      rowRenderer={this.renderItem}
    />
  }
}