import { useIntl } from 'react-intl'
import { getIsPermission } from '../../../../../app/modules/auth/Permission'
import { sideBarMenuData } from '../../../../../app/router/routerData'
import { DivRoot } from '../../../../partials'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const defaultIcon = '/media/icons/duotune/art/art002.svg'

  function getPath(path?: string) {
    return path ? `/${path}` : ''
  }

  return <DivRoot>
    {sideBarMenuData.map((item, i: number) => {
      const { key, children, path, icon, permission } = item
      const title = intl.formatMessage({ id: `sidebar.${key}` as any })
      const isPermission = getIsPermission([permission, 'READ'])

      const option = {
        to: getPath(path),
        title,
        icon: icon || defaultIcon
      }

      const items: any[] = children?.length
        ? children
          .map((sub: any) => ({
            permission: getIsPermission([sub.permission, 'READ']),
            to: getPath(path) + getPath(sub.path),
            title: intl.formatMessage({ id: `sidebar.${key}.${sub.key}` as any }),
            icon: sub.icon || defaultIcon
          }))
          .filter(f => f.permission) : []

      return children && children.length
        ? items.length
          ? <SidebarMenuItemWithSub key={i} {...option} >
            <div className=''>
              {items.map((item, ind) => <SidebarMenuItem key={ind} {...item} />)}
            </div>
          </SidebarMenuItemWithSub>
          : <DivRoot key={i} children={undefined} />
        : <SidebarMenuItem key={i} permission={isPermission} {...option} />
    })}
  </DivRoot>
}

export { SidebarMenuMain }

