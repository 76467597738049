import { Controller, useForm } from "react-hook-form"
import { BasicCell, CustomHeader, InputSelect, KTSVG, KTTable } from "../../../../theme/partials"
import Chart from 'react-apexcharts'
import { useEffect, useState } from "react"
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import clsx from "clsx"
import { useStatoverallsummary, useStatpaymentsummary, useStatpendingreimbursements, useTopdeptswithmostadvancetotal, useTopdeptswithmostpaymenttotal, useTopdeptswithmostproposaltotal, useTopuserswithmostadvancetotal, useTopuserswithmostpaymenttotal, useTopuserswithmostproposaltotal } from "../../../modules/api/requests"
import { formatCurrent } from "../../../../theme/helpers"
import { Link } from "react-router-dom"
import { Column } from "react-table"
const filterDefault = 'thisyear'
const colorBadge = ['success','info','primary','warning','danger','secondary','secondary','secondary','secondary','secondary']


const SearchDropdown = (props: any) => {
    const {tab,setTab,dataFilter} = props
    return <Dropdown>
    <Dropdown.Toggle as={'div'} className='text-end cursor-pointer'>
        <span>{dataFilter.find((i: any)=> i.value == tab)?.label}</span>
    </Dropdown.Toggle>
    <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg-light-primary fw-bold w-150px p-0' >
        {
            dataFilter.map((item: any,index: any) => {
                return <Dropdown.Item as='div' key={index} onClick={e => setTab(item.value)} className={clsx('menu-item ',)}>
                    <div className={clsx(`menu-item ${item.class} ${item.action?'px-3':'px-1'}`,)}>
                        <div className={clsx(`${item.action?"menu-link px-4 py-1":"menu-content text-gray-600 pb-2 fs-7 text-uppercase"}`)}>
                            {item.label}
                        </div>
                    </div>
                </Dropdown.Item>
            })
        }
    </Dropdown.Menu>
</Dropdown>
}
export const TopList = (props: any) => {
  const {intl, dataFilter} = props
    // console.log('props',props)
    const [tab1, setTab1] = useState<any>(filterDefault)
    const [tab2, setTab2] = useState<any>(filterDefault)
    const [tab3, setTab3] = useState<any>(filterDefault)
    const { data: dataUserProposal, isSuccess: isSuccessProposal } = useTopuserswithmostproposaltotal(`filterby=${tab1}&status=284210002`)
    const { data: dataUserAdvance, isSuccess: isSuccessAdvance } = useTopuserswithmostadvancetotal(`filterby=${tab2}&status=284210002`)
    const { data: dataUserPayment, isSuccess: isSuccessPayment } = useTopuserswithmostpaymenttotal(`filterby=${tab3}&status=284210002`)

    const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()
    const onSubmit = (data: any) => {

    }
    useEffect(()=>{
        // setValue('type','1')
    },[])
    const renderBlockList = (data: any, title: any, tab: any, setTab: any, type: any) => {
      // console.log('data',data)
      return <div className="col-md-4 col-12">
        <div className='card h-100'>
          <div className='card-header border-0 py-3 bg-secondary bg-opacity-60 align-items-center'>
            <h6 className='mb-0 fw-bold'>{title}</h6>
            <div className="">
              <form onSubmit={handleSubmit(onSubmit)} className=''>
                <SearchDropdown tab={tab} setTab={setTab} dataFilter={dataFilter}/>
              </form>
            </div>
          </div>
          <div className='card-body pt-5'>
            {data && data.length != 0 ? 
              data.map((item: any, index: number) => {
                // console.log('item',item)
                return <div key={index} className='d-flex flex-stack mb-3'>
                <div className='w-100'>
                  <div className="d-flex flex-stack gap-2">
                    <Link className='text-dark fw-bold text-hover-primary one-row-ellipsis w-100' title={item.username} to={`/requests/${type}?userid=${item.id}`}>
                      {item.fullname}
                    </Link>
                    <div className='d-flex align-items-center justify-content-end'>
                      <span className='me-3'>{formatCurrent((+item.total).toFixed(0))}</span>
                      <span className={clsx(`d-flex flex-center min-w-20px text-center badge badge-${colorBadge[index]} px-2`)}>{item.amount}</span>
                    </div>
                  </div>
                  <div className='text-gray-700 fs-7'>{item.departmentname}</div>
                </div>
                
              </div>
              })
              :
              <div>
                {intl.formatMessage({ id: "table.not-data" })}
              </div>
            }
            
          </div>
        </div>
      </div>
    }
    return <div className="row">
        {renderBlockList(dataUserProposal, intl.formatMessage({ id: "text.top-order" }), tab1, setTab1,'order')}
        {renderBlockList(dataUserAdvance, intl.formatMessage({ id: "text.top-advance" }), tab2, setTab2,'advance')}
        {renderBlockList(dataUserPayment,intl.formatMessage({ id: "text.top-payment" }), tab3, setTab3,'payment')}
    </div>
}
export const ChartAreaBasic = (props: any) => {
    // console.log('props',props)
    const {dataFilter,title,intl} = props as any
    const [tab,setTab] = useState<any>(filterDefault)
    const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()
    const { data: dataApi, isSuccess: isSuccessApi } = useStatpaymentsummary(`filterby=${tab}&status=284210002`)
    const [state,setState] = useState<any>({})
    
    const onSubmit = (data: any) => {

    }
    const formatData = () => {

    }
    useEffect(()=>{
      if(dataApi) {
        let tempState = {
          series: [{
            name:  intl.formatMessage({ id: "text.price" }),
            data: dataApi.data?.map((item: any) => Number((+item).toFixed(0))),
          }, 
        ],
        zoom: {
          enabled: false,
          autoScaleYaxis: false,
          autoScaleXaxis: false,
        },
          options: {
            chart: {
              height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth',
              width: [1]
            },
            xaxis: {
              tickPlacement: 'between',
              // type: 'datetime',
              title: {
                text: intl.formatMessage({ id: `text.fiscal-year` })+' '+ (tab.includes('previous')? new Date().getFullYear() - 1:new Date().getFullYear()),
              },

              categories: dataApi.categories.map((item: any) => {
                let text = item
                if(tab =='thisyear'||tab =='previousyear') {
                  text = intl.formatMessage({ id: `text.month${item}` })
                }
                else {
                  text = intl.formatMessage({ id: "text.week" })+' '+ item
                }
                return text
              }),
              labels: {
                style: {
                  fontSize: "10px",
                  paddingLeft: "6px",
                  paddingRight: "6px",
              },
              }
            },
            tooltip: {
              x: {
                // format: 'dd/MM/yy HH:mm'
              },
              y: {
                formatter: function(e: any) {
                    return  formatCurrent(Number(e))
                }
            }
            },
            yaxis: {
              title: {
                text: `(${intl.formatMessage({ id: `text.chart.unit` })}: 1.000 VNĐ)`,
              },
              labels: {
                  style: {
                      // colors: labelColor,
                      // fontSize: "12px"
                  },
                  formatter: function(e: any) {
                      // if(e < 1000) return (Number(e)) //+ intl.formatMessage({ id: "text.currentVND" })
                      // else if(e < 1000000) return (Number(e)/1000) + intl.formatMessage({ id: "text.currentTH" })
                      // else 
                    return (formatCurrent(Number(e)/1000)) //+ intl.formatMessage({ id: "text.currentM" })
                  }
              }
          },
          },
        
        } as any
      setState(tempState)
      }
        // setValue('type','1')
    },[dataApi])

    return <div className='card h-100'>
    <div className='card-header border-0 py-3 bg-secondary bg-opacity-60 align-items-center'>
      <h5 className='mb-0 fw-bold'>{title}</h5>
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)} className=''>
            <SearchDropdown tab={tab} setTab={setTab} dataFilter={dataFilter}/>
        </form>
      </div>
    </div>
    <div className='card-body p-0 ps-3'>
      {state.options && 
        <Chart options={state.options} series={state.series} type="area" height={350} />
      }
    </div>
  </div>
}
export const ChartColumn = (props: any) => {
    // console.log('props',props)
    const {dataFilter,title,chartData} = props as any
    const [tab,setTab] = useState<any>('This month')
    // console.log('chartData',chartData)
    const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()
    const onSubmit = (data: any) => {

    }
    useEffect(()=>{
        // setValue('type','1')
    },[])
    
    return <div className='card h-100'>
    <div className='card-header border-0 py-3 bg-secondary bg-opacity-60 align-items-center'>
      <h5 className='mb-0 fw-bold'>{title}</h5>
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)} className=''>
            <SearchDropdown tab={tab} setTab={setTab} dataFilter={dataFilter}/>
        </form>
      </div>
    </div>
    <div className='card-body pt-5'>
        <Chart options={chartData.options} series={chartData.series} type='bar' height={300} />
    </div>
  </div>
}
export const OverAllSum = (props: any) => {
    // console.log('props',props)
    const {dataFilter,title, intl} = props as any
    const [tab, setTab] = useState<any>(filterDefault)
    const { data: dataApi, isSuccess: isSuccessPayment } = useStatoverallsummary(`filterby=${tab}&status=284210002`)

    const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()
    useEffect(()=>{
        // setValue('type','1')
    },[])
    const onSubmit = (data: any) => {

    }
    const returnData = (objecttype: any) => {
      let tempData = dataApi?.find((item: any) => item.objecttype == objecttype)||{}
      if(tempData.total) {
        tempData.total = Number((+tempData.total).toFixed(0))
      }
      return tempData
    }
    return <div className='card h-100'>
    <div className='card-header border-0 py-3 bg-secondary bg-opacity-60 align-items-center'>
      <h5 className='mb-0 fw-bold'>{title}</h5>
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)} className=''>
            <SearchDropdown tab={tab} setTab={setTab} dataFilter={dataFilter}/>
        </form>
      </div>
    </div>
    <div className='card-body pt-5'>
      <div className='d-flex flex- mb-3'>
        <div className="symbol symbol-50px me-5">
            <span className="bg-light-primary symbol-label">
                <KTSVG className="svg-icon svg-icon-2x svg-icon-primary" path={`/media/icons/custom/tnf_order_request.svg`}/>
            </span>
        </div>
        <div className='d-flex flex-column w-100'>
          <div className='text-dark text-hover-primary fs-6 fw-bold'>{intl.formatMessage({ id: "text.proposals" })}</div>
          <span className='fs-7'>{formatCurrent(returnData('Proposals').total)} VNĐ</span>
        </div>
      </div>
      <div className='d-flex flex- mb-3'>
        <div className="symbol symbol-50px me-5">
            <span className="bg-light-warning symbol-label">
                <KTSVG className="svg-icon svg-icon-2x svg-icon-warning" path={`/media/icons/custom/tnf_advance.svg`}/>
            </span>
        </div>
        <div className='d-flex flex-column w-100'>
          <div className='text-dark text-hover-primary fs-6 fw-bold'>{intl.formatMessage({ id: "text.advances" })}</div>
          <span className=' fs-7'>{formatCurrent(returnData('Advances')?.total)} VNĐ</span>
        </div>
      </div>
      <div className='d-flex flex- mb-3'>
        <div className="symbol symbol-50px me-5">
            <span className="bg-light-success symbol-label">
                <KTSVG className="svg-icon svg-icon-2x svg-icon-success" path={`/media/icons/custom/tnf_payment.svg`}/>
            </span>
        </div>
        <div className='d-flex flex-column w-100'>
          <div className='text-dark text-hover-primary fs-6 fw-bold'>{intl.formatMessage({ id: "text.payments" })}</div>
          <span className='fs-7'>{formatCurrent(returnData('Payments')?.total)} VNĐ</span>
        </div>
      </div>
      <div className='d-flex flex- mb-3'>
        <div className="symbol symbol-50px me-5">
            <span className="bg-light-info symbol-label">
                <KTSVG className="svg-icon svg-icon-2x svg-icon-info svg-custom" path={`/media/icons/custom/pending-proposal.svg`}/>
            </span>
        </div>
        <div className='d-flex flex-column w-100'>
          <div className='text-dark text-hover-primary fs-6 fw-bold'>{intl.formatMessage({ id: "text.pending-proposals" })}</div>
          <span className='fs-7'>({returnData('Pending_Proposals')?.amount}) {formatCurrent(returnData('Pending_Proposals')?.total)} VNĐ</span>
        </div>
      </div>
      <div className='d-flex flex- mb-3'>
        <div className="symbol symbol-50px me-5">
            <span className="bg-light-warning symbol-label">
                <KTSVG className="svg-icon svg-icon-2x svg-icon-warning svg-custom" path={`/media/icons/custom/pending-advance.svg`}/>
            </span>
        </div>
        <div className='d-flex flex-column w-100'>
          <div className='text-dark text-hover-primary fs-6 fw-bold'>{intl.formatMessage({ id: "text.pending-advances" })}</div>
          <span className='fs-7'>({returnData('Pending_Advances')?.amount}) {formatCurrent(returnData('Pending_Advances')?.total)} VNĐ</span>
        </div>
      </div>
      <div className='d-flex flex- mb-3'>
        <div className="symbol symbol-50px me-5">
            <span className="bg-light-danger symbol-label">
                <KTSVG className="svg-icon svg-icon-2x svg-icon-danger svg-custom" path={`/media/icons/custom/pending-payment.svg`}/>
            </span>
        </div>
        <div className='d-flex flex-column w-100'>
          <div className='text-dark text-hover-primary fs-6 fw-bold'>{intl.formatMessage({ id: "text.pending-payments" })}</div>
          <span className='fs-7'>({returnData('Pending_Payments')?.amount}) {formatCurrent(returnData('Pending_Payments')?.total)} VNĐ</span>
        </div>
      </div>
    </div>
  </div>
}
export const TopListDept = (props: any) => {
    // console.log('props',props)
    const [tab, setTab] = useState<any>(filterDefault)
    const { data: dataProposals , isSuccess: isSuccessProposal } = useTopdeptswithmostproposaltotal(`filterby=${tab}&status=284210002`)
    const { data: dataAdvances, isSuccess: isSuccessAdvances } = useTopdeptswithmostadvancetotal(`filterby=${tab}&status=284210002`)
    const { data: dataPayments, isSuccess: isSuccessPayments } = useTopdeptswithmostpaymenttotal(`filterby=${tab}&status=284210002`)

    const {dataFilter,title, intl} = props as any
    const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()
    const onSubmit = (data: any) => {

    }
    useEffect(()=>{
        // setValue('type','1')
    },[])
    const renderTab = (data: any, id: any,type: any, index?: any) => {
      return <div className="tab-content">
          <div className={clsx('tab-pane fade pt-3',{'active show': index == 0})} id={id}>
          {data && data.length != 0 ? 
            data.map((item: any,index: number) => {
              let {creaters} = item
              let more = 0
              if(creaters && creaters.length> 7) {
                creaters = creaters.filter((i: any,index: number) => index <8)
                more = creaters.length - 7
              }
              return <div key={index} className='d-flex align-items-center mb-3'>
              <span className={clsx(`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-3 bg-${colorBadge[index]}`)}></span>
              <div className="w-100">
                <div className='text-gray-700 mb-3'>
                  <Link className="text-dark text-hover-primary" to={`/requests/${type}?deptid=${item.id}`}>{item.departmentcode?`[${item.departmentcode}] `:''}{item.departmentname}</Link>
                </div>
                <div className='d-flex flex-stack flex-grow-1'>
                  <div className="symbol-group symbol-hover mb-3">
                    {creaters && 
                      creaters.map((i: any,ind: number) => (
                      <OverlayTrigger key={ind} overlay={<Tooltip>
                            {i.username}
                        </Tooltip>}>
                        <Link to={`/requests/${type}?userid=${i.id}`}>
                          <div className="symbol symbol-35px symbol-circle">
                          { i.avatar ? 
                            <img alt="avatar" src={i.avatar} />:
                            <span className={clsx(`symbol-label bg-${colorBadge[ind]} text-inverse-info fw-bolder text-uppercase`)}>{i.username && i.username[0]}</span>
                          }
                          </div>
                        </Link>
                      </OverlayTrigger>
                      ))
                    }
                    {
                      more !=0 &&
                      <Link to={`/requests/${type}?deptid=${item.id}`} className="symbol symbol-35px symbol-circle" >
                        <span className="symbol-label bg-secondary text-gray-700 fs-8 fw-bolder">+{more}</span>
                      </Link>
                    }
                    
                  </div>
                  <div className='d-flex align-items-center'>
                    <span className=' me-3'>{formatCurrent((+item.total).toFixed(0))} VNĐ</span>
                    <span className={clsx(`badge badge-${colorBadge[index]} px-2 fs-7`)}>{item.amount}</span>
                  </div>
                </div>
              </div>
            </div>
            })
            :
            intl.formatMessage({ id: "table.not-data" })
          }
        </div>
      </div>
    }
    return <div className='card h-100'>
    <div className='card-header border-0 py-3 bg-secondary bg-opacity-60 align-items-center'>
      <h5 className='mb-0 fw-bold'>{title}</h5>
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)} className=''>
            <SearchDropdown tab={tab} setTab={setTab} dataFilter={dataFilter}/>
        </form>
      </div>
    </div>
    <div className="card-body py-0">
        <ul className="nav nav-stretch nav-pills nav-pills-custom d-flex flex-end">
            <li className="nav-item p-0 ms-0">
                <a className="nav-link btn btn-color-gray-400 flex-center p-3 pb-2 active" data-kt-timeline-widget-4="tab" data-bs-toggle="tab" href="#kt_timeline_1">
                    <span className="nav-text fw-bold fs-6">{intl.formatMessage({ id: "text.proposals" })}</span>
                    <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                </a>
            </li>
            <li className="nav-item p-0 ms-0">
                <a className="nav-link btn btn-color-gray-400 flex-center p-3 pb-2" data-kt-timeline-widget-4="tab" data-bs-toggle="tab" href="#kt_timeline_2">
                    <span className="nav-text fw-bold fs-6">{intl.formatMessage({ id: "text.advances" })}</span>
                    <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                </a>
            </li>
            <li className="nav-item p-0 ms-0">
                <a className="nav-link btn btn-color-gray-400 flex-center p-3 pb-2" data-kt-timeline-widget-4="tab" data-bs-toggle="tab" href="#kt_timeline_3">
                    <span className="nav-text fw-bold fs-6">{intl.formatMessage({ id: "text.payments" })}</span>
                    <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded"></span>
                </a>
            </li>
        </ul>
      {renderTab(dataProposals,'kt_timeline_1','order',0)}
      {renderTab(dataAdvances,'kt_timeline_2','advance')}
      {renderTab(dataPayments,'kt_timeline_3','payment')}
    </div>
    
  </div>
}
export const TopListReimbursements = (props: any) => {
    // console.log('props',props)
    const {title, dataFilter,intl} = props as any
    const columns: Array<Column> = [{
      Header: (props) => <CustomHeader tableProps={props} title='text.advance-details' />,
      accessor: 'documentno',
      Cell: ({ ...props }) => {
        let createdat = new Date(props.data[props.row.index].createdat).toLocaleDateString('en-GB')
        return <div>
          <div className="">
            <Link className="text-dark text-hover-primary" to={`/requests/advance/${props.data[props.row.index].id}`}>
              <span>{props.data[props.row.index].documentno}</span>
              <span className="px-1">-</span>
              <span>{props.data[props.row.index].creater}</span>
            </Link>
          </div>
          <span className="text-gray-600">{intl.formatMessage({ id: "text.date-created" })}: {createdat}</span>
        </div>
      },
  }, {
      Header: (props) => <CustomHeader tableProps={props} title='text.total' className="text-end" />,
      accessor: 'creater',
      Cell: ({ ...props }) => {
        let remainingadvancedamount = Number((+props.data[props.row.index].remainingadvancedamount).toFixed(0))
        let totalamount = Number((+props.data[props.row.index].totalamount).toFixed(0))
        return <div className="text-end">
          <div><i className="text-danger">{formatCurrent(remainingadvancedamount)}</i></div>
          <div className="fw-bold">{formatCurrent(totalamount)}</div>
        </div>
      },
  },]
    const [tab, setTab] = useState<any>(filterDefault)
    const { data: dataApi, isSuccess: isSuccessPayments } = useStatpendingreimbursements(`filterby=${tab}&status=284210002`)
    const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()
    useEffect(()=>{
    },[])
    const onSubmit = (data: any) => {
    }
    return <div className='card h-100'>
    <div className='card-header border-0 py-3 bg-secondary bg-opacity-60 align-items-center'>
      <h5 className='mb-0 fw-bold'>{title}</h5>
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)} className=''>
            <SearchDropdown tab={tab} setTab={setTab} dataFilter={dataFilter}/>
        </form>
      </div>
    </div>
    <div className='chart-rei'>
        {
          isSuccessPayments && <KTTable
          data={dataApi}
          columns={columns}
          search
          pagination
          cardInnner
          pageOption={{limit: 10,page: 0}}
          theadClass="bg-warning bg-opacity-50"
          
      />
        }
    </div>
  </div>
}