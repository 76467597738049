import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

interface Props {
  data: string | number | undefined | any[] | ReactNode
  className?: string
  type?: 'date' | 'datetime' | 'time' | 'img' | 'boolean'
  badgeColor?: string
  badgeBorderSize?: number
  to?: string
  onClick?: () => void
  isIntl?: boolean
}

export const BasicCell: React.FC<Props> = ({ isIntl = false, ...props }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  let temp: any = props.data && props.data != 'NaN' && props.data != 'Invalid Date' ? props.data : props.data === 0 ? '0' : ''
  // format date
  if (props.type == 'date' && temp != '') {
    temp = new Date(temp).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
  } else if (props.type == 'datetime' && temp != '') {
    temp = new Date(temp).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  } else if (props.type == 'time' && temp != '') {
    temp = new Date(temp).toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit' })
  }
  // render boolean
  if (typeof props.data == 'boolean' || (!props.data && props.type == 'boolean')) {
    const isAcive = typeof props.data == 'boolean' ? props.data : false
    temp = <div className="form-check form-check-custom form-check-solid form-check-sm">
      <input className="form-check-input w-15px h-15px disabled" type="checkbox" value="" onChange={() => { }} checked={isAcive} />
    </div>
  }
  // render img
  if (props.type == 'img' && !!props.data) {
    temp = <img src={props.data as any} className='img-auto' style={{ width: '40px', height: '40px', objectFit: "contain" }} />
  }

  if (isIntl != false && typeof props.data == 'string') {
    temp = intl.formatMessage({ id: `"text.${temp}` as any })
  }
  const isClick = typeof props.to == 'string'
    || typeof props.onClick == 'function'

  return <div className={clsx(props.className, { 'cursor-pointer': isClick, 'text-primary': isClick })}
    onClick={() => {
      if (props.to) return navigate(props.to)
      props.onClick && props.onClick()
    }}>
    {Array.isArray(props.data)
      ? props.data.map((item, ind) => {
        let label = item.label
        if (isIntl != false && typeof label == 'string') {
          label = intl.formatMessage({ id: `"text.${label}` as any })
        }
        return <BasicCellBadge key={ind} color={item.colorb} borderSize={props.badgeBorderSize} temp={label} ismuti />
      })
      : props.badgeColor
        ? <BasicCellBadge color={props.badgeColor} borderSize={props.badgeBorderSize} temp={temp} />
        : temp}
  </div>
}

interface IBasicCellBadge {
  ismuti?: boolean
  color: string,
  borderSize?: number,
  temp: any
}

const BasicCellBadge: FC<IBasicCellBadge> = ({ ismuti, color, borderSize, temp }) => {
  const classtext = clsx(
    'badge',
    (color && !borderSize) && `badge-${color}`,
    borderSize && `border border-${borderSize} border-${color} text-${color}`, {
    'me-1 mb-1': ismuti
  })
  return <span className={classtext}> {temp} </span>
}