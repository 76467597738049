import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Column } from "react-table";
import { IAdUserGroup } from ".";
import { ApiService, ApiShowError, changeDataModify, getValueId, sortWithAny } from "../../../../../theme/helpers";
import { ContentHeader } from "../../../../../theme/layout/components/content";
import { BasicCell, CustomHeader, FilterDropdown, FormSubmit, InputSelect, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../../theme/partials";
import SearchPopup from "../../../../../theme/partials/layout/search-popup";
import { InputCheckBox } from "../../../../../theme/partials/widgets/form/InputCheckbox";
import { IInitField } from "../../../api";
import { apiAd } from "../../../api/admin";
import { popupMessage } from "../../../messages";
import { IAdUser } from "../users";

const breadCrumbs = [{ title: 'system', path: '/system-settings' }]

const defaultValues: IAdUserGroup = {
    code: null,
    name: null,
    defaultprofiles: null,
    assigntoallnewusers: false,
}
const apiurl = 'settings/usergroups'
const apiurlmember = 'settings/usergroupmembers'
export const AdUserGroupsDetailPage = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching } = apiAd.useUserGroupsByID(valueid)
    const { register, reset, watch, control, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdUserGroup>({
        defaultValues,
        resolver: yupResolver(yup.object({
            code: yup.string().required(),
            name: yup.string().required()
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    const optionDefaultProfiles: any[] = []
    return <>
        <ContentHeader title="system.user-groups" isInfo items={[...breadCrumbs, { title: 'system.user-groups', path: '/system-settings/user-groups' }]} />
        <form
            className="card mb-6"
            onSubmit={handleSubmit(async (data: IAdUserGroup) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate('/system-settings/user-groups')
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        handleClose={() => navigate('/system-settings/user-groups')} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.defaultprofiles'>
                            <Controller
                                name="defaultprofiles"
                                control={control}
                                render={({ field }) => <InputSelect
                                    options={optionDefaultProfiles}
                                    value={field.value}
                                    onChange={(e) => field.onChange(e.value)}
                                    className={clsx({ 'form-error': errors.defaultprofiles })}
                                />}
                            />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                        </KTFormItem>
                        <KTFormItem title='text.assigntoallnewusers'>
                            <InputCheckBox  {...register('assigntoallnewusers')} />
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
        {data?.id && <AdUserGroupMembers usergroup={data} />}
    </>
}

export interface IAdUserGroupMember extends IInitField {
    usergroupid: string | null
    usergroupcode: string | null
    usergroupname: string | null
    userid: string | null
    username: string | null
    fullname: string | null
}

const AdUserGroupMembers: FC<{ usergroup: IAdUserGroup }> = ({ usergroup }) => {
    const { data, isFetching, isSuccess, refetch } = apiAd.useUserGroupMembersByQuery(`?usergroupid=${usergroup.id}`)
    const [tableData, setTableData] = useState<IAdUserGroupMember[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setTableData(sortWithAny(data, { sort: "createdat", order: "desc" }))
        }
    }, [isFetching])

    const columns: Array<Column<any>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.username' />,
        accessor: 'username',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].username} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.fullname' />,
        accessor: 'fullname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].fullname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.created-at' />,
        accessor: 'createdat',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].createdat} type="datetime" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end min-w-100px' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(apiurlmember + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [keys, setKeys] = useState<Array<Array<string>>>([])
    const { data: usersData, isSuccess: iUsers, isLoading, refetch: loadUsers } = apiAd.useUsersByQuery(`?isinusergroupmember=false`)
    const optionsUser = iUsers ? usersData.map((item: IAdUser) => ({
        ...item,
        title: item.username,
        description: item.fullname+' / '+item.authenticationemail
    })) : []

    async function onSubmit(data: IAdUser[]) {
        try {
            const body: IAdUserGroupMember = {
                usergroupid: usergroup.id as string,
                usergroupcode: usergroup.code,
                usergroupname: usergroup.name,
                userid: null,
                username: null,
                fullname: null
            }
            await Promise.all(data.map(async m => await ApiService.post(apiurlmember, {
                ...body,
                userid: m.id,
                username: m.username,
                fullname: m.fullname
            })))
            popupMessage({ icon: 'success', autoClose: true })
            refetch()
        } catch (error) { ApiShowError(error) }
    }

    function handleClose() {
        setKeys([])
    }

    return <div className="position-relative">
        <KTTable
            isLoading={isFetching}
            toolbar={<>
                <FilterDropdown>
                </FilterDropdown>
                <KTButton action='new' onClick={async () => {
                    await loadUsers()
                    setKeys([['userid', 'id']])
                }} />
            </>}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search
        />
        <SearchPopup
            {...{
                isLoading,
                keys,
                handleClose,
                searchData: optionsUser,
                isMulti: true,
                fieldname: "username",
                selfSave: onSubmit
            }} />
    </div>
}
