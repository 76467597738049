/* eslint-disable */
import clsx from "clsx"
import React, { FC, useState, useEffect, useRef } from "react"
import { Card, Dropdown, Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { useParams, Link, useNavigate } from "react-router-dom"

import Swal from "sweetalert2"
import { ApiService } from "../../../theme/helpers/ApiService"
import { PageTitle } from "../../../theme/layout/core"
import { ApiShowError, exportCSV, formatCurrent, formatOptions, getLocalStorage, sortByNumber, sortWithDate, unique_arr } from "../../../theme/helpers"
import { ShowForPermission, getIsPermission, getIsRole } from "../../modules/auth/Permission"
import { popupDelete, popupLoading, popupMessage } from "../../modules/messages"
import { KTSVG } from "../../../theme/partials/widgets/utils/KTSVG"
import { CustomHeader, BasicCell, InputSelect, AccordionToggle, DivRoot, FilterDropdown, KTButton, RowIcon, KTFormItem, InputDate } from "../../../theme/partials"
import { KTTable } from "../../../theme/partials/widgets/table"
import { PaginationCustom } from "../../../theme/partials/widgets/table/pagination"
import { SearchTable } from "../../../theme/partials/widgets/table/search"
import { ContentHeader } from "../../../theme/layout/components/content"
import { useAdvance, usePayment, usePaymentForme, useSelectcreators, useSelectdepartments } from "../../modules/api/requests"
import { LocalData } from "../../modules/api/localData"
import { callApprovalHistory, callRenderLine, cloneRecord, closedRequestApproval, convertStatus, getEmailInfo, historiesApproval, printPdf, templateDetails } from "../orderRequest/details/function"
import { useAuth } from "../../modules/auth"
import { ActionApprovals } from "../orderRequest/details/actions"
import { apiAd } from "../../modules/api/admin"
import { useIntl } from "react-intl"
import { apiPrivate } from "../../modules/api"


const TemplateComponent: FC<{ permission: string }> = ({ permission }) => {
  const intl = useIntl()
  let {selectedLang} = getLocalStorage('siteConfig') as any
  selectedLang = selectedLang||'vi'
  const permission_modify = getIsPermission([permission,'MODIFY'])
  const permission_delete = getIsPermission([permission,'DELETE'])
  const [dataDefault, setDataDefault] = useState<Array<any>>([])
  const [tableData, setTableData] = useState<Array<any>>([])
  const [pageOption, setPageOption] = useState({ page: 1, limit: 50, total: 50 })
  

  const [optionsDepartments, setOptionsDepartments] = useState<any>([])
//   const [optionsCreaters, setOptionsCreaters] = useState<any>([])
  const [tempRecord, setTempRecord] = useState<any>({})
  const {auth} = useAuth()
  const {user} = auth || {} as any
  const isSuperAdmin = getIsRole(['SUPER'])
  const [filter, setFilter] = useState<any>({
    
  })
  const [valueSearch,setValueSearch] = useState<any>('')
  const optionsVendersType = [{value: 0, label: intl.formatMessage({ id: `text.all` as any })}].concat(Object.values(LocalData.venderType).map((i: any) =>({
    label: i['label'+selectedLang],
    value: i.value
  })))
  const filterParams = () => {
    let param = ''
    // console.log('filterParams',filter)
    if(filter) {
        if(filter.createdby != "" && filter.createdby != 0 && filter.createdby != undefined) {
            param += '&createdby='+filter.createdby
        }
        if(filter.deptid != "" && filter.deptid != 0 && filter.deptid != undefined) {
            param += '&departmentid='+filter.deptid
        }
        if(filter.documenttype) {
            param += '&documenttype='+filter.documenttype
        }
        if(filter.statusLocal) {
            if(filter.statusLocal == '284210005' || filter.statusLocal == '284210006') {
                let isonly = filter.statusLocal == '284210005'
                param += `&isonlywaiting=${isonly}&status=284210001`
            }
            else {
                param += '&status='+filter.statusLocal
            }
        }
        // if(filter.keyword) {
        //     param += '&keyword='+filter.keyword
        // }
        if(filter.fromdate) {
            param += '&fromdate='+filter.fromdate
        }
        if(filter.todate) {
            param += '&todate='+filter.todate
        }
        
    }
    return param
  }
  const currentApi = () => {
    let api = ''
    if(tab == 'forme') {
        api = 'find/findapprovalforme'
    }
    else if (tab == 'byme') {
        api = 'find/findrequestbyme'
    }
    if(tab == 'all') {
        api = ''
    }
    return api
  }
  const [tab,setTab] = useState<any>(user.isadminselfservice||isSuperAdmin? 'all':'byme')
  const [dataTabs,setDataTabs] = useState<any>({})
  const [dataTabs1,setDataTabs1] = useState<any>({})
  const [totalAll,setTotalAll] = useState<any>('')
  //-------------
    const { data: usersData, isSuccess: iUsers, isLoading: loadUsers } = useSelectcreators(`${tab == 'all'?``:``}`)
    const [optionUsernames,setOptionUsernames] = useState<any>([])
    useEffect(() => {
    if (!loadUsers && iUsers) {
        let temp = usersData ? [{label: intl.formatMessage({ id: `text.all` as any }), value: 0}].concat(usersData.map((i: any) => ({value: i.createdby?i.createdby:-1, label: i.createdby?i.creater:'No Infomation'}))) : []
        setOptionUsernames(temp)
    }
    }, [loadUsers])
    const { data: dataDept, isSuccess: iDept, isFetching: fetDept, isLoading: loadDepts } = useSelectdepartments(`${tab == 'all'?``:``}`)
    const [optionDepts,setOptionDepts] = useState<any>([])
    useEffect(() => {
    if (!loadDepts && iDept) {
        let temp = dataDept ? [{label: intl.formatMessage({ id: `text.all` as any }), value: 0}].concat(dataDept.map((i: any) => ({label: i.deptid?i.deptcode+'/'+i.deptname:'No Infomation' , value: i.deptid?i.deptid:-1}))) : []
        setOptionDepts(temp)
    }
    }, [loadDepts])
  //-------------
  const paramsTotal = (type:string) => {
    let param = '?type=waiting_and_created'
    if(type == 'forme') {
        param = '?type=waiting_approval&isonlywaiting=true&status=284210001'
    }
    else if(type == 'byme') {
        param = '?type=created_by_me&status=284210000' // new
    }
    return param
  }
  const { data: dataQuery, isSuccess, isFetching, isLoading } = usePaymentForme(currentApi(),`?page=${pageOption.page}&pagesize=${pageOption.limit}${filterParams()}${valueSearch?'&keyword='+valueSearch:''}`,user.isadminselfservice||isSuperAdmin && tab == 'all')
  const { data: dataTabsApi, isSuccess: isSuccessTab, isFetching: isFetchingTab, refetch: reFetchtab } = usePaymentForme('stat/totalrequests',paramsTotal('byme'),false) 
  const { data: dataTabsApi1, isSuccess: isSuccessTab1, isFetching: isFetchingTab1, refetch: reFetchtab1 } = usePaymentForme('stat/totalrequests',paramsTotal('forme'),false) 
  const optionStatusCustom = [{value: 0, label: intl.formatMessage({ id: `text.all` as any })}].concat(Object.values(LocalData.statusData).map((item: any)=> ({
    ...item,
    label: item["label"+selectedLang]
  })))
  const optionStatusLocal = () => {
    let arr = sortByNumber(optionStatusCustom,'index')
    if(tab == 'forme') {
        return arr.filter((item: any) => item.value != '284210001')
    }
    else if(tab == 'byme' || tab == 'all') {
        return arr.filter((item: any) => item.value != '284210005' && item.value != '284210006')
    }
  }
  const { data: objectData, isSuccess: isObjectData } = apiAd.useObjectsByNumber(permission)
  const arrObject: any = isObjectData ? objectData[0] : undefined
  const [approvalhistories,setApprovalhistories] = useState<any>(null)
  const urlApi = {
    settings: 'settings',
    typeRequest: 'payments/paymentrequests',
    sendMail: 'utilities/sendmail/withcustomtemplate',
    printPdf: 'print-payment',
    request: 'payment',
  }
  const [detailLine,setDetailLine] = useState<any>([])
  const [loadingLine,setLoadingLine] = useState<any>(false)

  let urlApiLines = 'payments/paymentrequestlines?paymentrequestid='
  useEffect(() => {
    if (dataQuery && !isFetching)
        getData()
  }, [dataQuery,isFetching])
  useEffect(() => {
    if (isSuccessTab && !isFetchingTab) {
        setDataTabs(dataTabsApi)
    }
  }, [isFetchingTab])
  useEffect(() => {
    if (isSuccessTab1 && !isFetchingTab1) {
        setDataTabs1(dataTabsApi1)
    }
  }, [isFetchingTab1])
  const getData = () => {
    let data = dataQuery.items
    if(tab == 'all') {
        setTotalAll(dataQuery.total)
    }
    setLoadingLine(false)
    setDetailLine([])
    let tempPagi = {...pageOption,total: dataQuery.total}
    setPageOption(tempPagi)
    data = sortWithDate(data,{sort:'documentdate', order: 'desc'})
    let tempDepartments =  [] as any
    let tempCreater =  [] as any
    data?.forEach((item: any) => {
        if(item.otherinfo && item.otherinfo.deptid && item.otherinfo.deptname) {
            tempDepartments.push({
                value: item.otherinfo?.deptid,
                label: item.otherinfo?.deptname,
                code: item.otherinfo?.deptcode
            })
            item.deptid = item.otherinfo?.deptid
        }
        item.fullname = item.User?.fullname
        tempCreater.push({
            value: item.createdby,
            label: item.creater +' - '+ item.fullname,
        })
        if(item.documenttype && LocalData.venderType[item.documenttype]) {
            item.type_document = LocalData.venderType[item.documenttype]['label'+selectedLang]
        }
        item.statusLocal = convertStatus(item.status, item.approvalhistory,user?.id)
        item.isSubmitto = item.approvalhistory?.Approval?.type != "20120002" && item.approvalhistory?.rule && item.approvalhistory?.rule[0]?.userid == user?.id
    })
    // setOptionsDepartments(unique_arr(tempDepartments,'value'))
    // setOptionsCreaters(unique_arr(tempCreater,'value'))
    setDataDefault(data)
  }
  useEffect(() => {
      setTableData([...dataDefault])
  }, [dataDefault])

const columnsNew = [
    {
        title: intl.formatMessage({ id: `text.code` as any }),
        class: 'w-100px',
        component: (data: any) => <BasicCell className='' data={data.expensevaluecode} />
    },
    {
        title: intl.formatMessage({ id: `text.description` as any }),
        class: '',
        component: (data: any) => <BasicCell className='' data={data.description} />
    },
    {
        title: intl.formatMessage({ id: `text.explain` as any }),
        class: '',
        component: (data: any) => <BasicCell className='' data={data.remark} />
    },
    {
        title: intl.formatMessage({ id: `text.quantity` as any }),
        class: 'text-center w-100px',
        component: (data: any) => <BasicCell className='' data={formatCurrent(data.quantity)} />
    },
    {
        title: intl.formatMessage({ id: `text.uom` as any }),
        class: 'w-100px',
        component: (data: any) => <BasicCell className='' data={data.uom} />
    },
    {
        title: intl.formatMessage({ id: `text.unit-price` as any }),
        class: 'text-center w-100px',
        component: (data: any) => <BasicCell className='' data={formatCurrent(data.unitprice)} />
    },
    {
        title: '% VAT',
        class: 'text-center w-100px',
        component: (data: any) => <BasicCell className='' data={data.vatperc} />
    },
    {
        title: intl.formatMessage({ id: `text.total` as any }),
        class: 'text-end min-w-100px',
        component: (data: any) => <BasicCell className='' data={formatCurrent(data.amountinclvat)} />
    },
]
// let columns = [{
//     Header: (props: any) => <CustomHeader tableProps={props} title='text.code' className='w-100px' />,
//     id: 'expensevaluecode',
//     Cell: ({ ...props }) => <BasicCell className='' data={props.data[props.row.index].expensevaluecode} />
// }, 
// {
//     Header: (props: any) => <CustomHeader tableProps={props} title='text.description' className='' />,
//     id: 'description',
//     Cell: ({ ...props }) => <BasicCell className='' data={props.data[props.row.index].description} />,
//   },
//   {
//     Header: (props: any) => <CustomHeader tableProps={props} title='text.explain' className='' />,
//     id: 'explain',
//     Cell: ({ ...props }) => <BasicCell className='' data={props.data[props.row.index].remark} />,
//   },
//   {
//     Header: (props: any) => <CustomHeader tableProps={props} title='text.quantity' className='text-center w-100px' />,
//     id: 'quantity',
//     Cell: ({ ...props }) => <BasicCell className='text-center' data={formatCurrent(props.data[props.row.index].quantity)} />,
// },
// {
//     Header: (props: any) => <CustomHeader tableProps={props} title='text.uom' className='w-100px' />,
//     id: 'uom',
//     Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].uom} />,
// },
// {
//     Header: (props: any) => <CustomHeader tableProps={props} title='text.unit-price' className='text-center w-100px' />,
//     id: 'unitprice',
//     Cell: ({ ...props }) => <BasicCell className='text-center' data={formatCurrent(props.data[props.row.index].unitprice)} />,
// },


// {
//     Header: (props: any) => <CustomHeader tableProps={props} title='% VAT' className='text-center w-100px' />,
//     id: 'vatperc',
//     Cell: ({ ...props }) => <BasicCell className="text-center" data={props.data[props.row.index].vatperc} />
// },
// {
//     Header: (props: any) => <CustomHeader tableProps={props} title='text.total' className='text-end' />,
//     id: 'amountinclvat',
//     Cell: ({ ...props }) => <BasicCell className="text-end" data={formatCurrent(props.data[props.row.index].amountinclvat)} />
// },
// ]
    let header = [
        {
            title: '',
            class: 'mw-25px',
            type: 'check_all',
            id: 'check_all'
        }, 
    {
      title: intl.formatMessage({ id: `text.document-date` as any }),
      class: ' text-start',
  }, {
    title: intl.formatMessage({ id: `text.document-code` as any }),
    class: ' text-start min-w-120px',
}, 
// {
//     title: intl.formatMessage({ id: `text.payment-object` as any }),
//     class: ' text-start min-w-100px',
// },
    {
        title: intl.formatMessage({ id: `text.payment-code` as any }),
        class: '',
    },
    {
        title: intl.formatMessage({ id: `text.payment-for` as any }),
        class: 'min-w-120px text-start',
    }, 
    {
        title: intl.formatMessage({ id: `text.advance-code` as any }),
        class: ' text-start',
    }, 
    
    {
        title: intl.formatMessage({ id: `text.total` as any }),
        class: ' min-w-100px',
    },
    {
        title: intl.formatMessage({ id: `text.subtotal` as any }),
        class: '',
    },
    {
        title: intl.formatMessage({ id: `text.status` as any }),
        class: 'text-center',
    },
    {
      title: intl.formatMessage({ id: `text.actions` as any }),
      class: 'text-end w-100px',
  },
    ]

    const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()

    
    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        const filterCreatedby = urlParams.get('userid');
        const filterDept = urlParams.get('deptid');
        let temp = {
            createdby: 0,
            deptid: 0,
            statusLocal: 0,
            documenttype: 0
        } as any
        let checkFilter = false
        if(filterCreatedby) {
            temp = Object.assign(temp,{
                'createdby':filterCreatedby
            }) 
            setValue('createdby',filterCreatedby)
            checkFilter = true
        }
        if(filterDept) {
            temp = Object.assign(temp,{
                'deptid':filterDept
            }) 
            setValue('deptid',filterDept)
            checkFilter = true
        }
        setFilter(temp)
        reset(temp)
    },[])
    const onSubmitFilter:any = (data: any) => {
        return data
        if(!filter) return data
        let tempData = data.map((item:any)=> item)
        if(filter.statusLocal ) {
            tempData = tempData.filter((i:any)=> i.statusLocal == filter.statusLocal)
        }
        
        if(filter.createdby) {
            tempData = tempData.filter((i:any)=> i.createdby == filter.createdby)
        }
        if(filter.deptid) {
            tempData = tempData.filter((i:any)=> i.deptid == filter.deptid)
        }
        if(filter.documenttype) {
            tempData = tempData.filter((i:any)=> i.documenttype == filter.documenttype)
        }
        let fromdate = filter.fromdate ? filter.fromdate: new Date()
        if(fromdate) {
            fromdate = new Date(fromdate).setHours(0,0,0)
        }
        
        let todate = filter.todate? filter.todate[0]: null 
        if(todate) {
            todate = new Date(todate).setHours(23,59,59)
        }
        if(todate) {
            tempData = tempData.filter((i:any)=> new Date(i.createdat).getTime() <= todate && new Date(i.createdat).getTime() >= fromdate)
        }
        // console.log('tempData',tempData)
        return tempData
    }
    const checkShowTab = () => {
        return true
        let check = false
        if((user.isadminselfservice || isSuperAdmin)) {
            if(dataTabs.totalWaitingApproval != 0 || dataTabs.totalRequestByMe != 0 ) {
                check = true
            }
        }
        else if (dataTabs.totalWaitingApproval != 0 && dataTabs.totalRequestByMe != 0) {
            check = true
        }
        return check
    }
    const templateFilter = <FilterDropdown
    width="350"
    onClose={() => {
        setFilter({})
        reset({})
        setPageOption(values => ({ ...values, page: 1 }))
        }
    }
    reset={reset}
    handleSubmit={handleSubmit}
    filterValue={filter}
    onSubmit={(data:any) => {
        let fromdate = data.fromdate ? data.fromdate[0]: null
        if(fromdate) {
            data.fromdate = new Date(fromdate).setHours(0,0,0)
            data.fromdate = new Date(fromdate).toISOString()
        }
        
        let todate = data.todate? data.todate[0]: null 
        if(todate) {
            data.todate = new Date(todate).setHours(23,59,59)
            data.todate = new Date(todate).toISOString()
        }
        // console.log('data',data)
        setPageOption(values => ({ ...values, page: 1 }))
        setFilter(data)
       
    }}>
        <div className="container-fluid">
            <div className="row">
            <div className="col-md-6 col-12">
                    <KTFormItem labelWidth="80" isBLockLabel title='text.from-date'>
                        <Controller
                        name='fromdate'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputDate
                            
                            className={clsx('form-control form-control-sm w-100',{ 'form-error': errors.fromdate })}
                            value={value}
                            onChange={onChange}
                            />
                        )}
                        />
                    </KTFormItem>
                </div>
                <div className="col-md-6 col-12">
                    <KTFormItem labelWidth="80" isBLockLabel title='text.to-date'>
                        <Controller
                        name='todate'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputDate
                            className={clsx('form-control form-control-sm w-100',{ 'form-error': errors.todate })}
                            value={value}
                            options={ {
                                minDate: watch('fromdate') && watch('fromdate')[0],
                            }
                            }
                            onChange={onChange}
                            />
                        )}
                        />
                    </KTFormItem>
                </div>
                {
                    tab == 'all' && 
                    <div className="col-md-12 col-12">
                        <KTFormItem labelWidth="80" isBLockLabel title='text.creator'>
                            <Controller
                                name='createdby'
                                control={control}
                                render={({ field }) => <InputSelect
                                    options={optionUsernames}
                                    isClearable
                                    value={optionUsernames?.find((e: any) => e.value == field.value)}
                                    onChange={e => {
                                    if (!e) return field.onChange(null)
                                        field.onChange(e.value)
                                    }
                                    }
                                />} />
                        
                        </KTFormItem>
                    </div>
                }
                
                <div className="col-12">
                    <KTFormItem labelWidth="80" isBLockLabel title='text.deptname'>
                        <Controller
                            name='deptid'
                            control={control}
                            render={({ field }) => <InputSelect
                                options={optionDepts}
                                isClearable
                                value={optionDepts?.find((e: any) => e.value == field.value)}
                                onChange={e => {
                                if (!e) return field.onChange(null)
                                field.onChange(e.value)
                                }
                                }
                            />} />
                    </KTFormItem>
                </div>
                <div className="col-12">
                    <KTFormItem labelWidth="80" isBLockLabel title='text.payment-object'>
                        <Controller
                            name='documenttype'
                            control={control}
                            render={({ field }) => <InputSelect
                            options={optionsVendersType}
                            isClearable
                            value={optionsVendersType?.find((e: any) => e.value == field.value)}
                            onChange={e => {
                                if (!e) return field.onChange(null)
                                    field.onChange(e.value)
                                }
                                }
                        />} />
                    </KTFormItem>
                </div>
                
                <div className="col-md-12 col-12">
                    <KTFormItem labelWidth="80" isBLockLabel title='text.status'>
                        <Controller
                        name='statusLocal'
                        control={control}
                        render={({ field }) => <InputSelect
                            options={optionStatusLocal()}
                            isClearable
                            value={optionStatusLocal()?.find((e: any) => e.value == field.value)}
                            onChange={e => {
                            if (!e) return field.onChange(null)
                                field.onChange(e.value)
                            }
                            }
                        />} />
                    </KTFormItem>
                </div>
            </div>
        </div>
</FilterDropdown>
    const toolbar = <>
        {
            !checkShowTab() && templateFilter
        }
        <KTButton permission={permission} action="new" />
    </>
    const [isCheck, setIsCheck] = useState<Array<any>>([])
    const [isCheckAll, setIsCheckAll] = useState(false);
    const handleSelectAll = (e: any) => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(tableData.filter((item:any)=>(item.status == "284210003") && item.createdby == user?.id).map((item) => item.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = (e: any) => {
        const { id, checked } = e.target;
        if (!checked) {
            setIsCheckAll(false)
        }
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };
    useEffect(() => {
        if(watch('status') && watch('status') != -1) {
            const grid = dataDefault.map((item: any)=> {
                if(item.id == tempRecord.id) {
                    item.status = watch('status')
                    item.statusLocal = convertStatus(watch('status'), item.approvalhistory,user?.id,1)
                    item.isApproval = true
                }
                return item
            })
            if(tab != 'forme') {
                reFetchtab()
            }
            else if(tab == 'forme') {
                reFetchtab1()
            }
            setDataDefault(grid)
            setValue('status',-1)
        }
      }, [watch('status')])
      
      const exportDataCSV = async() => {
        let tempData = [] as any
        let a = filterParams()
        if(a) {
            a = a.replace('&','?')
        }
        let urlApi = `payments/paymentrequests/${currentApi()}${a}${valueSearch?'&keyword='+valueSearch:''}`
        try {
            let {data} = await ApiService.get(urlApi)
            data?.items?.forEach((item:any)=> {
                let temp = {
                    "Ngày chứng từ": item.documentdate && new Date(item.documentdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
                    "Mã chứng từ": item.documentno,
                    "Thanh toán cho": item.sourcename,
                    "Nội dung": item.employeeadvancerequest,
                    "Phương thức": LocalData.paymentMethod[item.paymentmethodcode]?.label,
                    "Tổng tiền": item.totalamount,
                    "Trạng thái": LocalData.statusData[item.status]?.label,
                    "Người lập phiếu": item.creater,
                }
                tempData.push(temp)
            })
            let fileName = 'request-payment-list_'+new Date().toLocaleDateString()
            exportCSV(tempData,fileName)
        } 
        catch (error) { ApiShowError(error) }
        
    }
    const paginationTable:any = () => {
        let {page,limit} = pageOption
        return onSubmitFilter(tableData)//.filter((item: any,index: any) => index >=(page-1)*limit && index< page*limit)||[]
    }
    const returnFuncSearch = (keyword: any) => {
        setPageOption(values => ({ ...values, page: 1 }))
        setValueSearch(keyword)
    }
    const [resettoggle,setresettoggle] = useState(false)
    const handleClickTab = (active: string) => {
        if(tab == active) {
            return
        }
        setPageOption(values => ({ ...values, page: 1 }))
        setValueSearch('')
        setresettoggle(true)
        setTimeout(()=> {
            let searchA = document.getElementById('tableKeySearch') as any
            if(searchA) {
                searchA.value = ''
            }
        },200)
        setFilter({})
        reset({})
        setDetailLine([])
        setDataDefault([])
        setTab(active)
    }
    return <div className="position-relative">
      <ContentHeader title={intl.formatMessage({ id: `text.list-of-payment` as any })} elements={toolbar}/>
      {
            checkShowTab() &&
            <div className="">
                <div className="nav nav-tabs gap-1-custom">
                    {(user.isadminselfservice || isSuperAdmin) && 
                        <div className="nav-item mt-2">
                            <button className={clsx("border-0 rounded-top-3  ms-0 pe-15 ps-4 py-2 position-relative shadow",{'active bg-white': tab =='all',}, {'bg-secondary': tab !='all',})} onClick={e=>handleClickTab('all')}>{intl.formatMessage({ id: `text.all` as any })}</button>
                        </div>
                    }
                    {
                        // dataTabs.totalRequestByMe &&
                        <div className="nav-item mt-2">
                            <button className={clsx("border-0 rounded-top-3 ms-0 pe-15 ps-4 py-2 position-relative shadow",{'active bg-white': tab =='byme'}, {'bg-secondary': tab !='byme',})} onClick={e=>handleClickTab('byme')}>
                                {
                                    dataTabs?.total ?
                                    <span className="position-absolute top-0 end-0 translate-middle-y badge badge-primary h-20px fs-8">{dataTabs?.total}</span>
                                    :
                                    <></>
                                }
                                {intl.formatMessage({ id: `text.byme` as any })}
                            </button>
                        </div>
                    }
                    {
                        // dataTabs.totalWaitingApproval ? 
                        <div className="nav-item mt-2">
                            <button className={clsx("border-0 rounded-top-3 ms-0 pe-15 ps-4 py-2 position-relative shadow",{'active bg-white': tab =='forme'}, {'bg-secondary': tab !='forme',})} onClick={e=>handleClickTab('forme')}>
                                {
                                    dataTabs1?.total ?
                                    <span className="position-absolute top-0 end-0 translate-middle-y badge badge-info h-20px fs-8">{dataTabs1?.total}</span>
                                    :
                                    <></>
                                }
                                {intl.formatMessage({ id: `text.forme` as any })} 
                            </button>
                        </div>
                    }
                </div>
            </div>
        }
        <Card className="rounded-top-0">
            {/* <HeaderTableFilter {...{ handleSearch }} /> */}
            <div className="d-flex flex-stack align-items-center p-3">
                <SearchTable readOnly={isFetching} onEnter={true} returnFuncSearch={returnFuncSearch} valueSearch={valueSearch} data={dataDefault} setTableData={setTableData} keySearch={['creater','employeeadvancerequest','sourcename','documentno', 'totalamount', 'type_document']} />
                <div className="d-flex gap-2 flex-center">
                    {/* {Object.values(LocalData.statusData).map((item:any,index:number)=> {
                        const count = onSubmitFilter(tableData).filter((i:any)=>i.statusLocal == item.value).length
                        return count != 0 && <span key={index}>
                            <span className={`badge badge-${item.badge} p-3`}>{item['label'+selectedLang]}: {count} </span>
                        </span>
                    })} */}
                    {
                        checkShowTab() && templateFilter
                    }
                    <span className={clsx("btn btn-sm btn-success d-flex flex-center",{'disabled':tableData.length == 0})} onClick={exportDataCSV}><i className="bi bi-download me-1"></i>{intl.formatMessage({ id: `text.export` as any })}</span>
                    {isCheck.length != 0 && <span className="btn btn-sm btn-danger" onClick={e =>closedRequestApproval(intl, dataDefault, setDataDefault, isCheck, `${urlApi.typeRequest}/upsertmulti`, setIsCheck, setIsCheckAll)}>
                        <KTSVG  className="svg-icon svg-icon-2 svg-icon-white" path="/media/icons/duotune/arrows/arr015.svg"/>
                        {intl.formatMessage({ id: `text.close-request` as any })} ({isCheck.length})
                    </span>}
                </div>
            </div>
            <div className="table-loading px-3">
                <table className='table table-row-bordered dataTable no-footer table-hover'>
                    <thead>
                        <tr className="fw-bolder">
                            {header.map((cell, index) => 
                                <th key={'groups' + index} className={clsx("",cell.class,)}>
                                    {cell.type == 'check_all'?<div className="form-check-sm form-check form-check-custom form-check-solid"><input disabled={ !onSubmitFilter(tableData).find((item:any)=>item.status == "284210003")} className="cursor-pointer form-check-input" checked={isCheckAll} value='all' onChange={handleSelectAll} type="checkbox" id={cell.id} /></div>:cell.title}
                                </th>
                            )}
                            
                        </tr>
                    </thead>
                    <Accordion as={'tbody'} >
                        {paginationTable().length != 0 && !isFetching?
                        paginationTable().map((item:any, index:number) => <DivRoot key={index}>
                            <tr className={clsx("align-top hover-approval", )}>
                                <td className="collapsed w-25px">
                                    <div className="form-check-sm form-check form-check-custom form-check-solid">
                                        <input className={clsx("form-check-input check_input cursor-pointer",{"disabled": (item.status != "284210003") || item.createdby != user?.id})} type="checkbox" checked={isCheck.includes(item.id)} onChange={handleClick} value={item.id} id={`${item.id}`}/>
                                    </div>
                                </td>
                                <td className="px-0">
                                    <AccordionToggle resettoggle={resettoggle} eventKey={'body' + index}>
                                        <div onClick={(e: any) => callRenderLine(urlApiLines,item.id, setDetailLine,detailLine,'paymentrequestid',setLoadingLine)}>
                                            <span className={clsx("px-3 d-block", {"d-block w-100 min-h-30px": !item.documentdate})}>
                                                {item.documentdate && new Date(item.documentdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} 
                                            </span>
                                        </div>
                                    </AccordionToggle>
                                    
                                </td>
                                <td className="px-0">
                                    <div onClick={(e: any) => callRenderLine(urlApiLines,item.id, setDetailLine,detailLine,'paymentrequestid',setLoadingLine)}>
                                    <AccordionToggle  eventKey={'body' + index} >
                                        <span className={clsx("px-3 d-block", {"d-block w-100 min-h-30px": !item.documentno})}>
                                            {item.documentno}
                                        </span>
                                    </AccordionToggle>
                                    </div>
                                     
                                </td>
                                <td className="px-0">
                                    <div onClick={(e: any) => callRenderLine(urlApiLines,item.id, setDetailLine,detailLine,'paymentrequestid',setLoadingLine)}>
                                    <AccordionToggle eventKey={'body' + index} >
                                        <span className={clsx("px-3 d-block", {"d-block w-100 min-h-30px": !item.expensecode})}>
                                            {item.expensecode}
                                        </span>
                                    </AccordionToggle> 
                                    </div>
                                    
                                </td>
                                <td className="px-0">
                                    <div onClick={(e: any) => callRenderLine(urlApiLines,item.id, setDetailLine,detailLine,'paymentrequestid',setLoadingLine)}>
                                    <AccordionToggle  eventKey={'body' + index} >
                                        <span className={clsx("px-3 d-block", {"d-block w-100 min-h-30px": !item.sourcename})}>
                                            {item.sourcename}
                                        </span>
                                    </AccordionToggle> 
                                    </div>
                                    
                                </td>
                                
                                
                                <td className="px-0">
                                    <div onClick={(e: any) => callRenderLine(urlApiLines,item.id, setDetailLine,detailLine,'paymentrequestid',setLoadingLine)}>
                                    <AccordionToggle  eventKey={'body' + index} >
                                        <span className={clsx("px-3 d-block", {"d-block w-100 min-h-30px": !item.employeeadvancerequestno})}>
                                            {item.employeeadvancerequestno}
                                        </span>
                                    </AccordionToggle>
                                    </div>
                                    
                                 </td>
                                 <td className="px-0">
                                    <div onClick={(e: any) => callRenderLine(urlApiLines,item.id, setDetailLine,detailLine,'paymentrequestid',setLoadingLine)}>
                                    <AccordionToggle eventKey={'body' + index} >
                                        <span className={clsx("px-3 d-block", {"d-block w-100 min-h-30px": !item.totalamount})}>
                                            {formatCurrent(item.totalamount)}
                                        </span>
                                    </AccordionToggle>
                                    </div>
                                </td>
                                 <td className="px-0">
                                    <div onClick={(e: any) => callRenderLine(urlApiLines,item.id, setDetailLine,detailLine,'paymentrequestid',setLoadingLine)}>
                                    <AccordionToggle  eventKey={'body' + index} >
                                        {/* {
                                            LocalData.paymentMethod[item.paymentmethodcode] && 
                                            <span className={clsx("px-3 d-block", {"d-block w-100 min-h-30px": !item.paymentmethodcode})}>
                                                {LocalData.paymentMethod[item.paymentmethodcode]['label'+selectedLang]}
                                            </span>
                                        } */}
                                        <span className={clsx("px-3 d-block", {"d-block w-100 min-h-30px": !(+item.totalamount - +item.advancedamount)})}>
                                            {+item.totalamount - +item.advancedamount >= 0 ? formatCurrent(+item.totalamount - +item.advancedamount):
                                            <span className="text-danger">{`(${formatCurrent(Math.abs(+item.totalamount - +item.advancedamount))})`}</span>}
                                        </span>
                                        
                                    </AccordionToggle>
                                    </div>
                                    
                                </td>
                                
                                
                                
                                <td className="text-center px-0"> 
                                    <div onClick={(e: any) => callRenderLine(urlApiLines,item.id, setDetailLine,detailLine,'paymentrequestid',setLoadingLine)}>
                                    <AccordionToggle  eventKey={'body' + index} >
                                        {LocalData.statusData[item.statusLocal] && 
                                            <span className={clsx(`badge badge-${LocalData.statusData[item.statusLocal]?.badge}`)}>{LocalData.statusData[item.statusLocal]['label'+selectedLang]}</span>
                                        }
                                    </AccordionToggle> 
                                    </div>
                                    
                                </td>
                                <td className="text-end">
                                    <div className='d-flex flex-end gap-2'>
                                    {
                                            item.status == '284210001' && item.approvalhistory?.open && item.approvalhistory?.rule?.find((i:any)=>i.stage == item.approvalhistory?.currentstage && i.userid == user?.id)  && !item.isApproval &&
                                            <div className="in-approval menu-state-bg-light-warning menu-gray-600 w-150px menu menu-sub menu-sub-dropdown show bg-light-warning rounded border-warning border border-dashed">
                                                <ActionApprovals 
                                                approvalUser={item}
                                                approvalhistories={item.approvalhistory}
                                                approvalLevels={item.approvalhistory?.rule}
                                                totalamount={item?.totalamount}
                                                valueid={item?.id}
                                                tableData={item.PaymentRequestLines}
                                                setValue={setValue}
                                                includeFromlist={true}
                                                setTempRecord={setTempRecord}
                                                emailnotification={getEmailInfo(item.approvalhistory?.Approval?.emailnotification||{},item,permission)}
                                                urlApi={urlApi}
                                                employeeAdvanceData= {
                                                    {
                                                      employeeadvancerequestid: item.employeeadvancerequestid,
                                                      remainingadvancedamount: item.remainingadvancedamount
                                                    }
                                                }
                                            />
                                        </div>
                                        }
                                        {permission_modify && 
                                            <RowIcon action="modify" to={item.id} />
                                        }
                                        {/* <RowIcon action="delete" className={clsx({"disabled opacity-75":(!permission_delete || user?.id != item.createdby || item.status != "284210000")})} onClick={async () => {
                                            const id = item.id
                                            if(!permission_delete || user?.id != item.createdby || item.status != "284210000") return
                                            try {
                                                let tempDataemp = {
                                                    open: true,
                                                }
                                                ApiService.put('employees/employeeadvancerequests' + `/${item.employeeadvancerequestid}`,tempDataemp)
                                                await ApiService.delete(urlApi.typeRequest + `/${id}`)
                                                popupMessage({ icon: 'success', autoClose: true })
                                                setDataDefault((pre:any) => pre.filter((f:any) => f.id != id))
                                            } 
                                            catch (error) { ApiShowError(error) }
                                            
                                        }} /> */}
                                       <Dropdown>
                                            <Dropdown.Toggle as={'div'} className='btn btn-light-info btn-active-info btn-sm btn-icon h-el-after'>
                                                <i className="fs-4 bi bi-three-dots-vertical" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px p-2' >
                                                <Dropdown.Item onClick={async () => {
                                                        let props = {
                                                            item,
                                                            arrObject,
                                                            dataDefault,
                                                            setDataDefault,
                                                            urlApi: `${urlApi.typeRequest}/upsertwithdetails`,
                                                            fields: ['createremail','documentno','User', 'approvalhistory','remainingadvancedamount','advancedamount','employeeadvancerequestid','employeeadvancerequest','employeeadvancerequestno'],
                                                            lineDetails: 'PaymentRequestLines',
                                                            apiGetLine: `payments/paymentrequestlines/?paymentrequestid=${item.id}`,
                                                            user: user,
                                                            tab: tab,
                                                            reFetchtab: reFetchtab,
                                                        }
                                                        cloneRecord(props)
                                                    }} as='div' className={clsx('menu-item ',{ })}>
                                                    <Link to={'#'} className=''> 
                                                        <span className="menu-link d-flex align-items-center gap-2 py-2">
                                                        <i className="bi bi-files fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.duplicate` as any })}</span></i>
                                                        </span>
                                                        
                                                    </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item as='div' onClick={e=> {
                                                    let url =  window.location.origin
                                                    let dataTemp = {
                                                        "pagepath": `${url}/${urlApi.printPdf}/${item.id}`,
                                                        "filename": `${urlApi.printPdf}-${watch('code')?watch('code'):new Date().getTime()}`,
                                                        "landscape": '0',
                                                    }
                                                    printPdf(dataTemp,ApiService)
                                                }} className={clsx('menu-item ',)}>
                                                    <Link to='#' className=''> 
                                                    <span className="menu-link d-flex align-items-center gap-2 py-2">
                                                        <i className="bi bi-printer fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.print` as any })}</span></i>
                                                    </span>
                                                        
                                                    </Link>
                                                </Dropdown.Item>
                                                
                                                <Dropdown.Item onClick={e=> { callApprovalHistory(item,setApprovalhistories)}} as='div' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" className={clsx('menu-item ',{'disabled opacity-50': !item.approvalhistory })}>
                                                    <Link to='#' className=''> 
                                                        <span className="menu-link d-flex align-items-center gap-2 py-2">
                                                        <i className="bi bi-chat-square-text fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.approval-history` as any })}</span></i>
                                                        </span>
                                                        
                                                    </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item as='div' onClick={async () => {
                                                    let isQuestion = await popupDelete()
                                                    if(!isQuestion) {
                                                        return
                                                    }
                                                    const id = item.id
                                                    if(((!permission_delete || user?.id != item.createdby || item.status != "284210000") && !isSuperAdmin) || (isSuperAdmin && (item.status != "284210000" || item.status != "284210003"))) {
                                                        return
                                                    }
                                                    try {
                                                        if(item.employeeadvancerequestid) {
                                                            let tempDataemp = {
                                                                open: true,
                                                            }
                                                            ApiService.put('employees/employeeadvancerequests' + `/${item.employeeadvancerequestid}`,tempDataemp)
                                                        }
                                                        await ApiService.delete(urlApi.typeRequest + `/${id}`)
                                                        popupMessage({ icon: 'success', autoClose: true })
                                                        setDataDefault((pre:any) => pre.filter((f:any) => f.id != id))
                                                    } 
                                                    catch (error) { ApiShowError(error) }
                                                    
                                                }} className={clsx('menu-item ',{'disabled opacity-50': ((!permission_delete || user?.id != item.createdby || item.status != "284210000") && !isSuperAdmin) || (isSuperAdmin && (item.status == "284210000" || item.status == "284210003")) })}>
                                                    <Link to='#' className=''> 
                                                        <span className="menu-link d-flex align-items-center gap-2 py-2">
                                                        <i className="bi bi-trash3 fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.delete` as any })}</span></i>
                                                        </span>
                                                        
                                                    </Link>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    
                                </td>

                            </tr>
                            <tr className="border-0">
                                <td colSpan={20} className='p-0'>
                                    <Accordion.Collapse eventKey={'body' + index} className='border border-bottom-1'>
                                        <div>
                                        {templateDetails(detailLine, columnsNew, intl, loadingLine)}
                                        </div>
                                    </Accordion.Collapse>
                                </td>
                            </tr>
                        </DivRoot>
                        ): 
                        <tr className={'text-center'}>
                            <td className="p-3" colSpan={10}>
                                {
                                 !isSuccess ? <div className='custom-loading'><span className='loader'></span></div>:intl.formatMessage({ id: `table.not-data` as any })
                                }
                            </td>
                        </tr>
                        }
                    </Accordion>
                </table>
            </div>
            <PaginationCustom
                page={pageOption.page - 1}
                pageSize={pageOption.limit}
                totalData={pageOption.total}
                gotoPage={function (pageIndex: number): void {
                    setPageOption(values => ({ ...values, page: pageIndex + 1 }))
                }}
                setPageSize={function (pageSize: number): void {
                    setPageOption(values => ({ ...values, limit: pageSize, page: 1 }))
                }}
                className="m-6"
            />
            <div className="d-none">
                <InputDate value={''} className="d-none" onChange={e => {}}/>
            </div>
        </Card>
        {historiesApproval(approvalhistories,intl)}
    </div>
}

export default TemplateComponent;