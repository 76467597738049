import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useAsyncDebounce } from 'react-table';

export function removeVietnameseTones(str: string, isWhitespace?: boolean) {
  if (!str) return str;
  str = String(str)
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  // eslint-disable-next-line no-useless-escape
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return (isWhitespace ? str : str.replaceAll(' ', '')).toLowerCase().trim();
}

interface PropsSearchTable {
  className?: string
  data?: any
  setTableData: (e: any[]) => void
  keySearch?: string[]
  type?: string
  returnFuncSearch?: any
  valueSearch?: any
  onEnter?: any
  readOnly?: any
}

const TWO_HUNDRED_MS = 500

export const SearchTable: React.FC<PropsSearchTable> = ({ className, data, setTableData, keySearch, type, returnFuncSearch, valueSearch, onEnter, readOnly }) => {
  const [tempData] = useState<any[]>([])

  const searchItems = (value: any) => {
    if(returnFuncSearch) {
      returnFuncSearch(value)
      return
    }
    if (!data) return
    tempData.splice(0, tempData.length, ...data)
    if (value !== '') {
      let results: any = []
      for (let j = 0; j < tempData.length; j++) {
        if (keySearch?.length) {
          let valueByKey: any = {}
          keySearch.forEach(item => {
            valueByKey[item] = tempData[j][item]
          })
          if (searchingFunc(valueByKey, value)) {
            results.push(tempData[j])
          }
        } else {
          if (searchingFunc(tempData[j], value)) {
            results.push(tempData[j])
          }
        }
      }
      if(type) {
        results = {
          type: type,
          results: results
        }
      }
      return setTableData(results)
    }
    if(type) {
      data = {
        type: type,
        results: data
      }
    }
    setTableData(data)
  }

  function checkType(value: string) {
    const untype = ["</p>", "</div>", "</ol>", "</ul>", "</span>"]
    return untype.filter(item => value.split(item).length).length != 0
  }

  const searchingFunc = (obj: any, value: any) => {
    for (let index in Object.keys(obj)) {
      const key = Object.keys(obj)[index]
      if (obj[key] && typeof obj[key] === 'string') {
        if (checkType(obj[key])) {
          if (removeVietnameseTones(obj[key]).indexOf(removeVietnameseTones(value)) != -1) {
            return true
          }
        }
      }
    }
    return false
  }

  return <InputSeacrch {...{
    className,
    onEnter,
    readOnly,
    value: valueSearch,
    handleChange(e) {
      searchItems(e)
    },
  }} />
}

interface IInputSeacrch {
  className?: string
  value?: string
  handleChange: (e: string) => void
  onEnter?: any
  readOnly?: any
}

export const InputSeacrch: FC<IInputSeacrch> = ({ className, value, handleChange, onEnter, readOnly }) => {
  const intl = useIntl()
  const [search, setSearch] = useState<any>(value || '');
  const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()
  useEffect(()=> {
    setValue('search',search)
  },[])
  const onChange = useAsyncDebounce(value => handleChange(value), TWO_HUNDRED_MS);
  const onSubmit = (data: any) => {
    if(!onEnter) return
    handleChange(search)
  }
  return <form onSubmit={handleSubmit(onSubmit)} className={clsx('position-relative', className)}>
    <i className="fa-solid fa-magnifying-glass position-absolute ms-3" style={{ lineHeight: '30px' }} />
    <input
      id='tableKeySearch'
      readOnly={readOnly}
      {...register('search')}
      // value={search}
      onChange={(e) => {
        setSearch(e.target.value);
        !onEnter && onChange(e.target.value);
      }}
      className={clsx('form-control form-control-sm ps-9')}
      placeholder={intl.formatMessage({ id: "table.search" })}
    />
  </form>
}