import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router"
import { Column } from "react-table"
import { FormattedMessage, useIntl } from "react-intl";
import { sortByUpdateAt, ApiService, getValueId, changeDataModify, ApiShowError } from "../../../theme/helpers"
import { ContentHeader } from "../../../theme/layout/components/content"
import { CustomHeader, BasicCell, RowIcon, FilterDropdown, KTButton, KTTable, yup, TextFormByUser, FormSubmit, KTFormItem, DivHr, InputSelect, InputHtml } from "../../../theme/partials"
import { InputTag } from "../../../theme/partials/widgets/form/InputTag"
import { IInitField } from "../../modules/api"
import { apiAd } from "../../modules/api/admin"
import { popupMessage } from "../../modules/messages"
import { MergedProps } from "../../router/AppRoutes"


interface IAdEmailTemplate extends IInitField {
    code: string | null
    name: string | null
    title: string | null
    body: string | null
    mailwith: string | null
    mailtype: string | null
    allowobjects: any
}

const apiurl = 'settings/emailtemplates'
export default function AdEmailTemplate({ keyElement, permission }: MergedProps) {
    const { data, isSuccess, isFetching } = apiAd.useEmailTemplates()
    const [tableData, setTableData] = useState<IAdEmailTemplate[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].code}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" permission={permission} to={props.data[props.row.index].id} />
            <RowIcon action="delete"
                disabled={process.env.REACT_APP_RESET_PASSWORD == props.data[props.row.index].code}
                permission={permission}
                onClick={async () => {
                    const id = props.data[props.row.index].id
                    await ApiService.delete(apiurl + `/${id}`)
                    popupMessage({ icon: 'success', autoClose: true })
                    setTableData(pre => pre.filter(f => f.id != id))
                }} />
        </div>
    }]

    return <>
        <ContentHeader title={keyElement} elements={<>
            <FilterDropdown> </FilterDropdown>
            <KTButton action='new' />
        </>} />
        <KTTable
            isLoading={isFetching}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search />
    </>
}

const defaultValues: IAdEmailTemplate = {
    code: null,
    name: null,
    title: null,
    body: null,
    mailwith: null,
    mailtype: null,
    allowobjects: null
}

export const AdEmailTemplatesDetailPage = ({ keyElement, pathElement, permission }: MergedProps) => {
    const intl = useIntl()
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching } = apiAd.useEmailTemplatesId<IAdEmailTemplate>(valueid)
    const { register, reset, control, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdEmailTemplate>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            name: yup.string().required()
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching) {
            reset(data)
            const body = document.querySelector('body')
            body?.addEventListener('click', callFuncOutsiteContent)
        }
    }, [isFetching])

    const { data: objectsData, isSuccess: isObjects } = apiAd.useObjectsByQuery(`?allowcustomfield=true`)
    const { data: objectCustomFieldsData, isSuccess: isObjectCustomFields } = apiAd.useObjectCustomFields()
    const { data: dataListUrl, isSuccess: isListUrl } = apiAd.useListUrlsForEmailTemplate()

    const optionObjects = isObjects && isListUrl && isObjectCustomFields ? [
        ...objectsData.map((i: any) => ({
            ...i,
            value: i.objectid,
            label: `${i.objectid}_${i.objectname}`,
            items: objectCustomFieldsData.filter((f: any) => f.objectcode == i.objectid).map((i: any) => i.labelnamesystem)
        })),
        ...dataListUrl.map((i: any) => ({
            ...i,
            value: i.objecttype,
            label: `000000_${i.objecttype}`,
            items: i.fieldnames
        }))
    ] : []

    let [editorModel, setEditorModel] = useState<any>()

    function handleAddItem(text: string) {
        var textTag = '{{' + text + '}}';

        editorModel.model.change((writer: any) => {
            writer.insertText(textTag, editorModel.model.document.selection.getFirstPosition());
        });
    }

    function handlePreview(e: any) {
        e.preventDefault()
        const htmlInput = document.getElementById('html-input')
        const preview = document.getElementById('html-preview')
        const isPreview = htmlInput?.classList.value.indexOf('d-none') != -1
        if (!isPreview) {
            htmlInput?.classList.add('d-none')
            preview?.classList.add('d-block')
            preview?.classList.remove('d-none')
        } else {
            htmlInput?.classList.remove('d-none')
            preview?.classList.remove('d-block')
            preview?.classList.add('d-none')
        }
    }

    function handleClose() {
        navigate(pathElement)
    }

    const btnSourceView: HTMLButtonElement | null = document.querySelector('.ck-source-editing-button')
    const [isSourceView, setIsSourceView] = useState(false)

    function callFuncOutsiteContent(event?: any) {
        const element: HTMLDivElement | null = event.target
        if (!element?.closest(".ck-editor")) {
            if (!!btnSourceView && btnSourceView.classList.contains("ck-on")) btnSourceView.click()
        }
        setIsSourceView (btnSourceView?.classList.contains("ck-on") ? true : false)
    }

    return <>
        <ContentHeader title={keyElement} isInfo items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IAdEmailTemplate) => {
                try {
                    data.mailwith = optionObjects
                        .filter(f => watch('allowobjects')?.includes(f.value))
                        .map((item: any) => item.items)
                        .reduce((tol, pre) => [...tol, ...pre], []).join(',')

                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    handleClose()
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        permission={permission}
                        isSubmitting={isSubmitting}
                        handleClose={handleClose} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                        </KTFormItem>
                    </div>

                    <DivHr />
                    <div className="d-flex flex-stack">
                        <div className="fs-4 mb-4"><FormattedMessage id="text.email-template.detail" /></div>
                    </div>
                    <div className="col-12">
                        <KTFormItem title='text.subject' isBLockLabel>
                            <input className={`form-control form-control-sm ${errors.title && 'form-error'}`} {...register('title')} />
                        </KTFormItem>
                        <KTFormItem title='text.object' isBLockLabel>
                            <Controller
                                control={control}
                                name="allowobjects"
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isMulti
                                    className="border-0"
                                    options={optionObjects}
                                    value={optionObjects.filter(f => value?.includes(f.value)) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(undefined)
                                        onChange(e.map((e: any) => e.value).join(','))
                                    }}
                                />}
                            />
                            <InputTag disabled={isSourceView} title={intl.formatMessage({ id: "text.fields" }) + ":"} value={optionObjects
                                .filter(f => watch('allowobjects')?.includes(f.value))
                                .map((item: any) => item.items)
                                .reduce((tol, pre) => [...tol, ...pre], [])} className="border-0 p-0" itemOnClick={handleAddItem} />
                        </KTFormItem>
                        <KTFormItem
                            title={<>
                                <FormattedMessage id="text.content" /> | <a href="#" onClick={handlePreview}>
                                    <FormattedMessage id="text.preview" />
                                </a>
                            </>}
                            isHeight
                            isBLockLabel>
                            <Controller
                                control={control}
                                name="body"
                                render={({ field: { value, onChange } }) => <>
                                    <div id="html-input">
                                        <InputHtml
                                            isHtml
                                            setEditorModel={setEditorModel}
                                            className={clsx("ck-editor-sm")}
                                            value={value}
                                            onChange={(e) => {
                                                if (!e) return onChange(null)
                                                onChange(e)
                                            }} />
                                    </div>
                                    <iframe id="html-preview" title="html-preview" className="d-none w-100 h-500px" srcDoc={value || ''} />
                                </>}
                            />
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
    </>
}