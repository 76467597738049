import { useQuery } from "react-query";
import { ApiService } from "../../../theme/helpers/ApiService";
import { sortByNumber, sortWithDate } from "../../../theme/helpers";

//order
export const useOrder = (query?:string) => useQuery(['orders'+query], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`/orders/requestorders/${query}`).then(res => res.data)
})
export const useOrderForme = (currentApi: any,query?:string, isAdmin?: any,isReturn?: any) => useQuery(['ordersforme'+query+currentApi], async() => {
    if(isReturn) return {}
    let url = '/orders/requestorders/'
    if(!query) {
        query = ''
    }
    if(currentApi) {
        url = url+currentApi
    }
    if(isAdmin) {
        query = query+'&expand=users&isincludeapprovalhistories=true'
    }
    return ApiService.get(`${url}${query}`).then(res => res.data)
}, { cacheTime: 0 })
export const useOrderId = (id?: string) => useQuery(['orders', id], () => {
    return ApiService.get(`/orders/requestorders/${id}`).then(res => res.data)
}, { enabled: !!id })
export const useOrderIdByQuery = (id?: string, query?: string) => useQuery(['orders', id], () => {
    return ApiService.get(`/orders/requestorders/${id}/${query}`).then(res => res.data)
}, { enabled: !!id })
//advance
export const useAdvance = (query?:string) => useQuery(['employees'+query], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`/employees/employeeadvancerequests/${query}`).then(res => res.data)
})
export const useAdvanceForme = (currentApi: any, query?:string,isAdmin?: any,isReturn?: any) => useQuery(['advancesforme'+query+currentApi], async() => {
    if(isReturn) return {}
    let url = '/employees/employeeadvancerequests/'
    if(!query) {
        query = ''
    }
    if(currentApi) {
        url = url+currentApi
    }
    if(isAdmin) {
        query = query+'&expand=users&isincludeapprovalhistories=true'
    }
    return ApiService.get(`${url}${query}`).then(res => res.data)
}, { cacheTime: 0 })
export const useAdvanceId = (id?: string) => useQuery(['employees', id], () => {
    return ApiService.get(`/employees/employeeadvancerequests/${id}`).then(res => res.data)
}, { enabled: !!id })
export const useAdvanceIdByQuery = (id?: string, query?: string) => useQuery(['employees', id], () => {
    return ApiService.get(`/employees/employeeadvancerequests/${id}/${query}`).then(res => res.data)
}, { enabled: !!id })
//payment
export const usePayment = (query?:string) => useQuery(['payments'], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`/payments/paymentrequests/${query}`).then(res => res.data)
})

export const usePaymentId = (id?: string) => useQuery(['payments', id], () => {
    return ApiService.get(`/payments/paymentrequests/${id}`).then(res => res.data)
}, { enabled: !!id })
export const usePaymentIdByQuery = (id?: string, query?: string) => useQuery(['payments', id], () => {
    return ApiService.get(`/payments/paymentrequests/${id}/${query}`).then(res => res.data)
}, { enabled: !!id })
export const usePaymentForme = (currentApi: any, query?:string,isAdmin?:any, isReturn?: any) => useQuery(['paymentsforme_'+query+currentApi], async() => {
    if(isReturn) return {}
    let url = '/payments/paymentrequests/'
    if(!query) {
        query = ''
    }
    if(currentApi) {
        url = url+currentApi
    }
    if(isAdmin) {
        query = query+'&expand=users&isincludeapprovalhistories=true'
    }
    return ApiService.get(`${url}${query}`).then(res => res.data)
}, { cacheTime: 0,enabled: !!query })
//approvalhistories
export const useApprovalHistories = (query?:string) => useQuery(['approvalhistories'], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`/settings/approvalhistories/${query}`).then(res => res.data)
},{ enabled: !!query, cacheTime: 0 })

export const useApprovalHistoriesId = (id?: string) => useQuery(['approvalhistories', id], () => {
    return ApiService.get(`/settings/approvalhistories/${id}`).then(res => res.data)
}, { enabled: !!id })

export const useTopdeptswithmostproposaltotal = (query?: string) => useQuery(['topdeptswithmostproposaltotal', query], () => {
    return ApiService.get(`/selfservices/stats/topdeptswithmostproposaltotal?${query}`).then(res => sortByNumber(res.data,'total','DESC'))
}, { enabled: !!query })
export const useTopdeptswithmostadvancetotal = (query?: string) => useQuery(['topdeptswithmostadvancetotal', query], () => {
    return ApiService.get(`/selfservices/stats/topdeptswithmostadvancetotal?${query}`).then(res => sortByNumber(res.data,'total','DESC'))
}, { enabled: !!query })
export const useTopdeptswithmostpaymenttotal = (query?: string) => useQuery(['topdeptswithmostpaymenttotal', query], () => {
    return ApiService.get(`/selfservices/stats/topdeptswithmostpaymenttotal?${query}`).then(res => sortByNumber(res.data,'total','DESC'))
}, { enabled: !!query })
export const useStatpendingreimbursements = (query?: string) => useQuery(['statpendingreimbursements', query], () => {
    return ApiService.get(`/selfservices/stats/statpendingreimbursements?${query}`).then(res => sortWithDate(res.data,{sort:'createdat', order:'asc'}))
}, { enabled: !!query })
export const useTopuserswithmostproposaltotal = (query?: string) => useQuery(['topuserswithmostproposaltotal', query], () => {
    return ApiService.get(`/selfservices/stats/topuserswithmostproposaltotal?${query}`).then(res => sortByNumber(res.data,'total','DESC'))
}, { enabled: !!query })
export const useTopuserswithmostadvancetotal = (query?: string) => useQuery(['topuserswithmostadvancetotal', query], () => {
    return ApiService.get(`/selfservices/stats/topuserswithmostadvancetotal?${query}`).then(res => sortByNumber(res.data,'total','DESC'))
}, { enabled: !!query })
export const useTopuserswithmostpaymenttotal = (query?: string) => useQuery(['topuserswithmostpaymenttotal', query], () => {
    return ApiService.get(`/selfservices/stats/topuserswithmostpaymenttotal?${query}`).then(res => sortByNumber(res.data,'total','DESC'))
}, { enabled: !!query })
export const useStatoverallsummary = (query?: string) => useQuery(['statoverallsummary', query], () => {
    return ApiService.get(`/selfservices/stats/statoverallsummary?${query}`).then(res => res.data)
}, { enabled: !!query })
export const useStatpaymentsummary = (query?: string) => useQuery(['statpaymentsummary', query], () => {
    return ApiService.get(`/selfservices/stats/statpaymentsummary?${query}`).then(res => res.data)
}, { enabled: !!query })
export const useSelectcreators = (query?: any) => useQuery(['useSelectcreators'+query], () => {
    let params = ''
    if(query) {
        params = query
    }
    return ApiService.get(`/payments/paymentrequests/select/selectcreators${params}`).then(res => res.data)
})
export const useSelectdepartments = (query?: any) => useQuery(['useSelectdepartments'+query], () => {
    let params = ''
    if(query) {
        params = query
    }
    return ApiService.get(`/payments/paymentrequests/select/selectdepartments${params}`).then(res => res.data)
})
//-----order----
export const useSelectcreatorsOrder = (query?: any) => useQuery(['useSelectcreatorsOrder'+query], () => {
    let params = ''
    if(query) {
        params = query
    }
    return ApiService.get(`/orders/requestorders/select/selectcreators${params}`).then(res => res.data)
})
export const useSelectdepartmentsOrder = (query?: any) => useQuery(['useSelectdepartmentsOrder'+query], () => {
    let params = ''
    if(query) {
        params = query
    }
    return ApiService.get(`/orders/requestorders/select/selectdepartments${params}`).then(res => res.data)
})
//-----advance----
export const useSelectcreatorsAdvance = (query?: any) => useQuery(['useSelectcreatorsOrder'+query], () => {
    let params = ''
    if(query) {
        params = query
    }
    return ApiService.get(`/employees/employeeadvancerequests/select/selectcreators${params}`).then(res => res.data)
})
export const useSelectdepartmentsAdvance = (query?: any) => useQuery(['useSelectdepartmentsOrder'+query], () => {
    let params = ''
    if(query) {
        params = query
    }
    return ApiService.get(`/employees/employeeadvancerequests/select/selectdepartments${params}`).then(res => res.data)
})