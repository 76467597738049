import clsx from "clsx";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import { FC, lazy, useEffect } from 'react';
import { useAuth } from "../../../../app/modules/auth";

const Flatpickr = lazy(() => import('react-flatpickr'))

interface PropsInputDate {
  options?: flatpickr.Options.Options | undefined;
  value: any // "d/m/Y"
  onChange: (e: Date[]) => void
  className?: string
  placeholder?: string
  disabled?: boolean
  ico?: boolean
}

export const InputDate: FC<PropsInputDate> = (props) => {
  const { currentUser } = useAuth()
  const { options, onChange, value, ico, ...rest } = props

  useEffect(() => {
  }, [value])

  function handleChange(data: Date[]) {
    onChange(data)
  }

  return <div className='position-relative w-100'>
    <Flatpickr
      {...rest}
      value={value}
      onChange={handleChange}
      options={{
        ...options,
        dateFormat: props.options?.enableTime == true ? "d/m/Y H:i" : "d/m/Y",
        time_24hr: true,
      }}
      className={clsx("form-control form-control-sm", rest.className)}
    />
    <div className={clsx('position-absolute top-0 end-0 h-100 d-flex flex-center pe-3 pt-1 events-none', {
      'd-none': !ico
    })}>
      <i className="bi bi-calendar3 cursor-pointer fs-4" />
    </div>
  </div>
}
