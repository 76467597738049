import { useQuery } from "react-query";
import { ApiService } from "../../../theme/helpers/ApiService";
export * from './type'

type IApiType =
    | 'departments'
    | 'items'
    | 'expenses'
    | 'vendors'
    | 'vendorbankaccounts'
    | 'customers'
    | 'customerbankaccounts'
    | 'expensevalues'
    | 'stores'
    | 'unitofmeasures'
    | 'dimensions'
    | 'dimensionvalues'

const fetchID = (name: IApiType, id?: string) =>
    ApiService.get(`/settings/${name}${id ? `/${id}` : ''}`).then(res => res.data)

const fetchQuery = (name: IApiType, query?: string) =>
    ApiService.get(`/settings/${name}${query || ''}`).then(res => res.data)

export const apiPrivate = {
    //Api Departments
    useDepartments() {
        return useQuery(['ad-departments'], () => fetchID('departments'))
    },
    useDepartmentsByQuery(query?: string) {
        return useQuery(['ad-departments', query], () => fetchQuery('departments', query), { enabled: !!query })
    },
    useDepartmentsId<I>(id?: string) {
        return useQuery<I>(['ad-departments', id], () => fetchID('departments', id), { enabled: !!id })
    },
    //Api Items
    useItems() {
        return useQuery(['ad-items'], () => fetchID('items'))
    },
    useItemsId<I>(id?: string) {
        return useQuery<I>(['ad-items', id], () => fetchID('items', id), { enabled: !!id })
    },
    //Api Expenses
    useExpenses() {
        return useQuery(['ad-expenses'], () => fetchID('expenses'))
    },
    useExpensesByQuery(query?: string) {
        return useQuery(['ad-expenses', query], () => fetchQuery('expenses', query), { enabled: !!query })
    },
    useExpensesId<I>(id?: string) {
        return useQuery<I>(['ad-expenses', id], () => fetchID('expenses', id), { enabled: !!id })
    },
    useExpenseValuesByQuery(query?: string) {
        return useQuery(['ad-expensevalues', query], () => fetchQuery('expensevalues', query), { enabled: !!query })
    },
    //Api Vendors
    useVendors() {
        return useQuery(['ad-vendors'], () => fetchID('vendors'))
    },
    useVendorsByQuery(query?: string) {
        return useQuery(['ad-vendors', query], () => fetchQuery('vendors', query), { enabled: !!query })
    },
    useVendorId<I>(id?: string) {
        return useQuery<I>(['ad-vendors', id], () => fetchID('vendors', id), { enabled: !!id })
    },
    useVendorBankAccounts() {
        return useQuery(['ad-vendorbankaccounts'], () => fetchID('vendorbankaccounts'))
    },
    useVendorBankAccountsByQuery(query?: string) {
        return useQuery(['ad-vendorbankaccounts', query], () => fetchQuery('vendorbankaccounts', query), { enabled: !!query })
    },
    useVendorBankAccountsId<I>(id?: string) {
        return useQuery<I>(['ad-vendorbankaccounts', id], () => fetchID('vendorbankaccounts', id), { enabled: !!id })
    },
    //Api customers
    useCustomers() {
        return useQuery(['ad-customers'], () => fetchID('customers'))
    },
    useCustomersByQuery(query?: string) {
        return useQuery(['ad-customers', query], () => fetchQuery('customers', query), { enabled: !!query })
    },
    useCustomerId<I>(id?: string) {
        return useQuery<I>(['ad-customers', id], () => fetchID('customers', id), { enabled: !!id })
    },
    useCustomerBankAccountsByQuery(query?: string) {
        return useQuery(['ad-customerbankaccounts', query], () => fetchQuery('customerbankaccounts', query), { enabled: !!query })
    },
    useStores() {
        return useQuery(['ad-stores'], () => fetchID('stores'))
    },
    useStoresId<I>(id?: string) {
        return useQuery<I>(['ad-stores', id], () => fetchID('stores', id), { enabled: !!id })
    },
    //Api UOMs
    useUoms() {
        return useQuery(['ad-uoms'], () => fetchID('unitofmeasures'))
    },
    useDimensions() {
        return useQuery(['ad-dimensions'], () => fetchID('dimensions'))
    },
    useDimensionsByQuey(query?: string) {
        return useQuery(['ad-dimensions', query], () => fetchQuery('dimensions', query), { enabled: !!query })
    },
    useDimensionsValuesByQuery(query?: string) {
        return useQuery(['ad-dimensionvalues'], () => fetchID('dimensionvalues', query), { enabled: !!query })
    }
}