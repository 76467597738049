import CryptoJS from "crypto-js";
import { loadMessageI18n, popupLoading, popupMessage, popupQuestion } from "../../app/modules/messages";
import { ApiService } from "./ApiService";
import { removeAccents } from "./AssetHelpers";

const key = process.env.REACT_APP_PRIVATE_KEY

export const clientTime = {
    nowDate: new Date(),
    nowDateString: new Date().toLocaleDateString(),
    nowTimeNumber: new Date().getTime()
}

export const encryptData: (data: string, code?: string) => string | null = (data, code) => {
    if (!key) return null;
    // console.log('data',data)
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

export const decryptData: (ciphertext: string, code?: string) => string | null = (ciphertext, code) => {
    if (!key) return null;
    const bytes = CryptoJS.AES.decrypt(ciphertext, key).toString(CryptoJS.enc.Utf8)
    // console.log('bytes',bytes)
    return bytes ? JSON.parse(bytes) : null;
}

export async function ApiShowError(error: any) {
    // 403-284210010 token invalid
    const { errorCode, errorMessage, name, statusCode } = error.response?.data
    if (errorCode == '403-284210010') {
        await popupMessage({ icon: 'info', description: loadMessageI18n({ key: "message.error.token-expired" }), confirmButtonText: loadMessageI18n({ key: "text.return-login" }) })
        window.location.href = '/login'
    }
    popupMessage({ icon: 'error', description: error.response?.data?.errorMessage })
}

export async function ApiUploadFiles(uploadcode: string, category: string, files: File[], isMessage?: boolean) {
    if (!files || files.length == 0) {
        popupMessage({ icon: "error", description: "Invalid files." })
        return undefined
    }
    const formData = new FormData();
    formData.append('uploadcode', uploadcode)
    formData.append('category', category.toUpperCase())
    files.forEach((file: any) => {
        let tempPre = file.name.split('.')
        let tempName = [removeAccents(tempPre[0]),tempPre[1]].filter((f: any) => f).join('.')
        formData.append('file', new File([file],tempName))
    })
    try {
        const results = await ApiService.post(`utilities/uploadfiles`, formData)
        if (results) {
            isMessage && popupMessage({ icon: 'success', autoClose: true })
            return results.data.uploadedfiles
        }
        return undefined
    } catch (error: any) {
        ApiShowError(error)
    }
}

export async function ApiDeleteFile(pathtofile: string) {
    try {
        await ApiService.post(`utilities/deletefile`, { pathtofile: pathtofile })
    } catch (error) {
        ApiShowError(error)
    }
}

export function getLocalStorage<T>(key: string) {
    const ls = localStorage.getItem(key)
    if (ls) {
        try {
            return JSON.parse(ls) as T
        } catch (er) {
            console.error(er)
        }
    }
    return undefined
}

export function setLocalStorage(key: string, values: any) {
    localStorage.setItem(key, JSON.stringify(values))
}

export function changeDataModify(data: any) {
    delete data.createdat
    delete data.createdby
    delete data.modifiedat
    delete data.modifiedby
    delete data.modifier
    delete data.creater
    return data
}

export async function syncObjects(url: string, callback: () => void) {
    const question = await popupQuestion({ description: 'Confirm synchronization of this table data with bc.' })
    if (question) {
        popupLoading()
        try {
            await ApiService.get(url)
            popupMessage({ icon: "success", autoClose: true })
        } catch (error) {
            ApiShowError(error)
        }
        callback()
    }
}