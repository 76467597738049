import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { FormattedMessage, useIntl } from 'react-intl'

export function ForgotPassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(intl.formatMessage({ id: "login.wrong-email-format" as any }))
        .min(3, intl.formatMessage({ id: "login.min-character" as any }))
        .max(50, intl.formatMessage({ id: "login.max-character" as any }))
        .required(intl.formatMessage({ id: "login.field-required" as any })),
    }),
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({ data: { result } }) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            // setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework p-8 shadow-sm'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-5'>
        <div className='m-auto mb-2'>
          <img src="/media/images/logo_sp_trungnguyenlegend.png" alt="" />
        </div>
        <div className='text-gray-700 fw-semibold fs-6 mb-6'><FormattedMessage id="login.more" /></div>
        <h3 className='text-dark fw-bolder text-gray-700'><FormattedMessage id="login.forgot-password" /></h3>
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'><FormattedMessage id="login.username" /></label>
        <input
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            { 'is-valid': formik.touched.email && !formik.errors.email, }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center d-grid'>
        <button type='submit' id='kt_password_reset_submit' className='mb-5 btn bg-light-orange rounded-5 border-orange border border-2 border-solid text-white fw-bolder'>
          <span className='indicator-label text-uppercase'><FormattedMessage id="login.send-request" /></span>
          {loading && (
            <span className='indicator-progress'>
              <FormattedMessage id="text.loadings" />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/login'>
          <span className='text-orange fw-bolder'><FormattedMessage id="login.back-to-login" /></span>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
