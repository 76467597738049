import clsx from "clsx"
import React, { FC, useEffect, useRef, useState } from "react"
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap"
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { formatLable, formatOptions, isFloat, isInt } from "../../../../../theme/helpers"
import { InputDate, InputHtml, InputPrice, InputSelect, KTFormItem } from "../../../../../theme/partials"
import { apiPrivate, IInitField } from "../../../api"
import { apiAd } from "../../../api/admin"

export interface IAdApproval extends IInitField {
    objectid: string | null
    objectcode: string | null
    objectname: string | null
    type: string | null
    approvalname: string | null
    description: string | null
    priority: number | null
    blocked: boolean
    approvalreminderbefore: number | null
    rule: {
        columns: Array<{
            comparator: string | null
            field: string | null
            index: number
            value: string | null
            name?: string
            datatype?: string
        }>,
        criteria_string: string
    } | null
    approvaltype: string | null
    approvallevels: Array<IApprovallevel> | null
    emailnotification: {
        cc: string | null,
        bcc: string | null
        message: {
            index: number | null
            type: string | null
            name: string | null
            emailtemplateid: string | null
            emailtemplatecode: string | null
            subject: string | null
            body: string | null
            mailwith?: string | null
        }[] | null
    } | null
    slaconfiguration: {
        execution: Array<{
            sla_duration: string | null
            sla_details: {
                sla_type: string | null
                sla_value: string | null
            }
            notification_mails: {
                notification_time: string | null
                notification_time_type: string | null
            } | null
        }>
    }
}

interface IApprovallevel {
    condition?: string | null
    sla?: string | null
    departmentid: string | null
    departmentname: string | null
    username: string | null
    approvalpolicyid: string | null
    type: string | null
    typeformatted: string | null
    subtype: string | null
    userid: string | null
    subtypeformatted: string | null
    order: number | string
}

export const optionType = [
    { value: "20120001", label: <FormattedMessage id="text.hierarchical-approval" /> },
    { value: "20120002", label: <FormattedMessage id="text.custom-approval" /> },
]

export const optionApprovalTypeCustom = [
    { value: 'manual', label: <FormattedMessage id="text.configure-approval-flow" /> },
    { value: 'auto_approval', label: <FormattedMessage id="text.auto-approval" /> },
    { value: 'auto_reject', label: <FormattedMessage id="text.auto-reject" /> }
]

export const optionRuleComparator = [
    { type: 'STRING', value: 'starts_with', label: <FormattedMessage id="text.starts-with" /> },
    { type: 'STRING', value: 'ends_with', label: <FormattedMessage id="text.ends-with" /> },
    { type: 'STRING', value: 'contains', label: <FormattedMessage id="text.contains" /> },
    { type: 'STRING', value: 'not_contains', label: <FormattedMessage id="text.doesn't-contain" /> },
    { type: 'STRING', value: 'is_empty', label: <FormattedMessage id="text.is-empty" /> },
    { type: 'STRING', value: 'is_not_empty', label: <FormattedMessage id="text.is-not-empty" /> },
    { type: 'STRING,NUMBER,DATE,DECIMAL', value: 'equal', label: '=' },
    { type: 'STRING,NUMBER,DATE,DECIMAL', value: 'not_equal', label: '!=' },
    { type: 'NUMBER,DATE,DECIMAL', value: 'different', label: '<>' },
    { type: 'NUMBER,DATE,DECIMAL', value: 'greater_equal', label: '>' },
    { type: 'NUMBER,DATE,DECIMAL', value: 'greater_than_or_equal_to', label: '>=' },
    { type: 'NUMBER,DATE,DECIMAL', value: 'less_than', label: '<' },
    { type: 'NUMBER,DATE,DECIMAL', value: 'less_than_or_equal_to', label: '<=' },
    { type: 'BOOLEAN,UUID', value: 'is', label: <FormattedMessage id="text.is" /> },
]

export const optionRenderInputBoolean = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
]

export const optionRuleType = [
    { value: 'WHEN', label: 'when' },
    { value: 'OR', label: 'or' },
    { value: 'AND', label: 'and' }
]

export const optionUnitTime = [
    { value: 'days', label: 'Days' },
    { value: 'hours', label: 'Hours' }
]

export const optionAssignTo: any[] = [

]

export const defaultApproval: IAdApproval = {
    approvalreminderbefore: null,
    blocked: false,
    priority: null,
    objectid: null,
    objectcode: null,
    objectname: null,
    type: '20120001',
    approvalname: null,
    description: null,
    rule: null,
    approvaltype: null,
    approvallevels: null,
    emailnotification: null,
    slaconfiguration: { execution: [] }
}

export function EmailTemplateCard(props: any) {
    const { index, register, control, setValue, optionEmailTemplates } = props
    const optionType = ['new_request', 'notify_approver', 'next_level', 'approved', 'denied',]

    return <>
        <KTFormItem title='text.email-template' isBLockLabel>
            <Controller
                name={`emailnotification.message.${index}.emailtemplateid`}
                control={control}
                render={({ field: { value, onChange } }) => <InputSelect
                    isClearable
                    options={optionEmailTemplates}
                    value={optionEmailTemplates.find((f: { value: any }) => f.value == value) || null}
                    onChange={(e) => {
                        setValue(`emailnotification.message.${index}.type`, null)
                        setValue(`emailnotification.message.${index}.emailtemplatecode`, null)
                        setValue(`emailnotification.message.${index}.subject`, null)
                        setValue(`emailnotification.message.${index}.body`, null)
                        setValue(`emailnotification.message.${index}.mailwith`, null)
                        if (!e) return onChange(null)
                        onChange(e.value)
                        setValue(`emailnotification.message.${index}.type`, optionType[index])
                        setValue(`emailnotification.message.${index}.emailtemplatecode`, e.label)
                        setValue(`emailnotification.message.${index}.subject`, e.title)
                        setValue(`emailnotification.message.${index}.body`, e.body)
                        setValue(`emailnotification.message.${index}.mailwith`, e.mailwith)
                    }} />}
            />
        </KTFormItem>
        <KTFormItem title='text.subject' isBLockLabel>
            <input className={`form-control form-control-sm`} {...register(`emailnotification.message.${index}.subject`)} />
        </KTFormItem>
        <KTFormItem title='text.body' isHeight isBLockLabel>
            <Controller
                control={control}
                name={`emailnotification.message.${index}.body`}
                render={({ field: { value, onChange } }) => <InputHtml
                    className="ck-editor-sm"
                    value={value}
                    onChange={(e) => {
                        if (!e) return onChange(null)
                        onChange(e)
                    }} />}
            />
        </KTFormItem>
    </>
}

export function RenderRuleInput(props: any) {
    const { index, register, control, datatype, handleClick, error, options } = props
    const inputKey = `rule.columns.${index}.value`
    switch (datatype) {
        case 'NUMBER':
            return <input className={`form-control form-control-sm`} {...register(inputKey)} type="number" />
        case 'BOOLEAN':
            return <Controller
                name={inputKey}
                control={control}
                render={({ field: { value, onChange } }) => <InputSelect
                    className={clsx({ 'form-error': error })}
                    options={optionRenderInputBoolean}
                    value={optionRenderInputBoolean.find((f: any) => f.value == value) || null}
                    onChange={(e) => {
                        if (!e) return onChange(null)
                        onChange(e.value)
                    }} />}
            />
        case 'STRING':
            return <input className={clsx(`form-control form-control-sm`, { 'form-error': error })} {...register(inputKey)} />
        case 'DATE':
            return <Controller
                name={inputKey}
                control={control}
                render={({ field: { value, onChange } }) => <InputDate
                    className={clsx({ 'form-error': error })}
                    value={value}
                    onChange={(e) => {
                        if (!e.length) return onChange(null)
                        onChange(e[0].toISOString())
                    }} />}
            />
        case 'DECIMAL':
            return <Controller
                name={inputKey}
                control={control}
                render={({ field: { value, onChange } }) => <InputPrice
                    className={clsx({ 'form-error': error })}
                    value={value}
                    onChange={onChange} />}
            />
        case 'UUID':
            return options.length
                ? <Controller
                    name={inputKey}
                    control={control}
                    render={({ field: { value, onChange } }) => <InputSelect
                        className={clsx({ 'form-error': error })}
                        options={options}
                        value={options.find((f: any) => f.value == value) || null}
                        onChange={(e) => {
                            if (!e) return onChange(null)
                            onChange(e.value)
                        }} />}
                />
                : <input
                    readOnly
                    className={clsx(`form-control form-control-sm`, { 'form-error': error })}
                    {...register(`rule.columns.${index}.name`)}
                    onClick={handleClick} />
        default:
            return <></>
    }
}

interface IApprovalIconDropdow {
    index: number
    approvallevels: IApprovallevel[]
    watch: UseFormWatch<IAdApproval>
    setValue: UseFormSetValue<IAdApproval>
}

const ApprovalIconDropdown: FC<IApprovalIconDropdow> = ({ index, approvallevels, watch, setValue }) => {
    const btnRef = useRef<any>(null)
    const sla_duration = watch(`slaconfiguration.execution.0.sla_duration`)
    const { sla, order, type } = approvallevels[index]
    const isSubmitTo = (type == 'submits_to')
    const [message, setMessage] = useState<string>("")

    useEffect(() => {
        if (sla) setMessage(sla)
        else setMessage(sla_duration || "")
    }, [index, sla_duration])

    function handleSave() {
        btnRef?.current.click()
        setValue(`approvallevels.${index}.sla`, message)
    }

    function handleClick(evt: any) {
        evt.preventDefault()
        btnRef?.current.click()
    }

    const neworder = Number(String(order).split('.')[0])
    const levels = approvallevels.filter(f => isFloat(f.order) && (neworder < Number(f.order) && Number(f.order) < (neworder + 1)))
    const isFullItems = levels.length > 3

    function handleAddApprovalVS2() {
        if (isFullItems) return;
        const tempindex = index + levels.length
        if (isInt(order)) {
            approvallevels.splice(tempindex + 1, 0, {
                order: Number(`${order}.${levels.length + 1}`),
                condition: "AND",
                type: null
            } as IApprovallevel)
            setValue('approvallevels', approvallevels)
        } else if (isFloat(order)) {
            approvallevels.splice(index + 1, 0, {
                order: Number(`${neworder}.${levels.length + 1}`),
                condition: "AND",
                type: null
            } as IApprovallevel)

            const newarray: any[] = []
            approvallevels.forEach((m, ind) => {
                if (ind <= index) return newarray.push(m);
                if (Number(m.order) >= (neworder + 1)) return newarray.push(m);

                const no = Number(newarray.at(-1).order)
                return newarray.push({ ...m, order: Number((no + 0.1).toFixed(1)) })
            })
            setValue('approvallevels', newarray)
        }
    }

    return <div className="d-flex align-items-center">
        {/* {order} */}
        {sla && <div className="d-flex gap-2 align-items-center fs-7 me-2">
            {`SLA: ${sla} ${Number(sla) > 1 ? 'days' : 'day'}`} <i className="bi bi-pen fs-7" onClick={handleClick}></i>
        </div>}
        <OverlayTrigger trigger="click" overlay={<Popover id="popover-basic" className="w-250px">
            <Popover.Body>
                <input className="form-control form-control-sm" value={message} onChange={e => {
                    setMessage(e.target.value)
                    return e
                }} />
                <div className="d-flex gap-2 mt-4">
                    <button className="btn btn-sm btn-primary" onClick={handleSave}>Save</button>
                    <button className="btn btn-sm btn-secondary" onClick={handleClick}>Cancel</button>
                </div>
            </Popover.Body>
        </Popover>}>
            <button ref={btnRef} type="button" className="invisible w-1px p-0" />
        </OverlayTrigger>
        <Dropdown drop="down-centered">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
            <Dropdown.Menu>
                <Dropdown.Item as="button" type="button" onClick={handleClick} disabled={sla != undefined || isFloat(order)}>Add SLA</Dropdown.Item>
                <Dropdown.Item as="button" type="button" onClick={handleAddApprovalVS2} disabled={isSubmitTo || isFullItems}>
                    Add a parallel branch
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </div>
}

const CustomToggle = React.forwardRef<any>(({ children, onClick }: any, ref) => (
    <a href="" ref={ref as any} onClick={(e) => { e.preventDefault(); onClick(e); }} className="btn btn-sm btn-link btn-icon">
        <i className="bi bi-plus-circle fs-3 text-primary"></i>
        {children}
    </a>
));

interface IApprovalTypeManual {
    watch: UseFormWatch<IAdApproval>
    control: Control<IAdApproval, any>
    errors: FieldErrors<IAdApproval>
    setValue: UseFormSetValue<IAdApproval>
    optionUsernames: any[]
    handleAddApprovalManual: () => void
}

export const ApprovalTypeManual: FC<IApprovalTypeManual> = ({ watch, control, errors, setValue, optionUsernames, handleAddApprovalManual }) => {
    const { approvallevels } = watch()

    const { data: departmentsData, isSuccess: isDepartments } = apiPrivate.useDepartments()
    const optionDepartments = isDepartments ? formatOptions(departmentsData, ['id', 'departmentname']) : []

    const { data: approvalPoliciesData, isSuccess: isApprovalPolicies } = apiAd.useApprovalPolicies()
    const optionApprovalPolicy = isApprovalPolicies ? formatOptions(approvalPoliciesData, ['id', 'typename']) : []

    function optionApprovalPolicy2(id?: string | null) {
        const approvalPolicy = optionApprovalPolicy.find(f => f.id == id)
        if (!approvalPolicy || !approvalPolicy.subtype) return []
        if (approvalPolicy.subtype) return approvalPolicy.subtype.split(',').map((item: string) => ({ value: item, label: formatLable(item), }))
    }

    function handleRemove(item: IApprovallevel, index: number) {
        if (!Array.isArray(approvallevels)) return;
        const count = approvallevels.filter(f => isFloat(f.order)
            && (Number(item.order) < Number(f.order) && Number(f.order) < (Number(item.order) + 1)))
        if (count.length && !isFloat(item.order)) return;

        let array: any[] = []
        approvallevels.forEach((item, ind) => {
            if (ind < index) return array.push(item);
            if (ind == index) return;

            let preneworder = array.filter(f => isInt(f.order)).at(-1)?.order

            if (isInt(item.order)) return array.push({ ...item, order: preneworder + 1 });
            if (isFloat(item.order)) return array.push({ ...item, order: array.at(-1).order + 0.1 });

        })
        setValue('approvallevels', array)
    }

    function getOptionsInRow(data: any[], options: any[], key: string, order: string | number) {
        const neworder = Number(String(order).split('.')[0])
        const levels = data.filter(f => neworder <= Number(f.order) && Number(f.order) < (neworder + 1) && f[key] != undefined)
        const arrayValue = levels.map(m => m[key])
        return options.filter(f => !arrayValue.includes(f.value))
    }

    return <div className="ps-6">
        {Array.isArray(approvallevels) && approvallevels.map((item, index) => <div key={index} className="row d-row-hover">
            <div className="col-12 col-lg-6 mb-3">
                <div className="d-flex align-items-center h-100 gap-6">
                    <div className={clsx("d-flex flex-column flex-center min-w-25px mw-25px")}>
                        <span className={clsx("badge badge-circle bg-primary", isFloat(item.order) && 'd-none')}>{item.order}</span>
                        <div className="text-gray-300">|</div>
                    </div>
                    {isFloat(item.order) && <Controller
                        name={`approvallevels.${index}.condition`}
                        control={control}
                        render={({ field: { onChange } }) => <InputSelect
                            className={clsx('min-w-80px mw-80px', { 'form-error': errors.approvallevels?.[index]?.condition })}
                            options={optionRuleType.filter(f => f.value != 'WHEN')}
                            value={optionRuleType.filter(f => f.value != 'WHEN').find((f: any) => f.value == item.condition) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                        />}
                    />}
                    <Controller
                        name={`approvallevels.${index}.approvalpolicyid`}
                        control={control}
                        render={({ field: { onChange } }) => {
                            const order = Number(Number(item.order).toFixed(0))
                            const items = approvallevels
                                .filter(f => (order <= Number(f.order) && Number(f.order) < (order + 1))
                                    && f.type != item.type
                                    && f.type != 'user'
                                    && f.type != 'head_of_selected_department')
                                .map(m => m.type)

                            const options = optionApprovalPolicy
                                .filter(f => (isFloat(item.order) || items.length > 0) ? (f.type != 'submits_to') : true)
                                .filter(f => !items.includes(f.type))

                            return <InputSelect
                                className={clsx('w-100', { 'form-error': errors.approvallevels?.[index]?.approvalpolicyid })}
                                options={options}
                                value={options.find(f => f.value == item.approvalpolicyid) || null}
                                onChange={(e) => {
                                    setValue(`approvallevels.${index}`, {
                                        ...item,
                                        condition: isFloat(item.order) ? item.condition : undefined,
                                        departmentid: null,
                                        departmentname: null,
                                        userid: null,
                                        username: null,
                                        approvalpolicyid: null,
                                        type: null,
                                        typeformatted: null,
                                        subtype: null,
                                        subtypeformatted: null,
                                        sla: undefined
                                    })
                                    if (!e) {
                                        const temps = approvallevels.filter((f: any, i: number) => i != index)
                                        if (index != 0 && temps.length) {
                                            setValue(`approvallevels`, temps)
                                        }
                                        return onChange(null)
                                    }
                                    onChange(e.value)
                                    setValue(`approvallevels.${index}.type`, e.type)
                                    setValue(`approvallevels.${index}.typeformatted`, e.type_formatted)
                                }}
                            />
                        }}
                    />
                </div>
            </div>
            <div className="col-12 col-lg-6 mb-3">
                <div className="d-flex align-items-center h-100 gap-6">
                    {item.type == 'submits_to' && <Controller
                        name={`approvallevels.${index}.subtype`}
                        control={control}
                        render={({ field: { onChange } }) => <InputSelect
                            className={clsx('min-w-250px mw-250px', { 'form-error': errors.approvallevels?.[index]?.subtype })}
                            options={optionApprovalPolicy2(item.approvalpolicyid)}
                            value={optionApprovalPolicy2(item.approvalpolicyid).find((f: any) => f.value == item.subtype) || null}
                            onChange={(e) => {
                                setValue(`approvallevels.${index}.subtypeformatted`, null)
                                if (!e) return onChange(null)
                                onChange(e.value)
                                setValue(`approvallevels.${index}.subtypeformatted`, e.label)
                            }}
                        />}
                    />}
                    {item.type == 'head_of_selected_department' && <Controller
                        name={`approvallevels.${index}.departmentid`}
                        control={control}
                        render={({ field: { onChange } }) => <InputSelect
                            className={clsx('min-w-250px mw-250px', { 'form-error': errors.approvallevels?.[index]?.departmentid })}
                            options={getOptionsInRow(approvallevels, optionDepartments, 'departmentid', item.order)}
                            value={optionDepartments.find((f: any) => f.value == item.departmentid) || null}
                            onChange={(e) => {
                                setValue(`approvallevels.${index}.departmentname`, null)
                                if (!e) return onChange(null)
                                onChange(e.value)
                                setValue(`approvallevels.${index}.departmentname`, e.label)
                            }}
                        />}
                    />}
                    {item.type == 'user' && <Controller
                        name={`approvallevels.${index}.username`}
                        control={control}
                        render={({ field: { onChange } }) => <InputSelect
                            className={clsx('min-w-250px mw-250px', { 'form-error': errors.approvallevels?.[index]?.username })}
                            options={getOptionsInRow(approvallevels, optionUsernames, "username", item.order)}
                            value={optionUsernames.find((f: any) => f.value == item.username) || null}
                            onChange={(e) => {
                                setValue(`approvallevels.${index}.userid`, null)
                                if (!e) return onChange(null)
                                onChange(e.value)
                                setValue(`approvallevels.${index}.userid`, e.id)
                            }}
                        />}
                    />}
                    <ApprovalIconDropdown {...{ setValue, index, watch, approvallevels }} />
                    {index !== 0 && <RemoveRowButton handleClick={() => handleRemove(item, index)} />}
                </div>
            </div>
        </div>)}
        <AddRowButton title={<FormattedMessage id="text.add-one-more" />} handleClick={handleAddApprovalManual} />
    </div>
}

export function AddRowButton({ handleClick, title }: any) {
    return <button type="button" className="btn btn-sm btn-link d-flex gap-2 flex-center text-primary" onClick={handleClick}>
        <span className="badge badge-circle bg-primary"><i className="bi bi-plus text-white fs-3" /></span> {title}
    </button>
}

export function RemoveRowButton(props: any) {
    const { handleClick } = props
    return <div className="w-100px">
        <button type='button' className="btn btn-sm btn-link d-cell-hover" onClick={handleClick}>
            <span className="badge badge-circle bg-danger">
                <i className="bi bi-x text-white fs-3"></i>
            </span>
        </button>
    </div>
}