import { useState } from "react"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { Column } from "react-table"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, KTButton, KTTable, RowIcon } from "../../../../theme/partials"
import { IInitField, apiPrivate } from "../../../modules/api"
import { MergedProps } from "../../../router/AppRoutes"
import { syncObjects } from "../../../../theme/helpers"

export interface IDimension extends IInitField {
    id: string
    code: string
    name: string
    description: any
    blocked: any
}

const apiurl = 'settings/dimensions'
export default function PDimensions({ keyElement, permission }: MergedProps) {
    const intl = useIntl()
    const { data, isSuccess, isFetching, refetch } = apiPrivate.useDimensions()

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='Code' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].code}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell
            data={props.data[props.row.index].name}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Description' />,
        accessor: 'description',
        Cell: ({ ...props }) => <BasicCell
            data={props.data[props.row.index].description}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" to={props.data[props.row.index].id} />
            {/* <RowIcon permission={permission} action="delete" onClick={async () => {
            }} /> */}
        </div>
    }]

    const [filter, setFilter] = useState<IDimension | undefined>()
    const { register, watch, setValue, control, reset, handleSubmit } = useForm<any>()
    const onFilter = (data: IDimension[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks: boolean[] = [
            ]
            return checks.every(val => val == true)
        })
    }

    return <>
        <ContentHeader title={keyElement} elements={<>
            {/* <FilterDropdown
                filterValue={filter}
                handleSubmit={handleSubmit}
                onClose={() => {
                    setFilter(undefined)
                    reset({})
                }}
                onSubmit={(data) => setFilter(data)}>
                <KTFormItem title='text.parent' isBLockLabel>
                    <Controller
                        name="parentid"
                        control={control}
                        render={({ field: { value, onChange } }) => <InputSelect
                            isClearable
                            options={optionDepartments}
                            value={optionDepartments.find(f => f.value == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                        />}
                    />
                </KTFormItem>
            </FilterDropdown> */}
            <KTButton permission={permission} action="sync" onClick={() => syncObjects('/bcsync/dimensions?isDeleteOldData=false&source=BC', refetch)} />
        </>} />
        <KTTable
            isLoading={isFetching}
            data={data || []}
            columns={columns}
            cardInnner
            pagination
            search />
    </>
}
