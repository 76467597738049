import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Column } from "react-table"
import { ApiService, jsUcfirst, sortByUpdateAt } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, FilterDropdown, KTButton, KTFormItem, KTTable, RowIcon } from "../../../../theme/partials"
import { IInitField } from "../../../modules/api"
import { apiFnb } from "../../../modules/api/fnb"
import { popupMessage } from "../../../modules/messages"
import { MergedProps } from "../../../router/AppRoutes"

export interface IFnbTimeBasedMenu extends IInitField {
    code: string | null
    name: string | null
    starteddate?: any
    endeddate?: any
    data: any[]
    daysoftheweek: any
    active: boolean
}

export function FNBTimeBasedMenus({ keyElement, permission }: MergedProps) {
    const urlApi = 'fnb/timebasedmenus'
    const [tableData, setTableData] = useState<IFnbTimeBasedMenu[]>([])
    const { data: dataQuery, isSuccess, isFetching, refetch } = apiFnb.useTimeBasedMenus()

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(dataQuery))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.days' />,
        id: 'days',
        Cell: ({ ...props }) => {
            const weeks = props.data[props.row.index].daysoftheweek
            const days = Object.keys(weeks).filter((v: any, ind: number) => weeks[v].status).map(v => jsUcfirst(v.slice(0, 3)))
            return <BasicCell data={days.join('')} />
        }
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.times' />,
        id: 'time',
        Cell: ({ ...props }) => {
            const weeks = props.data[props.row.index].daysoftheweek
            const days = Object.keys(weeks).filter((v: any, ind: number) => weeks[v].status)
            let times: any[] = days.map(v => ({
                week: v,
                // start: weeks[v].start_time ? new Date(weeks[v].start_time).toLocaleString("en-GB", { hour: '2-digit', minute: "2-digit" }) : undefined,
                // end: weeks[v].end_time ? new Date(weeks[v].end_time).toLocaleString("en-GB", { hour: '2-digit', minute: "2-digit" }) : undefined
                start: weeks[v].start_time.slice(0,5) || '',
                end: weeks[v].end_time.slice(0,5) || ''
            }))
            times = times.reduce((tol, pre) => !tol.includes(`${pre.start} - ${pre.end}`) ? [...tol, `${pre.start} - ${pre.end}`] : tol, [])
            return <div className="d-flex flex-column">
                {times.map((time, ind) => <div key={ind}>{time}</div>)}
            </div>
        }
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.started-date' />,
        accessor: 'starteddate',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].starteddate} type='date' />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.ended-date' />,
        accessor: 'endeddate',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].endeddate} type='date' />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.active' />,
        accessor: 'active',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].active} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" to={props.data[props.row.index].id} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [filter, setFilter] = useState<IFnbTimeBasedMenu | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: IFnbTimeBasedMenu[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                f.active == filter.active,
            ]
            return checks.every(val => val == true)
        })
    }

    const toolbar = <>
        <FilterDropdown
            handleSubmit={handleSubmit}
            onClose={() => {
                setFilter(undefined)
                reset({})
            }}
            onSubmit={(data) => setFilter(data)}>
            <KTFormItem title='text.active' labelWidth="auto">
                <label className="form-check form-switch form-check-custom">
                    <input className="form-check-input" type="checkbox" {...register("active")} />
                </label>
            </KTFormItem>
        </FilterDropdown>
        <KTButton permission={permission} action="new" />
    </>

    return <>
        <ContentHeader title={keyElement} elements={toolbar} />
        <KTTable
            isLoading={isFetching}
            data={onFilter(tableData)}
            columns={columns}
            search
            pagination
            cardInnner
        />
    </>
}
