import clsx from "clsx"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { ContentHeader } from "../../../theme/layout/components/content"
import { DivRoot, KTSVG } from "../../../theme/partials"
import { getIsRole } from "../auth/Permission"
import AdApprovalsPage from "./components/approvals"
import AdApprovalsDetailPage from "./components/approvals/detail"
import AdApprovalUserSetupsPage, { AdApprovalUserSetupsDetailPage } from "./components/approvalUserSetups"
import { AdBackupsPage } from "./components/backups"
import AdCompanysPage, { AdCompanysDetailPage } from "./components/companyInformations"
import { AdEmailSetupsDetailPage } from "./components/emailSetups"
import { GeneralSystem } from "./components/general"
import AdObjectsPage, { AdObjectsDetailPage } from "./components/objects"
import AdPermissionsPage, { AdPermissionsDetailPage } from "./components/permissions"
import { AdUserGroupsPage } from "./components/userGroups"
import { AdUserGroupsDetailPage } from "./components/userGroups/detail"
import AdUsersPage, { AdUsersDetailPage } from "./components/users"

interface ISettingMenusData {
    key: 'companies'
    | 'email-setup'
    | 'users'
    | 'user-setups'
    | 'permission-sets'
    | 'objects'
    | 'approvals'
    | 'zalo-oa'
    | 'sms-setup'
    | 'auto-generate-database'
    | 'user-groups'
    | 'general-settings'
    component: JSX.Element | null
    child: JSX.Element | null
    icon: string
    role?: string
}

export const settingMenusData: ISettingMenusData[] = [/* {
    key: 'general-settings',
    component: <GeneralSystem />,
    child: null,
    icon: 'bi bi-sliders2',
    role: 'ADMIN'
},  */{
    key: 'companies',
    component: <AdCompanysPage />,
    child: <AdCompanysDetailPage />,
    icon: '/media/icons/custom/companies.svg',
    role: 'ADMIN'
}, {
    key: 'email-setup',
    component: <AdEmailSetupsDetailPage />,
    child: null,
    icon: '/media/icons/custom/emal-setup.svg',
    role: 'ADMIN'
}, {
    key: 'users',
    component: <AdUsersPage />,
    child: <AdUsersDetailPage />,
    icon: '/media/icons/custom/user.svg',
    role: 'ADMIN'
}, {
    key: 'user-setups',
    component: <AdApprovalUserSetupsPage />,
    child: <AdApprovalUserSetupsDetailPage />,
    icon: '/media/icons/custom/user-setup.svg',
    role: 'ADMIN'
}, {
    key: 'user-groups',
    component: <AdUserGroupsPage />,
    child: <AdUserGroupsDetailPage />,
    icon: '/media/icons/custom/usergroups.svg',
    role: 'ADMIN'
}, {
    key: 'permission-sets',
    component: <AdPermissionsPage />,
    child: <AdPermissionsDetailPage />,
    icon: '/media/icons/custom/permissionset.svg',
    role: 'ADMIN'
}, {
    key: 'objects',
    component: <AdObjectsPage />,
    child: <AdObjectsDetailPage />,
    icon: '/media/icons/custom/object.svg',
    role: 'SUPER'
}, {
    key: 'approvals',
    component: <AdApprovalsPage />,
    child: <AdApprovalsDetailPage />,
    icon: 'bi bi-clipboard2-check',
    role: 'SUPER'
}, {
    key: 'auto-generate-database',
    component: <AdBackupsPage />,
    child: null,
    icon: '/media/icons/custom/database_backup.svg',
    role: 'SUPER'
}, {
    key: 'zalo-oa',
    component: null,
    child: null,
    icon: '/media/icons/custom/icons8-zalo.svg',
    role: 'SUPER'
}, {
    key: 'sms-setup',
    component: null,
    child: null,
    icon: 'bi bi-chat-dots',
    role: 'SUPER'
},]

const SystemSettings = () => {
    const intl = useIntl()
    const navigate = useNavigate()

    return <>
        <ContentHeader title={"system"} />
        <div className='row'>
            {settingMenusData.map((item, index) => {
                if (item.role && !getIsRole([item.role])) return <DivRoot key={index} children={undefined} />
                const isSvg = item.icon && (item.icon?.split('media/icons/').length > 1)

                return <div
                    key={index}
                    onClick={() => item.component && navigate(item.key)}
                    className={clsx('col-12 col-xxl-3 col-xl-4 col-md-6 text-muted mb-4', {
                        'opacity-75 cursor-default': !item.component,
                        'text-hover-primary cursor-pointer': item.component != null
                    })}>
                    <div className="card mb-md-8 mb-lg-0" >
                        <div className="card-body">
                            <div className="d-flex align-items-start h-100 my-auto" style={{ minHeight: '60px' }}>
                                <div className="me-4">
                                    {isSvg
                                        ? <KTSVG path={item.icon} className='svg-icon-3hx' />
                                        : <i className={clsx(item.icon, 'fs-3hx')} />
                                    }
                                </div>
                                <div className="d-flex flex-column text-muted">
                                    <span className="fs-3 mb-1 ">
                                        {intl.formatMessage({ id: `system.${item.key}` })}
                                    </span>
                                    <div className="text-gray-600">
                                        <span style={{
                                            display: "-webkit-box",
                                            maxWidth: "100%",
                                            height: "35px",
                                            margin: "0 auto",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}>
                                            {intl.formatMessage({ id: `system.${item.key}.more` })}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </>
}

export { SystemSettings }

