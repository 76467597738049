import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { IProduct } from ".";
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { ExtendClearValue, FormSubmit, InputPrice, InputSelect, KTFormItem, TextFormByUser, yup } from "../../../../theme/partials";
import SearchPopup from "../../../../theme/partials/layout/search-popup";
import { apiPrivate } from "../../../modules/api";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";

const defaultValues: IProduct = {
    itemno: null,
    description: null,
    baseunitofmeasure: null,
    purchaseunitofmeasure: null,
    inventory: null,
    maxinventory: null,
    unitprice: 0,
    unitcost: 0,
    lastdirectcost: 0,
    vendorno: null,
    vendorname: null,
    vendoritemno: null,
    assemblybom: false,
    blocked: false,
}

const urlApi = 'settings/items'
export const SettingItemsForm = ({ keyElement, pathElement, permission }: MergedProps) => {
    const intl = useIntl()
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching } = apiPrivate.useItemsId<IProduct>(valueid)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IProduct>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            // itemno: yup.string().required(),
            // unitprice: yup.number().nullable(true).transform(val => val ? Number(val) : null),
            // unitcost: yup.number().nullable(true).transform(val => val ? Number(val) : null),
            // lastdirectcost: yup.number().nullable(true).transform(val => val ? Number(val) : null)
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    const { data: vendorsData, isSuccess: isVendors, isLoading } = apiPrivate.useVendors()
    const optionsVendors = isVendors ? vendorsData.map((m: any) => ({ ...m, title: m.vendorname, description: m.number })) : []
    const [keys, setKeys] = useState<any[]>([])

    const { data: uomsData, isSuccess: isUoms } = apiPrivate.useUoms()
    const optionUoms = isUoms ? formatOptions(uomsData, ['code', 'displayname']) : []

    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IProduct) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.itemno && 'form-error'}`} {...register('itemno')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.description && 'form-error'}`} {...register('description')} />
                        </KTFormItem>
                        <KTFormItem title='text.unit-of-measure'>
                            <Controller
                                name="baseunitofmeasure"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    disabled={isEdit}
                                    options={optionUoms}
                                    value={optionUoms.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                    className={clsx("w-100", { 'form-error': errors.baseunitofmeasure })}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.purchase-unit-of-measure'>
                            <Controller
                                name="purchaseunitofmeasure"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    disabled={isEdit}
                                    options={optionUoms}
                                    value={optionUoms.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                    className={clsx("w-100", { 'form-error': errors.purchaseunitofmeasure })}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.max-inventory'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.maxinventory && 'form-error'}`} {...register('maxinventory')} type="number" />
                        </KTFormItem>
                        <KTFormItem title='Vat product posting group'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.vatprodpostinggroup && 'form-error'}`} {...register('vatprodpostinggroup')} />
                        </KTFormItem>
                        <KTFormItem title='text.assemblybom'>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                                <input disabled={isEdit} className="form-check-input" type="checkbox" {...register("assemblybom")} />
                            </label>
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.unit-price'>
                            <Controller
                                name="unitprice"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputPrice
                                    disabled={isEdit}
                                    value={value}
                                    onChange={onChange}
                                    className={`${errors.unitprice && 'form-error'}`} />} />
                        </KTFormItem>
                        <KTFormItem title='text.last-direct-cost'>
                            <Controller
                                name="lastdirectcost"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputPrice
                                    disabled={isEdit}
                                    value={value}
                                    onChange={onChange}
                                    className={`${errors.lastdirectcost && 'form-error'}`} />} />
                        </KTFormItem>
                        <KTFormItem title='text.vendor.id'>
                            <ExtendClearValue
                                hide={!watch('vendorno')}
                                handleClear={function (): void {
                                    setValue("vendorno", null)
                                    setValue("vendorname", null)
                                }}>
                                <input
                                    disabled={isEdit}
                                    className={`form-control form-control-sm ${errors.vendorno && 'form-error'}`}
                                    readOnly
                                    onClick={() => {
                                        setKeys([['vendorno', 'number'], ['vendorname']])
                                    }}
                                    {...register('vendorno')} />
                            </ExtendClearValue>
                        </KTFormItem>
                        <KTFormItem title='text.vendor.name'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.vendorname && 'form-error'}`} {...register('vendorname')} />
                        </KTFormItem>
                        <KTFormItem title='Vendor Item/Barcode'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.vendoritemno && 'form-error'}`} {...register('vendoritemno')} />
                        </KTFormItem>
                        <KTFormItem title='text.status'>
                            <span className={`badge badge-${watch('blocked') ? 'danger' : 'success'}`}>
                                {watch('blocked') ? intl.formatMessage({ id: "text.blocked" }) : intl.formatMessage({ id: "text.active" })}
                            </span>
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
        <SearchPopup
            isLoading={isLoading}
            setValue={setValue}
            searchData={optionsVendors || []}
            keys={keys}
            handleClose={() => setKeys([])} />
    </>
}
