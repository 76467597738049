export const enMessages = {
    //Messages api with code
    "message.api.error.401": "Unauthorized",
    "message.api.error.401.description": "You are not authorized to access this resource. Please contact your administrator.",
    "message.api.error.403": "Forbidden",
    "message.api.error.403.description": "You are not authorized to access this resource. Please contact your administrator.",
    "message.api.error.404": "Not found",
    "message.api.error.404.description": "The resource you are looking for might have been removed, had its name changed, or is temporarily unavailable.",
    "message.api.error.500": "Internal server error",
    "message.api.error.500.description": "The server encountered an internal error and was unable to complete your request. Please contact your administrator.",
    "message.error.something-wrong": "Something went wrong.",
    "text.return-home": "Return home",
    "text.return-login": "Return login",
    "message.error.token-expired": "Token invalid or expired. Please login again.",
    //Messages with popup
    "message.popup.success": "Your work has been saved",
    "message.popup.error": "An error occurred",
    "message.popup.loading": "Loading...",
    "message.popup.button.ok": "OK",
    "message.popup.button.cancel": "Cancel",
    "message.popup.button.close": "Close",
    "message.question.sync-bc": "Confirm synchronization of this table data with bc.",
    "message.question.clone": "Do you want to create a new record from the current row?",
    "message.question.delete": "Do you want to delete this row?",
    "message.question.download": "Do you want to download this file?",
    "message.delete.dependencies-exist": "Unfortunately, it is not possible to delete the record at this time due to existing dependencies.",
    "message.error.user-not-found": "User not found.",
    //Header
    "header.title": "iRMS",
    "header.avatar.my-profile": "My Profile",
    "header.avatar.logout": "Logout",
    //Hearder Menu
    "header.menu.merchant-console": "Merchant Console",
    //Login
    "login": "Login",
    "login.username": "Username",
    "login.password": "Password",
    "login.more": "iRMS Management System for Head Office",
    "login.when-click-login": "By clicking {login} you agree to {service_agreement}.",
    "login.service-agreement": "Service Agreement",
    "text.login.or": "OR",
    "login.required": "Required",
    "login.field-required": "Field bắt buộc",
    "login.max-character": "Vui lòng nhập không quá 50 ký tự",
    "login.min-character": "Vui lòng nhập ít nhất 3 ký tự",
    "login.forgot-password": "Forget password",
    "login.send-request": "Send request",
    "login.back-to-login": "Back to login",
    "login.wrong-email-format": "Wrong email format",
    //Login - Azure
    "login.update-info": "Please update your information to complete!",
    "login.confirm": "Confirm",
    "login.sign-in-with-microsoft": "Sign in with Microsoft",
    //Sys Menus
    "system": "Admin Settings",
    "system.general": "General",
    "system.general.more": "Set up basic information.",
    "system.users": "Users",
    "system.users.more": "Here, you manage who has access, and who can do what. Assign specific permissions to individual users.",
    "system.companies": "Companies",
    "system.companies.more": "Company information.",
    "system.email-setup": "Email setup",
    "system.email-setup.more": "Email configuration.",
    "system.user-setups": "User setups",
    "system.user-setups.more": "You also set amount limits for specific types of requests and define substitute approvers to whom approval requests are delegated when the original approver is absent.",
    "system.permission-sets": "Permission sets",
    "system.permission-sets.more": "Permission sets configuration.",
    "system.objects": "Objects",
    "system.objects.more": "Specifies the type of object that the permissions apply to in the current database.",
    "system.zalo-oa": "Zalo OA",
    "system.zalo-oa.more": "Zalo OA Configuration.",
    "system.sms-setup": "SMS setup",
    "system.sms-setup.more": "SMS Configuration.",
    "system.approvals": "Approvals",
    "system.approvals.more": "Approvals configuration.",
    "system.auto-generate-database": "Auto generate database",
    "system.auto-generate-database.more": "Backup files",
    "system.auto-generate-database.question.generate": "Are you create backup file.",
    "system.user-groups": "User groups",
    "system.user-groups.more": "User groups configuration.",
    "system.general-settings": "General settings",
    "system.general-settings.more": "General settings configuration.",
    //Sidebar
    "sidebar.dashboard": "Dashboard",
    "sidebar.requests": "Self-Service",
    "sidebar.requests.order": "Proposal",
    "sidebar.requests.advance": "Advance Request",
    "sidebar.requests.payment": "Payment Request",
    "sidebar.catalogue": "Catalogue",
    "sidebar.catalogue.items": "Items",
    "sidebar.catalogue.vendors": "Vendors",
    "sidebar.catalogue.customers": "Customers",
    "sidebar.catalogue.expenses": "Expenses",
    "sidebar.catalogue.stores": "Stores",
    "sidebar.catalogue.budgets": "Budget",
    "sidebar.catalogue.departments": "Departments",
    "sidebar.catalogue.dimensions": "Dimensions",
    "sidebar.fnb": "FnB",
    "sidebar.fnb.categories": "Categories",
    "sidebar.fnb.sub-categories": "Sub Categories",
    "sidebar.fnb.menu-structures": "Menu Structures",
    "sidebar.fnb.hospitality-items": "Hospitality Items",
    "sidebar.fnb.tags": "Tags",
    "sidebar.fnb.customizations": "Customizations",
    "sidebar.fnb.time-based-menus": "Time based Menus",
    "sidebar.fnb.merchants": "Merchants",
    "sidebar.discounts": "Discounts",
    "sidebar.discounts.promotions": "Promotions",
    "sidebar.discounts.coupons": "Coupons",
    "sidebar.configuration": "Configuration",
    "sidebar.configuration.store-tier": "Store Tier",
    "sidebar.configuration.menu-structure-tier": "Menu Structure Tier",
    "sidebar.configuration.time-based-menu-tier": "Time Based Menu Tier",
    "sidebar.configuration.payment-tier": "Payment Tier",
    "sidebar.configuration.self-service-setup": "Self Service Setup",
    "sidebar.configuration.email-templates": "Email Templates",
    "sidebar.configuration.attachments": "Attachments",
    "sidebar.configuration.no-series": "No Series",
    "sidebar.configuration.staffs": "Staffs",
    "sidebar.configuration.staffs-permission-group": "Staffs Permission Group",
    "sidebar.configuration.tax-posting-setup": "Tax Posting Setup",
    "sidebar.configuration.customer-price-groups": "Customer Price Groups",
    //Card & Table
    "popup.filter": "Filter Options",
    "table.search": "Search ...",
    "table.not-data": "No data is available.",
    "table.page-size": "Showing {count} of {totalcount}",
    //Buttons
    "button.confirm": "Confirm",
    "button.sync-bc": "Sync BC",
    "button.add": "Add new",
    "button.cancel": "Cancel",
    "button.save": "Save",
    "button.close": "Close",
    "button.save&close": "Save & Close",
    "button.reset": "Reset",
    "button.apply": "Apply",
    "button.filter": "Filter",
    "button.back": "Back",
    //Sys - General settings
    "text.general-settings": "General settings",
    //Sys - User groups 
    "text.user-groups": "User groups",
    "text.user-group": "User group",
    "text.user-group.id": "User group ID",
    "text.user-group.name": "User group name",
    "text.user-group.user-group-members": "User group members",
    "text.defaultprofiles": "Default profiles",
    "text.assigntoallnewusers": "Assign to all new users",
    //Sys - Users
    "text.email-verification": "Email verification",
    "text.access-controls": "Access controls",
    "text.users": "Users",
    "text.user": "User",
    "text.user.id": "User ID",
    "text.user.name": "User name",
    "text.signature": "Signature",
    "text.communication": "Communication",
    "text.email-address": "Email address",
    "text.avatar": "Avatar",
    "text.picture": "Picture",
    "text.password-reset": "Password reset ?",
    "text.password-reset.more": "Mật khẩu mới sẽ được gửi vào email liên hệ của bạn. Nếu không tìm thấy email, vui lòng kiểm tra thư mục Junk/Spam.",
    //Sys - Users vs Profile
    "text.login.info": "Login information",
    "text.login.email": "Login email",
    "text.web-service": "Web service",
    "text.web-service.accesskey": "Web service access key",
    "text.web-service.expirydate": "Web service expiry date",
    "text.sms": "SMS",
    "text.zalo": "Zalo",
    "text.account-information": "Account information",
    "text.account-information.more": "Change your account settings",
    "text.security": "Security",
    //Sys - Companies
    "text.store-price-group": "Store Price Group",
    "text.payment": "Payment",
    "text.companies": "Companies",
    "text.company": "Company",
    "text.company.id": "Company ID",
    "text.company.name": "Company name",
    "text.tax-registration-no": "Tax registration No.",
    "text.website": "Website",
    "text.beneficiary.name": "Beneficiary name",
    "text.swift-code": "SWIFT Code",
    "text.company-badge": "Company Badge",
    "text.company-badge.title": "Company Badge",
    "text.company-badge.style": "Company Badge Style",
    "text.company-badge.text": "Company Badge Text",
    //Sys - User-setups
    "text.user-setups": "User setups",
    "text.user-setup": "User setup",
    "text.user-setup.id": "User setup ID",
    "text.user-setup.name": "User setup name",
    "text.admin-self-service": "Admin self service",
    "text.submit-to": "Submit to",
    "text.forward-to": "Forward to",
    "text.posting-from": "Posting from",
    "text.posting-to": "Posting to",
    "text.user-setup.isshowdataentryform": "Pre-flight",
    "text.user-setup.isapprovalcompletion": "Allow completion of approval",
    "text.user-setup.employee": "Employee",
    "text.user-setup.isadminview": "Admin view",
    //Sys - Permission-sets
    "text.permission-sets": "Permission sets",
    "text.permission-set": "Permission set",
    "text.permission-set.id": "Permission set ID",
    "text.permission-set.name": "Permission set name",
    "text.permistions": "Permistions",
    "text.permission-set.delete.error.permissions": "You can't delete this permission set because it's being used by one or more users.",
    "text.permission-set.delete.error.accesscontrols": "You can't delete this permission set because it's being used by one or more access controls.",
    //Sys - Objects
    "text.objects": "Objects",
    "text.objects.add": "Add objects",
    "text.object": "Object",
    "text.object.id": "Object ID",
    "text.object.type": "Object type",
    "text.object.name": "Object name",
    "text.object.is-super": "Is super",
    "text.is-super-role": "Role super",
    "text.allow-custom-field": "Allow custom field",
    "text.field.add": "Add field",
    "text.field.update": "Update field",
    "text.label.name-system": "Label system name",
    "text.label.name": "Label name",
    "text.data-type": "Data type",
    "text.default-value": "Default value",
    "text.fields": "Fields",
    "text.report": "Report",
    //Sys - Approvals
    "text.approvals": "Approvals",
    "text.approval": "Approval",
    "text.approval.id": "Approval ID",
    "text.approval.name": "Approval name",
    "text.approval.admin": "Approval admin",
    "text.module": "Module",
    "text.priority": "Priority",
    "text.turn-around-time": "Turn Around Time",
    "text.criteria-pattern": "Criteria Pattern",
    "text.change-criteria": "Change Criteria",
    "text.define-ther-criteria-(if-any)": "Define ther criteria ( if any )",
    "text.approval.setting-message": "You can either configure approver levels or allow the system to auto approve / reject the reports",
    "text.email-notification": "Email notification",
    "text.configure-approval-flow": "Configure Approval Flow",
    "text.auto-approval": "Auto Approval",
    "text.auto-reject": "Auto Reject",
    "text.cc": "Cc",
    "text.bcc": "Bcc",
    "text.new-request": "New request",
    "text.notify-approver": "Notify approver",
    "text.next-lever": "Next lever",
    "text.approved": "Approved",
    "text.denied": "Denied",
    "text.hierarchical-approval": "Hierarchical approval",
    "text.custom-approval": "Custom Approval",
    "text.is": "is",
    "text.when": "when",
    "text.or": "or",
    "text.and": "and",
    "text.to": "to",
    "text.starts-with": "starts with",
    "text.ends-with": "ends with",
    "text.contains": "contains",
    "text.doesn't-contain": "doesn't contain",
    "text.is-empty": "is empty",
    "text.is-not-empty": "is not empty",
    "text.add-one-more": "Add one more",
    "text.remind-approver-input-before": "Remind approver {input} before",
    "text.no-of-days-allotted-for-approving-request-for-each-approver-input-days": "No. of days allotted for approving request for each approver {input} days",
    "text.when-tat-has-elapsed": "When TAT has elapsed",
    "text.intermediate-approver-settings": "Intermediate approver settings",
    "text.move-to-next-approver": "Move to next approver",
    "text.assign-to": "Assign to",
    "text.auto-approve": "Auto approve",
    "text.final-approver-settings": "Final approver settings",
    "text.request-will-remain-pending-approval": "Request will remain pending approval",
    "text.remind-approver-once-in-input-days-when-request-is-still-pending-for-approval": "Remind approver once in {input} days when request is still pending for approval",
    //Sys - Email-setup
    "text.anonymous": "Anonymous",
    "text.basic": "Basic",
    "text.oauth-2.0": "OAuth 2.0",
    "text.ntlm": "NTLM",
    "text.sender.name": "Sender name",
    "text.server.url": "Server url",
    "text.server.port": "Server port",
    "text.authentication": "Authentication",
    "text.secure-connection": "Secure connection",
    "text.rate-limit-per-minute": "Rate limit per minute",
    //Catalogue - Items
    "text.items": "Items",
    "text.item": "Item",
    "text.item.id": "Item ID",
    "text.item.name": "Item name",
    "text.purchase-unit-of-measure": "Purchase unit of measure",
    "text.assemblybom": "BOM",
    "text.last-direct-cost": "Last direct cost",
    //Catalogue - Vendors
    "text.vendors": "Vendors",
    "text.vendor": "Vendor",
    "text.vendor.id": "Vendor ID",
    "text.vendor.name": "Vendor name",
    "text.vendor.employee": "Employee",
    "text.vendor.user-setup": "Username",
    "text.pay-to-vendor": "Pay to vendor",
    "text.balance-as-customer": "Balance as customer",
    //Employee
    "text.job-title": "Job title",
    //
    "text.bank.account.no": "Bank account No.",
    "text.bank.account.name": "Bank account name",
    //Catalogue - Customers
    "text.customers": "Customers",
    "text.customer": "Customer",
    "text.customer.id": "Customer ID",
    "text.customer.name": "Customer name",
    "text.bill-to-customer-no": "Bill to customer No.",
    "text.balance-as-vendor": "Balance as vendor",
    "text.total-sales-excl-vat": "Total sale (excl. vat)",
    "text.credit-limit": "Credit limit",
    //Catalogue - Expenses
    "text.expenses": "Expenses",
    "text.expense": "Expense",
    "text.expense.id": "Expense ID",
    "text.expense.name": "Expense name",
    "text.expense.line": "Expense line",
    "text.expense.lines": "Expense lines",
    "text.expense.detail": "Expense detail",
    "text.expense.line.id": "Expense line id",
    "text.expense.line.name": "Expense line name",
    "text.cost-center-no": "Cost Center No.",
    "text.cash-flow": "Cash Flow",
    //Catalogue - Stores
    "text.stores": "Stores",
    "text.store": "Store",
    "text.store.id": "Store ID",
    "text.store.name": "Store name",
    "text.store.type": "Store type",
    "text.open-hours": "Open hours",
    "text.closed-hours": "Closed hours",
    "text.close-time": "Close time",
    "text.open-time": "Open time",
    "text.manager.id": "Manager ID",
    "text.manager.name": "Manager name",
    //Catalogue - Budgets
    "text.budgets": "Budgets",
    "text.budget": "Budget",
    "text.budget.id": "Budget ID",
    "text.budget.name": "Budget name",
    //Catalogue - Departments
    "text.departments": "Departments",
    "text.department": "Department",
    "text.department.id": "Department ID",
    "text.department.name": "Department name",
    "text.department-head": "Department head",
    "text.department-head.phone": "Department head phone",
    "text.department-head.email": "Department head email",
    "text.parent": "Parent",
    "text.department.delete.error.isparent": "You can't delete this department because it's being used by one or more departments.",
    //Fnb - Merchants
    "text.merchants": "Merchants",
    "text.merchant": "Merchant",
    "text.merchant.id": "Merchant ID",
    "text.merchant.name": "Merchant name",
    "text.sections": "Section",
    "text.section": "Section",
    "text.section.name": "Section name",
    "text.section.add": "Add section",
    "text.dining-tables": "Dining tables",
    "text.dining-table": "Dining table",
    "text.dining-table.id": "Table ID",
    "text.dining-table.add": "Add dining tables",
    "text.seat-capacity": "Seat capacity",
    "text.public": "Public",
    "text.allowed": "Allowed",
    "text.not-allowed": "Not allowed",
    "text.display-mode": "Display Mode",
    "text.thousand-separator": "Thousand Separator",
    "text.merchant-key": "Merchant Key",
    "text.merchant-url": "Merchant URL",
    "text.latitude": "Latitude",
    "text.longitude": "Longitude",
    "text.country-phone-code": "Country Phone Code",
    "text.decimal-separator": "Decimal Separator",
    "text.advance-settings": "Advance Settings",
    "text.show-item-pictures": "Show Item Pictures",
    "text.auto-accept-order": "Auto Accept Order",
    "text.receipt-printer": "Receipt Printer",
    "text.kitchen-printer": "Kitchen Printer",
    "text.reprint": "Reprint",
    "text.smartweb-splash-screen": "SmartWeb Splash Screen",
    "text.store-tier": "Store Tier",
    "text.menu-structure-tier": "Menu Structure Tier",
    "text.payment-tier": "Payment Tier",
    "text.time-based-menu-tier": "Time Based Menu Tier",
    "text.invoice": "Invoice",
    "text.vat-bus-posing-group": "Vat Bus. Posing Group",
    "text.tax-identification-no": "Tax Identification No.",
    "text.section-and-table": "Section & Table",
    "text.expires-at": "Expires At",
    "text.order-settings": "Order Settings",
    "text.permission-for-key-order-type": "Permission for {key} order type",
    "text.enable-order-type": "Enable order type",
    "text.enable-order-type-options": "Enable order type options",
    "text.request-no-of-guest": "Request No. of Guest",
    "text.use-seating-capacity": "Use Seating Capacity",
    "text.cover-exceeds-capacity": "Cover Exceeds Capacity",
    "text.qr-code": "QR Code",
    "text.generate-qr-code": "Generate QRCodes",
    "text.printer-settings": "Printer Settings",
    "text.printer": "Printer",
    "text.host": "Host",
    "text.port": "Port",
    "text.logo-size": "Logo Size",
    "text.logo-align": "Logo Align",
    "text.qr-code-width": "QR Code width",
    "text.qr-code-height": "QR Code height",
    "text.server-https": "Server https",
    "text.hardware-station-host": "Hardware station host",
    "text.left": "Left",
    "text.center": "Center",
    "text.right": "Right",
    "text.windows": "Windows",
    "text.win-printer": "WinPrinter",
    "text.opos-printer": "OPOS Printer",
    "text.normal": "Normal",
    "text.double-wide": "Double wide",
    "text.double-high": "Double high",
    "text.double-wide-and-high": "Double wide and high",
    //Fnb - Categories
    "text.categories": "Categories",
    "text.category": "Category",
    "text.category.id": "Category ID",
    "text.category.name": "Category name",
    "text.dine-in": "Dine in",
    "text.pickup": "Pickup",
    "text.take-away": "Take away",
    "text.delivery": "Delivery",
    //Fnb - Sub-categories
    "text.sub-categories": "Sub Categories",
    "text.sub-category": "Sub Category",
    "text.sub-category.id": "Sub Categorie ID",
    "text.sub-category.name": "Sub Category Name",
    //Fnb - Menu-structures
    "text.menu-structures": "Menu Structures",
    "text.menu-structure": "Menu Structure",
    "text.menu-structure.id": "Menu Structure ID",
    "text.menu-structure.name": "Menu Structure name",
    "text.primary-menu-structure": "Primary Menu Structure",
    "text.secondary-menu-structure": "Secondary Menu Structure",
    "text.primary-menu": "Primary Menu",
    "text.secondary-menu": "Secondary Menu",
    "text.display-location": "Display location",
    "text.add-menu-items": "Add Menu Items",
    //Fnb - Hospitality-items
    "text.hospitality-items": "Hospitality Items",
    "text.hospitality-item": "Hospitality Item",
    "text.hospitality-item.id": "Hospitality Item ID",
    "text.hospitality-item.name": "Hospitality Item name",
    "text.hospitality-item.variant.id": "Variant ID",
    "text.recommendation": "Recommendation",
    "text.recommended": "Recommended",
    "text.featured": "Featured",
    "text.stock": "Stock",
    "text.stock-in": "Stock in",
    "text.stock-out": "Stock out",
    "text.has-variants": "Has variants",
    "text.unit-of-measure": "Unit of measure",
    "text.item-has-allergen": "Item has allergen?",
    "text.side-item": "Side item?",
    "text.has-sku-image": "Has SKU image?",
    "text.incl-vat": "Incl. Vat?",
    "text.is-combo": "Is combo",
    "text.max-dish-per-day": "Max dish per day",
    "text.how-many-calories": "How many calories?",
    //Fnb - Tags
    "text.tags": "Tags",
    "text.tag": "Tag",
    "text.tag.id": "Tag ID",
    "text.tag.name": "Tag name",
    //Fnb - Customizations
    "text.customizations": "Customizations",
    "text.customization": "Customization",
    "text.customization.id": "Customization ID",
    "text.customization.name": "Customization name",
    "text.no-of-selection": "No. Of Selection",
    "text.min-no-of-selection": "Min No. Of Selection",
    "text.max-no-of-selection": "Max No. Of Selection",
    "text.header-text": "Header Text",
    "text.show-as-recommendation": "Show as Recommendation",
    "text.do-not-show-in-combo": "Do not show in combo",
    "text.view-model": "View model",
    "text.one-column": "One column",
    "text.two-column": "Two column",
    "text.option-for-customization": "Option for Customization",
    "text.allow-multiple-qty": "Allow Multiple QTY",
    "text.max-allowed-qty": "Max Allowed QTY",
    //Fnb - Time-based-menus
    "text.time-based-menus": "Time based menus",
    "text.time-based-menu": "Time based menu",
    "text.time-based-menu.id": "Time based menu ID",
    "text.time-based-menu.name": "Time based menu name",
    "text.days-of-the-week": "Days of the week",
    "text.days": "Days",
    "text.available": "Available",
    //Fnb - Promotions
    "text.promotions": "Promotions",
    "text.promotion": "Promotion",
    "text.promotion.id": "Promotion ID",
    "text.promotion.name": "Promotion name",
    //Configuration - Attachments
    "text.attachments": "Attachments",
    "text.attachments.add": "Attachments add",
    "text.attachment": "Attachment",
    "text.attachment.id": "Attachment ID",
    "text.attachment.name": "Attachment name",
    "text.attachment.type": "Attachment type",
    "text.attachment.object": "Module",
    //Configuration - Email-template
    "text.email-templates": "Email templates",
    "text.email-template": "Email template",
    "text.email-template.id": "Email template ID",
    "text.email-template.name": "Email template name",
    "text.email-template.detail": "Email template detail",
    //Configuration - No-series
    "text.seq": "Seq",
    "text.no-series": "No. Series",
    "text.no-seri": "No. Seri",
    "text.no-seri.id": "No. Seri ID",
    "text.no-seri.name": "No. Seri name",
    "text.no-seri.lines": "No. Seri lines",
    "text.no-seri.start-no": "Start No.",
    "text.no-seri.end-no": "End No.",
    "text.no-seri.last-number-used": "Last number used",
    "text.no-seri.warning-number": "Warning number",
    "text.last-date-used": "Last date used",
    "text.gap": "Gap",
    "text.allow-gap": "Allow gap",
    "text.allow-manual": "Allow manual",
    //Configuration - Staffs
    "text.employee": "Employee",
    "text.staff": "Staff",
    "text.manager": "Manager",
    "text.name-on-receipt": "Name on Receipt",
    "text.logged-into-pos": "Logged into POS",
    "text.home-phone-no": "Home phone",
    "text.work-phone-no": "Work phone",
    "text.personal": "Personal",
    "text.address2": "Address 2",
    "text.hourly-rate": "Hourly rate",
    "text.payroll-no": "Payroll no",
    "text.change-password": "Change password",
    "text.date-to-be-blocked": "Date to be blocked",
    "text.employment-type": "Employment type",
    "text.last-z-report": "Last ZReport",
    "text.permission-group": "Permission group",
    "text.first-name": "First name",
    "text.last-name": "Last name",
    "text.cashier": "Cashier",
    "text.void-item-in-bill": "Void item in bill",
    //Configuration - Store-tier
    "text.minimum-quantity": "Minimum QTY",
    "text.minimum-amount": "Minimum Amount",
    "text.lead-time-(minutes)": "Lead Time (minutes)",
    "text.lead-time-minutes": "Lead Time (minutes)",
    "text.maximum-size-(MB)": "Maximum size (MB)",
    "text.maximum-quantity-(file)": "Maximum quantity (file)",
    "text.setting-item": "Setting Item",
    "text.monday": "Monday",
    "text.tuesday": "Tuesday",
    "text.wednesday": "Wednesday",
    "text.thursday": "Thursday",
    "text.friday": "Friday",
    "text.saturday": "Saturday",
    "text.sunday": "Sunday",
    "text.weekly-timing": "Weekly Timing",
    "text.order-type-assign": "Order Type Assign",
    //Configuration - Menu-structure-tier
    //Configuration - Self-service-setup
    "text.advanceNo": "Advance No. Series",
    "text.paymentNo": "Payment No. Series",
    "text.orderNo": "Order No. Series",
    "text.advanceRequestFor": "Advance Request For Someone",
    "text.paymentRequestFor": "Payment Request For Someone",
    //Requests - Order
    "text.order-requests": "Proposals",
    "text.order-request": "Proposal",
    "text.order-request.id": "Proposal ID",
    "text.order-request.name": "Proposal name",
    "text.request.name": "Name",
    "text.request.type": "Type",
    "text.system-unit-price": "System Unit Price",
    "text.dear": "Dear",
    "text.about": "About",
    "text.purpose": "Purpose",
    "text.order-details": "Order Details",
    "text.list-of-order": "List of Order",
    "text.closed-request": "Closed Request",
    "text.send-request": "Send Request",
    "text.send-request-approval": "Send request approval",
    "text.send": "Send",
    "text.order.noti-import": "Please check that the information below is not blank",
    "text.detail-not-empty": "Please check the detailed requirements as leaving them blank is not allowed",
    //Requests - Advance
    "text.advance-requests": "Advance requests",
    "text.advance-request": "Advance request",
    "text.advance-request.id": "Advance request ID",
    "text.advance-request.name": "Advance request name",
    "text.advance-details": "Advance Details",
    "text.advance-amount": "Advance amount",
    "text.list-of-advance": "List of Advance",
    "text.advance-for": "Advance for",
    "text.salary-deduction": "Salary Deduction",
    "text.maximum-deduction": "Maximum Deduction",
    "text.completion-date": "Completion date",
    "text.amount-of-advance-request": "Amount of advance request",
    "text.explain-content": "Explain content",
    "text.advance-object" : "Vendor Type",
    "text.advance-objectcode":"Code",
    "text.advance-objectname":"Name",
    //Requests - Payment
    "text.payment-requests": "Payment requests",
    "text.payment-request": "Payment request",
    "text.payment-request.id": "Payment request ID",
    "text.payment-request.name": "Payment request name",
    "text.payment-for": "Payment for",
    "text.list-of-payment": "List of Payment",
    "text.advance-code": "Advance code",
    "text.advance-content": "Advance content",
    "text.payment-code": "Payment code",
    "text.payment-content": "Payment content",
    "text.remaining-advance-payment": "Remaining advance payment",
    "text.remaining-advance": "Remaining",
    "text.payment-information": "Payment Information",
    "text.payment-details": "Payment Details",
    "text.store-code": "Store code",
    "text.store-name": "Store name",
    "text.bill-code": "Bill code",
    "text.discount-rate": "Discount rate (%)",
    "text.discount-value": "Discount value",
    "text.warranty-rate": "Warranty rate (%)",
    "text.warranty-value": "Warranty value",
    "text.payment-amount": "Payment amount",
    "text.excluding-vat": "excl. VAT",
    "text.advance": "Advance",
    "text.payment-object" : "Vendor Type",
    "text.payment-objectcode":"Code",
    "text.payment-objectname":"Name",
    "text.payment.not-map": "Invalid Code, Please check again",
    "text.payment.not-space": "Fields cannot be blank, please double check the fields below",
    "text.success-import": "Imported successfully",
    "text.currencytype": "Currency",
    //Requests
    "text.document-code": "Document code",
    "text.document-date": "Document Date",
    "text.document-type": "Document Type",
    "text.department-code": "Department code",
    "text.department-name": "Department name",
    "text.bank-code": "Bank code",
    "text.bank-name": "Bank name",
    "text.bank-account-no": "Bank account no",
    "text.bank-account-name": "Bank account name",
    "text.bank-information": "Bank Information",
    "text.general-information": "General Information",
    "text.creator": "Creator",
    "text.date-created": "Date created",
    "text.expected-date": "Expected date",
    "text.approval-history": "Approval History",
    "text.print": "Print",
    "text.waiting": "Waiting",
    "text.day": "Day",
    "text.duplicate": "Duplicate",
    "text.from-date": "From date",
    "text.to-date": "To date",
    "text.export": "Export",
    "text.size": "Size",
    "text.explain": "Explain",
    "text.closed": "Closed",
    "text.please-attach-Files-as-requested": "Please attach Files as requested",
    "text.please-quickly-select-an-action": "Please quickly select an action",
    "text.save-and-send-request": "Save and send request",
    "text.save-and-create": "Save and create",
    "text.do-you-want-to-close-these-proposals": "Do you want to close these proposals?",
    "text.moved-to-next": "Moved to the next level of approval",
    "text.do-you-want-to-submit-approval": "Do you want to submit this proposal for approval?",
    "text.approval-reject": "Approval reject",
    "text.note": "Note",
    "text.details": "Details",
    "text.object.code": "Object Code",
    "text.contact-to-admin": "You do not belong to any department. Please contact admin for assistance.",
    "text.required-dept-head" : "A department manager is required for each department.",
    //Dashboard
    "text.dashboard.task.approve": "Approve",
    "text.dashboard.task.deny": "Deny",
    "text.task-is-open": "The task is open",
    "text.hello": "Hi",
    "text.all": "All",
    "text.expired": "Expired",
    "text.expired-week": "Expired within the week",
    "text.created-a-new-ticket": "created a new ticket",
    "text.you": "You",
    "text.task.pending": "pending",
    "text.task.approval": "approval",
    "text.task.approved": "approved",
    "text.task.denied": "denied",
    "text.task.of": "of",
    "text.task.closed": "closed",
    "text.task.request": "Request",
    "text.task.by": "by",
    "text.task.request-for": "request for",
    "text.task.initialization-has": "initialization has been",
    "text.task.approved-and-forwarded": "approved and forwarded to",
    "text.task.next-request": "to request next approval",
    "text.task.forwarded": "forwarded",
    "text.task.date": "Date",
    "text.task.for": "for",
    "text.task.vote": "Vote",
    "text.task.order": "Proposal",
    "text.task.advance": "advance",
    "text.task.payment": "payment",
    "text.show": "Show",
    "text.approval-request": "Approval request",
    //admin-view
    "text.payment-summary": "Payment Summary",
    "text.overall-summary": "Overall Summary",
    "text.current": "Current",
    "text.thismonth": "This Month",
    "text.thisquarter": "This Quarter",
    "text.thisyear": "This Year",
    "text.previous": "Previous",
    "text.previous-month": "Previous Month",
    "text.previous-quarter": "Previous Quarter",
    "text.previous-year": "Previous Year",
    "text.pending-reimbursements": "Pending Reimbursements",
    "text.pending-proposals": "Pending Proposals",
    "text.pending-advances": "Pending Advances",
    "text.pending-payments": "Pending Payments",
    "text.fiscal-year": "Fiscal Year",
    "text.top-dept": "Top department",
    "text.top-order": "Top Proposals",
    "text.top-advance": "Top Advances",
    "text.top-payment": "Top Payments",
    "text.proposals": "Proposals",
    "text.advances": "Advances",
    "text.payments": "Payments",
    "text.currentM": "M",
    "text.currentTH": "TH",
    "text.currentVND": "",
    "text.week": "Week",
    "text.month1":"Jan",
    "text.month2":"Feb",
    "text.month3":"Mar",
    "text.month4":"Apr",
    "text.month5":"May",
    "text.month6":"Jun",
    "text.month7":"Jul",
    "text.month8":"Aug",
    "text.month9":"Sep",
    "text.month10":"Oct",
    "text.month11":"Nov",
    "text.month12":"Dec",
    //General - Actions
    "text.confirm": "Confirm",
    "text.sync-bc": "Sync BC",
    "text.sync": "Sync",
    "text.delete": "Delete",
    "text.modify": "Modify",
    "text.read": "Read",
    "text.execute": "Execute",
    "text.insert": "Insert",
    "text.add": "Add",
    "text.cancel": "Cancel",
    "text.save": "Save",
    "text.close": "Close",
    "text.save&close": "Save & Close",
    "text.reset": "Reset",
    "text.apply": "Apply",
    "text.filter": "Filter",
    "text.back": "Back",
    "text.generate": "Generate",
    "text.login": "Login",
    "text.reset-password": "Reset password",
    "text.forgot-password": "Forget password",
    "text.search": "Search",
    "text.skip": "Skip",
    //General - Api
    "text.created-at": "Created at",
    "text.created-by": "Created by",
    "text.updated-at": "Updated at",
    "text.updated-by": "Updated by",
    "text.created": "Created",
    "text.updated": "Updated",
    "text.created-by-name": "Created by {name}",
    "text.updated-date-by-name": "Updated {date} by {name}",
    //General - DateTime
    "text.times": "Times",
    "text.start-time": "Start date",
    "text.end-time": "End date",
    "text.started-date": "Started date",
    "text.ended-date": "Ended date",
    "text.starting-date": "Starting Date",
    "text.ending-date": "Ending Date",
    //General - Address
    "text.address": "Address",
    "text.state": "State",
    "text.district": "District",
    "text.zipcode": "Zipcode",
    "text.province": "Province",
    "text.country": "Country",
    "text.ward": "Ward",
    //General - Text
    "text.members": "Members",
    "text.contact.name": "Contact name",
    "text.contact.email": "Contact email",
    "text.location": "Location",
    "text.location.id": "Location ID",
    "text.account": "Account",
    "text.account.no": "Account No.",
    "text.account.name": "Account name",
    "text.timezone": "Timezone",
    "text.bank": "Bank",
    "text.banks": "Banks",
    "text.bank.name": "Bank name",
    "text.bank.info": "Bank information",
    "text.branch.id": "Branch ID",
    "text.branch.name": "Branch name",
    "text.general": "General",
    "text.subject": "Subject",
    "text.body": "Body",
    "text.information": "Information",
    "text.email": "Email",
    "text.username": "Username",
    "text.password": "Password",
    "text.#": "#",
    "text.id": "ID",
    "text.code": "Code",
    "text.name": "Name",
    "text.required": "Required",
    "text.fullname": "Full name",
    "text.blocked": "Blocked",
    "text.active": "Active",
    "text.status": "Status",
    "text.type": "Type",
    "text.actions": "Actions",
    "text.action": "Action",
    "text.phone": "Phone",
    "text.total": "Total",
    "text.description": "Description",
    "text.reason": "Reason",
    "text.content": "Content",
    "text.preview": "Preview",
    "text.method": "Method",
    "text.payment-method": "Payment method",
    "text.payment-term": "Payment term",
    "text.price": "Price",
    "text.unit-price": "Unit Price",
    "text.vat-registration-no": "Vat registration No.",
    "text.inventory": "Inventory",
    "text.variants": "Variants",
    "text.prices": "Prices",
    "text.max-inventory": "Max inventory",
    "text.balance": "Balance",
    "text.system": "System",
    "text.order-type": "Order Type",
    "text.image": "Image",
    "text.images": "Images",
    "text.thumb-image": "Thumb image",
    "text.icon": "Icon",
    "text.settings": "Settings",
    "text.table": "Table",
    "text.select-all": "Select all",
    "text.yes": "Yes",
    "text.no": "No",
    "text.page": "Page",
    "text.edit": "Edit",
    "text.order": "Order",
    "text.by": "by",
    "text.uom": "Uom",
    "text.quantity": "Quantity",
    "text.vat": "VAT",
    "text.noti-required-file": "Please attach this file type",
    "text.remove-all": "Remove all",
    "text.loadings": "Loading...",
    "text.globaldimension1code": "Global Dimension code 1",
    "text.globaldimension2code": "Global Dimension code 2",
    "text.globaldimension3code": "Global Dimension code 3",
    "text.globaldimension4code": "Global Dimension code 4",
    "text.row": "Row",
    "text.please-check-form-detail": "Please check details information!",
    "text.workflows-not-defined": "Workflows are not defined",
    "text.advance-check-amount": "The advance amount is not enough. Please check again!",
    "text.chart.unit": "Unit",
    //------------import----------------
    "text.import.title": "Import",
    "text.import.label": "Upload a file, allowed extensions: xlsx",
    "text.import.choose-file": "Choose File",
    "text.import.no-file": "No file choosen",
    "text.import.sample-file": "Link file csv sample",
    "text.import.submit": "Submit",
    "text.deptname": "Department name",
    "text.complete-approval": "Complate approval",
    "text.byme": "My Documents",
    "text.forme": "Action Required",
    //--------------history
    "text.waitingfor": "Waiting for",
    "text.toapprove": "to approve",
    "text.subtotal": "Sub Total",

}