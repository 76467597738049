import clsx from "clsx"
import { ApiService, ApiShowError, formatCurrent, getLocalStorage, sortWithAny, sortWithDate } from "../../../../theme/helpers"
import { CustomHeaderColumn, CustomRow, DivRoot, KTSVG, KTTable } from "../../../../theme/partials"
import { LocalData } from "../../../modules/api/localData"
import { popupLoading, popupMessage, popupQuestion } from "../../../modules/messages"
import { useRef } from "react"
import { useIntl } from "react-intl"
import { ColumnInstance, Row, useTable } from "react-table"

export const convertOperatorObject = {
    "starts_with": function (field:any,value:any) {return field.startsWith(value)},
    "ends_with": function (field:any,value:any) {return field.endsWith(value)},
    "contains": function (field:any,value:any) {return field.contains(value)},
    "not_contains": function (field:any,value:any) {return !field.contains(value)},
    "is_empty": function (field:any,value:any) {return field == '' || field == null},
    "is_not_empty": function (field:any,value:any) {return field != '' || field != null},
    "equal": function (field : any, value: any, type? : any) {
      if(type == 'DATE') {
        return new Date(field).getTime() == new Date(value).getTime()
      }
      else if(type == 'STRING') {
        return field == value
      }
      else {
        return Number(field) == Number(value)
      }
    },
    "not_equal": function (field : any, value: any, type? : any) {
      if(type == 'DATE') {
        return new Date(field).getTime() != new Date(value).getTime()
      }
      else if(type == 'STRING') {
        return field != value
      }
      else {
        return Number(field) != Number(value)
      }
    },
    "different": function (field : any, value: any, type? : any) {
      if(type == 'DATE') {
        return new Date(field).getTime() != new Date(value).getTime()
      }
      else if(type == 'STRING') {
        return field != value
      }
      else {
        return Number(field) != Number(value)
      }
    },
    "greater_equal": function (field : any, value: any, type? : any) {
      if(type == 'DATE') {
        return new Date(field).getTime() > new Date(value).getTime()
      }
      else {
        return Number(field) > Number(value)
      }
    },
    "greater_than_or_equal_to": function (field : any, value: any, type? : any) {
      if(type == 'DATE') {
        return new Date(field).getTime() >= new Date(value).getTime()
      }
      else {
        return Number(field) >= Number(value)
      }
    },
  
    "less_than": function (field : any, value: any, type? : any) {
      if(type == 'DATE') {
        return new Date(field).getTime() < new Date(value).getTime()
      }
      else {
        return Number(field) < Number(value)
      }
    },
    "less_than_or_equal_to": function (field : any, value: any, type? : any) {
      if(type == 'DATE') {
        return new Date(field).getTime() <= new Date(value).getTime()
      }
      else {
        return Number(field) <= Number(value)
      }
    },
    "is": function (field : any, value: any, type? : any) {
      return field == value
    },
    "and": function (arr:any) {
      return arr.find((i:any)=>!i) ? true : false
    },
    "or": function (x : any, y: any) {
      return x || y
    },
  } as any
  export function convertToIfCondition(conditionString: string, arrData: any) {
    // Xóa khoảng trắng không cần thiết trong chuỗi
    conditionString = conditionString.replace(/\s+/g, '');
    // Thay thế các toán tử với toán tử tương ứng của JavaScript
    conditionString = conditionString.replace(/OR/gi, '||');
    conditionString = conditionString.replace(/AND/gi, '&&');
    // Thêm cặp dấu ngoặc đơn vào xung quanh mỗi cụm xử lý logic
    conditionString = conditionString.replace(/\w+/gi, (match:any) => `${arrData[match - 1]}`);
    return conditionString;
  }
export function formatDataLevel(data: any,userid?:any,approvalreminderbefore?: any) {
    
    let dataLevel = JSON.parse(JSON.stringify(data))
    let user_arr = dataLevel.filter((item:any)=> item.type == "user")
    user_arr.forEach((i:any) => {
      let indexUser = dataLevel.findIndex((item:any)=> item.order == i.order)
      if(indexUser > -1) {
        dataLevel[indexUser].email = dataLevel[indexUser].useremail
        dataLevel[indexUser].approvalreminderbefore = approvalreminderbefore
      }
    })
    let head_of_selected_department_arr = dataLevel.filter((item:any)=> item.type == "head_of_selected_department")
    head_of_selected_department_arr.forEach((i:any)=> {
      let indexHeadof = dataLevel.findIndex((item:any)=> item.order == i.order)
      if(indexHeadof > -1) {
        dataLevel[indexHeadof].userid = dataLevel[indexHeadof].departmenthead?.departmentheadid
        dataLevel[indexHeadof].username = dataLevel[indexHeadof].departmenthead?.departmentheadcode
        dataLevel[indexHeadof].usercode = dataLevel[indexHeadof].departmenthead?.departmentheadcode
        dataLevel[indexHeadof].email = dataLevel[indexHeadof].departmenthead?.departmentheademail
        dataLevel[indexHeadof].approvalreminderbefore = approvalreminderbefore
      }
    })
   
    let department_head_arr = dataLevel.filter((item:any)=> item.type == "department_head")
    department_head_arr.forEach((i:any) => {
      let indexHead = dataLevel.findIndex((item:any)=> item.order == i.order)
      if(indexHead > -1) {
        dataLevel[indexHead].userid = dataLevel[indexHead].departmenthead?.departmentheadid
        dataLevel[indexHead].username = dataLevel[indexHead].departmenthead?.departmentheadcode
        dataLevel[indexHead].usercode = dataLevel[indexHead].departmenthead?.departmentheadcode
        dataLevel[indexHead].email = dataLevel[indexHead].departmenthead?.departmentheademail
        dataLevel[indexHead].approvalreminderbefore = approvalreminderbefore
      }
    })
    
    let submits_to_arr = dataLevel.filter((item:any)=> item.type == 'submits_to')
    let submittoLevel = [] as any
    submits_to_arr.forEach((i:any) => {
      let index = dataLevel.findIndex((d:any)=> d.order == i.order)
      let temp = i.levels.filter((i:any)=> i.submittoid).map((item:any)=> {
        return {
          type: 'submits_to',
          userid: item.submittoid,
          username: item.submittocode,
          usercode: item.submittocode,
          condition: item.submittoifamountexceed,
          approvaladministrator: item.approvaladministrator,
          allowpostingfrom: item.allowpostingfrom,
          allowpostingto: item.allowpostingto,
          order: item.order + dataLevel[index].order - 1,
          stageParent: dataLevel[index].order,
          email: item.submittoemail,
          approvalreminderbefore: approvalreminderbefore,
        }
      })
      submittoLevel = submittoLevel.concat(temp)
      for(let ind = index + 1; ind<dataLevel.length;ind++) {
        dataLevel[ind].order += dataLevel[index].levels.length - 1
      }
    })
    dataLevel = dataLevel.filter((item:any)=> item.type != 'submits_to')
    dataLevel = dataLevel.concat(submittoLevel).filter((i:any)=>i.userid != userid && i.userid)
    return sortWithAny(dataLevel,{sort: 'order'}).map((e:any,index:number)=>({ stage: index, ...e }))
  }
export function formatApprovalLevel(data:any){
    let tempLevel = [] as any
    if(data.submittoid) {
        tempLevel.push({
            userid: data.submittoid,
            username: data.submittocode,
            usercode: data.submittocode,
            condition: data.submittoifamountexceed,
            approvaladministrator: data.approvaladministrator,
            order: 1,
            stage: 0,
            type: "submits_to",
            stageParent: 0,
            email: data.submittoemail,
        })
    }
    if(data.forwardtoid) {
        tempLevel.push({
            userid: data.forwardtoid,
            username: data.forwardtocode,
            usercode: data.forwardtocode,
            condition: data.forwardtoifamountexceed,
            approvaladministrator: data.approvaladministrator,
            type: "submits_to",
            stage: 1,
            order: 2,
            stageParent: 0,
            email: data.forwardtoemail,
    
        })
    }
    
    return tempLevel
  }
  export const convertStatus = (status:any,approvalhistory:any, userid?: string,ind?:number) => {
    if(!approvalhistory) return  status
    let indexStage = +approvalhistory.currentstage + (ind?ind:0)
    let indexInRule = approvalhistory.rule?.find((i:any)=>i.userid == userid)?.stage
    
    //284210002: approved
    //284210003: denied
    //284210004: đóng
    if(indexInRule == undefined || status == "284210002" || status == "284210003" || status == "284210004") {
        return status
    }
    if(indexInRule < indexStage) {
        status = "284210006" // đã chuyển tiếp
    }
    else {
        status = "284210005" //cần phê duyệt
    }
    return status
}
export const templateDetails = (data: any,columns: any, intl: any, isLoading?: any) => {
  // console.log('columns',columns)
  
  return <div className='row'>
  <div className='col-12 '>
      <div className=' notice bg-opacity-25 border-warning border border-1 border-dashed'>
          <div className='wrap-inf'>
          <table className="table table-row-bordered table-hover dataTable no-footer bg-light-warning">
              <thead className="bg-warning bg-opacity-25">
                  <tr className="fw-bolder">
                      {columns.map((cell: any, index: any) => 
                          <th key={'groups' + index} className={clsx("",cell.class,)}>
                              {cell.title}
                          </th>
                      )}
                      
                  </tr>
              </thead>
              <tbody >
                  {
                      data && data.length !=0 ?
                      data.map((item: any,index: number) => (
                          <DivRoot key={index}>
                              <tr className={clsx("align-top hover-approval", )} >
                                  {columns.map((cell: any, index: any) => 
                                      <td key={index} className={clsx("",cell.class,)}>
                                          {cell.component && cell.component(item)}
                                      </td>
                                  )}
                              </tr>
                          </DivRoot>
                      )):
                      <tr>
                          <td colSpan={20}>
                              <div className='d-flex w-100 justify-content-center py-2 text-gray-600'>
                              {isLoading
                                  ? intl.formatMessage({ id: "text.loadings" })
                                  : intl.formatMessage({ id: "table.not-data" })}
                              </div>
                          </td>
                      </tr>
                  }
              </tbody>
          </table>
          </div>
      </div>
  </div>
</div>
}
export const getEmailInfo = (emailnotification:any, item:any, type: any) => {
  let data = JSON.parse(JSON.stringify(item))
  if(data.documentdate && typeof data.documentdate != 'string' ) {
      data.documentdate = new Date(data.documentdate[0]).toLocaleDateString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  }
  else {
    data.documentdate = data.documentdate && new Date(data.documentdate).toLocaleDateString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  }
  if(data.expecteddate && typeof data.expecteddate != 'string' ) {
      data.expecteddate = new Date(data.expecteddate[0]).toLocaleDateString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  }
  else {
    data.expecteddate = data.expecteddate && new Date(data.expecteddate).toLocaleDateString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  }
  if(data.requireddate && typeof data.requireddate != 'string' ) {
    data.requireddate = new Date(data.requireddate[0]).toLocaleDateString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  }
  else {
    data.requireddate = data.requireddate && new Date(data.requireddate).toLocaleDateString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  }
  if(data.advancepaymentperiod && typeof data.advancepaymentperiod != 'string' ) {
    data.advancepaymentperiod = new Date(data.advancepaymentperiod[0]).toLocaleDateString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  }
  else {
    data.advancepaymentperiod = data.advancepaymentperiod && new Date(data.advancepaymentperiod).toLocaleDateString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  }
  
  if(data.createdat) {
    data.createdat = new Date(data.createdat).toLocaleDateString('en-GB',{ day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  }
  if(data.totalamount) {
      data.totalamount = formatCurrent(data.totalamount)
  }
  data.email = data.createremail
  if(type == '500002' && data.RequestOrderLines) {
    delete data.RequestOrderLines
  }
  if(type == '500003' && data.EmployeeAdvanceRequestLines) {
    delete data.EmployeeAdvanceRequestLines
  }
  if(type == '500004' && data.PaymentRequestLines) {
    delete data.PaymentRequestLines
  }
  if(data.User) {
    delete data.User
  }
  if(data.requireddocuments) {
    delete data.requireddocuments
  }
  if(data.approvalhistory) {
    delete data.approvalhistory
  }
  emailnotification.data = data
  return emailnotification
}
const upsertChangeStatusApproval = async(tableData:any,isCheck:any) => {
  let tempData = [] as any
      tableData.filter((i:any)=>isCheck.includes(i.id)).forEach((item:any)=>{
          let temp = {
              "id": item.approvalhistory?.id,
              "currentstatus": "284210004",
              "open": false,
              "currentstage": -1,
              }
          tempData.push(temp)
      })
      try {
          let urlApi = 'settings/approvalhistories/upsertmulti'
          await ApiService.post(urlApi,tempData)
      } 
      catch (error) { ApiShowError(error) }
}
const upsertAddApprovaldetails = async(tableData: any, isCheck: any) => {
  let tempData = [] as any
      tableData.filter((i:any)=>isCheck.includes(i.id)).forEach((item:any)=>{
          let temp = {
              "statuscode": 1,
              "approvalhistoryid": item.approvalhistory?.id,
              "status": "284210004",
              "comment": 'Đã đóng',
          }
          tempData.push(temp)
      })
      try {
          let urlApi = 'settings/approvaldetails/upsertmulti'
          await ApiService.post(urlApi,tempData)
      } 
      catch (error) { ApiShowError(error) }
}
export const closedRequestApproval = async(intl: any, dataDefault: any, setDataDefault: any, isCheck: any,urlApi?: any, setIsCheck?: any, setIsCheckAll?: any) => {
  popupLoading()
  const question = await popupQuestion({description: intl.formatMessage({ id: `text.do-you-want-to-close-these-proposals?` as any })+` (${isCheck.length})`})
  if(question) {
      let tempData = [] as any
      isCheck.forEach((id:any)=>{
          let temp = {
              "id": id,
              "status": "284210004",
          }
          tempData.push(temp)
      })
      await upsertChangeStatusApproval(dataDefault,isCheck)
      await upsertAddApprovaldetails(dataDefault,isCheck)
      try {
          await ApiService.post(urlApi,tempData)
          popupMessage({ icon: 'success', autoClose: true })
          const grid = dataDefault.map((item:any)=>{
              if(isCheck.includes(item.id)) {
                  item.status = "284210004"
                  item.statusLocal = "284210004"
              }
              return item
          })
          setDataDefault(grid)
         
          popupMessage({ icon: 'success', autoClose: true })
      } 
      catch (error) { ApiShowError(error) }
      setIsCheck([])
      setIsCheckAll(false)
  }
}
export const approvalusersetupsByQuery = async(setRequestUser: any,setApprovalUser: any, setApprovalLevels: any, id: any, dataQuery: any) => {
  try {
    let tempLevel = null
    let {data} = await ApiService.get(`settings/approvalusersetups?userid=${id}`)
    if(data && data.length!= 0) {
      // console.log('data',data[0])
      const createdby = dataQuery?.createdby || id // new => createdby== id
      let temp = data
      temp = data.find((item:any)=>item.userid == id)
      if(temp && id == createdby) {
        temp.isUserId = true
        setRequestUser(temp)
        // console.log('tempLevel',formatApprovalLevel(temp))
        tempLevel = formatApprovalLevel(temp)
        setApprovalLevels(tempLevel)
      }
      else {
        temp = data.find((item:any)=>item.submittoid == id)
        if(temp) {
          temp.isApproved = true
          temp.isSubmitto = true
        }
        else {
          temp = data.find((item:any)=>item.forwardtoid == id)
          if(temp) {
            temp.isForward = true
            temp.isApproved = true
          }
        }
        setApprovalUser(temp)
        tempLevel = formatApprovalLevel(temp)
        setApprovalLevels(tempLevel)
      }
      return tempLevel
    }
  } catch (error) { ApiShowError(error) }
}
export const historiesApproval = (approvalhistories: any, intl: any) => {
  return <div className="offcanvas offcanvas-end w-400px" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div className="offcanvas-header pb-0">
      <h5 className="offcanvas-title" id="offcanvasExampleLabel">{intl.formatMessage({ id: `text.approval-history` as any })}</h5>
      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body">
  {approvalhistories && 
    <div className="card">
      {/* <div className='card-header card-header-stretch'>
          <div className='card-title d-flex align-items-center'>
            <h5 className="fw-bolder m-0 text-gray-800">Lịch sử phê duyệt</h5>
          </div>
      </div> */}
      <div className="card-body">
        <div className='timeline'>
          {sortWithDate(approvalhistories?.ApprovalDetails||[],{sort:'createdat',order:'desc'})?.map((item:any, index: number) => {
            return <div className='timeline-item' key={index}>
              <div className='timeline-line w-40px'></div>
              <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                <div className={clsx(`symbol-label bg-${LocalData.statusData[item.status]?.badge}`)}>
                  <KTSVG path={`/media/icons/${item.status == '284210000' ? 'duotune/general/gen016' : 
                    item.status == '284210001' ? 'duotune/general/gen070' : 
                    item.status == '284210002' ? index == 0 ? 'duotune/arrows/arr084' : 'custom/like' : 
                    item.status == '284210003' ? 'custom/denied' : 'duotune/arrows/arr015'}.svg`} 
                    
                    className={clsx('svg-icon svg-icon-2',{
                    'svg-icon-primary': item.status =='284210000',
                    'svg-icon-warning': item.status =='284210001',
                    'svg-icon-success svg-icon-2x': item.status =='284210002',
                    'svg-icon-danger svg-icon-2x': item.status =='284210003',
                    'svg-icon-info': item.status =='284210004',
                  })} />
                </div>
              </div>
              <div className='timeline-content mb-3 mt-n1'>
                <div className="pe-3 mb-5">
                  <div className="">
                  {(item.status == '284210000') && item.comment}
                  {item.status == '284210001'&& <div>{intl.formatMessage({ id: `text.waitingfor` as any })} <strong className='text-primary fs-6'>{item.approvername}</strong> {intl.formatMessage({ id: `text.toapprove` as any })}</div>}
                  {(item.status == '284210002' && index != 0 )&& <div>{intl.formatMessage({ id: `text.approved` as any })}</div>}
                  {((item.status == '284210002' && index == 0 )) && <strong>{intl.formatMessage({ id: `text.approved` as any })}</strong>}
                  {item.status == '284210003'&& <div>{intl.formatMessage({ id: `text.denied` as any })}</div>}
                  {item.status == '284210004' && <strong>{item.comment}</strong>}
                  </div>
                  <div className="d-flex align-items-center mt-1">
                    <div className="text-muted me-2">
                    {intl.formatMessage({ id: `text.day` as any })} {new Date(item.createdat).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', })}
                      {item.status != '284210001' && 
                        <span> {intl.formatMessage({ id: `text.by` as any })} <strong className='text-primary fs-6'>{!item.approverid && (item.status == '284210002' || item.status == '284210003') ?intl.formatMessage({ id: `text.system` as any }): item.creater}</strong></span>
                      }
                    </div>
                  </div>
                  <div className="">
                  {
                    (item.status == '284210003' || (item.status == '284210002' && index != 0 )) && item.comment
                  }
                  </div>
                </div>
              </div>
            </div>
          })}
        
        </div>
      </div>
    </div>
    }
  </div>
</div>
}
/**

 * Format Bytes size to 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' size

 */

export function formatBytes(bytes:any, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]

}
export const getOptions = async(urlApi: any) => {
  let {data} = await ApiService.get(urlApi)
  let formatOption = data.map((e: any) => ({ label: e.displayname, value: e.id, title: e.displayname, description: e.code, ...e })) as any
  return formatOption
}

export const setValueGrid = (data: any, tableData: any, setTableData: any) => {
  const {props} = data
  const {row,col} = props
  const grid = tableData.map((row:any) => [...row]) as any
  let className = grid[row][col].className||''
  let classError = 'form-error'
  let value = data.label
  if(!value && col != 7) {
    className = className.replace(classError,'')+' '+ classError
  }
  else {
    className = className.replace(classError,'')
  }
  grid[row][col] = { ...grid[row][col], value,className };

  // grid[row][col].value = data.label//{value: data.label, className: 'text-center',dataEditor: props.cell.dataEditor, options: props.cell.optionsData}
  setTableData(grid)
}
export const deleteDefault = ['id','createdby','createdat','creater','modifiedat','modifiedby','modifier']
export const deleteFields = (data: any, fields: any) => {
  fields?.forEach((i:any) =>{
    delete data[i]
  })
  return data
}
const getDetailLine = async(urlApi: any) => {
  let dataLine = [] as any
  try {
    let {data} = await ApiService.get(urlApi)
    // console.log('data',data)
    dataLine = data
  } 
  catch (error) { ApiShowError(error) }
  return dataLine
}
export const cloneRecord = async(props: any) => {
  let  {item,arrObject,dataDefault,setDataDefault,urlApi,fields,lineDetails,type,user,apiGetLine,tab,reFetchtab} = props
  let tempData = JSON.parse(JSON.stringify(item))
  fields = fields.concat(deleteDefault)
  tempData[lineDetails] = await getDetailLine(apiGetLine)
  tempData = deleteFields(tempData,fields)
  tempData[lineDetails]?.forEach((i:any)=> {
      i = deleteFields(i,deleteDefault)
  })
  tempData.status = "284210000"
  if(type == 'advance') {
    tempData.remainingadvancedamount = tempData.totalamount
  }
  let otherinfo = {
    deptid: user?.departmentid,
    deptcode: user?.departmentcode,
    deptname: user?.departmentname,
  }
  tempData.otherinfo = otherinfo
  let nosericode = arrObject?.nosericode ? `/?nosericode=${arrObject?.nosericode}` : ''
  try {
      let {data} = await ApiService.post(`${urlApi}${nosericode}`, tempData)
      popupMessage({ icon: 'success', autoClose: true })
      let {selectedLang} = getLocalStorage('siteConfig') as any
      selectedLang = selectedLang||'vi'
      data.statusLocal = data.status
      if(data.otherinfo?.deptid) {
        data.deptid = data.otherinfo?.deptid
      }
      if(data.documenttype && LocalData.venderType[data.documenttype]) {
        data.type_document = LocalData.venderType[data.documenttype]['label'+selectedLang]
      }
      let grid = dataDefault.map((i:any) => i)
      reFetchtab()
      if(tab != 'forme') {
        grid.unshift(data)
        setDataDefault(grid)
      }
      
      
  } 
  catch (error) { ApiShowError(error) }
} 
export const printPdf = async(dataTemp: any,ApiCustom: any,) => {
  //14/10/2023
  window.open(dataTemp?.pagepath, '_blank');
  //end update
  return
  popupLoading()
  try {
    const {data} = await ApiCustom.post(`utilities/printpdf`,dataTemp)
    window.open(data.filepath, '_blank');
    popupMessage({ icon: 'success', autoClose: true })
  } 
  catch (error) { ApiShowError(error) }
}
export const callApprovalHistory = async(item: any, setApprovalhistories: any) => {
  try {
    const {data} = await ApiService.get(`settings/approvalhistories/?documentid=${item.id}&expand=approvaldetails`)
    setApprovalhistories(data[0])
  } 
  catch (error) { ApiShowError(error) }
}
export const checkApprovalDeptHead = (approvallevels: any) => {
  // 1: not set dept for user // 2: not set dept head // 3: continue 
  // console.log('approvallevels',approvallevels)
  let recordHead = approvallevels?.find((i: any) => i.type == 'department_head')
  if(recordHead) {
    return !recordHead.departmentid ? 1: recordHead.departmenthead?.departmentheadid ? 3: 2
  }
  return 3
}
export const getInforGroup = async(id: any) => {
  if(!id) return []
  const {data} = await ApiService.get(`settings/usergroupmembers/?userid=${id}`)
  return data
}
export const templateCell = (props: any) => {
  const {label, subTitle} = props
  return (
    <div className='text-start w-100'>
      <div className='one-row-ellipsis' title={label}>{label}</div>
      <div className='d-block text-gray-700'>
        {subTitle}
      </div>
    </div>
  )
}
export const customFilter = (option: any, searchText: any) => {
  if (
    option.data.label?.toLowerCase().includes(searchText.toLowerCase()) ||
    option.data.subTitle?.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
};
export const getInforUser = async(id: string) => {
  let {data} = await ApiService.get(`settings/approvalusersetups/?vendorid=${id}`)
  return data
}
export const callRenderLine = async(urlApi: string, id: string,setDetailLine: any,detailLine: any,requestid: any,setLoadingLine: any) => {
  // let checkShow = document.querySelector('.accordion-collapse.collapse.show')
  // console.log('checkShow',checkShow)
  // if(!checkShow) {
    if(detailLine?.find((i: any) => i[requestid] == id)) {
      return detailLine
    }
    else {
      setDetailLine([])
      setLoadingLine(true)
      try {
        let {data} = await ApiService.get(urlApi + `${id}`)
        setDetailLine(data)
        setLoadingLine(false)
      } 
      catch (error) { ApiShowError(error) }
    }
  // }
}