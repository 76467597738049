import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../helpers'

type Props = {
  className?: string
  path: string
  svgClassName?: string
}

const KTSVG: React.FC<Props> = ({ className = '', path, svgClassName = 'mh-50px' }) => {
  const isCustom = path.split('/media/icons/custom').length == 2

  return (
    <span className={`svg-icon ${className} ${isCustom ? 'svg-custom' : ''}`} >
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  )
}

export { KTSVG }
