import clsx from "clsx"
import { FC, ReactNode } from "react"

interface IStyle {
    isBLockLabel?: boolean
    isHeight?: boolean
    className?: string
    labelWidth?: string
    isRequired?: boolean
    labelClassName?: string
}

interface IKTFormItem extends IStyle {
    title: string | ReactNode
    children?: string | ReactNode
}

export const KTItem: FC<IKTFormItem> = ({ title, children, isBLockLabel, className, isHeight, labelWidth, isRequired, labelClassName }) => {
    const labelWidthCus = (labelWidth ? labelWidth : '130') + 'px'
    const style = !isHeight && !isBLockLabel ? { minHeight: '23px', maxHeight: '25px' } : undefined

    return <div
        style={style}
        className={clsx(
            `fv-row align-items-${isHeight ? 'start' : 'center'}`,
            !isBLockLabel && 'd-flex',
            className != undefined ? className : 'mb-2'
        )}>
        <div
            className={clsx(
                " mb-0 me-6 text-black label-item",
                labelClassName,
                isBLockLabel ? 'me-auto text-nowrap pe-2' : '',
                isRequired && 'required'
            )}
            style={{
                minWidth: !isBLockLabel ? labelWidthCus : 'auto',
                maxWidth: !isBLockLabel ? labelWidthCus : '100%'
            }}>
            {title}
        </div>
        {children}
    </div>
}