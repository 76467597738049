import { useEffect, useState } from "react"
import { Column } from "react-table"
import { ApiService, sortWithAny } from "../../../../../theme/helpers"
import { ContentHeader } from "../../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, FilterDropdown, KTButton, KTTable, RowIcon } from "../../../../../theme/partials"
import { IInitField } from "../../../api"
import { apiAd } from "../../../api/admin"
import { popupMessage } from "../../../messages"

export interface IAdUserGroup extends IInitField {
    code: string | null
    name: string | null
    defaultprofiles: string | null
    assigntoallnewusers: boolean
    memberamount?: number
}

const breadCrumbs = [{ title: 'system', path: '/system-settings', }]
const apiurl = 'settings/usergroups'
export const AdUserGroupsPage = () => {
    const { data, isFetching, isSuccess } = apiAd.useUserGroups()
    const [tableData, setTableData] = useState<IAdUserGroup[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setTableData(sortWithAny(data, { sort: "modifiedat" }))
        }
    }, [isFetching])

    const columns: Array<Column<any>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].code} to={props.data[props.row.index].id} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.members' className="text-center"/>,
        accessor: 'memberamount',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].memberamount} className="text-center"/>,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end min-w-100px' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" to={props.data[props.row.index].id} />
            <RowIcon action="delete" disabled onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <div className="position-relative">
        <ContentHeader title="system.user-groups" items={breadCrumbs} />
        <KTTable
            isLoading={isFetching}
            toolbar={<>
                <FilterDropdown>
                </FilterDropdown>
                <KTButton action='new' />
            </>}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search
        />
    </div>
}