import clsx from "clsx";
import React, { ReactNode } from "react";
interface ICheckboxProps {
    className?: string
    children?: ReactNode
    type?: 'checkbox'
}

export const InputCheckBox = React.forwardRef<HTMLInputElement, ICheckboxProps>((props, ref) => {
    const classw = 'form-check form-check-sm form-check-custom'
    const classs = 'form-check form-switch form-check-custom'
    const { className, children, type, ...rest } = props
    return type
        ? <label className={clsx(className || classw)}>
            <input ref={ref} className="form-check-input" type="checkbox" {...rest} />
            {children}
        </label>
        : <label className={clsx(className || classs)}>
            <input ref={ref} className="form-check-input" type="checkbox" {...rest} />
            {children}
        </label>
});
