import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Column } from "react-table"
import { ApiService, sortByUpdateAt } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { CustomHeader, BasicCell, RowIcon, KTButton, KTTable, FilterDropdown, KTFormItem } from "../../../../theme/partials"
import { IInitField } from "../../../modules/api"
import { apiFnb } from "../../../modules/api/fnb"
import { popupMessage } from "../../../modules/messages"
import { useIntl } from "react-intl"

export interface IFnbMenuStructure extends IInitField {
    code: string | null
    displaylocation: string | null
    name: string | null
    active: boolean
    ismerchantdisplay: boolean
}

export const optionDisplayLocation = [
    { value: 'primary-menu', label: 'Primary Menu' },
    { value: 'secondary-menu', label: 'Secondary Menu' },
]

export function FNBMenuStructures(props: any) {
    const {permission} = props
    const intl = useIntl()
    const urlApi = 'fnb/menustructures'
    const [tableData, setTableData] = useState<IFnbMenuStructure[]>([])
    const { data: dataQuery, isSuccess, isFetching, refetch } = apiFnb.useMenuStructures()

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(dataQuery))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='ID' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.display-location' />,
        accessor: 'displaylocation',
        Cell: ({ ...props }) => {
            let displaylocation = props.data[props.row.index].displaylocation
            if(!displaylocation) return null
            displaylocation = optionDisplayLocation.find((item:any)=>item.value == displaylocation)
            return <div>
                {intl.formatMessage({ id: `text.${displaylocation.value}` as any })}
            </div>
        },
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.merchant' />,
        accessor: 'ismerchantdisplay',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].ismerchantdisplay} />,
    },{
        Header: (props) => <CustomHeader tableProps={props} title='text.request.name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.active' />,
        accessor: 'active',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].active} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" to={props.data[props.row.index].id} />
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [filter, setFilter] = useState<IFnbMenuStructure | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: IFnbMenuStructure[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                f.active == filter.active,
            ]
            return checks.every(val => val == true)
        })
    }

    const toolbar = <>
        <FilterDropdown
            handleSubmit={handleSubmit}
            onClose={() => {
                setFilter(undefined)
                reset({})
            }}
            onSubmit={(data) => setFilter(data)}>
            <KTFormItem title='text.status' labelWidth="auto">
                <label className="form-check form-switch form-check-custom">
                    <input className="form-check-input" type="checkbox" {...register("active")} />
                </label>
            </KTFormItem>
        </FilterDropdown>
        <KTButton action="new" />
    </>

    return <>
        <ContentHeader title={'sidebar.fnb.menu-structures'} elements={toolbar} />
        <KTTable
            isLoading={isFetching}
            data={onFilter(tableData)}
            columns={columns}
            search
            pagination
            cardInnner
        />
    </>
}
