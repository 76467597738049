import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import { ApiService, ApiShowError } from "../../../theme/helpers"
import { ContentHeader } from "../../../theme/layout/components/content"
import { pluginsInitialization } from "../../../theme/layout/MasterInit"
import { InputSelect, InputSingleImage, yup } from "../../../theme/partials"
import { MergedProps } from "../../router/AppRoutes"
import { optionAddress } from "../api/address"
import { apiAd } from "../api/admin"
import { useAuth } from "../auth"
import { getIsPermission } from "../auth/Permission"
import { popupMessage } from "../messages"
import { IAdUser, resetPassword } from "../settings/components/users"

export const AccountProfilePage = ({ permission }: MergedProps) => {
    const navigate = useNavigate()
    const isEdit = getIsPermission([permission as string, 'MODIFY'])
    const { currentUser, auth, saveAuth, logout } = useAuth()
    const { register, watch, control, reset, handleSubmit, formState: { errors } } = useForm<IAdUser>({
        resolver: yupResolver(yup.object().shape({
            contactemail: yup.string().email().required(),
        }))
    })
    const { data, isSuccess, isFetching, refetch } = apiAd.useUsersByQuery(currentUser?.id ? `/${currentUser.id}?select=avatar` : undefined)

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    async function onSubmit(data: IAdUser) {
        try {
            const reuslt = await ApiService.put(`settings/users/${data.id}`, data)
            saveAuth({ ...auth, user: { ...auth?.user, ...reuslt.data } })
            popupMessage({ icon: "success", autoClose: true })
            refetch()
            pluginsInitialization()
        } catch (error) {
            ApiShowError(error)
        }
    }

    async function resetPass() {
        const isSuccess = await resetPassword(data)
        isSuccess && logout(true)
    }

    return <>
        <ContentHeader title={""} />
        <form className="card card-custom" onSubmit={handleSubmit(onSubmit)}>
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label fw-bolder text-dark"><FormattedMessage id="text.account-information" /></h3>
                    <span className="text-muted fs-7 mt-1"><FormattedMessage id="text.account-information.more" /></span>
                </div>
                <div className="card-toolbar gap-2">
                    <button type="button" className="btn btn-sm btn-secondary" onClick={() => navigate(-1)}>
                        <FormattedMessage id="text.cancel" />
                    </button>
                    <button type="submit" disabled={!isEdit} className="btn btn-sm btn-success">
                        <FormattedMessage id="text.save" />
                    </button>
                </div>
            </div>
            <div className="card-body">
                <h5 className="fw-bold mb-6"><FormattedMessage id="text.account" />:</h5>
                <div className="form-group row mb-4">
                    <label className="col-xl-3 col-lg-3 col-form-label"><FormattedMessage id="text.avatar" /></label>
                    <div className="col-lg-9 col-xl-6">
                        <Controller
                            name="avatar"
                            control={control}
                            render={({ field: { value, onChange } }) => <InputSingleImage
                                value={value}
                                onChange={onChange} />}
                        />
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <label className="col-xl-3 col-lg-3 col-form-label"><FormattedMessage id="text.username" /></label>
                    <div className="col-lg-9 col-xl-6">
                        <div className="spinner spinner-sm spinner-success spinner-right">
                            <input className="form-control form-control-sm" value={watch("username") || ""} disabled />
                        </div>
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <label className="col-xl-3 col-lg-3 col-form-label"><FormattedMessage id="text.contact.email" /></label>
                    <div className="col-lg-9 col-xl-6">
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.contactemail })} {...register('contactemail')} />
                    </div>
                </div>
                <div className="form-group row mb-4 align-items-center">
                    <label className="col-xl-3 col-lg-3 col-form-label"><FormattedMessage id="text.communication" /></label>
                    <div className="col-lg-9 col-xl-6">
                        <div className="checkbox-inline d-flex gap-6">
                            <label className="form-check form-check-sm form-check-custom form-check-solid gap-2">
                                <input className="form-check-input" type="checkbox" {...register('communication')} value={'email'} />
                                <FormattedMessage id="text.email" />
                            </label>
                            <label className="form-check form-check-sm form-check-custom form-check-solid gap-2">
                                <input className="form-check-input" type="checkbox" {...register('communication')} value={'sms'} />
                                <FormattedMessage id="text.sms" />
                            </label>
                            <label className="form-check form-check-sm form-check-custom form-check-solid gap-2">
                                <input className="form-check-input" type="checkbox" {...register('communication')} value={'phone'} />
                                <FormattedMessage id="text.phone" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <label className="col-xl-3 col-lg-3 col-form-label"><FormattedMessage id="text.timezone" /></label>
                    <div className="col-lg-9 col-xl-6">
                        <Controller
                            name="timezone"
                            control={control}
                            render={({ field: { value, onChange } }) => <InputSelect
                                options={optionAddress.timeZone}
                                value={optionAddress.timeZone.find(f => f.value == value) || null}
                                onChange={(e) => {
                                    if (!e) return onChange(null)
                                    onChange(e.value)
                                }}
                            />}
                        />
                    </div>
                </div>

                <div className="separator separator-dashed my-5"></div>
                <h5 className="fw-bold mb-6"><FormattedMessage id="text.security" />:</h5>
                <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label"><FormattedMessage id="text.password-reset" /></label>
                    <div className="col-lg-9 col-xl-6">
                        <button type="button" className="btn btn-light-primary fw-bold btn-sm" onClick={resetPass}><FormattedMessage id="text.reset" /></button>
                        <p className="form-text text-muted pt-2">
                            <FormattedMessage id="text.password-reset.more" />
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </>
}
