import clsx from "clsx"
import { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { InputSelect } from "../form/InputSelect"

interface PaginationCustomProps {
    className?: string
    pageSize: number
    page: number
    totalData: number
    gotoPage: (pageIndex: number) => void
    setPageSize: (pageSize: number) => void
    setPageOption?: (e: { page: number; limit: number }) => void
    isHideSelectPage?: any
}

export const PaginationCustom: React.FC<PaginationCustomProps> = ({ className, page, gotoPage, setPageSize, pageSize, totalData, setPageOption, isHideSelectPage}) => {
    const intl = useIntl()
    const pages = useMemo(() => totalData < pageSize ? 1 : Math.ceil(totalData / pageSize), [totalData, pageSize])

    const options = [
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
        { label: '200', value: 200 },
    ]
    const [visiblePages, setVisiblePages] = useState<number[]>([])
    const [pageLimit, setPageLimit] = useState(options.filter((e) => e.value == pageSize))

    useEffect(() => {
        setVisiblePages(getVisiblePages(page, pages))
    }, [pages])

    const filterPages = (visiblePages: any[], totalPages: number) => {
        return visiblePages.filter((page) => page <= totalPages)
    }

    const getVisiblePages = (page: number, total: number) => {
        if (total < 7) {
            return filterPages([1, 2, 3, 4, 5, 6], total)
        } else {
            if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                return [1, page - 1, page, page + 1, page + 2, total]
            } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                return [1, total - 4, total - 3, total - 2, total - 1, total]
            } else {
                return [1, 2, 3, 4, 5, 6, total]
            }
        }
    }

    const changePage = (page: number) => {
        const activePage = page + 1
        if (page === activePage) {
            return
        }
        const visiblePages = getVisiblePages(page, pages)
        setVisiblePages(filterPages(visiblePages, pages))
        gotoPage(page - 1)
        setPageOption && setPageOption({ page: page - 1, limit: pageSize })
    }

    return <div className={clsx('row z-index-99', className)}>
        {
            !isHideSelectPage && <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
            {totalData > 10 && (<InputSelect
                className="w-auto"
                value={pageLimit}
                options={options}
                onChange={(e: any) => {
                    setPageSize(e.value)
                    setPageOption && setPageOption({ page: page, limit: e.value })
                    setPageLimit(e)
                }}
            />)}
            <span className='ms-2'>
                {intl.formatMessage({ id: "table.page-size" }, { 
                    count: `
                    ${totalData != 0 ? ((pageSize * (page + 1)) - pageSize) + 1 : 0}
                     - 
                    ${totalData > (pageSize * (page + 1))
                        ? (pageSize * (page + 1))
                        : totalData}
                    `,
                    totalcount: totalData
                 })}
            </span>
        </div>
        }
        
        {totalData > 10 && pages > 1 && (
            <div className={clsx(`col-sm-12 col-md-${isHideSelectPage?'12':'7'} d-flex align-items-center justify-content-center justify-content-md-end`,)}>
                <ul className='pagination'>
                    <li id='kt_roles_view_table_previous'
                        className={clsx(
                            'paginate_button page-item', {
                            'disabled': page == 0
                        })}>
                        <span className='page-link cursor-pointer' onClick={() => changePage(page)}>
                            <i className='previous'></i>
                        </span>
                    </li>
                    {visiblePages.map((pageNumber: number, index: number, array: number[]) => (
                        <li key={index}
                            className={clsx(
                                'paginate_button page-item',
                                page + 1 === pageNumber && 'active'
                            )}>
                            <span className='page-link cursor-pointer' onClick={() => changePage(pageNumber)}>
                                <span>
                                    {array[index - 1] + 2 < pageNumber ? `... ${pageNumber}` : pageNumber}
                                </span>
                            </span>
                        </li>
                    ))}
                    <li className={clsx('paginate_button page-item ',`${page}-${pages}`, {
                        'disabled': page + 1 >= pages
                    })}>
                        <span className='page-link cursor-pointer' onClick={() => changePage(page + 2)}>
                            <i className='next'></i>
                        </span>
                    </li>
                </ul>
            </div>
        )}
    </div>
}