import clsx from "clsx";
import React from "react";
import { ReactNode, FC, useRef } from "react"
import { Dropdown } from "react-bootstrap"
import { UseFormHandleSubmit } from "react-hook-form";
import { FormattedMessage } from "react-intl";

interface IFilterDropdown {
    btnRef?: React.MutableRefObject<HTMLButtonElement | null>
    children?: ReactNode
    onClose?: () => void
    onSubmit?: (data: any) => void
    handleSubmit?: UseFormHandleSubmit<any>
    width?: string
    filterValue?: any
    reset?: any
}

export const FilterDropdown: FC<IFilterDropdown> = ({ handleSubmit, children, onClose, onSubmit, width, filterValue, reset }) => {
    const btnFilter = useRef<HTMLButtonElement | null>(null)

    function getCountFilter() {
        if (filterValue) {
            return Object.keys(filterValue).map(m => filterValue[m]).filter(f => f != undefined).length
        }
        return 0
    }

    if (!handleSubmit || !onSubmit) return <></>
    return <Dropdown align={{ lg: 'end' }}>
        <Dropdown.Toggle ref={btnFilter} as={CustomToggle} className={getCountFilter() > 0 && onClose ? 'pe-9' : ''}>
            {getCountFilter() > 0 && onClose
                ? <button className="btn btn-sm btn-icon btn-light w-15px h-15px ms-2 position-absolute end-0 top-25 me-2" onClick={(evt) => {
                    evt.preventDefault()
                    onClose && onClose()
                }} >
                    <i className="bi bi-x text-primary" />
                </button>
                : <></>}
        </Dropdown.Toggle>
        <Dropdown.Menu className='min-w-250px p-0' style={{ width: width ? width + 'px' : 'auto' }}>
            <Dropdown.Header as="div" className="d-flex flex-stack align-items-end pt-3">
                <h4 className="m-0"><FormattedMessage id="popup.filter" /></h4>
            </Dropdown.Header>
            <Dropdown.ItemText>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex flex-column">
                        {children}
                    </div>
                    <div className="d-flex justify-content-end">
                        {onClose && <button
                            type="button"
                            className="btn btn-sm btn-secondary me-2"
                            onClick={() => {
                                btnFilter.current?.click()
                                onClose()
                            }}
                        >
                            <FormattedMessage id="text.reset" />
                        </button>}
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary"
                            onClick={() => btnFilter.current?.click()}
                        > <FormattedMessage id="text.apply" /></button>
                    </div>
                </form>
            </Dropdown.ItemText>
        </Dropdown.Menu>
    </Dropdown>
}

const CustomToggle = React.forwardRef<any>(({ children, onClick, className, reset, filterValue }: any, ref) => (
    <div className="position-relative">
        <button
            ref={ref}
            className={clsx("btn btn-sm btn-secondary fw-bolder d-none-after d-flex align-items-center", className)}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <i className="bi bi-funnel-fill" /> <FormattedMessage id="text.filter" />
        </button>
        {children}
    </div>
));