import { yupResolver } from "@hookform/resolvers/yup"
import { FC, useEffect, useRef, useState } from "react"
import { Modal } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { Column } from "react-table"
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId, sortByUpdateAt, sortWithAny } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, FilterDropdown, FormSubmit, InputSelect, KTButton, KTFormItem, RowIcon, TextFormByUser, yup } from "../../../../theme/partials"
import { KTTable } from "../../../../theme/partials/widgets/table"
import { apiAd } from "../../api/admin"
import { IInitField } from "../../api/type"
import { getIsRole } from "../../auth/Permission"
import { popupMessage } from "../../messages"

export interface IAdObject extends IInitField {
    objecttype: number | null
    objectid: string | null
    objectname: string | null
    objectcaption: string | null
    noseriid: string | null
    nosericode: string | null
    allowcustomfield: boolean
    issuperrole: boolean
}

export const optionObjectType = [
    { value: 20120000, label: <FormattedMessage id="text.page" /> },
    { value: 20120001, label: <FormattedMessage id="text.table" /> },
    { value: 20120002, label: <FormattedMessage id="text.report" /> },
]

const breadCrumbs = [{ title: 'system', path: '/system-settings', }]
const apiurl = 'settings/objects'
export default function AdObjectsPage() {
    const isSuper = getIsRole(['SUPER'])
    const { data, isSuccess, isFetching } = apiAd.useObjectsByQuery(`?issuperrole=${isSuper}`)
    const [tableData, setTableData] = useState<IAdObject[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortWithAny(data, { sort: 'objectid', order: 'asc' }))
    }, [isFetching])

    const addColum: Column = {
        Header: (props) => <CustomHeader tableProps={props} title='text.object.is-super' className="text-center" />,
        id: 'issuperrole',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].issuperrole} className="d-flex flex-center" />,
    }

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'objectid',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].objectid}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'objectname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].objectname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.type' />,
        accessor: 'objecttype',
        Cell: ({ ...props }) => <BasicCell data={optionObjectType.find(f => f.value == props.data[props.row.index].objecttype)?.label} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.description' />,
        accessor: 'objectcaption',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].objectcaption} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.allow-custom-field' className="text-center" />,
        id: 'allowcustomfield',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].allowcustomfield} className="d-flex flex-center" />,
    },
    ...(isSuper ? [addColum] : []),
    {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" to={props.data[props.row.index].id} />
            <RowIcon action="delete" disabled={!isSuper} onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <ContentHeader title="system.objects" items={breadCrumbs} />
        <KTTable
            isLoading={isFetching}
            toolbar={<>
                <FilterDropdown>
                </FilterDropdown>
                <KTButton action='new' />
            </>}
            data={tableData}
            columns={isSuper ? [...columns] : columns}
            cardInnner
            pagination
            search />
    </>
}

const defaultValues: IAdObject = {
    allowcustomfield: false,
    issuperrole: false,
    objecttype: null,
    objectid: null,
    objectname: null,
    objectcaption: null,
    noseriid: null,
    nosericode: null
}

export const AdObjectsDetailPage = () => {
    const isSuper = getIsRole(['SUPER'])
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching, refetch } = apiAd.useObjectsId<IAdObject>(valueid)
    const { register, reset, control, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdObject>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            objectid: yup.number().required(),
            objecttype: yup.string().required(),
            objectname: yup.string().required(),
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    const { data: noseriesData, isSuccess: isNoseries } = apiAd.useNoSeries()
    const optionNoseries = isNoseries ? formatOptions(noseriesData, ['id', 'code']) : []

    const btnSumit = useRef<HTMLButtonElement | null>(null)
    async function onSubmit(data: IAdObject) {
        try {
            const method = isEdit ? 'put' : 'post'
            await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
            popupMessage({ icon: 'success', autoClose: true })
            navigate('/system-settings/objects')
        } catch (error) { ApiShowError(error) }
    }

    const [tabid, setTabid] = useState<string>('general')
    const [valueData, setValueData] = useState<IAdObjectField | undefined>()

    return <>
        <ContentHeader title="system.objects" items={[...breadCrumbs, { title: 'system.objects', path: '/system-settings/objects' }]} />
        <div className="card" >
            <div className="card-header py-0">
                <h3 className="card-title my-0">
                    <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x text-dark h-100 border-0 gap-1">
                        <li className="nav-item">
                            <a className="nav-link h-100 d-flex flex-center m-0 px-2 active" data-bs-toggle="tab" href="#tab-general" onClick={() => setTabid('general')}>
                                <FormattedMessage id="text.general" />
                            </a>
                        </li>
                        {data && data.allowcustomfield && <li className="nav-item">
                            <a className="nav-link h-100 d-flex flex-center m-0 px-2" data-bs-toggle="tab" href="#tab-fields" onClick={() => setTabid('fields')}>
                                <FormattedMessage id="text.fields" />
                            </a>
                        </li>}
                    </ul>
                </h3>
                <div className="card-toolbar">
                    {tabid == 'general' && <>
                        <TextFormByUser data={watch()} />
                        <FormSubmit
                            type={isEdit}
                            isSubmitting={isSubmitting}
                            handleSubmit={() => btnSumit.current?.click()}
                            handleClose={() => navigate('/system-settings/objects')} />
                    </>}
                    {tabid == 'fields' && <KTButton action='new' disabled={!isEdit} onClick={() => {
                        data && data.id && setValueData({
                            objectid: data.id,
                            objectcode: data.objectid,
                            objectname: data.objectname,
                            labelname: null,
                            labelnamesystem: null,
                            defaultvalue: null,
                            status: false
                        })
                    }} />}
                </div>
            </div>
            <div className="card-body" >
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="tab-general" role="tabpanel" >
                        <form className="row" onSubmit={handleSubmit(onSubmit)}>
                            <button ref={btnSumit} type="submit" className="d-none"></button>
                            <div className="col-12 col-lg-6">
                                <KTFormItem title='text.id'>
                                    <input disabled={isEdit} className={`form-control form-control-sm ${errors.objectid && 'form-error'}`} {...register('objectid')} type="number" />
                                </KTFormItem>
                                <KTFormItem title='text.name'>
                                    <input className={`form-control form-control-sm ${errors.objectname && 'form-error'}`} {...register('objectname')} />
                                </KTFormItem>
                                <KTFormItem title='text.type'>
                                    <Controller
                                        name="objecttype"
                                        control={control}
                                        render={({ field: { value, onChange } }) => <InputSelect
                                            disabled={isEdit}
                                            options={optionObjectType}
                                            value={optionObjectType.find(f => f.value == value) || null}
                                            onChange={(e) => {
                                                if (!e) return onChange(null)
                                                onChange(e.value)
                                            }}
                                            className={`w-100 ${errors.objecttype && 'form-error'}`}
                                        />}
                                    />
                                </KTFormItem>
                            </div>
                            <div className="col-12 col-lg-6">
                                {isSuper && <KTFormItem title='text.is-super-role'>
                                    <label className="form-check form-switch form-check-custom form-check-solid gap-2">
                                        <input className="form-check-input" type="checkbox" {...register("issuperrole")} />
                                    </label>
                                </KTFormItem>}
                                <KTFormItem title='text.allow-custom-field'>
                                    <label className="form-check form-switch form-check-custom form-check-solid gap-2">
                                        <input className="form-check-input" type="checkbox" {...register("allowcustomfield")} />
                                    </label>
                                </KTFormItem>
                                <KTFormItem title='text.no-seri'>
                                    <Controller
                                        name="noseriid"
                                        control={control}
                                        render={({ field: { value, onChange } }) => <InputSelect
                                            isClearable
                                            options={optionNoseries}
                                            value={optionNoseries.find(f => f.value == value) || null}
                                            onChange={(e) => {
                                                setValue("nosericode", null)
                                                if (!e) return onChange(null)
                                                onChange(e.value)
                                                setValue("nosericode", e.code)
                                            }}
                                            className={`w-100 ${errors.noseriid && 'form-error'}`}
                                        />}
                                    />
                                </KTFormItem>
                            </div>
                            <div className="col-12">
                                <KTFormItem title='text.description' isHeight>
                                    <textarea className={`form-control form-control-sm ${errors.objectcaption && 'form-error'}`} {...register('objectcaption')} rows={2} />
                                </KTFormItem>
                            </div>
                        </form>
                    </div>
                    <div className="tab-pane fade" id="tab-fields" role="tabpanel">
                        {data && <AdObjectFieldsCard {...{
                            objectData: data,
                            valueData,
                            setValueData
                        }} />}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export interface IAdObjectField extends IInitField {
    objectid: string | null
    objectcode: string | null
    objectname: string | null
    labelname: string | null
    labelnamesystem: string | null
    datatype?: string
    defaultvalue: string | null
    status: boolean
}

interface IAdObjectFieldsCard {
    objectData: IAdObject
    valueData: IAdObjectField | undefined
    setValueData: React.Dispatch<React.SetStateAction<IAdObjectField | undefined>>
}

const AdObjectFieldsCard: FC<IAdObjectFieldsCard> = ({ objectData, valueData, setValueData }) => {
    const apiurl = 'settings/objectcustomfields'
    const { data, isSuccess, isFetching } = apiAd.useObjectCustomFieldsByObjectId(objectData.id)
    const [tableData, setTableData] = useState<IAdObjectField[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.label.name-system' />,
        accessor: 'labelnamesystem',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => setValueData(props.data[props.row.index])}
            data={props.data[props.row.index].labelnamesystem}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.label.name' />,
        accessor: 'labelname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].labelname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.data-type' />,
        accessor: 'datatype',
        Cell: ({ ...props }) => props.data[props.row.index].datatype
            ? <span className="badge badge-light-primary">{props.data[props.row.index].datatype.toUpperCase()}</span>
            : <></>
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.status' className="text-center" />,
        accessor: 'status',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].status} className="d-flex flex-center" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" onClick={() => setValueData(props.data[props.row.index])} />
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <KTTable 
            isLoading={isFetching} 
            data={tableData} 
            columns={columns} 
            pagination />
        {valueData && <AdObjectFieldsModal {...{
            tableData,
            valueData,
            handleClose() {
                setValueData(undefined)
            },
            handleSave(type, value) {
                if (type) return setTableData(pre => sortByUpdateAt(pre.map(item => item.id == value.id ? value : item)))
                setTableData(pre => [value, ...pre])
            },
        }} />}
    </>
}

interface IAdObjectFieldsModal {
    tableData: IAdObjectField[]
    valueData: IAdObjectField
    handleSave: (type: boolean, value: IAdObjectField) => void
    handleClose: () => void
}

const AdObjectFieldsModal: FC<IAdObjectFieldsModal> = ({ tableData, valueData, handleSave, handleClose }) => {
    const urlApi = 'settings/objectcustomfields'
    const { valueid, isEdit } = getValueId(valueData.id)
    const { register, control, reset, watch, handleSubmit, setValue, formState: { errors, isSubmitting } } = useForm<IAdObjectField>({
        resolver: yupResolver(yup.object().shape({
            objectid: yup.string().required(),
            objectcode: yup.number().required(),
            objectname: yup.string().required(),
            labelname: yup.string().required(),
            labelnamesystem: yup.string().required(),
        })),
    })

    useEffect(() => {
        if (valueData)
            reset(valueData)
    }, [valueData])

    const { data: fieldsData, isSuccess: isFields } = apiAd.useListFieldsOfObject(valueData.objectname ? valueData.objectname : undefined)
    const objectIds = [...tableData.map(m => m.labelnamesystem).filter(f => f != valueData.labelnamesystem)]
    const optionFields = isFields ? Object
        .keys(fieldsData)
        .filter(f => !objectIds.includes(f))
        .map(item => ({ label: item, value: item, type: fieldsData[item] })) : []

    const onSubmit = async (data: any) => {
        try {
            const method = isEdit ? 'put' : 'post'
            const result = await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, data)
            handleSave(isEdit, result.data)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
        handleClose()
    }

    return <Modal size="lg" show={valueData != undefined} onHide={handleClose} centered >
        <Modal.Header>
            <Modal.Title> <FormattedMessage id={isEdit ? 'text.field.update' : 'text.field.add'} /> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.label.name'>
                            <input className={`form-control form-control-sm ${errors.labelname && 'form-error'}`} {...register('labelname')} />
                        </KTFormItem>
                        <KTFormItem title='text.label.name-system'>
                            <Controller
                                name="labelnamesystem"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    options={optionFields}
                                    value={optionFields.find(f => f.label == value) || null}
                                    onChange={(e) => {
                                        setValue("datatype", '')
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue("datatype", e.type)
                                    }}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.data-type'>
                            <span className="badge badge-light-primary">{watch('datatype')?.toUpperCase()}</span>
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.default-value'>
                            <input className={`form-control form-control-sm ${errors.defaultvalue && 'form-error'}`} {...register('defaultvalue')} />
                        </KTFormItem>
                        <KTFormItem title='text.status'>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" {...register("status")} />
                            </label>
                        </KTFormItem>
                    </div>
                </div>
                <div className="d-flex flex-stack">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        handleClose={handleClose} />
                </div>
            </form>
        </Modal.Body>
    </Modal>
}
