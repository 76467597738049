import { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useAuth } from "../../../../app/modules/auth"

export const TextFormByUser: FC<{ data: any }> = ({ data }) => {
    const { auth } = useAuth()
    const intl = useIntl()
    let [text, setText] = useState(intl.formatMessage({ id: "text.created-by-name" }, { name: auth?.user?.username }))

    useEffect(() => {
        async function getData() {
            if (data && data?.id && data?.modifier && data?.modifiedat) {
                setText(intl.formatMessage({ id: "text.updated-date-by-name" }, {
                    name: data.modifier,
                    date: new Date(data.modifiedat).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })
                }))
            } else if (data?.id && data?.modifiedat) {
                setText(`${intl.formatMessage({ id: "text.updated" })} ${new Date(data.modifiedat).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}`)
            }
        }
        getData()
    }, [data?.modifiedat, data?.id])

    return <i className="text-gray-600 fs-7 me-2">{text}</i>
}