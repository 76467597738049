import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Column } from "react-table";
import { formatCurrent, formatOptions, sortByUpdateAt, syncObjects } from "../../../../theme/helpers";
import { ApiService } from "../../../../theme/helpers/ApiService";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { BasicCell, CustomHeader, InputSelect, KTButton, KTFormItem, RowIcon } from "../../../../theme/partials";
import { FilterDropdown } from "../../../../theme/partials/layout/filtter-dropdown";
import { KTTable } from "../../../../theme/partials/widgets/table";
import { apiPrivate } from "../../../modules/api";
import { IInitField } from "../../../modules/api/type";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";

export interface IProduct extends IInitField {
    itemno: string | null
    description: string | null
    baseunitofmeasure: string | null
    purchaseunitofmeasure: string | null
    inventory: number | null
    maxinventory: number | null
    unitprice: number | null
    unitcost: number | null
    lastdirectcost: number | null
    vendorno: string | null
    vendorname: string | null
    vendoritemno: string | null
    assemblybom: boolean
    blocked: boolean
    vatprodpostinggroup?: any
}

const urlApi = 'settings/items'
export function SettingItems({ keyElement, permission }: MergedProps) {
    const [tableData, setTableData] = useState<IProduct[]>([])
    const { data: dataQuery, isSuccess, isFetching, refetch } = apiPrivate.useItems()

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(dataQuery))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'itemno',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].itemno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.item.name' />,
        accessor: 'description',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].description} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.purchase-unit-of-measure' />,
        accessor: 'purchaseunitofmeasure',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].purchaseunitofmeasure} />,
    },/*  {
        Header: (props) => <CustomHeader tableProps={props} title='text.inventory' />,
        accessor: 'inventory',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].inventory} />,
    },  */{
        Header: (props) => <CustomHeader tableProps={props} title='text.max-inventory' />,
        accessor: 'maxinventory',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].maxinventory} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.last-direct-cost' />,
        accessor: 'lastdirectcost',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].lastdirectcost)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.assemblybom' />,
        accessor: 'assemblybom',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].assemblybom} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" to={props.data[props.row.index].id} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [filter, setFilter] = useState<IProduct | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: IProduct[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                !f.blocked == !filter.blocked,
                !!filter.baseunitofmeasure ? f.baseunitofmeasure == filter.baseunitofmeasure : true,
                !!filter.vendorno ? f.vendorno == filter.vendorno : true
            ]
            return checks.every(val => val == true)
        })
    }

    const { data: uomsData, isSuccess: isUoms } = apiPrivate.useUoms()
    const optionUoms = isUoms ? formatOptions(uomsData, ['code', 'displayname']) : []

    const { data: vendorsData, isSuccess: isVendors } = apiPrivate.useVendors()
    const optionsVendors = isVendors ? formatOptions(vendorsData, ['number', 'number', 'vendorname']) : []

    const toolbar = <>
        <FilterDropdown
            handleSubmit={handleSubmit}
            onClose={() => {
                setFilter(undefined)
                reset({})
            }}
            onSubmit={(data) => setFilter(data)}>
            <KTFormItem title="text.unit-of-measure" isBLockLabel>
                <Controller
                    name="baseunitofmeasure"
                    control={control}
                    render={({ field: { value, onChange } }) => <InputSelect
                        options={optionUoms}
                        value={optionUoms.find(f => f.value == value) || null}
                        onChange={(e) => {
                            if (!e) return onChange(null)
                            onChange(e.value)
                        }}
                    />}
                />
            </KTFormItem>
            <KTFormItem title="text.vendor.id" isBLockLabel>
                <Controller
                    name="vendorno"
                    control={control}
                    render={({ field: { value, onChange } }) => <InputSelect
                        options={optionsVendors}
                        value={optionsVendors.find(f => f.value == value) || null}
                        onChange={(e) => {
                            if (!e) return onChange(null)
                            onChange(e.value)
                        }}
                    />}
                />
            </KTFormItem>
            <KTFormItem title='text.blocked' labelWidth="auto">
                <label className="form-check form-switch form-check-custom form-check-solid">
                    <input className="form-check-input" type="checkbox" {...register("blocked")} />
                </label>
            </KTFormItem>
        </FilterDropdown>
        <KTButton permission={permission} action="sync" onClick={() => syncObjects('/bcsync/items?isDeleteOldData=false&source=WEBSERVICE', refetch)} />
    </>

    return <>
        <ContentHeader title={keyElement} elements={toolbar} />
        <KTTable
            isLoading={isFetching}
            data={onFilter(tableData)}
            columns={columns}
            search
            pagination
            cardInnner
        />
    </>
}
