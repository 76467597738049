import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { ApiService, ApiShowError, changeDataModify, getValueId } from "../../../../theme/helpers"
import { FormattedMessage } from "react-intl";
import { ContentHeader } from "../../../../theme/layout/components/content"
import { FormSubmit, InputSelect, KTFormItem, TextFormByUser, yup } from "../../../../theme/partials"
import { apiAd } from "../../api/admin"
import { IInitField } from "../../api/type"
import { popupMessage } from "../../messages"

export interface IAdEmailSetup extends IInitField {
    accountname: string | null
    sendername: string | null
    emailaddress: string | null
    serverurl: string | null
    serverport: number | null
    authentication: number | null
    username: string | null
    password: string | null
    secureconnection: boolean
    ratelimitperminute: number | null
}

const defaultValues: IAdEmailSetup = {
    accountname: null,
    sendername: null,
    emailaddress: null,
    serverurl: null,
    serverport: null,
    authentication: null,
    username: null,
    password: null,
    secureconnection: false,
    ratelimitperminute: null
}

const schema = yup.object().shape({

});

const breadCrumbs = [{ title: 'system', path: '/system-settings', }]
const apiurl = 'settings/emailsetups'
export const AdEmailSetupsDetailPage = () => {
    const navigate = useNavigate();
    const { data, isSuccess, isFetching } = apiAd.useEmailSetups()
    const { register, reset, control, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdEmailSetup>({
        defaultValues,
        resolver: yupResolver(schema),
    })

    const optionAuthentication = [
        { value: 20120000, label: <FormattedMessage id="text.anonymous"/> },
        { value: 20120001, label: <FormattedMessage id="text.basic"/> },
        { value: 20120002, label: <FormattedMessage id="text.oauth-2.0"/> },
        { value: 20120003, label: <FormattedMessage id="text.ntlm"/> },
    ]
    

    useEffect(() => {
        if (isSuccess && !isFetching) {
            reset(data[0])
        } else if (isSuccess && !isFetching && data && data.length == 0) {
            reset(defaultValues)
        }
    }, [isFetching])

    return <>
        <ContentHeader title="system.email-setup" isInfo items={breadCrumbs} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IAdEmailSetup) => {
                try {
                    const { isEdit, valueid } = getValueId(data.id)
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(-1)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general"/></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={true}
                        isSubmitting={isSubmitting}
                        handleClose={() => navigate(-1)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.account.name'>
                            <input className={`form-control form-control-sm ${errors.accountname && 'form-error'}`} {...register('accountname')} />
                        </KTFormItem>
                        <KTFormItem title='text.sender.name'>
                            <input className={`form-control form-control-sm ${errors.sendername && 'form-error'}`} {...register('sendername')} />
                        </KTFormItem>
                        <KTFormItem title='text.email'>
                            <input className={`form-control form-control-sm ${errors.emailaddress && 'form-error'}`} {...register('emailaddress')} />
                        </KTFormItem>
                        <KTFormItem title='text.server.url'>
                            <input className={`form-control form-control-sm ${errors.serverurl && 'form-error'}`} {...register('serverurl')} />
                        </KTFormItem>
                        <KTFormItem title='text.server.port'>
                            <input className={`form-control form-control-sm ${errors.serverport && 'form-error'}`} {...register('serverport')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.authentication'>
                            <Controller
                                name="authentication"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    options={optionAuthentication}
                                    value={optionAuthentication.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (e.value == 20120000 || e.value == 20120002) setValue("password", null)
                                        if (e.value == 20120000) setValue("username", null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)

                                    }}
                                    className="w-100"
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.username'>
                            <input
                                disabled={watch("authentication") == 20120000}
                                className={`form-control form-control-sm ${errors.username && 'form-error'}`}
                                {...register('username')} />
                        </KTFormItem>
                        <KTFormItem title='text.password'>
                            <input
                                disabled={watch("authentication") == 20120000 || watch("authentication") == 20120002}
                                className={`form-control form-control-sm ${errors.username && 'form-error'}`}
                                {...register('password')} />
                        </KTFormItem>
                        <KTFormItem title='text.secure-connection'>
                            <label className="form-check form-switch form-check-custom form-check-solid gap-2">
                                <input className="form-check-input" type="checkbox" {...register("secureconnection")} />
                            </label>
                        </KTFormItem>
                        <KTFormItem title='text.rate-limit-per-minute'>
                            <input className={`form-control form-control-sm ${errors.ratelimitperminute && 'form-error'}`} {...register('ratelimitperminute')} type="number" />
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
    </>
}
