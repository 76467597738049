import { createContext, FC, useContext } from 'react'
import { ApiService, ApiShowError, WithChildren } from '../../../../theme/helpers'
import * as authHelper from './AuthHelpers'
import { AuthModel, UserModel } from './_models'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  logout: (notTokent?: boolean) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const AuthProvider: FC<WithChildren> = ({ children }) => {
  let auth = authHelper.getAuth()

  const saveAuth = (auth: AuthModel | undefined) => {
    if (auth) return authHelper.setAuth(auth)
    authHelper.removeAuth()
  }

  const logout = async (notTokent?: boolean) => {
    try {
      if (auth && !notTokent) await ApiService.post(`auth/signout/${auth?.user?.id}`)
      saveAuth(undefined)
      window.location.href = '/login'
    } catch (error) { ApiShowError(error) }
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser: auth?.user, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }

