import clsx from "clsx";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { getIsPermission } from "../../../../app/modules/auth/Permission";

interface PropsBtnSubmit {
    showOnlySave?: boolean
    handleClose: () => void
    handleCloseWhenSubmit?: (type: boolean) => void
    handleSubmit?: () => void
    hideSubmit?: boolean
    type: "insert" | "modify" | boolean
    permission?: string,
    align?: "left" | "center" | "right"
    isSubmitting: boolean
    disabled?: boolean
    refSaveBtn?: any
    hideCloseButton?: boolean
}

export const FormSubmit: FC<PropsBtnSubmit> = ({ handleCloseWhenSubmit, hideCloseButton, showOnlySave, handleSubmit, hideSubmit, type, align, permission, isSubmitting, handleClose, disabled, refSaveBtn }) => {

    const permisstiontext = typeof type == 'string' ? type : (type ? 'modify' : 'insert')
    const isPermission = permission
        ? getIsPermission([permission, permisstiontext.toUpperCase()])
        : true
    const option: any = { disabled: isSubmitting || disabled, type: "submit" }

    return <div className={clsx("d-flex gap-2", align ? `justify-content-${align}` : 'justify-content-end')}>
        {!hideCloseButton && <button className="btn btn-secondary btn-sm" type="button" onClick={handleClose}>
            <i className="bi bi-x-lg"></i> <FormattedMessage id="text.cancel" />
        </button>}
        {!hideSubmit && <>
            {showOnlySave && <button
                {...option}
                type={isSubmitting || disabled || !isPermission ? "button" : "submit"}
                onClick={(e: any) => {
                    handleCloseWhenSubmit && handleCloseWhenSubmit(false)
                    if (handleSubmit) e.preventDefault()
                    if (isSubmitting || disabled || !isPermission) return
                    else if (handleSubmit) handleSubmit()
                }}
                className={clsx("btn btn-success btn-sm ", {
                    'disabled': isSubmitting || disabled || !isPermission,
                })}
            >
                <i className="bi bi-check-lg fs-5"></i>
                <FormattedMessage id="text.save" />
            </button>}
            <button
                {...option}
                ref={refSaveBtn}
                type={isSubmitting || disabled || !isPermission ? "button" : "submit"}
                onClick={async (e: any) => {
                    if (handleSubmit) e.preventDefault()
                    if (isSubmitting || disabled || !isPermission) return
                    else if (handleSubmit) handleSubmit()
                    else if (!isSubmitting && showOnlySave && handleCloseWhenSubmit) handleCloseWhenSubmit(true)
                }}
                className={clsx("btn btn-primary btn-sm", {
                    'disabled': isSubmitting || disabled || !isPermission
                })}
            >
                <i className="bi bi-check-lg fs-5"></i>
                <FormattedMessage id="text.save&close" />
            </button>
        </>}
    </div >
}