import clsx from 'clsx'
import React from 'react'
import { useLocation } from 'react-router'
import { checkIsActive, WithChildren } from '../../../../helpers'
import { KTSVG } from '../../../../partials/widgets/utils/KTSVG'

type Props = {
  to: string
  title: string
  icon?: string
  hasBullet?: boolean
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  hasBullet,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)

  const isSvg = icon && (icon?.split('media/icons/').length > 1)

  return (
    <div
      className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && <span className='menu-icon'>
          {isSvg
            ? <KTSVG path={icon} className='svg-icon-2' />
            : <i className={clsx('fs-3', icon)}></i>
          }
        </span>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive })}>
        {children}
      </div>
    </div>
  )
}

export { SidebarMenuItemWithSub }

