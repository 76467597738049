import clsx from "clsx"
import { FC } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { getIsPermission } from "../../../../app/modules/auth/Permission"
import { popupQuestion } from "../../../../app/modules/messages"
import { ApiShowError } from "../../../helpers"
import { KTSVG } from "../utils/KTSVG"

interface IKTButton {
    permission?: string
    to?: string
    onClick?: () => void
    action: 'new' | 'itemnew' | 'itemdelete' | 'sync' | 'back' | 'generate' | 'import'
    question?: string
    titleBtn?: string
    classBtn?: string
    disabled?: boolean
    hideIcon?: boolean
    isSubmit?: boolean
}

export const KTButton: FC<IKTButton> = ({ disabled, permission, to, onClick, action, question, titleBtn, classBtn, hideIcon, isSubmit }) => {
    const intl = useIntl()
    let contents = {
        'generate': {
            text: intl.formatMessage({ id: "text.generate" }),
            color: 'primary',
            icon: 'fa-solid fa-rotate',
            permission: ['MODIFY', 'INSERT']
        },
        'sync': {
            text: intl.formatMessage({ id: "text.sync-bc" }),
            color: 'primary',
            icon: 'fa-solid fa-rotate',
            permission: ['MODIFY', 'INSERT']
        },
        'new': {
            text: intl.formatMessage({ id: "text.add" }),
            color: 'primary',
            icon: 'fa-solid fa-plus',
            permission: ['INSERT']
        },
        'itemnew': {
            text: intl.formatMessage({ id: "text.add" }),
            color: 'primary',
            icon: 'fa-solid fa-plus',
            permission: [/* 'MODIFY',  */'INSERT']
        },
        'itemdelete': {
            text: intl.formatMessage({ id: "text.delete" }),
            color: 'danger',
            icon: 'fa-solid fa-trash',
            permission: [/* 'MODIFY',  */'DELETE']
        },
        'back': {
            text: intl.formatMessage({ id: "text.back" }),
            color: 'secondary',
            icon: 'bi bi-arrow-left',
            permission: []
        },
        'import': {
            text: 'Import',
            color: 'info',
            icon: '/media/icons/custom/excel.svg',
            permission: [],

        },
    }
    if (titleBtn) {
        contents[action].text = titleBtn
    }
    const navigate = useNavigate()
    const { icon, color, text } = contents[action]
    const isSvg = icon && (icon.split('media/icons/').length > 1)

    const isPermission = permission && contents[action].permission.length ? getIsPermission([permission, ...contents[action].permission]) : true

    const options = {
        className: clsx(classBtn || `btn btn-sm btn-${color}`, {
            'disabled': disabled || !isPermission
        }),
        onClick: to
            ? () => navigate(to)
            : async () => {
                try {
                    let isQuestion = true
                    if (action == 'new' && !onClick) return navigate('new')
                    if (typeof question == 'string') isQuestion = await popupQuestion({ description: question })
                    if (isQuestion && onClick) onClick()
                } catch (error) {
                    ApiShowError(error)
                }
            }
    }

    const content = <>
        {icon && !hideIcon && <span className='menu-icon me-1'>
            {isSvg
                ? <KTSVG path={icon} className='svg-icon-2' />
                : <i className={clsx('fs-8', icon)}></i>
            }
        </span>}
        {text}
    </>

    return isSubmit ? <button {...options} >{content}</button> : <div {...options} > {content} </div>
}