import { useNavigate, useParams } from "react-router-dom"
import { IInitField, apiPrivate } from "../../../modules/api"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { BasicCell, CustomHeader, DivHr, FormSubmit, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../theme/partials"
import { useEffect, useState } from "react"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { MergedProps } from "../../../router/AppRoutes"
import { FormattedMessage } from "react-intl"
import { Column } from "react-table"
import { Modal } from "react-bootstrap"
import { getValueId } from "../../../../theme/helpers"

interface IDimensionValue extends IInitField {
    id: string
    dimensionid: string
    dimensioncode: string
    code: string
    name: string
    description: any
    blocked: any
    dimensionvaluetype: any
    totaling: any
}

export default function PDimensionsForm({ keyElement, pathElement, permission }: MergedProps) {
    const navigate = useNavigate()
    const { id } = useParams()
    const { data, isFetching } = apiPrivate.useDimensionsByQuey(id ? `/${id}` : ``)
    const { data: dataValues } = apiPrivate.useDimensionsValuesByQuery(id ? `?dimensionid=${id}` : ``)
    const { register, reset, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<IDimensionValue>({
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            noseriname: yup.string().required()
        })),
    })
    const [valueData, setValueData] = useState<IDimensionValue | undefined>()

    useEffect(() => {
        if (data && !isFetching) reset(data)
    }, [isFetching])

    function onSubmit(data: IDimensionValue) {

    }

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='Code' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].code} onClick={() => setValueData(props.data[props.row.index])} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Dimension Value Type' />,
        accessor: 'dimensionvaluetype',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].dimensionvaluetype} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Totaling' />,
        accessor: 'totaling',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].totaling} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" permission={permission} onClick={() => setValueData(props.data[props.row.index])} />
        </div>
    }]

    // const refSubmit = useRef<HTMLButtonElement | null>(null)
    return <>
        <ContentHeader title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">General</h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <button className="btn btn-sm btn-secondary" onClick={() => navigate(-1)}><i className="bi bi-x-lg"></i> Back</button>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <form className="col-12"
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className="row justify-content-between">
                            <div className="col-12 col-lg-6">
                                <KTFormItem title='Code'>
                                    <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                                </KTFormItem>
                            </div>
                            <div className="col-12 col-lg-6">
                                <KTFormItem title='Name'>
                                    <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                                </KTFormItem>
                            </div>
                            {/* <button ref={refSubmit} type="submit" className="d-none" /> */}
                        </div>
                    </form>
                    <div className="col-12">
                        <DivHr />
                        <div className="d-flex flex-stack align-items-center">
                            <div className="fs-4">Dimension Values</div>
                            <button className="btn btn-sm btn-primary" onClick={()=>{}}>Sync Values (BC)</button>
                        </div>
                        <KTTable data={dataValues || []} columns={columns} pagination />
                    </div>
                </div>
            </div>
        </div>
        {valueData && <DimensionValueModal {...{
            permission,
            valueData,
            handleClose() { setValueData(undefined) },
            /* handleSave(type, value) {
                if (type) return setTableData(pre => pre.map(item => item.id == value.id ? value : item))
                setTableData([value, ...tableData])
            }, */
        }} />}
    </>
}

const DimensionValueModal = ({ permission, valueData, handleClose, handleSave }: any) => {
    const { isEdit, valueid } = getValueId(valueData?.id)
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<IDimensionValue>({
        resolver: yupResolver(yup.object().shape({

        }))
    })

    useEffect(() => {
        if (valueData)
            reset(valueData)
    }, [valueData])

    return <Modal show={valueData != undefined} onHide={handleClose} centered size="lg">
        <Modal.Header>
            <Modal.Title>Dimension Value</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(async (data: IDimensionValue) => { })}>
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='Code'>
                            <input className="form-control form-control-sm" {...register("code")} />
                        </KTFormItem >
                        <KTFormItem title='Dimension Value Type'>
                            <input className="form-control form-control-sm" {...register("dimensionvaluetype")} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='Name'>
                            <input className="form-control form-control-sm" {...register("name")} />
                        </KTFormItem>
                        <KTFormItem title='Totaling'>
                            <input className="form-control form-control-sm" {...register("totaling")} />
                        </KTFormItem>
                    </div>
                    <KTFormItem title='Description' isHeight>
                        <textarea className="form-control form-control-sm" {...register("description")} rows={3} />
                    </KTFormItem>
                    <KTFormItem title='Blocked'>
                        <label className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" {...register("blocked")} />
                        </label>
                    </KTFormItem>
                    <div className="col-12 align-items-end d-flex flex-stack">
                        <TextFormByUser data={watch()} />
                        <button className="btn btn-sm btn-secondary" onClick={handleClose}><i className="bi bi-x-lg"></i> Close</button>
                        {/* <FormSubmit
                            type={isEdit}
                            permission={permission}
                            isSubmitting={isSubmitting}
                            handleClose={handleClose} /> */}
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
}