import { FC, useEffect } from "react"
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { optionAddress } from "../../../../app/modules/api/address"
import { KTFormItem } from "../utils/KTFormItem"
import { InputSelect } from "./InputSelect"

interface IExtendAddressValue {
    disabledAddress?: boolean
    register: UseFormRegister<any>
    errors: FieldErrors<any>
    watch: UseFormWatch<any>
    control: Control<any, any>
    setValue: UseFormSetValue<any>
}

export const ExtendAddressValue: FC<IExtendAddressValue> = ({ control, setValue, watch, errors, register, ...props }) => {
    const { data: countriesData, isFetching, isSuccess: isCountries } = optionAddress.useCountries()
    const optionCountries = isCountries ? optionAddress.countries(countriesData) : []
    const typeCountry = optionAddress.getTypeCountry(optionCountries, watch('country'))

    const { data: provincesData, isSuccess: isProvinces } = optionAddress.useProvincesByQuery(watch('country'))
    const optionProvinces = isProvinces ? optionAddress.provinces(provincesData) : []

    const { data: districtsData, isSuccess: isDistricts } = optionAddress.useDistrictsByQuery(watch('province'))
    const optionDistricts = isDistricts ? optionAddress.districts(districtsData) : []

    const { data: wardsData, isSuccess: isWards } = optionAddress.useWardsByQuery(watch('district'))
    const optionWards = isWards ? optionAddress.wards(wardsData) : []

    useEffect(() => {
        if (isCountries && !isFetching && !watch("country"))
            setValue("country", optionCountries.find(f => f.value == 'VN')?.code || null)
    }, [isFetching])

    function resetAddress(type: 1 | 2) {
        if (type >= 1) setValue('ward', null)
        if (type == 2) {
            setValue('district', null)
            setValue('zipcode', null)
            setValue('province', null)
            setValue('state', null)
        }
    }

    return <>
        <KTFormItem title='text.country'>
            <Controller
                name="country"
                control={control}
                render={({ field: { value, onChange } }) => <InputSelect
                    isClearable
                    options={optionCountries}
                    value={optionCountries.find(f => String(f.value) == value) || null}
                    onChange={(e) => {
                        resetAddress(2)
                        if (!e) return onChange(null)
                        onChange(e.value)
                    }}
                    className="w-100"
                />}
            />
        </KTFormItem>
        <KTFormItem title='text.province'>
            <Controller
                name={'province'}
                control={control}
                render={({ field: { value, onChange } }) => <InputSelect
                    isClearable
                    options={optionProvinces}
                    value={optionProvinces.find(f => String(f.value) == value) || null}
                    onChange={(e) => {
                        resetAddress(2)
                        if (!e) return onChange(null)
                        onChange(e.value)
                        if (typeCountry == 'State') setValue('state', e.statename)
                        setValue('zipcode', e.zipcode)
                    }}
                    className="w-100"
                />}
            />
        </KTFormItem>
        {typeCountry == 'Province'
            ? <>
                <KTFormItem title='text.district'>
                    <Controller
                        name="district"
                        control={control}
                        render={({ field: { value, onChange } }) => <InputSelect
                            isClearable
                            options={optionDistricts}
                            value={optionDistricts.find(f => String(f.value) == value) || null}
                            onChange={(e) => {
                                resetAddress(1)
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                            className="w-100"
                        />}
                    />
                </KTFormItem>
                <KTFormItem title='text.ward'>
                    <Controller
                        name="ward"
                        control={control}
                        render={({ field: { value, onChange } }) => <InputSelect
                            isClearable
                            options={optionWards}
                            value={optionWards.find(f => String(f.value) == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                            className="w-100"
                        />}
                    />
                </KTFormItem>
            </>
            : typeCountry == 'State'
                ? <KTFormItem title='text.state'>
                    <input className={`form-control form-control-sm ${errors.state && 'form-error'}`} {...register('state')} />
                </KTFormItem>
                : <></>}
        <KTFormItem title='text.zipcode'>
            <input className={`form-control form-control-sm ${errors.zipcode && 'form-error'}`} {...register('zipcode')} />
        </KTFormItem>
        <KTFormItem title='text.address'>
            <input disabled={props.disabledAddress} className={`form-control form-control-sm ${errors.address && 'form-error'}`} {...register('address')} />
        </KTFormItem>
    </>
}