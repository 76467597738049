import Swal from "sweetalert2"
import { ApiService } from "../../../../theme/helpers"

export const getOptionCustomers = async(zustandStore: any) => {
    await ApiService.get(`/settings/customers/?expand=customerbankaccounts`)
    .then(({data}) => {
      let tempData = data.map((i: any) =>( {...i, value: i.customerno,label: i.customerno+' / '+i.customername} ))
      zustandStore?.setCustomers(tempData)
    })
    .catch(({response}) => {
      Swal.fire({
        icon: 'error',
        title: 'An error occurred',
        showConfirmButton: false,
        timer: 1500
      })
    })
  }
  export const getOptionVenders = async(type: string, zustandStore: any) => {
    const vendortype = type == '284210001'?'employee':'vendor'
    await ApiService.get(`/settings/vendors/?vendortype=${vendortype}&expand=vendorbankaccounts`)
    .then(({data}) => {
      let tempData = data.map((i: any) =>( {...i, value: i.number,label: i.number+' / '+i.vendorname} ))
      if(vendortype == 'employee') {
        zustandStore?.setEmployees(tempData)
      }
      else {
        zustandStore?.setVenders(tempData)

      }
    })
    .catch(({response}) => {
      Swal.fire({
        icon: 'error',
        title: 'An error occurred',
        showConfirmButton: false,
        timer: 1500
      })
    })
  }
