import React from "react";
// import Typography from "@mui/material/Typography";
import { NodeModel } from "@minoru/react-dnd-treeview";
// import { TypeIcon } from "./TypeIcon";
import styles from "./ExternalNode.module.css";

type Props = {
  node: NodeModel;
};

export const ExternalNode: React.FC<Props> = (props) => {
  const { droppable } = props.node;
  const handleDragStart = (e: React.DragEvent) => {
    e.dataTransfer.setData("text", JSON.stringify(props.node));
  };

  return (
    <div className={styles.wexternalnode} draggable onDragStart={handleDragStart} >
      {/* <div className={styles.filetype}>
        <TypeIcon droppable={droppable || false} />
      </div> */}
      <div className="d-flex flex-stack">
        <div>{props.node.text}</div>
        <span><i className="bi bi-arrows-move"></i></span>
      </div>
    </div>
  );
};
