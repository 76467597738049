import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Column } from "react-table"
import { ApiService, ApiShowError, changeDataModify, sortByUpdateAt } from "../../../../../theme/helpers"
import { ContentHeader } from "../../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, FilterDropdown, KTButton, KTTable, RowIcon } from "../../../../../theme/partials"
import { apiAd } from "../../../api/admin"
import { popupMessage } from "../../../messages"
import { IAdApproval, optionType } from "./component"

const breadCrumbs = [{ title: 'system', path: '/system-settings', }]
const apiurl = 'settings/approvals'
export default function AdApprovalsPage() {
    const navigate = useNavigate()
    const { data, isSuccess, isFetching } = apiAd.useApprovalsByQuery(`?iseditedcheck=true`)
    const [tableData, setTableData] = useState<IAdApproval[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data.map((item: any) => ({ ...item, blockedtext: item.blocked ? "Active" : "Inactive" }))))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.object.id' />,
        accessor: 'objectcode',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => handleEdit(props.data[props.row.index])}
            data={props.data[props.row.index].objectcode}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.module' />,
        accessor: 'objectname',
        Cell: ({ ...props }) => <BasicCell
            data={props.data[props.row.index].objectname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'approvalname',
        Cell: ({ ...props }) => <BasicCell
            data={props.data[props.row.index].approvalname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.type' />,
        accessor: 'type',
        Cell: ({ ...props }) => <BasicCell data={optionType.find(f => f.value == props.data[props.row.index].type)?.label} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.priority' className="text-center" />,
        accessor: 'priority',
        Cell: ({ ...props }) => <BasicCell
            data={props.data[props.row.index].priority && String(props.data[props.row.index].priority)} className="text-center" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.status' className="text-center" />,
        accessor: 'blockedtext',
        Cell: ({ ...props }) => <BasicCell
            data={props.data[props.row.index].blockedtext}
            badgeColor={!props.data[props.row.index].blocked ? 'light' : 'light-success'}
            className="text-center" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon
                action="clone"
                onClick={async () => {
                    const cloneValue: IAdApproval = {
                        ...props.data[props.row.index],
                        id: undefined,
                        approvalname: props.data[props.row.index].approvalname + ' #copy',
                        blocked: true,
                    }
                    try {
                        const record = await ApiService.post(apiurl, changeDataModify(cloneValue))
                        setTableData([record.data, ...tableData])
                    } catch (error) {
                        ApiShowError(error)
                    }
                }} />
            <RowIcon
                action="modify" onClick={() => handleEdit(props.data[props.row.index])} />
            <RowIcon
                disabled={!props.data[props.row.index].isedited}
                action="delete" onClick={async () => {
                    const id = props.data[props.row.index].id
                    await ApiService.delete(apiurl + `/${id}`)
                    popupMessage({ icon: 'success', autoClose: true })
                    setTableData(pre => pre.filter(f => f.id != id))
                }} />
        </div>
    }]

    async function handleEdit(record: IAdApproval) {
        try {
            record.id && navigate(record.id)
        } catch (error) {
            ApiShowError(error)
        }
    }

    return <>
        <ContentHeader title="system.approvals" items={breadCrumbs} />
        <KTTable
            isLoading={isFetching}
            toolbar={<>
                <FilterDropdown>
                </FilterDropdown>
                <KTButton action='new' />
            </>}
            rowActive={tableData.filter((item) => !item.blocked && item.id).map(item => item.id) as string[]}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search />
    </>
}


