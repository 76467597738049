import clsx from "clsx";
import { useRef, useState } from "react";
import { isBase64, toBase64 } from "../helpers";

export function UploadImage({ isLoading, value, onChange, className, objectFit = 'contain' }: any) {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [key, setKey] = useState(Date.now())

    function loaderImage(value: any) {
        if (!value) return;
        if (isBase64(value)) {
            return value
        } else {
            return value
        }
    }

    return <div>
        <div className={clsx("position-relative border rounded", className)}>
            {typeof value == 'string'
                ? <img
                    src={loaderImage(value)}
                    className={`w-100 h-100 object-fit-${objectFit} rounded`}
                    alt="uploadimage"
                />
                : <div
                    className="w-100 h-100 display-3 fw-bold text-success d-flex align-items-center justify-content-center gap-2"
                    onClick={(e) => {
                        e.preventDefault()
                        inputRef.current?.click()
                    }}>
                    {isLoading && <div className="spinner-grow spinner-grow-sm">
                        <span className="visually-hidden">Loading...</span>
                    </div>}
                    <i className="bi bi-image fs-2x" />
                </div>}
            {typeof value == 'string' && <div style={{ position: 'absolute', top: -5, right: -5 }}>
                <button
                    type="button"
                    className="shadow btn w-25px h-25px btn-sm btn-white btn-icon rounded-circle border"
                    onClick={(e) => {
                        e.preventDefault()
                        inputRef.current?.click()
                    }}>
                    <i className="bi bi-pen text-dark" />
                </button>
            </div>}
            {typeof value == 'string' && <div style={{ position: 'absolute', bottom: -5, right: -5 }}>
                <button
                    type="button"
                    className="shadow btn w-25px h-25px btn-sm btn-white btn-icon rounded-circle border"
                    onClick={(e) => {
                        onChange(null)
                        setKey(Date.now())
                    }} >
                    <i className="bi bi-x fs-4 text-dark" />
                </button>
            </div>}
        </div>
        <input
            key={key}
            ref={inputRef}
            style={{ display: 'none' }}
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={async event => {
                const files: any = event.target?.files
                if (files.item(0)) {
                    const image = await toBase64(files.item(0)).then(value => value)
                    onChange(typeof image == 'string' ? image : null)
                }
            }} />
        <div className="form-text">Please use JPG, GIF or PNG format. Not more than 1MB.</div>
    </div>
}