import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, UseFormWatch, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { FixedSizeList } from 'react-window';
import { ApiService, ApiShowError, changeDataModify, getValueId } from "../../../theme/helpers";
import { ContentHeader } from "../../../theme/layout/components/content";
import { FormSubmit, InputDate, InputPrice, InputSelect, TextFormByUser, yup } from "../../../theme/partials";
import { apiFnb } from "../../modules/api/fnb";
import { MergedProps } from "../../router/AppRoutes";
import { IFnbCategory, IFnbHospitalityItem } from "../fnb";
import { useQuery } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { popupMessage } from "../../modules/messages";
import dayjs from "dayjs";

const optionDiscountType = [
    { value: 'fixed-discount-to-entire-order', label: 'Fixed discount to entire order' },
    { value: 'percentage-discount-to-entire-order', label: 'Percentage discount to entire order' },
    { value: 'fixed-discount-to-specific-products', label: 'Fixed discount to specific products' },
    { value: 'percentage-discount-to-specific-products', label: 'Percentage discount to specific products' },
    { value: 'buy-get-y', label: 'Buy X Get Y' }
]

const optionOperators = [
    { value: 'equal', label: 'Equal', con: 'number' },
    { value: 'not-equal', label: 'Not equal', con: 'number' },
    { value: 'greater', label: 'Greater', con: 'number' },
    { value: 'greater-equal', label: 'Greater or equal', con: 'number' },
    { value: 'smaller', label: 'Smaller', con: 'number' },
    { value: 'smaller-equal', label: 'Smaller or equal', con: 'number' },
    { value: 'in', label: 'In', con: 'text' },
    { value: 'not-in', label: 'Not in', con: 'text' },
]

const optionSelects = [
    { value: 'category', label: 'Category', type: 'select' },
    { value: 'sku', label: 'Sku', type: 'select' },
    { value: 'price', label: 'Price', type: 'price' }
]

export interface IPromotion {
    id: string
    code: string
    description: string
    startdatetime: string
    enddatetime: string
    type: string
    discountvalue: string | null
    buyxgetydetails: any[]
    ordermustcontainsproduct: any[]
    targetproduct: any[]
    minpurchaseamount: number
    minpurchasequantity: number
    maximumqty: number

    clubconditions: null
    schemeconditions: null
    memberminimumpurchaseorder: null
}

function isEndDateAfterStartDate(startDate: string, endDate: string) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    return end.isAfter(start);
}

export default function PromotionsDetailPage({ keyElement, pathElement, permission }: MergedProps) {
    const urlApi = 'discounts/promotions'
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>();
    const { valueid, isEdit } = getValueId(id)
    const { data: promotionData, isLoading } = useQuery([`${urlApi}/${valueid}`], () => ApiService.get(`${urlApi}/${valueid}`).then(res => res.data), { enabled: !!valueid })
    const { register, setValue, control, watch, reset, handleSubmit, formState: { errors, isSubmitting } } = useForm<IPromotion>({
        defaultValues: {
            type: 'fixed-discount-to-entire-order',
            discountvalue: '20000',
            targetproduct: [],
            buyxgetydetails: [],
            ordermustcontainsproduct: [],
        },
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            description: yup.string().required(),
            startdatetime: yup.string().required(),
            enddatetime: yup.string()
                .test('check', (val, ctx) => {
                    if (val) {
                        if (!ctx.parent['startdatetime']) return ctx.createError()
                        return isEndDateAfterStartDate(ctx.parent['startdatetime'], val)
                    }
                    return true
                })
                .transform(val => val || null),
            discountvalue: yup.string().required(),
            minpurchaseamount: yup.number().nullable(true).transform(val => val || null),
            minpurchasequantity: yup.number().nullable(true).transform(val => val || null),
            maximumqty: yup.number().nullable(true).transform(val => val || null),
            targetproduct: yup.array().of(yup.object().shape({
                type: yup.string().required(),
                operator: yup.string().required(),
                value: yup.string().required()
            })),
            buyxgetydetails: yup.array().of(yup.object().shape({
                sku: yup.string().required(),
                x: yup.number().required(),
                y: yup.number().required(),
                maxofy: yup.number().required(),
                discountpercent: yup.number().required()
            })),
            ordermustcontainsproduct: yup.array().of(yup.object().shape({
                type: yup.string().required(),
                operator: yup.string().required(),
                value: yup.string().required(),
                minimumqty: yup.number().required()
            }))
        }))
    })
    const [valueSelected, setValueSelected] = useState<string | undefined>()
    const [isMultiple, setIsMultiple] = useState(false)
    const [typeData, setTypeData] = useState<'sku' | 'category' | undefined>()
    const { data: hospitemsData } = apiFnb.useHospitalityItems()
    const { data: categoriesData } = apiFnb.useCategories()

    useEffect(() => {
        if (promotionData && !isLoading) {
            reset(promotionData)
        }
    }, [promotionData])

    async function onSubmit(data: any) {
        try {
            const method = isEdit ? 'put' : 'post'
            await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
            popupMessage({ icon: 'success', autoClose: true })
            navigate(pathElement)
        } catch (error) {
            ApiShowError(error)
        }
    }

    function addTargetProduct() {
        const newLine = { id: `target_${new Date().getTime()}`, type: '', operator: '', value: '' }
        setValue('targetproduct', [...(watch('targetproduct') || []), newLine])
    }

    function removeTargetProduct(id: string) {
        setValue('targetproduct', watch('targetproduct').filter((item: any) => item.id != id))
    }

    function addOrderCondition() {
        const newLine = { id: `order_${new Date().getTime()}`, type: '', operator: '', value: '', minimumqty: '' }
        setValue('ordermustcontainsproduct', [...(watch('ordermustcontainsproduct') || []), newLine])
    }

    function removeOrderCondition(id: string) {
        setValue('ordermustcontainsproduct', watch('ordermustcontainsproduct').filter((item: any) => item.id != id))
    }

    function addBuyXProduct() {
        const newLine = { id: `buy_${new Date().getTime()}`, sku: '', x: '', y: '', maxofy: '', discountpercent: '' }
        setValue('buyxgetydetails', [...(watch('buyxgetydetails') || []), newLine])
    }

    function removeBuyX(id: string) {
        setValue('buyxgetydetails', watch('buyxgetydetails').filter((item: any) => item.id != id))
    }

    const isFixedValue = ['fixed-discount-to-entire-order', 'fixed-discount-to-specific-products'].includes(watch('type'))
    const isPercentageValue = ['percentage-discount-to-entire-order', 'percentage-discount-to-specific-products'].includes(watch('type'))

    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            onSubmit={handleSubmit(onSubmit, (error) => console.log(error))}>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                    <div className="card-toolbar">
                        <TextFormByUser data={watch()} />
                        <FormSubmit
                            type={isEdit}
                            isSubmitting={isSubmitting}
                            permission={permission}
                            handleClose={() => navigate(pathElement)} />
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="mb-6">
                                <label className="mb-1">Code</label>
                                <input className={`form-control form-control-sm mb-4 ${errors.code && 'form-error'}`} {...register('code')} />
                            </div>
                            <div className="mb-6">
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <label>Start Date</label>
                                        <Controller
                                            name="startdatetime"
                                            control={control}
                                            render={({ field }) => <InputDate
                                                value={field.value}
                                                onChange={(e) => {
                                                    if (e[0]) field.onChange(e[0].toISOString())
                                                }}
                                                className={clsx({ 'form-error': errors.startdatetime })}
                                            />}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <label>Start Hour</label>
                                        <input
                                            type="time"
                                            className={clsx("form-control form-control-sm", { 'form-error': errors.startdatetime })}
                                            value={watch("startdatetime") ? dayjs(watch("startdatetime")).format('HH:mm') : undefined}
                                            onChange={(e) => {
                                                const hour = Number(e.target.value.split(':')[0])
                                                const minute = Number(e.target.value.split(':')[1])
                                                const date = new Date(watch('startdatetime') || Date.now())
                                                date.setHours(hour)
                                                date.setMinutes(minute)
                                                setValue('startdatetime', date.toISOString())
                                                return e
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-check form-check-custom my-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="isenddate"
                                        checked={!!watch("enddatetime")}
                                        onChange={(e) => setValue("enddatetime", e.target.checked ? new Date().toISOString() : "")}
                                    />
                                    <label htmlFor="isenddate"> Set end date </label>
                                </div>
                                <div className={clsx("row", { 'd-none': !watch("enddatetime") })}>
                                    <div className="col-12 col-lg-6">
                                        <label>End Date</label>
                                        <Controller
                                            name="enddatetime"
                                            control={control}
                                            render={({ field }) => <InputDate
                                                value={field.value}
                                                onChange={(e) => {
                                                    if (e[0]) field.onChange(e[0].toISOString())
                                                }}
                                                className={clsx({ 'form-error': errors.enddatetime })}
                                            />}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <label>End Hour</label>
                                        <input
                                            type="time"
                                            className={clsx("form-control form-control-sm", { 'form-error': errors.enddatetime })}
                                            value={watch("enddatetime") ? dayjs(watch("enddatetime")).format('HH:mm') : undefined}
                                            onChange={(e) => {
                                                const hour = Number(e.target.value.split(':')[0])
                                                const minute = Number(e.target.value.split(':')[1])
                                                const date = new Date(watch('enddatetime') || Date.now())
                                                date.setHours(hour)
                                                date.setMinutes(minute)
                                                setValue('enddatetime', date.toISOString())
                                                return e
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="mb-6">
                                <label className="mb-1">Description</label>
                                <textarea
                                    rows={5}
                                    className={`form-control form-control-sm ${errors.description && 'form-error'}`}
                                    {...register('description')} />
                            </div>
                        </div>
                    </div>
                    <div className="fs-5 mb-3"> Discount Type </div>
                    {optionDiscountType.map((item, index) => <div
                        key={index}
                        className="form-check form-check-custom align-items-center gap-2 mb-4">
                        <input
                            className="form-check-input"
                            id={item.value}
                            type="radio"
                            value={item.value}
                            {...register(`type`, {
                                onChange(event) {
                                    setValue('type', event.target.value)
                                    if (event.target.value == 'fixed-discount-to-entire-order') {
                                        setValue('discountvalue', '20000')
                                    } else if (event.target.value == 'percentage-discount-to-entire-order') {
                                        setValue('discountvalue', '100')
                                    } else if (event.target.value == 'fixed-discount-to-specific-products') {
                                        setValue('discountvalue', '20000')
                                    } else if (event.target.value == 'percentage-discount-to-specific-products') {
                                        setValue('discountvalue', '100')
                                    } else if (event.target.value == 'buy-get-y') {
                                    }
                                },
                            })} />
                        <label className="form-label" htmlFor={item.value}> {item.label} </label>
                    </div>)}
                    {(isFixedValue || isPercentageValue) && <>
                        <div className="d-flex align-items-center gap-3">
                            <div className="bg-secondary rounded h-35px d-flex gap-2 px-2 align-items-center">
                                <span className={clsx(
                                    "px-2 py-1 d-flex align-items-center rounded w-40px justify-content-center",
                                    isFixedValue ? 'bg-white' : 'bg-secondary'
                                )}> VND </span>
                                <span className={clsx(
                                    "px-2 py-1 d-flex align-items-center rounded w-40px justify-content-center",
                                    isPercentageValue ? 'bg-white' : 'bg-secondary'
                                )}> % </span>
                            </div>
                            {isFixedValue
                                ? <InputPrice
                                    placeholder="Discount Value"
                                    className={clsx("form-control form-control-sm w-200px", { 'form-error': errors.discountvalue })}
                                    value={watch('discountvalue') ? Number(watch('discountvalue')) : 0}
                                    onChange={(e) => setValue('discountvalue', String(e))}
                                />
                                : isPercentageValue
                                    ? <input
                                        type="number"
                                        min={0}
                                        max={100}
                                        placeholder="Discount Value"
                                        className={clsx("form-control form-control-sm w-200px", { 'form-error': errors.discountvalue })}
                                        {...register('discountvalue')} />
                                    : null}
                        </div>
                    </>}
                    {['fixed-discount-to-specific-products', 'percentage-discount-to-specific-products']
                        .includes(watch("type")) && <>
                            <div className="fs-5 my-3">Target products</div>
                            <div className="d-flex align-items-center gap-2 mb-3">
                                Maximum
                                <input className={clsx("w-40px form-control form-control-sm", { 'form-error': errors.maximumqty })} {...register(`maximumqty`)} />
                                quantity of products are matched bellow conditions(All)
                            </div>
                            <table className="table table-bordered border border-secondary g-2">
                                <thead>
                                    <tr>
                                        <th style={{ width: '85px' }}>Type</th>
                                        <th style={{ width: '120px' }}>Operator</th>
                                        <th>Value</th>
                                        <th style={{ width: '50px' }}></th>
                                    </tr>
                                </thead>
                                <tbody>{(watch("targetproduct") || [])
                                    .map((item: any, ind: number) => {
                                        const selected = optionSelects.find(f => f.value == watch(`targetproduct.${ind}.type`))
                                        const operators = optionOperators.filter(f => selected?.type == 'price' ? f.con == 'number' : f.con == 'text')

                                        let textValue = ''
                                        if (watch(`targetproduct.${ind}.value`)) {
                                            if (selected?.value == 'sku') {
                                                const isArray = watch(`targetproduct.${ind}.value`).indexOf(',') != -1
                                                if (isArray) {
                                                    const temps = watch(`targetproduct.${ind}.value`).split(',')
                                                    const temp = hospitemsData?.find((f: any) => f.code == temps.at(0))
                                                    textValue = temp?.name
                                                    if (temps.length > 1) {
                                                        textValue += ` + ${temps.length - 1} more`
                                                    }
                                                } else {
                                                    const temp = hospitemsData?.find((f: any) => f.code == watch(`targetproduct.${ind}.value`))
                                                    textValue = temp?.name
                                                }
                                            } else if (selected?.value == 'category') {
                                                const temp = categoriesData?.find((f: any) => f.code == watch(`targetproduct.${ind}.value`))
                                                textValue = temp?.name
                                            }
                                        }

                                        return <tr key={item.id}>
                                            <td className={clsx("p-0", { 'form-error': errors.targetproduct && (errors.targetproduct[ind] as any)?.type })}>
                                                <Controller
                                                    name={`targetproduct.${ind}.type`}
                                                    control={control}
                                                    render={({ field }) => <InputSelect
                                                        border={false}
                                                        options={optionSelects}
                                                        value={optionSelects.find(f => f.value == field.value)}
                                                        onChange={(e) => {
                                                            setValue(`targetproduct.${ind}.operator`, undefined)
                                                            setValue(`targetproduct.${ind}.value`, undefined)
                                                            field.onChange(e.value)
                                                            if (e.value == 'sku') {
                                                                setIsMultiple(true)
                                                                setValue(`targetproduct.${ind}.operator`, 'in')
                                                            } else if (e.value == 'category') {
                                                                setValue(`targetproduct.${ind}.operator`, 'in')
                                                            } else if (e.value == 'price') {
                                                                setValue(`targetproduct.${ind}.operator`, 'equal')
                                                            }
                                                        }}
                                                    />}
                                                />
                                            </td>
                                            <td className={clsx("p-0", { 'form-error': errors.targetproduct && (errors.targetproduct[ind] as any)?.operator })}>
                                                <Controller
                                                    name={`targetproduct.${ind}.operator`}
                                                    control={control}
                                                    render={({ field }) => <InputSelect
                                                        border={false}
                                                        options={operators}
                                                        value={optionOperators.find(f => f.value == field.value)}
                                                        onChange={(e) => field.onChange(e.value)}
                                                    />}
                                                />
                                            </td>
                                            <td className={clsx("p-0", { 'form-error': errors.targetproduct && (errors.targetproduct[ind] as any)?.value })}>
                                                {selected?.type == 'select'
                                                    ? <button
                                                        type="button"
                                                        className="btn text-primary ps-2 py-0 fs-7"
                                                        onClick={() => {
                                                            setTypeData(watch(`targetproduct.${ind}.type`) as any)
                                                            setValueSelected(`targetproduct.${ind}.value`)
                                                            if (watch(`targetproduct.${ind}.type`) == 'sku') {
                                                                setIsMultiple(true)
                                                            }
                                                        }}
                                                    >
                                                        {watch(`targetproduct.${ind}.value`)
                                                            ? textValue
                                                            : watch(`targetproduct.${ind}.type`) == 'sku'
                                                                ? 'Choose SKUs'
                                                                : watch(`targetproduct.${ind}.type`) == 'category'
                                                                    ? 'Choose categories'
                                                                    : null
                                                        }
                                                    </button>
                                                    : selected?.type == 'price'
                                                        ? <InputPrice
                                                            className="form-control border-0"
                                                            value={watch(`targetproduct.${ind}.value`)}
                                                            onChange={(e) => setValue(`targetproduct.${ind}.value`, e)}
                                                        />
                                                        : null
                                                }
                                            </td>
                                            <td className="p-0 text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-link text-danger fw-bold w-100 h-100"
                                                    onClick={() => removeTargetProduct(item.id)}>
                                                    -
                                                </button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            <button type="button" className="btn btn-link p-0" onClick={addTargetProduct}>+ Add product</button>
                        </>}
                    {watch("type") == 'buy-get-y' && <>
                        <table className="table table-bordered border border-secondary g-2">
                            <thead>
                                <tr>
                                    <th>Sku</th>
                                    <th style={{ width: '125px' }}>X</th>
                                    <th style={{ width: '125px' }}>Y</th>
                                    <th style={{ width: '125px' }}>Max of Y</th>
                                    <th style={{ width: '125px' }}>Discount percent</th>
                                    <th style={{ width: '40px' }}></th>
                                </tr>
                            </thead>
                            <tbody>{(watch("buyxgetydetails") || [])
                                .map((item: any, ind: number) => {
                                    let textValue = watch(`buyxgetydetails.${ind}.sku`)
                                    if (textValue) {
                                        const temp = hospitemsData?.find((f: any) => f.code == textValue)
                                        textValue = temp?.name
                                    }

                                    return <tr key={item.id}>
                                        <td className={clsx("p-0", { 'form-error': errors.buyxgetydetails && (errors.buyxgetydetails[ind] as any)?.sku })}>
                                            <div
                                                className="text-primary ps-2 py-0 cursor-pointer fs-7"
                                                onClick={() => {
                                                    setTypeData('sku')
                                                    setValueSelected(`buyxgetydetails.${ind}.sku`)
                                                }}
                                            >
                                                {textValue || 'Choose SKU'}
                                            </div>
                                        </td>
                                        <td className={clsx("p-0", { 'form-error': errors.buyxgetydetails && (errors.buyxgetydetails[ind] as any)?.x })}>
                                            <input type="number" className="form-control form-control-sm border-0" {...register(`buyxgetydetails.${ind}.x`)} />
                                        </td>
                                        <td className={clsx("p-0", { 'form-error': errors.buyxgetydetails && (errors.buyxgetydetails[ind] as any)?.y })}>
                                            <input type="number" className="form-control form-control-sm border-0" {...register(`buyxgetydetails.${ind}.y`)} />
                                        </td>
                                        <td className={clsx("p-0", { 'form-error': errors.buyxgetydetails && (errors.buyxgetydetails[ind] as any)?.maxofy })}>
                                            <input type="number" className="form-control form-control-sm border-0" {...register(`buyxgetydetails.${ind}.maxofy`)} />
                                        </td>
                                        <td className={clsx("p-0", { 'form-error': errors.buyxgetydetails && (errors.buyxgetydetails[ind] as any)?.discountpercent })}>
                                            <input type="number" className="form-control form-control-sm border-0" value={watch(`buyxgetydetails.${ind}.discountpercent`)} onChange={(e) => {
                                                const val = e.target.value
                                                if (val && Number(val) < 0 || Number(val) > 100) return;
                                                setValue(`buyxgetydetails.${ind}.discountpercent`, e.target.value)
                                            }} />
                                        </td>
                                        <td className="p-0 text-center">
                                            <button
                                                type="button"
                                                className="btn btn-link text-danger fw-bold w-100 h-100"
                                                onClick={() => removeBuyX(item.id)}>
                                                -
                                            </button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <button type="button" className="btn btn-link p-0" onClick={addBuyXProduct}>+ Add product</button>
                    </>}
                </div>
            </div>

            <div className="row mt-6">
                <div className="col-12 col-lg-8">
                    <div className="card card-body">
                        <div className="fs-5 mb-3">Order Conditions</div>
                        <div className="mb-3">
                            <label>Minimum purchase amount</label>
                            <input className="form-control form-control-sm" {...register("minpurchaseamount")} />
                        </div>
                        <div className="mb-3">
                            <label>Minimum purchase qty</label>
                            <input className="form-control form-control-sm" {...register("minpurchasequantity")} />
                        </div>
                        <div>
                            <label>Order must contains product matched bellow conditions(All)</label>
                            <table className="table table-bordered border-secondary g-2">
                                <thead>
                                    <tr>
                                        <th style={{ width: '85px' }}>Type</th>
                                        <th style={{ width: '120px' }}>Operator</th>
                                        <th>Value</th>
                                        <th style={{ width: '125px' }}>Minimum quantity</th>
                                        <th style={{ width: '40px' }}></th>
                                    </tr>
                                </thead>
                                <tbody> {(watch("ordermustcontainsproduct") || [])
                                    .map((item: any, ind: number) => {
                                        const selected = optionSelects.find(f => f.value == watch(`ordermustcontainsproduct.${ind}.type`))

                                        let textValue = ''
                                        if (watch(`ordermustcontainsproduct.${ind}.value`)) {
                                            if (selected?.value == 'sku') {
                                                const isArray = watch(`ordermustcontainsproduct.${ind}.value`).indexOf(',') != -1
                                                if (isArray) {
                                                    const temps = watch(`ordermustcontainsproduct.${ind}.value`).split(',')
                                                    const temp = hospitemsData?.find((f: any) => f.code == temps.at(0))
                                                    textValue = temp?.name
                                                    if (temps.length > 1) {
                                                        textValue += ` + ${temps.length - 1} more`
                                                    }
                                                } else {
                                                    const temp = hospitemsData?.find((f: any) => f.code == watch(`ordermustcontainsproduct.${ind}.value`))
                                                    textValue = temp?.name
                                                }
                                            } else if (selected?.value == 'category') {
                                                const temp = categoriesData?.find((f: any) => f.code == watch(`ordermustcontainsproduct.${ind}.value`))
                                                textValue = temp?.name
                                            }
                                        }

                                        return <tr key={item.id}>
                                            <td className={clsx("p-0", { 'form-error': errors.ordermustcontainsproduct && (errors.ordermustcontainsproduct[ind] as any)?.type })}>
                                                <Controller
                                                    name={`ordermustcontainsproduct.${ind}.type`}
                                                    control={control}
                                                    render={({ field }) => <InputSelect
                                                        size="sm"
                                                        border={false}
                                                        options={optionSelects}
                                                        value={optionSelects.find(f => f.value == field.value)}
                                                        onChange={(e) => {
                                                            setValue(`ordermustcontainsproduct.${ind}.operator`, undefined)
                                                            setValue(`ordermustcontainsproduct.${ind}.value`, undefined)
                                                            field.onChange(e.value)
                                                            if (e.value == 'sku') {
                                                                setIsMultiple(true)
                                                                setValue(`ordermustcontainsproduct.${ind}.operator`, 'in')
                                                            } else if (e.value == 'category') {
                                                                setValue(`ordermustcontainsproduct.${ind}.operator`, 'in')
                                                            } else if (e.value == 'price') {
                                                                setValue(`ordermustcontainsproduct.${ind}.operator`, 'equal')
                                                            }
                                                        }}
                                                    />}
                                                />
                                            </td>
                                            <td className={clsx("p-0", { 'form-error': errors.ordermustcontainsproduct && (errors.ordermustcontainsproduct[ind] as any)?.operator })}>
                                                <Controller
                                                    name={`ordermustcontainsproduct.${ind}.operator`}
                                                    control={control}
                                                    render={({ field }) => <InputSelect
                                                        size="sm"
                                                        border={false}
                                                        options={optionOperators.filter(f => watch(`ordermustcontainsproduct.${ind}.type`) == 'price' ? f.con == 'number' : f.con == 'text')}
                                                        value={optionOperators.find(f => f.value == field.value)}
                                                        onChange={(e) => field.onChange(e.value)}
                                                    />}
                                                />
                                            </td>
                                            <td className={clsx("p-0", { 'form-error': errors.ordermustcontainsproduct && (errors.ordermustcontainsproduct[ind] as any)?.value })}>
                                                {selected?.type == 'select' && <div
                                                    className="text-primary ps-2 fs-7 cursor-pointer"
                                                    onClick={() => {
                                                        setTypeData(watch(`ordermustcontainsproduct.${ind}.type`) as any)
                                                        setValueSelected(`ordermustcontainsproduct.${ind}.value`)
                                                        if (watch(`ordermustcontainsproduct.${ind}.type`) == 'sku') {
                                                            setIsMultiple(true)
                                                        }
                                                    }}
                                                >
                                                    {watch(`ordermustcontainsproduct.${ind}.value`)
                                                        ? textValue
                                                        : watch(`ordermustcontainsproduct.${ind}.type`) == 'sku'
                                                            ? 'Choose SKUs'
                                                            : watch(`ordermustcontainsproduct.${ind}.type`) == 'category'
                                                                ? 'Choose categories'
                                                                : null
                                                    }
                                                </div>}
                                                {selected?.type == 'price' && <InputPrice
                                                    className="form-control form-control-sm border-0"
                                                    value={watch(`ordermustcontainsproduct.${ind}.value`)}
                                                    onChange={(e) => setValue(`ordermustcontainsproduct.${ind}.value`, e)}
                                                />}
                                            </td>
                                            <td className={clsx("p-0", { 'form-error': errors.ordermustcontainsproduct && (errors.ordermustcontainsproduct[ind] as any)?.minimumqty })}>
                                                <input className="form-control form-control-sm border-0" {...register(`ordermustcontainsproduct.${ind}.minimumqty`)} />
                                            </td>
                                            <td className="p-0 text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-link text-danger fw-bold w-100 h-100"
                                                    onClick={() => removeOrderCondition(item.id)}>
                                                    -
                                                </button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            <button type="button" className="btn btn-link p-0" onClick={addOrderCondition}>+ Add product</button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="card card-body h-100">
                        <div className="fs-5 mb-3">Member Conditions</div>
                        <Controller
                            name="clubconditions"
                            control={control}
                            render={({ field }) => <InputSelect
                                className="mb-4"
                                options={[]}
                                value={field.value}
                                onChange={field.onChange}
                                disabled
                            />}
                        />
                        <div className="mb-3">
                            <label>Scheme</label>
                            <input disabled className="form-control form-control-sm" {...register("schemeconditions")} />
                            <span>Use comma when you have multi scheme</span>
                        </div>
                        <div className="mb-3">
                            <label>Member's purchase</label>
                            <input disabled className="form-control form-control-sm" {...register("memberminimumpurchaseorder")} />
                            <span>Minimum purchase amount</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <ModalListSelectedMutiProduct
            hospitemsData={hospitemsData || []}
            categoriesData={categoriesData || []}
            subCategoriesData={[]}
            isMultiple={isMultiple}
            typeData={typeData}
            watch={watch}
            valueSelected={valueSelected}
            onSave={(value) => {
                if (valueSelected) {
                    console.log(value)
                    setValue(valueSelected as any, isMultiple ? value.map((val: any) => val?.value).join(',') : value[0]?.value)
                }
                setValueSelected(undefined)
                setIsMultiple(false)
            }}
            onClose={() => {
                setValueSelected(undefined)
                setIsMultiple(false)
            }}
        />
    </>
}

interface IModalListSelectedMutiProduct {
    hospitemsData: IFnbHospitalityItem[]
    categoriesData: IFnbCategory[]
    subCategoriesData: any[]
    isMultiple: boolean
    typeData?: 'sku' | 'category' | 'subcategory'
    watch: UseFormWatch<any>
    valueSelected: any
    onSave: (value: any) => void
    onClose: () => void
}

export const ModalListSelectedMutiProduct = ({ isMultiple, typeData, hospitemsData, categoriesData, subCategoriesData, watch, valueSelected, onClose, onSave }: IModalListSelectedMutiProduct) => {
    const [search, setSearch] = useState('')
    const [selected, setSelected] = useState<any[]>([])
    const dataSource: any[] = useMemo(() => {
        if (typeData == 'sku') {
            return hospitemsData.map(item => ({
                type: 'hospitems',
                value: item.code,
                label: item.name,
                image: item.images?.find((f: any) => f.isDefault)?.url,
                unitofmeasure: item.unitofmeasure
            }))
        } else if (typeData == 'category') {
            return categoriesData.map(item => ({
                type: 'category',
                value: item.code,
                label: item.name,
                image: item.image
            }))
        } else if (typeData == 'subcategory') {
            return subCategoriesData.map(item => ({
                type: 'subcategory',
                value: item.code,
                label: item.name,
                image: item.image
            }))
        }
        return []
    }, [search, typeData])

    useEffect(() => {
        const val = watch(valueSelected)
        if (isMultiple && val) {
            const temps = val.split(',') || []
            setSelected(temps.map((item: any) => {
                const temp = dataSource.find(f => f.value == item)
                return temp
            }))
        } else if (val) {
            const temp = dataSource.find(f => f.value == val)
            temp && setSelected([temp])
        } else {
            setSelected([])
        }
    }, [valueSelected])

    function onSearch() {
        if (!search) return dataSource
        return dataSource.filter((item: any) => {
            return item.name.toLowerCase().includes(search.toLowerCase())
        })
    }

    const ItemRenderer = ({ index, data, style }: { index: number, data: any[], style: any }) => {
        const item = data[index]
        const image = item.image || 'https://via.placeholder.com/40'
        const isSelected = selected.find(f => f.value == item.value)

        return <div style={style} className="d-flex align-item-center gap-3 border-bottom py-2">
            {item.image
                ? <img src={image} className="rounded w-40px h-40px object-cover" alt={'listitem'} />
                : <div className="rounded w-40px h-40px bg-gray-200 d-flex flex-center">
                    <i className="bi bi-box fs-3"></i>
                </div>}
            <div className="flex-1">
                <div>{item.label}</div>
                <div>{item.value}</div>
            </div>
            <div className="ms-auto me-1">
                <button
                    type="button"
                    className={clsx("btn btn-sm btn-icon p-1", {
                        "btn-success": isSelected,
                        "border border-2 border-secondary bg-hover-light": !isSelected
                    })}
                    onClick={() => {
                        if (isSelected) {
                            setSelected(pre => pre.filter(f => f.value != item.code))
                        } else {
                            setSelected(pre => {
                                // console.log(item)
                                if (isMultiple) {
                                    return [...(pre || []), item]
                                }
                                return [item]
                            })
                        }
                    }}>
                    <i className="bi bi-check fs-2"></i>
                </button>
            </div>
        </div>
    }

    return <Modal show={valueSelected != undefined} onHide={onClose} centered>
        <Modal.Body>
            <div className="fs-4 mb-3">Select {typeData == 'sku' ? 'Products' : typeData == 'category' ? 'Categories' : ''}</div>
            <input
                className="form-control form-control-sm mb-4"
                type="text"
                value={search} onChange={(e) => {
                    setSearch(e.target.value)
                }}
            />
            <FixedSizeList
                useIsScrolling
                itemData={onSearch() || []}
                height={400}
                width={'100%'}
                itemSize={50}
                itemCount={onSearch().length || 0}
            >
                {ItemRenderer}
            </FixedSizeList>
            <div className="mt-6 d-flex gap-2">
                <button type="button" onClick={onClose} className="btn btn-sm btn-secondary ms-auto">Close</button>
                <button type="button" onClick={() => onSave(selected)} className="btn btn-sm btn-primary">Save changes</button>
            </div>
        </Modal.Body>
    </Modal>
}

