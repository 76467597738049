import clsx from "clsx";
import { useContext, useEffect, useRef } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import { KTSVG } from "./KTSVG";

export function AccordionToggle({ children, eventKey, callback, isIcon, className, resettoggle }: any) {
    const { activeEventKey } = useContext(AccordionContext);
    const refClick = useRef<any>()
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );
    const isCurrentEventKey = activeEventKey === eventKey;
    useEffect(()=>{
        if(resettoggle && isCurrentEventKey) {
            refClick.current?.click()
        }
    },[resettoggle])
    return (
        <div {...{ className: clsx(className, 'cursor-pointer h-100') }} ref={refClick} onClick={decoratedOnClick} >
            {isIcon ? <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className={clsx('svg-icon-3 m-0 rotate', !isCurrentEventKey && 'rotate-icon-90' )} />
                : children}
        </div>
    );
}