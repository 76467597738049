/* eslint-disable */
import { debounce } from 'lodash'
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
// import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx'
import { Dropdown, Modal } from 'react-bootstrap'
import ReactDataSheet from 'react-datasheet'
import 'react-datasheet/lib/react-datasheet.css'
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Link, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ApiService } from '../../../../theme/helpers/ApiService'
import SearchPopup from '../../../../theme/partials/layout/search-popup'
import { KTFormItem } from '../../../../theme/partials/widgets/utils/KTFormItem'
import { KTSVG } from '../../../../theme/partials/widgets/utils/KTSVG'
import { useAuth } from '../../../modules/auth'
import { ShowForPermission, getIsPermission } from '../../../modules/auth/Permission'
import { popupCustomQuestion, popupDelete, popupInputHTML, popupMessage, popupQuestion } from '../../../modules/messages'
import { InputDate, InputSelect, InputPrice, FormSubmit, KTButton, TextFormByUser, CustomMenuList, CustomOption } from '../../../../theme/partials'
import { ApiShowError, exportCSV, exportExcel, formatCurrent, formatOptions, getLocalStorage, getValueId, reFormatCurrent, sortWithAny, sortWithDate, unique_arr } from '../../../../theme/helpers'
import { ContentHeader } from '../../../../theme/layout/components/content'
import { useApprovalHistories, useOrderIdByQuery } from '../../../modules/api/requests'
import { LocalData } from '../../../modules/api/localData'
import { apiPrivate } from '../../../modules/api'
// import CustomRendererSheet from '../../../../theme/partials/widgets/CustomRendererSheet'
import { RenderSheetData } from '../../../../theme/partials/widgets/CustomRendererSheet/frameTable'
import { apiAd } from '../../../modules/api/admin'
import { approvalusersetupsByQuery, convertOperatorObject, convertStatus, convertToIfCondition, customFilter, formatApprovalLevel, formatDataLevel, getEmailInfo, getInforGroup, getOptions, historiesApproval } from './function'
import { ActionApprovals } from "./actions";
import { useIntl } from 'react-intl'
import { ImportModalRequest } from './importModal'
import ReactSelect, { GroupBase, StylesConfig, components, createFilter } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncSelect from 'react-select/async'
import { InputSelectCell } from './InputSelectCell'


const TemplateComponent: FC<{ permission: string }> = ({ permission }) => {
  const permission_modify = getIsPermission([permission,'MODIFY'])
  const permission_insert = getIsPermission([permission,'INSERT'])
  
  const { id } = useParams() as any
  const urlApi = 'orders/requestorders'
  const { isEdit, valueid } = getValueId(id)
  const {auth} = useAuth()
  const {user} = auth || {} as any
  const navigate = useNavigate();
  const { data: dataDepartments } = apiPrivate.useDepartments()
  const { data: dataItems } = apiPrivate.useItems()
  let {selectedLang} = getLocalStorage('siteConfig') as any
  selectedLang = selectedLang||'vi'
  const [optionsDepts,setOptionsDepts] = useState<any>([])

  const [optionsItems,setOptionsItems] = useState<any>([])
  const [optionsUoms, setOptionsUoms] = useState<any>([])
  const [idRequest,setidRequest] = useState<any>(valueid)
  const { data: dataQuery, isSuccess, isFetching } = useOrderIdByQuery(idRequest,'?expand=requestorderlines,users')
  const [ dataRequest, setDataRequest] = useState<any>(dataQuery)
  
  const { data: dataApprovalHistories,refetch: refetchApproval } = useApprovalHistories(idRequest && `?documentid=${idRequest}&expand=approvaldetails`)||{}
  const { data: dataApprovals, isSuccess: isSuccessApprovals, isFetching: isFetchingApp, refetch: dataSetupApproval } = apiAd.useApprovalsByQuery(
    idRequest ? dataRequest && `?blocked=true&objectcode=${permission}&expand=approvalleveldetails(${dataRequest?.createdby})`:
    `?blocked=true&objectcode=${permission}&expand=approvalleveldetails(${user?.id})`
    )
  const [autoApprovalRequest,setAutoApprovalRequest] = useState<any>(false)
  const [approvalLevels,setApprovalLevels] = useState<any>([])
  const [requestUser,setRequestUser] = useState<any>(null)
  const [approvalUser,setApprovalUser] = useState<any>(null)
  const [approvalhistories,setApprovalhistories] = useState<any>(null)
  const { data: objectData, isSuccess: isObjectData } = apiAd.useObjectsByNumber(permission)
  const arrObject: any = isObjectData ? objectData[0] : undefined
  const refApprovalRequest = useRef<any>()
  const refSaveBtn = useRef<any>()
  const [isEditNotApproval,setIsEditNotApproval] = useState<any>(false)
  const [isHasChange,setIsHasChange] = useState<any>(false)
  const [emailnotification,setEmailnotification] = useState<any>({})
  useEffect(() => {
    if (dataDepartments && dataDepartments.length != 0) {
      setOptionsDepts(formatOptions(dataDepartments, ['id', 'code']))
    }
  }, [dataDepartments])
  const [isShowImport, setIsShowImport] = useState<boolean>(false)
  const handleCloseModalImport = () => {
    setIsShowImport(false)
  }
  const handleAddModalImport = () => {
    setIsShowImport(true)
    
  }
  const templateCell = (props: any) => {
    const {label, subTitle} = props
    return (
      <div className='text-start'>
        <div>{label}</div>
        {/* <div className='d-block text-gray-700'>
          {subTitle}
        </div> */}
      </div>
    )
  }
  const SelectEditorPopup = (props: any) => {
    const state = {} as any
    let optionsData = props.cell.optionsData
    const templateCell = props.cell.templateCell
    let createdSelect = props.cell.createdSelect
    const checkCreated = props.cell.checkCreated
    const index = props.row
    const col = props.col
    let isInputPopup = false
    // console.log('props',props)
      let code = watch('code_'+index)
      let itemid = watch('itemid_'+index)
      let type = watch('type_order_'+index)
    if(checkCreated) {
      createdSelect = true
      // console.log('type',type)
      if(type && (type == '284210000'|| type?.toLowerCase() == 'item')) {
        isInputPopup = true
        optionsData = optionsData||optionsItems
      }
      else {
        optionsData = []
      }
    }
    let style: StylesConfig<any, false, GroupBase<any>> | undefined = {
      menu: (provided: any) => ({
        ...provided,
        width: "max-content",
        minWidth: "100%"
      }),
      // control: (base: any, state: any) => ({
      //   ...base,
      //   border: state.isFocused ? 0 : 0,
      //   // This line disable the blue border
      //   boxShadow: state.isFocused ? 0 : 0,
      //   "&:hover": {
      //     border: state.isFocused ? 0 : 0
      //   }
      // }),
      option: (base: any, { isFocused, isSelected, isDisabled }: any) => ({
        ...base,
        lineHeight: 1.5,
        color: isSelected ? '#fff' : '#5e6278',
        zIndex: 1,
        backgroundColor: isSelected ? '#009ef7' : 'transparent',
        borderRadius: '5px',
        marginBottom: '3px',
        ':hover': {
          backgroundColor: isSelected ? '#009ef7' : '#f6f6f6'
        },
      }),
      control: (base: any, { menuIsOpen, isMulti, isDisabled }: any) => ({
        ...base,
        boxShadow: '#b5b5c3',
        ':focus': {
          color: '#5e6278',
          backgroundColor: '#ffffff',
          borderColor: '#b5b5c3',
          outline: 0,
          boxShadow: 'inset 0 1px 2px rgb(0 0 0 / 8%), 0 0 0 0.25rem rgb(0 158 247 / 25%)',
        },
        ':hover': {
          borderColor: '#b5b5c3'
        },
      }),
      menuList: (base: any) => ({
        ...base,
        padding: '3px 3px 3px 3px'
      }),
      singleValue: (provided: any, state: any) => {
        const transition = 'opacity 300ms';
        const noBorder = {}
  
        return {
          ...provided,
          ...noBorder,
          color: '#181c32',
          transition,
          lineHeight: 1.5,
        };
      },
      } as any
      const formatOptionLabel = (props: any) => {
        const {label, value, subTitle} = props
        return (
          <div className='text-start'>
            <div>{label}</div>
            {/* <div className='d-block text-gray-700'>
              {subTitle}
            </div> */}
          </div>
        )
      };

    // if(typeCell == 'custom-name') {
    //   optionsData =  optionsPayments('name').filter((i:any)=>!checkChoise(i.value))
    // }
    // if(typeCell == 'custom-code') {
    //   optionsData =  optionsPayments('code').filter((i:any)=>!checkChoise(i.value))
    //   style.indicatorsContainer = (prevStyle: any, state: any) => (
    //     {
    //       ...prevStyle,
    //       display: 'none'
    //     }
    //   )
    // }
    const handleChange = (e: any) => {
      const {onCommit, onRevert} = props
      if (!e) {
        return onRevert()
      }
      // if(col == 0) {
      //   setValue('type_'+index,e.value)
      // }
      let value = e.value
      if(checkCreated) {
        value = e.itemno||e.value
      }
      onCommit(value, state.e)
    };
    let options = optionsData.map((e:any)=>({ ...e, value: e.label }))
    const handleKeyDown = (e: any) => {
      var TAB_KEY = 9;
      var ENTER_KEY =  13;
      // record last key pressed so we can handle enter
      if (e.which === ENTER_KEY || e.which === TAB_KEY) {
        e.persist()
        state.e = e
      } else {
        state.e = null
      }
    }
    return isInputPopup?
    <InputSelectCell
        isAsync
        options={options}
        value={props.value}
        onChange={handleChange}
        formatOptionLabel={templateCell?templateCell:formatOptionLabel}
        className='custom-select-cell'
        filterOption={customFilter}

    />
    :
    createdSelect?
    <CreatableSelect
      autoFocus
      openMenuOnFocus
      closeMenuOnSelect
      value={props.value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      options={options}
      className='custom-select-cell custom-search-select'
      styles={style}
      placeholder={""}
      formatOptionLabel={templateCell?templateCell:formatOptionLabel}
      filterOption={customFilter}
    />:
  col == '0'?
  <div className='wrap-action-row'>
    <div className='w-100 custom-select-table'>
      <ReactSelect
        autoFocus
        openMenuOnFocus
        closeMenuOnSelect
        value={props.value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        options={options}
        className='custom-select-cell '
        styles={style}
        placeholder={""}
        formatOptionLabel={templateCell?templateCell:formatOptionLabel}
        hideSelectedOptions
        filterOption={customFilter}

      />
      
    </div>
  </div>
  :
  <ReactSelect
    autoFocus
    openMenuOnFocus
    closeMenuOnSelect
    value={props.value}
    onChange={handleChange}
    onKeyDown={handleKeyDown}
    options={options}
    className='custom-select-cell custom-search-select'
    styles={style}
    placeholder={""}
    formatOptionLabel={templateCell?templateCell:formatOptionLabel}
    filterOption={customFilter}

  />
     
    
  };
  const SelectEditorPopup1 = (props: any) => {
    // console.log('props',props)
    let { value, onKeyDown, row } = props;
    if(!value) {
      value = props.cell.value
    }
    // console.log('props',props)
    // console.log('value',value)
    const optionsData = props.cell.optionsData||optionsUoms
    const _input = useRef() as any
     setTimeout(()=> {
      _input?.current?.focus()
     },100)
    const handleChange = (e: any) => {
      let input_ele = e.target
      if(!e.target) {
        input_ele = e
      }
      // console.log('input_ele',input_ele.value)
      props.onChange(input_ele.value);
    };
    let options = optionsData.map((e:any)=>({ props: props, ...e }))
    return (
      <div className='w-100 '>
        <div className='d-flex flex-stack'>
          <input
            type='text'
            // ref={_input}
            onChange={handleChange} 
            // onKeyDown={onKeyDown}
            value={value}
            className={clsx('form-control form-control-sm border-0 data-editor bg-transparent')}
             /> 
          <span className='py-3 pe-3 cursor-pointer'
            onClick={(e:any) => {
              e.preventDefault()
              // handleChange(_input?.current)
              handleSearch(
              [
                  ['value'],
                  ['label'],
                  ['props'],
              ],
              options,
              ['title', 'description'],
              )}
              }><i className="bi bi-chevron-down"></i>
          </span>
        </div>
        </div>
    );
  };
  
  const [defaultRow,setDefaultRow] = useState<any>([ 
    { value: '' , dataEditor: SelectEditorPopup, optionsData: Object.values(LocalData.requestType)}, 
    { value: '' , dataEditor: SelectEditorPopup, optionsData: optionsItems,checkCreated: true, templateCell: templateCell}, 
    { value: '' , }, 
    { value: '', className: 'text-center', dataEditor: SelectEditorPopup, optionsData: optionsUoms },
    { value: '', className: 'text-center' }, 
    { value: '', readOnly: true, className: 'text-center' }, 
    { value: '', className: 'text-center' }, 
    { value: '', readOnly: true, className: 'text-center' }, 
    { value: '' }
  ])

  useEffect(() => {
    const funcApproval = async() => {
      if(isSuccessApprovals) {
        let tempDataLevel = null as any
        let id_request = idRequest||valueid as any

        if(dataApprovals) {
          const dataApprovalsSort = sortWithAny(dataApprovals,{sort:'priority',order:'asc'})
          // console.log('dataApprovalsSort',dataApprovalsSort)
          let checkCond = true
          let groupUser = await getInforGroup(dataRequest?.createdby)||[] as any
          dataApprovalsSort.forEach((item:any) => {
            if(!checkCond) return
            if(dataRequest) {
              setEmailnotification(getEmailInfo(item.emailnotification,dataRequest,permission))
            }
            
            if(item.type != 20120002) {
              approvalusersetupsByQuery(setRequestUser,setApprovalUser, setApprovalLevels ,user?.id, dataRequest)
              checkCond = false
              return
            }
            else {
              let tempArr = [] as any
              let criteria_string = item.rule?.criteria_string||"1" as string
              let not_rule = true as boolean
              if(item.rule && item.rule.columns && item.rule.columns.length != 0) {
                item.rule?.columns.forEach((it: any) => {
                  let item = it
                  not_rule = false
                  if(item.field == 'usergroup' && item.comparator == 'is' && id_request) {
                    let check = groupUser.find((i: any) => i.usergroupid == item.value)
                    let a = check? true: false
                    tempArr.push(a)
                  }
                  else if(dataRequest) {
                    tempArr.push(convertOperatorObject[item.comparator](dataRequest[item.field],item.value,item.datatype))
                  }
                })
              }
              if (tempArr.length == 0 && dataRequest && not_rule) {
                tempArr = [true]
              }
              // console.log('tempArr',tempArr)
              const ifCondition = convertToIfCondition(criteria_string,tempArr);
              // console.log('ifCondition',ifCondition)
              if (eval(ifCondition)) {
                // console.log("Điều kiện đúng");
                tempDataLevel = formatDataLevel(item?.approvallevels||[],dataRequest?.createdby||user?.id, item?.approvalreminderbefore)
                // console.log("tempDataLevel",tempDataLevel);
                setApprovalLevels(tempDataLevel)
                // console.log('dataRequest',dataRequest)
                if(user?.id == dataRequest?.createdby) {
                  item.approvalid = item.id
                  setRequestUser(item)
                  setIsEditNotApproval(false)
                }
                else if (tempDataLevel?.find((i:any)=>i.userid == user?.id)){
                  setApprovalUser(item)
                }
                checkCond = false
                return
    
              } else {
                // console.log("Điều kiện sai");
                setApprovalLevels(null)
                setApprovalUser(null)
                setRequestUser(null)
                setAutoApprovalRequest(false)
                if(user?.id == dataRequest?.createdby  && dataRequest.status == '284210000') {
                  setIsEditNotApproval(true)
                }
              }
            }
          })
          
        }
        else {
          tempDataLevel = approvalusersetupsByQuery(setRequestUser,setApprovalUser, setApprovalLevels ,user?.id, dataRequest)
        }
        if(id_request) {
          if((isEditNotApproval || (!tempDataLevel && (user?.id == dataRequest?.createdby && dataRequest.status == '284210000'))) && isSuccessApprovals) {
            popupMessage({icon: "info", description: intl.formatMessage({ id: `text.workflows-not-defined`}) })
          }
          else if(autoApprovalRequest) {
            setTimeout(()=>{refApprovalRequest?.current?.click()},100)
          } 
        }
      }
    }
    if(isSuccessApprovals && !isFetchingApp && dataApprovals) {
      funcApproval()
    }
    
}, [isSuccessApprovals,isFetchingApp])
  useEffect(() => {
    if(dataQuery && isSuccess) {
      let data = JSON.parse(JSON.stringify(dataQuery))
      if(data) {
        data.documentdate = data.documentdate && [new Date(data.documentdate)]
        data.expecteddate = data.expecteddate && [new Date(data.expecteddate)]
        getData(data)
      }
    }
      
  }, [dataQuery])
  useEffect(() => {
    if(!valueid) {
      getData(undefined)
    }
}, [id])
  useEffect(() => {
    setApprovalhistories(null)
  }, [])
  
  useEffect(() => {
    if(dataApprovalHistories && dataApprovalHistories.length != 0) {
      setApprovalhistories(dataApprovalHistories[0])
    }
    else {
      setApprovalhistories(null)
    }
  }, [dataApprovalHistories])
  
 
  const checkIsDisabledForm:any = () => {
    // console.log('idRequest',idRequest)
    // console.log('requestUser',requestUser)
    if(!permission_insert && !idRequest) return true
    else if(!permission_modify) return true
    if(isEditNotApproval) return false
    if(!idRequest) return false
    if (!requestUser) return true
    return  (approvalhistories?.currentstatus == '284210001' || approvalhistories?.currentstatus == '284210002' || approvalhistories?.currentstatus == '284210004')
  }
  // useEffect(() => {
  //   if(dataItems) {
  //     setOptionsItems(
  //       dataItems.map((e: any) => ({ label: e.itemno, value: e.id, title: e.itemno, ...e }))
  //       )
  //   }
  // }, [dataItems])
  let schema = {
    requestto: yup.string()
      .required('Input is required'),
    title: yup.string()
      .required('Input is required'),
    purpose: yup.string()
      .required('Input is required'),
    documentdate: yup.array()
      .required('Input is required'),
    departmentid: yup.string()
      .required('Input is required'),
    expecteddate: yup.array()
      .required('Input is required'),
  }
  if(!(arrObject?.nosericode && !idRequest)) {
    schema = Object.assign(schema,{code: yup.string()
      .required('Input is required')})
  }
  const yupSchema = yup.object().shape(schema)

  const { register, control, reset, handleSubmit,getValues, setValue, watch, setError, formState: { errors, isSubmitting } } = useForm({ resolver: yupResolver(yupSchema) })
  const [tableData, setTableData] = useState<any>([])
  const [isCheck, setIsCheck] = useState<Array<any>>([])
  const [isCheckAll, setIsCheckAll] = useState(false);

  const [keys, setKeys] = useState<Array<Array<any>>>([])
  const [filters, setFilters] = useState<Array<string>>([])
  const [searchData, setSearchData] = useState<any[]>([])
  function handleSearch(keys: Array<Array<any>>, data: any[], filters: string[]) {
      setKeys(keys)
      setSearchData(data)
      setFilters(filters)
  }
  const handleSelectAll = (e:any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(tableData.map((item:any,index:number) => index.toString()));
    if (isCheckAll) {
      setIsCheck([]);
      setIsShowMore(false)
    }
};
  const intl = useIntl()
  const header = [
//     {
//     title: '',
//     class: 'text-center w-30px',
//     type: 'check',
// },
{
  title: '#',
  class: 'text-center w-30px',
},
{
  title: intl.formatMessage({ id: `text.request.type` as any }),
  class: 'text-start select-arrow w-100px',
},
 {
    title: intl.formatMessage({ id: `text.code` as any }),
    class: 'text-center w-100px',
},{
    title: intl.formatMessage({ id: `text.item.name` as any }),
    class: '',
},  {
  title: intl.formatMessage({ id: `text.uom` as any }),
  class: 'w-70px text-center',
},{
    title: intl.formatMessage({ id: `text.quantity` as any }),
    class: 'w-70px text-center',
},  {
    title: intl.formatMessage({ id: `text.system-unit-price` as any }),
    class: 'w-100px text-center',
},
 {
    title: intl.formatMessage({ id: `text.unit-price` as any }),
    class: 'w-100px text-center',
},
{
  title: intl.formatMessage({ id: `text.total` as any }),
  class: 'w-100px text-center',
},

{
  title: intl.formatMessage({ id: `text.note` as any }),
  class: '',
},]
const SelectEditor = (props: any) => {
  const handleChange = (e: any) => {
    props.onChange(e.value);
  };
  let dataOption = Object.values(LocalData.requestType)
  dataOption = dataOption?.map((item:any)=> {
    return {
      label: item.label,
      value: item.label,
      codeValue: item.value,
    }
  })
  let { value, onKeyDown,row } = props;
  // console.log('dataOption',dataOption)
  // console.log('RangeEditor',props)
  return (
    <div className="d-flex">
      <div className='w-100 custom-select-table'>
        <InputSelect
            options={dataOption}
            isClearable
            value={dataOption?.find((e: any) => e.value == value)|| ''}
            onChange={e => {
              // console.log('getValues(type_invoice)',getValues(type_invoice))
              // setValue(item_code,'')
            // console.log('e.value',e)
              // clearRowTable(index)
              if (!e) return handleChange(e)
                handleChange(e)
                // checkDiscount()
              }
            }
            defaultValue={284210000}
          />
      </div>
        <Dropdown className={clsx('action-row-table')}>
          <Dropdown.Toggle as={'div'} className='btn btn-light btn-active-light-primary btn-sm btn-icon h-el-after'>
            <i className="bi bi-three-dots-vertical" />
          </Dropdown.Toggle>
          <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px p-4' >
            <Dropdown.Item as='div' className='menu-item disabled'>
              <span className='menu-link px-3' onClick={e=> handleSelectMore(row)}> Select more </span>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')}>
              <span className='menu-link px-3' onClick={e=>handleClickRemoveItem(row)}> Remove row </span>
            </Dropdown.Item>
            <Dropdown.Item as='div'  className={clsx('menu-item')}>
              <span className='menu-link px-3' onClick={e=>clearRowTable(row)}> Clear row </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
    </div>
    // <input
    //   type="range"
    //   className="data-editor"
    //   value={value}
    //   min="1"
    //   max="5"
    //   onChange={handleChange}
    //   onKeyDown={onKeyDown}
    // />
  );
};


const renderRowTable = (data:any,id?: string,index?: number,listUoms?: any, listItems?: any) => {
  return [
    { 
      value: data.type , 
      dataEditor: SelectEditorPopup, 
      optionsData: Object.values(LocalData.requestType),
      id: id,
      index: index,
      readOnly: checkIsDisabledForm()
    }, 
    { value: data.itemcode||data.itemno , 
      dataEditor: SelectEditorPopup, 
      optionsData: listItems|| optionsItems,
      checkCreated: true,
      templateCell: templateCell,
      readOnly: checkIsDisabledForm()
    }, 
    {
      value: data.description,
      // itemid: id ? data.itemid : data.id,
      // itemcode: id ? data.itemcode : data.itemno,
      // id: id,
      className: '',
      readOnly: checkIsDisabledForm()
      // index: index,
    },
    {
      value: id ? data.uom : data.baseunitofmeasure,
      className: 'text-center',
      dataEditor: SelectEditorPopup, 
      optionsData: listUoms|| optionsUoms,
      readOnly: checkIsDisabledForm()
    },
    {
      value: id ? formatCurrent(data.quantity) : 1,
      className: 'text-center',
      readOnly: checkIsDisabledForm()
    },
    {
      value: id? formatCurrent(data.unitprice):formatCurrent(data.lastdirectcost),
      readOnly: true,
      className: 'text-center',
    },
    {
      value: id? formatCurrent(data.unitpriceref) : '',
      className: 'text-center',
      readOnly: checkIsDisabledForm()
    },
    {
      value: id ? formatCurrent(Number((data.unitpriceref*data.quantity).toFixed(0))) : formatCurrent(data.lastdirectcost*1),
      readOnly: true, 
      className: 'text-center',
    },
    {
      value: id ? data.remark : '',
      readOnly: checkIsDisabledForm()
    }
  ]
}
  const renderDetails = (RequestOrderLines?:any,uomArr?: any,itemsArr?: any) => {
    let row = [ 
      { value: '' , dataEditor: SelectEditorPopup, optionsData: Object.values(LocalData.requestType)}, 
      { value: '' , dataEditor: SelectEditorPopup, optionsData: itemsArr, checkCreated: true}, 
      { value: '' }, 
      { value: '', className: 'text-center', dataEditor: SelectEditorPopup, optionsData: uomArr },
      { value: '', className: 'text-center' }, 
      { value: '', readOnly: true, className: 'text-center' }, 
      { value: '', className: 'text-center' }, 
      { value: '', readOnly: true, className: 'text-center' }, 
      { value: '' }
    ]
    setDefaultRow(row)
    // console.log('DefaultRow',defaultRow)
    // console.log('uomArr',uomArr)
    if(RequestOrderLines) {
      let tempData = [] as any
      RequestOrderLines.forEach((item:any,index:number) => {
        // console.log('item',item)
        // (item[0].value || record[type_order]) == 'Item'?'284210000':''
        item.type = item.type == '284210000'? "Item":''
        setValue('code_'+index,item.itemcode)
        setValue('type_order_'+index,item.type)
        let temp = renderRowTable(item,item.id,index,uomArr, itemsArr)
        tempData.push(temp)
      });
      // console.log('defaultRow',checkIsDisabledForm())
      if(watch('status') == "284210001" || watch('status') == "284210002" ) {
        setTableData(tempData)
      }
      else {
        setTableData(tempData.concat([row]))
      }
      // setTableData(tempData.concat([row]))

      setValue('item_code_'+tempData.length,'')
      setValue('type_order_'+tempData.length,'')
    }
    else {
      // console.log('aaa')
      setTableData([row])
    }
  }
  const getItems = async() => {
    let {data} = await ApiService.get('settings/items')
    let formatOption = data.map((e: any) => ({ label: e.itemno+'/'+e.description, value: e.id, title: e.itemno,subTitle: e.description, ...e })) as any
    return formatOption
  }
  const getData = async(data: any) => {
    if(!user) return;
    setDataRequest(data)
    let dataReset = data||{
        "statuscode": 1,
        "code": null,
        "requestto": null,
        "title": null,
        "purpose": null,
        "documentdate": [new Date()],
        "expecteddate": null,
        "departmentcode": user.departmentcode,
        "departmentname": user.departmentname,
        "departmentid": user.departmentid,
        "status": "284210000",
        "createdat": [new Date()],
        "creater": user?.username
    } as any
    reset(dataReset)
    const {RequestOrderLines} = data||[]
    let uomArr = [] as any
    let itemsArr = [] as any
    if(!(optionsUoms && optionsUoms.length != 0)) {
      uomArr = await getOptions('settings/unitofmeasures')
      setOptionsUoms(uomArr)
    }
    if(!(optionsItems && optionsItems.length != 0)) {
      itemsArr = await getItems()
      setOptionsItems(itemsArr)
    }
    
    renderDetails(RequestOrderLines,uomArr,itemsArr)

  }
  const checkTableError = (table: any) => {
    let check = false
    let classError = 'form-error'
    table.forEach((item:any,ind:number)=> {
      if(ind == table.length - 1 && table.length>1 && (!item[2].value && !item[3].value&& !item[4].value && !item[6].value)) {
        return
      }
      item.forEach((i:any,index: number)=> {
        if(index == 0 || index == 1 || index == 5 || index == 7 || index == 8) {
          return
        }
        if(i.value =="") {
          i.className = i.className?.replace(classError,'')+' '+ classError
          check = true
        }
      })
    })
    // console.log('table',table)
    setTableData(table.concat([defaultRow]))
    return check
  }
  const onSubmit = async(record: any) => {
    // console.log('tableData',tableData)
    let ids = tableData.filter((item:any)=>(item[0].isDeleted && !item[0].doneDelete) ).map((i:any)=>i[0].id)
    ids = unique_arr(ids).filter((item:any)=> item)
    let arrDelete = {
      ids: ids
    } as any
    
    // console.log('record',record)
    let datatempCheck = tableData.map((row:any) => [...row])?.filter((i:any)=> (i[2]?.value && i[3]?.value) && !arrDelete.ids.includes(i[0].id))
    if(datatempCheck.length == 0) {
      popupMessage({ icon: 'info',description: intl.formatMessage({ id: `text.detail-not-empty` as any }) })
      return
    }
    let checkTable = checkTableError(datatempCheck)
    if(checkTable) {
      return
    }
    let RequestOrderLines = [] as any
    
    tableData.forEach((item:any,index:number)=>{
      if(item[0].isDeleted ||!item[2]?.value || !item[3]?.value || !item[4]?.value || !item[6]?.value ) {
        return
      }
      // console.log('item',item)
      let type_order = 'type_order_'+index
      let item_code = 'code_'+index
      let temp = {
        "requestorderid": idRequest,
        // "id": item[0].id,
        "requestordercode": record.code,
        "type": (item[0].value || record[type_order]) == 'Item'?'284210000':'',
        "itemcode": item[1].value || record[item_code],//record[item_code],
        "itemid": item[0].itemid,
        "description": item[2].value,
        "uom": item[3].value,
        "quantity": reFormatCurrent(item[4].value),
        "unitprice": reFormatCurrent(item[5].value),
        "unitpriceref": reFormatCurrent(item[6].value), 
        "amountref": reFormatCurrent(item[7].value), 
        "remark": item[8].value,
        "statuscode": 1,
        "unitpricebudget": null,
        "amountbudget": null,
        "budgetid": null,
        "budgetcode": null,
        "budgetname": null,
        "budgetlineid": null,

      }  
      RequestOrderLines.push(temp)
    })
    // console.log('record',record)
    let tempData = {
      "id": valueid || idRequest,
      "statuscode": 1,
      "code": record.code|| null,
      "documentdate": record.documentdate? new Date(record.documentdate[0]):undefined,
      "requestto": record.requestto,
      "title": record.title,
      "purpose": record.purpose,
      "departmentid": record.departmentid,
      "departmentcode": record.departmentcode,
      "departmentname": record.departmentname,
      "expecteddate": record.expecteddate? record.expecteddate[0]:undefined,
      "status": record.status,
      "totalamount": reFormatCurrent(totallineitemAmount()),
      "RequestOrderLines": RequestOrderLines,
    } as any
    if (!arrObject) return popupMessage({ icon: 'error' })
    if (!arrObject?.nosericode && !record.code) return setError(`code`, { type: 'validate' }, { shouldFocus: true })
    let nosericode = arrObject?.nosericode && !isEdit ? `/?nosericode=${arrObject?.nosericode}` : ''
    let chooseTypeSubmit = {} as any
    if(isHasChange && !idRequest) {
      chooseTypeSubmit = RequestOrderLines.length == 0 ? {} 
      : 
      await popupCustomQuestion(
      { description: intl.formatMessage({ id: `text.please-quickly-select-an-action`}),
      confirmButtonText: intl.formatMessage({ id: `text.save-and-create`}),
      denyButtonText: intl.formatMessage({ id: `text.save-and-send-request`}),
      showDenyButton: !isEditNotApproval && true, 
      denyButton:"btn-success"}
      )
      if(chooseTypeSubmit.isDismissed == true) {
        return
      }
    }
    try {
      if(arrDelete.ids.length!=0) {
        await ApiService.post(`orders/requestorderlines/deletemulti`, arrDelete)
      }
      const method = 'post'//isEdit ? 'put' : 'post'
      setIsHasChange(false)
      if(!idRequest) {
        let otherinfo = {
          deptid: user?.departmentid,
          deptcode: user?.departmentcode,
          deptname: user?.departmentname,
        }
        tempData.otherinfo = otherinfo
      }
      let {data} = await ApiService[method](`orders/requestorders/upsertwithnewdetails${nosericode}`, tempData)
      data.documentdate = data.documentdate && [new Date(data.documentdate)]
      data.expecteddate = data.expecteddate && [new Date(data.expecteddate)]
      getData(data)
      // console.log('chooseTypeSubmit.isDenied',chooseTypeSubmit.isDenied)
      setAutoApprovalRequest(chooseTypeSubmit.isDenied)
      setidRequest(data.id)
      await dataSetupApproval()
      if(chooseTypeSubmit.isDenied && approvalLevels) {
        // await dataSetupApproval()
        // setTimeout(()=> {
        //   refApprovalRequest?.current.click()
        // },200)
      }
      else {
        popupMessage({ icon: 'success', autoClose: true })
      }
    } catch (error) { ApiShowError(error) }
    
  }

 
  //invoices/scan/fromsessions
  

  const handleClickRemoveItem = (index: any) => {
    Swal.fire({
      text: `Do you want to delete this row?`,
      icon: "question",
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary order-2",
        cancelButton: "btn btn-secondary bg-secondary order-1",
      },
    }).then(async (result) => {
      if(result.isConfirmed) {
        let grid = tableData.map((row:any) => [...row]) as any
        // let index = grid.findIndex((i:any)=> i[0].id == record[0].id)
        grid[index][0].isDeleted = true
        setTableData([...grid])
      }
    })
    
  }
  const [isShowMore,setIsShowMore] = useState(false)
  const handleSelectMore = (index:any) => {
    setIsCheck([...isCheck, index]);
    setIsShowMore(true)
  }
  const autoFillData = (value:any,index:any,col?: any) => {
    let tempData = optionsItems?.find((item:any)=>item.itemno == value)
    // console.log('value',value)
    // console.log('optionsItems',optionsItems)
    // console.log('tempData',tempData)
    const grid = tableData//JSON.parse(JSON.stringify(tableData)).map((row:any) => [...row]);
    // const grid = JSON.parse(JSON.stringify(tableData)).map((row:any) => [...row]);
    let rowEdit = [ 
      { value: '' , dataEditor: SelectEditorPopup, optionsData: Object.values(LocalData.requestType)}, 
      { value: '' , dataEditor: SelectEditorPopup, optionsData: optionsItems,checkCreated: true, templateCell: templateCell}, 
      { value: '' , }, 
      { value: '', className: 'text-center', dataEditor: SelectEditorPopup, optionsData: optionsUoms },
      { value: '', className: 'text-center' }, 
      { value: '', readOnly: true, className: 'text-center' }, 
      { value: '', className: 'text-center' },
      { value: '', readOnly: true, className: 'text-center' }, 
      { value: '' }
    ] as any
    if(tempData) {
      // console.log('tempData',tempData)
      tempData.type = watch('type_order_'+index)
      rowEdit = renderRowTable(tempData,undefined,index)
      setValue('code_'+index,tempData.itemno)
    }
    else if(index != 1) {
      setValue('code_'+index,'')
      rowEdit[1].value = ''
      return []
    }
    rowEdit[0].id = grid[index][0]?.id
    // console.log('col',col)
    if(col == 1) {
      rowEdit[0].value = grid[index][0]?.value
    }
    if(col == 0) {
      rowEdit[1].value = ''
    }
    grid[index] = rowEdit
    if(tempData) {
      grid[index][7] = {value: totalAmount(rowEdit),readOnly: true,className: 'text-center',}
    }

    if(grid.length-1 == index && value!= '') { // add new row
      // console.log('defaultRow',defaultRow)
      grid.push(defaultRow)
    }
    // console.log('autofill return ',grid)
    return grid
    // setTableData([...grid])

  }
  const totalAmount = (item:any) => {
    let total = (+reFormatCurrent(item[4]?.value) * +reFormatCurrent(item[6]?.value))
    // let discount = +reFormatCurrent(item[3].value)
    total = Number(total.toFixed(0))
    return formatCurrent(total)
  }
  const totallineitemAmount = () => {
    let total = tableData.filter((item:any)=> !(item[0].isDeleted ||!item[2]?.value || !item[3]?.value || !item[4]?.value || !item[6]?.value)).reduce(function (acc:any, obj:any) { return acc + +reFormatCurrent(obj[7]?.value) }, 0)
    total = Number(total.toFixed(0))
    return formatCurrent(total)
  }
  const clearRowTable = (row:any) => {
    setValue('code_'+row,'')
    setValue('type_order_'+row,'')
    let grid = tableData.map((row:any) => [...row]) as any
    // let index = grid.findIndex((i:any)=> i[0].id == record[0].id)
    grid[row][0].isDeleted = true
    return grid

    setValue('code_'+row,'')
    setValue('type_order_'+row,'')
    return autoFillData('',row)
    
}
const downloadFileExample = () => {
  let tempData = [] as any
  let head = {} as any
  let fields = [] as any
  header.forEach((i: any,index: number) => {
    if(index == 6 || index == 8) return
    if(i.title != "#") {
      head[i.title] = ""
      fields.push(i.title)
    }
  })
  tempData.push(head)
  let fileName = 'request-order-import-details_'+new Date().toISOString().slice(0,10)+'.csv'
  exportCSV(tempData, fileName, fields)
}
  const templateTableCell = () => {
    let data = [] as any
    tableData?.forEach((i: any) =>{
      let temp = i as any
      temp.forEach((item: any,index: number) => {
        if(index != 5 && index != 7)
        item.readOnly = checkIsDisabledForm()
      });
      data.push(temp)
    })||[]
    return <ReactDataSheet
    className="table-manual fs-7 w-100"
    data={data||[]}
    valueRenderer={(cell:any) => cell.value}
    onCellsChanged={changes => {
    let grid = data.map((row:any) => [...row]) as any
    // console.log('grid',grid.length)
    let rowChange = null as any
    let classError = 'form-error'
    let dataGrid = [] as any
    changes.forEach(({ cell, row, col, value }) => {
      // console.log('value change:',value)
      let className = grid[row][col].className||''
      setIsHasChange(true)
      
      // console.log('row',row)
      // if(!value) return
        if(col == 0) {
          setValue('type_order_'+row,value)
          if(!value) {
            grid = clearRowTable(row)
          }
          else {
            let a = autoFillData('',row, col)
            if(a.length != 0) {
              grid = a
            }
            else {
              setValue('code_'+row,'')
              grid[row][1].value = ''
            }
          }
          
        }
        if(col == 1) {
          setValue('code_'+row,value)
          if(grid[row][0].value && grid[row][0].value?.toLowerCase() == 'item') {
            let a = autoFillData(value,row, col)
            if(a.length != 0) {
              grid = a
            }
            value = optionsItems.find((i: any) => i.itemno == value)?.itemno
          }
          
        }
        // if(col == 4) { 
        //   value = isNaN(value as any) ? '1' : value
        // }
        if(col == 0) {
          value = value?.toLowerCase() == 'item'?"Item":""
        }
        
        if(col == 1 && grid[row][4].value) {
          rowChange = row
        }
        if(col == 2 && value && grid[row][6].value) {
          rowChange = row
        }
        if(col == 6 || col == 4) {
          value = reFormatCurrent(value) as any
          value = isNaN(value as any)?'' : formatCurrent(Number(value))
          // console.log('value',value)
          if(value != '' && grid[row][2].value) {
            rowChange = row
          }
        }
        grid[row][col] = { ...grid[row][col], value };
        if(col == 0){
          grid[row][col].index = row
        }
        if(col != 0 && col != 1 && col != 5 && col != 7 && col != 8  && row != grid.length - 1) {
          if(!value) {
            grid[row][col].className = className.replace(classError,'')+' '+ classError

          }
          else {
            grid[row][col].className = className.replace(classError,'')
            
          }
        }
        
        grid[row][7] = {value: totalAmount(grid[row]),readOnly: true,className: 'text-center'}
    });
    
    // setTableData([...tableData, grid)
    if(rowChange != null && (grid.length - 1) == rowChange) {
      grid.push(defaultRow)
    }
    setTableData(grid)

    }}
    sheetRenderer={(props:any) => (
        <table className={clsx(props.className + ' table-invoice',{'select-more':isShowMore})}>
            <thead className="bg-light">
                <tr>
                    {header.map((cell:any, index:number) => (
                        <th rowSpan={cell.rowSpan} colSpan={cell.colSpan}
                        key={'header' + index} className={clsx(cell.class,{'wrap-checkinvoice':cell.type == 'check'})} >
                          {cell.type == 'check' ?
                            <div className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input m-auto" type="checkbox" checked={isCheckAll} value='all' onChange={handleSelectAll} id='checkAll' />
                            </div>
                            :
                            <span>{cell.title}</span> 
                          }
                            
                        </th>

                    )
                    )}
                </tr>
            </thead>
            <tbody>
                {props.children}
            </tbody>
        </table>
      )}
      rowRenderer={(props:any) => {
        let grid = tableData.filter((i:any)=>!i[0].isDeleted)
        // console.log('tableData',tableData)
        let number = grid.findIndex((i:any)=>i[0].index == tableData[props.row][0].index).toString()
        // console.log('number',number)
        const item = tableData[props.row]||{}
       
        let index = props.row.toString()
        let type_order = 'type_order_'+index//props.row
        
        let item_code = 'code_'+index//props.row
        // setValue(type_order,284210000)
        return <tr className={clsx({'tr-cell-custom': !checkIsDisabledForm()},{'d-none': item[0].isDeleted})}>
            {/* <td className="text-center wrap-checkinvoice">
                <div className="form-check form-check-custom form-check-solid">
                    <input className="form-check-input m-auto" type="checkbox" checked={isCheck.includes(index)} onChange={handleClick} id={index} />
                </div>
            </td> */}
            <td className='hover-tr'>
              {
                (item[0].value != '') && <>
                  <div className='text-center stt-number'>{+number+1}</div>
                  {
                    !checkIsDisabledForm() &&
                    <span className='clear-row-cell text-center' onClick={e=>clearRowTable(index)}><i className="bi bi-x-circle text-danger fw-bold"></i></span>
                  }
                  
                </>
              }
            </td>
            {props.children}
            
        </tr>
      }}
/>
  }
  const setValueGrid = (data: any,) => {
    // console.log('setValueGrid')
    const {props} = data
    const {row,col} = props
    const grid = tableData.map((row:any) => [...row]) as any
    grid[row][col] = {value: data.label, className: 'text-center',dataEditor: props.cell.dataEditor, options: props.cell.optionsData}
    setTableData(grid)
  }
  const toolbar = <>
      <KTButton action="back" onClick={() => navigate(-1)}/>
  </>
  return <>
    <ContentHeader title={intl.formatMessage({ id: `text.order-details` as any })} items={[{ title: intl.formatMessage({ id: `text.list-of-order` as any }), path: '/requests/order' }]}  elements={toolbar}/>

    <div className='card'>
      <div className='card-header border-0 pt-6 mb-0 min-h-10px'>
        <div className='card-title'>
            <h5>{intl.formatMessage({ id: `text.general-information` as any })}</h5>
        </div>
        <div className='card-toolbar gap-2'>
          {valueid &&
          
            <TextFormByUser data={watch()} />
          }

            {
              (approvalLevels || isEditNotApproval) && 
              <ActionApprovals 
                requestUser={requestUser}
                approvalUser={approvalUser}
                approvalhistories={approvalhistories}
                approvalLevels={approvalLevels}
                refApprovalRequest= {refApprovalRequest}
                totalamount={reFormatCurrent(totallineitemAmount())||+dataRequest?.totalamount}
                refetchApproval={refetchApproval}
                setValue={setValue}
                watch={watch}
                valueid={idRequest}
                isHasChange={isHasChange}
                refSaveBtn={refSaveBtn}
                tableData={tableData}
                emailnotification={emailnotification}
                dataQuery={dataQuery}
                urlApi={
                  {
                    settings: 'settings',
                    typeRequest: 'orders/requestorders',
                    sendMail: 'utilities/sendmail/withcustomtemplate',
                    printPdf: 'print-order',
                    request: 'order'
                  }
                }
                />
          }
          
        </div>
      </div>
    </div>
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className='card'>
         
          <div className="row card-body pt-3">
            <div className="col-md-6 col-12">
              <KTFormItem title='text.document-code' isRequired={!(arrObject?.nosericode && !idRequest)}>
                <input readOnly={(arrObject?.nosericode && !idRequest) || (checkIsDisabledForm()) } className={clsx('form-control form-control-sm', {'form-control-solid': ((arrObject?.nosericode && !idRequest) || checkIsDisabledForm()) , 'form-error': errors.code })} {...register('code')} />
              </KTFormItem>
              <KTFormItem title='text.dear' isRequired>
                <input readOnly={checkIsDisabledForm()} className={clsx('form-control form-control-sm', { 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.requestto })} {...register('requestto')} />
              </KTFormItem>
              <KTFormItem title='text.about' isRequired>
                <input disabled={checkIsDisabledForm()} className={clsx('form-control form-control-sm', { 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.title })} {...register('title')} />
              </KTFormItem>
              <KTFormItem title='text.purpose' isRequired className='align-items-baseline mb-4'>
                <textarea disabled={checkIsDisabledForm()} className={clsx('form-control form-control-sm resize-none', { 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.purpose })} {...register('purpose')} />
              </KTFormItem>
              {/* <KTFormItem title='Mã ngân sách'>
                <input readOnly className={clsx('form-control-solid form-control form-control-sm', { 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.title && !watch('mns')  })} {...register('mns')} />
              </KTFormItem>
              <KTFormItem title='Tên ngân sách'>
                <input readOnly className={clsx('form-control-solid form-control form-control-sm', { 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.title && !watch('tns')  })} {...register('tns')} />
              </KTFormItem> */}
              <KTFormItem title='text.creator'>
                <input readOnly className={clsx('form-control form-control-sm form-control-solid', { 'form-error': errors.creater })} {...register('creater')} />
              </KTFormItem>
              <KTFormItem title='text.date-created'>
                <Controller
                  name='createdat'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                      <InputDate
                      disabled
                      className={clsx('form-control form-control-sm w-100 form-control-solid')}
                      value={value}
                      
                      onChange={onChange}
                      />
                  )}
                  />
              </KTFormItem>
            </div>
            <div className="col-md-6 col-12">
              
              <KTFormItem title='text.department-name' isRequired>
                <Controller
                  name={'departmentid'}
                  control={control}
                  render={({ field }) => <InputSelect
                    options={optionsDepts.map((i: any) =>( {value: i.id,label: i.departmentname} ))}
                    disabled={checkIsDisabledForm()}
                    className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.departmentid })}
                    value={optionsDepts.map((i: any) =>( {value: i.id,label: i.departmentname} ))?.find((e: any) => e.value == field.value)|| ''}
                      onChange={e => {
                      if (!e) return field.onChange(null)
                      field.onChange(e.value)
                      let record = optionsDepts.find((i: any) => i.value == e.value)
                      if(record) {
                        setValue('departmentcode', record.code)
                        setValue('departmentname', record.departmentname)
                      }
                    }
                  }
                  />} />
              </KTFormItem>
              <KTFormItem title='text.department-code'>
                <Controller
                  name={'departmentid'}
                  control={control}
                  render={({ field }) => <InputSelect
                    options={optionsDepts}
                    value={optionsDepts?.find((e: any) => e.value == field.value)|| ''}
                    disabled={true}
                    className={clsx('form-control form-control-sm w-100 select-custom form-control-solid',{})}
                    onChange={e => {
                      if (!e) return field.onChange(null)
                      field.onChange(e.value)
                      let record = optionsDepts.find((i: any) => i.value == e.value)
                      if(record) {
                        setValue('departmentcode', record.code)
                        setValue('departmentname', record.departmentname)
                      }
                    }
                  }
                />} />
              </KTFormItem>
              <KTFormItem title='text.document-date' isRequired>
                <Controller
                  name='documentdate'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                      <InputDate
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100',{ 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.documentdate })}
                      value={value}
                      onChange={onChange}
                      />
                  )}
                  />
              </KTFormItem>
              <KTFormItem title='text.expected-date' isRequired>
                <Controller
                  name='expecteddate'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                      <InputDate
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100',{ 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.expecteddate })}
                      value={value}
                      options={ {
                          minDate: watch('documentdate') && new Date(watch('documentdate')[0]?.setHours(0,0,0)),
                        }
                      }
                      onChange={e => {
                          onChange(e)
                          setValue('expecteddate', [new Date(watch('expecteddate')[0]?.setHours(1,0,0))])
                        }
                      }
                      />
                  )}
                  />
              </KTFormItem>
              {/* <KTFormItem title='Tổng ngân sách'>
                <Controller
                  name="totalbudget"
                  control={control}
                    render={({ field: { value, onChange } }) => <InputPrice
                      value={value}
                      onChange={onChange}
                      readOnly
                      className={clsx('form-control-solid form-control form-control-sm', {'form-error': errors.totalbudget })} />}
                    />
              </KTFormItem>
              <KTFormItem title='Ngân sách bổ sung'>
                <Controller
                  name="additionalbudget"
                  control={control}
                    render={({ field: { value, onChange } }) => <InputPrice
                      value={value}
                      onChange={onChange}
                      readOnly
                      className={clsx('form-control-solid form-control form-control-sm', {'form-error': errors.additionalbudget })} />}
                    />
              </KTFormItem>
              <KTFormItem title='Ngân sách còn lại'>
                <Controller
                  name="montbudget"
                  control={control}
                    render={({ field: { value, onChange } }) => <InputPrice
                      value={value}
                      readOnly
                      onChange={onChange}
                      className={clsx('form-control-solid form-control form-control-sm', { 'form-error': errors.montbudget })} />}
                    />
              </KTFormItem> */}
              <KTFormItem title='text.status'>
                { LocalData.statusData[convertStatus(watch('status'),approvalhistories,user?.id)] && 
                  <span className={clsx(`badge badge-${LocalData.statusData[convertStatus(watch('status'),approvalhistories,user?.id)]?.badge}`)}>
                    {LocalData.statusData[convertStatus(watch('status'),approvalhistories,user?.id)]['label'+selectedLang]}
                  </span>
                }
                
              </KTFormItem>
              
            </div>
          </div>
        </div>
        <hr className='m-0' />
        <div className="card mb-6">
          <div className='card-header border-0'>
            <div className='card-title'>
                <h5>{intl.formatMessage({ id: `text.details` as any })}</h5>
            </div>
            <div className='card-toolbar'>
              <div className=' d-flex flex-end'>
                {
                  !checkIsDisabledForm() &&
                  <KTButton action="import" onClick={handleAddModalImport} titleBtn={intl.formatMessage({ id: `text.import.title` as any })}/>
                }
              </div>
              {/* <span className={clsx('btn btn-sm btn-success')} ><i className="bi bi-cloud-upload me-3"></i>Import</span> */}
            </div>
          </div>
          <div className={clsx("card-body pt-0",{})}>
           
              {/* <RenderSheetData columns={header} grid={tableData} className="table-manual w-100" setGrid={setTableData}/> */}
          { templateTableCell()}
              <div className='col-12 mt-5 mb-6'>
                <div className='d-flex gap-5 flex-end'>
                  <span className='text-black fw-bold'>{intl.formatMessage({ id: `text.total` as any })}</span>
                  <strong className='text-danger fs-5'>{totallineitemAmount()}</strong>
                </div>
              </div>
              <div className="col-12 text-end">
                {!checkIsDisabledForm() &&
                  <FormSubmit
                  type='modify'
                  isSubmitting={isSubmitting}
                  permission={permission}
                  refSaveBtn={refSaveBtn}
                  handleClose={() => navigate(-1)} />
                }
                
              
              </div>
          </div>
        </div>
        
      </div>
    </form>
    {historiesApproval(approvalhistories, intl)}
    <ImportModalRequest
      {...{
          show: isShowImport,
          title: intl.formatMessage({ id: `text.import.title` as any }),
          filename: 'order_import_sample.xlsx',
          filelink: `/samples/xlsx/request_order_import_details_(${selectedLang}).xlsx`,
          // funcDowloadFile: downloadFileExample,
          handleClose() {
              setIsShowImport(false)
          },
          handleImport(data) {
            const file = data.files?.item(0)
            if (file) {
                const formData = new FormData();
                formData.append("file", file);
                ApiService.post(`utilities/convertxlsxtoarray`, formData)
                    .then(({data}) => {
                      // console.log('data',data)
                      let a = JSON.parse(JSON.stringify(header)) as any
                      let tempTable = [] as any
                      let errRow = [] as any
                      let row = [ 
                        { value: '' , dataEditor: SelectEditorPopup, optionsData: Object.values(LocalData.requestType)}, 
                        { value: '' , dataEditor: SelectEditorPopup, optionsData: optionsItems,checkCreated: true, templateCell: templateCell}, 
                        { value: '' , }, 
                        { value: '', className: 'text-center', dataEditor: SelectEditorPopup, optionsData: optionsUoms },
                        { value: '', className: 'text-center' }, 
                        { value: '', readOnly: true, className: 'text-center' }, 
                        { value: '', className: 'text-center' }, 
                        { value: '', readOnly: true, className: 'text-center' }, 
                        { value: '' }
                      ]
                      data.forEach((item: any, index: number) => {
                        let tempData = [] as any
                        for(let i = 0;i<a.length;i++) {
                          let temp  = {
                            title: a[i].title,
                            value: item[a[i].title]
                          } as any
                     
                          tempData.push(temp)
                        }
                        let type = tempData[1].value?.toLowerCase() == 'item'?'Item':''
                        let itemcode = tempData[2].value
                        if(type == 'Item') {
                          itemcode = optionsItems.find((i: any) => i.itemno == tempData[2].value)?.itemno||''
                        }
                        if(!item[a[3].title] || !item[a[4].title] || !item[a[5].title]|| !item[a[7].title] || (type == 'Item' && !itemcode)) {
                          errRow.push({
                            item: item,
                            index: index + 1
                          })
                          return
                        }
                        
                        // console.log('tempData',tempData)
                        let ind = index - errRow.length
                        
                        setValue('type_order_'+ind,type)
                        setValue('code_'+ind,itemcode)
                        let recordTable = [
                          { value: type, 
                            dataEditor: SelectEditorPopup, 
                            optionsData: Object.values(LocalData.requestType)
                          }, 
                          { value: itemcode, 
                            dataEditor: SelectEditorPopup, 
                            optionsData: optionsItems,
                            checkCreated: true,
                            templateCell: templateCell
                          }, 
                          {
                            value: tempData[3].value,
                            // itemid: id ? data.itemid : data.id,
                            itemcode: tempData[2].value,
                            className: '',
                            index: ind,
                          },
                          {
                            value: tempData[4].value,
                            className: 'text-center',
                            dataEditor: SelectEditorPopup, 
                            optionsData: optionsUoms
                          },
                          {
                            value: tempData[5].value,
                            className: 'text-center',
                          },
                          {
                            value: 0,
                            readOnly: true,
                            className: 'text-center',
                          },
                          {
                            value: formatCurrent(tempData[7].value),
                            className: 'text-center',
                          },
                          {
                            value: formatCurrent(tempData[5].value*tempData[7].value),
                            readOnly: true, 
                            className: 'text-center',
                          },
                          {
                            value: tempData[9].value,
                          }
                        ] as any
                        tempTable.push(recordTable)
                      })
                      if(errRow.length !=0){
                        let th = ''
                        let style = 'style="width: 100px; text-align: left;border: 1px solid #cacaca;padding: 3px"'
                        a.forEach((i: any,index: number) => {
                          if(index == 6 || index == 8) {
                            return
                          }
                          th += `<th ${style}>${i.title == '#'?intl.formatMessage({ id: `text.row` as any }):i.title}</th>` 
                        })

                        let tr = ''
                        errRow.forEach((item: any,index: number) => {
                          let td = `<td ${style}>${item.index}</td>`
                          for(let i = 1;i<a.length;i++) {
                            if(i != 6 && i != 8) {
                              let value = item.item[a[i].title]
                              let typecode = ''
                              let checkItemCode = true
                              if(i == 2) {
                                typecode = item.item[a[i-1].title]?.toLowerCase()
                                if(typecode == 'item') {
                                  checkItemCode = optionsItems.find((i: any) => i.label == value)
                                }
                              }
                              let stylerow = style
                              if(!value && (i == 3 ||i == 4 ||i == 5 ||i == 7 )) {
                                stylerow = 'style="width: 100px; text-align: left;border: 2px solid red;padding: 3px;"'
                              }
                              else if(!checkItemCode && (i == 2 && typecode == 'item')) {
                                stylerow = 'style="width: 100px; text-align: left;border: 2px solid red;padding: 3px;"'
                              }
                              td += `<td ${stylerow}>${value}</td>`
                            }
                          }
                          tr += `<tr>${td}</tr>`
                        })
                        popupMessage({
                          icon: "info",
                          width: 850,
                          html: `<h4>${intl.formatMessage({ id: `text.order.noti-import` as any })}</h4>
                          <table style="margin: 8px auto">
                            <tr>
                              ${th}
                            </tr>
                            ${tr}
                          </table>`
                        })
                      }
                      if(tempTable.length != 0) {
                        tableData.forEach((i: any, index: number) => {
                          if(index < tempTable.length) {
                            return
                          } 
                          setValue('type_order_'+index,'')
                          setValue('code_'+index,'')
                        })
                        setIsHasChange(true)
                        setTableData(tempTable.concat([row]))
                        if(errRow.length ==0) {
                          popupMessage({ icon: 'success',description: intl.formatMessage({ id: `text.success-import` as any }), autoClose: true })
                        }
                      }
                      
                      
                    })
                    .catch(ApiShowError)
            }
          },
      }} />
    <SearchPopup {...{
            keys,
            filters,
            searchData,
            // className: 'popup-in-popup',
            setValue,
            handleClose: function () { setKeys([]); setFilters([]) },
            selfSave: (data:any) => {
              // console.log('choose and close popup')
              if(data.props) {
                return setValueGrid(data)
              }
              for (const [key, value] of Object.entries(data)) {
                if(key != 'index') {
                  // if(data.setValueChild) {
                  //   setValueChild(key,value)
                  // }
                  // else {
                    setValue(key,value)
                  // }
                }
              }
              if(data.index) {
                autoFillData(data.id,data.index)
              }
            },
            
        }} />
  </>
}

export default TemplateComponent
