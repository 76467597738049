import { create } from "zustand"

export interface SideBarMenuItem {
    title: string
    path: string
    permission: string
    icon?: string
    children?: SideBarMenuItem[]
}

export const useZustandStore = create((set) => ({
  dataEmployees: [],
  dataVenders: [],
  dataCustomers: [],
  dataExpenses: [],
  setEmployees: (data:any) => set((state:any) => {
    return { dataEmployees: data}
  }),
  setVenders: (data:any) => set((state:any) => {
    return { dataVenders: data}
  }),
  setCustomers: (data:any) => set((state:any) => {
    return { dataCustomers: data}
  }),
  setExpenses: (data:any) => set((state:any) => {
    return { dataExpenses: data}
  }),
}))
