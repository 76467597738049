import { yupResolver } from "@hookform/resolvers/yup"
import { FC, useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { Column } from "react-table"
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId, sortByUpdateAt } from "../../../../theme/helpers"
import { BasicCell, CustomHeader, FormSubmit, InputSelect, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../theme/partials"
import { IInitField } from "../../api"
import { apiAd } from "../../api/admin"
import { popupMessage } from "../../messages"
import { IAdUser } from "./users"
import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"
import { getIsRole } from "../../auth/Permission"

export interface IAdAccessControl extends IInitField {
    permissionsetid: string | null
    rolecode: string | null
    userid: string | null
    username: string | null
    companyid: string | null
    companyname: string | null
}

const apiurl = 'settings/accesscontrols'
export default function AdAccessControls({ userData }: { userData?: IAdUser }) {
    const intl = useIntl()
    const { data, isSuccess, isFetching } = apiAd.useAccessControlsByQuery(userData?.id ? `?userid=${userData.id}` : undefined)
    const [tableData, setTableData] = useState<IAdAccessControl[]>([])
    const [valueData, setValueData] = useState<IAdAccessControl | undefined>()

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.permission-set.id' />,
        accessor: 'rolecode',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => setValueData(props.data[props.row.index])}
            data={props.data[props.row.index].rolecode}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.company' />,
        accessor: 'companyname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].companyname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" onClick={() => setValueData(props.data[props.row.index])} />
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <KTTable
            isLoading={isFetching}
            title={intl.formatMessage({ id: "text.access-controls" })}
            toolbar={<KTButton disabled={!userData || !userData.id} action='new' onClick={() => setValueData({
                userid: userData?.id as any,
                username: userData?.username as any,
                permissionsetid: null,
                rolecode: null,
                companyid: null,
                companyname: null
            })} />}
            data={tableData}
            columns={columns}
        />
        {valueData && <AdAccessControlsDetail
            valueData={valueData}
            handleClose={() => {
                setValueData(undefined)
            }}
            handleSave={(type, value) => {
                if (type) {
                    setTableData(pre => pre.map(item => item.id == value.id ? value : item))
                } else {
                    setTableData([value, ...tableData])
                }
            }} />}
    </>
}

const schema = yup.object().shape({
    permissionsetid: yup.string().required(),
});

interface IAdAccessControlsDetail {
    valueData: IAdAccessControl
    handleClose: () => void
    handleSave: (type: boolean, value: IAdAccessControl) => void
}

export const AdAccessControlsDetail: FC<IAdAccessControlsDetail> = ({ valueData, handleClose, handleSave }) => {
    const isSuper = getIsRole(['SUPER'])
    const { isEdit, valueid } = getValueId(valueData.id)
    const { setValue, reset, control, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdAccessControl>({
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        if (valueData)
            reset(valueData)
    }, [valueData])

    const { data: permissionSetsData, isSuccess: isPermissionSets } = apiAd.usePermissionSets()
    const optionPermissionSets = isPermissionSets ? formatOptions(permissionSetsData, ['id', 'rolecode', 'name']) : []
    const optionRoleCode = optionPermissionSets.filter((f: any) => !isSuper ? !['SUPER'].includes(f.rolecode) : true)

    const { data: companyInformationsData, isSuccess: isCompanyInformations } = apiAd.useCompanyInformations()
    const optionCompanyInformations = isCompanyInformations ? formatOptions(companyInformationsData, ['id', 'name']) : []

    const isDisabled = !isSuper && valueData.rolecode == 'SUPER'
    return <Modal show={valueData != undefined} onHide={handleClose} size="lg" centered>
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IAdAccessControl) => {
                try {
                    const role = optionPermissionSets.find(f => f.value == data.permissionsetid)
                    if (role) {
                        data.rolecode = role.rolecode
                    }
                    const companry = optionCompanyInformations.find(f => f.value == data.companyid)
                    if (companry) {
                        data.companyname = companry.name
                    }
                    const method = isEdit ? 'put' : 'post'
                    const response = await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    handleSave(isEdit, response.data)
                    popupMessage({ icon: 'success', autoClose: true })
                } catch (error) { ApiShowError(error) }
                handleClose()
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id='text.access-controls' /> </h3>
                <div className="card-toolbar"></div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.permission-set' isBLockLabel>
                            <Controller
                                name="permissionsetid"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    disabled={isDisabled}
                                    options={isDisabled ? optionPermissionSets : optionRoleCode}
                                    value={optionPermissionSets.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue('rolecode', null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                    className={clsx("w-100", { 'form-error': errors.permissionsetid })}
                                />}
                            />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.company' isBLockLabel>
                            <Controller
                                name="companyid"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    disabled={isDisabled}
                                    isClearable
                                    options={optionCompanyInformations}
                                    value={optionCompanyInformations.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue('companyname', null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                    className="w-100"
                                />}
                            />
                        </KTFormItem>
                    </div>
                    <div className="col-12 align-items-end d-flex flex-stack">
                        <TextFormByUser data={watch()} />
                        <FormSubmit
                            type={isEdit}
                            isSubmitting={isSubmitting}
                            handleClose={handleClose} />
                    </div>
                </div>
            </div>
        </form>
    </Modal>
}
