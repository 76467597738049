import clsx from "clsx"
import { FC, useEffect, useRef, useState } from "react"
import { popupMessage } from "../../../../app/modules/messages"
import { isError } from "react-query"
import { KTSVG } from "../utils/KTSVG"
import { useIntl } from "react-intl"

interface PropsListFilesInForm {
    values: FileCustom[]
    onChange: (values: FileCustom[], oldValues: any) => void
    disabled?: boolean
    maxSizes?: number 
    maxFiles?: number
    className?: string 
    isErrors?: any
}

interface FileCustom extends File {
    url?: string
    fileName: string
    fileSize?: string
    pathtofile?: string
    fileType?: string
    state: "upload" | "remove" | "load"
    maxSizes?: number 
    maxFiles?: number
    className?:string
    isErrors?: any
}



export const InputFiles: FC<PropsListFilesInForm> = ({ disabled, values, onChange,maxSizes, maxFiles,className, isErrors }) => {
    const intl = useIntl()
    const [files, setFiles] = useState<FileCustom[]>([])
    let config = {
        MAXSIZE: maxSizes?maxSizes:20, // MB
        MAXFILES: maxFiles?maxFiles:10,
    }
    useEffect(() => {
        if(files.filter((i:any)=> i.state!='remove').length > config.MAXFILES) {
            popupMessage({icon: "error", description: `The number of files to upload does not exceed ${config.MAXFILES}`})
            // return null
        }
        else if (values && values.length && files.length == 0) {
        setFiles(values.map(e => ({ ...e, name: e.fileName, state: "load" })))
        }
        // setFiles(values.map(e => ({ ...e, name: e.fileName, state: "load" })))
    }, [values])

    function getSize(n: number) {
        const kb = n / 1024
        if (kb > 999) {
            return `${(kb / 1024).toFixed(1)} MB`
        }
        return `${(kb).toFixed(1)} KB`
    }

    const inputRef = useRef<HTMLInputElement | null>(null)

    function resetFiles(event: React.ChangeEvent<HTMLInputElement>) {
        var fileBuffer = new DataTransfer();
        event.target.files = fileBuffer.files
    }

    const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filesEvent = event.target.files
            ? Object.values(event.target.files)
            : []

        const maxkb = (((config.MAXSIZE) * 1024) * 1024)
        // console.log('filesEvent',filesEvent)
        // console.log('config.MAXFILES',config.MAXFILES)
        const isErrSize = filesEvent.filter((f: any, index) => f.size > maxkb)
        const isErrFiles = filesEvent.filter((f: any, index) => index + 1 > config.MAXFILES)
        // console.log('isErrFiles',isErrFiles)
        if (isErrSize.length) {
            popupMessage({icon: "error", description: "File size exceeds the maximum limit."})
            resetFiles(event)
            return null
        }
        if (isErrFiles.length != 0) {
            popupMessage({icon: "error", description: `The number of files to upload does not exceed ${config.MAXFILES}`})
            resetFiles(event)
            return 
        }

        const listname = files.filter(f => f.state != "remove").map(e => e.name)

        const fileResult: any[] = (filesEvent
            .filter((f,) => !listname.includes(f.name))
            .map(item => Object.assign(item, { fileName: item.name, state: "upload" }))
            .concat(files))
        
        resetFiles(event)
        // console.log('fileResult',fileResult)
        if(fileResult.filter((i:any)=>i.state != 'remove').length > config.MAXFILES) {
            popupMessage({icon: "error", description: `The number of files to upload does not exceed ${config.MAXFILES}`})
            return
        }
        else {
            setFiles(fileResult)
            onChange(fileResult, values)
        }
        
    }

    const removeFile = (i: number) => {
        const fileResult: any[] = files
            .filter((file, index) => !(index == i && file.state == 'upload'))
            .map((file, index) => {
                return index == i ? ({ ...file, state: "remove" }) : file
            })
        setFiles(fileResult)
        // console.log('fileResult',fileResult)
        onChange(fileResult, values)
    }
    const removeAllFile = () => {
        const fileResult = files.filter(f => f.state != 'upload').map((file, index) => {
            if (file.state == 'load') {
                file.state = 'remove'
            }
            return file
        })
        setFiles(fileResult)
        onChange(fileResult, values)
    }

    const items = files.map((file, index) => (
        <div key={index} className={clsx("dropzone-item mt-1", { "d-none": file.state == "remove" })}>
            <div className="dropzone-file">
                <div className="" title={file.name}>
                {/* || URL.createObjectURL(file) */}
                    <a target={'_blank'} href={file.url  || '#'} className="text-truncate me-2" style={{ width: '300px' }} rel="noreferrer">{index+1}. {file.name}</a>
                    <strong className="d-inline-block">(<span>{file.fileSize ? file.fileSize : getSize(file.size)}</span>)</strong>
                </div>
                
            </div>
           
            {/* <div className="dropzone-progress">
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" data-dz-uploadprogress>
              </div>
            </div>
          </div> */}
            {!disabled && <div className="dropzone-toolbar">
                {/* <span className="dropzone-start"><i className="bi bi-play-fill fs-3"></i></span> */}
                {/* <span className="dropzone-cancel" data-dz-remove ><i className="bi bi-x fs-3"></i></span> */}
                <span className="dropzone-delete" onClick={() => removeFile(index)}><i className="bi bi-x fs-1"></i></span>
            </div>}
        </div>
    ))

    return <div className="mt-1 w-100">
        <input ref={inputRef} className="d-none inputFile" type='file' multiple onChange={selectFile} />
        <div className={clsx(`dropzone dropzone-queue mb-0 ${className && className}`)} >
            {!disabled &&<div className="dropzone-panel mb-lg-0 mb-2 d-flex flex-stack">
                <span className={clsx("dropzone-select btn btn-sm btn-primary me-2",{'disabled': disabled})} onClick={() => inputRef.current?.click()}>{intl.formatMessage({ id: `text.attachments` as any })}</span>
                {/* <a className="dropzone-upload btn btn-sm btn-light-primary me-2">Upload All</a> */}
                <a className={clsx("dropzone-remove-all btn btn-sm btn-light-danger me-0", { "d-inline-block": files.filter(f => f.state != 'remove').length })} onClick={removeAllFile}>
                {intl.formatMessage({ id: `text.remove-all` as any })} ({files.filter((i:any)=>i.state != 'remove').length})
                </a>
                    </div>}
            <div className="dropzone-items ">
                {items}
            </div>
        </div>
        <span className="form-text">{intl.formatMessage({ id: `text.maximum-size-(MB)` as any })} {config.MAXSIZE}MB {intl.formatMessage({ id: `text.and` as any })} {intl.formatMessage({ id: `text.maximum-quantity-(file)` as any })} {config.MAXFILES}.</span>
        {
            isErrors &&
            <div className="text-danger" >{intl.formatMessage({ id: `text.noti-required-file` as any })}</div>
        }
        {/* <div>Files: </div>
        <ul className="p-0">
            {isArray && data.length && data.map((item: any, index: number) => <li key={index}
                className="px-2 rounded bg-light ms-2 mb-2 d-inline-flex gap-1 flex-stack align-items-center text-secondary min-h-25px">
                <Link target={'_blank'} to={item.url || '#'} className="fs-7 fw-bold text-gray-600 text-hover-primary">
                    {(index + 1) + '. ' + item.name}
                </Link>
                {handleRemove && <i className="bi bi-x cursor-pointer fs-2 h-15px w-15px" onClick={() => {
                    var fileBuffer = new DataTransfer();
                    if (!isArray) {
                        for (let i = 0; i < data.length; i++) {
                            // Exclude file in specified index
                            if (index !== i)
                                fileBuffer.items.add(data[i]);
                        }
                        return handleRemove(fileBuffer.files)
                    }
                    handleRemove(data.splice(index, 1))
                }} />}
            </li>
            )}
        </ul> */}
    </div>
}