import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { IFnbCategory, optionOrderType } from ".";
import { ApiDeleteFile, ApiService, ApiShowError, ApiUploadFiles, base64ToFile, changeDataModify, getValueId, isBase64, toAbsoluteUrl, toBase64 } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { FormSubmit, InputSingleImage, KTFormItem, TextFormByUser, yup } from "../../../../theme/partials";
import { RadioBoolean } from "../../../../theme/partials/widgets/form/RadioBoolean";
import { apiFnb } from "../../../modules/api/fnb";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";
import clsx from "clsx";
import { UploadImage } from "../../../../theme/partials/UploadImage";

const defaultValues: IFnbCategory = {
    code: null,
    name: null,
    ordertype: null,
    active: false
}

export function FNBCategoriesForm({ keyElement, pathElement, permission }: MergedProps) {
    const urlApi = 'fnb/categories'
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching } = apiFnb.useCategoriesId<IFnbCategory>(valueid)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IFnbCategory>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            name: yup.string().required(),
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (value: IFnbCategory) => {
                const isDelete = data?.image && (!value.image || isBase64(value.image))
                const isUpload = isBase64(value.image)
                let bodyData = {
                    ...value,
                    image: !(isDelete || isUpload) ? value.image : null
                }
                let itemid;
                try {
                    const method = isEdit ? "put" : "post"
                    const datas = await ApiService[method](`${urlApi}${isEdit ? `/${id}` : ""}`, bodyData)
                    itemid = datas?.data?.id
                } catch (error) {
                    ApiShowError(error)
                }

                if (data?.image && (!value.image || isBase64(value.image))) {
                    try {
                        const path = String(data.image).split(`https://icmsapi.isoftvietnam.com`)
                        await ApiDeleteFile(path.length > 1 ? path.at(1) : data.image)
                    } catch (error) { }
                }
                try {
                    if (itemid && isBase64(value.image)) {
                        const file = base64ToFile(value.image)
                        const result = await ApiUploadFiles(itemid, "category", [file], false)
                        if (!result.length) {
                            return popupMessage({ icon: "error", autoClose: true })
                        }
                        await ApiService.put(`${urlApi}/${itemid}`, { ...bodyData, image: result?.at(0)?.url })
                    }

                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                        </KTFormItem>
                        <KTFormItem title='text.order-type' isHeight>
                            <div>
                                {optionOrderType.map((item, index) => <label key={index} className="form-check form-check-sm form-check-custom mb-4">
                                    <input className="form-check-input" type="checkbox" value={item.value} {...register(`ordertype`)} />
                                    {item.label}
                                </label>)}
                            </div>
                        </KTFormItem>
                        <KTFormItem title='text.active'>
                            <RadioBoolean control={control} name="active" className="form-check form-check-sm form-check-custom w-80px" />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        {/* <KTFormItem title='text.icon' isHeight>
                            <Controller
                                name="icon"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSingleImage
                                    size="0.5:0.5"
                                    value={value}
                                    onChange={onChange} />}
                            />
                        </KTFormItem> */}
                        <KTFormItem title='text.image' isHeight>
                            <Controller
                                name="image"
                                control={control}
                                render={({ field: { value, onChange } }) => <UploadImage
                                    value={value}
                                    onChange={onChange}
                                    className="w-100px h-100px border"
                                />}
                            />
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
    </>
}

