import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { Column } from "react-table"
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId, isInt, sortByUpdateAt } from "../../../../theme/helpers"
import { FormattedMessage } from "react-intl";
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, ExtendClearValue, FilterDropdown, FormSubmit, InputDate, InputPrice, InputSelect, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../theme/partials"
import SearchPopup from "../../../../theme/partials/layout/search-popup"
import { apiPrivate, IInitField } from "../../api"
import { apiAd } from "../../api/admin"
import { popupMessage } from "../../messages"
import { IAdUser } from "./users"
import { apiFnb } from "../../api/fnb"

export interface IAdApprovalUserSetup extends IInitField {
    userid: string | null
    username: string | null
    employeecode: string | null
    fullname: string | null
    allowpostingfrom: string | null
    allowpostingto: string | null
    submittoid: string | null
    submittocode: string | null
    submittoname: string | null
    submittoifamountexceed: number | null
    forwardtoid: string | null
    forwardtocode: string | null
    forwardtoname: string | null
    forwardtoifamountexceed: number | null
    email: string | null
    phoneno: string | null
    approvaladministrator: boolean
    // storeid: string | null
    // storecode: string | null
    // storename: string | null
    stores: any
    // locationid: string | null
    // locationcode: string | null
    // locationname: string | null
    vendorid: string | null
    vendorcode: string | null
    vendorname: string | null
    isadminselfservice: boolean
    isshowdataentryform: boolean
    isapprovalcompletion: boolean
    jobtitle: string | null
    departmentid: string | null
    departmentcode: string | null
    departmentname: string | null
    usergroup?: string | null
    isadminview: boolean
}

const breadCrumbs = [{ title: 'system', path: '/system-settings', }]
const apiurl = 'settings/approvalusersetups'
export default function AdApprovalUserSetupsPage() {
    const { data, isSuccess, isFetching } = apiAd.useApprovalusersetups()
    const [tableData, setTableData] = useState<IAdApprovalUserSetup[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column<IAdApprovalUserSetup>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.username' />,
        accessor: 'username',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].username}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.submit-to' />,
        accessor: 'submittocode',
        Cell: ({ ...props }) => <BasicCell
            data={props.data[props.row.index].submittocode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.forward-to' />,
        accessor: 'forwardtocode',
        Cell: ({ ...props }) => <BasicCell
            data={props.data[props.row.index].forwardtocode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.posting-from' />,
        accessor: 'allowpostingfrom',
        Cell: ({ ...props }) => <BasicCell type='date' data={props.data[props.row.index].allowpostingfrom} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.posting-to' />,
        accessor: 'allowpostingto',
        Cell: ({ ...props }) => <BasicCell type='date' data={props.data[props.row.index].allowpostingto} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" to={props.data[props.row.index].id} />
            <RowIcon action="delete" disabled onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <ContentHeader title="system.user-setups" items={breadCrumbs} />
        <KTTable
            isLoading={isFetching}
            toolbar={<>
                <FilterDropdown>
                </FilterDropdown>
                <KTButton action='new' />
            </>}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search />
    </>
}

const defaultValues: IAdApprovalUserSetup = {
    userid: null,
    username: null,
    allowpostingfrom: null,
    allowpostingto: null,
    email: null,
    phoneno: null,
    employeecode: null,
    fullname: null,
    submittoid: null,
    submittocode: null,
    submittoname: null,
    submittoifamountexceed: null,
    forwardtoid: null,
    forwardtocode: null,
    forwardtoname: null,
    forwardtoifamountexceed: null,
    approvaladministrator: false,
    // storeid: null,
    // storecode: null,
    // storename: null,
    stores: null,
    // locationid: null,
    // locationcode: null,
    // locationname: null,
    vendorid: null,
    vendorcode: null,
    vendorname: null,
    isadminselfservice: false,
    isshowdataentryform: false,
    isapprovalcompletion: false,
    jobtitle: null,
    departmentid: null,
    departmentcode: null,
    departmentname: null,
    isadminview: false
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const AdApprovalUserSetupsDetailPage = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching, refetch } = apiAd.useApprovalusersetupsByQuery(valueid ? `/${valueid}?expand=usergroup` : undefined)
    const { register, reset, watch, control, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdApprovalUserSetup>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            email: yup.string().email().nullable(true).transform(val => val ? val : null),
            phoneno: yup.string().matches(phoneRegExp).nullable(true).transform(val => val ? val : null),
            userid: yup.string().required(),
            // submittoid: yup.string().required(),
        })),
    })
    const [input1, setInput1] = useState(false)
    const [input2, setInput2] = useState(false)

    useEffect(() => {
        if (isSuccess && !isFetching) {
            if (data.submittoifamountexceed) setInput1(true)
            if (data.forwardtoifamountexceed) setInput2(true)
            reset({ ...data, usergroup: [data.User.usergroupcode, data.User.usergroupname].filter(f => f).join(' / ') })
        }
    }, [isFetching])

    const [keys, setKeys] = useState<any[]>([])

    const { data: usersData, isSuccess: iUsers, isLoading: loadUsers } = apiAd.useUsers()
    const optionsUser = iUsers ? usersData.map((item: IAdUser) => ({ ...item, title: item.fullname, description: `${item.username}-${item.authenticationemail}` })) : []
    const { data: approvalusersetupsData, isSuccess: isApprovalusersetups, isLoading: loadApprovalusersetups } = apiAd.useApprovalusersetups()

    const { data: storesData, isSuccess: isStores } = apiFnb.useMerchants()
    const optionsStores = isStores ? formatOptions(storesData, ['id', 'storecode', 'storename']) : []

    const { data: vendorsData, isSuccess: isVendors } = apiPrivate.useVendorsByQuery(`?vendortype=employee&nolinkedtouser=true`)
    const optionVendors = isVendors ? vendorsData : []
    const optionVendorsWithData = formatOptions(
        isSuccess && data.vendorid
            ? [{ id: data.vendorid, number: data.vendorcode, vendorname: data.vendorname }, ...optionVendors]
            : optionVendors, ['id', 'number', 'vendorname'])

    const [typeSearch, setTypeSearch] = useState<string>('userid')
    function changeTypeSearch(data: any[]) {
        const types = [watch('userid'), watch('submittoid'), watch('forwardtoid')]
        const approvalusersetups = isApprovalusersetups ? approvalusersetupsData : []
        const mapids = approvalusersetups.map((item: any) => item.userid)
        return data
            .filter(f => typeSearch == 'userid' ? !mapids.includes(f.id) : true)
            .filter(f => !types.includes(f.id))
    }

    const { data: departmentsData, isSuccess: isDepartment } = apiPrivate.useDepartments()
    const optionDepartments = isDepartment ? formatOptions(departmentsData, ['id', 'code', 'departmentname']) : []

    return <>
        <ContentHeader title="system.user-setups" items={[...breadCrumbs, { title: 'system.user-setups', path: '/system-settings/user-setups' }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IAdApprovalUserSetup) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate('/system-settings/user-setups')
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        handleClose={() => navigate('/system-settings/user-setups')} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.user'>
                            <ExtendClearValue
                                hide={!watch("userid")}
                                handleClear={() => {
                                    setValue("userid", null)
                                    setValue("username", null)
                                    setValue("email", null)
                                    setValue("phoneno", null)
                                    setValue("fullname", null)
                                }}>
                                <input
                                    className={`form-control form-control-sm ${errors.userid && 'form-error'}`} {...register('username')}
                                    readOnly
                                    onClick={() => {
                                        setTypeSearch('userid')
                                        setKeys([
                                            ['userid', 'id'],
                                            ['username'],
                                            ['email', 'authenticationemail'],
                                            ['phoneno', 'phone'],
                                            ['fullname']
                                        ])
                                    }} />
                            </ExtendClearValue>
                        </KTFormItem>
                        <KTFormItem title='text.email'>
                            <input className={`form-control form-control-sm ${errors.email && 'form-error'}`} {...register('email')} />
                        </KTFormItem>
                        <KTFormItem title='text.phone'>
                            <input className={`form-control form-control-sm ${errors.phoneno && 'form-error'}`} {...register('phoneno')} />
                        </KTFormItem>
                        <KTFormItem title='text.job-title'>
                            <input className={`form-control form-control-sm ${errors.jobtitle && 'form-error'}`} {...register('jobtitle')} />
                        </KTFormItem>
                        <KTFormItem title='text.department'>
                            <Controller
                                name="departmentid"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isClearable
                                    options={optionDepartments}
                                    value={optionDepartments.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue("departmentcode", null)
                                        setValue("departmentname", null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue("departmentcode", e.code)
                                        setValue("departmentname", e.departmentname)
                                    }}
                                    className={clsx("w-100", { 'form-error': errors.departmentid })}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.user-setup.employee'>
                            <input className={`form-control form-control-sm ${errors.employeecode && 'form-error'}`} {...register('employeecode')} />
                        </KTFormItem>
                        <KTFormItem title='text.vendor.employee'>
                            <Controller
                                name="vendorid"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isClearable
                                    options={optionVendorsWithData}
                                    value={optionVendorsWithData.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue('vendorcode', null)
                                        setValue('vendorname', null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue('vendorcode', e.number)
                                        setValue('vendorname', e.vendorname)
                                    }} />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.posting-from'>
                            <Controller
                                name="allowpostingfrom"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputDate
                                    value={value}
                                    onChange={(e) => {
                                        if (!e.length) return onChange(null)
                                        onChange(e[0])
                                    }}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.posting-to'>
                            <Controller
                                name="allowpostingto"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputDate
                                    value={value}
                                    onChange={(e) => {
                                        if (!e.length) return onChange(null)
                                        onChange(e[0])
                                    }}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title="text.store" isHeight labelClassName="mt-2">
                            <Controller
                                name="stores"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isMulti
                                    isClearable
                                    options={optionsStores}
                                    value={optionsStores.filter(f => (value || []).map((m: any) => m.merchantid).includes(f.value)) || null}
                                    onChange={(e) => {
                                        console.log(e)
                                        onChange(e.map((m: any) => ({
                                            merchantid: m.value,
                                            merchantkey: m.merchantkey,
                                            storecode: m.storecode,
                                            storename: m.storename
                                        })))
                                        // setValue('storecode', null)
                                        // setValue('storename', null)
                                        // if (!e) return onChange(null)
                                        // onChange(e.value)
                                        // setValue('storecode', e.code)
                                        // setValue('storename', e.storename)
                                    }} />}
                            />
                        </KTFormItem>
                        {/* <KTFormItem title="text.location">
                            <Controller
                                name="locationid"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isClearable
                                    options={optionsLocations}
                                    value={optionsLocations.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue('locationcode', null)
                                        setValue('locationname', null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue('locationcode', e.code)
                                        setValue('locationname', e.locationname)
                                    }} />}
                            />
                        </KTFormItem> */}
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.submit-to' isHeight>
                            <ExtendClearValue
                                hide={!watch("submittoid")}
                                handleClear={() => {
                                    setValue("submittoid", null)
                                    setValue("submittocode", null)
                                    setValue("submittoname", null)
                                    setValue("submittoifamountexceed", null)
                                }}>
                                <input
                                    className={`form-control form-control-sm ${errors.submittoid && 'form-error'}`} {...register('submittocode')}
                                    readOnly
                                    onClick={() => {
                                        setTypeSearch('submittoid')
                                        setKeys([
                                            ['submittoid', 'id'],
                                            ['submittocode', 'username'],
                                            ['submittoname', 'fullname']
                                        ])
                                    }} />
                            </ExtendClearValue>
                        </KTFormItem>
                        <KTFormItem title=''>
                            <button
                                type="button"
                                className={clsx("btn btn-sm btn-link d-flex flex-center text-primary", { 'd-none': input1 })}
                                onClick={() => {
                                    setValue("submittoifamountexceed", null)
                                    setInput1(true)
                                }}>
                                <i className="bi bi-plus-circle-fill text-primary"></i> Add Criterion
                            </button>
                            <div className={clsx("d-flex align-items-center gap-2", { 'd-none': !input1 })}>
                                <span className="w-250px">Only if amount exceeds</span>
                                <ExtendClearValue
                                    hide={!watch("submittoifamountexceed")}
                                    handleClear={() => {
                                        setValue("submittoifamountexceed", null)
                                        setInput1(false)
                                    }}>
                                    <Controller
                                        name="submittoifamountexceed"
                                        control={control}
                                        render={({ field: { value, onChange } }) => <InputPrice
                                            value={value}
                                            onChange={onChange} />}
                                    />
                                </ExtendClearValue>
                            </div>
                        </KTFormItem>
                        <KTFormItem title='text.forward-to' isHeight>
                            <ExtendClearValue
                                hide={!watch("forwardtoid")}
                                handleClear={() => {
                                    setValue("forwardtoid", null)
                                    setValue("forwardtocode", null)
                                    setValue("forwardtoname", null)
                                    setValue("forwardtoifamountexceed", null)
                                }}>
                                <input
                                    className={`form-control form-control-sm ${errors.forwardtoid && 'form-error'}`} {...register('forwardtocode')}
                                    readOnly
                                    onClick={() => {
                                        setTypeSearch('forwardtoid')
                                        setKeys([
                                            ['forwardtoid', 'id'],
                                            ['forwardtocode', 'username'],
                                            ['forwardtoname', 'fullname']
                                        ])
                                    }} />
                            </ExtendClearValue>
                        </KTFormItem>
                        <KTFormItem title=''>
                            <button
                                type="button"
                                className={clsx("btn btn-sm btn-link d-flex flex-center text-primary", { 'd-none': input2 })}
                                onClick={() => {
                                    setValue("forwardtoifamountexceed", null)
                                    setInput2(true)
                                }}>
                                <i className="bi bi-plus-circle-fill text-primary"></i> Add Criterion
                            </button>
                            <div className={clsx("d-flex align-items-center gap-2", { 'd-none': !input2 })}>
                                <span className="w-250px">Only if amount exceeds</span>
                                <ExtendClearValue
                                    hide={!watch("forwardtoifamountexceed")}
                                    handleClear={() => {
                                        setValue("forwardtoifamountexceed", null)
                                        setInput2(false)
                                    }}>
                                    <Controller
                                        name="forwardtoifamountexceed"
                                        control={control}
                                        render={({ field: { value, onChange } }) => <InputPrice
                                            value={value}
                                            onChange={onChange} />}
                                    />
                                </ExtendClearValue>
                            </div>
                        </KTFormItem>
                        <KTFormItem title='text.approval.admin'>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                                <input {...register('approvaladministrator')} className="form-check-input" type="checkbox" />
                            </label>
                        </KTFormItem>
                        <KTFormItem title='text.admin-self-service'>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                                <input {...register('isadminselfservice')} className="form-check-input" type="checkbox" />
                            </label>
                        </KTFormItem>
                        <KTFormItem title='text.user-setup.isshowdataentryform'>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                                <input {...register('isshowdataentryform')} className="form-check-input" type="checkbox" />
                            </label>
                        </KTFormItem>
                        <KTFormItem title='text.user-setup.isapprovalcompletion'>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                                <input {...register('isapprovalcompletion')} className="form-check-input" type="checkbox" />
                            </label>
                        </KTFormItem>
                        <KTFormItem title='text.user-setup.isadminview'>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                                <input {...register('isadminview')} className="form-check-input" type="checkbox" />
                            </label>
                        </KTFormItem>
                        <KTFormItem title='text.user-group' className="py-3">
                            {watch('usergroup')}
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form >
        <SearchPopup
            {...{
                isLoading: loadUsers || loadApprovalusersetups,
                keys,
                setValue,
                searchData: changeTypeSearch(optionsUser),
                handleClose: function () { setKeys([]) }
            }} />
    </>
}
