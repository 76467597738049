/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { ApiService, ApiShowError, encryptData } from '../../../../theme/helpers'
import { Languages } from '../../../../theme/partials/layout/Languages'
import { useAuth } from '../core/Auth'
import { framePermission } from '../Permission'
import axios from 'axios'

async function requestAuthenticated(id: string) {
  try {
    const newAuth = await ApiService.post(`/auth/authenticate/${id}`)
    return newAuth.data
  } catch (error) {
    ApiShowError(error)
  }
  return undefined
}



function getLocalUser() {
  if (!process.env.REACT_APP_USERNAME) return []
  return process.env.REACT_APP_USERNAME?.split('/')
}
export function getPermissions(datas: any) {
  let permissions = [] as any

  datas?.forEach((item: any) => {
    let tempPermission = [] as any
    framePermission?.forEach((i: any) => {
      if (item[i.code]) tempPermission.push(i.value)
    })
    let temp = {
      code: item.objectid,
      permissions: tempPermission
    }
    permissions.push(temp)
  })
  permissions = JSON.stringify(permissions) as any
  permissions = encryptData(permissions) as any
  return permissions
}
export function Login() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [viewPass, setViewPass] = useState(false)
  const { saveAuth, auth } = useAuth()

  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, intl.formatMessage({ id: "login.min-character" as any }))
      .max(50, intl.formatMessage({ id: "login.max-character" as any }))
      .required(intl.formatMessage({ id: "login.field-required" as any })),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: "login.min-character" as any }))
      .max(50, intl.formatMessage({ id: "login.max-character" as any }))
      .required(intl.formatMessage({ id: "login.field-required" as any })),
  })

  const formik = useFormik({
    initialValues: {
      username: getLocalUser()[0] || '',
      password: getLocalUser()[1] || '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        values.username = values.username.toLowerCase()
        let { data } = await ApiService.post(`auth/login`, values)
        data.user.rolecode = data.user.rolecodes.map((m: string) => encryptData(m))
        saveAuth({ token: data.accessToken, user: data.user, permissions: getPermissions(data.permissions) })
        window.location.href = '/dashboard'
      } catch (error) {
        ApiShowError(error)
      }
      setLoading(false)
    },
  })

  useEffect(() => {
    async function checkAuth() {
      if (auth && auth.user?.id) {
        const { isAuthenticated, newAccessToken } = await requestAuthenticated(auth.user?.id)
        if (newAccessToken && isAuthenticated) saveAuth({ ...auth, token: newAccessToken })
        if (isAuthenticated) {
          window.location.href = '/dashboard'
        } else {
          saveAuth(undefined)
        }
      }
    }
    checkAuth()
  }, [auth])
  const loginAzure = async () => {
    let env = ''
    if (process.env.REACT_APP_LOCAL != 'false') {
      env = '&env=local'
    }
    await ApiService
      .get(`auth2/authorize?${env}`)
      .then(({ data }) => {
        window.location.href = data
      })
      .catch(({ response }) => {
        console.log("err", response);
      });
  }
  return <form id='kt_login_signin_form' className='form w-100 p-6 shadow-sm d-flex flex-column gap-4' onSubmit={formik.handleSubmit} noValidate>
    <div className='text-center'>
      <div className='m-auto mb-2'>
        <img src="/media/images/logo_sp_trungnguyenlegend.png" alt="loginimage" />
      </div>
      <div className='text-gray-700 fw-semibold fs-6 mb-4'>
        {intl.formatMessage({ id: "login.more" })}
      </div>
      <h3 className='text-dark fw-bolder text-gray-700 text-uppercase'>
        <FormattedMessage id="login" />
      </h3>
    </div>
    <div className='fv-row'>
      <label className='form-label fs-6 fw-bolder text-dark'><FormattedMessage id="login.username" /></label>
      <input
        {...formik.getFieldProps('username')}
        className={clsx(
          'form-control bg-transparent', {
          'is-invalid': formik.touched.username && formik.errors.username,
          'is-valid': formik.touched.username && !formik.errors.username,
        },)}
        type='text'
        name='username'
        autoComplete='off'
      />
      {formik.touched.username && formik.errors.username && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        </div>
      )}
    </div>
    <div className='fv-row'>
      <label role='alert' className='form-label fw-bolder text-dark fs-6'><FormattedMessage id="login.password" /></label>
      <div className='position-relative'>
        <input
          type={viewPass ? 'text' : 'password'}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent', {
            'is-invalid': formik.touched.password && formik.errors.password,
          },)}
        />
        {!(formik.touched.password && formik.errors.password) && <div className='cursor-pointer view-password' onClick={e => setViewPass(!viewPass)}>
          <i className={clsx(`text-dark fs-5 bi bi-eye${viewPass ? '-slash' : ''}`)}></i>
        </div>}
      </div>

      {formik.touched.password && formik.errors.password && <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{formik.errors.password}</span>
        </div>
      </div>}
    </div>
    <div className="d-flex align-items-center flex-stack">
      <Languages />
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold'>
        <Link to='/forgot-password' className='link-primary'> <FormattedMessage id="text.forgot-password" /> </Link>
      </div>
    </div>
    <div className='rounded'>
      {intl.formatMessage({ id: "login.when-click-login" }, {
        "login": <strong className='text-orange text-uppercase'>
          <FormattedMessage id="login" />
        </strong>,
        "service_agreement": <strong className='text-orange'>
          <FormattedMessage id="login.service-agreement" />
        </strong>
      })}
    </div>
    <div className='d-grid mt-6'>
      <button
        type='submit'
        id='kt_sign_in_submit'
        className='btn bg-light-orange rounded-5 border-orange border border-2 border-solid text-white fw-bolder'
        disabled={formik.isSubmitting || !formik.isValid}
      >
        {!loading && <span className='indicator-label text-uppercase'><FormattedMessage id="login" /></span>}
        {loading && <span className='indicator-progress' style={{ display: 'block' }}>
          ...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
        }
      </button>
    </div>
    <div className='d-flex flex-center gap-1 wrap-or-login'>
      <div className='line-1'></div>
      <span><FormattedMessage id="text.login.or" /></span>
      <div className='line-1'></div>
    </div>
    <div className='cursor-pointer fw-bolder mx-auto' onClick={loginAzure}>
      <div className='d-flex gap-2 flex-center btn bg-secondary rounded-5 border-gray border border-2 px-10'>
        <img className='w-20px' src="/media/images/Microsoft-Logo.png" alt="" />
        <FormattedMessage id="login.sign-in-with-microsoft" />
      </div>
    </div>
  </form>
}
