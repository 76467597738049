import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { IFnbTimeBasedMenu } from ".";
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId, jsUcfirst } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { FormSubmit, InputDate, InputSelect, KTFormItem, KTSVG, TextFormByUser, yup } from "../../../../theme/partials";
import { InputTime } from "../../../../theme/partials/widgets/form/InputTime";
import { apiFnb } from "../../../modules/api/fnb";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";

const defaultValues: IFnbTimeBasedMenu = {
    code: null,
    name: null,
    active: false,
    daysoftheweek: null,
    data: []
}

const tablist = [
    { title: 'General', ico: '/media/icons/tabs/info (1).svg' },
    { title: 'Available', ico: '/media/icons/tabs/item_available.svg' },
]

export function FNBTimeBasedMenusForm({ keyElement, pathElement, permission }: MergedProps) {
    const urlApi = 'fnb/timebasedmenus'
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching } = apiFnb.useTimeBasedMenusId<IFnbTimeBasedMenu>(valueid)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IFnbTimeBasedMenu>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            name: yup.string().required(),
            starteddate: yup.string().required(),
            endeddate: yup.string().nullable(true).transform(val => val || null),
            daysoftheweek: yup.object().shape({
                monday: yup.object().shape({
                    start_time: yup.string().test('require_monday_start', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                    end_time: yup.string().test('require_monday_end', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                }),
                tuesday: yup.object().shape({
                    start_time: yup.string().test('require_tuesday_start', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                    end_time: yup.string().test('require_tuesday_end', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                }),
                wednesday: yup.object().shape({
                    start_time: yup.string().test('require_wednesday_start', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                    end_time: yup.string().test('require_wednesday_end', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                }),
                thursday: yup.object().shape({
                    start_time: yup.string().test('require_thursday_start', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                    end_time: yup.string().test('require_thursday_end', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                }),
                friday: yup.object().shape({
                    start_time: yup.string().test('require_friday_start', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                    end_time: yup.string().test('require_friday_end', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                }),
                saturday: yup.object().shape({
                    start_time: yup.string().test('require_saturday_start', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                    end_time: yup.string().test('require_saturday_end', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                }),
                sunday: yup.object().shape({
                    start_time: yup.string().test('require_sunday_start', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                    end_time: yup.string().test('require_sunday_end', (val, ctx) => {
                        if (ctx.parent['status'] && !val) return ctx.createError()
                        return true
                    }).nullable(true).transform(val => val || null),
                })
            }),
            data: yup
                .array()
                .of(yup.object().shape({
                    categoryid: yup.string().required()
                }))
        })),
    })
    const [tabListId, setTabListId] = useState('General')

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset({ ...data })
    }, [isFetching])

    const { data: dataCategories, isSuccess: isCategories } = apiFnb.useCategories()
    const optionCategories = isCategories ? formatOptions(dataCategories, ['id', 'name']) : []

    const { data: dataSubCategories, isSuccess: isSubCategories } = apiFnb.useSubCategories()
    const optioSubCategories = isSubCategories ? formatOptions(dataSubCategories, ['id', 'name']) : []

    const { data: dataHopsItem, isSuccess: isHopsItem } = apiFnb.useHospitalityItems()
    const optionItems = isHopsItem ? formatOptions(dataHopsItem, ['id', 'name']) : []

    async function handleDeleteLine(index: number, key: any) {
        if (!watch(key)) return popupMessage({ icon: "error" })
        return setValue(key, watch(key).filter((f: any, i: number) => i != index) || [])
    }

    const refSubmit = useRef<HTMLButtonElement | null>(null)
    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} elements={<>
            <TextFormByUser data={watch()} />
            <FormSubmit
                type={isEdit}
                isSubmitting={isSubmitting}
                permission={permission}
                handleSubmit={() => refSubmit.current?.click()}
                handleClose={() => navigate(pathElement)} />
        </>} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IFnbTimeBasedMenu) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <button ref={refSubmit} type="submit" className="d-none"></button>
            <div className="card-header bg-secondary rounded-top">
                <h3 className="card-title">
                    {tablist.map(text => <div key={text.title} onClick={() => setTabListId(text.title)} className={clsx('btn p-3 py-1 rounded min-w-125px text-center', {
                        'bg-orange text-white': tabListId == text.title
                    })}>
                        <KTSVG path={text.ico} className={clsx(`svg-icon-2 d-block m-0`, tabListId == text.title ? 'svg-icon-white' : 'svg-icon-dark')} />
                        <FormattedMessage id={`text.${text.title.toLowerCase()}` as any} />
                    </div>)}
                </h3>
            </div>
            <div className="card-body">
                <div className={clsx("row", { 'd-none': tabListId != 'General' })}>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                        </KTFormItem>
                        <KTFormItem title='text.active'>
                            <label className="form-check form-switch form-check-custom">
                                <input className="form-check-input" type="checkbox" {...register("active")} />
                            </label>
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.starting-date'>
                            <Controller
                                control={control}
                                name={`starteddate`}
                                render={({ field: { value, onChange } }) => <InputDate
                                    value={value}
                                    onChange={(e) => {
                                        if (!e.length) return onChange(null)
                                        onChange(e[0].toISOString())
                                    }}
                                    className={clsx({ 'form-error': errors.starteddate })}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.ending-date'>
                            <Controller
                                control={control}
                                name={`endeddate`}
                                render={({ field: { value, onChange } }) => <InputDate
                                    value={value}
                                    onChange={(e) => {
                                        if (!e.length) return onChange(null)
                                        onChange(e[0].toISOString())
                                    }}
                                    className={clsx({ 'form-error': errors.endeddate })}
                                />}
                            />
                        </KTFormItem>
                    </div>
                    <div className="col-12">
                        <hr />
                        <div className="card-header px-0 pt-0 border-bottom-0">
                            <div className="card-title"><FormattedMessage id="text.days-of-the-week" /></div>
                        </div>
                        <div className="d-flex gap-2">
                            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((item: any) => <div
                                key={item}
                                style={{ width: `${100 / 7}%` }}
                                className={clsx(
                                    "rounded flex-1 d-flex flex-column", {
                                    'border border-2 border-primary': watch(`daysoftheweek.${item}.status`),
                                    'opacity-75 ': !watch(`daysoftheweek.${item}.status`),
                                    'border-danger': errors.daysoftheweek && watch(`daysoftheweek.${item}.status`) && (!watch(`daysoftheweek.${item}.start_time`) || !watch(`daysoftheweek.${item}.end_time`))
                                })}>
                                <span
                                    className={clsx(
                                        "fw-bold cursor-pointer rounded pt-2 px-2", {
                                        'border border-2 pb-2': !watch(`daysoftheweek.${item}.status`),
                                        'text-primary': watch(`daysoftheweek.${item}.status`)
                                    })}>
                                    <label className="form-check form-check-sm form-check-custom">
                                        <input className="form-check-input" type="checkbox" {...register(`daysoftheweek.${item}.status`, {
                                            onChange(event) {
                                                if (!event.target.checked) {
                                                    setValue(`daysoftheweek.${item}.start_time`, null)
                                                    setValue(`daysoftheweek.${item}.end_time`, null)
                                                }
                                                return event
                                            },
                                        })} />
                                        <FormattedMessage id={`text.${item}` as any}/>
                                    </label>
                                </span>
                                {watch(`daysoftheweek.${item}.status`) && <div className="d-flex flex-center mt-2 border-top">
                                    <Controller
                                        control={control}
                                        name={`daysoftheweek.${item}.start_time`}
                                        render={({ field: { value, onChange } }) => <InputTime
                                            className="text-center border-0 bg-transparent form-control-sm px-0"
                                            value={value}
                                            onChange={onChange} />}
                                    />
                                    <i className="fa-solid fa-arrow-right"></i>
                                    <Controller
                                        control={control}
                                        name={`daysoftheweek.${item}.end_time`}
                                        render={({ field: { value, onChange } }) => <InputTime
                                            className="text-center border-0 bg-transparent form-control-sm px-0"
                                            value={value}
                                            onChange={onChange} />}
                                    />
                                </div>}
                            </div>)}
                        </div>
                    </div>
                </div>
                <div className={clsx("d-flex flex-column", { 'd-none': tabListId != 'Available' })}>
                    {watch("data").length
                        ? watch("data").map((line, index) => <div key={index} className="card border border-secondary mb-4 d-row-hover">
                            <div className="card-body pb-0">
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <KTFormItem title='text.category'>
                                            <Controller
                                                control={control}
                                                name={`data.${index}.categoryid`}
                                                render={({ field: { value, onChange } }) => <InputSelect
                                                    options={optionCategories}
                                                    value={optionCategories.find(f => f.value == value) || null}
                                                    onChange={(e) => {
                                                        setValue(`data.${index}.subcategories`, null)
                                                        setValue(`data.${index}.hopitems`, null)
                                                        if (!e) return onChange(null)
                                                        onChange(e.value)
                                                    }}
                                                />}
                                            />
                                        </KTFormItem>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <KTFormItem title='text.sub-category' isHeight labelClassName="mt-3">
                                            <Controller
                                                control={control}
                                                name={`data.${index}.subcategories`}
                                                render={({ field: { value, onChange } }) => <InputSelect
                                                    isMulti
                                                    options={optioSubCategories.filter(f => f.categoryid == watch(`data.${index}.categoryid`))}
                                                    value={optioSubCategories.filter(f => value?.map((v: any) => v.id).includes((f.value))) || null}
                                                    onChange={(e) => {
                                                        if (!e) return onChange(null)
                                                        onChange(e.map((v: any) => ({ id: v.id, code: v.code, name: v.name })))
                                                    }}
                                                />}
                                            />
                                        </KTFormItem>
                                    </div>
                                    <div className="col-12">
                                        <label className="form-check form-check-sm form-check-custom me-3 w-100px">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={optionItems
                                                    .filter(f => watch(`data.${index}.subcategories`)?.map((v: any) => v.code).includes(f.subcategorycode))
                                                    .length == watch(`data.${index}.hopitems`)?.length}
                                                value={''}
                                                onChange={(ev) => {
                                                    if (optionItems
                                                        .filter(f => watch(`data.${index}.subcategories`)?.map((v: any) => v.code).includes(f.subcategorycode))
                                                        .length == watch(`data.${index}.hopitems`)?.length) {
                                                        setValue(`data.${index}.hopitems`, [])
                                                        return ev
                                                    }
                                                    setValue(`data.${index}.hopitems`, optionItems
                                                        .filter(f => watch(`data.${index}.subcategories`)?.map((v: any) => v.code).includes(f.subcategorycode))
                                                        .map(i => i.value))
                                                    return ev
                                                }} />
                                            <FormattedMessage id="text.select-all" />
                                        </label>
                                        <hr />
                                        <div className="row">
                                            {optionItems
                                                .filter(f => watch(`data.${index}.subcategories`)?.map((v: any) => v.code).includes(f.subcategorycode)).length
                                                ? optionItems
                                                    .filter(f => watch(`data.${index}.subcategories`).map((v: any) => v.code).includes(f.subcategorycode))
                                                    .map(item => <div key={item.value} className="col-12 col-xxl-3 col-lg-4 col-sm-6 ">
                                                        <label className="form-check form-check-sm form-check-custom d-inline-block mb-3 me-3">
                                                            <div className="d-flex align-items-start gap-2">
                                                                <input className="form-check-input" type="checkbox" value={item.value} {...register(`data.${index}.hopitems`)} />
                                                                {item.label}
                                                            </div>
                                                        </label>
                                                    </div>)
                                                : <div className="col-12"><FormattedMessage id="table.not-data" /></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex w-100">
                                <span className="btn btn-icon btn-sm btn-light-danger ms-auto d-cell-hover" onClick={() => handleDeleteLine(index, 'data')}>
                                    <KTSVG path={'/media/icons/duotune/general/gen027.svg'} className={`svg-icon-4 svg-icon-danger`} />
                                </span>
                            </div>
                        </div>)
                        : <></>}
                    <div className="card card-body border border-secondary">
                        <span
                            className={clsx("btn btn-icon btn-sm btn-primary rounded-circle mx-auto")}
                            onClick={() => setValue('data', [...(watch('data') || []), {
                                categoryid: null,
                                subcategories: [],
                                hopitems: []
                            }])}>
                            <i className="bi bi-plus-lg fs-1"></i>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </>
}