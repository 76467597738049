import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import { HeaderProps } from 'react-table'

type Props = {
  className?: string
  title?: string | any
  tableProps: PropsWithChildren<HeaderProps<any>>
  id?: string
}
export const CustomHeader: React.FC<Props> = ({ className, title, tableProps, id }) => {
  const intl = useIntl()
  const order: 'asc' | 'desc' | undefined = tableProps.column.isSortedDesc ? 'asc' : 'desc'
  const option = {
    ...tableProps.column.getHeaderProps(tableProps.column.getSortByToggleProps({ title: undefined })),
    id: id
  }
  if (!id) delete option.id
  const textTitle = typeof title == 'string' && title.includes("text.") ? intl.formatMessage({ id: title as any }) : title

  return (
    <th
      {...option}
      className={clsx(
        className,
        tableProps.column.isSorted && order !== undefined && `table-sort-${order}`
      )}
    >
      {textTitle}
    </th>
  )
}
