import { useQuery } from "react-query";
import { ApiService } from "../../../theme/helpers/ApiService";
export * from './type'

type IApiType =
    | 'categories'
    | 'subcategories'
    | 'menustructures'
    | 'menustructurelines'
    | 'hospitems'
    | 'hospitemvariants'
    | 'hospitemprices'
    | 'customizations'
    | 'customizationlines'
    | 'tags'
    | 'timebasedmenus'
    | 'merchants'
    | 'staffs'
    | 'stafflists'
    | 'hospdiningtables'
    | 'hospdiningareasections'
    | 'customerpricegroups'

export const fetchID = (name: IApiType, id?: string, query?: string) => {
    if (!query) {
        query = ''
    }
    return ApiService.get(`/fnb/${name}${id ? `/${id}` : ''}${query}`).then(res => res.data)
}


export const fetchQuery = (name: IApiType, query?: string) =>
    ApiService.get(`/fnb/${name}${query || ''}`).then(res => res.data)

export const apiFnb = {
    //Api Categories
    useCategories() {
        return useQuery(['fnb-categories'], () => fetchID('categories'))
    },
    useCategoriesId<I>(id?: string) {
        return useQuery<I>(['fnb-categories', id], () => fetchID('categories', id), { enabled: !!id })
    },
    //Api Sub Categories
    useSubCategories() {
        return useQuery(['fnb-subcategories'], () => fetchID('subcategories'))
    },
    useSubCategoriesId<I>(id?: string) {
        return useQuery<I>(['fnb-subcategories', id], () => fetchID('subcategories', id), { enabled: !!id })
    },
    //Api Menu Structure
    useMenuStructures() {
        return useQuery(['fnb-menustructures'], () => fetchID('menustructures'))
    },
    useMenuStructuresId<I>(id?: string, query?: string) {
        return useQuery<I>(['fnb-menustructures', id], () => fetchID('menustructures', id, query), { enabled: !!id })
    },
    useMenustructurelines<I>(id?: string) {
        return useQuery<I>(['fnb-menustructurelines', id], () => fetchID('menustructurelines', id), { enabled: !!id })
    },
    //Api Hospitality Items
    useHospitalityItems() {
        return useQuery(['fnb-hospitems'], () => fetchID('hospitems'))
    },
    useHospitalityItemsId<I>(id?: string) {
        return useQuery<I>(['fnb-hospitems', id], () => fetchQuery('hospitems', `/${id}?expand=hospitemvariants,hospitemprices`), { enabled: !!id })
    },
    useHospItemVariants() {
        return useQuery(['fnb-hospitemvariants'], () => fetchID('hospitemvariants'))
    },
    useHospItemVariantsByItemID(hospitemid?: string) {
        return useQuery(['fnb-hospitemvariants', hospitemid], () => fetchQuery('hospitemvariants', `?hospitemid=${hospitemid}`), { enabled: !!hospitemid })
    },
    useHospItemVariantsId<I>(id?: string) {
        return useQuery<I>(['fnb-hospitemvariants', id], () => fetchID('hospitemvariants', id), { enabled: !!id })
    },
    useHospItemPrices() {
        return useQuery(['fnb-hospitemprices'], () => fetchID('hospitemprices'))
    },
    useHospItemPricesByItemID(hospitemid?: string) {
        return useQuery(['fnb-hospitemprices', hospitemid], () => fetchQuery('hospitemprices', `?hospitemid=${hospitemid}`), { enabled: !!hospitemid })
    },
    useHospItemPricesId<I>(id?: string) {
        return useQuery<I>(['fnb-hospitemprices', id], () => fetchID('hospitemprices', id), { enabled: !!id })
    },
    //Api Customizations
    useCustomizations() {
        return useQuery(['fnb-customizations'], () => fetchQuery('customizations', '?expand=customizationlines'))
    },
    useCustomizationsId<I>(id?: string) {
        return useQuery<I>(['fnb-customizations', id], () => fetchQuery('customizations', `/${id}?expand=customizationlines`), { enabled: !!id })
    },
    //Api Tags
    useTags() {
        return useQuery(['fnb-tags'], () => fetchID('tags'))
    },
    useTagsId<I>(id?: string) {
        return useQuery<I>(['fnb-tags', id], () => fetchID('tags', id), { enabled: !!id })
    },
    //Api Time Based Menus
    useTimeBasedMenus() {
        return useQuery(['fnb-timebasedmenus'], () => fetchID('timebasedmenus'))
    },
    useTimeBasedMenusId<I>(id?: string) {
        return useQuery<I>(['fnb-timebasedmenus', id], () => fetchID('timebasedmenus', id), { enabled: !!id })
    },
    //Api Promotions
    usePromotions() {
        return useQuery(['fnb-categories'], () => fetchID('categories'))
    },
    usePromotionsId<I>(id?: string) {
        return useQuery<I>(['fnb-categories', id], () => fetchID('categories', id), { enabled: !!id })
    },
    //Api Merchants
    useMerchants() {
        return useQuery(['ad-merchants'], () => fetchID('merchants'))
    },
    useMerchantsId<I>(id?: string) {
        return useQuery<I>(['ad-merchants', id], () => fetchID('merchants', id), { enabled: !!id })
    },
    useHospStaffs() {
        return useQuery(['ad-staffs'], () => fetchID('staffs'))
    },
    useHospStaffsByQuery(query?: string) {
        return useQuery(['ad-staffs', query], () => fetchQuery('staffs', query), { enabled: !!query })
    },
    useHospStaffsById<I>(id?: string) {
        return useQuery<I>(['ad-staffs', id], () => fetchID('staffs', id), { enabled: !!id })
    },
    useHospDiningTables() {
        return useQuery(['ad-hospdiningtables'], () => fetchID('hospdiningtables'))
    },
    useHospDiningTablesByQuery(query?: string) {
        return useQuery(['ad-hospdiningtables', query], () => fetchQuery('hospdiningtables', query), { enabled: !!query })
    },
    useHospDiningTablesId<I>(id?: string) {
        return useQuery<I>(['ad-hospdiningtables', id], () => fetchID('hospdiningtables', id), { enabled: !!id })
    },
    useHospDiningAreaSections() {
        return useQuery(['ad-hospdiningareasections'], () => fetchID('hospdiningareasections'))
    },
    useHospDiningAreaSectionsByQuery(query?: string) {
        return useQuery(['ad-hospdiningareasections', query], () => fetchQuery('hospdiningareasections', query), { enabled: !!query })
    },
    useHospDiningAreaSectionsId<I>(id?: string) {
        return useQuery<I>(['ad-hospdiningareasections', id], () => fetchID('hospdiningareasections', id), { enabled: !!id })
    },
}