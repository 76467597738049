import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { IDepartment } from ".";
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { ExtendClearValue, FormSubmit, InputSelect, KTFormItem, TextFormByUser, yup } from "../../../../theme/partials";
import SearchPopup from "../../../../theme/partials/layout/search-popup";
import { apiPrivate } from "../../../modules/api";
import { apiAd } from "../../../modules/api/admin";
import { popupMessage } from "../../../modules/messages";
import { IAdUser } from "../../../modules/settings/components/users";
import { MergedProps } from "../../../router/AppRoutes";

const defaultValues: IDepartment = {
    code: null,
    departmentname: null,
    parentid: null,
    parentname: null,
    description: null,
    departmentheadid: null,
    departmentheadcode: null,
    departmentheadname: null,
    departmentheadphone: null,
    departmentheademail: null,
    statuscode: 1
}

export const SettingDepartmentsForm = ({ keyElement, pathElement, permission }: MergedProps) => {
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const [isSubDepartment, setIsSubDepartment] = useState(false)

    const { data, isSuccess, isFetching } = apiPrivate.useDepartmentsId<IDepartment>(valueid)
    const { register, reset, watch, control, setError, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IDepartment>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            departmentname: yup.string().required(),
            parentid: yup.string().test((val, ctx) => {
                if(!val && isSubDepartment) return ctx.createError()
                return true
            }).nullable().transform(val => val || null)
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching) {
            reset(data)
            data?.parentid && setIsSubDepartment(true)
        }
    }, [isFetching])

    const { data: departmentsData, isSuccess: isDepartment, isLoading: loadDepartment } = apiPrivate.useDepartments()
    const optionDepartments = isDepartment ? formatOptions(departmentsData.filter((f: any) => !f.parentid && f.id != valueid), ['id', 'departmentname']) : []

    const [keys, setKeys] = useState<any[]>([])
    const { data: usersData, isSuccess: iUsers, isLoading: loadUsers } = apiAd.useUsers()
    const optionsUser = iUsers
        ? usersData.map((item: IAdUser) => ({ ...item, title: item.fullname, description: `${item.username}-${item.authenticationemail}` }))
        : []

    function changeTypeSearch() {
        const mapids = isDepartment
            ? departmentsData.map((item: any) => item.departmentheadid).filter((f: string) => f != data?.departmentheadid)
            : []
        return iUsers ? optionsUser.filter((f: any) => true/* !mapids.includes(f.id) */) : []
    }

    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IDepartment) => {
                try {
                    // if (!arrObject) return popupMessage({ icon: 'error' })
                    // if (!arrObject.nosericode && !data.code) return setError(`code`, { type: 'validate' }, { shouldFocus: true })
                    // let nosericode = arrObject.nosericode && !isEdit ? `/?nosericode=${arrObject.nosericode}` : ''
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](`settings/departments${isEdit ? `/${valueid}` : ''}` /* ${nosericode}` */, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.departmentname && 'form-error'}`} {...register('departmentname')} />
                        </KTFormItem>
                        <KTFormItem title="">
                            <span className="form-check form-switch form-switch-sm form-check-custom form-check">
                                <input
                                    className={clsx("form-check-input")}
                                    type="checkbox"
                                    checked={isSubDepartment}
                                    onChange={(e) => {
                                        if(!e.target.checked){
                                            setValue('parentid', null)
                                            setValue('parentname', null)
                                        }
                                        setIsSubDepartment(e.target.checked)
                                    }}
                                />
                                Make this a sub-department
                            </span>
                        </KTFormItem>
                        {isSubDepartment && <KTFormItem title='text.parent'>
                            <Controller
                                name="parentid"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isClearable
                                    options={optionDepartments}
                                    value={optionDepartments.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue('parentname', null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue('parentname', e.label)
                                    }}
                                    className="w-100"
                                />}
                            />
                        </KTFormItem>}
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.department-head'>
                            <ExtendClearValue
                                hide={!watch("departmentheadid")}
                                handleClear={() => {
                                    setValue("departmentheadid", null)
                                    setValue("departmentheadcode", null)
                                    setValue("departmentheadname", null)
                                    setValue("departmentheademail", null)
                                    setValue("departmentheadphone", null)
                                }}>
                                <input
                                    className={`form-control form-control-sm ${errors.departmentheadid && 'form-error'}`} {...register('departmentheadname')}
                                    readOnly
                                    onClick={() => {
                                        setKeys([
                                            ['departmentheadid', 'id'],
                                            ['departmentheadname', 'fullname'],
                                            ['departmentheademail', 'contactemail'],
                                            ['departmentheadphone', 'phone'],
                                            ['departmentheadcode', 'username'],
                                        ])
                                    }} />
                            </ExtendClearValue>
                        </KTFormItem>
                        <KTFormItem title='text.department-head.email'>
                            <input className={`form-control form-control-sm ${errors.departmentheademail && 'form-error'}`} {...register('departmentheademail')} />
                        </KTFormItem>
                        <KTFormItem title='text.department-head.phone'>
                            <input className={`form-control form-control-sm ${errors.departmentheadphone && 'form-error'}`} {...register('departmentheadphone')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12">
                        <KTFormItem title='text.description' isHeight>
                            <textarea className={`form-control form-control-sm ${errors.description && 'form-error'}`} {...register('description')} rows={3} />
                        </KTFormItem>
                        <KTFormItem title="Active">
                            <span className="form-check form-switch form-switch-sm form-check-custom form-check">
                                <input
                                    className={clsx("form-check-input", { "form-error": errors.statuscode })}
                                    type="checkbox"
                                    checked={watch('statuscode') == 1 ? true : false}
                                    onChange={(e) => setValue('statuscode', e.target.checked ? 1 : 2)}
                                />
                            </span>
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
        <SearchPopup
            {...{
                isLoading: loadUsers || loadDepartment,
                keys,
                setValue,
                searchData: changeTypeSearch(),
                handleClose: function () { setKeys([]) }
            }} />
    </>
}