/* eslint-disable */
import { debounce } from 'lodash'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
// import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx'
import { Dropdown, Modal } from 'react-bootstrap'
import ReactDataSheet from 'react-datasheet'
import 'react-datasheet/lib/react-datasheet.css'
import { yupResolver } from "@hookform/resolvers/yup"
import { Link, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as yup from "yup"
import { ApiService } from '../../../../theme/helpers/ApiService'
import { PageLink, PageTitle } from '../../../../theme/layout/core'
import SearchPopup from '../../../../theme/partials/layout/search-popup'
import { KTFormItem } from '../../../../theme/partials/widgets/utils/KTFormItem'
import { KTSVG } from '../../../../theme/partials/widgets/utils/KTSVG'
import { useAuth } from '../../../modules/auth'
import { ShowForPermission, getIsPermission } from '../../../modules/auth/Permission'
import { popupCustomQuestion, popupDelete, popupMessage } from '../../../modules/messages'
import { CustomHeader, BasicCell, InputDate, InputSelect, InputFiles, InputPrice, RowIcon, KTButton, FormSubmit, TextFormByUser, DivRoot } from '../../../../theme/partials'
import { KTTable } from '../../../../theme/partials/widgets/table'
import { ApiShowError, ApiUploadFiles, formatCurrent, formatOptions, getLocalStorage, getValueId, reFormatCurrent, removeAccents, sortWithAny, sortWithDate, unique_arr } from '../../../../theme/helpers'
import { ContentHeader } from '../../../../theme/layout/components/content'
import { useAdvanceId, useAdvanceIdByQuery, useApprovalHistories } from '../../../modules/api/requests'
import { LocalData } from '../../../modules/api/localData'
import { apiPrivate } from '../../../modules/api'
import { apiAd } from '../../../modules/api/admin'
import { approvalusersetupsByQuery, convertOperatorObject, convertStatus, convertToIfCondition, formatBytes, formatDataLevel, getEmailInfo, getInforGroup, getInforUser, historiesApproval, templateCell } from '../../orderRequest/details/function'
import { ActionApprovals } from '../../orderRequest/details/actions'
import { useIntl } from 'react-intl'
import { useZustandStore } from '../../../modules/store'
import { getOptionCustomers, getOptionVenders } from '../../paymentRequest/details/funcStore'

const TemplateComponent: FC<{ permission: string }> = ({ permission }) => {
  const permission_modify = getIsPermission([permission,'MODIFY'])
  const permission_insert = getIsPermission([permission,'INSERT'])
  const zustandStore = useZustandStore() as any

  const intl = useIntl()
  let {selectedLang} = getLocalStorage('siteConfig') as any
  selectedLang = selectedLang||'vi'
  const { id } = useParams() as any
  const { isEdit, valueid } = getValueId(id)
  const { data: dataDepartments } = apiPrivate.useDepartments()
  const {auth} = useAuth()
  const {user} = auth || {} as any
  const [idRequest,setidRequest] = useState<any>(valueid)
  // const { data: dataCustomers } = apiPrivate.useCustomersByQuery('?expand=customerbankaccounts')

  const [optionsDepartment,setOptionsDepartment] = useState<any>([])
  const { data: dataRequiredDocument } = apiAd.useRequiredDocumentsByQuery(`?objectcode=${permission}`)

  const { data: dataQuery, isSuccess, isFetching } = useAdvanceIdByQuery(idRequest,'?expand=employeeadvancerequestlines,User')
  const [ dataRequest, setDataRequest] = useState<any>(dataQuery)
  
  const [requiredDocument,setRequiredDocument] = useState(dataRequiredDocument?.map((i:any)=> {return{values: [],...i}}))
  const { data: dataApprovalHistories,refetch: refetchApproval } = useApprovalHistories(idRequest && `?documentid=${idRequest}&expand=approvaldetails`)||{}
  const { data: dataApprovals, isSuccess: isSuccessApprovals,isFetching: isFetchingApp,refetch: dataSetupApproval } = apiAd.useApprovalsByQuery(
    idRequest ? dataRequest && `?blocked=true&objectcode=${permission}&expand=approvalleveldetails(${dataRequest?.createdby})`:
    `?blocked=true&objectcode=${permission}&expand=approvalleveldetails(${user?.id})`
    )
    
  const [autoApprovalRequest,setAutoApprovalRequest] = useState<any>(false)
  const [approvalLevels,setApprovalLevels] = useState<any>([])
  const [requestUser,setRequestUser] = useState<any>(null)
  const [approvalUser,setApprovalUser] = useState<any>(null)
  const [approvalhistories,setApprovalhistories] = useState<any>(null)
  const { data: objectData, isSuccess: isObjectData } = apiAd.useObjectsByNumber(permission)
  const arrObject: any = isObjectData ? objectData[0] : undefined
  const refApprovalRequest = useRef<any>()
  const refSaveBtn = useRef<any>()
  const [isHasChange,setIsHasChange] = useState<any>(false)
  const [emailnotification,setEmailnotification] = useState<any>({})
  const [listFiles,setListFiles] = useState<any>([])
  const [advancerequestforsomeone,setadvancerequestforsomeone] = useState<any>(false)
  const { data: dataSelf, isSuccess: isdataSelf } = apiAd.useSelfServiceSetups()
  const [optionsDepts,setOptionsDepts] = useState<any>([])
  const [isEditNotApproval,setIsEditNotApproval] = useState<any>(false)
  useEffect(() => {
    if (dataDepartments && dataDepartments.length != 0) {
      setOptionsDepts(formatOptions(dataDepartments, ['id', 'code']))
    }
  }, [dataDepartments])
  useEffect(() => {
    if (dataSelf && dataSelf.length != 0) {
      let data = dataSelf.pop()
      setadvancerequestforsomeone(data.advancerequestforsomeone)
    }
}, [dataSelf])
  useEffect(() => {
    if(dataQuery && isSuccess) {
      let data = JSON.parse(JSON.stringify(dataQuery))
      if(data) {
        data.requireddate = data.requireddate && [new Date(data.requireddate)]
        data.advancepaymentperiod = data.advancepaymentperiod && [new Date(data.advancepaymentperiod)]
      }
      getData(data)
    }
  }, [dataQuery])
  useEffect(() => {
    if(!valueid) {
      getData(undefined)
    }
}, [id])
  useEffect(() => {
    setApprovalhistories(null)
  }, [])
  
  useEffect(() => {
    if(dataApprovalHistories && dataApprovalHistories.length != 0) {
      setApprovalhistories(dataApprovalHistories[0])
    }
    else {
      setApprovalhistories(null)
    }
  }, [dataApprovalHistories])
  const checkIsDisabledForm:any = () => {
    if(!permission_insert && !idRequest) return true
    else if(!permission_modify) return true
    if(isEditNotApproval) return false
    if(!idRequest) return false
    if (!requestUser) return true
    return  (approvalhistories?.currentstatus == '284210001' || approvalhistories?.currentstatus == '284210002' || approvalhistories?.currentstatus == '284210004')
  }
  useEffect(() => {
    if(dataDepartments) {
      setOptionsDepartment(
        dataDepartments.map((e: any) => ({  ...e, title: e.departmentname, description: [e.code, e.departmentname] }))
        )
    }
  }, [dataDepartments])
  const navigate = useNavigate();

  const [tab, setTab] = useState('info')
  const tabs = [
    { id: 'info', name: intl.formatMessage({ id: `text.advance-details` as any }) },
    { id: 'files', name: intl.formatMessage({ id: `text.attachments` as any }) },
  ]
  // const { data: invoiceById, isLoading } = useInvoiceById(id)

  const [optionsVenders,setOptionsVenders] = useState<any>([])
  const [optionsEmployees,setOptionsEmployees] = useState<any>([])
  const [optionsCustomers,setOptionsCustomers] = useState<any>([])
  useEffect(() => {
    setOptionsEmployees(zustandStore?.dataEmployees||[])
  },[zustandStore?.dataEmployees])
  useEffect(() => {
    setOptionsVenders(zustandStore?.dataVenders||[])
  },[zustandStore?.dataVenders])
  useEffect(() => {
    setOptionsCustomers(zustandStore?.dataCustomers||[])
  },[zustandStore?.dataCustomers])
  // useEffect(() => {
  //   if(dataCustomers) {
  //     setOptionsCustomers(
  //       dataCustomers.map((e: any) => ({...e, title: e.customerno, description: e.customername + (e.email?' | '+e.email:''), }))
  //       )
  //   }
  // }, [dataCustomers])
  useEffect(() => {
    const funcApproval = async() => {
      let tempDataLevel = null as any
      let id_request = idRequest||valueid
      if(isSuccessApprovals) {

        if(dataApprovals) {
          
          const dataApprovalsSort = sortWithAny(dataApprovals,{sort:'priority',order:'asc'})
          // console.log('dataApprovalsSort',dataApprovalsSort)
          let checkCond = true
          let groupUser = await getInforGroup(dataRequest?.createdby)||[] as any
          dataApprovalsSort.forEach((item:any) => {
            // console.log('itemm',item)
            if(!checkCond) return
            if(dataRequest) {
              setEmailnotification(getEmailInfo(item.emailnotification,dataRequest,permission))
            }
            
            if(item.type != 20120002) {
              approvalusersetupsByQuery(setRequestUser,setApprovalUser, setApprovalLevels ,user?.id, dataRequest)
              checkCond = false
              return
            }
            else {
              let tempArr = [] as any
              let criteria_string = item.rule?.criteria_string||"1" as string
              let not_rule = true as boolean
              if(item.rule && item.rule.columns && item.rule.columns.length != 0) {
                item.rule?.columns.forEach((it: any) => {
                  let item = it
                  not_rule = false
                  if(item.field == 'usergroup' && item.comparator == 'is' && id_request) {
                    let check = groupUser.find((i: any) => i.usergroupid == item.value)
                    let a = check? true: false
                    tempArr.push(a)
                  }
                  else if(dataRequest) {
                    tempArr.push(convertOperatorObject[item.comparator](dataRequest[item.field],item.value,item.datatype))
                  }
                })
              }
              if (tempArr.length == 0 && dataRequest && not_rule) {
                tempArr = [true]
              }
              // console.log('tempArr',tempArr)
              const ifCondition = convertToIfCondition(criteria_string,tempArr);
              // console.log('ifCondition',ifCondition)
              if (eval(ifCondition)) {
                // console.log("Điều kiện đúng");
                tempDataLevel = formatDataLevel(item?.approvallevels||[],dataRequest?.createdby||user?.id, item?.approvalreminderbefore)
                setApprovalLevels(tempDataLevel)
                if(user?.id == dataRequest?.createdby) {
                  item.approvalid = item.id
                  setRequestUser(item)
                  setIsEditNotApproval(false)
                }
                else if (tempDataLevel?.find((i:any)=>i.userid == user?.id)){
                  setApprovalUser(item)
                }
                checkCond = false
                return
    
              } else {
                // console.log("Điều kiện sai");
                setApprovalLevels(null)
                setApprovalUser(null)
                setRequestUser(null)
                setAutoApprovalRequest(false)
                if(user?.id == dataRequest?.createdby && dataRequest.status == '284210000') {
                  setIsEditNotApproval(true)
                }
              }
            }
          })
        }
        else {
          approvalusersetupsByQuery(setRequestUser,setApprovalUser, setApprovalLevels ,user?.id, dataRequest)
        }
        if(id_request) {
          if((isEditNotApproval || (!tempDataLevel && (user?.id == dataRequest?.createdby && dataRequest.status == '284210000'))) && isSuccessApprovals) {
            popupMessage({icon: "info", description: intl.formatMessage({ id: `text.workflows-not-defined`}) })
          }
          else if(autoApprovalRequest) {
            setTimeout(()=>{refApprovalRequest?.current?.click()},100)
          }
        }
      }
      
    }
    if(isSuccessApprovals && !isFetchingApp && dataApprovals) {
      funcApproval()
    }
    
}, [isSuccessApprovals,isFetchingApp])
let schema = {
  sourceno: yup.string()
    .required('Input is required'),
  description: yup.string()
    .required('Input is required'),
  requireddate: yup.array()
    .required('Input is required'),
  paymentmethodcode: yup.string().required(),
  // departmentid: yup.string()
  //     .required('Input is required'),
  // advancepaymentperiod: yup.array()
  // .required('Input is required'),

}
  
  useEffect(()=>{
    if(isObjectData) {
      if(!(arrObject?.nosericode && !idRequest)) {
        schema = Object.assign(schema,{documentno: yup.string()
          .required('Input is required')})
          setYupSchema(yup.object().shape(schema))
      }
    }
  },[isObjectData])
  const [yupSchema, setYupSchema] = useState<any>(yup.object().shape(schema))
  const [yupSchemaFile,setYupSchemaFile] = useState<any>()
  function checkIfFilesAreTooBig(files?: [File],maxSize?:any): boolean {
    let valid = true
    if (files) {
      files.map(file => {
        const size = file.size / 1024 / 1024
        if (size > maxSize) {
          valid = false
        }
      })
    }
    return valid
  }
  const templateTableCell = () => {
    let data = [] as any
    tableData?.forEach((i: any) =>{
      let temp = i as any
      temp.forEach((item: any,index: number) => {
        item.readOnly = checkIsDisabledForm()
      });
      data.push(temp)
    })||[]
    return <ReactDataSheet
    className={clsx("table-manual w-100",{})}
    data={data}
    valueRenderer={(cell:any) => cell.value}
    onCellsChanged={changes => {
    const grid = tableData.map((row:any) => [...row]) as any
    // console.log('tableData',tableData)
    let rowChange = null as any
    let classError = 'form-error'

    setIsHasChange(true)
    changes.forEach(({ cell, row, col, value }) => {
      let className = grid[row][col].className||''
        if(col == 1) {
          value = (isNaN(value as any))? '' : formatCurrent(Number(value))
          if(value == '0') {
            value = ''
          }
        }
        rowChange = row

          // console.log('row',row)//&& row != grid.length-1
        if(!value) {
          className = className.replace(classError,'')+' '+ classError
        }
        else {
          className = className.replace(classError,'')
        }
        grid[row][col] = { ...grid[row][col], value, className };

    });
    // console.log('rowChange',rowChange)
    // setTableData([...tableData, grid)
    let lastRow = grid.length - 1
    // console.log('lastRow',lastRow)

    if(rowChange == lastRow) {
      autoFillData('new-record',lastRow,grid)
    }
    else {
      setTableData([...grid])
    }


    }}
    sheetRenderer={(props:any) => (
        <table className={clsx(props.className + '',{'select-more':isShowMore})}>
            <thead className="bg-light">
                <tr>
                    {header.map((cell:any, index:number) => (
                        <th rowSpan={cell.rowSpan} colSpan={cell.colSpan}
                        key={'header' + index} className={clsx(cell.class,{'wrap-checkinvoice':cell.type == 'check'})} >
                          {cell.type == 'check' ?
                            <div className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input m-auto" type="checkbox" checked={isCheckAll} value='all' onChange={handleSelectAll} id='checkAll' />
                            </div>
                            :
                            <span>{cell.title}</span> 
                          }
                            
                        </th>

                    )
                    )}
                </tr>
            </thead>
            <tbody>
                {props.children}
            </tbody>
        </table>
      )}
      rowRenderer={(props:any) => {
        let grid = tableData.filter((i:any)=>!i[0].isDeleted)
        let number = grid.findIndex((i:any)=>i[0].index == tableData[props.row][0].index).toString()
        const item = tableData[props.row]|| {}
       
        let index = props.row.toString()
        
        return <tr className={clsx({'d-none': item[0].isDeleted})}>
           
            <td className='py-3'>
              {
                (item[0].value != '') && <div className='text-center'>{+number+1}</div>
              }
            </td>
           
            {props.children}
            
        </tr>
      }}
/>
  }
  const checkFile = (item: any) => {
    let check = false
    let arrFiles = listFiles.map((item:any)=> item.typeId)
    if(item.isrequired && !arrFiles.includes(item.id)) {
      check = true
    }
    return check
  }
  const checkErrorFormFiles = () => {
    let tempYup = {} as any
      dataRequiredDocument?.forEach((item:any,index: number)=> {
        if(checkFile(item)) {
          let temp = {
            [item.id]: yup.array().min(1).max(item.numberoffile).required("At least a file is required")
            .test('is-correct-file', 'VALIDATION_FIELD_FILE_BIG', (value:any) => {
              return value && checkIfFilesAreTooBig(value,item.maxfilesize)
          })
          }
          tempYup = Object.assign(tempYup,temp)
        }
      })
      setYupSchemaFile(yup.object().shape(tempYup))
  }
  useEffect(()=>{
    if(dataRequiredDocument) {
      setRequiredDocument(dataRequiredDocument)
      checkErrorFormFiles()
    }
    
  },[dataRequiredDocument])
  const { register:registerFile, control:controlFile, resetField, reset: resetFile, handleSubmit: handleSubmitFile,getValues: getValuesFile, setValue: setValueFile, watch: watchFile, formState: { errors: errorsFile } } = useForm({ resolver: yupResolver(yupSchemaFile) })
 
  const { register, control, reset, handleSubmit,getValues, setValue, clearErrors, watch, setError, formState: { errors, isSubmitting } } = useForm({ resolver: yupResolver(yupSchema) })

  
  const [tableData, setTableData] = useState<any>([])
  // const [selectCourse, setSelectCourse] = useState<Array<ISubject>>([])
  const [pageOption, setPageOption] = useState({ page: 0, limit: 50 })
  const [selected, setSelected] = useState<Array<string>>([])
  const [isCheck, setIsCheck] = useState<Array<any>>([])
  const [isCheckAll, setIsCheckAll] = useState(false);

  const [keys, setKeys] = useState<Array<Array<any>>>([])
  const [filters, setFilters] = useState<Array<string>>([])
  const [searchData, setSearchData] = useState<any[]>([])
  // const { data: dataVendors } = apiPrivate.useVendorsByQuery('?expand=vendorbankaccounts')

  // useEffect(() => {
  //   if(dataVendors) {
  //     setOptionsVenders(
  //       dataVendors.map((e: any) => ({...e, title: e.number, description: [e.number, e.vendorname], }))
  //       )
  //   }
  // }, [dataVendors])
  const getInfoBank = () => {
    let arr = arrBank()
    // console.log('arr',arr)
      if(arr.length != 0 && watch('paymentmethodcode') == "284210001") {
          setValue('bankno',arr[0].code)
          setValue('bankname',arr[0].bankname)

          setValue('bankbranchno',arr[0].bankbranchno)
          setValue('bankbranchname',arr[0].bankbranchname)

          setValue('bankaccountno',arr[0].bankaccountno)
          setValue('bankaccountname',arr[0].bankaccountname?arr[0].bankaccountname:watch('sourcename'))
      }
      else {
        setValue('bankno',null)
        setValue('bankname',null)

        setValue('bankbranchno',null)
        setValue('bankbranchname',null)
        
        setValue('bankaccountno',null)
        setValue('bankaccountname',null)

      }
  }
  const arrBank = () => {
    let arr = [] as any
    let record = renderOptionObjectCodeVal().find((i:any)=>i.number && i.number == watch('sourceno'))|| {}
    arr = record.VendorBankAccounts

    if(watch('documenttype') == '284210002') {
      record = renderOptionObjectCodeVal().find((i:any)=>i.customerno && i.customerno == watch('sourceno'))|| {}
      arr = record.CustomerBankAccounts
    }
    // console.log('arr',arr)
    if(arr) {
      arr = arr.map((e: any) => ({...e,label: e.code,value: e.code, title: e.bankname, description: [e.code, e.bankname], }))
      arr = sortWithDate(arr,{sort:'createdat', order: 'desc'})
    }
    
    return arr||[]
  }
  function handleSearch(keys: Array<Array<any>>, data: any[], filters: string[]) {
      setKeys(keys)
      setSearchData(data)
      setFilters(filters)
  }
  const handleSelectAll = (e:any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(tableData.map((item:any,index:number) => index.toString()));
    if (isCheckAll) {
      setIsCheck([]);
      setIsShowMore(false)
    }
};

let columns = [{
  Header: (props: any) => <CustomHeader tableProps={props} title='#' className='w-30px' />,
  id: 'index',
  Cell: ({ ...props }) => <BasicCell className='' data={props.row.index+1} />
}, {
  Header: (props: any) => <CustomHeader tableProps={props} title='text.request.name' className='' />,
  id: 'fileName',
  Cell: ({ ...props }) => <div>
    <a href={props.data[props.row.index].url} target="_blank" rel="noopener noreferrer">{props.data[props.row.index].fileName}</a>
  </div>,
},
{
  Header: (props: any) => <CustomHeader tableProps={props} title='text.document-type' className='' />,
  id: 'typeName',
  Cell: ({ ...props }) => <BasicCell className='' data={props.data[props.row.index].typeName} />,
},
{
  Header: (props: any) => <CustomHeader tableProps={props} title='text.size' className='' />,
  id: 'size',
  Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].size} />,
},
{
  Header: (props: any) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
  id: 'actions',
  Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
      {/* <RowIcon action="modify" permission={permission} to={props.data[props.row.index].id} /> */}
      {
        <RowIcon action="delete" className={clsx({'disabled opacity-75': checkIsDisabledForm()})} onClick={async () => {
          if(checkIsDisabledForm()) return
          // const id = props.data[props.row.index].url
          const pathtofile = props.data[props.row.index].pathtofile
        if(!pathtofile) {
          setListFiles((pre:any) => pre.filter((f:any,index: number) => index != props.row.index))
        }
        else {
          let tempArr = JSON.parse(JSON.stringify(listFiles))
          let filesDeleteTemp = JSON.parse(JSON.stringify(filesDelete))
          // tempArr[props.row.index].isDelete = true
          // setListFiles(tempArr)
          let pathtofile = tempArr[props.row.index].pathtofile
          filesDeleteTemp.push(pathtofile)
          setFilesDelete(filesDeleteTemp)
          setListFiles((pre:any) => pre.filter((f:any) => f.pathtofile != pathtofile))

        }
          
      }} />
      }
      
  </div>,
},
]
  const header = [

{
  title: '#',
  class: 'text-center w-30px',
},
 {
    title: intl.formatMessage({ id: `text.explain-content` as any }),
    class: ' fw-bold',
}, {
    title: intl.formatMessage({ id: `text.amount-of-advance-request` as any }),
    class: 'w-200px fw-bold text-center',
}, 
]
  
  const renderDetails = (data?:any) => {
    // console.log('invoicedetails',data)
    let defaultRow = [
      [ { value: '' },{ value: '', className: 'text-center',}],
    ]
    if(data) {
      let tempData = [] as any
      
      // let discount = dataInvoice.discountamount/(invoicedetails.filter((item:any)=>item.ses_producttype == 284210000).length)
      
      data.forEach((item:any,index:number) => {
        // console.log('item',item)
        let temp = [
          {
            value: item.description,
            id: item.id,
            documentno: item.documentno,
            index: index,
            lineno: item.lineno,
            readOnly: checkIsDisabledForm(),
          },
          {
            value: formatCurrent(item.amount),
            className: 'text-center',
            readOnly: checkIsDisabledForm(),
          },
        
        ]
        tempData.push(temp)
      });
      // console.log('tempData',tempData)
      if(watch('status') == "284210001" || watch('status') == "284210002" ) {
        setTableData(tempData)
      }
      else {
        setTableData(tempData.concat(defaultRow))
      }
    }
    else {
      setTableData(defaultRow)
    }
  }
  const getData = (data: any) => {
    
    setDataRequest(data)
    let dataReset = data||
    {
      "statuscode": 1,
      "documentno": null,
      "documenttype": "284210000",
      "sourceno": null,
      "sourcename": null,
      "departmentid": null,
      "departmentcode": null,
      "departmentname": null,
      "jobtitle": null,
      "requireddate": null,
      "description": null,
      "totalamount": null,
      "status": "284210000",
      "noofprint": null,
      "bankno": null,
      "bankname": null,
      "bankbranch": null,
      "bankbranchno": null,
      "bankaccountno": null,
      "beneficiaryname": null,
      "requireddocuments": null,
      "createdat": [new Date()],
      "creater": user?.username,
      "remainingadvancedamount": null,
      "paymentmethodcode": 284210000,
      "advancepaymentperiod": null,
      "open": true,

  } as any
  // console.log('dataReset',dataReset.requireddate)
  reset(dataReset)
  // setValue('requireddate',[new Date(data.requireddate)])

  if(dataReset?.documenttype) {
    if(dataReset.documenttype == '284210002') {
      getOptionCustomers(zustandStore)
    }
    else {
      getOptionVenders(dataReset.documenttype,zustandStore)
    }
  }
  const {EmployeeAdvanceRequestLines} = data||[]
  renderDetails(EmployeeAdvanceRequestLines)
  setListFiles(data?.requireddocuments||[])
  }
  const checkTableError = (table: any) => {
    let check = false
    let classError = 'form-error'
    table.forEach((item:any,ind:number)=> {
      if(ind == table.length - 1 && table.length>1 && (!item[0].value && !item[1].value)) {
        return
      }
      item.forEach((i:any,index: number)=> {
        if(i.value == "") {
          i.className = i.className?.replace(classError,'')+' '+ classError
          check = true
        }
      })
      if(!item[0].value && !item[1].value && table.filter((i:any)=>i[0].value && i[1].value).length > 0 ) {
        check = false
        item[0].className = item[0].className?.replace(classError,'')
        item[1].className = item[1].className?.replace(classError,'')

      }
    })
    // console.log('table',table)
    if(check) {
      popupMessage({icon: "error", description: intl.formatMessage({ id: `text.please-check-form-detail`}) })
    }
    setTableData(table)
    return check
  }
  const uploadFileSubmit = async() => {
  try {
    // console.log('listFiles',listFiles)

    let files = listFiles.filter((item:any)=>!item.url).map((item:any)=>item.file)
    let filesLocal = listFiles.filter((item:any)=>item.url)
    // console.log('filesLocal',filesLocal)
    // console.log('files',files)
    if(!files || files.length == 0) return filesLocal
    let userid = user?.id as any
    let dataFile = await ApiUploadFiles(userid,'advance-request',files)
    // console.log('dataFile',dataFile)
    let tempData = [] as any
    dataFile?.forEach((item:any)=> {
      let requiredDocumentRecord = listFiles.find((i:any)=>i.fileName == item.fileName )||{}
      // console.log('item',item)
      // console.log('requiredDocumentRecord',requiredDocumentRecord)
      let temp = {
        fileName: item.fileName,
        size: item.fileSize,
        typeId: requiredDocumentRecord.typeId,
        typeName: requiredDocumentRecord?.typeName,
        url: item.url,
        pathtofile: item.pathtofile
      }
      tempData.push(temp)
    })
    // console.log('tempData',tempData)
    setListFiles(filesLocal.concat(tempData))
    // setListFiles(tempData)
    return  filesLocal.concat(tempData)//tempData
  } 
  catch (error) { ApiShowError(error) }
  }
  const checkErrorFilesSubmit = () => {
   let isErrors = false
   requiredDocument.forEach((item: any) => {
    if(isErrors) return
    isErrors = checkFile(item)
   })
   if(isErrors) {
    popupMessage({icon: "error", description: intl.formatMessage({ id: `text.please-attach-Files-as-requested` as any }) })
   }
   return isErrors
  }
  const onSubmit = async(record: any) => {
    // console.log('documentno',record)
    let checkTable = checkTableError(JSON.parse(JSON.stringify(tableData)).map((row:any) => [...row]))
    if(checkTable || checkErrorFilesSubmit()) {
      return
    }
    
    let EmployeeAdvanceRequestLines = [] as any
    let arrDelete = {
      ids: tableData.filter((item:any)=>!item[0].value && item[0].id).map((i:any)=>i[0].id)
    } as any
    tableData.filter((i:any)=>i[0]?.value && i[1]?.value).forEach((item:any,index:number)=>{
      let temp = {
        "id": item[0].id,
        "employeeadvancerequestid": valueid,
        "documentno": record.documentno,
        "lineno": null,
        "description": item[0].value,
        "amount": reFormatCurrent(item[1].value),
        "statuscode": 1,

      }  
      EmployeeAdvanceRequestLines.push(temp)
    })
    
    let tempData = JSON.parse(JSON.stringify(record))
    tempData.id = valueid||idRequest
    tempData.code = tempData.code||null
    tempData.requireddate = record.requireddate && new Date(record.requireddate[0])
    tempData.advancepaymentperiod = record.advancepaymentperiod && new Date(record.advancepaymentperiod[0])
    
    tempData.createdat = undefined
    tempData.creater = undefined
    tempData.issubtractsalary = record.issubtractsalary == 1 ? true : false
    
    tempData.statuscode = 1
    tempData.totalamount = reFormatCurrent(totallineitemAmount())
    tempData.remainingadvancedamount = tempData.totalamount
    tempData.EmployeeAdvanceRequestLines = EmployeeAdvanceRequestLines
    if (!arrObject) return popupMessage({ icon: 'error' })
    if (!arrObject?.nosericode && !record.documentno) return setError(`documentno`, { type: 'validate' }, { shouldFocus: true })
    let nosericode = arrObject?.nosericode && !isEdit ? `/?nosericode=${arrObject?.nosericode}` : ''
    let chooseTypeSubmit = {} as any
    if(isHasChange && !idRequest) {
      chooseTypeSubmit = EmployeeAdvanceRequestLines.length == 0 ? {} 
      : 
      await popupCustomQuestion(
      { description: intl.formatMessage({ id: `text.please-quickly-select-an-action`}),
        confirmButtonText: intl.formatMessage({ id: `text.save-and-create`}),
        denyButtonText: intl.formatMessage({ id: `text.save-and-send-request`}),
        showDenyButton: !isEditNotApproval && true, 
        denyButton:"btn-success"}
      )
      if(chooseTypeSubmit.isDismissed == true) {
        return
      }
    }
    let requireddocuments = await uploadFileSubmit()
    tempData.requireddocuments = requireddocuments ? requireddocuments.filter((i:any)=> !i.isDelete) : undefined
    try {
      if(arrDelete.ids.length!=0) {
        await ApiService.post(`employees/employeeadvancerequestlines/deletemulti`, arrDelete)
      }
      if(filesDelete.length!=0) {
        await ApiService.post(`utilities/deletemultifiles`, {pathtofiles: filesDelete})
        setFilesDelete([])
      }
      const method = 'post'//isEdit ? 'put' : 'post'
      setIsHasChange(false)
      if(!idRequest) {
        let otherinfo = {
          deptid: user?.departmentid,
          deptcode: user?.departmentcode,
          deptname: user?.departmentname,
        }
        tempData.otherinfo = otherinfo
      }
      let {data} = await ApiService[method](`employees/employeeadvancerequests/upsertwithdetails${nosericode}`, tempData)
      data.requireddate = data.requireddate && [new Date(data.requireddate)]
      data.advancepaymentperiod = data.advancepaymentperiod && [new Date(data.advancepaymentperiod)]
      getData(data)
      setidRequest(data.id)
      setAutoApprovalRequest(chooseTypeSubmit.isDenied)
      await dataSetupApproval()
      if(chooseTypeSubmit.isDenied) {
        // setTimeout(()=> {
        //   if(approvalLevels)
        //     refApprovalRequest?.current.click()
        // },500)
      }
      else {
        popupMessage({ icon: 'success', autoClose: true })
      }
    } catch (error) { ApiShowError(error) }
  }
  const [isShowFiles, setIsShowFile] = useState<boolean>(false)
  const handleCloseModalFile = () => {
    setIsShowFile(false)
  }
  const handleAddModalFile = () => {
    setIsShowFile(true)
    setTimeout(()=>{
      requiredDocument?.forEach((item:any)=> {
        setValueFile(item.id,returnFileValues(item.id))
        checkErrorFormFiles()
      })
    },100)
  }
  const onSubmitFile = async(record: any) => {
    // console.log('record',record)
    let tempData = [] as any
    let tempDelete = [] as any
    for (const [key, value] of Object.entries(record)) {
      // console.log(`${key}: ${value}`);
      let values = [] as any
      if(value){
        values = Object.values(value as any) as any
      }
      // console.log('values',values)
    values?.forEach((item:any)=> {
      let tempPre = item.fileName.split('.')
      let temp = {
        fileName: [removeAccents(tempPre[0]),tempPre[1]].filter((f: any) => f).join('.'),
          size: item.size && !isNaN(item.size) ? formatBytes(item.size): item.size,
          typeId: key,
          typeName: requiredDocument.find((item:any)=> item.id == key)?.description,
          file: item,
          url: item.url? item.url:null,
          pathtofile: item.pathtofile? item.pathtofile: null
        }
        if(item.state == 'remove') {
          if(item.pathtofile) {
            tempDelete.push(item.pathtofile)
          }
          return
        }
        else {
          tempData.push(temp)
        }
      })
      
    }
    // console.log('tempDataa',tempData)
    let dFiles = JSON.parse(JSON.stringify(filesDelete)).concat(tempDelete)
    setFilesDelete(unique_arr(dFiles))
    // setListFiles(listFiles.concat(tempData))
    setListFiles(tempData)
    // setFiles(record)
    handleCloseModalFile()
  }
 

  const [isShowMore,setIsShowMore] = useState(false)

  const autoFillData = (value:any,index:any,gridTable?:any) => {
    // let tempData = optionsName?.find((item:any)=>item.value == value)
      let grid = JSON.parse(JSON.stringify(tableData)).map((row:any) => [...row]) as any
      if(gridTable) {
        grid = JSON.parse(JSON.stringify(gridTable)).map((row:any) => [...row]) as any
      }
      let rowDefault:any = [ 
        { value: '',index: index }, 
        { value: '',className: 'text-center', }, 
      ]
          
      let rowEdit = JSON.parse(JSON.stringify(rowDefault))
      // if(value) {
      //   rowEdit = [ 
      //     { value: value }, 
      //  ]
      // }

      // grid[index] = rowEdit
      // setTableData([...grid])
      // console.log('grid',grid)
      // console.log('value',value)
      // console.log('index',index)
      // console.log('grid.length',grid.length)
      if(grid.length-1 == index && value!= '') { // add new row
        grid.push(rowDefault)
      }
      // console.log('grid',grid)

      setTableData([...grid])


  }
  const totalAmountIncludeVAT = (item:any) => {
    let total = (+item[0].value * +reFormatCurrent(item[2].value))
    // let discount = +reFormatCurrent(item[3].value)
    return formatCurrent(total)
  }
  const clearRowTable = (row:any) => {
    setValue('no_invoice_'+row,'')
    autoFillData('',row)

}
  const schemaChild = yup.object({
    name: yup.string().required(),
    ses_course: yup.string().required(),
    nameen: yup.string().required(),
    // ses_weighting: yup.number().required(),
}).required();
  const { register:registerChild, reset:resetChild,setValue:setValueChild,resetField: resetFieldChild,setError:setErrorChild, handleSubmit:handleSubmitChild, control:controlChild, watch:watchChild, formState: { errors:errorsChild } } = useForm<any>({ resolver: yupResolver(schemaChild) })
  const [isShow, setIsShow] = useState<boolean>(false)
  const handleCloseModal = () => {
    setIsShow(false)
  }

  const [files, setFiles] = useState<any>([]);
    const handleOnChange = (e:any) => {
        let temp = [] as any
        for(let i = 0;i<e.target.files.length;i++) {
            let file = {
                fileName:e.target.files[i].name,
                url: '#',
            }
            temp.push(file)
        }
        
        setFiles(temp);
    };
    const removeFile = (record:any,index:any) => {
      // console.log('record',record)
      let tempData = {
          pathtofile: record.pathtofile
      }
      // ApiService.post(`utility/delete/file`,tempData)
      // .then(({ data }) => {
      //     let filesTemp = [...files]
      //     filesTemp.splice(index,1)
      //     // setFiles(filesTemp)
      //     let isEdit = true
      //     let tempData = {supportingdocuments: filesTemp}
      // })
      // .catch(({ response }) => {
      // console.log("err", response);
      // });
  }
  const totallineitemAmount = () => {
    let total = tableData.filter((item:any)=> item[0].value != '' && item[1].value).reduce(function (acc:any, obj:any) { return acc + +reFormatCurrent(obj[1]?.value) }, 0)
    total = Number(total.toFixed(0))
    return formatCurrent(total)
  }
  
  const [filesDelete,setFilesDelete] = useState<any>([])
  const toolbar = <>
    <KTButton action="back" onClick={() => navigate(-1)}/>
  </>
  const returnFileValues = (typeId:string) => {
    return listFiles.filter((i:any)=> i.typeId == typeId && i.state != 'remove').map((i:any)=>({...i,fileSize: i.size}))
  }
  const renderOptionObjectCodeVal = () => {
    let options = []
    if(watch('documenttype') == "284210000") {
      options = optionsVenders
    }
    if(watch('documenttype') == "284210001") {
      options = optionsEmployees
    }
    if(watch('documenttype') == "284210002") {
      options = optionsCustomers
    }
    return options
  }
  
  return <>
    <ContentHeader title={intl.formatMessage({ id: `text.advance-details` as any })} items={[{ title: intl.formatMessage({ id: `text.list-of-advance` as any }), path: '/requests/advance' }]}  elements={toolbar}/>
    <div className='card'>
      <div className='card-header border-0 pt-6 mb-0 min-h-10px'>
        <div className='card-title'>
            <h5>{intl.formatMessage({ id: `text.general-information` as any })}</h5>
        </div>
        <div className='card-toolbar gap-2'>
          {valueid &&
          
            <TextFormByUser data={watch()} />
          }
            {
              (approvalLevels || isEditNotApproval) && 
              <ActionApprovals 
                requestUser={requestUser}
                approvalUser={approvalUser}
                approvalhistories={approvalhistories}
                approvalLevels={approvalLevels}
                refApprovalRequest= {refApprovalRequest}
                totalamount={reFormatCurrent(totallineitemAmount())||+dataRequest?.totalamount}
                refetchApproval={refetchApproval}
                setValue={setValue}
                watch={watch}
                valueid={idRequest}
                isHasChange={isHasChange}
                refSaveBtn={refSaveBtn}
                tableData={tableData}
                emailnotification={emailnotification}
                dataQuery={dataQuery}
                urlApi={
                  {
                    settings: 'settings',
                    typeRequest: 'employees/employeeadvancerequests',
                    sendMail: 'utilities/sendmail/withcustomtemplate',
                    printPdf: 'print-advance',
                    request: 'advance',
                  }
                }
                />
          }
          
        </div>
      </div>
    </div>
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className='card mb-6'>
          <div className="row card-body pt-3">
            <div className="col-md-6 col-12">
              <KTFormItem title='text.document-code' isRequired={!(arrObject?.nosericode && !idRequest)}>
                <input readOnly={(arrObject?.nosericode && !idRequest) || (checkIsDisabledForm()) } className={clsx('form-control form-control-sm', {'form-control-solid': ((arrObject?.nosericode && !idRequest) || checkIsDisabledForm()) , 'form-error': errors.documentno })} {...register('documentno')} />
              </KTFormItem>
              <KTFormItem title='text.advance-object'>
                <Controller
                  name='documenttype'
                  control={control}
                  render={({ field }) => <InputSelect
                    disabled={checkIsDisabledForm()}
                    className={clsx('form-control form-control-sm w-100 select-custom',{ 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.documenttype })}
                    options={Object.values(LocalData.venderType).map((i: any) =>({
                      label: i['label'+selectedLang],
                      value: i.value
                    }))}
                    value={Object.values(LocalData.venderType)?.find((e: any) => e.value == field.value)}
                    onChange={e => {
                      if(e.value != watch('documenttype')) {
                        field.onChange(e.value)
                        if(e.value == "284210001" && !advancerequestforsomeone) {
                          setValue('sourceno',user?.vendorcode)
                          setValue('sourceid',user?.vendorid)
                          setValue('sourcename',user?.vendorname)
                          setValue('departmentname',user?.departmentname)
                          setValue('departmentid',user?.departmentid)
                          setValue('departmentcode',user?.departmentcode)
                        }
                        else {
                          setValue('sourceno',null)
                          setValue('sourceid',null)
                          setValue('sourcename',null)
                          setValue('departmentname',null)
                          setValue('departmentid',null)
                          setValue('departmentcode',null)
                        }
                        if(e.value == "284210001" ) {
                          // let schema1 = Object.assign(schema,{departmentid: yup.string()
                          //   .required('Input is required')})
                          // setYupSchema(yup.object().shape(schema1))
                          if(optionsEmployees.length == 0) {
                            getOptionVenders(e.value,zustandStore)
                          }
                        }
                        else {
                          setYupSchema(yup.object().shape(schema))
                          if(e.value == "284210000") {
                            if(optionsVenders.length == 0) {
                              getOptionVenders(e.value,zustandStore)
                            }
                          }
                          else {
                            if(optionsCustomers.length == 0) {
                              getOptionCustomers(zustandStore)
                            }
                          }
                        }
                        setValue('paymentmethodcode',284210000)
                        getInfoBank()
                      }
                    }}
                  />} />
              </KTFormItem>
              <KTFormItem title='text.advance-objectcode' isRequired>
                {
                  watch('documenttype') != "284210002" ? 
                  // <input
                  // readOnly
                  // disabled={checkIsDisabledForm()}
                  // className={clsx('form-control form-control-sm', {'form-control-solid': checkIsDisabledForm() || (watch('documenttype') == "284210001" && !advancerequestforsomeone), 'form-error': errors.sourceno })} {...register('sourceno')}
                  // onChange={(e:any) => {
                  //   }}
                  //   onClick={() => checkIsDisabledForm() || (watch('documenttype') == "284210001" && !advancerequestforsomeone)? {} : handleSearch(
                  //     [
                  //         ['sourceno','number'],
                  //         ['sourceid','id'],
                  //         ['sourcename','vendorname'],
                  //         ['departmentname','departmentname'],
                  //         ['departmentid','departmentid'],
                  //         ['departmentcode','departmentcode'],
                  //     ],
                  //     optionsVenders.filter((item:any)=> watch('documenttype') == "284210001"? item.vendortype == 'employee' : item.vendortype == 'vendor'),
                  //     ['title', 'description'])} /> 
                  <Controller
                    name={'sourceno'}
                    control={control}
                    render={({ field }) => <InputSelect formatOptionLabel={templateCell}
                      options={renderOptionObjectCodeVal()
                                .map((i: any) =>( {value: i.number,label: i.number+' / '+i.vendorname} ))}
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.departmentid })}
                      value={renderOptionObjectCodeVal()
                      .map((i: any) =>( {value: i.number,label: i.number} ))
                      .find((e: any) => e.value == field.value)|| ''}
                      onChange={async(e: any) => {
                        if (!e) return field.onChange(null)
                        field.onChange(e.value)
                        let a = renderOptionObjectCodeVal()
                        let record = a.find((i: any) => i.number == e.value)
                        if(record) {
                          // setValue('sourceno', record.number)
                          setValue('sourcename', record.vendorname)
                          setValue('departmentname', record.departmentname)
                          setValue('departmentid', record.departmentid)
                          setValue('departmentcode', record.departmentcode)
                          setValue('sourcetitle', record.jobtitle)
                          if(watch('documenttype') == '284210001') {
                            let data = await getInforUser(record.id)
                            // console.log('record',record)
                            if(data && data.length != 0) {
                              let record = data[0]
                              setValue('sourcetitle', record.jobtitle)
                              setValue('departmentname', record.departmentname)
                              setValue('departmentid', record.departmentid)
                              setValue('departmentcode', record.departmentcode)
                            }
                            
                          }
                        }
                        getInfoBank()
                        // getAdvances(watch('sourceno'))
                      }
                    }
                    />} />
                      :
                  // <input
                  // readOnly
                  // disabled={checkIsDisabledForm()}
                  // className={clsx('form-control form-control-sm', {'form-control-solid': checkIsDisabledForm() || (watch('documenttype') == "284210001" && !advancerequestforsomeone), 'form-error': errors.sourceno })} {...register('sourceno')}
                  // onChange={(e:any) => {
                  //   }}
                  //   onClick={() => checkIsDisabledForm() ? {} : handleSearch(
                  //     [
                  //         ['sourceno','customerno'],
                  //         ['sourceid','id'],
                  //         ['sourcename','customername'],
                  //     ],
                  //     optionsCustomers,
                      
                  //     ['title', 'description'])} />
                      <Controller
                    name={'sourceno'}
                    control={control}
                    render={({ field }) => <InputSelect formatOptionLabel={templateCell}
                      options={optionsCustomers.map((i: any) =>( {value: i.customerno,label: i.customerno+' / '+i.customername} ))}
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.departmentid })}
                      value={optionsCustomers.map((i: any) =>( {value: i.customerno,label: i.customerno} ))?.find((e: any) => e.value == field.value)|| ''}
                        onChange={e => {
                        if (!e) return field.onChange(null)
                        field.onChange(e.value)
                        let record = optionsCustomers.find((i: any) => i.customerno == e.value)
                        if(record) {
                          setValue('sourcename', record.customername)
                        }
                        getInfoBank()
                        // getAdvances(watch('sourceno'))
                      }
                    }
                    />} />
                }

              </KTFormItem>
              {/* <KTFormItem title='text.advance-objectcode' isRequired>
              <input
                  readOnly
                  className={clsx('form-control form-control-sm', { 'form-control-solid': checkIsDisabledForm() || (watch('documenttype') == "284210001" && !advancerequestforsomeone), 'form-error': errors.sourceno })} {...register('sourceno')}
                  onChange={(e:any) => {
                      // console.log('ádadasa')
                    }}
                    onClick={() => checkIsDisabledForm() || (watch('documenttype') == "284210001" && !advancerequestforsomeone)?{}:handleSearch(
                      [
                          ['sourceno','number'],
                          ['sourceid','id'],
                          ['sourcename','vendorname'],
                          ['departmentname','departmentname'],
                          ['departmentid','departmentid'],
                          ['departmentcode','departmentcode'],
                          ['sourcetitle','jobtitle']
                      ],
                      optionsVenders.filter((item:any)=> watch('documenttype') == "284210001"? item.vendortype == 'employee' : item.vendortype == 'vendor'),
                      ['title', 'description'])} />
              </KTFormItem> */}
              <KTFormItem title='text.advance-objectname'>
                  <input readOnly className={clsx('form-control form-control-solid form-control-sm', { 'form-error': errors.sourcename })} {...register('sourcename')} />
                </KTFormItem>
              {watch('documenttype') == "284210001" && 
              <>
                <KTFormItem title='text.job-title'>
                  <input readOnly={checkIsDisabledForm()} className={clsx('form-control form-control-sm', {'form-control-solid': checkIsDisabledForm(), 'form-error': errors.sourcetitle })} {...register('sourcetitle')} />
                </KTFormItem>
                <KTFormItem title='text.department-name'>
                  {/* <input readOnly className={clsx('form-control form-control-solid form-control-sm', { 'form-error': errors.departmentname })} {...register('departmentname')} /> */}

                <Controller
                  name={'departmentid'}
                  control={control}
                  render={({ field }) => <InputSelect
                    options={optionsDepts.map((i: any) =>( {value: i.id,label: i.departmentname} ))}
                    disabled={true}
                    className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.departmentid })}
                    value={optionsDepts.map((i: any) =>( {value: i.id,label: i.departmentname} ))?.find((e: any) => e.value == field.value)|| ''}
                      onChange={e => {
                      if (!e) return field.onChange(null)
                      field.onChange(e.value)
                      let record = optionsDepts.find((i: any) => i.value == e.value)
                      console.log('record',record)
                      if(record) {
                        setValue('departmentcode', record.code)
                        setValue('departmentname', record.departmentname)
                      }
                    }
                  }
                  />} />
              </KTFormItem>
              <KTFormItem title='text.department-code'>
                <input readOnly className={clsx('form-control form-control-solid form-control-sm', { 'form-error': errors.departmentcode })} {...register('departmentcode')} />
                {/* <Controller
                  name={'departmentid'}
                  control={control}
                  render={({ field }) => <InputSelect
                    options={optionsDepts}
                    value={optionsDepts?.find((e: any) => e.value == field.value)|| ''}
                    disabled={true}
                    className={clsx('form-control form-control-sm w-100 select-custom form-control-solid',{})}
                    onChange={e => {
                      if (!e) return field.onChange(null)
                      field.onChange(e.value)
                      let record = optionsDepts.find((i: any) => i.value == e.value)
                      if(record) {
                        setValue('departmentcode', record.departmentcode)
                        setValue('departmentname', record.departmentname)
                      }
                    }
                  }
                />} /> */}
              </KTFormItem>
                
              </>
              }
              
              <KTFormItem title='text.description' className='align-items-baseline mb-5' isRequired>
                <textarea readOnly={checkIsDisabledForm()} className={clsx('form-control form-control-sm resize-none', { 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.description })} {...register('description')} />
              </KTFormItem>
              <KTFormItem title='text.date-created'>
                <Controller
                  name='createdat'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                      <InputDate
                      disabled
                      className={clsx('form-control form-control-sm w-100 form-control-solid')}
                      value={value}
                      
                      onChange={onChange}
                      />
                  )}
                  />
              </KTFormItem>
              <KTFormItem title='text.creator'>
                <input readOnly className={clsx('form-control form-control-sm form-control-solid', { 'form-error': errors.creater })} {...register('creater')} />
              </KTFormItem>
              {
                <KTFormItem title='text.remaining-advance'>
               <Controller
                 name="remainingadvancedamount"
                 control={control}
                   render={({ field: { value, onChange } }) => <InputPrice
                     value={value}
                     readOnly
                     onChange={(e: any) => {
                       onChange(e)

                     }}
                     className={clsx('form-control form-control-sm form-control-solid',)} />}
                   />
             </KTFormItem>
              }
              {watch('documenttype') != "284210001" && 
              <>
              <KTFormItem title='text.salary-deduction'>
                <label className={clsx("form-check form-check-inline form-check-solid me-5",{'disabled': checkIsDisabledForm()})}>
                  <input readOnly={checkIsDisabledForm()} className="form-check-input" {...register('issubtractsalary', {
                    onChange: (e) => {
                      if(!e.target.checked) {
                        setValue('subtractsalaryamount', null)
                        setYupSchema(yup.object().shape(schema))
                        clearErrors('subtractsalaryamount')
                      }
                      else {
                        let schemaTemp = Object.assign(schema,{subtractsalaryamount: yup.string()
                          .required('Input is required')})
                          setYupSchema(yup.object().shape(schemaTemp))
                      }
                  }
                  })} type="checkbox" value="1" />
                </label>
              </KTFormItem>
              <KTFormItem title='text.maximum-deduction' isRequired={watch('issubtractsalary')}>
                <Controller
                    name="subtractsalaryamount"
                    control={control}
                    render={({ field: { value, onChange } }) => <InputPrice
                        readOnly={checkIsDisabledForm() || !watch('issubtractsalary')}
                        value={value}
                        onChange={onChange}
                        className={clsx('form-control-sm',{'form-control-solid': checkIsDisabledForm() || !watch('issubtractsalary'), 'form-error': errors.subtractsalaryamount})} />}
                />
              </KTFormItem>
              
              </>
              } 
            </div>
            <div className="col-md-6 col-12">
              
              {watch('documenttype') == "284210001" && 
              <>
              <KTFormItem title='text.salary-deduction' labelWidth='135'>
                <label className={clsx("form-check form-check-inline form-check-solid me-5",{'disabled': checkIsDisabledForm()})}>
                  <input readOnly={checkIsDisabledForm()} className="form-check-input" {...register('issubtractsalary', {
                    onChange: (e) => {
                      if(!e.target.checked) {
                        setValue('subtractsalaryamount', null)
                      }
                  }
                  })} type="checkbox" value="1" />
                </label>
              </KTFormItem>
              <KTFormItem title='text.maximum-deduction' labelWidth='135'>
                <Controller
                    name="subtractsalaryamount"
                    control={control}
                    render={({ field: { value, onChange } }) => <InputPrice
                        readOnly={checkIsDisabledForm() || !watch('issubtractsalary')}
                        value={value}
                        onChange={onChange}
                        className={clsx('form-control-sm',{'form-control-solid': checkIsDisabledForm() || !watch('issubtractsalary'), 'form-error': errors.subtractsalaryamount})} />}
                />
              </KTFormItem>
             
              </>
              }
              <KTFormItem title='text.expected-date' isRequired labelWidth='135'>
                <Controller
                  name='requireddate'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                      <InputDate
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100',{ 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.requireddate })}
                      value={value}
                      options={ {
                        // minDate: new Date().setHours(0,0,0),
                      }
                      }
                      onChange={(e) => {
                        onChange(e)
                        setValue('requireddate', [new Date(watch('requireddate')[0]?.setHours(1,0,0))])
                      }}
                      />
                  )}
                  />
              </KTFormItem>
              <KTFormItem title='text.completion-date' labelWidth='135'>
                <Controller
                  name='advancepaymentperiod'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                      <InputDate
                      disabled={checkIsDisabledForm()}
                      className={clsx('form-control form-control-sm w-100',{ 'form-control-solid': checkIsDisabledForm(), 'form-error': errors.advancepaymentperiod })}
                      value={value}
                      options={ {
                        minDate: new Date(watch('requireddate')).setHours(0,0,0),
                      }
                      }
                      onChange={(e) => {
                        onChange(e)
                        setValue('advancepaymentperiod', [new Date(watch('advancepaymentperiod')[0]?.setHours(1,0,0))])
                      }}
                      />
                  )}
                  />
              </KTFormItem>
              <KTFormItem title='text.method' isRequired labelWidth='135'>
                <Controller
                  name='paymentmethodcode'
                  control={control}
                  render={({ field }) => <InputSelect
                    className={clsx('form-control form-control-sm w-100 select-custom',{'form-control-solid': checkIsDisabledForm(), 'form-error': errors.paymentmethodcode })}
                    options={Object.values(LocalData.paymentMethod)}
                    disabled={checkIsDisabledForm()}
                    value={Object.values(LocalData.paymentMethod)?.find((e: any) => e.value == field.value)}
                    onChange={e => {
                      field.onChange(e.value)
                      if(watch('paymentmethodcode') == "284210000") {
                        setValue('bankname',null)
                        setValue('bankno',null)
                        setValue('bankaccountno',null)
                        setValue('bankaccountname',null)
                        setValue('bankbranchno',null)
                        setValue('bankbranchname',null)
                      }
                      else {
                        getInfoBank()
                      }
                    }}
                  />} />
              </KTFormItem>
              {  true &&
              <div className={clsx({'disabled opacity-75': watch('paymentmethodcode') != "284210001"})}>
                <h5 className='py-5'>{intl.formatMessage({ id: `text.bank-information` as any })}</h5>
                <KTFormItem title='text.bank-code' labelWidth='135'>
                  <Controller
                    name={'bankno'}
                    control={control}
                    render={({ field }) => <InputSelect
                      options={arrBank()}
                      className={clsx('form-control form-control-sm w-100 select-custom ',{ 'form-control-solid': checkIsDisabledForm() || watch('paymentmethodcode') == "284210000",'form-error': errors.bankno })}
                      disabled={checkIsDisabledForm()||watch('paymentmethodcode') == "284210000"}
                      value={arrBank()?.find((e: any) => e.value == field.value)||''}
                      onChange={e => {
                          // console.log('e',e)
                          field.onChange(e.value)
                          let record = arrBank().find((i:any)=>i.value == e.value)
                            setValue('bankname',record?record.bankname:null)

                            setValue('bankbranchno',record?record.bankbranchno:null)
                            setValue('bankaccountname',record.bankaccountname?record.bankaccountname:watch('sourcename'))


                            setValue('bankaccountno',record?record.bankaccountno:null)
                            setValue('bankaccountname',record?record.bankaccountname:null)
                          
                        }
                      }
                      defaultValue=""
                    />} />
                </KTFormItem>
                <KTFormItem title='text.bank-name' labelWidth='135'>
                  <input disabled={checkIsDisabledForm() || watch("paymentmethodcode") == "284210000"} className={clsx('form-control form-control-sm ', {'form-control-solid': checkIsDisabledForm() || watch("paymentmethodcode") == "284210000", 'form-error': errors.bankname  })} {...register('bankname')} />
                </KTFormItem>
                <KTFormItem title='text.bank-account-no' labelWidth='135'>
                  <input disabled={checkIsDisabledForm() || watch("paymentmethodcode") == "284210000"} className={clsx('form-control form-control-sm ', {'form-control-solid': checkIsDisabledForm() || watch("paymentmethodcode") == "284210000", 'form-error': errors.bankaccountno  })} {...register('bankaccountno')} />
                </KTFormItem>
                <KTFormItem title='text.bank-account-name' labelWidth='135'>
                  <input disabled={checkIsDisabledForm() || watch("paymentmethodcode") == "284210000"} className={clsx('form-control form-control-sm ', {'form-control-solid': checkIsDisabledForm() || watch("paymentmethodcode") == "284210000", 'form-error': errors.bankaccountname })} {...register('bankaccountname')} />
                </KTFormItem>
                </div>
              }
              
              {
                <KTFormItem title='text.status'>
                  <span className={clsx(`badge badge-${LocalData.statusData[convertStatus(watch('status'),approvalhistories,user?.id)]?.badge}`)}>
                    {LocalData.statusData[convertStatus(watch('status'),approvalhistories,user?.id)]?.label}
                  </span>
                </KTFormItem>
              }
            </div>
            
            {/* <div className="col-12 col-md-4">
              <KTFormItem title='Files đính kèm' className='mb-1'>
                <input className="form-control form-control-sm mb-2" type="file" multiple id="exampleFormControlTextarea" {...register('files', {
                  onChange: e=> {
                      handleOnChange(e)
                  }
              },)} />
              
              </KTFormItem>
            
              {files.length != 0 && 
                <KTFormItem title='' className='mb-1'>
                  <div className="">
                      {files && files.map((item:any,index:number)=> {
                          return <div key={index}>
                              <Link target={item.url != '#' ? '_blank':''} to={item.url} className="">{(index+1)+'. ' + item.fileName } </Link> 
                              
                          </div>
                      })}
                  </div>
                </KTFormItem>
              }
            </div> */}
          </div>
        </div>
        <div className="card">
          <div className='card-header border-0'>
            <div className='card-title'>
              <div
                className='flex-fill d-flex flex-row hover-scroll-overlay-x px-0 mb-3'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-width='auto'
                data-kt-scroll-offset='0'
              >
                {tabs.map((item, index) => {
                  return ( <span
                    key={index}
                    id={item.id}
                    onClick={() => setTab(item.id)}
                    className={clsx(
                      'p-3 py-5 fw-bold cursor-pointer fs-5',
                      tab != item.id && 'text-black',
                      tab == item.id && 'text-primary border-bottom border-2 border-primary',
                      (item.id == 'files' && requiredDocument?.length == 0) && 'd-none',
                      index == 0 && 'ps-0'
                    )}>
                    {item.name}
                  </span>)
                })}
              </div>
            </div>
            <div className='card-toolbar'>
              {/* <span className={clsx('btn btn-sm btn-primary')} onClick={handleClickAdd}>Thêm mới</span> */}
            </div>
          </div>
          <div className="card-body pt-0">
          
            {
              tab == 'info' && <>
              
              {templateTableCell()}
              <div className='col-12 mb-6 mt-5'>
                <div className='d-flex gap-5 flex-end'>
                  <span className='text-black fw-bold'>Tổng cộng</span>
                  <strong className='text-danger fs-5'>{totallineitemAmount()}</strong>
                </div>
              </div>
              </>
              
            }
            {
              tab == 'files' &&
              <div>
                <KTTable
                  pagination
                  columns={columns}
                  data={listFiles.filter((i:any)=>i.state != 'remove')}
                  title={<></>}
                  toolbar={<div className='custom-btn-add-file'>
                    {
                      !checkIsDisabledForm() &&
                      <KTButton action="new" onClick={handleAddModalFile} titleBtn={intl.formatMessage({ id: `text.attachments.add` as any }) }/>
                    }
                  </div>}
                />
              </div>
            }
          
          </div>
          
        </div>
        <div className="row mt-6">
          
          <div className="col-12 text-end">
            {!checkIsDisabledForm() &&
              <FormSubmit
              type='modify'
              isSubmitting={isSubmitting}
              permission={permission}
              refSaveBtn={refSaveBtn}
              handleClose={() => navigate(-1)} />
            }
            
          
          </div>
        </div>
      </div>
    </form>
    <Modal size="lg" id='kt_modal_files' title={intl.formatMessage({ id: `text.attachments.add` as any }) } show={isShowFiles} onHide={handleCloseModalFile} centered >
        <Modal.Header className='pb-3 '>
            <Modal.Title className="d-flex align-items-center">
              <h3>{intl.formatMessage({ id: `text.attachments` as any }) }</h3>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 rounded-bottom">
            <div className="position-relative">
            <form onSubmit={handleSubmitFile(onSubmitFile)}>
                <div className="row">
                    <div className="col-12 col-md-12">
                      {
                        requiredDocument?.map((item:any,index: number) => (
                        <DivRoot key={index}>
                          <KTFormItem isRequired={item.isrequired} title={index+1 +'. '+ item.description} className='align-items-baseline mb-5' labelWidth='200' isHeight>
                            <Controller
                              control={controlFile}
                              name={item.id}
                              render={({ field: { value, onChange } }) => <InputFiles
                                  maxSizes={item.maxfilesize}
                                  maxFiles={item.numberoffile}
                                  values={returnFileValues(item.id)}//{item.values}
                                  isErrors={errorsFile[item.id]}
                                  onChange={(data,value) => {
                                    // console.log('data',data)
                                    // console.log('temps',temp)
                                    let tempDelete = data.filter((i:any)=>i.state == 'remove').map((i:any)=>i.pathtofile)
                                    setFilesDelete(unique_arr(filesDelete.concat(tempDelete)))

                                    let temp = data.filter((i:any)=>i.state != 'remove')
                                    setValueFile(item.id,temp)
                                    onChange(temp)
                                    // setFiles(data)
                                    // console.log('data',data)
                                    // console.log('value',value)
                                    // handleOnChange(data)
                                  }}
                                

                              />}
                            />
                          </KTFormItem>
                        </DivRoot>
                        ))
                      }
                        
                    </div>
                </div>
                
                <div className='d-flex mt-8 gap-3 justify-content-end'>
                <span className='btn btn-secondary' data-bs-dismiss='modal' aria-label='Close' onClick={e =>setIsShowFile(false)}>Hủy bỏ</span>
                <button className='btn btn-primary'>Lưu</button>
                </div>
            </form>
            </div>
        </Modal.Body>
    </Modal>
    {historiesApproval(approvalhistories, intl)}
    <SearchPopup {...{
            keys,
            filters,
            searchData,
            // className: 'popup-in-popup',
            setValue,
            handleClose: function () { setKeys([]); setFilters([]) },
            selfSave: (data:any) => {
              // console.log('choose and close popup')
              for (const [key, value] of Object.entries(data)) {
                setValue(key,value)
                if(key == 'sourceno') {
                  getInfoBank()
                } 
                
              }
           
            },
        }} />
  </>
}

export default TemplateComponent

