import { useQuery } from "react-query";
import { ApiService } from "../../../theme/helpers/ApiService";
import { IInitField } from "./type";

type IAdAddress = 'countries' | 'provinces' | 'districts' | 'wards'

export const fetchAdSetings = (name: IAdAddress, id?: string) =>
    ApiService.get(`/settings/${name}${id ? `/${id}` : ''}`).then(res => res.data)

export const fetchAdSetingsQuery = (name: IAdAddress, query?: string) =>
    ApiService.get(`/settings/${name}${query || ''}`).then(res => res.data)

interface IOptionLabel {
    value: string
    label: string
}

export interface ICountry extends IInitField, IOptionLabel {
    code: string | null
    countryname: string | null
    isocode: string | null
    isonumbericcode: number | null
    administrativeunit: 'State' | 'Region' | 'Province'
}

export interface IProvince extends IInitField, IOptionLabel {
    code: string | null
    provincename: string | null
    zipcode: string | null
    countryid: string | null
    countrycode: string | null
    statename: string | null
    regionname: string | null
    timezonecode: string | null
}

export interface IDistrict extends IInitField, IOptionLabel {
    code: string | null
    districtname: string | null
    zipcode: string | null
    provinceid: string | null
    provincecode: string | null
}

export interface IWard extends IInitField, IOptionLabel {
    code: string | null
    wardname: string | null
    zipcode: string | null
    disctrictid: string | null
    districtcode: string | null
}

export const optionAddress = {
    useCountries() {
        return useQuery(['countries'], () => fetchAdSetings('countries'), { cacheTime: Infinity })
    },
    useCountriesByQuery(query?: string) {
        return useQuery(['countries', query], () => fetchAdSetingsQuery('countries', query), { enabled: !!query })
    },
    useProvinces() {
        return useQuery(['provinces'], () => fetchAdSetings('provinces'))
    },
    useProvincesByQuery(countrycode?: string | null) {
        return useQuery(['provinces', countrycode], () => fetchAdSetingsQuery('provinces', `?countrycode=${countrycode}`), { enabled: !!countrycode, cacheTime: Infinity })
    },
    useDistricts() {
        return useQuery(['districts'], () => fetchAdSetings('districts'))
    },
    useDistrictsByQuery(provincecode?: string | null) {
        return useQuery(['districts', provincecode], () => fetchAdSetingsQuery('districts', `?provincecode=${provincecode}`), { enabled: !!provincecode, cacheTime: Infinity })
    },
    useWards() {
        return useQuery(['wards'], () => fetchAdSetings('wards'))
    },
    useWardsByQuery(districtcode?: string | null) {
        return useQuery(['wards', districtcode], () => fetchAdSetingsQuery('wards', `?districtcode=${districtcode}`), { enabled: !!districtcode, cacheTime: Infinity })
    },
    countries(data: any[]) {
        return data.map((item: ICountry) => ({
            ...item,
            value: item.code,
            label: item.countryname
        }))
    },
    getTypeCountry(countries: any[], countrycode?: string | null) {
        const country = countries.find(f => f.code == countrycode)
        if (!country) return;
        const type: 'State' | 'Region' | 'Province' = country.administrativeunit
        return type
    },
    provinces(data: any[]) {
        return data.map((item: IProvince) => ({
            ...item,
            value: item.code,
            label: item.provincename
        }))
    },
    districts(data: any[]) {
        return data.map((item: IDistrict) => ({
            ...item,
            value: item.code,
            label: item.districtname
        }))
    },
    wards(data: any[]) {
        return data.map((item: IWard) => ({
            ...item,
            value: item.code,
            label: item.wardname
        }))
    },
    timeZone: [{
        "label": "(GMT-11:00) Midway Island",
        "value": "Pacific/Midway"
    }, {
        "label": "(GMT-11:00) Samoa",
        "value": "US/Samoa"
    }, {
        "label": "(GMT-10:00) Hawaii",
        "value": "US/Hawaii"
    }, {
        "label": "(GMT-09:00) Alaska",
        "value": "US/Alaska"
    }, {
        "label": "(GMT-08:00) Pacific Time (US & Canada)",
        "value": "US/Pacific"
    }, {
        "label": "(GMT-08:00) Tijuana",
        "value": "America/Tijuana"
    }, {
        "label": "(GMT-07:00) Arizona",
        "value": "US/Arizona"
    }, {
        "label": "(GMT-07:00) Mountain Time (US & Canada)",
        "value": "US/Mountain"
    }, {
        "label": "(GMT-07:00) Chihuahua",
        "value": "America/Chihuahua"
    }, {
        "label": "(GMT-07:00) Mazatlan",
        "value": "America/Mazatlan"
    }, {
        "label": "(GMT-06:00) Mexico City",
        "value": "America/Mexico_City"
    }, {
        "label": "(GMT-06:00) Monterrey",
        "value": "America/Monterrey"
    }, {
        "label": "(GMT-06:00) Saskatchewan",
        "value": "Canada/Saskatchewan"
    }, {
        "label": "(GMT-06:00) Central Time (US & Canada)",
        "value": "US/Central"
    }, {
        "label": "(GMT-05:00) Eastern Time (US & Canada)",
        "value": "US/Eastern"
    }, {
        "label": "(GMT-05:00) Indiana (East)",
        "value": "US/East-Indiana"
    }, {
        "label": "(GMT-05:00) Bogota",
        "value": "America/Bogota"
    }, {
        "label": "(GMT-05:00) Lima",
        "value": "America/Lima"
    }, {
        "label": "(GMT-04:30) Caracas",
        "value": "America/Caracas"
    }, {
        "label": "(GMT-04:00) Atlantic Time (Canada)",
        "value": "Canada/Atlantic"
    }, {
        "label": "(GMT-04:00) La Paz",
        "value": "America/La_Paz"
    }, {
        "label": "(GMT-04:00) Santiago",
        "value": "America/Santiago"
    }, {
        "label": "(GMT-03:30) Newfoundland",
        "value": "Canada/Newfoundland"
    }, {
        "label": "(GMT-03:00) Buenos Aires",
        "value": "America/Buenos_Aires"
    }, {
        "label": "(GMT-03:00) Greenland",
        "value": "Greenland"
    }, {
        "label": "(GMT-02:00) Stanley",
        "value": "Atlantic/Stanley"
    }, {
        "label": "(GMT-01:00) Azores",
        "value": "Atlantic/Azores"
    }, {
        "label": "(GMT-01:00) Cape Verde Is.",
        "value": "Atlantic/Cape_Verde"
    }, {
        "label": "(GMT) Casablanca",
        "value": "Africa/Casablanca"
    }, {
        "label": "(GMT) Dublin",
        "value": "Europe/Dublin"
    }, {
        "label": "(GMT) Lisbon",
        "value": "Europe/Lisbon"
    }, {
        "label": "(GMT) London",
        "value": "Europe/London"
    }, {
        "label": "(GMT) Monrovia",
        "value": "Africa/Monrovia"
    }, {
        "label": "(GMT+01:00) Amsterdam",
        "value": "Europe/Amsterdam"
    }, {
        "label": "(GMT+01:00) Belgrade",
        "value": "Europe/Belgrade"
    }, {
        "label": "(GMT+01:00) Berlin",
        "value": "Europe/Berlin"
    }, {
        "label": "(GMT+01:00) Bratislava",
        "value": "Europe/Bratislava"
    }, {
        "label": "(GMT+01:00) Brussels",
        "value": "Europe/Brussels"
    }, {
        "label": "(GMT+01:00) Budapest",
        "value": "Europe/Budapest"
    }, {
        "label": "(GMT+01:00) Copenhagen",
        "value": "Europe/Copenhagen"
    }, {
        "label": "(GMT+01:00) Ljubljana",
        "value": "Europe/Ljubljana"
    }, {
        "label": "(GMT+01:00) Madrid",
        "value": "Europe/Madrid"
    }, {
        "label": "(GMT+01:00) Paris",
        "value": "Europe/Paris"
    }, {
        "label": "(GMT+01:00) Prague",
        "value": "Europe/Prague"
    }, {
        "label": "(GMT+01:00) Rome",
        "value": "Europe/Rome"
    }, {
        "label": "(GMT+01:00) Sarajevo",
        "value": "Europe/Sarajevo"
    }, {
        "label": "(GMT+01:00) Skopje",
        "value": "Europe/Skopje"
    }, {
        "label": "(GMT+01:00) Stockholm",
        "value": "Europe/Stockholm"
    }, {
        "label": "(GMT+01:00) Vienna",
        "value": "Europe/Vienna"
    }, {
        "label": "(GMT+01:00) Warsaw",
        "value": "Europe/Warsaw"
    }, {
        "label": "(GMT+01:00) Zagreb",
        "value": "Europe/Zagreb"
    }, {
        "label": "(GMT+02:00) Athens",
        "value": "Europe/Athens"
    }, {
        "label": "(GMT+02:00) Bucharest",
        "value": "Europe/Bucharest"
    }, {
        "label": "(GMT+02:00) Cairo",
        "value": "Africa/Cairo"
    }, {
        "label": "(GMT+02:00) Harare",
        "value": "Africa/Harare"
    }, {
        "label": "(GMT+02:00) Helsinki",
        "value": "Europe/Helsinki"
    }, {
        "label": "(GMT+02:00) Istanbul",
        "value": "Europe/Istanbul"
    }, {
        "label": "(GMT+02:00) Jerusalem",
        "value": "Asia/Jerusalem"
    }, {
        "label": "(GMT+02:00) Kyiv",
        "value": "Europe/Kiev"
    }, {
        "label": "(GMT+02:00) Minsk",
        "value": "Europe/Minsk"
    }, {
        "label": "(GMT+02:00) Riga",
        "value": "Europe/Riga"
    }, {
        "label": "(GMT+02:00) Sofia",
        "value": "Europe/Sofia"
    }, {
        "label": "(GMT+02:00) Tallinn",
        "value": "Europe/Tallinn"
    }, {
        "label": "(GMT+02:00) Vilnius",
        "value": "Europe/Vilnius"
    }, {
        "label": "(GMT+03:00) Baghdad",
        "value": "Asia/Baghdad"
    }, {
        "label": "(GMT+03:00) Kuwait",
        "value": "Asia/Kuwait"
    }, {
        "label": "(GMT+03:00) Nairobi",
        "value": "Africa/Nairobi"
    }, {
        "label": "(GMT+03:00) Riyadh",
        "value": "Asia/Riyadh"
    }, {
        "label": "(GMT+03:00) Moscow",
        "value": "Europe/Moscow"
    }, {
        "label": "(GMT+03:30) Tehran",
        "value": "Asia/Tehran"
    }, {
        "label": "(GMT+04:00) Baku",
        "value": "Asia/Baku"
    }, {
        "label": "(GMT+04:00) Volgograd",
        "value": "Europe/Volgograd"
    }, {
        "label": "(GMT+04:00) Muscat",
        "value": "Asia/Muscat"
    }, {
        "label": "(GMT+04:00) Tbilisi",
        "value": "Asia/Tbilisi"
    }, {
        "label": "(GMT+04:00) Yerevan",
        "value": "Asia/Yerevan"
    }, {
        "label": "(GMT+04:30) Kabul",
        "value": "Asia/Kabul"
    }, {
        "label": "(GMT+05:00) Karachi",
        "value": "Asia/Karachi"
    }, {
        "label": "(GMT+05:00) Tashkent",
        "value": "Asia/Tashkent"
    }, {
        "label": "(GMT+05:30) Kolkata",
        "value": "Asia/Kolkata"
    }, {
        "label": "(GMT+05:45) Kathmandu",
        "value": "Asia/Kathmandu"
    }, {
        "label": "(GMT+06:00) Ekaterinburg",
        "value": "Asia/Yekaterinburg"
    }, {
        "label": "(GMT+06:00) Almaty",
        "value": "Asia/Almaty"
    }, {
        "label": "(GMT+06:00) Dhaka",
        "value": "Asia/Dhaka"
    }, {
        "label": "(GMT+07:00) Novosibirsk",
        "value": "Asia/Novosibirsk"
    }, {
        "label": "(GMT+07:00) Bangkok, Hanoi",
        "value": "Asia/Bangkok"
    }, {
        "label": "(GMT+07:00) Jakarta",
        "value": "Asia/Jakarta"
    }, {
        "label": "(GMT+08:00) Krasnoyarsk",
        "value": "Asia/Krasnoyarsk"
    }, {
        "label": "(GMT+08:00) Chongqing",
        "value": "Asia/Chongqing"
    }, {
        "label": "(GMT+08:00) Hong Kong",
        "value": "Asia/Hong_Kong"
    }, {
        "label": "(GMT+08:00) Kuala Lumpur",
        "value": "Asia/Kuala_Lumpur"
    }, {
        "label": "(GMT+08:00) Perth",
        "value": "Australia/Perth"
    }, {
        "label": "(GMT+08:00) Singapore",
        "value": "Asia/Singapore"
    }, {
        "label": "(GMT+08:00) Taipei",
        "value": "Asia/Taipei"
    }, {
        "label": "(GMT+08:00) Ulaan Bataar",
        "value": "Asia/Ulaanbaatar"
    }, {
        "label": "(GMT+08:00) Urumqi",
        "value": "Asia/Urumqi"
    }, {
        "label": "(GMT+09:00) Irkutsk",
        "value": "Asia/Irkutsk"
    }, {
        "label": "(GMT+09:00) Seoul",
        "value": "Asia/Seoul"
    }, {
        "label": "(GMT+09:00) Tokyo",
        "value": "Asia/Tokyo"
    }, {
        "label": "(GMT+09:30) Adelaide",
        "value": "Australia/Adelaide"
    }, {
        "label": "(GMT+09:30) Darwin",
        "value": "Australia/Darwin"
    }, {
        "label": "(GMT+10:00) Yakutsk",
        "value": "Asia/Yakutsk"
    }, {
        "label": "(GMT+10:00) Brisbane",
        "value": "Australia/Brisbane"
    }, {
        "label": "(GMT+10:00) Canberra",
        "value": "Australia/Canberra"
    }, {
        "label": "(GMT+10:00) Guam",
        "value": "Pacific/Guam"
    }, {
        "label": "(GMT+10:00) Hobart",
        "value": "Australia/Hobart"
    }, {
        "label": "(GMT+10:00) Melbourne",
        "value": "Australia/Melbourne"
    }, {
        "label": "(GMT+10:00) Port Moresby",
        "value": "Pacific/Port_Moresby"
    }, {
        "label": "(GMT+10:00) Sydney",
        "value": "Australia/Sydney"
    }, {
        "label": "(GMT+11:00) Vladivostok",
        "value": "Asia/Vladivostok"
    }, {
        "label": "(GMT+12:00) Magadan",
        "value": "Asia/Magadan"
    }, {
        "label": "(GMT+12:00) Auckland",
        "value": "Pacific/Auckland"
    }, {
        "label": "(GMT+12:00) Fiji",
        "value": "Pacific/Fiji"
    }]
}