import clsx from "clsx"
import { ReactNode } from "react"

interface IInputTag {
    title?: string
    showRemove?: boolean
    value?: any
    onChange?: (value: string) => void
    onClick?: () => void
    itemOnClick?: (e: string) => void
    className?: string
    size?: 'sm' | 'normal'
    inputComponent?: ReactNode
    disabled?: boolean
}

export function InputTag({ title, value, onChange, onClick, showRemove, className, size, inputComponent, itemOnClick, disabled }: IInputTag) {

    return <>
        <div className={clsx("form-control py-0 ", className, { 'form-control-sm': size == undefined || size == 'sm', "disabled": disabled })} onClick={onClick}>
            {title && <span className="me-2">{title}</span>}
            {(typeof value == 'string'
                ? value.split(',')
                : Array.isArray(value)
                    ? value
                    : []).map((item, index) => item ? <div
                        key={index}
                        onClick={() => itemOnClick && itemOnClick(item)}
                        className={clsx("d-inline-block bg-gray-200 px-2 py-1 rounded-1 text-primary me-1 mb-2", {
                            'mt-2': index == 0,
                            'cursor-pointer': !!itemOnClick,
                            'text-secondary': disabled
                        })}>
                        <div className="d-flex flex-center">
                            <span className="fw-semibold">{item}</span>
                            {showRemove && <i className="bi bi-x ms-1 cursor-pointer" onClick={() => {
                                onChange && onChange((typeof value == 'string'
                                    ? value.split(',')
                                    : Array.isArray(value)
                                        ? value
                                        : []).filter((f: any) => f != item).join(','))
                            }} />}
                        </div>
                    </div> : <></>)}
            {inputComponent}
        </div>
    </>
}