import clsx from "clsx"
import { FC } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { getIsPermission } from "../../../../app/modules/auth/Permission"
import { popupQuestion } from "../../../../app/modules/messages"
import { ApiShowError } from "../../../helpers"
import { KTSVG } from "../utils/KTSVG"

interface IRowIcon {
    permission?: string
    to?: string
    onClick?: any
    action: 'delete' | "modify" | "read" | "approved" | "denied" | "clone" | "reset" | "sync" | "print" | "download"
    question?: string
    className?: string
    disabled?: boolean
    notQuestion?: boolean
}

const contents = {
    'download': {
        color: 'light-success',
        icon: 'bi bi-download',
        question: "message.question.download"
    },
    'print': {
        color: 'light',
        icon: 'bi bi-printer'
    },
    'sync': {
        color: 'light-success',
        icon: 'bi bi-arrow-down-up',
        question: "message.question.sync-bc",
        permission: ['MODIFY']
    },
    'reset': {
        color: 'light-warning',
        icon: 'bi bi-arrow-repeat',
        permission: ['MODIFY']
    },
    'delete': {
        color: 'light-danger',
        icon: '/media/icons/duotune/general/gen027.svg',
        question: "message.question.delete",
        permission: ['DELETE'],
    },
    'modify': {
        color: 'light-primary',
        icon: '/media/icons/custom/svgexport.svg',
        permission: ['MODIFY']
    },
    'read': {
        color: 'light-primary',
        icon: '/media/icons/duotune/general/gen027.svg',
        permission: ['READ']
    },
    'clone': {
        color: 'light-info',
        icon: '/media/icons/duotune/general/gen054.svg',
        question: "message.question.clone",
        permission: ['INSERT']
    },
    'approved': {
        color: 'light-success',
        icon: '/media/icons/duotune/arrows/arr084.svg',
        title: 'Phê duyệt',
        class: 'svg-icon-2'
    },
    'denied': {
        color: 'light-danger',
        icon: '/media/icons/custom/denied.svg',
        title: "Từ chối",
        class: 'svg-icon-2'
    },
} as any

export const RowIcon: FC<IRowIcon> = ({ permission, to, onClick, action, question, className, disabled, notQuestion }) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const isPermission = permission && contents[action].permission ? getIsPermission([permission, ...contents[action].permission]) : true
    const { icon, class: iconClass, color, title } = contents[action]
    const isSvg = icon && (icon?.split('media/icons/').length > 1)
    const btnClass = clsx(className,
        `btn btn-sm btn-icon btn-${color}`, {
        'disabled': disabled
    })

    async function handleClick() {
        if (to) return navigate(to)
        try {
            const message = contents[action].question ? intl.formatMessage({ id: contents[action].question as any }) : undefined
            let isQuestion = true
            if (!notQuestion) {
                if (typeof question == 'string') {
                    isQuestion = await popupQuestion({ description: question })
                } else if (message) isQuestion = await popupQuestion({ description: message })
            }
            if (isQuestion && onClick) onClick()
        } catch (error) {
            ApiShowError(error)
        }
    }

    return <span title={title} className={clsx(btnClass, { 'disabled': !isPermission })} onClick={handleClick}>
        {isSvg
            ? <KTSVG path={icon} className={iconClass || clsx('svg-icon-4', `svg-icon-${contents[action].color}`)} />
            : <i className={clsx('fs-3', icon)}></i>
        }
    </span>
}