import { forwardRef } from "react";

interface CustomToggleProps {
    children: React.ReactNode
    onClick: (e: React.MouseEvent) => void
}

const CustomToggle = forwardRef<any, CustomToggleProps>(({ children, onClick }, ref) => (
    <a href="" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} >
        {children}
    </a>
));

export default CustomToggle;