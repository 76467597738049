import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx"
import { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { Column } from "react-table"
import { ApiService, ApiShowError, changeDataModify, getValueId, radomString, sortByUpdateAt } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, DivHr, FilterDropdown, FormSubmit, InputSelect, InputSingleImage, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../theme/partials"
import { IInitField } from "../../api"
import { optionAddress } from "../../api/address"
import { apiAd } from "../../api/admin"
import { useAuth } from "../../auth"
import { loadMessageI18n, popupMessage, popupQuestion } from "../../messages"
import AdAccessControls from "./accessControlsCustom"

export interface IAdUser extends IInitField {
    signature?: string | null
    avatar?: string | null
    username: string | null
    fullname: string | null
    state: boolean
    departmentid: string | null
    departmentcode: string | null
    departmentname: string | null
    phone: string | null
    contactemail: string | null
    authenticationemail: string | null
    authenticationstatus: boolean
    employeecode: string | null
    password?: string | null
    webserviceaccesskey: string | null
    webserviceexpirydate: string | null
    timezone: string | null
    communication?: string[]
}

const breadCrumbs = [{ title: 'system', path: '/system-settings', }]
const apiurl = 'settings/users'
export default function AdUsersPage() {
    const { data, isSuccess, isFetching } = apiAd.useUsers()
    const [tableData, setTableData] = useState<IAdUser[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.username' />,
        accessor: 'username',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].username}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.fullname' />,
        accessor: 'fullname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].fullname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.phone' />,
        accessor: 'phone',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].phone} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.contact.email' />,
        accessor: 'email',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].contactemail} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.active' className="text-center" />,
        accessor: 'state',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].state} className="d-flex flex-center" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" to={props.data[props.row.index].id} />
            {/* <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                const items = await ApiService.get(`settings/accesscontrols?userid=${id}`)
                if (items.data.length) return popupMessage({ icon: "error", description: 'Access control!' })
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} /> */}
        </div>
    }]

    return <>
        <ContentHeader title="system.users" items={breadCrumbs} />
        <KTTable
            isLoading={isFetching}
            toolbar={<>
                <FilterDropdown>
                </FilterDropdown>
                <KTButton action='new' />
            </>}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search />
    </>
}

const defaultValues: IAdUser = {
    timezone: 'Asia/Bangkok',
    username: null,
    fullname: null,
    state: true,
    departmentid: null,
    departmentcode: null,
    departmentname: null,
    phone: null,
    contactemail: null,
    authenticationemail: null,
    authenticationstatus: true,
    employeecode: null,
    password: null,
    webserviceaccesskey: null,
    webserviceexpirydate: null
}

export async function resetPassword(userData?: IAdUser) {
    let isSuccess = false
    if (!userData || !userData?.id || (userData && userData.id == 'new')) {
        popupMessage({ icon: "error", description: loadMessageI18n({ key: "message.error.user-not-found" }) })
        return;
    }
    const isquestion = await popupQuestion({ description: loadMessageI18n({ key: "text.password-reset" }) })
    if (isquestion) {
        try {
            const newPassword = radomString(8)
            await ApiService.post(`/utilities/sendmail/withemailtemplate`, {
                emailtemplatecode: process.env.REACT_APP_RESET_PASSWORD,
                receiveremail: userData.authenticationemail,
                data: { "NEW_PASSWORD": newPassword }
            })
            await ApiService.put(`/settings/users/${userData.id}`, { "password": newPassword })
            await popupMessage({ icon: "success" })
            isSuccess = true
        } catch (error) {
            ApiShowError(error)
        }
    }
    return isSuccess
}

export const AdUsersDetailPage = () => {
    const { auth, logout } = useAuth()
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching } = apiAd.useUsersId<IAdUser>(valueid)
    const { register, reset, watch, setValue, control, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdUser>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            username: yup.string().required(),
            password: valueid ? yup.string() : yup.string().required(),
            fullname: yup.string().required(),
            authenticationemail: yup.string().email().required(),
            contactemail: yup.string().email().nullable(true).transform((_, val) => val ? val : null),
            timezone: yup.string().required()
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    async function onSubmit(data: IAdUser) {
        try {
            if (valueid != undefined) delete data.password
            if (data.username) data.username = data.username?.toLowerCase()
            const method = isEdit ? 'put' : 'post'
            const result = await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
            if (!isEdit && result?.data?.id) navigate(`/system-settings/users/${result.data.id}`)
            popupMessage({ icon: 'success', autoClose: true })
            if (isEdit) return navigate('/system-settings/users')
        } catch (error) {
            ApiShowError(error)
        }
    }

    async function resetPass() {
        const isSuccess = await resetPassword(data)
        if (isSuccess && auth?.user?.id == data?.id) logout(true)
    }

    const refSubmit = useRef<HTMLButtonElement | null>(null)
    return <>
        <ContentHeader isInfo title="system.users" items={[...breadCrumbs, { title: 'system.users', path: '/system-settings/users' }]} />
        <div className="card">
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        handleSubmit={() => refSubmit.current?.click()}
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        handleClose={() => navigate('/system-settings/users')} />
                </div>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)} className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.username'>
                            <input className={`form-control form-control-sm ${errors.username && 'form-error'}`} {...register('username')} />
                        </KTFormItem>
                        <KTFormItem title='text.fullname'>
                            <input className={`form-control form-control-sm ${errors.fullname && 'form-error'}`} {...register('fullname')} />
                        </KTFormItem>
                        {/* <KTFormItem title='text.phone'>
                            <input className={`form-control form-control-sm ${errors.phone && 'form-error'}`} {...register('phone')} />
                        </KTFormItem>
                        <KTFormItem title='text.contact.email'>
                            <input className={`form-control form-control-sm ${errors.contactemail && 'form-error'}`} {...register('contactemail')} />
                        </KTFormItem> */}
                        <KTFormItem title='text.timezone'>
                            <Controller
                                name="timezone"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    options={optionAddress.timeZone}
                                    value={optionAddress.timeZone.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                    className={clsx('w-100', { 'form-error': errors.timezone })}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.signature' isHeight>
                            <Controller
                                name="signature"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSingleImage
                                    size="2:1"
                                    value={value}
                                    onChange={onChange} />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.active'>
                            <label className="form-check form-switch form-check-custom form-check-solid gap-2">
                                <input className="form-check-input" type="checkbox" {...register("state")} />
                            </label>
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <h3 className="fw-medium mb-6"><FormattedMessage id="text.login.info" /></h3>
                        <KTFormItem title='text.email-verification'>
                            <label className="form-check form-switch form-check-custom form-check-solid gap-2">
                                <input disabled className="form-check-input" type="checkbox" {...register("authenticationstatus")} />
                            </label>
                        </KTFormItem>
                        <KTFormItem title='text.login.email'>
                            <input readOnly={isEdit} className={`form-control form-control-sm ${errors.authenticationemail && 'form-error'}`} {...register('authenticationemail')} />
                        </KTFormItem>
                        <KTFormItem title='text.password'>
                            <div className="d-flex w-100 gap-2">
                                <input
                                    readOnly={valueid != undefined}
                                    id='inputPassword'
                                    className={clsx(`form-control form-control-sm`, {
                                        'form-error': errors.password,
                                        'pe-10': valueid != undefined
                                    })}
                                    {...register('password')}
                                    type="password"
                                    placeholder={valueid ? "******" : ''} />
                                {isEdit && <div
                                    className="btn btn-sm btn-link text-hover-primary min-w-125px"
                                    onClick={resetPass}>
                                    <i className="bi bi-arrow-clockwise " ></i>
                                    <span className="fs-7"><FormattedMessage id="text.reset-password" /></span>
                                </div>}
                            </div>
                        </KTFormItem>
                        <h3 className="fw-medium mt-4 py-6"><FormattedMessage id="text.web-service" /></h3>
                        <KTFormItem title='text.web-service.accesskey'>
                            <input disabled className={`form-control form-control-sm ${errors.webserviceaccesskey && 'form-error'}`} {...register('webserviceaccesskey')} />
                        </KTFormItem>
                        <KTFormItem title='text.web-service.expirydate'>
                            <input disabled className={`form-control form-control-sm ${errors.webserviceexpirydate && 'form-error'}`} {...register('webserviceexpirydate')} />
                        </KTFormItem>
                    </div>
                    <button type="submit" ref={refSubmit} className="d-none" />
                </form>
                {isEdit && <>
                    <DivHr />
                    <AdAccessControls userData={data} />
                </>}
            </div>
        </div>
    </>
}
