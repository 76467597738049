import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Controller, FieldErrors, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService, ApiShowError, changeDataModify, formatOptions, getValueId, isInt, sortWithAny } from "../../../../../theme/helpers";
import { ContentHeader } from "../../../../../theme/layout/components/content";
import { DivHr, FormSubmit, InputDate, InputSelect, KTFormItem, TextFormByUser, yup } from "../../../../../theme/partials";
import SearchPopup from "../../../../../theme/partials/layout/search-popup";
import { InputTag } from "../../../../../theme/partials/widgets/form/InputTag";
import { apiAd } from "../../../api/admin";
import { popupMessage } from "../../../messages";
import { IAdUser } from "../users";
import { AddRowButton, ApprovalTypeManual, defaultApproval, EmailTemplateCard, IAdApproval, optionApprovalTypeCustom, optionRuleComparator, optionRuleType, optionType, optionUnitTime, RemoveRowButton, RenderRuleInput } from "./component";

const breadCrumbs = [{ title: 'system', path: '/system-settings', }]
const apiurl = 'settings/approvals'
export default function AdApprovalsDetailPage() {
    const intl = useIntl()
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching, refetch } = apiAd.useApprovalsId<IAdApproval | any>(valueid)
    const [checkeds, setCheckeds] = useState<any[]>([true, false, false])

    const { register, reset, watch, control, setError, setValue, handleSubmit, clearErrors, formState: { errors, isSubmitting } } = useForm<IAdApproval>({
        defaultValues: defaultApproval,
        resolver: yupResolver(yup.object().shape({
            priority: yup.number().min(1).test('unique', (val, ctx) => {
                if (ctx.parent['type'] == "20120002") {
                    const approvalValue = approvalsData.find((f: IAdApproval) =>
                        f.blocked && f.id != valueid && f.priority == val && f.objectid == ctx.parent['objectid'])
                    if (approvalValue || !val) return ctx.createError()
                }
                return true
            }).nullable().transform(val => val ? Number(val) : null),
            objectid: yup.string().required(),
            type: yup.number().required(),
            approvalname: yup.string().required(),
            approvallevels: yup.array().of(yup.object({
                sla: yup.number().nullable().transform(val => val ? Number(val) : null),
                approvalpolicyid: yup.string().required(),
                subtype: yup.string().test('require_subtype', (val, ctx) => {
                    if (ctx.parent['type'] == 'submits_to' && !val) return ctx.createError()
                    return true
                }).nullable().transform(val => val ? val : null),
                username: yup.string().test('require_user', (val, ctx) => {
                    if (ctx.parent['type'] == 'user' && !val) return ctx.createError()
                    return true
                }).nullable().transform(val => val ? val : null),
                departmentid: yup.string().test('require_departmentid', (val, ctx) => {
                    if (ctx.parent['type'] == 'head_of_selected_department' && !val) return ctx.createError()
                    return true
                }).nullable().transform(val => val ? val : null),
            })).nullable(),
            rule: yup.object().shape({
                columns: yup.array().of(yup.object({
                    index: yup.number().required(),
                    comparator: yup.string().required(),
                    field: yup.string().required(),
                    value: yup.string().required(),
                })),
                criteria_string: yup.string().nullable().transform(val => val || null)
            }).nullable(),
            slaconfiguration: yup.object({
                execution: yup.array().of(yup.object({
                    sla_duration: yup.number().test((val, ctx) => {
                        if (!val && checkeds[0] && ctx.path == `slaconfiguration.execution[0].sla_duration`) return ctx.createError()
                        if (!val && checkeds[2] && ctx.path == `slaconfiguration.execution[1].sla_duration`) return ctx.createError()
                        return true
                    }).nullable().transform(val => val || null),
                    sla_details: yup.object({
                        sla_type: yup.string().nullable().transform(val => val || null),
                        sla_value: yup.number().test((val, ctx) => {
                            if (!val && ctx.parent['sla_type'] == '3' && ctx.path == `slaconfiguration.execution[0].sla_details.sla_value`) return ctx.createError()
                            return true
                        }).nullable().transform(val => val || null)
                    }),
                    notification_mails: yup.object({
                        notification_time: yup.number().test((val, ctx) => {
                            if (!val && checkeds[1] && ctx.path == `slaconfiguration.execution[0].notification_mails.notification_time`) return ctx.createError()
                            return true
                        }).nullable().transform(val => val || null),
                        notification_time_type: yup.string().nullable().transform(val => val || null),
                    }).nullable()
                }))
            })
        })),
    })
    const { rule: wrule, approvallevels: wapprovallevels } = watch()
    const isCustomType = watch('type') == "20120002"
    const [editCriteria, setEditCriteria] = useState<string | undefined>(undefined)
    const [keys, setKeys] = useState<any[]>([])
    const [ids, setIds] = useState<string[]>([])
    const [searchType, setSearchType] = useState<'email' | 'username'>('username')

    useEffect(() => {
        if (isSuccess && !isFetching) {
            const checked1 = data?.slaconfiguration?.execution[0]?.notification_mails?.notification_time != undefined
            const checked2 = data?.slaconfiguration?.execution[1]?.sla_duration != undefined
            setCheckeds([true, checked1, checked2])
            reset({
                ...data,
                slaconfiguration: Array.isArray(data?.slaconfiguration?.execution)
                    ? data?.slaconfiguration
                    : {
                        execution: [{
                            sla_duration: '1',
                            sla_details: { sla_type: '1' }
                        }, {
                            sla_details: { sla_type: '1' }
                        }]
                    }
            })
        } else if (!isEdit) {
            reset({
                blocked: false,
                slaconfiguration: {
                    execution: [{
                        sla_duration: '1',
                        sla_details: { sla_type: '1' }
                    }, {
                        sla_details: { sla_type: '1' }
                    }]
                }
            })
        }
    }, [isFetching])

    const { data: objectsData, isSuccess: isObjects } = apiAd.useObjects()
    const { data: approvalsData, isSuccess: isApprovals } = apiAd.useApprovals()
    const objectsIds = isApprovals
        ? approvalsData
            .filter((f: IAdApproval) => f.objectid != data?.objectid && f.blocked)
            .filter((f: IAdApproval) => isCustomType ? f.type != "20120002" : true)
            .map((m: IAdApproval) => m.objectid)
        : []
    const optionObjects = isObjects && isApprovals ? sortWithAny(
        formatOptions((
            objectsData.filter((f: any) => !objectsIds.includes(f.id))
        ), ['id', 'objectid', 'objectname']),
        { order: "asc", sort: "objectid" }
    ) : []

    const { data: objectCustomFieldsData, isSuccess: isObjectCustomFields } = apiAd.useObjectCustomFieldsByObjectId(watch('objectid') || undefined)
    const optionObjectFields = isObjectCustomFields ? [{
        label: "Fields",
        options: formatOptions(objectCustomFieldsData, ['labelnamesystem', 'labelname'])
    }, {
        label: "Other",
        options: [{ value: 'usergroup', label: 'User Group', datatype: 'UUID' }]
    }] : []

    const { data: usersData, isSuccess: iUsers, isLoading: loadUsers } = apiAd.useUsers()
    const optionUsernames = usersData ? formatOptions(usersData, ['username', 'username']) : []
    const optionsUser = iUsers ? usersData.map((item: IAdUser) => ({
        ...item,
        title: searchType == 'username' ? item.username : item.contactemail,
        description: searchType == 'username' ? item.authenticationemail : item.username
    })) : []

    const { data: userGroupsData, isSuccess: isUsergroups } = apiAd.useUserGroups()
    const optionsUserGroup = isUsergroups ? formatOptions(userGroupsData, ['id', 'code', 'name']) : []

    const { data: emailTemplatesData, isSuccess: isEmailTemplates } = apiAd.useEmailTemplates()
    const optionEmailTemplates = isEmailTemplates ? formatOptions(emailTemplatesData, ['id', 'code']) : []

    function getCriteria(index: number, text?: string) {
        if (index > watch('rule.columns').length) return undefined
        if (index == 1) return 'WHEN'
        const arr = (text || watch("rule.criteria_string")).replace('(', '').replace(')', '').split(' ')
        const value = arr[arr.findIndex(item => item == String(index)) - 1]
        return value || null
    }

    function setCriteria(index: number, type: 'OR' | 'AND') {
        if (watch("rule.criteria_string").indexOf(String(index)) == -1) {
            return setValue('rule.criteria_string', watch("rule.criteria_string") + ` ${type} ${index}`)
        }
        let arr = watch("rule.criteria_string").split(' ')
        const indexText = arr.findIndex(item => item.indexOf(String(index)) != -1) - 1
        arr[indexText] = type
        setValue('rule.criteria_string', arr.join(' '))
    }

    function removeCellRule(index: number) {
        if (wrule && wrule?.columns.length < 2) return setValue('rule.criteria_string', '')

        let arr = watch("rule.criteria_string").split(' ')
        const indexText = arr.findIndex(item => item.indexOf(String(index)) != -1)
        if (arr[indexText].indexOf('(') != -1) {
            arr[indexText + 2] = arr[indexText + 2].replace(')', '')
        }
        if (arr[indexText].indexOf(')') != -1) {
            arr[indexText - 2] = arr[indexText - 2].replace('(', '')
        }
        let newArr = arr.filter((v, i, arr) => i != indexText && i != indexText - 1)
        wrule?.columns.forEach((item, rindex) => {
            if (rindex + 1 >= index) {
                const inewtext = newArr.findIndex(item => item.indexOf(String(wrule.columns[rindex].index)) != -1)
                if (inewtext != -1) {
                    if (wrule.columns[rindex].index == 2 && rindex + 1 == 1) newArr[inewtext - 1] = ""
                    newArr[inewtext] = newArr[inewtext].replace(String(wrule.columns[rindex].index), String(rindex + 1))
                }
                setValue(`rule.columns.${rindex}`, { ...wrule.columns[rindex], index: rindex + 1 })
            }
        })
        setValue('rule.criteria_string', newArr.join(' '))
    }

    function handleChangeCriteria() {
        if (!editCriteria) return setError('rule.criteria_string', { type: 'validate' }, { shouldFocus: true })
        let formatValue = editCriteria.replace('( ', '(').replace(' )', ')').replace('or', 'OR').replace('and', 'AND')
        let error = false
        const arr = formatValue.split(' ')
        wrule?.columns.forEach((item, index) => {
            if (arr.findIndex(f => f.indexOf(String(index + 1)) != -1) == -1) {
                error = true
            }
            if (index >= 1) {
                const type = getCriteria(index + 1, formatValue)
                if (!type) error = true
                if (type && !['OR', 'AND'].includes(type)) error = true
            }
        })
        formatValue.split(' ').forEach(item => {
            item = item.replace(/\D+/g, '')
            if (!isNaN(parseInt(item)) && wrule?.columns && parseInt(item) > wrule.columns.length) {
                error = true
            }
        })
        if (error) return setError('rule.criteria_string', { type: 'validate' }, { shouldFocus: true })
        setValue('rule.criteria_string', formatValue)
        clearErrors('rule.criteria_string')
        setEditCriteria(undefined)
    }

    function handleAddApprovalManual() {
        const counts = wapprovallevels?.filter(f => isInt(f.order)).map(m => m.order).sort((a, b) => Number(a) - Number(b))
        const newValue = {
            "notes": null,
            "departmentid": null,
            "departmentname": null,
            "userid": null,
            "username": null,
            "approvalpolicyid": null,
            "type": null,
            "typeformatted": null,
            "subtype": null,
            "subtypeformatted": null,
            "order": counts && counts?.length > 0 ? counts.length + 1 : 1
        }
        setValue('approvallevels', wapprovallevels?.length ? [...wapprovallevels, newValue] : [newValue])
    }

    function handleError(errors: FieldErrors<IAdApproval>) {
        console.log(errors)
    }

    const [isClose, setIsClose] = useState(false)
    return <>
        <ContentHeader title="system.approvals" items={[...breadCrumbs, { title: 'system.approvals', path: '/system-settings/approvals' }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IAdApproval) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    const result = await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    isEdit && refetch()
                    !isClose && !isEdit && navigate(`/system-settings/approvals/${result.data.id}`)
                    isClose && navigate('/system-settings/approvals')
                } catch (error) { ApiShowError(error) }
            }, handleError)}>
            <div className="card-header">
                <h3 className="card-title my-0">
                    <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x text-dark h-100 border-0 gap-1">
                        <li className="nav-item">
                            <a className="nav-link h-100 d-flex flex-center m-0 px-2 active" data-bs-toggle="tab" href="#tab-general" >
                                <FormattedMessage id="text.general" />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link h-100 d-flex flex-center m-0 px-2" data-bs-toggle="tab" href="#tab-turn_around_time">
                                <FormattedMessage id="text.turn-around-time" />
                            </a>
                        </li>
                    </ul>
                </h3>
                <div className="card-toolbar d-flex flex-lg-row flex-column-reverse gap-2 my-4 my-lg-2">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        disabled={!data?.isedited && isEdit}
                        showOnlySave
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        handleCloseWhenSubmit={setIsClose}
                        handleClose={() => navigate('/system-settings/approvals')} />
                </div>
            </div>
            <div className="card-body">
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="tab-general" role="tabpanel" >
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <KTFormItem title='text.type'>
                                    <Controller
                                        name="type"
                                        control={control}
                                        render={({ field: { value, onChange } }) => <InputSelect
                                            className={clsx('w-100', { 'form-error': errors.type })}
                                            options={optionType}
                                            value={optionType.find(f => f.value == value) || null}
                                            onChange={(e) => {
                                                if (!e) return onChange(null)
                                                if (e.value == '20120001') {
                                                    setValue('priority', null)
                                                    setValue('approvaltype', null)
                                                    setValue('approvallevels', null)
                                                    setValue('rule', null)
                                                }
                                                if (e.value == '20120002') {
                                                    setValue('approvaltype', 'manual')
                                                    handleAddApprovalManual()
                                                }
                                                onChange(e.value)
                                            }}
                                        />}
                                    />
                                </KTFormItem>
                                <KTFormItem title='text.name'>
                                    <input className={`form-control form-control-sm ${errors.approvalname && 'form-error'}`} {...register('approvalname')} />
                                </KTFormItem>
                            </div>
                            <div className="col-12 col-lg-6">
                                <KTFormItem title='text.priority'>
                                    <input
                                        disabled={!isCustomType}
                                        className={clsx('form-control form-control-sm', { 'form-error': errors.priority })} {...register('priority')} type="number" />
                                </KTFormItem>
                                <KTFormItem title='text.module'>
                                    <Controller
                                        name="objectid"
                                        control={control}
                                        render={({ field: { value, onChange } }) => <InputSelect
                                            className={clsx('w-100', { 'form-error': errors.objectid })}
                                            options={optionObjects}
                                            value={optionObjects.find(f => f.value == value) || null}
                                            onChange={(e) => {
                                                setValue("objectcode", null)
                                                setValue("objectname", null)
                                                setValue('rule', null)
                                                if (!e) return onChange(null)
                                                onChange(e.value)
                                                setValue("objectcode", e.objectid)
                                                setValue("objectname", e.objectname)
                                            }}
                                        />}
                                    />
                                </KTFormItem>
                            </div>
                            <div className="col-12">
                                <KTFormItem title='text.description'>
                                    <textarea className={`form-control form-control-sm ${errors.description && 'form-error'}`} {...register('description')} rows={1} />
                                </KTFormItem>
                                <KTFormItem title='text.active'>
                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                        <input className="form-check-input" type="checkbox" {...register("blocked")} />
                                    </label>
                                </KTFormItem>
                            </div>
                            {isCustomType && <>
                                {/* rules */}
                                <DivHr />
                                <div className="col-12 d-flex flex-column flex-start">
                                    <div className="fs-4 mb-3"><FormattedMessage id="text.define-ther-criteria-(if-any)" /></div>
                                    {wrule?.columns.map((item, index) => {
                                        return <div key={index} className="d-flex d-row-hover w-100">
                                            <div className="row w-100">
                                                <div className="col-12 col-lg-6 row align-items-center mb-3">
                                                    <div className="col-4 d-flex flex-center">
                                                        <span className="px-3">{item.index}</span>
                                                        <InputSelect
                                                            className="w-100"
                                                            disabled={item.index == 1}
                                                            options={item.index == 1 ? optionRuleType : optionRuleType.filter(f => f.value != 'WHEN')}
                                                            value={optionRuleType.find(f => f.value == getCriteria(item.index)) || null}
                                                            onChange={(e) => setCriteria(item.index, e.value)} />
                                                    </div>
                                                    <div className="col-8">
                                                        <Controller
                                                            name={`rule.columns.${index}.field`}
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => <InputSelect
                                                                className={clsx('w-100', { 'form-error': errors.rule?.columns?.[index]?.field })}
                                                                options={optionObjectFields}
                                                                value={optionObjectFields.reduce((tol: any[], pre) => [...(tol || []), ...(pre.options || [])], []).find(f => f.value == value) || null}
                                                                onChange={(e) => {
                                                                    setValue(`rule.columns.${index}.comparator`, null)
                                                                    setValue(`rule.columns.${index}.value`, null)
                                                                    setValue(`rule.columns.${index}.name`, undefined)
                                                                    if (!e) return onChange(null)
                                                                    onChange(e.value)
                                                                    setValue(`rule.columns.${index}.datatype`, e.datatype)
                                                                }} />}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6 row align-items-center mb-3">
                                                    {wrule.columns[index].field
                                                        && <div className="col-4">
                                                            <Controller
                                                                name={`rule.columns.${index}.comparator`}
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => <InputSelect
                                                                    className={clsx('w-100', { 'form-error': errors.rule?.columns?.[index]?.comparator })}
                                                                    options={item.datatype
                                                                        ? optionRuleComparator.filter(f => f.type.split(',').includes(item.datatype as any))
                                                                        : []}
                                                                    value={optionRuleComparator.find(f => f.value == value) || null}
                                                                    onChange={(e) => {
                                                                        setValue(`rule.columns.${index}.value`, null)
                                                                        if (!e) return onChange(null)
                                                                        onChange(e.value)
                                                                    }} />}
                                                            />
                                                        </div>}
                                                    <div className="col-8">
                                                        <RenderRuleInput {...{
                                                            index,
                                                            setValue,
                                                            register,
                                                            control,
                                                            datatype: item.datatype,
                                                            options: item.field && ['usergroup'].includes(item.field) ? optionsUserGroup : [],
                                                            error: errors.rule?.columns?.[index]?.value,
                                                            handleClick: () => {
                                                                setSearchType('username')
                                                                setKeys([
                                                                    [`rule.columns.${index}.name`, 'username'],
                                                                    [`rule.columns.${index}.value`, 'id']
                                                                ])
                                                            },
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <RemoveRowButton handleClick={() => {
                                                setValue('rule.columns', wrule.columns.filter((f, i, a) => i != index))
                                                removeCellRule(item.index)
                                            }} />
                                        </div>
                                    })}
                                    <AddRowButton title="Add Criteria" handleClick={() => {
                                        const newValue = {
                                            "comparator": null,
                                            "field": null,
                                            "index": wrule?.columns && wrule.columns.length > 0 ? wrule.columns.length + 1 : 1,
                                            "value": null,
                                        }

                                        if (wrule?.columns.length == 1) {
                                            setValue('rule.criteria_string', '1 AND 2')
                                        } else if (wrule && Array.isArray(wrule?.columns) && wrule.columns.length > 1) {
                                            setValue('rule.criteria_string', wrule.criteria_string + ` AND ${newValue.index}`)
                                        }
                                        setValue('rule.columns', wrule?.columns.length ? [...wrule.columns, newValue] : [newValue])
                                    }} />
                                    {wrule
                                        && Array.isArray(wrule?.columns)
                                        && wrule.columns.length >= 2
                                        && <div className="w-100 bg-gray-200 px-3 py-2 rounded d-flex align-items-center gap-2">
                                            <span className="min-w-100px"><FormattedMessage id="text.criteria-pattern" />:</span>
                                            {typeof editCriteria == 'string' ? <>
                                                <input className={clsx(`form-control form-control-sm`, { 'form-error': errors.rule?.criteria_string })} value={editCriteria} onChange={(e) => setEditCriteria(e.target.value)} />
                                                <button type="button" className="btn btn-sm btn-primary" onClick={handleChangeCriteria}>
                                                    <FormattedMessage id="text.save" />
                                                </button>
                                                <button type="button" className="btn btn-sm btn-secondary" onClick={() => setEditCriteria(undefined)}>
                                                    <FormattedMessage id="text.cancel" />
                                                </button>
                                            </>
                                                : <>
                                                    <span className="mx-2">{wrule?.criteria_string}</span>
                                                    <button type="button" className="btn btn-sm btn-link text-primary" onClick={() => setEditCriteria(watch('rule.criteria_string'))}>
                                                        <FormattedMessage id="text.change-criteria" />
                                                    </button>
                                                </>}
                                        </div>}
                                </div>
                                {/* approvals */}
                                <div className="col-12">
                                    <DivHr />
                                    <div className="fs-4"><FormattedMessage id="text.approvals" /></div>
                                    <div className="text-gray-600 mb-6"><FormattedMessage id="text.approval.setting-message" /></div>
                                    <div className="d-flex gap-2 mb-4">
                                        {optionApprovalTypeCustom.map(
                                            (item, index) => <div key={index} className="form-check form-check-sm form-check-custom form-check-solid">
                                                <input id={`radio${index}`} type="radio" value={item.value} className="form-check-input"
                                                    {...register("approvaltype", {
                                                        onChange(event) {
                                                            if (event.target.value != 'manual') setValue('approvallevels', [])
                                                            if (event.target.value == 'manual') {
                                                                setValue('approvallevels', [])
                                                                handleAddApprovalManual()
                                                            }
                                                            return event
                                                        },
                                                    })} />
                                                <label className="form-check-label" htmlFor={`radio${index}`}> {item.label} </label>
                                            </div>)}
                                    </div>
                                    {watch('approvaltype') == 'manual'
                                        && <ApprovalTypeManual {...{ watch, control, setValue, errors, optionUsernames, handleAddApprovalManual }} />}
                                </div>
                            </>}
                            {/* email notification */}
                            <div className="col-12">
                                <DivHr />
                                <div className="fs-4 mb-6"><FormattedMessage id="text.email-notification" /></div>
                                <KTFormItem title='text.cc' isBLockLabel>
                                    <Controller
                                        name={`emailnotification.cc`}
                                        control={control}
                                        render={({ field: { value, onChange } }) => <InputTag
                                            onClick={() => {
                                                setSearchType('email')
                                                setIds(watch('emailnotification.cc')?.split(',') || [])
                                                setKeys([['emailnotification.cc', 'contactemail']])
                                            }}
                                            value={value}
                                            onChange={onChange} />}
                                    />
                                </KTFormItem>
                                <KTFormItem title='text.bcc' isBLockLabel>
                                    <Controller
                                        name={`emailnotification.bcc`}
                                        control={control}
                                        render={({ field: { value, onChange } }) => <InputTag
                                            onClick={() => {
                                                setSearchType('email')
                                                setIds(watch(`emailnotification.bcc`)?.split(',') || [])
                                                setKeys([['emailnotification.bcc', 'contactemail']])
                                            }}
                                            value={value}
                                            onChange={onChange} />}
                                    />
                                </KTFormItem>
                                <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 text-dark">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-bs-toggle="tab" href="#tab-new-request" >
                                            <FormattedMessage id="text.new-request" />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#tab-notify-approver" >
                                            <FormattedMessage id="text.notify-approver" />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#tab-next-level" >
                                            <FormattedMessage id="text.next-lever" />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#tab-approved" >
                                            <FormattedMessage id="text.approved" />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#tab-denied" >
                                            <FormattedMessage id="text.denied" />
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="tab-new-request" role="tabpanel" >
                                        <EmailTemplateCard {...{ index: 0, register, control, setValue, optionEmailTemplates }} />
                                    </div>
                                    <div className="tab-pane fade" id="tab-notify-approver" role="tabpanel">
                                        <EmailTemplateCard {...{ index: 1, register, control, setValue, optionEmailTemplates }} />
                                    </div>
                                    <div className="tab-pane fade" id="tab-next-level" role="tabpanel">
                                        <EmailTemplateCard {...{ index: 2, register, control, setValue, optionEmailTemplates }} />
                                    </div>
                                    <div className="tab-pane fade" id="tab-approved" role="tabpanel">
                                        <EmailTemplateCard {...{ index: 3, register, control, setValue, optionEmailTemplates }} />
                                    </div>
                                    <div className="tab-pane fade" id="tab-denied" role="tabpanel">
                                        <EmailTemplateCard {...{ index: 4, register, control, setValue, optionEmailTemplates }} />
                                    </div>
                                </div>
                            </div>
                            {/* end col-12 */}
                        </div>
                    </div>
                    <div className="tab-pane fade show" id="tab-turn_around_time" role="tabpanel" >
                        {/* approvalreminderbefore */}
                        <div className="d-flex align-items-center gap-2">
                            <label className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox"
                                    disabled
                                    checked={checkeds[0]}
                                    onChange={(e) => {
                                        let slaconfiguration = watch('slaconfiguration')
                                        if (!e.target.checked) {
                                            slaconfiguration.execution[0].sla_duration = null
                                        }
                                        setValue(`slaconfiguration`, slaconfiguration)
                                        setCheckeds([e.target.checked, checkeds[1], checkeds[2]])
                                        return e
                                    }}
                                />
                            </label>
                            {intl.formatMessage({ id: "text.no-of-days-allotted-for-approving-request-for-each-approver-input-days" }, {
                                input: <div className={clsx('border-bottom', errors.slaconfiguration?.execution
                                    && errors.slaconfiguration?.execution[0]?.sla_duration
                                    ? 'border-danger' : 'border-secondary')}>
                                    <input
                                        readOnly={!checkeds[0]}
                                        className={clsx("form-control form-control-sm border-0 p-0 min-h-20px mh-20px rounded-0 w-50px text-center")}
                                        {...register(`slaconfiguration.execution.0.sla_duration`)} />
                                </div>
                            })}
                        </div>
                        <div className="d-flex align-items-center gap-2 min-h-45px">
                            <label className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" checked={checkeds[1]} onChange={(e) => {
                                    let slaconfiguration = watch('slaconfiguration')
                                    if (!e.target.checked) {
                                        slaconfiguration.execution[0].notification_mails = {
                                            notification_time: null,
                                            notification_time_type: null
                                        }
                                    } else {
                                        slaconfiguration.execution[0].notification_mails = {
                                            notification_time: null,
                                            notification_time_type: 'days',
                                        }
                                    }
                                    setValue(`slaconfiguration`, slaconfiguration)
                                    setCheckeds([checkeds[0], e.target.checked, checkeds[2]])
                                    return e
                                }} />
                            </label>
                            {intl.formatMessage({ id: "text.remind-approver-input-before" }, {
                                input: <>
                                    <div className={clsx('border-bottom', errors.slaconfiguration?.execution
                                        && errors.slaconfiguration?.execution[0]?.notification_mails?.notification_time
                                        ? 'border-danger' : 'border-secondary')}>
                                        <input
                                            readOnly={!checkeds[1]}
                                            className={clsx("form-control form-control-sm border-0 p-0 min-h-20px mh-20px text-center", !checkeds[1] ? 'w-125px' : 'w-50px')}
                                            {...register(`slaconfiguration.execution.0.notification_mails.notification_time`)} />
                                    </div>
                                    {checkeds[1] && <Controller
                                        name={`slaconfiguration.execution.0.notification_mails.notification_time_type`}
                                        control={control}
                                        render={({ field: { value, onChange } }) => <InputSelect
                                            options={optionUnitTime}
                                            value={optionUnitTime.find(f => f.value == value) || null}
                                            onChange={e => onChange(e.value)}
                                            border={false}
                                            className="w-70px border-bottom border-secondary mb-4"
                                        />}
                                    />}

                                </>
                            })}
                        </div>
                        <div className="mb-4 mt-6 fs-5"><FormattedMessage id="text.when-tat-has-elapsed" /></div>
                        <div className="d-flex flex-column ms-10">
                            <div className="text-gray-600 mb-3"><FormattedMessage id="text.intermediate-approver-settings" /></div>
                            <label className="form-check form-check-custom form-check-solid d-flex align-items-center gap-2 mb-4">
                                <input className="form-check-input" type="radio" value={'1'} {...register(`slaconfiguration.execution.0.sla_details.sla_type`)} />
                                <FormattedMessage id="text.move-to-next-approver" />
                            </label>
                            <label className="form-check form-check-custom form-check-solid d-flex align-items-center gap-2">
                                <input className="form-check-input" type="radio" value={'2'} {...register(`slaconfiguration.execution.0.sla_details.sla_type`)} />
                                <FormattedMessage id="text.auto-reject" />
                            </label>
                            <div className="d-flex align-items-center gap-2 h-45px">
                                <label className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" value={'3'} {...register(`slaconfiguration.execution.0.sla_details.sla_type`, {
                                        onChange(event) {
                                            if (event.target.value != '3') {
                                                setValue(`slaconfiguration.execution.0.sla_details.sla_value`, null)
                                            }
                                            return event
                                        },
                                    })} />
                                </label>
                                <FormattedMessage id="text.assign-to" />
                                {watch(`slaconfiguration.execution.0.sla_details.sla_type`) == '3'
                                    ? <div>
                                        <Controller
                                            name={`slaconfiguration.execution.0.sla_details.sla_value`}
                                            control={control}
                                            render={({ field: { value, onChange } }) => <InputSelect
                                                border={false}
                                                className={clsx('min-w-150px border-bottom border-secondary mb-4', {
                                                    'border-danger': errors.slaconfiguration
                                                        && errors.slaconfiguration.execution
                                                        && errors.slaconfiguration.execution[0]?.sla_details?.sla_value
                                                })}
                                                options={optionUsernames}
                                                value={optionUsernames.find((f: any) => f.value == value) || null}
                                                onChange={(e) => {
                                                    if (!e) return onChange(null)
                                                    onChange(e.value)
                                                }}
                                            />}
                                        />
                                    </div>
                                    : <div className="border-bottom border-secondary">
                                        <input readOnly className="form-control form-control-sm border-0 p-0 min-h-20px mh-20px min-w-150px"
                                        // readOnly={watch(`slaconfiguration.execution.0.sla_details.sla_type`) != '3'}
                                        // {...register(`slaconfiguration.execution.0.sla_details.sla_value`)}
                                        // onClick={() => {
                                        //     setSearchType('username')
                                        //     setKeys([[`slaconfiguration.execution.0.sla_details.sla_value`, 'username']])
                                        // }}
                                        />
                                    </div>}
                            </div>
                        </div>
                        {<div className="d-flex flex-column gap-4 ms-10">
                            <div className="mt-2 fs-5"><FormattedMessage id="text.final-approver-settings" /></div>
                            <label className="form-check form-check-custom form-check-solid d-flex align-items-center gap-2">
                                <input className="form-check-input" type="radio" value={'1'} {...register(`slaconfiguration.execution.1.sla_details.sla_type`)} />
                                <FormattedMessage id="text.auto-approve" />
                            </label>
                            <label className="form-check form-check-custom form-check-solid d-flex align-items-center gap-2">
                                <input className="form-check-input" type="radio" value={'2'} {...register(`slaconfiguration.execution.1.sla_details.sla_type`)} />
                                <FormattedMessage id="text.auto-reject" />
                            </label>
                            <label className="form-check form-check-custom form-check-solid d-flex align-items-center gap-2">
                                <input className="form-check-input" type="radio" value={'3'} {...register(`slaconfiguration.execution.1.sla_details.sla_type`, {
                                    onChange(event) {
                                        if (event.target.value != '3') {
                                            setCheckeds([checkeds[0], checkeds[1], false])
                                            setValue(`slaconfiguration.execution.1.sla_duration`, null)
                                        }
                                        return event
                                    },
                                })} />
                                <FormattedMessage id="text.request-will-remain-pending-approval" />
                            </label>
                            {watch(`slaconfiguration.execution.1.sla_details.sla_type`) == '3'
                                && <div className="d-flex align-items-center gap-2 mt-4">
                                    <label className="form-check form-check-custom form-check-solid ">
                                        <input className="form-check-input" type="checkbox" checked={checkeds[2]}
                                            onChange={e => {
                                                let slaconfiguration = watch('slaconfiguration')
                                                if (!e.target.checked) {
                                                    slaconfiguration.execution[1].sla_duration = null
                                                }
                                                setValue(`slaconfiguration`, slaconfiguration)
                                                setCheckeds([checkeds[0], checkeds[1], e.target.checked])
                                                return e
                                            }} />
                                    </label>
                                    {intl.formatMessage({ id: "text.remind-approver-once-in-input-days-when-request-is-still-pending-for-approval", }, {
                                        input: <div className={clsx('border-bottom', errors.slaconfiguration?.execution
                                            && errors.slaconfiguration?.execution[1]?.sla_duration
                                            ? 'border-danger' : 'border-secondary')}>
                                            <input readOnly={!checkeds[2]} className="form-control form-control-sm border-0 p-0 min-h-20px mh-20px w-50px text-center"
                                                {...register(`slaconfiguration.execution.1.sla_duration`)} />
                                        </div>
                                    })}
                                </div>}
                        </div>}
                    </div>
                </div>
            </div>
        </form>
        <InputDate className="d-none" value={''} onChange={(e) => { }} />
        <SearchPopup
            {...{
                values: ids,
                fieldname: searchType == 'email' ? 'contactemail' : undefined,
                isMulti: searchType == 'email',
                isLoading: loadUsers,
                keys,
                setValue,
                searchData: optionsUser || [],
                handleClose: function () { setKeys([]) }
            }} />
    </>
}
