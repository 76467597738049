import Swal from 'sweetalert2'
// import withReactContent, { ReactElementOr } from 'sweetalert2-react-content'
import { localMessages } from '../../../i18n'
import { enMessages } from '../../../i18n/messages/en'

// const MySwal = withReactContent(Swal.mixin({
//     showConfirmButton: false
// }))
const isShowSwal = Swal.isVisible()

export function loadMessageI18n({ key }: { key: keyof typeof enMessages }) {
    const lang = document.getElementsByTagName('html')[0].getAttribute('lang');
    const data = localMessages[lang || 'vi']

    return data[key] || key
}

export function popupClose() {
    Swal.close()
}

type SwalIcon = "success" | "error" | "warning" | "info" | "question"

export async function popupMessage(option: { icon: SwalIcon, description?: string | undefined, html?: any, autoClose?: boolean, confirmButtonText?: string, width?: any }) {
    if (isShowSwal) return undefined
    const { icon, description, html, autoClose, confirmButtonText, width } = option

    const success_text = loadMessageI18n({ key: "message.popup.success" })
    const error_text = loadMessageI18n({ key: "message.popup.error" })
    const isClose = autoClose != undefined && icon != "question"
    await Swal.fire({
        width,
        icon,
        html,
        text: description || (icon == "success" ? success_text : icon == "error" ? error_text : "..."),
        timer: isClose ? 1500 : undefined,
        // showCloseButton: icon == 'error',
        showConfirmButton: ['question', 'info'].includes(icon),
        showCancelButton: ['question', 'error'].includes(icon),
        confirmButtonText: confirmButtonText || loadMessageI18n({ key: "message.popup.button.ok" }),
        cancelButtonText: icon == 'error' ? loadMessageI18n({ key: "message.popup.button.close" }) : loadMessageI18n({ key: "message.popup.button.cancel" }),
        customClass: {
            confirmButton: "btn btn-sm btn-primary bg-primary order-2",
            cancelButton: "btn btn-sm btn-secondary bg-secondary order-1",
        },
    })
}

export async function popupDelete() {
    const result = await Swal.fire({
        text: loadMessageI18n({ key: "message.question.delete" }),
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: loadMessageI18n({ key: "message.popup.button.ok" }),
        cancelButtonText: loadMessageI18n({ key: "message.popup.button.cancel" }),
        customClass: {
            confirmButton: "btn btn-sm btn-primary order-2",
            cancelButton: "btn btn-sm btn-danger bg-danger order-1",
        },
    });
    return result.isConfirmed
}

export const popupLoading = () => {
    if (isShowSwal) return undefined
    const loading_text = loadMessageI18n({ key: "message.popup.loading" })
    Swal.fire({
        html: `<div class='custom-loading'><span>${loading_text}</span> <span class='loader'></span></div>`,
        buttonsStyling: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
    })
}

export async function popupQuestion(option: { title?: string, description?: string, confirmButtonText?: string, cancelButtonText?: string, denyButtonText?: string, denyButton?: string, confirmButton?: string, cancelButton?: string, showDenyButton?: boolean }) {
    const result = await Swal.fire({
        title: option.title || "",
        text: option.description || "",
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        showDenyButton: option.showDenyButton,
        confirmButtonText: option.confirmButtonText ? option.confirmButtonText : "Thực hiện",
        cancelButtonText: option.cancelButtonText ? option.cancelButtonText : "Hủy bỏ",
        denyButtonText: option.denyButtonText ? option.denyButtonText : "Hủy bỏ",
        customClass: {
            confirmButton: `btn btn-sm ${option.confirmButton ? option.confirmButton : "btn-primary"} order-2`,
            cancelButton: `btn btn-sm ${option.cancelButton ? option.cancelButton : "btn-secondary bg-secondary"} order-1`,
            denyButton: `btn btn-sm ${option.denyButton ? option.denyButton : "btn-danger bg-danger"} order-3`,
        },
    });
    if (result.isConfirmed) {
        return true;
    }

    return false
}

export async function popupCustomQuestion(option: { title?: string, description?: string, confirmButtonText?: string, cancelButtonText?: string, denyButtonText?: string, denyButton?: string, confirmButton?: string, cancelButton?: string, showDenyButton?: boolean }) {
    const result = await Swal.fire({
        title: option.title || "",
        text: option.description || "",
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        showDenyButton: option.showDenyButton,
        confirmButtonText: option.confirmButtonText ? option.confirmButtonText : "Thực hiện",
        cancelButtonText: option.cancelButtonText ? option.cancelButtonText : "Hủy bỏ",
        denyButtonText: option.denyButtonText ? option.denyButtonText : "Hủy bỏ",
        customClass: {
            confirmButton: `btn btn-sm ${option.confirmButton ? option.confirmButton : "btn-primary"} order-2`,
            cancelButton: `btn btn-sm ${option.cancelButton ? option.cancelButton : "btn-secondary bg-secondary"} order-1`,
            denyButton: `btn btn-sm ${option.denyButton ? option.denyButton : "btn-danger bg-danger"} order-3`,
        },
    });
    return result
}

export async function popupInputHTML(option: { icon?: "info", title?: string, html?: string | HTMLElement | JQuery | undefined, footer?: string | HTMLElement | JQuery | undefined }) {
    let messageText = ''
    const result = await Swal.fire({
        ...option,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Thực hiện",
        cancelButtonText: "Hủy bỏ",
        customClass: {
            confirmButton: "btn btn-sm btn-primary order-2",
            cancelButton: "btn btn-sm btn-secondary bg-secondary order-1",
        },
        preConfirm: (message: any) => {
            messageText = message
        },
    });
    if (result.isConfirmed) {
        return messageText;
    }
    return false;
}
