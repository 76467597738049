import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { IStore } from ".";
import { ApiService, ApiShowError, changeDataModify, getValueId } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { ExtendAddressValue, FormSubmit, InputSelect, KTFormItem, TextFormByUser, yup } from "../../../../theme/partials";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";
import { apiPrivate } from "../../../modules/api";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";

const defaultValues: IStore = {
    storetype: null,
    code: null,
    storename: null,
    locationcode: null,
    address: null,
    ward: null,
    district: null,
    zipcode: null,
    province: null,
    state: null,
    country: null,
    phoneno: null,
    email: null,
    managerid: null,
    managername: null,
    openhours: null,
    closedhours: null,
    latitude: null,
    longitude: null,
    status: false
}

export const optionStoreType = [
    { value: 'store', label: 'Store' },
    { value: 'head_office', label: 'Head Office' },
]

const urlApi = 'settings/stores'
export const SettingStoresForm: FC = ({ keyElement, pathElement, permission }: MergedProps) => {
    const intl = useIntl()
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching } = apiPrivate.useStoresId<IStore>(valueid)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IStore>({
        defaultValues,
        resolver: yupResolver( yup.object().shape({
            // code: yup.string().required(),
            managerid: yup.string().nullable(true).transform(val => val ? val : null)
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IStore) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.type'>
                            <Controller
                                name="storetype"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    disabled={isEdit}
                                    options={optionStoreType}
                                    value={optionStoreType.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                    className={clsx("w-100", { 'form-error': errors.storetype })}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.id'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.storename && 'form-error'}`} {...register('storename')} />
                        </KTFormItem>
                        <KTFormItem title='text.manager.id'>
                            <input className={`form-control form-control-sm ${errors.managerid && 'form-error'}`} {...register('managerid')} />
                        </KTFormItem>
                        <KTFormItem title='text.manager.name'>
                            <input className={`form-control form-control-sm ${errors.managername && 'form-error'}`} {...register('managername')} />
                        </KTFormItem>
                        <KTFormItem title='text.phone'>
                            <input className={`form-control form-control-sm ${errors.phoneno && 'form-error'}`} {...register('phoneno')} />
                        </KTFormItem>
                        <KTFormItem title='text.email'>
                            <input className={`form-control form-control-sm ${errors.email && 'form-error'}`} {...register('email')} />
                        </KTFormItem>
                        <KTFormItem title='text.open-hours'>
                            <input className={`form-control form-control-sm ${errors.openhours && 'form-error'}`} {...register('openhours')} />
                        </KTFormItem>
                        <KTFormItem title='text.closed-hours'>
                            <input className={`form-control form-control-sm ${errors.closedhours && 'form-error'}`} {...register('closedhours')} />
                        </KTFormItem>
                        <KTFormItem title='Vat bus posting group'>
                            <input disabled className={`form-control form-control-sm ${errors.vatbuspostinggroup && 'form-error'}`} {...register('vatbuspostinggroup')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.location.id'>
                            <input disabled={isEdit} className={`form-control form-control-sm ${errors.locationcode && 'form-error'}`} {...register('locationcode')} />
                        </KTFormItem>
                        <ExtendAddressValue {...{
                            register,
                            control,
                            errors,
                            setValue,
                            watch
                        }} />
                        <KTFormItem title='text.latitude'>
                            <input className={`form-control form-control-sm ${errors.latitude && 'form-error'}`} {...register('latitude')} />
                        </KTFormItem>
                        <KTFormItem title='text.longitude'>
                            <input className={`form-control form-control-sm ${errors.longitude && 'form-error'}`} {...register('longitude')} />
                        </KTFormItem>
                        <KTFormItem title='text.status'>
                        <span className={`badge badge-${watch('status') ? 'success' : 'danger'}`}>
                                {watch('status') 
                                ? intl.formatMessage({ id: "text.active"}) 
                                : intl.formatMessage({ id: "text.blocked"})}
                            </span>
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
    </>
}