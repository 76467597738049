import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'

export function MenuInner() {
  const intl = useIntl()
  
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'header.title'})} to='/' />
      <MenuItem title={intl.formatMessage({ id: 'header.menu.merchant-console'})} to='#' />
    </>
  )
}
