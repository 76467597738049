import { FC } from "react"
import { WithChildren } from "../../../helpers"
import clsx from "clsx"

interface IExtendClearValue extends WithChildren {
    handleClear: () => void
    hide?: boolean
    className?: string
}

export const ExtendClearValue: FC<IExtendClearValue> = ({ children, handleClear, hide, className }) => {
    return <div className={clsx("position-relative w-100", className)}>
        {children}
        <div className={clsx('position-absolute top-0 end-0 h-100 d-flex flex-center p-2', { 'd-none': hide })}>
            <i className="bi bi-x cursor-pointer fs-1" onClick={handleClear} />
        </div>
    </div>
}