import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { IFnbTag } from ".";
import { ApiService, ApiShowError, changeDataModify, getValueId } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { FormSubmit, InputSingleImage, KTFormItem, TextFormByUser, yup } from "../../../../theme/partials";
import { apiFnb } from "../../../modules/api/fnb";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";

const defaultValues: IFnbTag = {
    code: null,
    name: null,
    icon: null,
    image: null,
}

export function FNBTagsForm({ keyElement, pathElement, permission }: MergedProps) {
    const urlApi = 'fnb/tags'
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching } = apiFnb.useTagsId<IFnbTag>(valueid)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IFnbTag>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            name: yup.string().required()
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching) reset(data)
    }, [isFetching])

    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IFnbTag) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.icon' isHeight>
                            <Controller
                                name="icon"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSingleImage
                                    size="0.5:0.5"
                                    value={value}
                                    onChange={onChange} />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.image' isHeight>
                            <Controller
                                name="image"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSingleImage
                                    size="1.25:1.25"
                                    value={value}
                                    onChange={onChange} />}
                            />
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
    </>
}