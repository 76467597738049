import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { useParams } from "react-router-dom";
import { ApiService, formatCurrent, getTextCurrent, jsUcfirst } from "../../../theme/helpers";
import { KTCard, KTSVG } from "../../../theme/partials";
import { useAuth } from "../../modules/auth";
import { KTItem } from "./component";

const AdvancePrint = () => {
    
    const { id } = useParams()
    const [dataApi, setDataApi] = useState<any>({})
   
    // console.log('id',id)

    async function getData(data:any) {
        let tempData = data as any
        setDataApi(tempData)
        setTimeout(function () { window.print(); }, 500);
        window.onafterprint = function(event) { window.close() };
    }
    useEffect(() => { 
        ApiService.get(`employees/employeeadvancerequests/${id}/?expand=employeeadvancerequestlines&isincluderequestuser=true`)
        .then(({ data }) => {
            getData(data)
        })
        .catch(({ response }) => {
          console.log("err", response);
        });
    }, [])
    const {auth} = useAuth()
    const {user} = auth || {}
    
    return <>
        <div className={clsx('position-relative ',{'container-fluid': isTablet})}>
            <div className="position-relative print-pdf">
                <KTCard>
                    <div className="container-fluid">
                        <div className="row g-0">
                            <div className="col-12 text-center">
                                <img className="w-100px" src="/media/images/logo-tnf-black-1.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0 mt-10">
                        <div className="container-fluid">
                            {dataApi && 
                            <div className="row mb-4">
                                <div className="col-12 mb-4">
                                    <h2 className="text-center">PHIẾU ĐỀ NGHỊ TẠM ỨNG</h2>
                                    {/* <div className="text-center">{dataTable?.ses_termname}<KTSVG path='/media/icons/duotune/arrows/arr089.svg' className='svg-icon text-black svg-icon-1x' />{dataTable?.academicyearname}</div> */}
                                    {/* <div className="fst-italic text-center">{dataTable?.ses_termnameen}<KTSVG path='/media/icons/duotune/arrows/arr089.svg' className='svg-icon text-black svg-icon-1x' />{dataTable?.academicyearnameen}</div> */}
                                </div>


                                <div className="text-end">
                                    {
                                        dataApi.status != '284210002' &&
                                        <div className="btn btn-sm border-1 border-solid border border-black mb-3 fw-bold">
                                            {dataApi.status != '284210003' ?'Bản nháp':'Đã bị từ chối'}
                                        </div>
                                    }
                                    {/* <div>
                                        <span className="me-1">Số hiệu: </span>
                                        <span>{dataApi.documentno}</span>
                                    </div> */}
                                    <div>
                                        <span className="me-1">Ngày: </span>
                                        <span>{dataApi.createdat && new Date(dataApi.createdat).toLocaleDateString('en-Gb',{ day: '2-digit', month: '2-digit', year: 'numeric'})}</span>
                                    </div>
                                    <div>
                                        <span className="me-1">Số: </span>
                                        <span>{dataApi.documentno}</span>
                                    </div>
                                    <div>
                                        <span className="me-1">Người in: </span>
                                        <span>{dataApi.requestuser?.fullname}</span>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            }
                            <div className="row">
                                <div className="col-12">
                                    <table className="table-print w-100">
                                        <tbody className="">
                                            
                                          <tr>
                                            <td className="w-125px p-1">
                                                {dataApi.documenttype == '284210000' && <>Mã nhà cung cấp</>}
                                                {dataApi.documenttype == '284210001' && <>Họ và tên</>}
                                                {dataApi.documenttype == '284210002' && <>Mã khách hàng</>}
                                            </td>
                                            <td className="p-1">
                                                {dataApi.documenttype == '284210001' ? dataApi.sourcename:dataApi.sourceno}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="w-125px p-1">
                                                <div className="d-flex gap-5 justify-content-between">
                                                {dataApi.documenttype == '284210000' && <>Tên nhà cung cấp</>}
                                                {dataApi.documenttype == '284210001' && <>Bộ phận</>}
                                                {dataApi.documenttype == '284210002' && <>Tên khách hàng</>}
                                                </div>
                                            </td>
                                            <td>
                                                {dataApi.documenttype == '284210001' ? <div className="d-flex gap-5 justify-content-between">
                                                    <div className="p-1">{dataApi.departmentname}</div>
                                                    <KTItem labelWidth="50" className="mb-0 line-after min-w-250px" isHeight title='Chức vụ'>{dataApi.sourcetitle}</KTItem>
                                                </div>
                                                :
                                                <div>
                                                    {dataApi.sourcename}
                                                </div>
                                                }
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="w-125px p-1">
                                            Số tiền tạm ứng
                                            </td>
                                            <td className="p-1">
                                            {formatCurrent(dataApi.totalamount)}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="w-125px p-1">
                                            Bằng chữ
                                            </td>
                                            <td className="p-1">
                                            {getTextCurrent(dataApi.totalamount) && jsUcfirst(getTextCurrent(dataApi.totalamount))} đồng
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="w-125px p-1">
                                            Lý do
                                            </td>
                                            <td className="p-1">
                                                <div>{dataApi.description && jsUcfirst(dataApi.description)}</div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="w-125px p-1">
                                            Ngày cần thực hiện
                                            </td>
                                            <td className="p-1">
                                            {dataApi.requireddate && new Date(dataApi.requireddate).toLocaleDateString('en-Gb',{ day: '2-digit', month: '2-digit', year: 'numeric'})}
                                            </td>
                                          </tr>
                                        </tbody>
                                        <tfoot className="visibility-hidden">
                                            <tr className="">
                                                <td className="border-0" colSpan={10}>
                                                    <div className="page-footer-space h-20px">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="row g-0 page-break-inside ">
                                <div className="col-6">
                                    <div className="">
                                        Hình thức tạm ứng: {dataApi.paymentmethodcode == '284210000' ? 'Tiền mặt':'Chuyển khoản'}
                                    </div>
                                    <div className="">
                                        Tên tài khoản: {dataApi.paymentmethodcode != '284210000' && dataApi.bankaccountname}
                                    </div>
                                    <div className="">
                                        Số tài khoản: {dataApi.paymentmethodcode != '284210000' && dataApi.bankaccountno}
                                    </div>
                                    <div className="">
                                        Ngân hàng: {dataApi.paymentmethodcode != '284210000' && dataApi.bankname}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="">
                                        Hình thức trả: {dataApi.subtractsalaryamount && 'Cấn trừ lương'}
                                    </div>
                                    <div>- Trừ lương/ Tháng</div>
                                    <div className="">
                                        Trừ dần vào mỗi tháng là: 
                                    </div>
                                    <div className="">
                                        Bằng chữ:
                                    </div>
                                    <div>- Quyết toán theo chi phí phát sinh: x</div>
                                </div>

                                {
                                    dataApi.status == '284210002' &&
                                    <div className="col-12 mt-8 page-break-avoid">
                                        <div className="row g-0">
                                            <div className="col-3 text-center">
                                                <div className="border-bottom-0 p-1 border border-black">Người đề nghị</div>
                                                <div className="h-150px p-1 border border-black">
                                                    {
                                                       dataApi.requestuser?.signature && <img className="object-contant w-100 h-100" src={dataApi.requestuser.signature} alt="" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-3 text-center">
                                                <div className="border-bottom-0 border-left-0 p-1 border border-black">Trưởng bộ phận</div>
                                                <div className="h-150px p-1 border border-left-0 border-black"></div>
                                            </div>
                                            <div className="col-3 text-center">
                                                <div className="border-bottom-0 p-1 border-left-0 border border-black">Kế toán trưởng</div>
                                                <div className="h-150px p-1 border border-left-0 border-black"></div>
                                            </div>
                                            <div className="col-3 text-center">
                                                <div className="border-bottom-0 p-1 border-left-0 border border-black">Giám đốc</div>
                                                <div className="h-150px p-1 border border-left-0 border-black"></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </KTCard>
               
            </div>
            
            
        </div>
    </>
}

export default AdvancePrint;