import { FC } from 'react';
import { AuthModel, getAuth, useAuth } from '.';
import { WithChildren } from '../../../theme/helpers';
import { decryptData } from '../../../theme/helpers/utils';

export const framePermission: any = [{
    value: 'DELETE', code: 'deletepermission'
}, {
    value: 'EXECUTE', code: 'executepermission'
}, {
    value: 'INSERT', code: 'insertpermission'
}, {
    value: 'MODIFY', code: 'modifypermission'
}, {
    value: 'READ', code: 'readpermission'
}]

function getPermissions(auth: AuthModel | undefined, permission: string) {
    let userPermissions: any = decryptData(auth?.permissions as any)
    userPermissions = JSON.parse(userPermissions)
    return userPermissions.filter((item: any) => item.code == permission)
}

function getRoles(auth: AuthModel | undefined) {
    if (!auth || !auth.user || !auth.user.rolecode) return undefined
    if (!Array.isArray(auth.user.rolecode) || auth.user.rolecode.length == 0) return undefined
    return auth.user.rolecode.map(m => decryptData(m))
}

interface IShowForPermission extends WithChildren {
    permission: string[]
}

export const ShowForPermission: FC<IShowForPermission> = (props) => {
    const authData = getAuth()
    const roles = getRoles(authData)
    if (!roles?.length) return <></>
    if (roles.includes('SUPER')) return <>{props.children}</>
    let couldShow = false
    let listPermission = getPermissions(authData, props.permission[0])
    if (listPermission && listPermission.length != 0) {
        const permissions = listPermission[0].permissions
        if (permissions && permissions instanceof Array) {
            if (props.permission && props.permission.length > 0) {
                couldShow = permissions.some(per => [props.permission[1]].includes(per))
            }
        }
    }
    return <>{couldShow && props.children}</>
};

// ['10001', 'UPDATE', 'DELETE]
export const getIsPermission = (permission: string[], type?: 'or' | 'and') => {
    const authData = getAuth()
    const roles = getRoles(authData)
    if (!roles?.length) return false
    if (roles.includes('SUPER')) return true
    let listPermission = getPermissions(authData, permission[0])
    if (listPermission && listPermission.length != 0) {
        const permissions = listPermission[0].permissions
        if (permissions && permissions instanceof Array) {
            if (permission && permission.length > 0) {
                const permissionRoles = permission.filter((e: any, i: number) => i != 0)
                return type == 'or'
                    ? permissionRoles.some(per => permissions.includes(per))
                    : permissionRoles.every(per => permissions.includes(per))
            }
        }
    }
    return false
}

interface IShowForRole extends WithChildren { role: string[] }
export const ShowForRole: FC<IShowForRole> = ({ role, children }) => {
    const authData = getAuth()
    const roles = getRoles(authData)
    if (!roles?.length) return <></>
    if (roles.includes('SUPER') || roles.some(s => role.includes(s as string))) return <>{children}</>

    return <></>
};

export const getIsRole = (role: string[]) => {
    const authData = getAuth()
    const roles = getRoles(authData)
    if (!roles?.length) return false
    if (roles.includes('SUPER') || roles.some(s => role.includes(s as string))) return true

    return false
}