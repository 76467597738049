import { yupResolver } from "@hookform/resolvers/yup"
import { JSXElementConstructor, ReactElement, useEffect, useState } from "react"
import { Controller, ControllerFieldState, ControllerRenderProps, FieldValues, useForm, UseFormStateReturn } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { Column } from "react-table"
import { ApiService, ApiShowError, changeDataModify, getValueId, sortByUpdateAt } from "../../../../theme/helpers"
import { FormattedMessage } from "react-intl";
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, ExtendAddressValue, FilterDropdown, FormSubmit, InputSelect, InputSingleImage, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../theme/partials"
import { IInitFieldAddress } from "../../api"
import { apiAd } from "../../api/admin"
import { popupMessage } from "../../messages"
import clsx from "clsx"
import { Card } from "react-bootstrap"

export interface IAdCompany extends IInitFieldAddress {
    name: string | null
    taxregistrationno: string | null
    phone: string | null
    email: string | null
    contactname: string | null
    picture?: any
    homepage: string | null
    bankname: string | null
    bankbranch: string | null
    bankbranchno: string | null
    bankaccountno: string | null
    beneficiaryname: string | null
    swiftcode: string | null
    systemindicator: string | null
    customsystemindicator: string | null
    systemindicatorstyle: string | null
}

const breadCrumbs = [{ title: 'system', path: '/system-settings', }]
const apiurl = 'settings/companyinformations'
export default function AdCompanysPage() {
    const { data, isSuccess, isFetching } = apiAd.useCompanyInformations()
    const [tableData, setTableData] = useState<IAdCompany[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.address' />,
        accessor: 'address',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].address} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.phone' />,
        accessor: 'phone',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].phone} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" to={props.data[props.row.index].id} />
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <ContentHeader title="system.companies" items={breadCrumbs} />
        <KTTable
            isLoading={isFetching}
            toolbar={<>
                <FilterDropdown>
                </FilterDropdown>
                <KTButton action='new' />
            </>}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search />
    </>
}

const defaultValues: IAdCompany = {
    name: null,
    address: null,
    ward: null,
    district: null,
    country: null,
    taxregistrationno: null,
    phone: null,
    email: null,
    contactname: null,
    picture: null,
    homepage: null,
    bankname: null,
    bankbranch: null,
    bankbranchno: null,
    bankaccountno: null,
    beneficiaryname: null,
    swiftcode: null,
    province: null,
    zipcode: null,
    state: null,
    customsystemindicator: null,
    systemindicator: null,
    systemindicatorstyle: null
}

const schema = yup.object().shape({
    name: yup.string().required(),
});

export const AdCompanysDetailPage = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching, refetch } = apiAd.useCompanyInformationsId<IAdCompany>(valueid)
    const { register, reset, control, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IAdCompany>({
        defaultValues,
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    const optionIndicator = [
        { value: "none", label: "None" },
        { value: "custom", label: "Custom" }
    ]

    const optionStyle = [
        { value: "dark-blue", label: "Dark Blue" },
        { value: "light-blue", label: "Light Blue" },
        { value: "dark-green", label: "Dark Green" },
        { value: "light-green", label: "Light Green" },
        { value: "dark-yellow", label: "Dark Yellow" },
        { value: "light-yellow", label: "Light Yellow" },
        { value: "red", label: "Red" },
        { value: "orange", label: "Orange" },
        { value: "deep-purple", label: "Deep Purple" }
    ]

    return <>
        <ContentHeader title="system.companies" isInfo items={[...breadCrumbs, { title: 'system.companies', path: '/system-settings/companies' }]} />
        <form
            className=""
            onSubmit={handleSubmit(async (data: IAdCompany) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate('/system-settings/companies')
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card mb-6">
                <div className="card-header">
                    <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                    <div className="card-toolbar">
                        <TextFormByUser data={watch()} />
                        <FormSubmit
                            type={isEdit}
                            isSubmitting={isSubmitting}
                            handleClose={() => navigate('/system-settings/companies')} />
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <KTFormItem title='text.name'>
                                <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                            </KTFormItem>
                            <ExtendAddressValue {...{
                                register,
                                control,
                                errors,
                                setValue,
                                watch
                            }} />
                            <KTFormItem title='text.phone'>
                                <input className={`form-control form-control-sm ${errors.phone && 'form-error'}`} {...register('phone')} />
                            </KTFormItem>
                            <KTFormItem title='text.email'>
                                <input className={`form-control form-control-sm ${errors.email && 'form-error'}`} {...register('email')} />
                            </KTFormItem>
                            <KTFormItem title='text.website'>
                                <input className={`form-control form-control-sm ${errors.homepage && 'form-error'}`} {...register('homepage')} />
                            </KTFormItem>
                            <KTFormItem title='text.contact.name'>
                                <input className={`form-control form-control-sm ${errors.contactname && 'form-error'}`} {...register('contactname')} />
                            </KTFormItem>
                        </div>
                        <div className="col-12 col-lg-6">
                            <KTFormItem title='text.tax-registration-no'>
                                <input className={`form-control form-control-sm ${errors.taxregistrationno && 'form-error'}`} {...register('taxregistrationno')} />
                            </KTFormItem>
                            <KTFormItem title='text.image' isHeight>
                                <Controller
                                    name="picture"
                                    control={control}
                                    render={({ field: { value, onChange } }) => <InputSingleImage
                                        value={value}
                                        onChange={onChange} />}
                                />
                            </KTFormItem>
                            <div className="py-6 mt-4 fw-bold"><FormattedMessage id="text.bank.info" /></div>
                            <KTFormItem title='text.bank.name'>
                                <input className={`form-control form-control-sm ${errors.bankname && 'form-error'}`} {...register('bankname')} />
                            </KTFormItem>
                            <KTFormItem title='text.branch.id'>
                                <input className={`form-control form-control-sm ${errors.bankbranchno && 'form-error'}`} {...register('bankbranchno')} />
                            </KTFormItem>
                            <KTFormItem title='text.branch.name'>
                                <input className={`form-control form-control-sm ${errors.bankbranch && 'form-error'}`} {...register('bankbranch')} />
                            </KTFormItem>
                            <KTFormItem title='text.account.no'>
                                <input className={`form-control form-control-sm ${errors.bankaccountno && 'form-error'}`} {...register('bankaccountno')} />
                            </KTFormItem>
                            <KTFormItem title='text.beneficiary.name'>
                                <input className={`form-control form-control-sm ${errors.beneficiaryname && 'form-error'}`} {...register('beneficiaryname')} />
                            </KTFormItem>
                            <KTFormItem title='text.swift-code'>
                                <input className={`form-control form-control-sm ${errors.swiftcode && 'form-error'}`} {...register('swiftcode')} />
                            </KTFormItem>
                        </div>
                    </div>
                </div>
            </div>

            <Card>
                <div className="card-header">
                    <h3 className="card-title"><FormattedMessage id="text.company-badge.title" /></h3>
                </div>
                <div className="row p-4">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.company-badge'>
                            <Controller
                                name="systemindicator"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    className={clsx({ "form-error": errors.systemindicator })}
                                    options={optionIndicator}
                                    value={optionIndicator.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if(!e || e.value == 'none') {
                                            setValue('customsystemindicator', null)
                                        }
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                />} />
                        </KTFormItem>
                        <KTFormItem title='text.company-badge.style'>
                            <Controller
                                name="systemindicatorstyle"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    className={clsx({ "form-error": errors.systemindicatorstyle })}
                                    options={optionStyle}
                                    value={optionStyle.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                    }}
                                />} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.company-badge.text'>
                            <input disabled={!watch("systemindicator") || watch("systemindicator") == 'none'} className={`form-control form-control-sm ${errors.customsystemindicator && 'form-error'}`} {...register('customsystemindicator')} />
                        </KTFormItem>
                    </div>
                </div>
            </Card>
        </form>
    </>
}
