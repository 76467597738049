export const viMessages = {
    //Messages api with code
    "message.api.error.401": "Unauthorized",
    "message.api.error.401.description": "You are not authorized to access this resource. Please contact your administrator.",
    "message.api.error.403": "Forbidden",
    "message.api.error.403.description": "You are not authorized to access this resource. Please contact your administrator.",
    "message.api.error.404": "Not found",
    "message.api.error.404.description": "The resource you are looking for might have been removed, had its name changed, or is temporarily unavailable.",
    "message.api.error.500": "Internal server error",
    "message.api.error.500.description": "The server encountered an internal error and was unable to complete your request. Please contact your administrator.",
    "message.error.something-wrong": "Something went wrong.",
    "text.return-home": "Return home",
    "text.return-login": "Return login",
    "message.error.token-expired": "Token invalid or expired. Please login again.",
    //Messages with popup
    "message.popup.success": "Thông tin đã lưu thành công!",
    "message.popup.error": "Có lỗi xảy ra",
    "message.popup.loading": "Loading...",
    "message.popup.button.ok": "Đồng ý",
    "message.popup.button.cancel": "Hủy bỏ",
    "message.popup.button.close": "Đóng",
    "message.question.sync-bc": "Confirm synchronization of this table data with bc.",
    "message.question.clone": "Bạn có muốn nhân bản dữ liệu này?",
    "message.question.delete": "Bạn thật sự muốn xóa dữ liệu này?",
    "message.question.download": "Do you want to download this file?",
    "message.delete.dependencies-exist": "Thật đáng tiếc, bạn không thể xóa dữ liệu vào thời điểm này do có sự ràng buộc.",
    "message.error.user-not-found": "Không tìm thấy người dùng.",
    //Header
    "header.title": "iRMS",
    "header.avatar.my-profile": "Hồ sơ của tôi",
    "header.avatar.logout": "Đăng xuất",
    //Hearder Menu
    "header.menu.merchant-console": "Merchant Console",
    //Login
    "login": "Đăng nhập",
    "login.username": "Tên đăng nhập",
    "login.password": "Mật khẩu",
    "login.more": "iRMS Hệ thống quản lý bán lẻ",
    "login.when-click-login": "Bằng việc {login} bạn đã đồng ý với {service_agreement}.",
    "login.service-agreement": "Thỏa thuận bảo mật",
    "text.login.or": "HOẶC",
    "login.required": "Bắt buộc nhập",
    "login.field-required": "Bắt buộc nhập",
    "login.max-character": "Vui lòng nhập không quá 50 ký tự",
    "login.min-character": "Vui lòng nhập ít nhất 3 ký tự",
    "login.forgot-password": "Quên mật khẩu",
    "login.send-request": "Gửi yêu cầu",
    "login.back-to-login": "Quay lại đăng nhập",
    "login.wrong-email-format": "Wrong email format",
    //Login - Azure
    "login.update-info": "Vui lòng bổ sung thông tin để hoàn tất!",
    "login.confirm": "Xác nhận",
    "login.sign-in-with-microsoft": "Đăng nhập với Microsoft",
    //Sys Menus
    "system": "Cài đặt hệ thống",
    "system.general": "Thông tin chung",
    "system.general.more": "Thiết lập các thông tin cơ bản dành cho hệ thống.",
    "system.users": "Người dùng",
    "system.users.more": "Tại mục này, bạn có thể quản lý ai đang truy cập hệ thống. Khởi tạo các quyền cho người dùng ban đầu.",
    "system.companies": "Công ty",
    "system.companies.more": "Thông tin công ty.",
    "system.email-setup": "Cài đặt email",
    "system.email-setup.more": "Cấu hình máy chủ email.",
    "system.user-setups": "Cài đặt người dùng",
    "system.user-setups.more": "Bạn có thể thiết lập giới hạn phê duyệt theo số tiền chỉ định, định nghĩa người phê duyệt thay thế khi người phê duyệt đi vắng.",
    "system.permission-sets": "Phân quyền",
    "system.permission-sets.more": "Cấu hình phân quyền hệ thống.",
    "system.objects": "Objects",
    "system.objects.more": "Specifies the type of object that the permissions apply to in the current database.",
    "system.zalo-oa": "Zalo OA",
    "system.zalo-oa.more": "Zalo OA Configuration.",
    "system.sms-setup": "SMS setup",
    "system.sms-setup.more": "SMS Configuration.",
    "system.approvals": "Phê duyệt nâng cao",
    "system.approvals.more": "Cấu hình các luồng phê duyệt nâng cao dành cho hệ thống.",
    "system.auto-generate-database": "Auto generate database",
    "system.auto-generate-database.more": "Backup files",
    "system.auto-generate-database.question.generate": "Are you create backup file.",
    "system.user-groups": "User groups",
    "system.user-groups.more": "User groups configuration.",
    "system.general-settings": "General settings",
    "system.general-settings.more": "General settings configuration.",
    //Sidebar
    "sidebar.dashboard": "Dashboard",
    "sidebar.requests": "Self-Service",
    "sidebar.requests.order": "Đề xuất",
    "sidebar.requests.advance": "Đề nghị tạm ứng",
    "sidebar.requests.payment": "Đề nghị thanh toán",
    "sidebar.catalogue": "Danh mục",
    "sidebar.catalogue.items": "Sản phẩm",
    "sidebar.catalogue.vendors": "Nhà cung cấp",
    "sidebar.catalogue.customers": "Khách hàng",
    "sidebar.catalogue.expenses": "Hạng mục phí",
    "sidebar.catalogue.stores": "Cửa hàng",
    "sidebar.catalogue.budgets": "Budget",
    "sidebar.catalogue.departments": "Phòng ban",
    "sidebar.catalogue.dimensions": "Dimensions",
    "sidebar.fnb": "FnB",
    "sidebar.fnb.categories": "Categories",
    "sidebar.fnb.sub-categories": "Sub Categories",
    "sidebar.fnb.menu-structures": "Menu Structures",
    "sidebar.fnb.hospitality-items": "Hospitality Items",
    "sidebar.fnb.tags": "Tags",
    "sidebar.fnb.customizations": "Customizations",
    "sidebar.fnb.time-based-menus": "Time based Menus",
    "sidebar.fnb.merchants": "Merchants",
    "sidebar.discounts": "Discounts",
    "sidebar.discounts.promotions": "Promotions",
    "sidebar.discounts.coupons": "Coupons",
    "sidebar.configuration": "Configuration",
    "sidebar.configuration.store-tier": "Store Tier",
    "sidebar.configuration.menu-structure-tier": "Menu Structure Tier",
    "sidebar.configuration.time-based-menu-tier": "Time Based Menu Tier",
    "sidebar.configuration.payment-tier": "Payment Tier",
    "sidebar.configuration.self-service-setup": "Cài đặt Self Service",
    "sidebar.configuration.email-templates": "Mẫu email",
    "sidebar.configuration.attachments": "Cài đặt đính kèm",
    "sidebar.configuration.no-series": "Bộ số",
    "sidebar.configuration.staffs": "Staffs",
    "sidebar.configuration.staffs-permission-group": "Staffs Permission Group",
    "sidebar.configuration.tax-posting-setup": "Tax Posting Setup",
    "sidebar.configuration.customer-price-groups": "Customer Price Groups",
    //Card & Table
    "popup.filter": "Bộ lọc",
    "table.search": "Tìm kiếm ...",
    "table.not-data": "Không tìm thấy dữ liệu.",
    "table.page-size": "Hiển thị {count} trên tổng số {totalcount}",
    //Buttons
    "button.confirm": "Xác nhận",
    "button.sync-bc": "Sync BC",
    "button.add": "Thêm mới",
    "button.cancel": "Hủy",
    "button.save": "Lưu",
    "button.close": "Đóng",
    "button.save&close": "Lưu và Đóng",
    "button.reset": "Reset",
    "button.apply": "Chọn",
    "button.filter": "Lọc",
    "button.back": "Quay lại",
    //Sys - General settings
    "text.general-settings": "General settings",
    //Sys - User groups 
    "text.user-groups": "User groups",
    "text.user-group": "User group",
    "text.user-group.id": "User group ID",
    "text.user-group.name": "User group name",
    "text.user-group.user-group-members": "User group members",
    "text.defaultprofiles": "Default profiles",
    "text.assigntoallnewusers": "Assign to all new users",
    //Sys - Users
    "text.email-verification": "Email verification",
    "text.access-controls": "Access controls",
    "text.users": "Người dùng",
    "text.user": "Người dùng",
    "text.user.id": "User ID",
    "text.user.name": "Tên đăng nhập",
    "text.signature": "Chữ ký",
    "text.communication": "Nhận thông báo qua",
    "text.email-address": "Địa chỉ email",
    "text.avatar": "Ảnh đại diện",
    "text.picture": "Hình ảnh",
    "text.password-reset": "Đặt lại mật khẩu ?",
    "text.password-reset.more": "Mật khẩu mới sẽ được gửi vào email liên hệ của bạn. Nếu không tìm thấy email, vui lòng kiểm tra thư mục Junk/Spam.",
    //Sys - Users vs Profile
    "text.login.info": "Thông tin đăng nhập",
    "text.login.email": "Email",
    "text.web-service": "Web service",
    "text.web-service.accesskey": "Web service access key",
    "text.web-service.expirydate": "Web service expiry date",
    "text.sms": "SMS",
    "text.zalo": "Zalo",
    "text.account-information": "Thông tin tài khoản",
    "text.account-information.more": "Thay đổi thông tin tài khoản",
    "text.security": "Bảo mật",
    //Sys - Companies
    "text.store-price-group": "Store Price Group",
    "text.payment": "Thanh toán",
    "text.companies": "Công ty",
    "text.company": "Công ty",
    "text.company.id": "ID",
    "text.company.name": "Tên công ty",
    "text.tax-registration-no": "Mã số thuế",
    "text.website": "Website",
    "text.beneficiary.name": "Tên người thụ hưởng",
    "text.swift-code": "SWIFT Code",
    "text.company-badge": "Company Badge",
    "text.company-badge.title": "Company Badge",
    "text.company-badge.style": "Company Badge Style",
    "text.company-badge.text": "Company Badge Text",
    //Sys - User-setups
    "text.user-setups": "Cài đặt người dùng",
    "text.user-setup": "Cài đặt người dùng",
    "text.user-setup.id": "ID",
    "text.user-setup.name": "Tên",
    "text.admin-self-service": "Admin self service",
    "text.submit-to": "Người phê duyệt",
    "text.forward-to": "Chuyển tiếp",
    "text.posting-from": "Posting from",
    "text.posting-to": "Posting to",
    "text.user-setup.isshowdataentryform": "Pre-flight",
    "text.user-setup.isapprovalcompletion": "Allow completion of approval",
    "text.user-setup.employee": "Employee",
    "text.user-setup.isadminview": "Admin view",
    //Sys - Permission-sets
    "text.permission-sets": "Phân quyền",
    "text.permission-set": "Phân quyền",
    "text.permission-set.id": "ID",
    "text.permission-set.name": "Tên quyền",
    "text.permistions": "Permistions",
    "text.permission-set.delete.error.permissions": "You can't delete this permission set because it's being used by one or more users.",
    "text.permission-set.delete.error.accesscontrols": "You can't delete this permission set because it's being used by one or more access controls.",
    //Sys - Objects
    "text.objects": "Objects",
    "text.objects.add": "Add objects",
    "text.object": "Object",
    "text.object.id": "Object ID",
    "text.object.type": "Object type",
    "text.object.name": "Object name",
    "text.object.is-super": "Is super",
    "text.is-super-role": "Role super",
    "text.allow-custom-field": "Allow custom field",
    "text.field.add": "Add field",
    "text.field.update": "Update field",
    "text.label.name-system": "Label system name",
    "text.label.name": "Label name",
    "text.data-type": "Data type",
    "text.default-value": "Default value",
    "text.fields": "Fields",
    "text.report": "Report",
    //Sys - Approvals
    "text.approvals": "Approvals",
    "text.approval": "Approval",
    "text.approval.id": "Approval ID",
    "text.approval.name": "Approval name",
    "text.approval.admin": "Approval admin",
    "text.module": "Module",
    "text.priority": "Priority",
    "text.turn-around-time": "Turn Around Time",
    "text.criteria-pattern": "Criteria Pattern",
    "text.change-criteria": "Change Criteria",
    "text.define-ther-criteria-(if-any)": "Define ther criteria ( if any )",
    "text.approval.setting-message": "You can either configure approver levels or allow the system to auto approve / reject the reports",
    "text.email-notification": "Email notification",
    "text.configure-approval-flow": "Configure Approval Flow",
    "text.auto-approval": "Auto Approval",
    "text.auto-reject": "Auto Reject",
    "text.cc": "Cc",
    "text.bcc": "Bcc",
    "text.new-request": "New request",
    "text.notify-approver": "Notify approver",
    "text.next-lever": "Next lever",
    "text.approved": "Approved",
    "text.denied": "Denied",
    "text.hierarchical-approval": "Hierarchical approval",
    "text.custom-approval": "Custom Approval",
    "text.is": "is",
    "text.when": "when",
    "text.or": "or",
    "text.and": "và",
    "text.to": "to",
    "text.starts-with": "starts with",
    "text.ends-with": "ends with",
    "text.contains": "contains",
    "text.doesn't-contain": "doesn't contain",
    "text.is-empty": "is empty",
    "text.is-not-empty": "is not empty",
    "text.add-one-more": "Add one more",
    "text.remind-approver-input-before": "Remind approver {input} before",
    "text.no-of-days-allotted-for-approving-request-for-each-approver-input-days": "No. of days allotted for approving request for each approver {input} days",
    "text.when-tat-has-elapsed": "When TAT has elapsed",
    "text.intermediate-approver-settings": "Intermediate approver settings",
    "text.move-to-next-approver": "Move to next approver",
    "text.assign-to": "Assign to",
    "text.auto-approve": "Auto approve",
    "text.final-approver-settings": "Final approver settings",
    "text.request-will-remain-pending-approval": "Request will remain pending approval",
    "text.remind-approver-once-in-input-days-when-request-is-still-pending-for-approval": "Remind approver once in {input} days when request is still pending for approval",
    //Sys - Email-setup
    "text.anonymous": "Anonymous",
    "text.basic": "Basic",
    "text.oauth-2.0": "OAuth 2.0",
    "text.ntlm": "NTLM",
    "text.sender.name": "Sender name",
    "text.server.url": "Server url",
    "text.server.port": "Server port",
    "text.authentication": "Authentication",
    "text.secure-connection": "Secure connection",
    "text.rate-limit-per-minute": "Rate limit per minute",
    //Catalogue - Items
    "text.items": "Danh mục sản phẩm",
    "text.item": "Sản phẩm",
    "text.item.id": "ID",
    "text.item.name": "Tên sản phẩm",
    "text.purchase-unit-of-measure": "ĐVT (Purch.)",
    "text.assemblybom": "BOM",
    "text.last-direct-cost": "Giá mua gần đây",
    //Catalogue - Vendors
    "text.vendors": "Danh sách nhà cung cấp",
    "text.vendor": "Nhà cung cấp",
    "text.vendor.id": "ID",
    "text.vendor.name": "Tên nhà cung cấp",
    "text.vendor.employee": "Nhân viên",
    "text.vendor.user-setup": "Username",
    "text.pay-to-vendor": "Thanh toán cho",
    "text.balance-as-customer": "Số dư (là Khách hàng)",
    //Employee
    "text.job-title": "Chức danh",
    //
    "text.bank.account.no": "Số tài khoản",
    "text.bank.account.name": "Người thụ hưởng",
    //Catalogue - Customers
    "text.customers": "Customers",
    "text.customer": "Customer",
    "text.customer.id": "Customer ID",
    "text.customer.name": "Customer name",
    "text.bill-to-customer-no": "Bill to customer No.",
    "text.balance-as-vendor": "Balance as vendor",
    "text.total-sales-excl-vat": "Total sale (excl. vat)",
    "text.credit-limit": "Credit limit",
    //Catalogue - Expenses
    "text.expenses": "Hạng mục thanh toán",
    "text.expense": "Hạng mục thanh toán",
    "text.expense.id": "ID",
    "text.expense.name": "Tên",
    "text.expense.line": "Expense line",
    "text.expense.lines": "Expense lines",
    "text.expense.detail": "Thông tin chi tiết",
    "text.expense.line.id": "ID",
    "text.expense.line.name": "Tên",
    "text.cost-center-no": "Cost Center No.",
    "text.cash-flow": "Cash Flow",
    //Catalogue - Stores
    "text.stores": "Danh sách cửa hàng",
    "text.store": "Cửa hàng",
    "text.store.id": "ID",
    "text.store.name": "Tên cửa hàng",
    "text.store.type": "Loại",
    "text.open-hours": "Giờ mở cửa",
    "text.closed-hours": "Giờ đóng cửa",
    "text.close-time": "Close time",
    "text.open-time": "Open time",
    "text.manager.id": "Manager ID",
    "text.manager.name": "Manager name",
    //Catalogue - Budgets
    "text.budgets": "Budgets",
    "text.budget": "Budget",
    "text.budget.id": "Budget ID",
    "text.budget.name": "Budget name",
    //Catalogue - Departments
    "text.departments": "Danh sách phòng ban",
    "text.department": "Phòng ban",
    "text.department.id": "ID",
    "text.department.name": "Phòng ban/Cửa hàng",
    "text.department-head": "Quản lý",
    "text.department-head.phone": "Điện thoại Quản lý",
    "text.department-head.email": "Email Quản lý",
    "text.parent": "Parent",
    "text.department.delete.error.isparent": "You can't delete this department because it's being used by one or more departments.",
    //Fnb - Merchants
    "text.merchants": "Merchants",
    "text.merchant": "Merchant",
    "text.merchant.id": "Merchant ID",
    "text.merchant.name": "Merchant name",
    "text.sections": "Section",
    "text.section": "Section",
    "text.section.name": "Section name",
    "text.section.add": "Add section",
    "text.dining-tables": "Dining tables",
    "text.dining-table": "Dining table",
    "text.dining-table.id": "Table ID",
    "text.dining-table.add": "Add dining tables",
    "text.seat-capacity": "Seat capacity",
    "text.public": "Public",
    "text.allowed": "Allowed",
    "text.not-allowed": "Not allowed",
    "text.display-mode": "Display Mode",
    "text.thousand-separator": "Thousand Separator",
    "text.merchant-key": "Merchant Key",
    "text.merchant-url": "Merchant URL",
    "text.latitude": "Latitude",
    "text.longitude": "Longitude",
    "text.country-phone-code": "Country Phone Code",
    "text.decimal-separator": "Decimal Separator",
    "text.advance-settings": "Advance Settings",
    "text.show-item-pictures": "Show Item Pictures",
    "text.auto-accept-order": "Auto Accept Order",
    "text.receipt-printer": "Receipt Printer",
    "text.kitchen-printer": "Kitchen Printer",
    "text.reprint": "Reprint",
    "text.smartweb-splash-screen": "SmartWeb Splash Screen",
    "text.store-tier": "Store Tier",
    "text.menu-structure-tier": "Menu Structure Tier",
    "text.payment-tier": "Payment Tier",
    "text.time-based-menu-tier": "Time Based Menu Tier",
    "text.invoice": "Invoice",
    "text.vat-bus-posing-group": "Vat Bus. Posing Group",
    "text.tax-identification-no": "Tax Identification No.",
    "text.section-and-table": "Section & Table",
    "text.expires-at": "Expires At",
    "text.order-settings": "Order Settings",
    "text.permission-for-key-order-type": "Permission for {key} order type",
    "text.enable-order-type": "Enable order type",
    "text.enable-order-type-options": "Enable order type options",
    "text.request-no-of-guest": "Request No. of Guest",
    "text.use-seating-capacity": "Use Seating Capacity",
    "text.cover-exceeds-capacity": "Cover Exceeds Capacity",
    "text.qr-code": "QR Code",
    "text.generate-qr-code": "Generate QRCodes",
    "text.printer-settings": "Printer Settings",
    "text.printer": "Printer",
    "text.host": "Host",
    "text.port": "Port",
    "text.logo-size": "Logo Size",
    "text.logo-align": "Logo Align",
    "text.qr-code-width": "QR Code width",
    "text.qr-code-height": "QR Code height",
    "text.server-https": "Server https",
    "text.hardware-station-host": "Hardware station host",
    "text.left": "Left",
    "text.center": "Center",
    "text.right": "Right",
    "text.windows": "Windows",
    "text.win-printer": "WinPrinter",
    "text.opos-printer": "OPOS Printer",
    "text.normal": "Normal",
    "text.double-wide": "Double wide",
    "text.double-high": "Double high",
    "text.double-wide-and-high": "Double wide and high",
    //Fnb - Categories
    "text.categories": "Categories",
    "text.category": "Category",
    "text.category.id": "Category ID",
    "text.category.name": "Category name",
    "text.dine-in": "Dine in",
    "text.pickup": "Pickup",
    "text.take-away": "Take away",
    "text.delivery": "Delivery",
    //Fnb - Sub-categories
    "text.sub-categories": "Sub Categories",
    "text.sub-category": "Sub Category",
    "text.sub-category.id": "Sub Categorie ID",
    "text.sub-category.name": "Sub Category Name",
    //Fnb - Menu-structures
    "text.menu-structures": "Menu Structures",
    "text.menu-structure": "Menu Structure",
    "text.menu-structure.id": "Menu Structure ID",
    "text.menu-structure.name": "Menu Structure name",
    "text.primary-menu-structure": "Primary Menu Structure",
    "text.secondary-menu-structure": "Secondary Menu Structure",
    "text.primary-menu": "Primary Menu",
    "text.secondary-menu": "Secondary Menu",
    "text.display-location": "Display location",
    "text.add-menu-items": "Add Menu Items",
    //Fnb - Hospitality-items
    "text.hospitality-items": "Hospitality Items",
    "text.hospitality-item": "Hospitality Item",
    "text.hospitality-item.id": "Hospitality Item ID",
    "text.hospitality-item.name": "Hospitality Item name",
    "text.hospitality-item.variant.id": "Variant ID",
    "text.recommendation": "Recommendation",
    "text.recommended": "Recommended",
    "text.featured": "Featured",
    "text.stock": "Stock",
    "text.stock-in": "Stock in",
    "text.stock-out": "Stock out",
    "text.has-variants": "Has variants",
    "text.unit-of-measure": "Unit of measure",
    "text.item-has-allergen": "Item has allergen?",
    "text.side-item": "Side item?",
    "text.has-sku-image": "Has SKU image?",
    "text.incl-vat": "Incl. Vat?",
    "text.is-combo": "Is combo",
    "text.max-dish-per-day": "Max dish per day",
    "text.how-many-calories": "How many calories?",
    //Fnb - Tags
    "text.tags": "Tags",
    "text.tag": "Tag",
    "text.tag.id": "Tag ID",
    "text.tag.name": "Tag name",
    //Fnb - Customizations
    "text.customizations": "Customizations",
    "text.customization": "Customization",
    "text.customization.id": "Customization ID",
    "text.customization.name": "Customization name",
    "text.no-of-selection": "No. Of Selection",
    "text.min-no-of-selection": "Min No. Of Selection",
    "text.max-no-of-selection": "Max No. Of Selection",
    "text.header-text": "Header Text",
    "text.show-as-recommendation": "Show as Recommendation",
    "text.do-not-show-in-combo": "Do not show in combo",
    "text.view-model": "View model",
    "text.one-column": "One column",
    "text.two-column": "Two column",
    "text.option-for-customization": "Option for Customization",
    "text.allow-multiple-qty": "Allow Multiple QTY",
    "text.max-allowed-qty": "Max Allowed QTY",
    //Fnb - Time-based-menus
    "text.time-based-menus": "Time based menus",
    "text.time-based-menu": "Time based menu",
    "text.time-based-menu.id": "Time based menu ID",
    "text.time-based-menu.name": "Time based menu name",
    "text.days-of-the-week": "Days of the week",
    "text.days": "Days",
    "text.available": "Available",
    //Fnb - Promotions
    "text.promotions": "Promotions",
    "text.promotion": "Promotion",
    "text.promotion.id": "Promotion ID",
    "text.promotion.name": "Promotion name",
    //Configuration - Attachments
    "text.attachments": "Tập tin đính kèm",
    "text.attachments.add": "Thêm tập tin đính kèm",
    "text.attachment": "Đính kèm",
    "text.attachment.id": "ID",
    "text.attachment.name": "Tên",
    "text.attachment.type": "Loại",
    "text.attachment.object": "Module",
    //Configuration - Email-template
    "text.email-templates": "Email templates",
    "text.email-template": "Email template",
    "text.email-template.id": "Email template ID",
    "text.email-template.name": "Email template name",
    "text.email-template.detail": "Email template detail",
    //Configuration - No-series
    "text.seq": "Seq",
    "text.no-series": "No. Series",
    "text.no-seri": "No. Seri",
    "text.no-seri.id": "No. Seri ID",
    "text.no-seri.name": "No. Seri name",
    "text.no-seri.lines": "Chi tiết bộ số",
    "text.no-seri.start-no": "Start No.",
    "text.no-seri.end-no": "End No.",
    "text.no-seri.last-number-used": "Last number used",
    "text.no-seri.warning-number": "Warning number",
    "text.last-date-used": "Last date used",
    "text.gap": "Gap",
    "text.allow-gap": "Allow gap",
    "text.allow-manual": "Allow manual",
    //Configuration - Staffs
    "text.employee": "Họ tên",
    "text.staff": "Staff",
    "text.manager": "Manager",
    "text.name-on-receipt": "Name on Receipt",
    "text.logged-into-pos": "Logged into POS",
    "text.home-phone-no": "Home phone",
    "text.work-phone-no": "Work phone",
    "text.personal": "Personal",
    "text.address2": "Address 2",
    "text.hourly-rate": "Hourly rate",
    "text.payroll-no": "Payroll no",
    "text.change-password": "Change password",
    "text.date-to-be-blocked": "Date to be blocked",
    "text.employment-type": "Employment type",
    "text.last-z-report": "Last ZReport",
    "text.permission-group": "Permission group",
    "text.first-name": "First name",
    "text.last-name": "Last name",
    "text.cashier": "Cashier",
    "text.void-item-in-bill": "Void item in bill",
    //Configuration - Store-tier
    "text.minimum-quantity": "Minimum QTY",
    "text.minimum-amount": "Minimum Amount",
    "text.lead-time-(minutes)": "Lead Time (minutes)",
    "text.lead-time-minutes": "Lead Time (minutes)",
    "text.maximum-size-(MB)": "Kích thước tối đa (MB)",
    "text.maximum-quantity-(file)": "Số lượng tập tin tối đa",
    "text.setting-item": "Setting Item",
    "text.monday": "Monday",
    "text.tuesday": "Tuesday",
    "text.wednesday": "Wednesday",
    "text.thursday": "Thursday",
    "text.friday": "Friday",
    "text.saturday": "Saturday",
    "text.sunday": "Sunday",
    "text.weekly-timing": "Weekly Timing",
    "text.order-type-assign": "Order Type Assign",
    //Configuration - Menu-structure-tier
    //Configuration - Self-service-setup
    "text.advanceNo": "Advance No. Series",
    "text.paymentNo": "Payment No. Series",
    "text.orderNo": "Order No. Series",
    "text.advanceRequestFor": "Advance Request For Someone",
    "text.paymentRequestFor": "Payment Request For Someone",
    //Requests - Order
    "text.order-requests": "Đề xuất",
    "text.order-request": "Đề xuất",
    "text.order-request.id": "ID",
    "text.order-request.name": "Tên",
    "text.request.name": "Tên sản phẩm",
    "text.request.type": "Loại",
    "text.system-unit-price": "Giá gần đây",
    "text.dear": "Kính gửi",
    "text.about": "Về việc",
    "text.purpose": "Mục đích",
    "text.order-details": "Mô tả chi tiết",
    "text.list-of-order": "Danh sách đề xuất",
    "text.closed-request": "Đóng đề xuất",
    "text.send-request": "Gửi yêu cầu",
    "text.send-request-approval": "Gửi xin phê duyệt",
    "text.send": "Gửi",
    "text.order.noti-import": "Vui lòng kiểm thông tin bên dưới không được để trống",
    "text.detail-not-empty": "Vui lòng kiểm tra chi tiết yêu cầu không thể để trống",
    //Requests - Advance
    "text.advance-requests": "Đề nghị tạm ứng",
    "text.advance-request": "Đề nghị tạm ứng",
    "text.advance-request.id": "ID",
    "text.advance-request.name": "Tên",
    "text.advance-details": "Chi tiết đề nghị tạm ứng",
    "text.advance-amount": "Số tiền tạm ứng",
    "text.list-of-advance": "Danh sách đề nghị tạm ứng",
    "text.advance-for": "Tạm ứng cho",
    "text.salary-deduction": "Cấn trừ lương",
    "text.maximum-deduction": "Cấn trừ tối đa",
    "text.completion-date": "Ngày hoàn ứng",
    "text.amount-of-advance-request": "Số tiền tạm ứng",
    "text.explain-content": "Diễn giải",
    "text.advance-object": "Loại",
    "text.advance-objectcode": "Mã",
    "text.advance-objectname": "Tên",
    //Requests - Payment
    "text.payment-requests": "Đề nghị thanh toán",
    "text.payment-request": "Đề nghị thanh toán",
    "text.payment-request.id": "ID",
    "text.payment-request.name": "Tên",
    "text.payment-for": "Thanh toán cho",
    "text.list-of-payment": "Danh sách đề nghị thanh toán",
    "text.advance-code": "Mã tạm ứng",
    "text.advance-content": "Nội dung tạm ứng",
    "text.payment-code": "Mã thanh toán",
    "text.payment-content": "Nội dung thanh toán",
    "text.remaining-advance-payment": "Số tiền tạm ứng còn lại",
    "text.remaining-advance": "Còn lại",
    "text.payment-information": "Thông tin thanh toán",
    "text.payment-details": "Chi tiết thanh toán",
    "text.store-code": "Mã CH",
    "text.store-name": "Tên CH",
    "text.bill-code": "Số HĐ",
    "text.discount-rate": "Chiết khấu (%)",
    "text.discount-value": "Giá trị chiết khấu",
    "text.warranty-rate": "Tỷ lệ bảo hành (%)",
    "text.warranty-value": "Giá trị bảo hành",
    "text.payment-amount": "Số tiền thanh toán",
    "text.excluding-vat": "chưa VAT",
    "text.advance": "Tạm ứng",
    "text.payment-object": "Loại",
    "text.payment-objectcode": "Mã",
    "text.payment-objectname": "Tên",
    "text.payment.not-map": "Mã không hợp lệ, Vui lòng kiểm tra lại",
    "text.payment.not-space": "Fields không được để trống, Vui lòng kiểm tra các fields bên dưới",
    "text.success-import": "Import thành công",
    "text.currencytype": "Tiền tệ",
    //Requests
    "text.document-code": "Mã chứng từ",
    "text.document-date": "Ngày chứng từ",
    "text.document-type": "Loại chứng từ",
    "text.department-code": "Mã phòng ban/Cửa hàng",
    "text.department-name": "Tên Phòng ban/Cửa hàng",
    "text.bank-code": "Mã ngân hàng",
    "text.bank-name": "Tên ngân hàng",
    "text.bank-account-no": "Số tài khoản",
    "text.bank-account-name": "Tên người thụ hưởng",
    "text.bank-information": "Thông tin ngân hàng",
    "text.general-information": "Thông tin chung",
    "text.creator": "Người tạo",
    "text.date-created": "Ngày tạo",
    "text.expected-date": "Ngày cần",
    "text.approval-history": "Lịch sử phê duyệt",
    "text.print": "In",
    "text.waiting": "Waiting",
    "text.day": "Ngày",
    "text.duplicate": "Nhân bản",
    "text.from-date": "Từ ngày",
    "text.to-date": "Đến ngày",
    "text.export": "Xuất",
    "text.size": "Kích thước",
    "text.explain": "Diễn giải",
    "text.closed": "Đã đóng",
    "text.please-attach-Files-as-requested": "Vui lòng đính kèm tập tin",
    "text.please-quickly-select-an-action": "Vui lòng lựa chọn hành động sau",
    "text.save-and-send-request": "Lưu và Gửi phê duyệt",
    "text.save-and-create": "Lưu và Tạo",
    "text.do-you-want-to-close-these-proposals": "Bạn có muốn đóng đề xuất?",
    "text.moved-to-next": "Đã chuyển đến cấp phê duyệt tiếp theo",
    "text.do-you-want-to-submit-approval": "Bạn có muốn gửi yêu cầu phê duyệt chứng từ này?",
    "text.approval-reject": "Từ chối phê duyệt",
    "text.note": "Ghi chú",
    "text.details": "Thông tin chi tiết",
    "text.object.code": "Mã",
    "text.contact-to-admin": "Bạn không thuộc bất kỳ phòng ban nào. Vui lòng liên hệ admin để được hỗ trợ",
    "text.required-dept-head" : "Phòng ban yêu cầu phải có người quản lý.",
    //Dashboard
    "text.dashboard.task.approve": "Phê duyệt",
    "text.dashboard.task.deny": "Từ chối",
    "text.task-is-open": "Tác vụ đang mở",
    "text.hello": "Hi",
    "text.all": "Tất cả",
    "text.expired": "Hết hạn",
    "text.expired-week": "Hết hạn trong tuần",
    "text.created-a-new-ticket": "created a new ticket",
    "text.you": "Bạn",
    "text.task.pending": "chờ phê duyệt",
    "text.task.approval": "phê duyệt",
    "text.task.approved": "đã phê duyệt",
    "text.task.denied": "đã từ chối",
    "text.task.of": "của",
    "text.task.closed": "đóng",
    "text.task.request": "Yêu cầu",
    "text.task.by": "bởi",
    "text.task.request-for": "yêu cầu cho",
    "text.task.initialization-has": "khởi tạo đã được",
    "text.task.approved-and-forwarded": "phê duyệt và chuyển tiếp đến",
    "text.task.next-request": "để phê duyệt cấp tiếp theo",
    "text.task.forwarded": "đã chuyển tiếp",
    "text.task.date": "Ngày",
    "text.task.for": "cho",
    "text.task.vote": "Vote",
    "text.task.order": "mua hàng",
    "text.task.advance": "tạm ứng",
    "text.task.payment": "thanh toán",
    "text.show": "Hiển thị",
    "text.approval-request": "Yêu cầu phê duyệt",
    //admin-view
    "text.payment-summary": "Thống kê thanh toán",
    "text.overall-summary": "Thống kê",
    "text.current": "Hiện tại",
    "text.thismonth": "Tháng hiện tại",
    "text.thisquarter": "Quý hiện tại",
    "text.thisyear": "Năm hiện tại",
    "text.previous": "Năm trước",
    "text.previous-month": "Theo Tháng",
    "text.previous-quarter": "Theo Quý",
    "text.previous-year": "Theo Năm",
    "text.pending-reimbursements": "Danh sách chưa hoàn ứng",
    "text.pending-proposals": "Chờ đề xuất",
    "text.pending-advances": "Chờ tạm ứng",
    "text.pending-payments": "Chờ thanh toán",
    "text.fiscal-year": "Năm tài chính",
    "text.top-dept": "Top phòng ban",
    "text.top-order": "Top đề xuất",
    "text.top-advance": "Top tạm ứng",
    "text.top-payment": "Top thanh toán",
    "text.proposals": "Đề xuất",
    "text.advances": "Tạm ứng",
    "text.payments": "Thanh toán",
    "text.currentM": "Tr",
    "text.currentTH": "Nghìn",
    "text.currentVND": "",
    "text.week": "Tuần",
    "text.month1":"Tháng 1",
    "text.month2":"Tháng 2",
    "text.month3":"Tháng 3",
    "text.month4":"Tháng 4",
    "text.month5":"Tháng 5",
    "text.month6":"Tháng 6",
    "text.month7":"Tháng 7",
    "text.month8":"Tháng 8",
    "text.month9":"Tháng 9",
    "text.month10":"Tháng 10",
    "text.month11":"Tháng 11",
    "text.month12":"Tháng 12",
    //General - Actions
    "text.confirm": "Xác nhận",
    "text.sync-bc": "Đồng bộ BC",
    "text.sync": "Đồng bộ",
    "text.delete": "Xóa",
    "text.modify": "Chỉnh sửa",
    "text.read": "Đọc",
    "text.execute": "Thực thi",
    "text.insert": "Ghi",
    "text.add": "Thêm",
    "text.cancel": "Hủy",
    "text.save": "Lưu",
    "text.close": "Đóng",
    "text.save&close": "Lưu & Đóng",
    "text.reset": "Reset",
    "text.apply": "Apply",
    "text.filter": "Lọc",
    "text.back": "Quay lại",
    "text.generate": "Generate",
    "text.login": "Đăng nhập",
    "text.reset-password": "Đặt lại mật khẩu",
    "text.forgot-password": "Quên mật khẩu",
    "text.search": "Tìm kiếm",
    "text.skip": "Bỏ qua",
    //General - Api
    "text.created-at": "Created at",
    "text.created-by": "Created by",
    "text.updated-at": "Updated at",
    "text.updated-by": "Updated by",
    "text.created": "Created",
    "text.updated": "Updated",
    "text.created-by-name": "Created by {name}",
    "text.updated-date-by-name": "Updated {date} by {name}",
    //General - DateTime
    "text.times": "Times",
    "text.start-time": "Start date",
    "text.end-time": "End date",
    "text.started-date": "Started date",
    "text.ended-date": "Ended date",
    "text.starting-date": "Starting Date",
    "text.ending-date": "Ending Date",
    //General - Address
    "text.address": "Địa chỉ",
    "text.state": "Bang",
    "text.district": "Quận/Huyện",
    "text.zipcode": "Zipcode",
    "text.province": "Tỉnh/Tp",
    "text.country": "Quốc gia",
    "text.ward": "Phường",
    //General - Text
    "text.members": "Members",
    "text.contact.name": "Tên liên hệ",
    "text.contact.email": "Email liên hệ",
    "text.location": "Kho hàng",
    "text.location.id": "Mã kho hàng",
    "text.account": "Account",
    "text.account.no": "Account No.",
    "text.account.name": "Account name",
    "text.timezone": "Timezone",
    "text.bank": "Ngân hàng",
    "text.banks": "Ngân hàng",
    "text.bank.name": "Tên ngân hàng",
    "text.bank.info": "Thông tin ngân hàng",
    "text.branch.id": "Branch ID",
    "text.branch.name": "Branch name",
    "text.general": "Thông tin chung",
    "text.subject": "Tiêu đề",
    "text.body": "Nội dung",
    "text.information": "Thông tin",
    "text.email": "Email",
    "text.username": "Tên đăng nhập",
    "text.password": "Mật khẩu",
    "text.#": "#",
    "text.id": "ID",
    "text.code": "Mã",
    "text.name": "Tên",
    "text.required": "Yêu cầu",
    "text.fullname": "Tên đầy đủ",
    "text.blocked": "Khóa",
    "text.active": "Kích hoạt",
    "text.status": "Tình trạng",
    "text.type": "Loại",
    "text.actions": "Hành động",
    "text.action": "Hành động",
    "text.phone": "Điện thoại",
    "text.total": "Tổng cộng",
    "text.description": "Mô tả",
    "text.reason": "Lý do",
    "text.content": "Tạm ứng",
    "text.preview": "Xem trước",
    "text.method": "Phương thức",
    "text.payment-method": "Phương thức thanh toán",
    "text.payment-term": "Điều khoản thanh toán",
    "text.price": "Giá",
    "text.unit-price": "Đơn giá",
    "text.vat-registration-no": "Mã số thuế",
    "text.inventory": "Tồn kho",
    "text.variants": "Variants",
    "text.prices": "Giá",
    "text.max-inventory": "Tồn kho tối đa",
    "text.balance": "Số dư",
    "text.system": "System",
    "text.order-type": "Order Type",
    "text.image": "Image",
    "text.images": "Images",
    "text.thumb-image": "Thumb image",
    "text.icon": "Icon",
    "text.settings": "Settings",
    "text.table": "Table",
    "text.select-all": "Select all",
    "text.yes": "Yes",
    "text.no": "No",
    "text.page": "Page",
    "text.edit": "Edit",
    "text.order": "Order",
    "text.by": "bởi",
    "text.uom": "ĐVT",
    "text.quantity": "SL",
    "text.vat": "Tiền thuế",
    "text.noti-required-file": "Please attach this file type",
    "text.remove-all": "Remove all",
    "text.loadings": "Chờ giây lát...",
    "text.globaldimension1code": "Global Dimension code 1",
    "text.globaldimension2code": "Global Dimension code 2",
    "text.globaldimension3code": "Global Dimension code 3",
    "text.globaldimension4code": "Global Dimension code 4",
    "text.row": "Dòng",
    "text.please-check-form-detail": "Vui lòng kiểm tra thông tin chi tiết!",
    "text.workflows-not-defined": "Không tìm thấy cài đặt phê duyệt phù hợp",
    "text.advance-check-amount": "Số tiền tạm ứng không đủ. Vui lòng kiểm tra lại!",
    "text.chart.unit": "Đơn vị",
    //------------import----------------
    "text.import.title": "Tải lên",
    "text.import.label": "Tải lên tập tin, cho phép tập tin có định dạng .xlsx",
    "text.import.choose-file": "Chọn tập tin",
    "text.import.no-file": "Chưa có tập tin",
    "text.import.sample-file": "Tải về tập tin mẫu",
    "text.import.submit": "Tải lên",
    "text.deptname": "Phòng ban",
    "text.complete-approval": "Hoàn tất phê duyệt",
    "text.byme": "Chứng từ của tôi",
    "text.forme": "Chứng từ liên quan",
    "text.waitingfor": "Đang chờ",
    "text.toapprove": "phê duyệt",
    "text.subtotal": "Phải thanh toán",
    
}