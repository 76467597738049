import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Column } from "react-table"
import { ApiService, sortByUpdateAt } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, FilterDropdown, KTButton, KTFormItem, KTTable, RowIcon } from "../../../../theme/partials"
import { IInitField } from "../../../modules/api"
import { apiFnb } from "../../../modules/api/fnb"
import { popupMessage } from "../../../modules/messages"
import { MergedProps } from "../../../router/AppRoutes"

export interface IFnbCustomization extends IInitField {
    code: string | null
    name: string | null
    headertext: string | null
    active: boolean
    minnoofselection: number | null
    maxnoofselection: number | null
    viewmodel: string | null
    showasrecommendation: boolean
    donotshowincombo: boolean
    CustomizationLines?: any[]
}

export const optionViewModals = [
    { value: 'one-column', label: 'One Column' },
    { value: 'two-column', label: 'Two Column' },
    { value: 'image', label: 'Image' }
]

export function FNBCustomizations({ keyElement, permission }: MergedProps) {
    const urlApi = 'fnb/customizations'
    const [tableData, setTableData] = useState<IFnbCustomization[]>([])
    const { data: dataQuery, isSuccess, isFetching, refetch } = apiFnb.useCustomizations()

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(dataQuery))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.header-text' />,
        accessor: 'headertext',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].headertext} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.no-of-selection' />,
        accessor: 'minnoofselection',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].CustomizationLines?.length} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" to={props.data[props.row.index].id} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [filter, setFilter] = useState<IFnbCustomization | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: IFnbCustomization[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                f.active == filter.active,
            ]
            return checks.every(val => val == true)
        })
    }

    const toolbar = <>
        <FilterDropdown
            handleSubmit={handleSubmit}
            onClose={() => {
                setFilter(undefined)
                reset({})
            }}
            onSubmit={(data) => setFilter(data)}>
            <KTFormItem title='text.active' labelWidth="auto">
                <label className="form-check form-switch form-check-custom">
                    <input className="form-check-input" type="checkbox" {...register("active")} />
                </label>
            </KTFormItem>
        </FilterDropdown>
        <KTButton permission={permission} action="new" />
    </>

    return <>
        <ContentHeader title={keyElement} elements={toolbar} />
        <KTTable
            isLoading={isFetching}
            data={onFilter(tableData)}
            columns={columns}
            search
            pagination
            cardInnner
        />
    </>
}
