import clsx from "clsx"
import { watch } from "fs"
import { FC, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"

interface PropsImportModal {
    title?: string
    show: boolean
    handleClose: () => void,
    handleImport: (data: any) => void
    filename?: string
    filelink?: string
    funcDowloadFile?: any
}
const host = process.env.REACT_APP_HOST_URL;
export const ImportModalRequest: React.FC<PropsImportModal> = ({ title, show, handleClose, handleImport, filename, filelink,funcDowloadFile }) => {
    const { reset, handleSubmit, setValue, watch } = useForm()

    useEffect(() => {
        reset({
            "files": null
        })
    }, [show])
    const intl = useIntl()
    const onSubmit = (data: any) => {
        handleImport(data)
        handleClose()
    }

    return <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{title || intl.formatMessage({ id: `text.import.title` as any })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-2">
                    <label className="form-label">{intl.formatMessage({ id: `text.import.label` as any })}</label>
                    <div>
                        <input type='file' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className={clsx('form-control form-control-sm d-none')} name="files" id="file-input"
                            onChange={(event) => {
                                document.querySelector(".btn-import")?.classList.remove("disabled")
                                setValue('files', event.target.files)
                        }} />
                        <label htmlFor="file-input" id="label-files" className="d-flex cursor-pointer">
                            <span className="btn btn-sm btn-secondary min-w-120px rounded-0">{intl.formatMessage({ id: `text.import.choose-file` as any })}</span>
                            <span className="form-control form-control-sm border-1 rounded-0">
                            {
                                watch('files') ? <>
                                { watch('files')[0]?.name} </> :
                                <>{intl.formatMessage({ id: `text.import.no-file` as any })}</>
                            }
                            </span>
                            
                        </label>
                    </div>
                </div>
                {filename && filelink && <div className='mb-3 fs-8'>
                {intl.formatMessage({ id: `text.import.sample-file` as any })}: 
                    {funcDowloadFile ? 
                    <span onClick={funcDowloadFile} className="text-primary cursor-pointer"> {filename} </span>
                    :                    
                    <Link target="_blank" to={`${host}${filelink}`}> {filename} </Link>
                    }
                </div>}
                <div className="text-end">
                    <button type='submit' className="btn btn-sm btn-primary btn-import me-3 disabled">{intl.formatMessage({ id: `text.import.submit` as any })}</button>
                </div>
            </form>
        </Modal.Body>
    </Modal>
}