import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { LayoutProvider, LayoutSplashScreen } from '../theme/layout/core'
import { MasterInit } from '../theme/layout/MasterInit'

export function App() {
  return <Suspense fallback={<LayoutSplashScreen />}>
    <LayoutProvider>
      <Outlet />
      <MasterInit />
    </LayoutProvider>
  </Suspense>
}
