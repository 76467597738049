import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Column } from "react-table";
import { IExpense } from ".";
import { ApiService, ApiShowError, changeDataModify, getValueId, sortByUpdateAt } from "../../../../theme/helpers";
import { FormattedMessage } from "react-intl";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { BasicCell, CustomHeader, FormSubmit, KTButton, KTFormItem, KTTable, RowIcon, TextFormByUser, yup } from "../../../../theme/partials";
import { apiPrivate, IInitField } from "../../../modules/api";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";

const defaultValues: IExpense = {
    code: null,
    expensename: null,
    accountno: null,
    costcenterno: null,
    budgetcode: null,
    cashflowcode: null,
    noofmonthallocated: null,
    blocked: false
}

export const SettingExpensesForm: FC = ({ keyElement, pathElement, permission }: MergedProps) => {
    const urlApi = 'settings/expenses'
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data: dataQuery, isFetching, isSuccess } = apiPrivate.useExpensesId<IExpense>(valueid)
    const { register, reset, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<IExpense>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
        })),
    })

    useEffect(() => {
        if (isSuccess && !isFetching) {
            reset(dataQuery)
        }
    }, [isFetching])

    return <>
        <ContentHeader isInfo title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card mb-6"
            onSubmit={handleSubmit(async (data: any) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general"/></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.expensename && 'form-error'}`} {...register('expensename')} />
                        </KTFormItem>
                        <KTFormItem title='text.account.no'>
                            <input className={`form-control form-control-sm ${errors.accountno && 'form-error'}`} {...register('accountno')} />
                        </KTFormItem>
                        <KTFormItem title='text.cost-center-no'>
                            <input className={`form-control form-control-sm ${errors.costcenterno && 'form-error'}`} {...register('costcenterno')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.budget.id'>
                            <input className={`form-control form-control-sm ${errors.budgetcode && 'form-error'}`} {...register('budgetcode')} />
                        </KTFormItem>
                        <KTFormItem title='text.cash-flow'>
                            <input className={`form-control form-control-sm ${errors.cashflowcode && 'form-error'}`} {...register('cashflowcode')} />
                        </KTFormItem>
                        <KTFormItem title='text.blocked'>
                            <label className="form-check form-switch form-check-custom form-check-solid form-control-sm">
                                <input className="form-check-input" type="checkbox" {...register("blocked")} />
                            </label>
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
        {isEdit && dataQuery?.id && <div className="card card-body mb-6">
            <ExpenseValuesCard expenseData={dataQuery} />
        </div>}
    </>
}

interface IExpenseValue extends IInitField {
    expenseid: string | null
    expensecode: string | null
    expense: string | null
    code: string | null
    description: string | null
    accountno: string | null
    costcenterno: string | null
    budgetcode: string | null
    cashflowcode: string | null
    noofmonthallocated: string | null
}

const ExpenseValuesCard: FC<{ expenseData: IExpense }> = ({ expenseData }) => {
    const urlApi = 'settings/expensevalues'
    const { isEdit, valueid } = getValueId(expenseData?.id)
    const [tableData, setTableData] = useState<IExpenseValue[]>([])
    const { data: dataQuery, isFetching, isSuccess } = apiPrivate.useExpenseValuesByQuery(isEdit ? `?expenseid=${valueid}` : undefined)
    const [valueData, setValueData] = useState<IExpenseValue | undefined>()

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setTableData(sortByUpdateAt(dataQuery))
        }
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' className='w-50px' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => setValueData(props.data[props.row.index])}
            data={props.data[props.row.index].code}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'description',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].description} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.account.no' />,
        accessor: 'accountno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].accountno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.cost-center-no' />,
        accessor: 'costcenterno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].costcenterno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.budget.id' className='w-100px' />,
        accessor: 'budgetcode',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].budgetcode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.cash-flow' className='w-100px' />,
        accessor: 'cashflowcode',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].cashflowcode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" onClick={() => setValueData(props.data[props.row.index])} />
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`${urlApi}/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <div>
        <KTTable
            isLoading={isFetching}
            title={<FormattedMessage id={'text.expense.lines'}/>}
            toolbar={<KTButton action="new" onClick={() => {
                expenseData && setValueData({
                    expenseid: expenseData.id as any,
                    expensecode: expenseData.code,
                    expense: expenseData.expensename,
                    code: null,
                    description: null,
                    accountno: null,
                    costcenterno: null,
                    budgetcode: null,
                    cashflowcode: null,
                    noofmonthallocated: null
                })
            }} />}
            data={tableData}
            columns={columns} />
        {valueData && <ExpenseValuesModal {...{
            tableData,
            valueData,
            handleClose() {
                setValueData(undefined)
            },
            handleSave(type, value) {
                setTableData(pre => type
                    ? sortByUpdateAt(pre.map(item => item.id == value.id ? value : item))
                    : [value, ...pre]
                )
            },
        }} />}
    </div>
}

interface IExpenseValuesModal {
    valueData: IExpenseValue
    handleSave: (type: boolean, value: IExpenseValue) => void
    handleClose: () => void
    tableData: IExpenseValue[]
}

const ExpenseValuesModal: FC<IExpenseValuesModal> = ({ valueData, handleSave, handleClose, tableData }) => {
    const urlApi = 'settings/expensevalues'
    const { valueid, isEdit } = getValueId(valueData.id)
    const { register, control, reset, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<IExpenseValue>({
        resolver: yupResolver(yup.object().shape({
            code: yup.string().test('unique', (val, ctx) => {
                if(val && val != valueData.code && tableData.map(m => m.code).includes(val)) return ctx.createError()
                return true
            })
            .required(),
        })),
    })

    useEffect(() => {
        if (valueData)
            reset(valueData)
    }, [valueData])

    const onSubmit = async (data: any) => {
        try {
            const method = isEdit ? 'put' : 'post'
            const result = await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
            handleSave(isEdit, result.data)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
        handleClose()
    }

    return <Modal size="lg" show={valueData != undefined} onHide={handleClose} centered >
        <Modal.Header>
            <Modal.Title> Thông tin chi tiết mã thanh toán </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.id'>
                            <input className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                        </KTFormItem>
                        <KTFormItem title='text.name' isHeight>
                            <textarea className={`form-control form-control-sm ${errors.description && 'form-error'}`} {...register('description')} rows={1} />
                        </KTFormItem>
                        <KTFormItem title='text.account.no'>
                            <input className={`form-control form-control-sm ${errors.accountno && 'form-error'}`} {...register('accountno')} />
                        </KTFormItem>
                        <KTFormItem title='text.cost-center-no'>
                            <input className={`form-control form-control-sm ${errors.costcenterno && 'form-error'}`} {...register('costcenterno')} />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.budget.id'>
                            <input className={`form-control form-control-sm ${errors.budgetcode && 'form-error'}`} {...register('budgetcode')} />
                        </KTFormItem>
                        <KTFormItem title='text.cash-flow'>
                            <input className={`form-control form-control-sm ${errors.cashflowcode && 'form-error'}`} {...register('cashflowcode')} />
                        </KTFormItem>
                    </div>
                </div>
                <div className="d-flex flex-stack">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        handleClose={handleClose} />
                </div>
            </form>
        </Modal.Body>
    </Modal>
}