export const LocalData: any = {
    statusData: {
        284210000: { value: "284210000", label: "Mới",labelvi: "Mới", labelen: "New", badge: 'light-primary', color: 'primary',index: 0 },
        284210001: { value: "284210001", label: "Chờ phê duyệt", labelvi: "Chờ phê duyệt", labelen: "Pending request", badge: 'light-warning', color: 'warning',index: 1 },
        //------
        284210005: { value: "284210005", label: 'Cần phê duyệt', labelvi: 'Cần phê duyệt', labelen: 'Need approval', badge: 'light-info', color: 'info',index: 2 },
        284210006: { value: "284210006", label: 'Đã chuyển tiếp', labelvi: 'Đã chuyển tiếp', labelen: 'Forwarded', badge: 'secondary', color: 'black',index: 3 },
        //------
        284210002: { value: "284210002", label: "Đã phê duyệt", labelvi: "Đã phê duyệt", labelen: "Approved", badge: 'light-success', color: 'success',index: 4 },
        284210003: { value: "284210003", label: 'Đã từ chối', labelvi: 'Đã từ chối', labelen: 'Denied', badge: 'light-danger', color: 'danger',index: 5 },
        284210004: { value: "284210004", label: 'Đã đóng', labelvi: 'Đã đóng', labelen: 'Closed', badge: 'light-info', color: 'danger',index: 6 },
        
    },
    venderType: {
        284210000: { value: "284210000", label: "Nhà cung cấp",labelvi: "Nhà cung cấp",labelen: "Vendor", badge: 'light-primary' },
        284210001: { value: "284210001", label: "Nhân viên", labelvi: "Nhân viên", labelen: "Employee", badge: 'light-info' },
        284210002: { value: "284210002", label: "Khách hàng", labelvi: "Khách hàng",labelen: "Customer", badge: 'light-danger' },
    },
    requestType: {
        284210000: { value: "284210000", label: "Item", badge: 'light-primary' },
        // 284210001: { value: "284210001", label: "Cost Accounting", badge: 'light-info' },
    },
    paymentMethod: {
        284210000: { value: "284210000", label: "Tiền mặt",labelvi: "Tiền mặt",labelen: "Cash", badge: 'light-primary' },
        284210001: { value: "284210001", label: "Chuyển khoản", labelvi: "Chuyển khoản", labelen: "Transfer", badge: 'light-info' },
    },
    currencyMethod: {
        284210000: { value: "284210000", label: "VND",labelvi: "VND",labelen: "VND", badge: 'light-primary' },
        284210001: { value: "284210001", label: "USD", labelvi: "USD", labelen: "USD", badge: 'light-info' },
        // 284210002: { value: "284210002", label: "EUR", labelvi: "EUR", labelen: "EUR", badge: 'light-info' },
        // 284210003: { value: "284210003", label: "JPY", labelvi: "JPY", labelen: "JPY", badge: 'light-info' },
        // 284210004: { value: "284210004", label: "CNY", labelvi: "CNY", labelen: "CNY", badge: 'light-info' },
    },
    approvalType: {
        284210000: { value: "284210000", label: "Đề xuất", badge: 'light-primary' },
        284210001: { value: "284210001", label: "Tạm ứng", badge: 'light-primary' },
        284210002: { value: "284210002", label: "Thanh toán", badge: 'light-primary' },
    }
}


export const localSetings = {
    optionDocumemntType: [
        { value: "word", label: 'Word' },
        { value: "excel", label: 'Excel' },
        { value: "pdf", label: 'PDF' },
        { value: "image", label: 'Image' },
        { value: "text", label: 'Text' },
    ],
}

