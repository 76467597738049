import clsx from "clsx";
import { Control, Controller } from "react-hook-form";
import { useIntl } from "react-intl";

interface IRadioBoolean {
    control: Control<any, any>,
    name: string,
    labels?: string[],
    className?: string,
}

export function RadioBoolean({ control, name, labels, className }: IRadioBoolean) {
    const intl = useIntl()
    const classw = 'form-check form-check-sm form-check-custom form-check-solid'
    const text = labels?.map(t => intl.formatMessage({ id: "text." + t as any })) || [intl.formatMessage({ id: "text.yes" }), intl.formatMessage({ id: "text.no" })]
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
                <>
                    <label className={clsx(className || classw)}>
                        <input
                            className="form-check-input"
                            type="radio"
                            onBlur={onBlur} // notify when input is touched
                            onChange={() => onChange(true)} // send value to hook form
                            checked={value === true}
                        // inputRef={ref}
                        />
                        {text[0]}
                    </label>
                    <label className={clsx(className || classw)}>
                        <input
                            className="form-check-input"
                            type="radio"
                            onBlur={onBlur} // notify when input is touched
                            onChange={() => onChange(false)} // send value to hook form
                            checked={value === false}
                        // inputRef={ref}
                        />
                        {text[1]}
                    </label>
                </>
            )
            }
        />
    );
}