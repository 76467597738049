import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { Column } from "react-table"
import { ApiService, formatOptions, sortByUpdateAt } from "../../../../theme/helpers"
import { ContentHeader } from "../../../../theme/layout/components/content"
import { BasicCell, CustomHeader, FilterDropdown, InputSelect, KTButton, KTFormItem, KTTable, RowIcon } from "../../../../theme/partials"
import { apiPrivate, IInitField } from "../../../modules/api"
import { popupMessage } from "../../../modules/messages"
import { MergedProps } from "../../../router/AppRoutes"

export interface IDepartment extends IInitField {
    code: string | null
    departmentname: string | null
    parentid: string | null
    parentname: string | null
    description: string | null
    departmentheadid: string | null
    departmentheadcode: string | null
    departmentheadname: string | null
    departmentheadphone: string | null
    departmentheademail: string | null
}

const apiurl = 'settings/departments'
export function SettingDepartments({ keyElement, permission }: MergedProps) {
    const intl = useIntl()
    const { data, isSuccess, isFetching } = apiPrivate.useDepartmentsByQuery(`?statuscode=-1`)
    const [tableData, setTableData] = useState<IDepartment[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].code}
        />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'departmentname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].departmentname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.department-head' />,
        accessor: 'departmentheadname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].departmentheadname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.department-head.email' />,
        accessor: 'departmentheademail',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].departmentheademail} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.parent' />,
        accessor: 'parentid',
        Cell: ({ ...props }) => <BasicCell data={tableData.find(f => f.id == props.data[props.row.index].parentid)?.departmentname || ''} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.active' />,
        accessor: 'statuscode',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].statuscode == 1 ? true : false} />,
    },  {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" to={props.data[props.row.index].id} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                const lines = tableData.filter(f => f.parentid == id)
                if (lines.length > 0) {
                    popupMessage({ icon: 'error', description: intl.formatMessage({ id: "text.department.delete.error.isparent" }) })
                    return;
                }
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [filter, setFilter] = useState<IDepartment | undefined>()
    const { register, watch, setValue, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: IDepartment[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                !!filter.parentid ? f.parentid == filter.parentid : true,
            ]
            return checks.every(val => val == true)
        })
    }

    function getDepartments() {
        if (!isSuccess || !Array.isArray(data)) return []
        let ids: string[] = []
        data
            .filter((f: any) => f.parentid)
            .forEach((m: any) => {
                !ids.includes(m.parentid) && ids.push(m.parentid)
            })
        return data.filter((f: any) => ids.includes(f.id)) as any[]
    }
    const optionDepartments = isSuccess ? formatOptions(getDepartments(), ['id', 'departmentname']) : []

    return <>
        <ContentHeader title={keyElement} elements={<>
            <FilterDropdown
                filterValue={filter}
                handleSubmit={handleSubmit}
                onClose={() => {
                    setFilter(undefined)
                    reset({})
                }}
                onSubmit={(data) => setFilter(data)}>
                <KTFormItem title='text.parent' isBLockLabel>
                    <Controller
                        name="parentid"
                        control={control}
                        render={({ field: { value, onChange } }) => <InputSelect
                            isClearable
                            options={optionDepartments}
                            value={optionDepartments.find(f => f.value == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                        />}
                    />
                </KTFormItem>
            </FilterDropdown>
            <KTButton permission={permission} action='new' />
        </>} />
        <KTTable
            isLoading={isFetching}
            data={onFilter(tableData)}
            columns={columns}
            cardInnner
            pagination
            search />
    </>
}
