/* eslint-disable */
import { useNavigate, useParams } from "react-router-dom"
import { ApiService, ApiShowError, exportCSV, getValueId, reFormatCurrent, sortWithDate } from "../../../../theme/helpers"
import { useAuth } from "../../../modules/auth"
import { popupLoading, popupMessage, popupQuestion } from "../../../modules/messages"
import { useEffect, useState } from "react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Dropdown, Modal } from "react-bootstrap"
import { DivRoot, InputSelect, KTFormItem, KTSVG, RowIcon } from "../../../../theme/partials"
import clsx from "clsx"
import { LocalData } from "../../../modules/api/localData"
import { Controller, useForm } from "react-hook-form"
import Swal from "sweetalert2"
import { checkApprovalDeptHead, printPdf } from "./function"
import { useIntl } from "react-intl"
export const ActionApprovals = (props: any) => {
    let { id } = useParams() as any
    // let { isEdit, valueid: idRequestOrder } = getValueId(id)
    const intl = useIntl()
    // const [valueid, setValueId] = useState<any>(idRequestOrder)
    const {auth} = useAuth()
    const {user} = auth || {} as any
    const navigate = useNavigate()
    // console.log('props',props)
    let { requestUser, approvalUser, approvalhistories, approvalLevels, totalamount, refetchApproval, setValue, watch, refApprovalRequest, valueid, isHasChange, refSaveBtn, tableData,includeFromlist,setTempRecord, messFromMail, ApiCustom, urlApi, emailnotification, employeeAdvanceData, dataQuery } = props
    if(id == 'new') {
      id = valueid
    }
    const [schemaChild, setsShemaChild] = useState<any>(yup.object({
      status: yup.string().required(),
      comment: yup.string().required(),
      
    }).required())
    if(!ApiCustom) {
      ApiCustom = ApiService
    }
    // const [userid,setUserId] = useState<any>('')
    let userid = user?.id
    if(messFromMail) {
      userid = messFromMail.userid
    }
    useEffect(() => {
      if(messFromMail) {
        userid = messFromMail.userid
        successApproval(messFromMail)
      }
      else {
      }
    }, [messFromMail])
    const { register:registerChild, reset:resetChild,setValue:setValueChild,resetField: resetFieldChild,setError:setErrorChild, handleSubmit:handleSubmitChild, control:controlChild, watch:watchChild, formState: { errors:errorsChild } } = useForm<any>({
      resolver: yupResolver(schemaChild) 
    })
    const [isShow, setIsShow] = useState<boolean>(false)
    const handleCloseModal = () => {
      setIsShow(false)
    }
    const handleAddModalApproval = (status?:any) => {
      setIsShow(true)
      setTimeout(()=> {
        resetChild({
          status: status ? status : "284210002",
          comment: null,
        })
      },100)
    }
    const formatPrintPdf = async() => {
      let url =  window.location.origin
      let dataTemp = {
        "pagepath": `${url}/${urlApi.printPdf}/${id}`,
        "filename": `${urlApi.printPdf}-${watch('code')?watch('code'):new Date().getTime()}`,
        "landscape": '0',
      }
      printPdf(dataTemp, ApiCustom)
    }
     const checkIsApprover = () => {
        let stage = approvalhistories?.currentstage
        return presentApproval() && approvalhistories?.open && (approvalhistories?.rule[stage]?.userid == userid) && approvalhistories?.currentstatus == '284210001'
      }
      const checkPendingRequest = () => {
        if(approvalUser || !requestUser) return false
        return approvalhistories?.currentstatus == '284210001'
      }
      const checkIsRelease = () => {
        if( approvalUser || requestUser?.approvaltype != "auto_approval" || approvalhistories?.currentstatus == '284210002') return false
        let check = false
        if(requestUser?.approvaltype == "auto_approval" || !requestUser?.submittoifamountexceed || (requestUser?.submittoifamountexceed != null && requestUser?.submittoifamountexceed >= totalamount)) {
          check = true
        }
        return check
      }
      const presentApproval = () => {
        return approvalLevels?.find((item: any)=> item.userid == userid && (+item.stage >= +approvalhistories?.currentstage))
      }
      const pendingRequest = async(approvalhistoryid:any, approvalInfo?: any) => {
        if(!approvalInfo) {
          approvalInfo = checkNextApproval()
          if(requestUser) {
            approvalInfo = approvalLevels[0]//.find((item: any)=> item.order == 1)
          }
        }
       
        let temp = {
          "statuscode": 1,
          "approvalhistoryid": approvalhistoryid,
          "status": '284210001',
          "comment": null,
          "approverid": approvalInfo?.userid,
          "apprvercode": approvalInfo?.usercode,
          "approvername": approvalInfo?.username,
        }
        await ApiCustom.post(`${urlApi?.settings}/approvaldetails/`, temp) // pending request if has nextApproval
        // console.log('emailnotification',emailnotification)
        if(!requestUser) {
          sendMailProcess(emailnotification?.data?.email,'next_level') // send mail
        }
        sendMailProcess(approvalInfo?.email,'notify_approver',approvalInfo?.userid) // send mail
        
      }
      const approvalBySys = async(approvalhistoryid: any) => {
        let temp = {
          "statuscode": 1,
          "approvalhistoryid": approvalhistoryid,
          "status": "284210002",
          "comment": "Được phê duyệt",
          "creater": "irms_system",
          "createdby": null,
          "modifiedby": null,
          "modifier": "irms_system",
        }
        await ApiCustom.post(`${urlApi?.settings}/approvaldetails/`, temp) // add 1 record history
      }
      const checkNextApproval = (isNextSubmitto?: any) => {
        // console.log('presentApproval',presentApproval())
        let present = presentApproval()||approvalLevels[0]
        let tempIncludes = approvalLevels.filter((item:any)=> item.type == "submits_to" && item.stageParent == present?.stageParent).map((i:any)=>i.userid)
        // console.log('tempIncludes',tempIncludes)
        let data = isNextSubmitto?approvalLevels.filter((item:any)=> !tempIncludes.includes(item.userid)): approvalLevels
        data = sortWithDate(data,{sort: 'order'})
        // console.log('data',data)
        // console.log('approvalhistories?.currentstage',approvalhistories?.currentstage)
        let index = data.findIndex((item: any,index: number)=> item.userid == userid && (+item.stage >= +approvalhistories?.currentstage))
        // console.log('index',index)
        if(index != data.length - 1) {
          // let index = approvalLevels.findIndex((item: any)=> isNextSubmitto?:item.order == +isA.order+1)
          return data[index+1]
        }
        return null
      }
      const checkSkipStage = async(approvalhistoryid: any) => {
       
        let presentApproval = approvalLevels?.find((item: any)=> item.userid == userid && (+item.stage >= +approvalhistories?.currentstage))||{} // check first stage is submits_to
        let nextApproval = checkNextApproval()
        // console.log('presentApproval',presentApproval)
        // console.log('nextApproval',nextApproval)
        // console.log('requestUser',requestUser)
        if(requestUser) {
          nextApproval = approvalLevels[0]//.find((item: any)=> item.order == 1) // first approval
          presentApproval = {}
        }
        // console.log('nextApproval',nextApproval)

        if(nextApproval?.type == 'submits_to') { // next to step not submitto
          // console.log('totalamount',totalamount)
          if(presentApproval.approvaladministrator || +nextApproval.condition >= totalamount ) {
            nextApproval = checkNextApproval(true) // skip user type submits_to and next stage
            // console.log('nextApproval',nextApproval)
            if(!nextApproval) { // first and end approval is submit
              if( presentApproval?.type == 'submits_to') {
                releaseApproval(approvalhistoryid) // end stage
                return
              }
              else { // end stage
                updateStatusforRelease(approvalhistoryid)
                return
              }
            }
          }
        }
        if(nextApproval) { // go to next state
          let data = {
            "currentstatus": '284210001',
            "currentstage": nextApproval.stage,
            "open": true,
          }
          updateCurrentStatusApproval(approvalhistoryid,data)
          pendingRequest(approvalhistoryid,nextApproval) // request pending
        }
        else {
          releaseApproval(approvalhistoryid) // end stage
        }
      }
      const closedRequestApproval = async() => {
        const question = await popupQuestion({description: intl.formatMessage({ id: `text.do-you-want-to-close-these-proposals` as any })})
        if(!question) return
        let approvalhistoryid = approvalhistories?.id
        let tempData = {
          "currentstatus": "284210004",
          "open": false, 
          "currentstage": -1,
        }
        updateCurrentStatusApproval(approvalhistoryid,tempData)

        let temp = {
          "statuscode": 1,
          "approvalhistoryid": approvalhistoryid,
          "status": "284210004",
          "comment": intl.formatMessage({ id: `text.closed` as any }),
        }
        await ApiCustom.post(`${urlApi?.settings}/approvaldetails/`, temp) // add 1 record closed history
      }
      const successApproval = async(data:any,isSystem?:any,idHistories?: any) => {
        // console.log('data',data)
        // console.log('messFromMail',messFromMail)
        // if(messFromMail) {
        //   data.status = messFromMail.status
        //   data.comment = messFromMail.comment
        // }
        let tempData = {
          "currentstatus": data.status,
          "open": data.status == "284210003", // denied: true
        }
        let firstApproval = approvalLevels[0]//.find((item: any)=> item.order == 1)
        // console.log('approvalLevels',approvalLevels)
        if(data.status == "284210003" && firstApproval) { // denied and return for first user approval
          let approverData = {
            stage: 0
          }
         tempData = Object.assign(tempData,approverData)
        }
        try {
          let response = await ApiCustom.put(`${urlApi?.settings}/approvalhistories/${idHistories?idHistories:approvalhistories?.id}`, tempData) // change last currentstatus and information
          if(response && response.data) {
            let approvalhistoryid = response.data?.id
            let presentApproval = approvalLevels?.find((item: any)=> item.userid == userid && (+item.stage >= +approvalhistories?.currentstage))||{}
            // console.log('presentApproval',presentApproval)
            let nextApproval = checkNextApproval()
          //  console.log('nextApproval',nextApproval)
            let temp = {
              "statuscode": 1,
              "approvalhistoryid": approvalhistoryid,
              "status": data.status,
              "comment": data.status == "284210003" ? data.comment: intl.formatMessage({ id: `text.moved-to-next` as any }),
              "approverid": presentApproval.userid,
              "apprvercode": presentApproval.usercode,
              "approvername": presentApproval?.username,
              "stage": presentApproval?.stage,
              "creater": (typeof isSystem == 'boolean' && isSystem == true) ? null: undefined,
              
            }
            await ApiCustom.post(`${urlApi?.settings}/approvaldetails/`, temp) // add 1 record history

            if(nextApproval && data.status == "284210002") { // 284210002: approval --> next stage approval
              // check is relsease approval
              // console.log('approvalUser?.isSubmitto',approvalUser?.isSubmitto)
              if(approvalUser?.isSubmitto && +nextApproval.condition >= totalamount) { //approval type submitto call from approvalsetup
              // console.log('release')
                updateStatusforRelease(approvalhistoryid)
                return
              }
              checkSkipStage(approvalhistoryid) //check skip stage and request next approval
            }
            else {
              updateStatusforRequest(data.status)
              refetchApproval && refetchApproval()
              if(data.status == '284210003') {
                sendMailProcess(emailnotification?.data?.email,'denied') // send mail
              }
              else {
                sendMailProcess(emailnotification?.data?.email,'approved')
              }
            }
          }
          popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
        handleCloseModal()
        
        !includeFromlist && navigate(-1)
      }
      const updateforAdvance = async(status: string, tempRecord: any) => {
        const {employeeadvancerequestid,advancedamount} = tempRecord||dataQuery
        let data = await getInfAdvance(employeeadvancerequestid)
        const remainingadvancedamount = +data?.remainingadvancedamount||0
        let isCallApi = false
        let remaining = remainingadvancedamount - +advancedamount
        if(status == "284210003" ) { //denied
          remaining = remainingadvancedamount + +(advancedamount)
          isCallApi = true
        }else if(!presentApproval()) { 
          remaining = remaining
          isCallApi = true
        }
        let open = remaining > 0
        let tempData = {
            open: open,
            remainingadvancedamount: Math.abs(remaining)
        }
        try {
          (employeeadvancerequestid && isCallApi) && await ApiService.put('employees/employeeadvancerequests' + `/${employeeadvancerequestid}`,tempData)
        } 
        catch (error) { ApiShowError(error) }
      }
      const updateStatusforRequest = async(status:string) => {
        // console.log('valueid',approvalUser)
        await ApiCustom.put(`${urlApi?.typeRequest}/${valueid}`, {status: status})
        if(setTempRecord) {
          setTempRecord(approvalUser)
        }
        if(setValue) {
          setValue('status',status)
        }
        // console.log('status',status)
        // console.log('urlApi.request',urlApi)
        //284210002 : được phê duyệt
        if(urlApi.request == 'payment' ) {
          // console.log('approvalUser',approvalUser)
          await updateforAdvance(status,approvalUser)
          // denied 284210003 
        }
        if(id == 'new') {
          navigate(-1)
        }
      }
      const updateCurrentStatusApproval = async(approvalhistoryid:string,data:any) => {
        await ApiCustom.put(`${urlApi?.settings}/approvalhistories/${approvalhistoryid}`, data)
        refetchApproval && refetchApproval()
        await updateStatusforRequest(data.currentstatus)
      }
      const releaseApproval = async(approvalhistoryid: string, isSys?: any) => {
        let temp = {
          "statuscode": 1,
          "approvalhistoryid": approvalhistoryid,
          "status": "284210002",
          "comment": isSys?'Admin system':intl.formatMessage({ id: `text.auto-approval` as any }),
          "approverid": null,
          "apprvercode": null,
          "approvername": null,
          "requesterid": null,
          "requestercode": null,
          "requestername": null,
        }
        ApiCustom.post(`${urlApi?.settings}/approvaldetails/`, temp)
        // console.log('approvalhistoryid',approvalhistoryid)
        updateStatusforRelease(approvalhistoryid)
      }
      const updateStatusforRelease = (approvalhistoryid:any) => {
        let tempData = {
          "currentstatus": '284210002',
          "currentstage": -1,//presentApproval.stage||0,
          "open": false, // approve: true
        }
        updateCurrentStatusApproval(approvalhistoryid, tempData)
        sendMailProcess(emailnotification?.data?.email,'approved') // send mail approved

        popupMessage({ icon: 'success', autoClose: true })
        handleCloseModal()
      }
      const getInfAdvance = async(id: any) => {
        if(!id) return null
        let {data} = await ApiService.get('employees/employeeadvancerequests' + `/${id}`)
        return data
      }
      const requestApproval = async(isRelease?:any) => {
        let checkDeptHead = checkApprovalDeptHead(requestUser?.approvallevels)
        // console.log('requestUser?.approvallevels',requestUser?.approvallevels)
        // console.log('checkDeptHead',checkDeptHead)
        if(checkDeptHead == 1 || checkDeptHead == 2) {
          let text = intl.formatMessage({ id: checkDeptHead == 1? `text.contact-to-admin`:`text.required-dept-head` as any })
          popupMessage({ icon: 'info', description: text })
          return
        }
        if(!valueid || !checkRequestApproval()) {
          // console.log('return not request')
          return
        }
        let isquestion = id == 'new'
        if(!isquestion) {
          isquestion = await popupQuestion({ description:intl.formatMessage({ id: `text.do-you-want-to-submit-approval` as any }) })
        }
        if(isHasChange) {
          refSaveBtn?.current.click()
        }
        if(employeeAdvanceData && employeeAdvanceData.employeeadvancerequestid) {
          let employeeadvancerequestid = employeeAdvanceData.employeeadvancerequestid
          let data = await getInfAdvance(employeeadvancerequestid)
          if(data && (+data.remainingadvancedamount < +employeeAdvanceData.advancedamount)) {
            popupMessage({icon: "info", description: intl.formatMessage({ id: `text.advance-check-amount`}) })
            return
          }
        }
        if (isquestion) {
          let firstApproval = approvalLevels[0]//.find((item: any)=> item.order == 1)
          let documentdate = watch('documentdate')
          if(watch('documentdate') && typeof watch('documentdate') != 'string') {
            documentdate = watch('documentdate')[0]
          }
          let tempData = {
            "statuscode": 1,
            "documenttype": "284210000",
            "documentid": valueid,
            "documentcode": watch('code'),
            "documentdate": documentdate,//watch('documentdate') && watch('documentdate')[0],
            "currentstatus": "284210001",// initial request
            "currentstage": 0,// initial stage 0
            "open": true,
            "id": approvalhistories?.id,
            "rule": approvalLevels,
            "approvalid": requestUser?.approvalid
          }
          // console.log('next', checkNextApproval())
          try {
            let approvalhistoryid = undefined as any
            const method = approvalhistories ? 'put' : 'post'
            // console.log('tempData',tempData)
            let response = await ApiCustom[method](`${urlApi?.settings}/approvalhistories/${approvalhistories ? approvalhistories.id : ''}`, tempData)
            // console.log('response',response)
            if(response && response.data) {
              approvalhistoryid = response.data?.id
              let temp = {
                "statuscode": 1,
                "approvalhistoryid": approvalhistoryid,
                "status": "284210000",
                "stage": 0,
                "comment": intl.formatMessage({ id: `text.send-request-approval` as any }),
                "approverid": !isRelease ? firstApproval?.userid : null,
                "approvercode": !isRelease ? firstApproval?.usercode : null,
                "approvername": !isRelease ? firstApproval?.username : null,
                "requesterid": userid,
                "requestercode": user?.username,
                "requestername": user?.username,
              }
              await ApiCustom.post(`${urlApi?.settings}/approvaldetails/`, temp)
              sendMailProcess(emailnotification?.data?.email,'new_request') // send mail initial
              if(isRelease) {
                releaseApproval(approvalhistoryid)
                return
              }
              else {
                checkSkipStage(approvalhistoryid) // check skip approval if type == 'submits_to' and 
              }
              if(requestUser?.approvaltype == 'auto_reject') {
                successApproval({status: "284210003", comment: intl.formatMessage({ id: `text.auto-reject` as any })},true,approvalhistoryid)

              }
            }
            popupMessage({ icon: 'success', autoClose: true })
          } catch (error) { 
            // console.log('error',error)
            ApiShowError(error) }
        }
        
      }
      const checkRequestApproval = () => {
        let check = true
        if(!requestUser ) {
          return false
        }
        if(!approvalhistories) return true
        else if(approvalhistories?.currentstatus == '284210001' || approvalhistories?.currentstatus == '284210002' || !approvalhistories?.open) {
          check = false
        }
        return check
      }
      const checkClosedApproval = () => {
        return checkRequestApproval() && approvalhistories?.ApprovalDetails.length>2
      }
      const checkApprovalCompletion = () => {
        let check = false
        if(valueid && dataQuery && user?.isapprovalcompletion && approvalhistories?.id && (approvalhistories?.currentstatus != '284210002' && approvalhistories?.currentstatus != '284210004')) {
          check = true
        }
        return check
      }
      const formatDateforSentMail = (data: any) => {
        let tempData = {} as any
        for (let [key, value] of Object.entries(data)) {
          let checkType = value as any
          if(checkType instanceof Date) {
            checkType = new Date(checkType).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
          }
          tempData[key] = checkType
        }
        // console.log('formatDateforSentMail',formatDateforSentMail)
        return tempData
      }
      const sendMailProcess = (receiveremail: string, type: string, userid?: string) => {
        // console.log('emailnotification',emailnotification)
        let tempData = {} as any
        let infoMailTemplate = emailnotification?.message?.find((i:any)=> i.type == type) as any
        // console.log('infoMailTemplate',infoMailTemplate)
        if(!infoMailTemplate) return
        emailnotification.data.userid = userid
        emailnotification.data.requesttype = urlApi.request
        tempData = {
          "receiveremail": receiveremail,
          "cc": emailnotification.cc,
          "bcc": emailnotification.bcc,
          "customtemplate": {
              "title": infoMailTemplate?.subject,
              "body": infoMailTemplate?.body,
              "mailwith": infoMailTemplate?.mailwith,
          },
          "data": formatDateforSentMail(emailnotification.data)
        }
        // console.log('tempData',tempData)
        // console.log('type',type)
        ApiCustom.post(urlApi.sendMail, tempData)
      }
      const exportDataCsv = () => {
        let tempData = [] as any
        if(urlApi.request == 'payment') {
          let documenttype = Object.values(LocalData.venderType).find((e: any) => e.value == dataQuery?.documenttype) as any
          dataQuery?.PaymentRequestLines?.forEach((item: any)=> {
            let head = {
              "Ngày chứng từ": dataQuery.documentdate && new Date(dataQuery?.documentdate).toLocaleDateString("en-GB"),
              "Mã chứng từ": dataQuery?.code,
              "Loại": documenttype?.label,
              "Mã":	dataQuery?.sourceno,
              "Tên": dataQuery?.sourcename,
              "Mã Thanh Toán": dataQuery?.expensecode,
              "Nội Dung": dataQuery?.expensename,
              "Thanh Toán": dataQuery?.totalamount,
              "Phương thức": LocalData.paymentMethod[dataQuery.paymentmethodcode]?.label,
              "Mã tạm ứng": dataQuery?.advancedno,
              "Tiền Tạm ứng": dataQuery?.advancedamount,
              "Mã chi phí":	item.expensevaluecode,
              "Tên chi phí": item.expensevaluecode,
              "Diễn giải":	item?.remark,
              "Store Code": item.globaldimension1value,
              "Store Name":	item.globaldimension1name,
              "Số lượng":	item.quantity,
              "ĐVT": item.uom,
              "VAT": item.vatperc,
              "Đơn giá": item.unitprice,
              "VAT Amount": item.vatamount,
              "Tổng cộng ( VAT)": item.amountexclvat,
              "Tổng cộng (gồm VAT)": item.amountinclvat,
              "Mã hóa đơn": item.invoiceno,
            } as any
            tempData.push(head)
          })
        }
        if(urlApi.request == 'order') {
          dataQuery?.RequestOrderLines?.forEach((item: any)=> {
            let head = {
              "Ngày chứng từ": dataQuery.documentdate && new Date(dataQuery?.documentdate).toLocaleDateString("en-GB"),
              "Số chứng từ": dataQuery?.requestordercode,
              "Kính gửi":	dataQuery?.requestto,
              "Về việc": dataQuery?.title,
              "Mục đích": dataQuery?.purpose,
              "Mã phòng ban": dataQuery?.departmentcode,
              "Phòng ban": dataQuery?.departmentname,
              "Ngày cần": dataQuery.expecteddate && new Date(dataQuery?.expecteddate).toLocaleDateString("en-GB"),
              "Loại":	item.type == '284210000' ? 'Item': '',
              "Mã": item.itemcode,
              "Tên sản phẩm": item.description,
              "Ghi chú":	item.remark,
              "Số lượng":	item.quantity,
              "Đvt": item.uom,
              "VAT": item.vatperc,
              "Đơn giá": item.unitprice,
              "Đơn giá (ref)": item.unitpriceref,
            } as any
            tempData.push(head)
          })
        }
        if(urlApi.request == 'advance') {
          let documenttype = Object.values(LocalData.venderType).find((e: any) => e.value == dataQuery?.documenttype) as any
          dataQuery?.EmployeeAdvanceRequestLines.forEach((item: any)=> {
            let head = {
              "Ngày chứng từ": dataQuery.createdat && new Date(dataQuery?.createdat).toLocaleDateString("en-GB"),
              "Mã chứng từ": dataQuery?.documentno,
              "Loại chứng từ": documenttype?.label,
              "Mã":	dataQuery?.sourceno,
              "Tên": dataQuery?.sourcename,
              "Mô tả": dataQuery?.description,
              "Phương thức": LocalData.paymentMethod[dataQuery.paymentmethodcode]?.label,
              "Ngân hàng": dataQuery?.bankname,
              "Mã Ngân hàng": dataQuery?.bankno,
              "Người thụ hưởng": dataQuery?.bankaccountname,
              "Ngày cần": dataQuery.requireddate && new Date(dataQuery?.requireddate).toLocaleDateString("en-GB"),
              "Ngày hoàn ứng": dataQuery.advancepaymentperiod && new Date(dataQuery?.advancepaymentperiod).toLocaleDateString("en-GB"),
              "Diễn giải": item.description,
              "Tạm ứng": item.amount,
              
            } as any
            tempData.push(head)
          })
        }
        
       
        let fileName = `export-${urlApi.request}-details_`+new Date().toLocaleDateString()
        exportCSV(tempData,fileName)
      }
      const modalApproval = () => {
        return <Modal size="sm" id='kt_modal_edit' title={intl.formatMessage({ id: `text.approval` as any })} show={isShow} onHide={handleCloseModal} centered >
        <Modal.Header className='pb-3 '>
            <Modal.Title className="d-flex align-items-center">
              <h3>{intl.formatMessage({ id: `text.approval-reject` as any })}</h3>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 rounded-bottom">
            <div className="position-relative">
            <form onSubmit={handleSubmitChild(successApproval)}>
                <div className="row">
                    <div className="col-12 col-md-6 d-none">
                        <KTFormItem title='text.status'>
                          <Controller
                            name='status'
                            control={controlChild}
                            render={({ field }) => <InputSelect
                              className={clsx('form-control form-control-sm w-100 select-custom',{ 'form-error': errorsChild.status })}
                              options={Object.values(LocalData.statusData).filter((i:any)=>i.value == 284210002 || i.value ==284210003)}
                              value={Object.values(LocalData.statusData).filter((i:any)=>i.value == 284210002 || i.value ==284210003)?.find((e: any) => e.value == field.value)}
                              disabled
                              onChange={e => {
                                field.onChange(e.value)
                                if(e.value == "284210003") {
                                  setsShemaChild(yup.object({
                                    status: yup.string().required(),
                                    comment: yup.string().required(),
                                  }).required())
                                }
                                
                              }}
                            />} />
                        </KTFormItem>
                    </div>
                    {
                      watchChild('status') == "284210003" &&
                      <div className="col-12 col-md-12">
                        <KTFormItem title='text.reason' isBLockLabel className='align-items-baseline mb-4' isRequired>
                          <textarea rows={4} className={clsx('form-control form-control-sm resize-none', { 'form-error': errorsChild.comment })} {...registerChild('comment')} />
                        </KTFormItem>
                      </div>
                    }
                </div>
                <div className='d-flex mt-4 gap-3 justify-content-end'>
                <span className='btn btn-secondary' data-bs-dismiss='modal' aria-label='Close' onClick={e =>setIsShow(false)}>{intl.formatMessage({ id: `text.cancel` as any })}</span>
                <button className='btn btn-primary'>{intl.formatMessage({ id: `text.send` as any })}</button>
                </div>
            </form>
            </div>
            
        </Modal.Body>
    </Modal>
      }
    return <DivRoot>
      
    {includeFromlist ? 
      <div className="wrap-action-approval">
        <div className="menu-item border border-warning border-dashed border-left-0 border-right-0 border-top-0" onClick={()=>{
                let data = {
                  status: "284210002",
                  comment: null
                }
                successApproval(data)
              }}>
            <a href="#">
              <i className="bi bi-shield-check menu-link fs-4 text-success hover-bold">
                <span className="ms-2 font-normal fs-6" >{intl.formatMessage({ id: `text.dashboard.task.approve` as any })}</span>
              </i>
            </a>
        </div>
        <div className="menu-item" onClick={()=>{
              handleAddModalApproval("284210003")
            }}>
          <a href="#" >
              <i className="bi bi-slash-circle menu-link fs-5 text-danger hover-bold">
                <span className="ms-2 font-normal fs-6">{intl.formatMessage({ id: `text.dashboard.task.deny` as any })}</span>
              </i>
          </a> 
        </div>
      </div>:
      <>
       
        <>
        <span className={clsx('btn btn-sm btn-primary d-none')} ref={refApprovalRequest} onClick={e=>requestApproval(checkIsRelease())}><i className="bi bi-send-fill"></i>{intl.formatMessage({ id: `text.send-request` as any })}</span>
        
        { checkIsApprover() &&
          <>
            <span className='btn btn-sm btn-primary' onClick={()=>{
              let data = {
                status: "284210002",
                comment: null
              }
              successApproval(data)
            }} ><i className="bi bi-shield-check"></i>{intl.formatMessage({ id: `text.approved` as any })}</span>
            <span className='btn btn-sm btn-danger' onClick={e=>handleAddModalApproval("284210003")}><i className="bi bi-slash-circle"></i>{intl.formatMessage({ id: `text.denied` as any })}</span>
          </>
        }
        <Dropdown>
          <Dropdown.Toggle as={'div'} className='btn btn-light-info btn-active-info btn-sm btn-icon h-el-after'>
            <i className="fs-4 bi bi-three-dots-vertical" />
          </Dropdown.Toggle>
          <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px p-2' >
              <Dropdown.Item as='div' className={clsx('menu-item ',{'disabled opacity-50': !valueid || !checkRequestApproval() })} onClick={e=>requestApproval(checkIsRelease())}>
                  <a href='#'  className=''> 
                    <span className="menu-link d-flex align-items-center gap-2 py-2">
                      <i className="bi bi-send-fill fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.send-request` as any })}</span></i> 
                    </span>
                  </a>
              </Dropdown.Item>
              
              <Dropdown.Item as='div' onClick={formatPrintPdf} className={clsx('menu-item ',{'disabled opacity-50': !valueid })}>
                  <a href='#' className=''> 
                  <span className="menu-link d-flex align-items-center gap-2 py-2">
                    <i className="bi bi-printer fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.print` as any })}</span></i>
                  </span>
                     
                  </a>
              </Dropdown.Item>
              <Dropdown.Item as='div' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" className={clsx('menu-item ',{'disabled opacity-50': !approvalhistories })}>
                  <a href='#' className=''> 
                    <span className="menu-link d-flex align-items-center gap-2 py-2">
                      <i className="bi bi-chat-square-text fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.approval-history` as any })}</span></i>
                    </span>
                     
                  </a>
              </Dropdown.Item>
              {checkApprovalCompletion()? 
                  <Dropdown.Item as='div' className={clsx('menu-item ')} onClick={e=>closedRequestApproval()}>
                  <a href='#'  className=''> 
                    <span className="menu-link d-flex align-items-center gap-2 py-2">
                      <i className="bi bi-x-circle fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.closed-request` as any })}</span></i> 
                    </span>
                  </a>
              </Dropdown.Item>:
              <Dropdown.Item as='div' className={clsx('menu-item ',{'disabled opacity-50': !valueid || !checkClosedApproval()})} onClick={e=>closedRequestApproval()}>
                  <a href='#'  className=''> 
                    <span className="menu-link d-flex align-items-center gap-2 py-2">
                      <i className="bi bi-x-circle fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.closed-request` as any })}</span></i> 
                    </span>
                  </a>
              </Dropdown.Item>
              }
              
              <Dropdown.Item as='div' className={clsx('menu-item ',{'disabled opacity-50': !valueid || !dataQuery })} onClick={exportDataCsv}>
                  <a href='#'  className=''> 
                    <span className="menu-link d-flex align-items-center gap-2 py-2">
                      <i className="bi bi-arrow-bar-down fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.export` as any })}</span></i> 
                    </span>
                  </a>
              </Dropdown.Item>
              {
                checkApprovalCompletion() && 
                <Dropdown.Item as='div' className={clsx('menu-item ',)} onClick={e =>releaseApproval(approvalhistories?.id, true)}>
                  <a href='#' className=''> 
                    <span className="menu-link d-flex align-items-center gap-2 py-2">
                      <i className="bi bi-check2-all fs-6 text-black"><span className="font-normal ms-2">{intl.formatMessage({ id: `text.complete-approval` as any })}</span></i> 
                    </span>
                  </a>
              </Dropdown.Item>
              }
              
          </Dropdown.Menu>
      </Dropdown>
        </>
        {/* } */}
      </>
    }
      {modalApproval()}
    </DivRoot>
}