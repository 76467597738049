import clsx from "clsx"
import { FC, ReactNode } from "react"
import { useIntl } from "react-intl"

interface IStyle {
    isBLockLabel?: boolean
    isHeight?: boolean
    className?: string
    labelWidth?: 'auto' | string
    isRequired?: boolean
    labelClassName?: string
}

interface IKTFormItem extends IStyle {
    title: string | ReactNode
    children?: string | ReactNode
}

export const KTFormItem: FC<IKTFormItem> = ({ title, children, isBLockLabel, className, isHeight, labelWidth, isRequired, labelClassName }) => {
    const intl = useIntl()
    const labelWidthCus = labelWidth == 'auto' ? labelWidth : (labelWidth ? labelWidth : '125') + 'px'
    const style = !isHeight && !isBLockLabel ? { minHeight: '36.5px', maxHeight: '40px' } : undefined
    const textTitle = typeof title == 'string' && title.includes("text.") ? intl.formatMessage({ id: title as any }) : title

    return <div style={{ ...style, }}
        className={clsx(
            `align-items-${isHeight ? 'start' : 'center'}`,
            className != undefined ? className : 'mb-4', {
            'd-flex flex-row': !isBLockLabel
        })}>
        <label
            className={clsx(
                labelClassName,
                "form-label  me-6 text-black fw-normal",
                isBLockLabel ? 'me-auto text-nowrap pe-2 mb-2' : 'mb-0',
                isRequired && 'required'
            )}
            style={{
                minWidth: !isBLockLabel ? labelWidthCus : 'auto',
                maxWidth: !isBLockLabel ? labelWidthCus : '100%'
            }}>
            {textTitle}
        </label>
        {children}
    </div>
}