// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import CKEditor5 from 'ckeditor5-custom-build/build/ckeditor'

type IToolbar = "bold" | "italic" | "underline" | "strikethrough" | "link" | "numberedList" | "bulletedList" | "heading" | "fontSize" | "fontColor" | "fontBackgroundColor" | "alignment" | "outdent" | "indent" | "imageUpload" | "blockQuote" | "insertTable" | "mediaEmbed" | "removeFormat" | "undo" | "redo" |
  "htmlEmbed" | "sourceEditing"

const defaultToolbal: IToolbar[] = ["bold"
  , "italic"
  , "underline"
  , "strikethrough"
  , "link"
  , "numberedList"
  , "bulletedList"
  , "heading"
  , "fontSize"
  , "fontColor"
  , "fontBackgroundColor"
  , "alignment"
  , "imageUpload"
  , "insertTable"
  , "mediaEmbed"
  , "undo"
  , "redo"]

interface InputHtmlProps {
  setEditorModel?: React.Dispatch<any>
  className?: string
  value?: string | null
  onChange: (value: any) => void | undefined
  onBlur?: (value: any) => void | undefined
  toolbar?: IToolbar[],
  isHtml?: boolean
}

export const InputHtml: React.FC<InputHtmlProps> = (props) => {
  const { value, onChange, onBlur, className, toolbar, setEditorModel } = props

  return <CKEditor
    editor={CKEditor5}
    config={{
      toolbar: toolbar || (props.isHtml ? [...defaultToolbal, "htmlEmbed", "removeFormat"] : defaultToolbal),
      removePlugins: ["MediaEmbedToolbar"],
      htmlSupport: {
        allow: [{
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true
        }],
        disallow: [ /* HTML features to disallow */]
      }
    } as any}
    data={value || ''}
    onReady={(editor) => {
      props.value && editor.setData(props.value)
      if (editor?.ui?.view?.element) {
        editor.ui.view.element.classList.add(className);
      }
      editor && setEditorModel && setEditorModel(editor)
    }}
    onBlur={(event, editor) => {
      const changevalue = editor.getData()
      if (onBlur) {
        onBlur(changevalue || "")
      }
    }}
    onFocus={(event, context) => { }}
    onChange={(event, editor) => {
      const changevalue = editor.getData()
      onChange(changevalue || "")
    }}
    onError={(props, context) => { console.log(props) }} />
}
