import { createContext, FC, useContext, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useAuth } from '../app/modules/auth'
import { intlConfig, TypeLanguages } from '../i18n'
import { getLocalStorage, setLocalStorage, WithChildren } from './helpers'

interface Props {
  timezone?: string
  currentLocale: TypeLanguages
  setCurrentLocale: React.Dispatch<React.SetStateAction<TypeLanguages>>
  sidebarMini: boolean
  setSidebarMini: React.Dispatch<React.SetStateAction<boolean>>
}

const SiteContenxt = createContext<Props>({
  timezone: '',
  currentLocale: 'en',
  setCurrentLocale: () => { },
  sidebarMini: false,
  setSidebarMini: () => { },
})

const useSiteConfig = () => {
  return useContext(SiteContenxt)
}

interface ISiteConfig {
  selectedLang: TypeLanguages
  sidebarMini: boolean
}

const SiteProvider: FC<WithChildren> = ({ children }) => {
  const key = 'siteConfig'
  const { currentUser } = useAuth()
  const configsData = getLocalStorage<ISiteConfig>(key)
  const [currentLocale, setCurrentLocale] = useState(configsData?.selectedLang || 'vi')
  const [sidebarMini, setSidebarMini] = useState(configsData?.sidebarMini || false)

  useEffect(() => {
    const lang = document.getElementsByTagName('html')[0].getAttribute('lang');
    if(configsData?.selectedLang != lang) {
      document.getElementsByTagName('html')[0].setAttribute('lang', configsData?.selectedLang || 'vi');
    }
    setLocalStorage(key, { ...configsData, selectedLang: currentLocale })
  }, [currentLocale])

  useEffect(() => {
    setLocalStorage(key, { ...configsData, sidebarMini: sidebarMini })
  }, [sidebarMini])

  return <SiteContenxt.Provider value={{ sidebarMini, setSidebarMini, currentLocale, setCurrentLocale, timezone: currentUser?.timezone }}>
    <IntlProvider locale={currentLocale} timeZone={currentUser?.timezone} messages={intlConfig.localMessages[currentLocale] as any}>
      {children}
    </IntlProvider>
  </SiteContenxt.Provider>
}

export { SiteProvider, useSiteConfig }

