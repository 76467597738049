import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { Column } from "react-table"
import { sortByUpdateAt, ApiService, getValueId, formatOptions, changeDataModify, ApiShowError } from "../../../theme/helpers"
import { ContentHeader } from "../../../theme/layout/components/content"
import { CustomHeader, BasicCell, RowIcon, FilterDropdown, KTButton, KTTable, yup, TextFormByUser, FormSubmit, KTFormItem, InputSelect } from "../../../theme/partials"
import { IInitField } from "../../modules/api"
import { apiAd } from "../../modules/api/admin"
import { localSetings } from "../../modules/api/localData"
import { popupMessage } from "../../modules/messages"
import { MergedProps } from "../../router/AppRoutes"

export interface IRequiredDocument extends IInitField {
    objectid: string | null
    objectcode: string | null
    objectname: string | null
    module: number | null
    seq: number | null
    description: string | null
    documenttype: string | null
    isrequired: boolean
    maxfilesize: number | null
    numberoffile: number | null
}

const apiurl = 'settings/requireddocuments'
export default function AdAttachmentsPage({ keyElement, pathElement, permission }: MergedProps) {
    const { data, isSuccess, isFetching } = apiAd.useRequiredDocuments()
    const [tableData, setTableData] = useState<IRequiredDocument[]>([])

    useEffect(() => {
        if (isSuccess && !isFetching)
            setTableData(sortByUpdateAt(data))
    }, [isFetching])

    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.object.id' />,
        accessor: 'objectcode',
        Cell: ({ ...props }) => <BasicCell
            to={props.data[props.row.index].id}
            data={props.data[props.row.index].objectcode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.object.name' />,
        accessor: 'objectname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].objectname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'description',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].description} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.type' />,
        accessor: 'documenttype',
        Cell: ({ ...props }) => {
            const items = String(props.data[props.row.index].documenttype).split(',')
            const types = items.map(item => localSetings.optionDocumemntType.find(f => f.value == item)?.label)
            return <BasicCell data={types.join(',')} />
        },
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.required' />,
        accessor: 'isrequired',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].isrequired} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" permission={permission} to={props.data[props.row.index].id} />
            <RowIcon action="delete" permission={permission} onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(apiurl + `/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTableData(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    return <>
        <ContentHeader title={keyElement} elements={<>
            <FilterDropdown>
            </FilterDropdown>
            <KTButton action='new' />
        </>} />
        <KTTable
            isLoading={isFetching}
            data={tableData}
            columns={columns}
            cardInnner
            pagination
            search />
    </>
}

const defaultValues: IRequiredDocument = {
    objectid: null,
    objectcode: null,
    objectname: null,
    module: null,
    seq: null,
    description: null,
    documenttype: null,
    isrequired: false,
    maxfilesize: null,
    numberoffile: null
}

const schema = yup.object().shape({
    objectid: yup.string().required(),
    maxfilesize: yup.number().min(1).max(25).nullable(true).transform((val) => val ? Number(val) : null),
    numberoffile: yup.number().nullable(true).transform((val) => val ? Number(val) : null)
});

export const AdAttachmentsDetailPage = ({ keyElement, pathElement, permission }: MergedProps) => {
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching, refetch } = apiAd.useRequiredDocumentsId<IRequiredDocument>(valueid)
    const { register, reset, control, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IRequiredDocument>({
        defaultValues,
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        if (isSuccess && !isFetching)
            reset(data)
    }, [isFetching])

    const { data: objectsData, isSuccess: isObjects } = apiAd.useObjects()
    const optionObjects: any[] = isObjects ? formatOptions(objectsData, ['id', 'objectname']) : []

    return <>
        <ContentHeader title={keyElement} items={[{ title: keyElement, path: pathElement }]} />
        <form
            className="card"
            onSubmit={handleSubmit(async (data: IRequiredDocument) => {
                try {
                    const method = isEdit ? 'put' : 'post'
                    await ApiService[method](apiurl + `${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                    popupMessage({ icon: 'success', autoClose: true })
                    navigate(pathElement)
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="card-header">
                <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                <div className="card-toolbar">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        permission={permission}
                        isSubmitting={isSubmitting}
                        handleClose={() => navigate(pathElement)} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.object'>
                            <Controller
                                name="objectid"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    options={optionObjects}
                                    value={optionObjects.find(f => f.value == value) || null}
                                    onChange={(e) => {
                                        setValue("objectcode", null)
                                        setValue("objectname", null)
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue("objectcode", e.objectid)
                                        setValue("objectname", e.objectname)
                                    }}
                                />}
                            />
                        </KTFormItem>
                        <KTFormItem title='text.seq'>
                            <input className={`form-control form-control-sm ${errors.seq && 'form-error'}`} {...register('seq')} />
                        </KTFormItem>
                        <KTFormItem title='text.name'>
                            <input className={`form-control form-control-sm ${errors.description && 'form-error'}`} {...register('description')} />
                        </KTFormItem>
                        <KTFormItem title='text.type'>
                            <Controller
                                name="documenttype"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    isMulti
                                    options={localSetings.optionDocumemntType}
                                    value={value?.split(',').map(item => localSetings.optionDocumemntType.find(f => f.value == item)) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.map((item: any) => item.value).join(','))
                                    }}
                                />}
                            />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title='text.maximum-size-(MB)'>
                            <div className="d-flex align-items-center gap-2 w-100">
                                <input className={`form-control form-control-sm ${errors.maxfilesize && 'form-error'}`} {...register('maxfilesize')} type="number" />
                                <span className="min-w-85px fw-bold">(Tối đa 25MB)</span>
                            </div>
                        </KTFormItem>
                        <KTFormItem title='text.maximum-quantity-(file)'>
                            <input className={`form-control form-control-sm ${errors.numberoffile && 'form-error'}`} {...register('numberoffile')} type="number" />
                        </KTFormItem>
                        <KTFormItem title='text.required'>
                            <label className="form-check form-switch form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" {...register("isrequired")} />
                            </label>
                        </KTFormItem>
                    </div>
                </div>
            </div>
        </form>
    </>
}
