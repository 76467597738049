import clsx from "clsx"
import { FC, useRef, useState } from "react"
import { popupMessage } from "../../../../app/modules/messages"
import { toBase64 } from "../../../helpers"

export type IRadioSize = '0.5:0.5' | '0.8:0.8' | '1:1' | '1.25:1.25' | '1.5:1.5' | '2:1' | '2:2' | '3:1'

interface IInputSingleFile {
    className?: string
    value?: string | null
    onChange: (value: string | null) => void
    size?: IRadioSize
}

export const InputSingleImage: FC<IInputSingleFile> = (props) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const { className, value, onChange, size, ...rest } = props
    const wRadio = size ? size.split(':').map(m => parseFloat(m) * 80) : [80, 80]
    const [key, setKey] = useState(Date.now())

    const onSelectFile = async (e: any) => {
        const files: any = e.target?.files
        if (files.item(0)) {
            // I've kept this example simple by using the first image instead of multiple
            const mbfile = files.item(0).size / 1024 / 1024
            if (mbfile > 2) {
                popupMessage({ icon: "error", description: "This selected file is too large. Please select a file less than 1MB" })
                return;
            }
            const image = await toBase64(files.item(0)).then(value => value)
            onChange(typeof image == 'string' ? image : null)
        }
    }

    function onRemove() {
        setKey(Date.now())
        onChange(null)
    }

    return <div
        className={clsx(className, 'position-relative d-row-hover border border-dashed border-2 border-secondary rounded ')}
        style={{ width: wRadio[0], minHeight: wRadio[1], height: wRadio[1] }}
    >
        <div className={`d-flex flex-column flex-center cursor-pointer w-100 h-100 p-1`} onClick={() => inputRef.current?.click()} >
            {typeof value == 'string' && (value.startsWith('data:image') || value.startsWith('http'))
                ? <img
                    src={value}
                    className={`rounded img-auto`}
                    style={{ width: wRadio[0] - 10, height: wRadio[1] - 10 }}
                    alt="image"
                />
                : <i className="bi bi-card-image fs-1"></i>}
        </div>
        <input
            key={key}
            ref={inputRef}
            onChange={onSelectFile}
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            className='d-none'
        />
        {!!value && <div className="position-absolute top-0 end-0 mt-2 me-2 d-cell-hover">
            <i className="bi bi-x-circle-fill text-danger opacity-75 cursor-pointer" onClick={onRemove}></i>
        </div>}
    </div>
}